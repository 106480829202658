/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 *
 */

import httpApi from "./httpApi";

const moment = require("moment");

/**
 * This method is used to get store list
 *
 * @returns
 */
const getStoreList = async () => {
  const response = await httpApi.get("v1/stores", {
  });
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get inventory config
 *
 * @returns
 */
 const getB2BInventoryConfiguration = async (salesCompany) => {
    const response = await httpApi.get(`v1/b2b/inventory/inventory-configuration?salesCompany=${salesCompany}`);
    if (response && response.data.success && response.data) {
      return response.data;
    }
    return null;
};


/**
 * This method is used to get store inventory
 *
 * @returns
 */
 const getB2BstoreInventory = async (salesCompany, brand, searchTerm, retailer, storeId, size, page) => {
    const requestBody = {
        searchTerm, salesCompany, brand, retailer, storeId, size, page
    };
    const response = await httpApi.post(`v1/b2b/inventory/search`, requestBody);
    if (response && response.data.success && response.data) {
      return response.data;
    }
    return null;
};

/**
 * This method is used to get inventory data
 *
 * @param {*} storeId
 * @returns
 */
const getInventoryData = async (storeId, page = 0, limit = 10) => {
  const response = await httpApi.get(`v1/inventory/store?storeId=${storeId}&page=${page}&size=${limit}`);
  if (response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get inventory data by search criteria
 *
 * @param {*} storeId
 * @returns
 */
const getInventoryDataBySearchCriteria = async (storeId, searchTerm, page = 0, size = 10, cancelToken) => {
  const response = await httpApi.get("v1/inventory/store/criteria-search", {
    params: {
      storeId, searchTerm, page, size,
    },
    cancelToken,
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to set perpetual flag and Quantity
 *
 * @returns
 */
const setPerpetualInInventory = async (productId, skuId, perpetual) => {
  const response = await
    httpApi.patch(`v1/inventory/update-perpetual/${productId}/sku/${skuId}/perpetual/${perpetual}`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to transform Customers data
 * @param Array
 * @returns {Array}
 */
const transformInventoryData = async (inventories) => {
  const filtered = inventories.map(({
    externalSkuId,
    skuId,
    productId,
    storeId,
    quantity,
    availableToPromise,
    status,
    createdDate,
    updatedDate,
    perpetual,
    allocatedStock,
  }) => ({
    "External SkuId": externalSkuId,
    SkuId: skuId,
    "Product Id": productId,
    "Store Id": storeId,
    "Available stock": perpetual ? "NA" : (quantity || 0),
    Reservation: perpetual ? "NA" : (allocatedStock || 0),
    ATP: perpetual ? "NA" : (availableToPromise || 0),
    Status: perpetual ? "InStock" : status,
    Perpetual: perpetual ? "True" : "False",
    "Created Date": createdDate ? moment(createdDate).format("YYYY-MM-DD HH:mm:ss") : null,
    "Updated Date": updatedDate ? moment(updatedDate).format("YYYY-MM-DD HH:mm:ss") : null,
  }));
  return filtered;
};

export {
  getStoreList,
  getInventoryData,
  getInventoryDataBySearchCriteria,
  setPerpetualInInventory,
  transformInventoryData,
  getB2BInventoryConfiguration,
  getB2BstoreInventory,
};
