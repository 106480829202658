/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
/** ========= SUB COMPONENT ========= */
import SearchBox from "./SearchBox";
import Marker from "./Marker";
/** ========= CONFIG ========= */
import config from "../../../config";

const LocationSelector = React.memo((props) => {
  const googleMapApiKey = config.key.googleMap;

  const libraries = ["places", "geometry"];
  const {
    defaultZoom,
    onChange,
  } = props;

  /**  local states */
  const [currentLocation, setCurrentLocation] = useState({
    lat: 25.2048,
    lng: 55.2708,
  });
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapApi, setMapApi] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [draggable, setDraggable] = useState(true);

  /** internationalization using i18n from common */
  const { i18n, ready } = useTranslation(["common"]);
  const language = (ready && i18n && i18n.language) || undefined;

  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation((prevState) => ({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }));
        },
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(getGeoLocation, []);

  const onGoogleApiLoaded = ({ map, maps }) => {
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);
  };

  /**
   * This method is used to
   * @param {Object} place
   */
  const addPlace = (place) => {
    if (place) {
      const placeObject = {
        id: place.id,
        name: place.name,
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      };
      setPlaces({ places: [placeObject] });
      setSelectedPlace(placeObject.id);
    }
  };

  const bootstrapURLKeys = { key: googleMapApiKey, libraries, language };

  /**
   * This method is used to change selected marker
   * @param {String} key
   */
  const markerClickHandler = (key) => {
    if (key) {
      setSelectedPlace(key);
    }
  };

  useEffect(() => {
    if (selectedPlace) {
      const place = places.filter(({ id }) => selectedPlace === id)[0];
      if (place) {
        const { lat } = place.location;
        const { lng } = place.location;
        onChange({ lat, lng });
      }
    }
  }, [selectedPlace, places, onChange]);

  /**
   * Custom paint in the map
   * @param {Object} customPoint
   */
  const mapClickHandler = (customPoint) => {
    if (customPoint && customPoint.lat && customPoint.lng) {
      const { lat, lng } = customPoint;
      setPlaces([{
        id: "custom-marker",
        name: `${lat}, ${lng}`,
        location: {
          lat, lng,
        },
      }]);
      setSelectedPlace("custom-marker");
    }
  };

  /**
   * This function is used to handle mouse down
   * @param {String} childKey
   * @param {Object} childProps
   * @param {Object} mouse
   */
  const childMouseDownHandler = (childKey, childProps, mouse) => {
    setDraggable(false);
  };

  /**
   * This function is used to handle mouse up
   * @param {String} childKey
   * @param {Object} childProps
   * @param {Object} mouse
   */
  const childMouseUpHandler = (childKey, childProps, mouse) => {
    setDraggable(true);
  };

  /**
   * This function is used to handle mouse move / drag
   * @param {String} childKey
   * @param {Object} childProps
   * @param {Object} mouse
   */
  const childMouseMoveHandler = (childKey, childProps, mouse) => {
    const newPlaces = places.map((place) => (
      place.id === childKey
        ? { ...place, location: { ...place.location, lat: mouse.lat, lng: mouse.lng } } : place
    ));

    setPlaces(newPlaces);
  };


  return (
    <div className="position-relative h-100 w-100">
      {mapApiLoaded && <SearchBox map={mapInstance} mapApi={mapApi} setPlace={addPlace} toZoom={14} />}
      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        defaultCenter={currentLocation}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChildClick={markerClickHandler}
        onClick={mapClickHandler}
        onChildMouseDown={childMouseDownHandler}
        onChildMouseUp={childMouseUpHandler}
        onChildMouseMove={childMouseMoveHandler}
        draggable={draggable}
        fullscreenControl={false}
      >
        {Array.isArray(places)
          && places.map((place) => (
            <Marker
              key={place.id}
              text={place.name}
              lat={place.location.lat}
              lng={place.location.lng}
              selected={selectedPlace === place.id}
              draggable
              onDragging={!draggable}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
});

LocationSelector.defaultProps = {
  defaultZoom: 11,
};

LocationSelector.propTypes = {
  defaultZoom: PropTypes.number,
  // functions
  onChange: PropTypes.func.isRequired,
};

export default LocationSelector;
