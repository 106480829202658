/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import JobHistoryConfiguration from "../components/JobHistoryConfiguartion";
import {
  getJobHistoryRunTimes,
  getJobHistoryStatuses,
  getJobHistory,
  getAllBatchJobConfigurations,
} from "../../../api/jobConfigurationService";
import JobNavigationHeader from "../components/JobNavigationHeader";
import JobDetailsContainer from "./JobDetailsContainer";
import JobAuditDetailsContainer from "./JobAuditDetailsContainer";
import JobTaskViewContainer from "./JobTaskViewContainer";

const pageLimit = 10;

const JobHistoryConfigurationContainer = () => {
  const history = useHistory();
  const moment = require("moment");
  const { params } = useRouteMatch();
  const [jobId, setJobId] = useState(null);
  const [jobHistoryRunTimesForm, setJobHistoryRunTimesForm] = useState([]);
  const [jobHistoryStatusesForm, setJobHistoryStatusesForm] = useState([]);
  const [jobNameList, setJobNameList] = useState([]);
  // const [jobHistoryForm, setJobHistoryForm] = useState({
  //     id: "",
  //     name: "",
  //     status: "",
  //     lastRun: "",
  //     scope: "",
  //     jobHistorySubTask: [],
  // });
  const [jobHistoryReqData, setJobHistoryReqData] = useState({
    page: 0,
    size: pageLimit,
  });
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobHistoryData, setJobHistoryData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [startOfResult, setStartOfResult] = useState(false);
  const [endOfResult, setEndOfResult] = useState(false);

  useEffect(() => {
    jobHistoryServiceCall(jobHistoryReqData);
  }, []);

  useEffect(() => {
    const reqObj = jobHistoryReqData;
    if (fromDate) {
      reqObj.startDate = fromDate;
    }
    if (toDate) {
      reqObj.endDate = toDate;
    }
    setJobHistoryReqData(reqObj);
  }, [fromDate, toDate]);

  // Calling job history service
  const jobHistoryServiceCall = (reqObj) => {
    console.log(reqObj);
    getJobHistory(reqObj).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (data && data.content) {
          setJobHistoryData(data.content);
          setStartOfResult(data.startOfResult);
          setEndOfResult(data.endOfResult);
        }
      }
    });
  };

  const search = () => {
    const reqObj = jobHistoryReqData;
    reqObj.page = 0;
    reqObj.size = pageLimit;
    jobHistoryServiceCall(reqObj);
  };

  const onChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const reqObj = jobHistoryReqData;
    // For Job Status Filter
    if (name === "status") {
      reqObj[name] = value;
      if (value === "All") {
        delete reqObj.status;
      }
    }
    // For Job Name Filter
    if (name === "jobConfigId") {
      reqObj[name] = value;
    }

    // For Search Term
    if (name === "searchTerm") {
      reqObj[name] = value;
    }
    setJobHistoryReqData(reqObj);
  };

  const paginationNext = () => {
    const reqObj = jobHistoryReqData;
    if (!endOfResult) {
      reqObj.page += 1;
      jobHistoryServiceCall(reqObj);
      setJobHistoryReqData(reqObj);
    }
  };

  const paginationPrev = () => {
    const reqObj = jobHistoryReqData;
    if (!startOfResult) {
      reqObj.page -= 1;
      jobHistoryServiceCall(reqObj);
      setJobHistoryReqData(reqObj);
    }
  };

  const formatDate = (date) => moment(date).format("YYYY-MM-DD HH:mm:ss");

  useEffect(() => {
    // if (params.id) {
    //     getJobHistoryById(params.id).then(async (response) => {
    //         if (response && response.success && response.data) {
    //             const { data } = response;
    //             setJobHistoryForm(data);
    //         }
    //     });
    // }
    setJobId(params.id);
    getJobHistoryRunTimes().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setJobHistoryRunTimesForm(data);
      }
    });
    getJobHistoryStatuses().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setJobHistoryStatusesForm(data);
      }
    });
    getAllBatchJobConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        const jobs = Array.isArray(data) ? data.map((each) => (
          {
            value: each.id,
            label: each.name,
          }
        )) : [];
        setJobNameList(jobs);
      }
    });
  }, []);

  /**
   *This method is used to handle routing from Job history page to Job listing page
   *
   */
  const handleJobListing = () => {
    history.push("/administration/job-list");
  };

  const [tabView, setTabView] = useState();

  const [selectedTab, setSelectedTab] = useState("JOB_HISTORY");

  const changeTabView = (tab) => {
    setSelectedTab(tab);
    if (tab === "JOB_DETAILS") {
      setTabView(<JobDetailsContainer />);
    }
    if (tab === "JOB_STEPS") {
      setTabView(<JobTaskViewContainer canModify />);
    }
    if (tab === "JOB_HISTORY") {
      setTabView();
    }
  };

  const onClickRow = (row) => {
    const { id } = row;
    setRowData(row);
    setSelectedJobId(id);
  };
  const onHandleBack = (id) => {
    setSelectedJobId(id);
  };

  if (selectedJobId) {
    return (
      <JobAuditDetailsContainer
        onHandleBack={onHandleBack}
        selectedJobId={selectedJobId}
        rowData={rowData}
        formatDate={formatDate}
      />
    );
  }
  return (
    <>
      <JobNavigationHeader
        selectedTab={selectedTab}
        changeTabView={changeTabView}
        onJobListing={handleJobListing}
      />
      {tabView}
      {selectedTab === "JOB_HISTORY" && (
        <JobHistoryConfiguration
          onJobListing={handleJobListing}
          jobId={jobId}
          jobHistoryData={jobHistoryData}
          jobHistoryRunTimes={jobHistoryRunTimesForm}
          jobHistoryStatuses={jobHistoryStatusesForm}
          jobNameList={jobNameList}
          onChange={onChange}
          setFromDate={setFromDate}
          setToDate={setToDate}
          search={search}
          startOfResult={startOfResult}
          endOfResult={endOfResult}
          onClickRow={onClickRow}
          paginationPrev={paginationPrev}
          paginationNext={paginationNext}
        />
      )}
    </>
  );
};

export default JobHistoryConfigurationContainer;
