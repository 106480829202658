/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Anagha
 */

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ===== COMPONENTS  =========== */
import FulfillmentMethod from "../components/fulfillmentMethod";

/** ===== API SERVICE FUNCTIONS =========== */

import { getFulfillmentMethodConfigurations, activateFulfillmentMethod, deactivateFulfillmentMethod } from "../../../api/fulfillmentMethodService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const FulfillmentMethodContainer = () => {
  const history = useHistory();

  /** local states */
  const [
    fulfillmentMethodConfigurations,
    setFulfillmentMethodConfigurations,
  ] = useState([]);

  useEffect(() => {
    getFulfillmentMethodConfigurations().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setFulfillmentMethodConfigurations(data);
      }
    });
  }, []);

  const onHandleEditFulfillment = (id) => {
    history.push(`/operations/fulfillment-method/edit/${id}`);
  };

  const updateStatusFlag = (active, id) => {
    if (active) {
      activateFulfillmentMethod(id).then(async (response) => {
        if (response && response.success) {
          const newFulfillmentData = fulfillmentMethodConfigurations.map((fulfillment) => (fulfillment.id === id
            ? ({ ...fulfillment, active }) : fulfillment));
          setFulfillmentMethodConfigurations(newFulfillmentData);
        }
      });
    } else {
      deactivateFulfillmentMethod(id).then(async (response) => {
        if (response && response.success) {
          const newFulfillmentData = fulfillmentMethodConfigurations.map((fulfillment) => (fulfillment.id === id
            ? ({ ...fulfillment, active }) : fulfillment));
          setFulfillmentMethodConfigurations(newFulfillmentData);
        }
      });
    }
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_OPERTNS_FULFILMNT_MTHD_VIEW_LIST}
    >
      <FulfillmentMethod
        fulfillmentMethodConfigurations={fulfillmentMethodConfigurations}
        onHandleEditFulfillment={onHandleEditFulfillment}
        onStatusChange={updateStatusFlag}
      />
    </Permission>
  );
};
export default FulfillmentMethodContainer;
