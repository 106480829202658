/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 * */

import httpApi from "./httpApi";

/**
 * This method is used  to get list of  customer groups
 * */

const getCustomerGroups = async (searchTerm = "", salesCompany = "", page = 0, size = 10, sortBy = null, sortDirection = null, active = false, cancelToken) => {
  const response = await httpApi.get("v1/customer-groups", {
    params: {
      searchTerm, salesCompany, active, page, size, sortBy, sortDirection,
    },
    cancelToken,
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to create new customer Group
 * @param {Object} data
 * @returns {Object}
 */
const addCustomerGroup = async (data) => {
  const response = await httpApi.post("v1/customer-groups", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used  to get customers
 * */

const getCustomers = async (searchTerm, cancelToken) => {
  const response = await httpApi.get("v1/b2b/department/search", {
    params: {
      searchTerm,
    },
    cancelToken,
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method used to change status of customer group
 * @param {String} id
 */
const setStatus = async (id) => {
  const response = await httpApi.patch(`v1/customer-groups/${id}/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to delete a customer group
 * @param {String} id
 */
const deleteUserGroup = async (id) => {
  const response = await httpApi.delete(`v1/customer-groups/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to get customer group details by id
 * @param  id
 */

const getCustomerGroupById = async (id) => {
  const response = await httpApi.get(`v1/customer-groups/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to update customer group details
 * @param {Object} data
 */
const updateCustomerGroup = async (data) => {
  const response = await httpApi.patch("v1/customer-groups", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used  to get customers by ids
 * */

const getCustomersByIds = async (ids) => {
  const response = await httpApi.post("v1/b2b/department/all/ids", ids);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to transform Customers data
 * @param Array
 * @returns {Array}
 */
const transformCustomerData = async (customers) => {
  const filtered = customers.map(
    ({ name, email, uniqueId }) => ({
      Name: name,
      Email: email,
      uniqueId,
    }),
  );
  return filtered;
};
const getAllSalesCompany = async () => {
  const response = await httpApi.get("/v1/b2b/organization/sales-company");
  if (response && response.data) {
    return response.data;
  }
  return null;
};
export {
  getCustomerGroups,
  addCustomerGroup,
  getCustomers,
  deleteUserGroup,
  getCustomerGroupById,
  updateCustomerGroup,
  setStatus,
  getCustomersByIds,
  transformCustomerData,
  getAllSalesCompany,
};
