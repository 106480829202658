/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * MobileAppCustomization
 *
 * @author Naseef O
 *
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import styles from "../../pageCustomization/css/PageCustomization.module.css";
import Dropdown from "../../../common/core/Dropdown";
import Spinner from "../../../common/core/Spinner";
import edit from "../../../common/assets/images/svg/edit.svg";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";

/** ========== SUB COMPONENT ================ */
import CategorySelector from "../../pageCustomization/containers/CategorySelectorContainer";
import WidgetContentsWrapper from "../../pageCustomization/containers/WidgetContentWrapperContainer";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

const MobileAppCustomization = (props) => {
  const history = useHistory();
  const {
    pageConfiguration,
    isEdit,
    selectedWidget,
    setWidgetTitle,
    widgetTitle,
    handleEditWidget,
    handleSaveTitle,
    isLoading,
    handleCancelTitle,
    toggleDropdown,
    dropdownOpen,
    handleSelectPage,
    selectedPage,
    categoryId,
    handleParentCategory,
    seoInformation,
    categoryLabel,
    brands,
    selectedBrand,
    handleSelectBrand,
    dropdownBrand,
    toggleDropdownBrand,
    handlePushToLive,
    pushToLiveEnabled,
    message,
    widgetConfigurations,
    toggleActiveWidget,
    setCategoryLabel,
    locale,
    getReplicationStatus,
    localeHandler,
  } = props;

  const pages = [
    { name: "Home", PageTypes: "HOME" },
  ];
  const { t } = useTranslation(["common", "page-customization"]);

  const getSelectedPage = (type) => {
    const newSelectedPage = pages.find((page) => page.PageTypes === type);
    return newSelectedPage.name;
  };

  /**
  *This method is used to handle redirect to WidgetContentEditor
  *
  */
  const handleRedirectToWidgetContentEditor = (widgetType, configId) => {
    history.push(`/merchandising/mobile-app-customization/content-editor?widgetType=${widgetType}&&configId=${configId}&&categoryId=${categoryId}&&pageType=${selectedPage}&&channel=mobile&&locale=${locale}`);
  };

  /**
  *This method is used to handle redirect to SEOInfoEditor
  *
  */
  const handleRedirectToSEOInfoEditor = () => {
    history.push(`/merchandising/mobile-app-customization/content-seo-editor?categoryId=${categoryId}&&pageType=${selectedPage}&&channel=mobile`);
  };
  /**
  *This method is used to handle redirect to Featured products editor
  *
  */
  const handleRedirectToProductEditor = (widgetType) => {
    history.push(`/merchandising/mobile-app-customization/content-product-editor?categoryId=${categoryId}&&pageType=${selectedPage}&&widgetType=${widgetType}&&channel=mobile`);
  };

  const widgets = pageConfiguration && pageConfiguration.widgets;
  const channel = "mobile";
  return (
    < >
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className=" common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText"> Mobile App Customization</span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12" md="4">
          <Row className="m-0">
            <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}> Page Selection</span>
          </Row>
          <Row className="m-0">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              className={styles.pageSelection}
            >
              <DropdownToggle caret>
                <span>
                  {getSelectedPage(selectedPage)}
                </span>
              </DropdownToggle>
              <DropdownMenu className={styles.dropdownWrapper}>
                {pages.map((page) => (
                  <div
                    key={`PAGE-${page.PageTypes}`}
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className={styles.dropdownItem}
                    onClick={() => handleSelectPage(page.PageTypes)}
                  >
                    {page.name}
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Row>
        </Col>
        {selectedPage === "BLP" && brands && brands.length > 0 && (
          <Col xs="12" md="4">
            <Row className="m-0">
              <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>Brand</span>
            </Row>
            <Row className="m-0">
              <Dropdown
                isOpen={dropdownBrand}
                toggle={toggleDropdownBrand}
                className={styles.pageSelection}
              >
                <DropdownToggle caret>
                  <span>
                    {selectedBrand.name || "Select a brand"}
                  </span>
                </DropdownToggle>
                <DropdownMenu className={styles.dropdownWrapper}>
                  {brands.map((brand) => (
                    <div
                      key={`PAGE-${brand.id}`}
                      onKeyPress={() => { }}
                      role="button"
                      tabIndex="0"
                      className={styles.dropdownItem}
                      onClick={() => handleSelectBrand(brand)}
                    >
                      {brand.name}
                    </div>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Row>
          </Col>
        )}
        <Col xs="12" md="4">
          {(selectedPage === "CLP" || selectedPage === "PLP")
            && (
              <>
                <Row className="m-0">
                  <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}> Select a category</span>
                </Row>
                <Row className="m-0">
                  <CategorySelector
                    parentCategory={categoryId}
                    handleParentCategory={handleParentCategory}
                    categoryLabel={categoryLabel}
                    setCategoryLabel={setCategoryLabel}
                  />
                </Row>
              </>
            )}
        </Col>
        <Col xs="12" md="4" className={styles.liveBtnWrapper}>
          <Row>
            {pushToLiveEnabled && (
              <Col xs="12" md="6">
                <Button
                  className={clsx(styles.liveBtn, " bg-color-tertiary-main ")}
                  onClick={() => handlePushToLive()}
                >
                  {t("common:button_push_to_live")}
                </Button>
              </Col>
            )}
            <Col xs="12" md="6" lg="6" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(
                    styles.liveBtn,
                    "float-right",
                    "text-truncate",
                    "btn-tertiary",
                    "button-font-size",
                  )}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        {/* <Col xs="6" className={styles.pagePreview}>
          <Row>
            <Col xs="12">
              <span className={clsx(styles.pagePreviewText, "fontsizeMediumBold")}>
                Default
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <img src={homepage} alt="" className={styles.selectedPageImg} width="73px" />
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7">
          <LocaleDropdownContainer
            isIndocument={false}
            entityName="pageContent"
            localeHandler={localeHandler}
          />
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          {pageConfiguration && pageConfiguration.widgets
            && Object.keys(pageConfiguration.widgets).map((key, index) => (
              <WidgetContentsWrapper
                pageConfiguration={pageConfiguration}
                widgets={widgets}
                index={index}
                isEdit={isEdit}
                selectedWidget={selectedWidget}
                widgetTitle={widgetTitle}
                setWidgetTitle={setWidgetTitle}
                handleEditWidget={handleEditWidget}
                widgetType={key}
                handleSaveTitle={handleSaveTitle}
                handleCancelTitle={handleCancelTitle}
                categoryId={categoryId}
                configId={pageConfiguration.id}
                selectedPage={selectedPage}
                handleRedirectToWidgetContentEditor={handleRedirectToWidgetContentEditor}
                handleRedirectToProductEditor={handleRedirectToProductEditor}
                channel={channel}
                widgetConfigurations={widgetConfigurations}
                toggleActiveWidget={toggleActiveWidget}
                locale={locale}
              />

            ))}
          {!isLoading && (
            <Row className="h-100">
              <Col xs="12" className={styles.spinnerWrapper}>
                <div className="h-100  d-flex align-items-center justify-content-center">
                  <Spinner />
                </div>
              </Col>
            </Row>
          )}
          {seoInformation && (
            <Row className={clsx(styles.block3)}>
              <Col xs="12" className="">
                <Row>
                  <Col xs="8" className=" d-flex justify-content-start">
                    <span className={clsx(styles.pageWidgetsText, "fontsizeMediumBold")}>
                      SEO INFORMATION
                    </span>
                    <img
                      src={edit}
                      alt="edit"
                      width="20px"
                      role="presentation"
                      className="pointer"
                      onClick={() => handleRedirectToSEOInfoEditor()}
                    />
                  </Col>
                </Row>
                <Row className="m-0 ">
                  <Col xs="12" className="pt-2">
                    <Row>
                      <Col xs="12" className={styles.seoText}>
                        <span>Page Title:</span>
                        <span className={styles.value}>{seoInformation.pageTitle ? seoInformation.pageTitle : ""}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" className={styles.seoText}>
                        <span>Meta Keywords:</span>
                        <span className={styles.value}>{seoInformation.metaKeywords ? seoInformation.metaKeywords : ""}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" className={styles.seoText}>
                        <span>Meta Description:</span>
                        <span className={styles.value}>{seoInformation.metaDescription ? seoInformation.metaDescription : ""}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

MobileAppCustomization.propTypes = {
  pageConfiguration: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  selectedWidget: PropTypes.number.isRequired,
  setWidgetTitle: PropTypes.func.isRequired,
  widgetTitle: PropTypes.string.isRequired,
  handleEditWidget: PropTypes.func.isRequired,
  handleSaveTitle: PropTypes.func.isRequired,
  handleCancelTitle: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  handleSelectPage: PropTypes.func.isRequired,
  selectedPage: PropTypes.string.isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  seoInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  categoryLabel: PropTypes.string.isRequired,
  brands: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSelectBrand: PropTypes.func.isRequired,
  dropdownBrand: PropTypes.bool.isRequired,
  toggleDropdownBrand: PropTypes.func.isRequired,
  selectedBrand: PropTypes.string.isRequired,
  widgetConfigurations: PropTypes.arrayOf(PropTypes.any).isRequired,
  setCategoryLabel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default MobileAppCustomization;
