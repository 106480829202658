/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TaxClasses from "../components/TaxClasses";
import { getTaxClassByRetailerAndBrand, deleteTaxClass } from "../../../api/taxConfigurationService";
import {
  getTaxClassByRetaileranBrand,
  saveTaxClass,
  setTaxClassStatus,
} from "../../../api/taxServices";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";


const TaxClassesContainer = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const submitting = false;
  //   const [editable, setEditable] = useState(false);
  //   const [deleteSelectedTaxClass, setDeleteSelectedTaxclass] = useState(null);
  //   const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTax, setDeleteTax] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);
  const [data, setData] = useState([]);


  /**
   * This method used to change tax class status
   * @param {String} id
   * @param {Boolean} status
   */
  const changeTaxClassStatus = async (status, id) => {
    if (submitting) return;
    setSubmit(true);
    setSelectedDataId(id);
    const response = await setTaxClassStatus(id);
    if (response && response.success) {
      const newData = data.map((tax) => (tax.id === id
        ? ({ ...tax, active: status }) : tax));
      setData(newData);
    }
    setSelectedDataId(null);
    setSubmit(false);
  };


  const onHandleNewClass = () => {
    setIsOpen(!isOpen);
    setName("");
    setCode("");
    setDescription("");
  };

  useEffect(() => {
    setTaxClassDetails();
  }, []);


  const setTaxClassDetails = () => {
    getTaxClassByRetaileranBrand(
      "5e411b264d50307e272ea9ed",
      "5e411c234d50307e272eaa05",
    ).then(async (response) => {
      if (response && response.success && response.data) {
        setData(response.data);
      }
    });
  };
  const onHandleEditClass = (id) => {
    // setEditable(true);
    history.push(`/administration/tax-class/edit/${id}`);
  };

  const cancelConfirm = () => {
    // setDeleteSelectedTaxclass(null);
  };
  const onHandleDeleteModalOpen = async (id) => {
    setDeleteTax({ id });
    // if (id) {
    //   setDeleteModalOpen(true);
    // }
  };


  const onHandleDeleteTaxClass = async () => {
    if (Boolean(deleteTax) && deleteTax.id) {
      //   setEditable(true);
      await deleteTaxClass(deleteTax.id);
      getTaxClassByRetailerAndBrand("5e411b264d50307e272ea9ed",
        "5e411c234d50307e272eaa05").then(async (response) => {
          if (response && response.success && response.data) {
            setData(response.data);
          }
        });
      setDeleteTax(null);
    }
  };

  //   const cancelForm = () => {
  //     history.push("/administration/tax-class");
  //   };


  /**
   * This function is used to submit the from
   * @param {Event} event
   */
  const submitForm = async () => {
    const requestBody = {
      name,
      code,
      description,
      retailer: "5e411b264d50307e272ea9ed",
      brand: "5e411c234d50307e272eaa05",
    };
    const response = await saveTaxClass(requestBody);
    if (response && response.success && response.data) {
      setIsOpen(!isOpen);
      data.push({ ...requestBody, ...response.data });
      history.push("/administration/tax-class");
    }
  };

  return (
    <>
      <Permission
        allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_CLS_VIEW_LIST}
      >
        <TaxClasses
          onHandleNewClass={onHandleNewClass}
          isOpen={isOpen}
          setName={setName}
          setCode={setCode}
          setDescription={setDescription}
          data={data}
          onHandleEditClass={onHandleEditClass}
          onHandleDeleteModalOpen={onHandleDeleteModalOpen}
          handletaxStatus={changeTaxClassStatus}
          submitting={submitting}
          submitForm={submitForm}
          name={name}
          submit={submit}
          selectedDataId={selectedDataId}
        />
        <ConfirmationModal
          isOpen={Boolean(deleteTax)}
          toggleOpen={cancelConfirm}
          togglClose={cancelConfirm}
          handleConfirm={onHandleDeleteTaxClass}
          content=" Are you sure you want to delete this tax class?"
        />
      </Permission>
    </>

  );
};

export default TaxClassesContainer;
