/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Harikrishnan A K
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/FacetEditor.module.css";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import validateForm from "../../../common/utils/validateForm";

const FacetEditor = ({
  onHandleCancel, singleFacetObject, handleSave, handleFormChange,
}) => {
  const methods = validateForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    validateCriteriaMode: "all",
  });
  const { handleSubmit, register, errors } = methods;
  const { t } = useTranslation(["common", "tax-configuration", "facet-customization"]);
  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)}>
        <fieldset>
          <Row className="common-page-card pageHeader">
            <Col xs="12">
              <span className="pageText">{t("facet-customization:title_facet_editor")}</span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "common-page-card ")}>
            <Col xs="12">
              <Row className="m-0 ">
                <Col xs="6" className="p-0">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    {t("facet-customization:label_facet_details")}

                  </span>
                </Col>
                <Col className="mb-2" xs="6" sm>
                  <div className={styles.areaActionRight}>
                    <div>
                      <Button
                        color="secondary"
                        className={clsx(
                          styles.borderRadius2,
                          styles.btn,
                          styles.secondaryColor,
                          "text-uppercase",
                          "text-truncate",
                          "btn-tertiary",
                          "ml-1",
                        )}
                        onClick={onHandleCancel}
                      >
                        {t("common:button_cancel")}
                      </Button>
                      <Button
                        color="primary"
                        className={clsx(
                          styles.borderRadius2,
                          "text-uppercase",
                          "text-truncate",
                          "btn-tertiary",
                          "ml-1",
                        )}
                        type="submit"
                      >
                        {t("common:button_save")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("facet-customization:label_facet_field_name")}</Label>
                        <Input
                          className="formText"
                          value={singleFacetObject.field}
                          name="field"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("facet-customization:validation_label_field"),
                            })
                          }
                          invalid={!!(errors.field && errors.field.message)}
                        />
                        <FormFeedback>
                          {errors.field && errors.field.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("facet-customization:label_path")}</Label>
                        <Input
                          className="formText"
                          value={singleFacetObject.path}
                          name="path"
                          disabled
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("facet-customization:validation_label_path"),
                            })
                          }
                          invalid={!!(errors.path && errors.path.message)}
                        />
                        <FormFeedback>
                          {errors.path && errors.path.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("facet-customization:label_noOfItemsToShow")}</Label>
                        <Input
                          className="formText"
                          value={singleFacetObject.noOfItemsToShow}
                          name="noOfItemsToShow"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("facet-customization:validation_label_noOfItemsToShow"),
                            })
                          }
                          invalid={!!(errors.noOfItemsToShow && errors.noOfItemsToShow.message)}
                        />
                        <FormFeedback>
                          {errors.noOfItemsToShow && errors.noOfItemsToShow.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                        <Label title={t("facet-customization:label_active")} className="formTextLabel text-truncate">{t("facet-customization:label_active")}</Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-1",
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="facet-active"
                            name="active"
                            checked={singleFacetObject.active}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <Label className="custom-control-label" htmlFor="facet-active" />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                        <Label title={t("facet-customization:label_searchable")} className="formTextLabel text-truncate">{t("facet-customization:label_searchable")}</Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-1",
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="facet-searchable"
                            name="searchable"
                            checked={singleFacetObject.searchable}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <Label className="custom-control-label" htmlFor="facet-searchable" />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col xs="6">
                          <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                            <Label title={t("facet-customization:label_disableCollapse")} className="formTextLabel text-truncate">{t("facet-customization:label_disableCollapse")}</Label>
                            <div
                              className={clsx(
                                "custom-control",
                                "custom-switch",
                                "ml-1",
                              )}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="facet-disableCollapse"
                                name="disableCollapse"
                                checked={singleFacetObject.disableCollapse}
                                onChange={(e) => handleFormChange(e)}
                              />
                              <Label className="custom-control-label" htmlFor="facet-disableCollapse" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                            <Label title={t("facet-customization:label_expandedByDefault")} className="formTextLabel text-truncate">{t("facet-customization:label_expandedByDefault")}</Label>
                            <div
                              className={clsx(
                                "custom-control",
                                "custom-switch",
                                "ml-1",
                              )}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="facet-expandedByDefault"
                                name="expandedByDefault"
                                disabled={singleFacetObject.disableCollapse}
                                checked={singleFacetObject.expandedByDefault}
                                onChange={(e) => handleFormChange(e)}
                              />
                              <Label className="custom-control-label" htmlFor="facet-expandedByDefault" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("facet-customization:label_displayOrder")}</Label>
                        <Input
                          className="formText"
                          value={singleFacetObject.displayOrder}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("facet-customization:label_code")}</Label>
                        <Input
                          className="formText"
                          value={singleFacetObject.code}
                          name="code"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>

    </>
  );
};

FacetEditor.propTypes = {
  onHandleCancel: PropTypes.func.isRequired,
  singleFacetObject: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
};

export default FacetEditor;
