/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Midhun Murali
 */

import React, { useState, useEffect } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import styles from "../css/Status.module.css";
import clsx from "clsx";
import { Tooltip } from "reactstrap"


const Preface = ({ index, attributes, title, textValue, icon, color, isStringNumeric, orginalText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <Col key={index} className={clsx("w-16px  bg-white shadow-sm rounded mx-3 d-inline-flex px-4 py-4")}>
      <Row className="">
        <Col className={clsx("col-auto justify-content-center p-2")} >
          <div className={styles.statusIcon} style={{ background: color }}>
            <img src={icon} alt="" width={16} />
          </div>
        </Col>
        <Col className={clsx("col-auto justify-content-center ml-2")} >
          <Row >
            <span>
              {attributes?.currencyCode &&
                <span className={styles.currencyCode}>
                  {attributes?.currencyCode + " "}
                </span>
              }
              <span className={clsx(title ? isStringNumeric(title) ? styles.numberTitle : styles.textTitle : styles.errorTitle)}>
                {title}
              </span>
            </span>
            {orginalText && orginalText.length > 19 && !isStringNumeric(title) &&
              <Tooltip
                isOpen={tooltipOpen}
                placement="right"
                target="tooltip"
                toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                {orginalText}
              </Tooltip>}
          </Row>
          <Row className={styles.description}>
            <span >{textValue}</span>
          </Row>
        </Col>
      </Row >
    </Col >
  );
}
export default Preface;