/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */


import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Card from "../../../common/core/Card";
import CardBody from "../../../common/core/CardBody";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Form from "../../../common/core/Form";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";

/** ========= SUB COMPONENT ========= */

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/Select";
import DateRangePicker from "../../../common/components/DateRangePicker";
import validateForm from "../../../common/utils/validateForm";

/** ========= API SERVICE FUNCTIONS ========= */
/** ============ SVG IMAGE ICON ===================== */

/** ========= MODULE STYLES ========= */
import styles from "../css/CampaignStepOneView.module.css";

const CampaignStepOneView = (props) => {
  const {
    name, description, active, startDate, endDate, onSubmitting, indefinite, update,
    handleFormChange, formSubmitHandler, formCancelHandler, translatableFileds, selectedLanguage,
  } = props;
  /** internationalization using i18n from common and campaign  */
  const { t } = useTranslation(["common", "campaign"]);
  /** validation methods */
  const { register, errors, handleSubmit } = validateForm();

  return (
    <Row className="justify-content-center text-body">
      <Col sm="12" md="10" xl="8">
        <div className="text-center">
          <h4 className="text-body font-weight-bolder">{t("campaign:title_basic_info")}</h4>
        </div>
        <div className="text-center">
          <p className="font-weight-normal pt-2 pb-1">
            {t("campaign:sub_title_basic_info")}
          </p>
        </div>
        <div>
          <Form className={styles.form} onSubmit={handleSubmit(formSubmitHandler)}>
            <fieldset disabled={onSubmitting}>
              <Card>
                <CardBody className="p-3">
                  <FormGroup>
                    <Label className={styles.formTextLabel}>
                      {t("campaign:label_name")}
                      <span className="pl-1 text-danger">*</span>
                    </Label>
                    <Input
                      defaultValue={name}
                      placeholder={t("campaign:placeholder_name")}
                      name="name"
                      onChange={handleFormChange}
                      disabled={Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("name") : false}
                      innerRef={
                        register({
                          required: t("common:validation_name"),
                          minLength: {
                            value: 3,
                            message: t("common:validation_min_three-letter"),
                          },
                          maxLength: {
                            value: 252,
                            message: t("common:validation_too_large"),
                          },
                        })
                      }
                      invalid={!!(errors.name && errors.name.message)}
                    />
                    <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className={styles.formTextLabel}>
                      {t("common:label_description")}
                    </Label>
                    <Input
                      value={description}
                      type="textarea"
                      placeholder={t("campaign:placeholder_description")}
                      name="description"
                      onChange={handleFormChange}
                      disabled={Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("description") : false}
                      innerRef={
                        register({
                          maxLength: {
                            value: 2499,
                            message: t("campaign:validation_too_large"),
                          },
                        })
                      }
                      invalid={!!(errors.description && errors.description.message)}
                    />
                    <FormFeedback>{errors.description && errors.description.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className={styles.formTextLabel}>
                      {t("common:label_active")}
                      <span className="pl-1 text-danger">*</span>
                    </Label>
                    <Select
                      className="formText"
                      placeholder={t("customerGroup:placeholder_enabled")}
                      name="active"
                      onChange={handleFormChange}
                      value={active}
                      options={[
                        { value: 1, selected: active, label: "Yes" },
                        { value: 0, selected: !active, label: "NO" },
                      ]}
                      disabled={Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("active") : false}
                    />
                  </FormGroup>
                  <FormGroup>
                    <DateRangePicker
                      labelClass={styles.formTextLabel}
                      startDateProps={{
                        label: "Start date",
                        value: startDate,
                        name: "startDate",
                        disabled: Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("startDate") : false,
                      }}
                      endDateProps={{
                        label: "End date",
                        noEndDate: true,
                        value: endDate,
                        name: "endDate",
                        disabled: Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("endDate") : false,
                      }}
                      indefinite={indefinite}
                      onChange={handleFormChange}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
              <Card className="mt-3">
                <CardBody className="p-3">
                  <FormGroup className="mb-0">
                    <Row noGutters>
                      <Col>
                        <Button onClick={formCancelHandler} type="button" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size")}>
                          {t("campaign:button_cancel")}
                        </Button>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "button-font-size")}>
                          {t(`campaign:${update ? "button_update_continue" : "button_save_continue"}`)}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </fieldset>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

CampaignStepOneView.defaultProps = {
  startDate: null,
  endDate: null,
};

CampaignStepOneView.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  active: PropTypes.number.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any),
  endDate: PropTypes.objectOf(PropTypes.any),
  onSubmitting: PropTypes.bool.isRequired,
  indefinite: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  // functions
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
  formCancelHandler: PropTypes.func.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default CampaignStepOneView;
