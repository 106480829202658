/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * PasswordReset Component
 * @author Harikrishnan A K
 */
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// SVG icons import
import invalidIcon from "../../../common/assets/images/svg/alert-error.svg";
import successIcon from "../../../common/assets/images/svg/check-solid-green.svg";
import eyeIcon from "../../../common/assets/images/svg/eye.svg";
import Button from "../../../common/core/Button";
import Card from "../../../common/core/Card";
import Col from "../../../common/core/Col";
import Container from "../../../common/core/Container";
import Form from "../../../common/core/Form";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import Row from "../../../common/core/Row";
import validateForm from "../../../common/utils/validateForm";
import ValidationConstants from "../../../common/utils/validationConstants";
import Modal from "./MessageModal";
/** ================== MODULE STYLES ================ */
import styles from "../css/PasswordReset.module.css";


const PasswordReset = (props) => {
  const { t } = useTranslation(["login"]);
  const {
    resetForm,
    handleChange,
    handleSubmit: handleSubmitForm,
    modal,
    closeModal,
  } = props;
  const methods = validateForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    validateCriteriaMode: "all",
  });
  const { handleSubmit, register, errors } = methods;
  const { IS_ADMIN_RESET_PASSWORD } = ValidationConstants;
  const [showPassword, setToggle] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const togglePassword = () => {
    setToggle(!showPassword);
  };

  const InvalidIcon = () => (
    <img src={invalidIcon} alt="invalid icon" />
  );

  const SuccessIcon = () => (
    <img src={successIcon} alt="invalid icon" width="24" height="24" />
  );

  const RoundedIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#EEE" />
    </svg>

  );

  return (
    <>
      <Modal modal={modal} closeModal={closeModal} />
      <Container className={styles.loginWrapper1}>
        <Card className={clsx(
          "loginWrapper",
          styles.passwordResetFormWrapper,
        )}
        >
          <h3>{t("login:reset_password_page_title")}</h3>
          <div className="loginContent">

            <Form onSubmit={handleSubmit(handleSubmitForm)}>
              <Label>
                {t("login:label_new_password")}
                <i className="text-danger">*</i>
              </Label>
              <div
                className={clsx(
                  styles.viewPassword,
                  "pt-2",
                )}
              >
                <Input
                  name="password"
                  label="Enter New Password"
                  value={resetForm.password}
                  onChange={handleChange}
                  onKeyPress={(e) => {
                    const key = e.keyCode || e.charCode;
                    if (key === 13) {
                      handleChange(e);
                    }
                  }}
                  size="lg"
                  required
                  type={showPassword ? "text" : "password"}
                  innerRef={
                    register({
                      required: t("login:error_no_password"),
                      minLength: {
                        value: 8,
                        message: t("login:error_atleast_8_chars"),
                      },
                      pattern: {
                        value: IS_ADMIN_RESET_PASSWORD,
                        message: t("login:error_atleast_one_number_letter"),
                      },
                    })
                  }

                  invalid={!!(errors.password && errors.password.message)}
                />
                <img onClick={() => togglePassword()} className={styles.iconShowPassword} alt="eye" height="10rem" width="10rem" src={eyeIcon} />
              </div>

              <Row className="mt-2 materialForm">
                <Col md="12">
                  {resetForm.password
                    ? (
                      <>
                        {errors.password && errors.password.types.minLength ? (
                          <span><InvalidIcon /></span>
                        )
                          : (
                            <span>
                              <SuccessIcon />
                            </span>
                          )}
                      </>
                    )
                    : <span><RoundedIcon /></span>}
                  <span className=" error ml-2 ">{t("login:char_validation_msg")}</span>
                </Col>
              </Row>
              <Row className="materialForm">
                <Col md="12">
                  {resetForm.password
                    ? (
                      <>
                        {" "}
                        {errors.password && errors.password.types.pattern ? (
                          <span><InvalidIcon /></span>
                        )
                          : (
                            <span><SuccessIcon /></span>
                          )}
                      </>
                    )
                    : <span><RoundedIcon /></span>}
                  <span className=" error ml-2 ">{t("login:contain_num_validation_msg")}</span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Label>
                    {t("login:label__confirm_password")}
                    <i className="text-danger">*</i>
                  </Label>
                  <div className="view-password">
                    <Input
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      onKeyPress={(e) => {
                        const key = e.keyCode || e.charCode;
                        if (key === 13) {
                          handleConfirmPasswordChange(e);
                        }
                      }}
                      size="lg"
                      required
                      type={showPassword ? "text" : "password"}
                      isRightContent
                      innerRef={
                        register({
                          required: "Please Confirm your password",
                          pattern: {
                            value: RegExp(resetForm.password),
                            message: "passwords mismatch",
                          },
                        })
                      }
                      invalid={!!(errors.confirmPassword && errors.confirmPassword.message)}
                    />
                    <Button onClick={() => togglePassword()} color="view">
                      <i className="icon-show-password" />
                    </Button>
                  </div>
                  <Label className="text-danger">
                    {errors.confirmPassword && errors.confirmPassword.types.pattern && t("login:error_mismatch_password")}
                  </Label>

                </Col>
              </Row>
              <Row className="mt-2">
                <Col md="12" className="px-3">
                  <Button type="submit" color="primary" className="w-100">{t("login:save_changes")}</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
      </Container>
    </>
  );
};

PasswordReset.propTypes = {
  resetForm: PropTypes.objectOf(PropTypes.any).isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PasswordReset;
