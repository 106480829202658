import React from "react";
import EyeDark from "../../../common/assets/images/svg/eye-dark.svg";
import Home from "../../../common/assets/images/svg/home.svg";
import SearchDark from "../../../common/assets/images/svg/search-dark.svg";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import CustomInput from "../../../common/core/CustomInput";
import DropdownItem from "../../../common/core/DropdownItem";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import Input from "../../../common/core/Input";
import Pagination from "../../../common/core/Pagination";
import PaginationItem from "../../../common/core/PaginationItem";
import PaginationLink from "../../../common/core/PaginationLink";
import Row from "../../../common/core/Row";
import Table from "../../../common/core/Table";
import UncontrolledDropdown from "../../../common/core/UncontrolledDropdown";
import styles from "../css/B2BCustomerListUI.module.css";

const B2BCustomerListUI = (props) => {
  console.log("styles", styles);
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText"><strong>B2B Customer</strong></span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/#">
                <img src={Home} alt="Home" />
                Home
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem><a href="/#">Administration</a></BreadcrumbItem>
            <BreadcrumbItem><a href="/#">User Management</a></BreadcrumbItem>
            <BreadcrumbItem active>B2B Customer</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <Row className="justify-content-between align-items-center mb-2">
            <Col xs="auto">
              <h5 className="mb-0"><strong>Manage B2B Customer</strong></h5>
            </Col>
            <Col xs="auto">
              <div className={styles.SearchCol}>
                <Input placeholder="Search B2B Customer" />
                <Button><img src={SearchDark} alt="Home" /></Button>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs="auto" className="ml-auto">
              <UncontrolledDropdown className="dropdown-custom">
                <DropdownToggle caret color="light" size="sm">
                  Export as
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Excel</DropdownItem>
                  <DropdownItem>Word</DropdownItem>
                  <DropdownItem>PDF</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="12">
              <Table hover responsive className="table-vcenter">
                <thead>
                  <tr>
                    <th width="25">
                      <CustomInput type="checkbox" id="customCheckbox" />
                    </th>
                    <th>Customer ID</th>
                    <th>Customer</th>
                    <th>Invoice Account</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-items-center">
                      <CustomInput type="checkbox" id="customCheckbox1" />
                    </td>
                    <td>Customer ID 1</td>
                    <td>Mark Fashion</td>
                    <td>AC19810608</td>
                    <td>b2buseremail@mail.com</td>
                    <td className="align-top">
                      <CustomInput type="switch" id="customSwitch" name="customSwitch" />
                    </td>
                    <td>
                      <Button color="transparent" className="btn-square">
                        <img src={EyeDark} alt="Home" />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-items-center">
                      <CustomInput type="checkbox" id="customCheckbox2" />
                    </td>
                    <td>Customer ID 2</td>
                    <td>Mark Fashion</td>
                    <td>AC19810608</td>
                    <td>b2buseremail@mail.com</td>
                    <td className="align-top">
                      <CustomInput type="switch" id="customSwitch1" name="customSwitch" />
                    </td>
                    <td>
                      <Button color="transparent" className="btn-square">
                        <img src={EyeDark} alt="Home" />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-items-center">
                      <CustomInput type="checkbox" id="customCheckbox3" />
                    </td>
                    <td>Customer ID 3</td>
                    <td>Mark Fashion</td>
                    <td>AC19810608</td>
                    <td>b2buseremail@mail.com</td>
                    <td className="align-top">
                      <CustomInput type="switch" id="customSwitch2" name="customSwitch" />
                    </td>
                    <td>
                      <Button color="transparent" className="btn-square">
                        <img src={EyeDark} alt="Home" />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="justify-content-between">
            <Col xs="auto" className="d-flex align-items-center">
              Show
              <UncontrolledDropdown className="dropdown-custom dropdown-number mx-2">
                <DropdownToggle caret color="light" size="sm">
                  10
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>15</DropdownItem>
                  <DropdownItem>20</DropdownItem>
                  <DropdownItem>25</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              entries
            </Col>
            <Col xs="auto">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled>
                  <PaginationLink previous href="#">
                    Previous
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem active>
                  <PaginationLink href="#">
                    1
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">
                    2
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">
                    3
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink next href="#">
                    Next
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        </Col>
      </Row>

    </>
  );
};

export default B2BCustomerListUI;
