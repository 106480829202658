/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Vishakh
 */

import React from "react";

import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
} from "chart.js";

import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ brandData }) => {
  const data = {
    labels: brandData ? Object.keys(brandData) : [],
    datasets: [{
      label: "# of Votes",
      data: brandData ? Object.values(brandData) : [],
      backgroundColor: [
        "rgba(113, 184, 65, 1)",
        "rgba(240, 170, 70, 1)",
        "rgba(224, 70, 102, 1)",
        "rgba(44, 84, 231, 1)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(113, 184, 65, 1)",
        "rgba(240, 170, 70, 1)",
        "rgba(224, 70, 102, 1)",
        "rgba(44, 84, 231, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    }],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
    },
    legend: {
      labels: {
        padding: 100,
        fontSize: 30,
      },
    },
  };

  return (
    <div>
      <Doughnut
        data={data}
        height={400}
        options={options}

      />
    </div>
  );
};

DoughnutChart.propTypes = {
  brandData: PropTypes.shape({}).isRequired,
};

export default DoughnutChart;
