/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========== */
import ReactPaginate from "react-paginate";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Alert from "../../../common/core/Alert";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownItem from "../../../common/core/DropdownItem";
import Select from "../../../common/components/Select";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
/** ========= CUSTOM COMPONENTS =============== */
import Image from "./Image";

/** ========= SVG IMAGE ICON ============= */
import LoaderIcon from "../../../common/assets/images/svg/loader.svg";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import RefreshIcon from "../../../common/assets/images/svg/refresh-circle.svg";

/** ========= UTILS/CONFIG =============== */

/** ========= MODULE STYLES =============== */
import styles from "../css/ProductManagement.module.css";

/**
 * authorization Rules
 */
import Rules from "../../../common/security/permissionRules";
import Permission from "../../../common/security/Permission";
import Spinner from "../../../common/core/Spinner";
import downloadIconfrom from "../../../common/assets/images/svg/file-upload.svg";

const ProductManagement = (props) => {
  const {
    productData,
    changeProductStatus,
    submitting,
    addNewProduct,
    editProduct,
    searchTerm,
    handleSearchTerm,
    onHandleClear,
    handlePushToLive,
    pushToLiveEnabled,
    message,
    previewSiteUrl,
    previewEnabled,
    pushToLiveButtonEnabled,
    getReplicationStatus,
    viewProduct,
    itemsPerPage,
    handleItemsPerPageChange,
    totalPagesCount,
    onPageChange,
    numberOfElements,
    isProccessing,
    brandId,
    brands,
    handleBrandChange,
    confirmDisableProductModalData,
    handleChangeProductStatus,
    toggleForceIndex,
    handleStatusChange,
    onHandleDownload,
    isDownloadInProgress,
  } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenForDownload, setDropdownOpenForDownload] = useState(false);
  /**
         *This method is used to toogle dropdown
         *
         */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdownForDownload = () => {
    setDropdownOpenForDownload(!dropdownOpenForDownload);
  };

  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader  common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">{t("product:title_manage")}</span>
        </Col>
        <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
          <Row>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                  onClick={() => redirectToPage()}
                >
                  {t("common:button_preview")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, " common-page-card")}>
        <Col xs="12">
          <Row className="m-0">
            <Col xs="8" className="p-0">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("product:sub_title_title_manage")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col className="mb-2" xs="12" sm>
          <div className={styles.areaActionRight}>
            <div className={clsx(styles.searchContainer)}>
              <span
                className="commonPointer"
                title="Refresh"
              >
                <Button
                  color="light"
                  onClick={() => onHandleClear()}
                >
                  <img
                    width="12"
                    src={RefreshIcon}
                    alt="search category"
                    className="mr-2"
                  />
                  <span>{t("product:label_reset")}</span>
                </Button>
              </span>
            </div>
            <div className="px-2">
              <Select
                className="formTextLabel"
                placeholder="select brand"
                name="brand"
                value={brandId}
                onChange={(e) => handleBrandChange(e)}
                options={
                  Array.isArray(brands)
                    ? [{
                      label: "Select brand",
                      disabled: true,
                      value: "",
                    }, ...brands]
                    : [{
                      label: "Select brand",
                      disabled: true,
                      value: "",
                    }]
                }
              >
                <option value="" hidden>{t("product:label_select_brand")}</option>
              </Select>
            </div>
            <div className="px-2">
              <Select
                className="formTextLabel"
                placeholder="select status"
                name="status"
                onChange={(e) => handleStatusChange(e)}
                options={[
                  {
                    label: "Select status",
                    disabled: true,
                    value: "",
                  },
                  { label: "All", value: "ALL" },
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ]}
              >
                <option value="" hidden>{t("product:label_select_status")}</option>
              </Select>
            </div>
            <div className="px-2">
              <InputGroup
                className={clsx(styles.search, "color-tertiary-main")}
              >
                <Input
                  placeholder={t("product:placeholder_search")}
                  value={searchTerm}
                  onChange={handleSearchTerm}
                />
                <InputGroupAddon
                  addonType="append"
                  className="commonPointer bg-color-tertiary-main"
                >
                  <img
                    src={SearchIcon}
                    alt={t("product:placeholder_search")}
                    className={styles.icon}
                  />
                </InputGroupAddon>
              </InputGroup>
            </div>
            {/* <Permission
              allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_CRT_PRDT}
            >
              <div>
                <Button
                  color="primary"
                  onClick={addNewProduct}
                  className={clsx(
                    styles.borderRadius2,
                    "text-uppercase",
                    "text-truncate",
                    "btn-tertiary",
                  )}
                >
                  New Product
                </Button>
              </div>
            </Permission> */}
          </div>
          <Col xs="12">
            {
              numberOfElements ? (
                <Row className="pt-3" align="left" valign="left">
                  <Col xs="8" className="p-0">
                    <span
                      className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                    >
                      {t("product:sub_title_product_count")}
                      {numberOfElements}
                    </span>
                  </Col>
                </Row>
              ) : null
            }
          </Col>
        </Col>
      </Row>
      <Row
        className={clsx(
          styles.block3,
          styles.tableWrapper,
          " common-page-card",
        )}
      >
        <Col>
          <Row className={clsx(styles.block2, " common-page-card")}>
            <Col className="mb-2" xs="12" sm>
              <div className={styles.areaActionRight}>
                <div className="px-2">
                  <Dropdown
                    isOpen={dropdownOpenForDownload}
                    toggle={toggleDropdownForDownload}
                    className={clsx(styles.dropdownBtn, "commonDropDownWrapper")}
                  >
                    <DropdownToggle caret disabled={isDownloadInProgress || productData.length < 1}>
                      <span>{t("common:span_download")}</span>
                      {isDownloadInProgress ? (
                        <img
                          src={LoaderIcon}
                          width="22"
                          alt="Product file downloading"
                          className={styles.icon}
                        />
                      ) : false}
                    </DropdownToggle>
                    {!isDownloadInProgress
                      ? (
                        <DropdownMenu right className="dropdownMenuWrapper">
                          <div
                            onKeyPress={() => { }}
                            role="button"
                            tabIndex="0"
                            className="dropdownMenuItem"
                            onClick={() => onHandleDownload("XLSX")}
                          >
                            {t("common:div_export_format_XLSX")}
                          </div>
                          <div
                            onKeyPress={() => { }}
                            role="button"
                            tabIndex="0"
                            className="dropdownMenuItem"
                            onClick={() => onHandleDownload("CSV")}
                          >
                            {t("common:div_item_export_format_CSV")}
                          </div>
                        </DropdownMenu>
                      )
                      : null}
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
          <div className={styles.product}>
            {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
            <div className={clsx(styles.productBody, "table-responsive")}>
              <Table borderless className={styles.productTable}>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <thead>
                  <tr>
                    {/* <th>{t("product:label_id")}</th> */}
                    <th>{t("product:label_external_id")}</th>
                    <th>{t("common:label_name")}</th>
                    <th>{t("common:label_image")}</th>

                    <th>{t("common:label_brand")}</th>
                    <th>{t("common:label_market")}</th>
                    <Permission
                      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT}
                    >
                      <th>{t("common:label_source_status")}</th>
                    </Permission>
                    <Permission
                      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT}
                    >
                      <th>{t("common:label_show")}</th>
                    </Permission>
                    {/* <Permission
                      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT}
                    >
                      <th>{t("common:label_edit")}</th>
                    </Permission>
                    <Permission
                      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_DLT_PRDT}
                    >
                      {" "}
                      <th>{t("common:label_delete")}</th>
                    </Permission> */}
                    {/* <th>{t("common:label_view")}</th> */}
                  </tr>
                </thead>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <tbody>
                  {/* ========= TABLE BODY S[0002] ======================= */}
                  {!isProccessing
                    ? (
                      <>
                        {(Array.isArray(productData) && productData.length > 0) ? (
                          productData.map(({
                            uniqueId, id, name, showInStorefront, activeInSource, medias, brand, locations,
                          }) => (
                            <tr key={id} className={styles.productTreeItem}>
                              {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
                              {/* <td><span onClick={(o) => viewProduct(id, o)} style={{ cursor: "pointer" }}>{id}</span></td> */}
                              <td><span role="presentation" onClick={(o) => viewProduct(id, o)} style={{ cursor: "pointer" }}>{uniqueId}</span></td>
                              <td>
                                <span
                                  role="presentation"
                                  title={name}
                                  className={clsx(
                                    styles.productName,
                                    "text-truncate",
                                    "d-block",
                                  )}
                                  style={{ cursor: "pointer" }}
                                  onClick={(o) => viewProduct(id, o)}
                                >
                                  {name}
                                </span>
                              </td>
                              <td className="p-1 align-middle text-center">
                                <Image
                                  src={getPreviewImage(medias)}
                                  alt={name}
                                  className={clsx(styles.productImage, "img-fluid")}
                                />
                              </td>

                              <td>
                                {brand}
                              </td>
                              <td>
                                {(Array.isArray(locations) && locations.length > 0) ? (
                                  locations.map((location, index) => (index == 0 ? location : `, ${location}`))
                                ) : ""}
                              </td>
                              <Permission
                                allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT}
                              >
                                <td>
                                  <div
                                    className={clsx(
                                      "custom-control",
                                      "custom-switch",
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`expand-multiple-status${id}`}
                                      checked={activeInSource}
                                      disabled="true"
                                    />
                                    {/* added input tag for removing ESLint error > if added above input tag inside label it wont work */}
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`expand-multiple-status${id}`}
                                    >
                                      <input type="hidden" />
                                    </label>
                                  </div>
                                </td>
                              </Permission>
                              <Permission
                                allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT}
                              >
                                <td>
                                  <div
                                    className={clsx(
                                      "custom-control",
                                      "custom-switch",
                                      submitting ? "loading" : "",
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`expand-multiple${id}`}
                                      disabled={!activeInSource}
                                      checked={showInStorefront}
                                      onChange={() => handleChangeProductStatus(id, uniqueId, true, !showInStorefront)}
                                    />
                                    {/* added input tag for removing ESLint error > if added above input tag inside label it wont work */}
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`expand-multiple${id}`}
                                    >
                                      <input type="hidden" />
                                    </label>
                                  </div>
                                </td>
                              </Permission>
                              {/* <Permission
                            allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT}
                          >
                            <td>
                              <span
                                onClick={(o) => editProduct(id, o)}
                                onKeyDown={() => { }}
                                tabIndex={0}
                                role="button"
                                className="commonPointer"
                              >
                                <img
                                  className={clsx("commonPointer", styles.icon)}
                                  src={EditIcon}
                                  alt="Edit"
                                />
                              </span>
                            </td>
                          </Permission>
                          <Permission
                            allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_DLT_PRDT}
                          >
                            <td>
                              <img
                                className={clsx("commonPointer", styles.icon)}
                                src={DeleteIcon}
                                alt="Delete"
                              />
                            </td>
                          </Permission> */}
                              {/* <td>
                          <Button
                            color="transparent"
                            className="btn-square"
                            onClick={(o) => viewProduct(id, o)}
                            onKeyDown={() => { }}
                          >
                            <img src={EyeDark} alt="View" />
                          </Button>
                        </td> */}
                              {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} align="center" valign="center">
                              Nothing to show
                            </td>
                          </tr>
                        )}
                      </>
                    )
                    : (
                      <tr>
                        <td colSpan={8} align="center" valign="center">
                          <Spinner size="lg" animation="border" role="status" className="my-5" />
                        </td>
                      </tr>
                    )}
                  {/* ========= TABLE BODY E[0002] ======================= */}
                </tbody>
              </Table>
            </div>
            {brandId ? (
              <Row className="justify-content-between">
                <Col xs="auto" className="d-flex align-items-center">
                  Show
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="dropdown-custom dropdown-number mx-2"
                  >
                    <DropdownToggle caret color="light" size="sm">
                      {itemsPerPage}
                    </DropdownToggle>
                    <DropdownMenu down>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(10)}
                        onKeyPress={() => { }}
                      >
                        10
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(15)}
                        onKeyPress={() => { }}
                      >
                        15
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(20)}
                        onKeyPress={() => { }}
                      >
                        20
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  entries
                </Col>
                <Col xs="auto">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPagesCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={onPageChange}
                    containerClassName="pagination"
                    pageClassName="page"
                    pageLinkClassName="pageLink"
                    activeClassName="activePage"
                    initialPage={0}
                  />
                </Col>
              </Row>
            ) : null}
            {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
          </div>
        </Col>
      </Row>
      <Modal isOpen={confirmDisableProductModalData.isModalOpen} centered>
        <ModalHeader>
          {t("product:title_confirm_product_status_change")}
        </ModalHeader>
        <ModalBody>
          <h6>
            {`${t("product:mesaage_confirm_product_status_change")} ${confirmDisableProductModalData.uniqueId}`}
          </h6>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="excludeCustomer"
              name="excludeCustomer"
              checked={confirmDisableProductModalData.forceIndex}
              onChange={toggleForceIndex}
            />
            <label className="custom-control-label" htmlFor="excludeCustomer">
              {t("product:label_force_index")}
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={submitting}
            onClick={() => !submitting && changeProductStatus(confirmDisableProductModalData)}
          >
            {submitting
              ? (
                <Spinner size="sm" animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : t("product:button_confirm")}
          </Button>
          <Button onClick={() => handleChangeProductStatus("", "", false, false)}>
            {t("product:button_cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ProductManagement.propTypes = {
  productData: PropTypes.objectOf(PropTypes.any).isRequired,
  changeProductStatus: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  addNewProduct: PropTypes.func.isRequired,
  editProduct: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  onHandleClear: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  viewProduct: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  totalPagesCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  numberOfElements: PropTypes.number.isRequired,
  isProccessing: PropTypes.bool.isRequired,
  brandId: PropTypes.string.isRequired,
  brands: PropTypes.objectOf(PropTypes.any).isRequired,
  handleBrandChange: PropTypes.func.isRequired,
  confirmDisableProductModalData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeProductStatus: PropTypes.func.isRequired,
  toggleForceIndex: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  onHandleDownload: PropTypes.func.isRequired,
  isDownloadInProgress: PropTypes.bool.isRequired,
};

export default ProductManagement;
