/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author shafeeqe
 */
import axios from "axios";
import config from "../config";

const { authServerHost } = config;

const instance = axios.create({
  baseURL: authServerHost,
});

const login = async (loginData, apiUrl) => {
  const { hostname: serviceUrl } = new URL(apiUrl);
  const loginRequest = {
    ...loginData,
    serviceUrl,
    grantType: "Password",
    appId: "MozCom",
    context: "RetailAdmin",
  };
  try {
    const response = await instance.post("/auth/v1/b2b/token", loginRequest);
    return response.data;
  } catch (error) {
    return null;
  }
};

const getAnonymusToken = async (apiUrl) => {
  const { hostname: serviceUrl } = new URL(apiUrl);
  const anonymusTokenRequest = {
    serviceUrl,
    grantType: "Anonymous",
    appId: "MozCom",
    context: "RetailAdmin",
  };
  try {
    const response = await instance.post(
      "/auth/v1/b2b/token",
      anonymusTokenRequest
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const updateTokenLocation = async (selectedLocation, token, apiUrl) => {
  const { hostname: serviceUrl } = apiUrl ? new URL(apiUrl) : {};
  const tokenUpdateRequest = {
    selectedLocation,
    serviceUrl,
    appId: "MozCom",
    token,
  };
  const response = await instance.post("/auth/v1/b2b/location", tokenUpdateRequest);
  return response;
};

export default { login, getAnonymusToken, updateTokenLocation };
