/* eslint-disable react/jsx-props-no-spreading */

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Drag and droppable  table
 *
 * @author Naseef O
 *
 */


import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { get } from "lodash";
import UncontrolledTooltip from "../../../common/core/UncontrolledTooltip";
import editIcon from "../../../common/assets/images/svg/edit.svg";
import deleteIcon from "../../../common/assets/images/svg/delete.svg";
import warningIcon from "../../../common/assets/images/svg/warning.svg";
import dragIndicatorIcon from "../../../common/assets/images/svg/dragIndicator.svg";
import Table from "../../../common/core/Table";
import Spinner from "../../../common/core/Spinner";
import styles from "../css/Table.module.css";
import transformers from "../../../common/utils/transformers";
import Label from "../../../common/core/Label";

const RowItem = (props) => {
  const {
    rowItem,
    index,
    pageCustomizationRows,
    widgetType,
    configId,
    handleRedirectToWidgetContentEditor,
    handleDelete,
    isLoading,
    selectedIndex,
    deleteEnabled,
    handleChangeStatus,
    widget,
  } = props;

  /**
 *This method is used to get a value from an item
 *
 * @param {object} row
 * @param {object} rowItemData
 * @returns
 */
  const getItem = (row, rowItemData) => {
    let data = get(row, rowItemData.path);
    if (rowItemData.transform && rowItemData.transform !== "") {
      data = transformers[rowItemData.transform](row, rowItemData.path);
    }
    return data;
  };


  return (
    <Draggable draggableId={Math.random().toString(36).slice(2)} width="100%" index={index}>
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <th scope="row" width="10%">
            <img src={dragIndicatorIcon} alt="drag indiactor icon" width="30px" height="24px" />
            <span className="p-2">{index + 1}</span>
          </th>
          <td width="10%"><img src={getItem(rowItem, { path: "media.defaultMediaUrl" })} alt="preview" width="60px" /></td>
          {pageCustomizationRows.map((row) => <td width="10%">{getItem(rowItem, row)}</td>)}
          <td width="10%">
            <img
              src={editIcon}
              className="commonPointer"
              onClick={() => handleRedirectToWidgetContentEditor(true, index, widgetType, configId)}
              role="presentation"
              alt="drag indiactor icon"
              width="20px"
            />
          </td>
          <td width="10%">
            {deleteEnabled ? (
              <>
                {" "}
                {isLoading
                  && (selectedIndex === index) ? (
                    <Spinner
                      className={styles.spinnerWrapper}
                    />
                  )
                  : (
                    <img
                      src={deleteIcon}
                      className="commonPointer"
                      alt="drag indiactor icon"
                      width="20px"
                      role="presentation"
                      onClick={() => handleDelete(index)}
                    />
                  )}
              </>
            )
              : (
                <>
                  <UncontrolledTooltip target="UncontrolledTooltipExample">
                    Limit reached. You can not delete this content.
                  </UncontrolledTooltip>
                  <img
                    src={warningIcon}
                    className="commonPointer"
                    alt="warning"
                    width="20px"
                    id="UncontrolledTooltipExample"
                    role="presentation"
                  />
                </>
              )}
          </td>
          <td width="10%">
            <div
              className={clsx(
                "custom-control",
                "custom-switch",

              )}
            >

              <input
                type="checkbox"
                className="custom-control-input"
                id={`toogle-${index + 1 + widget.id}`}
                checked={rowItem.active}
                onChange={() => handleChangeStatus(!rowItem.active, index)}
              />
              <Label className="custom-control-label" htmlFor={`toogle-${index + 1 + widget.id}`} />
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
};


RowItem.propTypes = {
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowItem: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
};

const RowsList = React.memo((props) => {
  const {
    rowData,
    pageCustomizationRows,
    widgetType,
    configId,
    handleRedirectToWidgetContentEditor,
    handleDelete,
    isLoading,
    selectedIndex,
    deleteEnabled,
    handleChangeStatus,
    widget,
  } = props;
  return rowData && Array.isArray(rowData) && rowData.length > 0 && rowData.map((rowItem, index) => (
    <RowItem
      pageCustomizationRows={pageCustomizationRows}
      rowItem={rowItem}
      index={index}
      key={rowItem.id}
      widgetType={widgetType}
      configId={configId}
      handleRedirectToWidgetContentEditor={handleRedirectToWidgetContentEditor}
      handleDelete={handleDelete}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      deleteEnabled={deleteEnabled}
      handleChangeStatus={handleChangeStatus}
      widget={widget}
    />
  ));
});

RowsList.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.any).isRequired,
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
};

const DragAndDropTable = (props) => {
  const {
    pageCustomizationRows,
    customizationData,
    onDragEnd,
    widgetType,
    configId,
    handleRedirectToWidgetContentEditor,
    handleDelete,
    isLoading,
    selectedIndex,
    deleteEnabled,
    handleChangeStatus,
    widget,
  } = props;

  return (
    <Table borderless>
      <thead>
        <tr>
          <th>Order</th>
          <th>Preview</th>
          {pageCustomizationRows.map((row) => <th>{row.label}</th>)}
          <th>Edit</th>
          <th>Delete</th>
          <th>Status</th>
        </tr>
      </thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              <RowsList
                rowData={customizationData}
                pageCustomizationRows={pageCustomizationRows}
                widgetType={widgetType}
                configId={configId}
                handleRedirectToWidgetContentEditor={handleRedirectToWidgetContentEditor}
                handleDelete={handleDelete}
                isLoading={isLoading}
                selectedIndex={selectedIndex}
                deleteEnabled={deleteEnabled}
                handleChangeStatus={handleChangeStatus}
                widget={widget}
              />
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};

DragAndDropTable.propTypes = {
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
};
export default DragAndDropTable;
