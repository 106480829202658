/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Spinner from "../../../common/core/Spinner";
import TabContent from "../../../common/core/TabContent";
import TabPane from "../../../common/core/TabPane";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
/** ======== MODULE STYLES ========== */
import styles from "../css/RobotsTxt.module.css";

const RobotsTxtManagement = (props) => {
  const {
    uploadFile, handleFileUpload, hiddenFileInput, loading, robotsTxtFileUrl,
  } = props;

  // local states
  const activeTab = "1";

  return (
    <>
      <Row>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7" className="text-truncate">
              <span className="pageText">SEO Settings</span>
            </Col>
          </Row>
          <Row className={clsx(styles.block2, "common-page-card")}>
            <Col xs="12">
              <div className="d-flex align-items-center m-0 text-truncate flex-shrink-1 pr-3">
                <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Robots Text File</span>
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="bg-white">
                  <Form>
                    <Row className={clsx(styles.block2, "common-page-card")}>
                      <Col xs="12">
                        <div className="my-2">
                          <Permission
                            allowed={Rules.Control.MOZCOM_RA_MRKTNG_SEO_RBTS_FILE_UPLD}
                          >
                            <Col xs="12" className="mb-3 d-flex algin-items-center">
                              <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Upload Robots Txt File</span>
                              <span className="ml-auto col-xs-4 col-md-3 col-lg-2 p-0">
                                <>
                                  <Button size="sm" color="primary" onClick={() => handleFileUpload()} block className="text-white">
                                    {
                                      loading ? (
                                        <Spinner size="sm" />
                                      ) : "Upload"
                                    }
                                  </Button>
                                  <input
                                    ref={hiddenFileInput}
                                    type="file"
                                    hidden
                                    onChange={(e) => uploadFile(e)}
                                    accept=".txt"
                                  />
                                </>
                              </span>
                            </Col>
                          </Permission>
                          <Permission
                            allowed={Rules.Control.MOZCOM_RA_MRKTNG_SEO_RBTS_FILE_DWNLD}
                          >
                            <Col xs="12" className="mb-3 d-flex algin-items-center">
                              <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Download Robots Txt File</span>
                              <span className="ml-auto col-xs-4 col-md-3 col-lg-2 p-0">
                                {robotsTxtFileUrl
                                  ? (
                                    <a href={robotsTxtFileUrl} style={{ display: "block" }} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-info text-white">
                                      Download
                                    </a>
                                  )
                                  : (
                                    <Button size="sm" block className="btn btn-sm btn-info text-white" disabled>
                                      Download
                                    </Button>
                                  )}
                              </span>
                            </Col>
                          </Permission>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

RobotsTxtManagement.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  robotsTxtFileUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RobotsTxtManagement;
