/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * A compound component that decides whether its childrens to be rendered, disabled or hidden
 * @module
 */
import PropTypes from "prop-types";

const Permission = (props) => {
  const { children, allowed, customized } = props;
  const isAllowed = typeof allowed === "function" ? allowed() : allowed;
  const elements = typeof children === "function" ? children(isAllowed) : children;
  const canRender = customized || isAllowed;
  return canRender ? elements : null;
};

const defaultRule = () => true;

Permission.defaultProps = {
  allowed: defaultRule,
  customized: false,
};

Permission.propTypes = {
  allowed: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.bool.isRequired]),
  customized: PropTypes.bool,
};

export default Permission;
