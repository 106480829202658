/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */
import { useHistory, useRouteMatch } from "react-router-dom";
import React, { useState, useEffect } from "react";
import EditUser from "../components/EditUser";
import validateForm from "../../../common/utils/validateForm";
import commonUtils from "../../../common/utils/commonUtils";
import { updateUser, getActiveUserRoles, getUserById } from "../../../api/UserServices";
import {
  retailAdminRoles,
} from "../../../api/rolesManagement";

const EditUserContainer = () => {
  const validator = validateForm();
  const [id, setId] = useState(null);
  const { params } = useRouteMatch();
  const { clearError, setError, errors } = validator;
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    roles: [],
    accessibleOrganizations: {
      brands: [],
      locations: [],
    },
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const allRoles = [...retailAdminRoles];
    setRoles(allRoles.map((each) => ({ ...each, disabled: false })));
  }, []);

  const updateRoles = (value, allRoles) => {
    const selectedRoles = allRoles.filter((each) => value.includes(each.value));
    const newRoles = allRoles.map((each) => ({
      ...each,
      disabled: selectedRoles.some((role) => role.value !== each.value && role.type === each.type) || each.type === "B2BAppUser",
      title: each.type === "B2BAppUser" ? "Not able to add storefront roles" : "You can add only one role",
    }));
    setRoles(newRoles);
  };
  useEffect(() => {
    if (params.id) {
      getUserById(params.id).then(async (newResponse) => {
        if (newResponse && newResponse.success && newResponse.data) {
          const { data: newData } = newResponse;
          const allRoles = [...retailAdminRoles];
          updateRoles(newData.roles, allRoles);
          await setForm({
            ...form, ...newData,
          });
        }
      });
    }
    setId(params.id);
  }, [params.id]);


  /**
  * This method is used to change fom state
  * @param {String} name
  * @param {String} value
  */
  const handleChange = (name, value) => {
    if (name === "roles") {
      updateRoles(value, roles);
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const onHandleCancel = () => {
    history.push("/administration/user");
  };

  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const onHandleSave = async () => {
    setLoading(true);
    setOnSubmitting(true);
    if (id) {
      await updateUser(form);
      setOnSubmitting(false);
      setLoading(false);
    }
    history.push("/administration/user");
  };

  return (
    <EditUser
      handleFormChange={handleFormChange}
      form={form}
      errors={errors}
      onHandleSave={onHandleSave}
      onHandleCancel={onHandleCancel}
      loading={loading}
      onSubmitting={onSubmitting}
      roles={roles}
    />
  );
};


export default EditUserContainer;
