/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author
 */

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import ValidationConstants from "../../../common/utils/validationConstants";
import Alert from "../../../common/core/Alert";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import styles from "../css/ChangePassword.module.css";

const ChangePassword = (props) => {
  const {
    showPassword,
    password,
    handleFormChange,
    newPassword,
    confirmPassword,
    handleCreatePassword,
    message,
    handleSubmit,
    errors,
    register,
    togglePassword,
    showNewPassword,
    showConfirmPassword,
  } = props;
  const TYPE_TEXT = "text";
  const TYPE_PASSWORD = "password";
  const { IS_PASSWORD } = ValidationConstants;
  return (
    <>
      <Row className="">
        <Col xs="12">
          <Form onSubmit={handleSubmit(handleCreatePassword)}>
            {message && message.type && (
              <Alert color={message.type}>{message.message}</Alert>
            )}
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  Change Password
                </span>
              </Col>
            </Row>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="4" className={styles.changePasswordOuter}>
                <div className={styles.changePasswordBox}>
                  <fieldset>
                    <FormGroup className="fixed-form-height">
                      <Label for="currentPassword">
                        Enter Current password
                      </Label>
                      <div className="input-password">
                        <Input
                          name="password"
                          autoComplete="password"
                          type={showPassword ? TYPE_TEXT : TYPE_PASSWORD}
                          id="currentPassword"
                          autoFocus
                          value={password}
                          innerRef={register({
                            required: "Please enter your current password",
                            minLength: {
                              value: 3,
                              message: "Please enter your current password",
                            },
                          })}
                          invalid={!!errors.password && errors.password.message}
                          isRightContent
                          onChange={handleFormChange}
                        />
                        {" "}
                        <FormFeedback>
                          {errors.password && errors.password.message}
                        </FormFeedback>
                        <button className={`password-eye ${showPassword ? "active" : ""}`} onClick={() => togglePassword("showPassword")} type="button">
                          <span className="sr-only" />
                        </button>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="newPassword">Enter New password</Label>
                      <div className="input-password">
                        <Input
                          id="newPassword"
                          value={newPassword}
                          name="newPassword"
                          type={showNewPassword ? TYPE_TEXT : TYPE_PASSWORD}
                          innerRef={register({
                            required:
                              "Please enter your new password",
                            minLength: {
                              value: 5,
                              message: "Please enter your new password",
                            },
                            pattern: {
                              value: IS_PASSWORD,
                              message: "Password is not Vaild",
                            },
                            maxLength: {
                              value: 15,
                              message: "Password is too large",
                            },
                          })}
                          invalid={
                            !!errors.newPassword && errors.newPassword.message
                          }
                          isRightContent
                          onChange={handleFormChange}
                        />

                        {" "}
                        <FormFeedback>
                          {errors.newPassword && errors.newPassword.message}
                        </FormFeedback>
                        <button className={`password-eye ${showNewPassword ? "active" : ""}`} onClick={() => togglePassword("showNewPassword")} type="button">
                          <span className="sr-only" />
                        </button>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="newPassword2">Retype New password</Label>
                      <div className="input-password">
                        <Input
                          id="newPassword2"
                          value={confirmPassword}
                          name="confirmPassword"
                          type={showConfirmPassword ? TYPE_TEXT : TYPE_PASSWORD}
                          innerRef={register({
                            required: "Please enter your retype new password",
                            minLength: {
                              value: 1,
                              message: "Please enter your retype new password",
                            },
                            maxLength: {
                              value: 15,
                              message: "Password is too large",
                            },
                          })}
                          invalid={
                            !!errors.confirmPassword
                            && errors.confirmPassword.message
                          }
                          isRightContent
                          onChange={handleFormChange}
                        />
                        {" "}
                        <FormFeedback>
                          {errors.confirmPassword
                            && errors.confirmPassword.message}
                        </FormFeedback>
                        <button className={`password-eye ${showConfirmPassword ? "active" : ""}`} onClick={() => togglePassword("showConfirmPassword")} type="button">
                          <span className="sr-only" />
                        </button>
                      </div>
                    </FormGroup>
                    <Button color="primary" type="submit">
                      Update Password
                    </Button>
                  </fieldset>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
ChangePassword.propTypes = {
  password: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  showNewPassword: PropTypes.bool.isRequired,
  showConfirmPassword: PropTypes.bool.isRequired,
  newPassword: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  handleCreatePassword: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  togglePassword: PropTypes.func.isRequired,
};
export default ChangePassword;
