/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */


import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../common/core/Button";
import Card from "../../../common/core/Card";
import CardBody from "../../../common/core/CardBody";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import CouponsContainer from "../containers/CouponsContainer";
/** ========= SUB COMPONENT ========= */
import CustomerGroupsContainer from "../containers/CustomerGroupsContainer";
/** ========= MODULE STYLES ========= */
import styles from "../css/CampaignCreatorView.module.css";

const CampaignStepTwoView = (props) => {
  const {
    onSubmitting, campaignId,
    formSubmitHandler, formBackHandler, formSaveDraftHandler, customerGroups, coupons,
    handleCustomerGroups, handleCoupons, isCouponEnabled, salesCompany,
  } = props;

  /** internationalization using i18n from common and campaign  */
  const { t } = useTranslation(["common", "campaign"]);

  return (
    <Row className="justify-content-center text-body">
      <Col sm="12" md="10" xl="8">
        <div className="text-center">
          <h4 className="text-body font-weight-bolder">{t("campaign:title_qualifiers")}</h4>
        </div>
        <div className="text-center">
          <p className="font-weight-normal pt-2 pb-1">
            {t("campaign:sub_title_qualifiers")}
          </p>
        </div>
        <div>
          <div>
            <fieldset disabled={onSubmitting}>
              <div>
                <CustomerGroupsContainer
                  campaignId={campaignId}
                  onChange={handleCustomerGroups}
                  selectedCustomerGroupDetails={customerGroups}
                  salesCompany={salesCompany}
                />
              </div>
              {isCouponEnabled
                && (
                  <div className="pt-3">
                    <CouponsContainer campaignId={campaignId} onChange={handleCoupons} selectedCouponDetails={coupons} />
                  </div>
                )}
              <div className="pt-3">
                <Card>
                  <CardBody className="p-3">
                    <FormGroup className="mb-0">
                      <div className="d-flex flex-wrap justify-content-end">
                        <div className="mr-md-auto py-1">
                          <Button onClick={formBackHandler} type="button" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size")}>
                            {t("common:button_back")}
                          </Button>
                        </div>
                        <div className="py-1">
                          <Button onClick={formSaveDraftHandler} type="button" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size")}>
                            {t("campaign:button_save_draft")}
                          </Button>
                        </div>
                        <div className="py-1">
                          <Button type="submit" onClick={formSubmitHandler} color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "button-font-size")}>
                            {t("campaign:button_save_continue")}
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </div>
            </fieldset>
          </div>
        </div>
      </Col>
    </Row>
  );
};

CampaignStepTwoView.defaultProps = {
  campaignId: null,
};

CampaignStepTwoView.propTypes = {
  onSubmitting: PropTypes.bool.isRequired,
  campaignId: PropTypes.string,
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  coupons: PropTypes.arrayOf(PropTypes.any).isRequired,
  // functions
  formSubmitHandler: PropTypes.func.isRequired,
  formBackHandler: PropTypes.func.isRequired,
  formSaveDraftHandler: PropTypes.func.isRequired,
  handleCustomerGroups: PropTypes.func.isRequired,
  handleCoupons: PropTypes.func.isRequired,
  isCouponEnabled: PropTypes.bool.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default CampaignStepTwoView;
