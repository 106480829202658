/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Midhun Murali
 */

import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datetime";
import { FormFeedback } from "reactstrap";
import { moment } from "moment";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import styles from "../css/B2BAnalytics.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Button from "../../../common/core/Button";
import Select from "../../../common/components/Select";

const AnalyticsFilter = ({
  resetFilterChange, catalogType, catalogTypeList, timeLineTypes, timeLineType, handleFilterChange, salesCompany,
  salesCompanyList, brand, brandList, isValidDate, toDate, fromDate,
}) => {
  const { t } = useTranslation(["common", "b2bAnalytics"]);


  return (
    <>
      <Col className={clsx("col-auto align-self-center pb-1")}>
        <Label className={styles.filterHeader}>
          {t("b2bAnalytics:filter_by")}
          :
        </Label>
      </Col>
      <Col className={clsx(" col-auto  align-self-center pt-1")}>
        <FormGroup>
          <Select
            className="form-select text-muted align-self-center"
            placeholder="filter type"
            name={salesCompany}
            id={salesCompany}
            value={salesCompany}
            onChange={(e) => handleFilterChange("salesCompany", e.target.value)}
            options={[
              {
                value: "", disabled: true, selected: true, label: "Select Sales Company",
              },
              ...(
                Array.isArray(salesCompanyList) ? salesCompanyList.map((item) => ({ value: item, label: item })) : []
              ),
            ]}
          />
        </FormGroup>
      </Col>
      <Col className={clsx("col-auto  align-self-center pt-1 ")} align="center">
        <FormGroup>
          <Select
            className="form-select text-muted"
            placeholder="filter type"
            name={catalogType}
            id={catalogType}
            value={catalogType}
            onChange={(e) => handleFilterChange("catalogType", e.target.value)}
            options={[
              {
                value: "", disabled: true, selected: true, label: "Select Order Type",
              },
              ...(
                Array.isArray(catalogTypeList) ? catalogTypeList.map((item) => ({ value: item, label: item })) : []
              ),
            ]}
          />
        </FormGroup>
      </Col>
      <Col className={clsx("col-auto roundedcol-auto  align-self-center pt-1")}>
        <FormGroup>
          <Select
            placeholder="filter type"
            className="form-select text-muted"
            name={brand}
            id={brand}
            value={brand}
            borderRadius="10px"
            onChange={(e) => handleFilterChange("brand", e.target.value)}

            options={[
              {
                value: "", disabled: true, selected: true, label: "Select Brand",
              },
              ...(
                Array.isArray(brandList) ? brandList.map((item) => ({ value: item, label: item })) : []
              ),
            ]}
          />
        </FormGroup>
      </Col>
      <Col className={clsx("col-auto roundedcol-auto  align-self-center pt-1")}>
        <FormGroup>
          <Select
            className="form-select text-muted"
            placeholder="Report Type"
            name={timeLineType}
            id={timeLineType}
            value={timeLineType}
            onChange={(e) => handleFilterChange("timeLineType", e.target.value)}
            options={[
              {
                value: "", disabled: true, selected: true, label: "Select Report Type",
              },
              ...(
                Array.isArray(timeLineTypes) ? timeLineTypes.map((item) => ({ value: item.value, label: item.label, selected: item.selected })) : []
              ),
            ]}
          />
        </FormGroup>
      </Col>
      <Col className={clsx("col-auto  align-self-center pt-1")}>
        <FormGroup>
          <div className="custom-datepicker">
            <Label className="formTextLabel" for="exampleEmail">
              {/* {t("order-management:label_order_from_date")} */}
              From:
            </Label>
            <DatePicker
              id="fromDate"
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              closeOnSelect
              input
              name="fromDate"
              value={fromDate}
              isValidDate={(e) => isValidDate(e, "ORDER_DATE_FROM")}
              onChange={(e) => handleFilterChange("fromDate", e)}
              inputProps={{
                placeholder: t("common:placeholder_date_example"),
              }}
            />
          </div>
          <FormFeedback>
            Invalid date
          </FormFeedback>
        </FormGroup>
      </Col>
      <Col className={clsx("col-auto  align-self-center pt-1")}>
        <FormGroup>
          <div className="custom-datepicker">
            <Label className="formTextLabel" for="exampleEmail">
              {/* {t("order-management:label_order_from_date")} */}
              To:
            </Label>
            <DatePicker
              id="toDate"
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              closeOnSelect
              input
              name="toDate"
              value={toDate}
              isValidDate={(e) => isValidDate(e, "ORDER_DATE_TO")}
              onChange={(e) => handleFilterChange("toDate", e)}
              inputProps={{
                placeholder: t("common:placeholder_date_example"),
              }}
            />
          </div>
          <FormFeedback>
            Invalid date
          </FormFeedback>
        </FormGroup>
      </Col>


      <Col className={clsx("col-auto  align-self-center pt-1")}>
        <FormGroup>
          <Button color="light" onClick={resetFilterChange}>
            <FontAwesomeIcon icon={faRedo} className="mr-2" />
            <span className="text-black">Reset</span>
          </Button>
        </FormGroup>
      </Col>
    </>
  );
};
AnalyticsFilter.propTypes = {
  brand: PropTypes.string.isRequired,
  timeLineTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  timeLineType: PropTypes.string.isRequired,
  catalogTypeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  catalogType: PropTypes.string.isRequired,
  salesCompanyList: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandList: PropTypes.arrayOf(PropTypes.any).isRequired,
  salesCompany: PropTypes.string.isRequired,
  toDate: PropTypes.instanceOf(moment).isRequired,
  fromDate: PropTypes.instanceOf(moment).isRequired,
  // functions
  handleFilterChange: PropTypes.func.isRequired,
  resetFilterChange: PropTypes.func.isRequired,
  isValidDate: PropTypes.func.isRequired,
};
export default AnalyticsFilter;
