/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * CustomerData Management Container
 *
 * @author Indrajith C
 *
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import { getB2BCustomers, transformCustomerData, initiateResetPassword } from "../../../api/customerDataService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import CustomerDataManagement from "../components/CustomerDataManagement";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const CustomerDataManagementContainer = () => {
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isProccessing, setIsProccessing] = useState(false);
  const [loading, setLoading] = useState([]);
  const paginationLimit = 10;
  /**
     * This method is used to list customer Groups
     * @param {String} searchTerm
     * @param {Number} newPage
     * @param {Number} paginationLimit
     * @param {String} sortBy
     * @param {String} sortDirection
     */

  const getCustomerGroupList = (searchTerm = "", newPage = 1, paginationLimitIn = paginationLimit) => {
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getB2BCustomers(searchTerm, newPage, paginationLimitIn, cancelToken).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setCustomers(data);
          if (data.length < paginationLimitIn) {
            setHasMoreData(false);
          } else {
            setHasMoreData(true);
          }
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 1));
      }
    });
  };

  /**
      * This method is used to trigger pagination
      * @param {Number} page
      */
  const getPageData = (direction) => {
    const newPage = page + direction;
    getCustomerGroupList(searchTerm, Math.max(newPage, 1), paginationLimit);
  };

  /** getting list of customers when page load */
  useEffect(() => {
    getCustomerGroupList();
  }, [paginationLimit, getCustomerGroupList]);

  /**
        * This method is used to update text in search box
        * @param {Event} event
        */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getCustomerGroupList(value); } else getCustomerGroupList();
  };


  const onHandleExport = async (type) => {
    setIsProccessing(true);
    const page = 0;
    const size = -1;
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getB2BCustomers(searchTerm, page, size, cancelToken)
      .then(async (response) => {
        if (response && response.success === true) {
          const {
            data,
          } = response;
          if (Array.isArray(data)) {
            const customers = await transformCustomerData(data);
            let fileName = "Customers.xlsx";
            if (type === "CSV") {
              fileName = "Customers.csv";
            }
            const ws = XLSX.utils.json_to_sheet(customers);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Customers");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 15 },
              { wch: 30 },
              { wch: 15 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch((err) => {
        setIsProccessing(false);
      });
  };
  const [resetPasswordRequest, setResetPassword] = useState([]);
  const handleResetPassword = (row) => {
    setLoading([...loading, row.id]);
    setResetPassword([row.firstName, ...resetPasswordRequest]);
    initiateResetPassword().then((response) => {
      const filterLoading = loading.filter((each) => each.id !== row.id);
      setLoading(filterLoading);
      if (response && response.success) {
        if (Array.isArray(response.data)) {
          setResetPassword(response.data);
        }
      }
    });
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_VIEW_LIST}
    >
      <CustomerDataManagement
        customers={customers}
        page={page}
        hasMoreData={hasMoreData}
        searchTerm={searchTerm}
        getPageData={getPageData}
        handleSearchTerm={handleSearchTerm}
        onHandleExport={onHandleExport}
        isProccessing={isProccessing}
        handleResetPassword={handleResetPassword}
        resetPasswordRequest={resetPasswordRequest}
        loading={loading}
      />
    </Permission>
  );
};

export default CustomerDataManagementContainer;
