/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import httpApi from "./httpApi";

const getAllPreOrderCatalogConfigs = async () => {
  const response = await httpApi.get("/v1/b2b/pre-order-catalog/all");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const updatePreOrderCatalogConfig = async (data) => {
  const response = await httpApi.patch("/v1/b2b/pre-order-catalog/", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getAllPreOrderCatalogConfigs,
  updatePreOrderCatalogConfig,
};
