import React from "react";
import B2BCustomerDetailsUI from "../components/B2BCustomerDetailsUI";

const B2BCustomerDetailsUIContainer = () => {
  return (
    <B2BCustomerDetailsUI />
  );
};

export default B2BCustomerDetailsUIContainer;
