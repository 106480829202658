/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ViewDepartment from "../components/ViewDepartment";
import {
  getB2BDepartmentDetailsById,
  getB2BUserDetailsByDepartment,
  getSalesRepresentativesOfDepartment,
} from "../../../api/customerConfigurationService";

const ViewDepartmentContainer = () => {
  const history = useHistory();
  const params = useParams();
  if (!params.departmentId) {
    if (params.customerId) {
      history.push(`/administration/b2bcustomer/view/${params.customerId}`);
    } else {
      history.push("/administration/b2bcustomer");
    }
  }
  const { departmentId } = params;
  const [department, setDepartment] = useState(null);
  const [users, setUsers] = useState([]);
  const [salesReps, setSalesReps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllSalesRepInfo = () => {
    getSalesRepresentativesOfDepartment(departmentId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setSalesReps(data);
      }
      setIsLoading(false);
    });
  };

  const getB2BDepartmentDetails = () => {
    getB2BDepartmentDetailsById(departmentId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setDepartment(data);
        }
        setIsLoading(false);
      }
    });
  };

  const getB2BUserDetails = () => {
    getB2BUserDetailsByDepartment(departmentId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        let consumers = [];
        if (data && data.length > 0) {
          consumers = data.map((item) => {
            if (item.roles && item.roles.length > 0 && !item.roles.includes("SALES_REPRESENTATIVE")) {
              return item;
            }

            return null;
          });
        }
        setUsers(consumers);
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getB2BDepartmentDetails();
    getAllSalesRepInfo();
    getB2BUserDetails();
  }, []);

  const onHandleCancel = () => {
    history.push(`/administration/b2bcustomer/view/${params.customerId}`);
  };
  return department ? (
    <ViewDepartment
      department={department}
      users={users}
      onHandleCancel={onHandleCancel}
      salesReps={salesReps}
      isLoading={isLoading}
    />
  ) : "";
};

export default ViewDepartmentContainer;
