/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import { getCustomers, getCustomersByIds, transformCustomerData } from "../../../api/customerGroupServices";
import CustomerSelector from "../components/CustomerSelector";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const CustomerSelectionContainer = (props) => {
  /** local states */
  const {
    userIds, setUserIds, isFormSubmit, csvUsers, setCustomers,
    customers,
  } = props;
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCustomers, setselectedCustomers] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    customers: [],
  });
  useEffect(() => {
    if (isFormSubmit) {
      setUsers([]);
      setUserList([]);
      setSelectedUsers([]);
      setSearchKeyword("");
    }
  }, [isFormSubmit]);
  useEffect(() => {
    const customerList = customers;
    const newCustomerList = customerList.concat(userIds);
    setCustomers(newCustomerList);
  }, [userIds]);
  useEffect(() => {
    if (Array.isArray(csvUsers) && csvUsers.length > 0) {
      const newSelectedUsers = [...selectedUsers];
      const newUserList = [...userList];

      csvUsers.forEach((user) => {
        if (!newSelectedUsers.includes(user.id)) {
          newSelectedUsers.push(user.id);
        }
        if (!userList.some((each) => each.id === user.id)) {
          newUserList.push(user);
        }
      });
      setUserList(newUserList);
      setSelectedUsers(newSelectedUsers);
    }
  }, [csvUsers]);
  /**
       * This method is used to get customer list from server
       * @param {String} searchTerm
       */
  const getCustomerListFromSever = (searchTerm = "") => {
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    const searchValue = searchTerm.replace("+", ".");
    getCustomers(searchValue, cancelToken).then((response) => {
      if (response && response.success && response.data) {
        const { content } = response.data;
        if (Array.isArray(content)) {
          const listedUserId = userList.map(({ uniqueId }) => uniqueId);
          setUsers(content);
          setUserList([
            ...(content.filter((each) => each && !listedUserId.includes(each.uniqueId))),
            ...userList,
          ]);
        }
      }
    });
  };

  const getSelectedCustomerListFromSever = () => {
    if (userIds && Array.isArray(userIds) && userIds.length > 0) {
      setIsLoading(true);
      getCustomersByIds(userIds).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          if (Array.isArray(data)) {
            const listedUserId = userList.map(({ uniqueId }) => uniqueId);
            setUsers(data);
            setUserList([
              ...(data.filter((each) => each && !listedUserId.includes(each.uniqueId))),
              ...userList,
            ]);
          }
          setIsLoading(false);
        }
      });
    }
  };

  /**
        * this method display selected customers in edit page
        */
  useEffect(() => {
    if (Array.isArray(userIds) && userIds.length > 0) {
      setIsLoading(true);
      const listedUserId = userIds;
      const filteredList = userIds.filter((each) => each && !listedUserId.includes(each.uniqueId));
      setUserList([
        ...userList,
        ...filteredList,
      ]);
      setSelectedUsers(userIds);
      setCustomers(userIds);
      setselectedCustomers(userIds);
      setIsLoading(false);
    }
  }, []);

  /**
       * This method is used to change search field and trigger search functionality
       * @param {Event} event
       */
  const handleSearch = (event) => {
    setUsers([]);
    const { value } = event.target;
    if (value && `${value}`.trim().length > 1) { getCustomerListFromSever(value); } else { getCustomerListFromSever(); }
    setSearchKeyword(value);
  };

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
       * This method is used to set select / deselect users
       * @param {String} userId
       */
  const setUserSelected = (userId) => {
    const index = selectedUsers.indexOf(userId);
    let newSelectedList = [];
    if (index < 0) {
      /** if item not exist adding in the list */
      newSelectedList = [...selectedUsers, userId];
    } else {
      /** if item exits remove form the list */
      newSelectedList = (selectedUsers.filter((o) => o !== userId));
    }
    setSelectedUsers(newSelectedList);
    handleChange(newSelectedList || []);
    setUserIds(newSelectedList);
    setselectedCustomers([]);
  };

  useEffect(() => {
    if (Array.isArray(userIds) && userIds.length > 0) {
      getSelectedCustomerListFromSever();
    }
  }, []);
  const deleteAll = () => {
    const newSelectedList = selectedUsers.filter((each) => !selectedCustomers.includes(each));
    const newUsers = users.filter((each) => !selectedCustomers.includes(each.id));
    setSelectedUsers(newSelectedList);
    handleChange(newSelectedList || []);
    setUserIds(newSelectedList);
    setselectedCustomers([]);
    setUserList(newUsers);
    setUsers(newUsers);
  };
  const selectCheckboxes = (userId) => {
    if (userId === "ALL") {
      const isAllSelected = userList.every((each) => selectedCustomers.includes(each.id));
      setselectedCustomers(isAllSelected ? [] : userList.map((each) => each.id));
    } else if (selectedCustomers.includes(userId)) {
      const newSelectedCustomers = selectedCustomers.filter((each) => each !== userId);
      setselectedCustomers(newSelectedCustomers);
    } else {
      const newSelectedCustomers = [...selectedCustomers, userId];
      setselectedCustomers(newSelectedCustomers);
    }
  };
  const onHandleExport = async (type) => {
    getCustomersByIds(selectedUsers)
      .then(async (response) => {
        if (response && response.success === true) {
          const {
            data,
          } = response;
          if (Array.isArray(data)) {
            const customers = await transformCustomerData(data);
            let fileName = "Customers.xlsx";
            if (type === "CSV") {
              fileName = "Customers.csv";
            }
            const ws = XLSX.utils.json_to_sheet(customers);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Customers");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 15 },
              { wch: 30 },
              { wch: 15 },
            ];
            XLSX.writeFile(wb, fileName);
          }
        }
      })
      .catch((err) => {
      });
  };
  const getInputValues = (e) => {
    setselectedCustomers(e.target.value);
  };

  const addCustomerToList = () => {
    const newCustomers = [...customers];
    if (!newCustomers.includes(selectedCustomers)) {
      newCustomers.push(selectedCustomers);
      setCustomers(newCustomers);
    }
  };
  const deleteCustomerFromList = (value) => {
    let newSelectedList = [];
    if (value) {
      newSelectedList = (customers.filter((o) => o !== value));
    }
    setCustomers(newSelectedList);
  };

  return (
    <CustomerSelector
      // users
      userList={userList}
      users={users}
      selectedUsers={selectedUsers}
      setUserSelected={setUserSelected}
      // user search
      searchKeyword={searchKeyword}
      handleSearch={handleSearch}
      handleChange={handleChange}
      selectCheckboxes={selectCheckboxes}
      selectedCustomers={selectedCustomers}
      deleteAll={deleteAll}
      onHandleExport={onHandleExport}
      isLoading={isLoading}
      getInputValues={getInputValues}
      addCustomerToList={addCustomerToList}
      customers={customers}
      deleteCustomerFromList={deleteCustomerFromList}
    />
  );
};
CustomerSelectionContainer.propTypes = {
  setUserIds: PropTypes.func.isRequired,
  setCustomers: PropTypes.func.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
  isFormSubmit: PropTypes.bool.isRequired,
};
export default CustomerSelectionContainer;
