/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Biljo
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import EditTaxClassForm from "../components/EditTaxClassForm";
import { createTaxClass, getTaxClassById, updateTaxClass } from "../../../api/taxConfigurationService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const EditTaxClassFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [id, setId] = useState(null);
  const [form, setForm] = useState({
    name: "",
    code: "",
    description: "",
    retailer: "5e411b264d50307e272ea9ed",
    brand: "5e411c234d50307e272eaa05",
  });

  const submitting = false;
  useEffect(() => {
    if (params.id) {
      getTaxClassById(params.id).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setForm(data);
        }
      });
    }
    setId(params.id);
  }, [params.id]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const onHandleCancel = () => {
    history.push("/administration/tax-class");
  };

  const onHandleSave = async () => {
    if (id) {
      await updateTaxClass(form);
    } else {
      await createTaxClass(form);
    }
    history.push("/administration/tax-class");
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_CLS_UPD_TAX_CLS}
    >
      <EditTaxClassForm
        handleFormChange={handleFormChange}
        form={form}
        onHandleSave={onHandleSave}
        onHandleCancel={onHandleCancel}
        submitting={submitting}
      />
    </Permission>
  );
};

export default EditTaxClassFormContainer;
