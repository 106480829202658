/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 *
 */
import React from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */
import Coupon from "../../couponsManagement/containers/AddCouponContainer";

/** ========= UTILS ========= */


const CouponCreator = (props) => {
  const { onSave, onCancel } = props;
  return (<Coupon onSave={onSave} onCancel={onCancel} />);
};

CouponCreator.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CouponCreator;
