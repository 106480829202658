/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */ 
import PromotionList from "./containers/PromotionList";
import PromotionCreater from "./containers/PromotionCreator";
import PromotionEditor from "./containers/PromotionEditor";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */


/** components */


const ProductManagement = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={PromotionList} />
    <PrivateRouter exact path={`${path}/new`} component={PromotionCreater} />
    <PrivateRouter exact path={`${path}/edit/:promotionId`} component={PromotionEditor} />
    {/* <PrivateRouter exact path="" component={NotFound} /> */}
  </Switch>
);
ProductManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default ProductManagement;
