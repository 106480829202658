/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 *
 * @author kishor
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import CustomizationTable from "../../../common/components/Table";
import styles from "../css/CustomerAuditManagement.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import leftArrowLong from "../../../common/assets/images/svg/left-arrow-long.svg";


const customRows = [
  {
    label: "Id",
    value: "",
    path: "id",
    transform: "",
  },
  {
    label: "Name",
    value: "",
    path: "name",
    transform: "",
  },
  {
    label: "Start Date",
    value: "",
    path: "startDate",
    transform: "",
    type: "date",
  },
  {
    label: "End Date",
    value: "",
    path: "endDate",
    transform: "",
    type: "date",
  },
  {
    label: "Status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Read Count",
    value: "",
    path: "readCount",
    transform: "",
  },
];
const CustomerStepAuditManagement = ({
  batchStepAudit, jobAuditData, onHandleBack,
}) => {
  const { t } = useTranslation(["common", "batch-management"]);
  return (
    <Row className={clsx(styles.block1, "common-page-card ")}>
      <Col xs="12">
        <Row className="pb-4">
          <Col xs="12">
            <span className={clsx(styles.leftArrow, "mr-4")}>
              <img
                src={leftArrowLong}
                alt="click left"
                width="30"
                height="auto"
                type="button"
                onClick={() => onHandleBack(null)}
                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === "Space") {
                    onHandleBack(null);
                  }
                }}
                tabIndex="0"
              />

            </span>
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              Customer Step Audit Details
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>
                  {t("batch-management:label_job_audit_name")}
                </b>
              </Label>
              <p>{jobAuditData.name}</p>
            </FormGroup>
          </Col>

          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>
                  {t("batch-management:label_job_audit_jobId")}
                </b>
              </Label>
              <p>{jobAuditData.id}</p>
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>
                  {t("batch-management:label_job_audit_status")}
                </b>
              </Label>
              <p>{jobAuditData.status}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>
                  {t("batch-management:label_job_audit_start_date")}
                </b>
              </Label>
              <p>{jobAuditData.startDate}</p>
            </FormGroup>
          </Col>

          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>
                  {t("batch-management:label_job_audit_end_date")}
                </b>
              </Label>
              <p>{jobAuditData.endDate}</p>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="12">
            <div className="custom-table">
              <CustomizationTable
                customRows={customRows}
                customizationData={batchStepAudit}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CustomerStepAuditManagement.propTypes = {
  jobAuditData: PropTypes.arrayOf(PropTypes.any).isRequired,
  batchStepAudit: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleBack: PropTypes.func.isRequired,
};

export default CustomerStepAuditManagement;
