/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 * */
import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import EditJob from "../components/EditJob";
import {
  getLocationListByScope, getJobListStatus, getJobListScope, getJobDetails,
} from "../../../api/jobConfigurationService";

const initialStates = {
  name: "",
  description: "",
  scope: [],
  locations: [],
  recurring: "",
  date: "",
  frequencyFromDate: "",
  frequencyEndDate: "",
  noEndDate: "",
  selectedWeekDays: [],
  startDateTime: "",
  startType: "",
  startTime: "",
  intervalTime: "",
  intervalTimeUnit: "",
  errorHandlingType: "",
  retryCount: "",
  notificationChannel: "",
  notificationStatuses: [],
  notificationEmailTo: "",
  notificationEmailCC: "",
  notificationSMSTo: "",

};
function reducer(form, {
  name, value, type,
}) {
  let selectedData = value;
  if (type === "MULTI_SELECT_CHECKBOX") {
    let filteredCheckBoxData = form[name] || [];
    if (filteredCheckBoxData && filteredCheckBoxData.includes(value)) {
      filteredCheckBoxData = filteredCheckBoxData.filter((
        checkBoxValue,
      ) => checkBoxValue !== value);
    } else {
      filteredCheckBoxData.push(value);
    }
    selectedData = filteredCheckBoxData;
  }
  switch (type) {
    case "recurring":
      return {
        ...form,
        [name]: selectedData,
        date: "",
        frequencyFromDate: "",
        frequencyEndDate: "",
        noEndDate: true,
        selectedWeekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        startDateTime: "",
        startType: "PARTICULAR_TIME",
        startTime: "",
        intervalTime: "",
        intervalTimeUnit: "HR",
      };
    case "noEndDate":
      return {
        ...form,
        [name]: selectedData,
        frequencyEndDate: "",
      };
    case "startType":
      return {
        ...form,
        [name]: selectedData,
        startTime: "",
        intervalTime: "",
        intervalTimeUnit: "HR",
      };
    case "errorHandlingType":
      return {
        ...form,
        [name]: selectedData,
        retryCount: 0,
      };
    case "notificationChannel":
      return {
        ...form,
        [name]: selectedData,
        notificationEmailTo: "",
        notificationEmailCC: "",
        notificationSMSTo: "",
      };
    default:
      return {
        ...form,
        [name]: selectedData,
      };
  }
}

const EditJobContainer = () => {
  const [form, dispatch] = useReducer(reducer, initialStates);
  const handleFormChange = (name, value, type) => {
    dispatch({ name, value, type });
  };

  useEffect(() => {
    getJobDetails().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        dispatch({ value: data.name, name: "name" });
        dispatch({ value: data.description, name: "description" });
        dispatch({ value: data.jobScope, name: "scope" });
        dispatch({ value: data.locations, name: "locations" });
        dispatch({ value: data.recurring, name: "recurring" });
        dispatch({ value: data.date, name: "date" });
        dispatch({ value: data.frequencyFromDate, name: "frequencyFromDate" });
        dispatch({ value: data.frequencyEndDate, name: "frequencyEndDate" });
        dispatch({ value: data.noEndDate, name: "noEndDate" });
        dispatch({ value: data.selectedWeekDays, name: "selectedWeekDays" });
        dispatch({ value: data.startDateTime, name: "startDateTime" });
        dispatch({ value: data.startType, name: "startType" });
        dispatch({ value: data.startTime, name: "startTime" });
        dispatch({ value: data.intervalTime, name: "intervalTime" });
        dispatch({ value: data.intervalTimeUnit, name: "intervalTimeUnit" });
        dispatch({ value: data.errorHandlingType, name: "errorHandlingType" });
        dispatch({ value: data.retryCount, name: "retryCount" });
        dispatch({ value: data.notificationChannel, name: "notificationChannel" });
        dispatch({ value: data.notificationStatuses, name: "notificationStatuses" });
        dispatch({ value: data.notificationEmailTo, name: "notificationEmailTo" });
        dispatch({ value: data.notificationEmailCC, name: "notificationEmailCC" });
        dispatch({ value: data.notificationSMSTo, name: "notificationSMSTo" });
      }
    });
  }, []);


  const [scopeList, setScopeList] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    getJobListScope().then(async (response) => {
      if (response && response.success && response.data) {
        const respoJobListScope = response.data;
        setScopeList(respoJobListScope);
      }
    });
    getJobListStatus().then(async (response) => {
      if (response && response.success && response.data) {
        const respoJobListStatus = response.data;
        setAlertList(respoJobListStatus);
      }
    });
  }, []);

  useEffect(() => {
    if (form.jobScope) {
      getLocationListByScope(form.jobScope).then(async (response) => {
        if (response && response.success && response.data) {
          const respoLocationList = response.data;
          setLocationList(respoLocationList);
        }
      });
    }
  }, [form.jobScope ? form.jobScope.length : ""]);

  const history = useHistory();
  const [jobTaskData, setJobTaskData] = useState([]);

  const cancelJobEdit = () => {
    setJobTaskData([]);
    history.push("/administration/job-list");
  };

  const saveJobDetails = () => {
    setJobTaskData([]);
    history.push("/administration/job-list");
  };

  return (
    <EditJob
      form={form}
      handleFormChange={handleFormChange}
      scopeList={scopeList}
      locationList={locationList}
      alertList={alertList}
      cancelJobEdit={cancelJobEdit}
      saveJobDetails={saveJobDetails}
      jobTaskData={jobTaskData}
    />
  );
};

export default EditJobContainer;
