/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anagha
 */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ChangePassword from "../components/ChangePassword";
import { changePasswordforAdmin } from "../../../api/changePasswordService";
import UserData from "../../../common/security/UserData";
import validateForm from "../../../common/utils/validateForm";

function ChangePasswordContainer() {
  const [form, setForm] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [showEachPassword, setShowPassword] = useState({
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const validator = validateForm();
  const {
    register, errors, clearError, setError, handleSubmit,
  } = validator;
  const history = useHistory();
  const validatePassword = () => {
    clearError(["confirmPassword"]);
    if (form.confirmPassword !== form.newPassword) {
      setError("confirmPassword", "noMatch", "New and Retype passwords should match");
      return false;
    }
    return true;
  };
  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };
  const handleChange = (name, value) => {
    if (name === "confirmPassword") {
      clearError(["confirmPassword"]);
    }
    setForm({
      ...form,
      [name]: value,
    });
  };
  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const handleCreatePassword = () => {
    const changePasswordRequest = {
      oldPassword: form.password,
      newPassword: form.newPassword,
      username: UserData.getUserName(),
    };
    if (validatePassword()) {
      changePasswordforAdmin(changePasswordRequest).then((response) => {
        if (response && response.success && response.data) {
          const alertData = {
            type: "success",
            message:
          "Sucessfully Updated",
          };
          setAlert(alertData);
          UserData.clearAll();
          history.push("/login");
        } else {
          const alertData = {
            type: "danger",
            message: "Invalid Current Password",
          };
          setAlert(alertData);
        }
      });
    }
  };
  const togglePassword = (key) => {
    setShowPassword({
      ...showEachPassword,
      [key]: !showEachPassword[key],
    });
  };

  return (
    <ChangePassword
      password={form.password}
      newPassword={form.newPassword}
      confirmPassword={form.confirmPassword}
      showNewPassword={showEachPassword.showNewPassword}
      showConfirmPassword={showEachPassword.showConfirmPassword}
      showPassword={showEachPassword.showPassword}
      handleCreatePassword={handleCreatePassword}
      togglePassword={togglePassword}
      message={message}
      UserData={UserData}
      handleFormChange={handleFormChange}
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
    />
  );
}
export default ChangePasswordContainer;
