/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login Container
 *
 * @author Dipin
 *
 */

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Login from "../components/Login";
import { login, initiateResetPassword, getAnonymousToken } from "../../../api/UserServices";
import { isAuthenticated } from "../../../common/security/permissionUtils";
import UserData from "../../../common/security/UserData";

const LoginContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const from = (location && location.state && location.state.from) || null;

  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [error, setError] = useState({ username: "", password: "", isValid: "" });
  const [submitting, setSubmitting] = useState(false);

  const [forgotPasswordForm, setForgotPasswordForm] = useState({ username: "" });
  const [modal, setModal] = useState(false);
  const [userNameValidation, setUserNameValidation] = useState(false);

  /**
   * This method is used to toggle forgot password modal
   */
  const toggle = () => {
    setModal(!modal);
    setForgotPasswordForm({ username: loginData.username });
  };

  /**
   * This method is used to toggle forgot password modal
   */
  const toggleClose = () => {
    setModal(false);
    setUserNameValidation(false);
    setForgotPasswordForm({ username: "" });
  };

  /**
   * This method is used to handle change forgot password
   *
   * @param {*} key
   * @param {*} e
   */
  const handleChangeForgotPassword = (e) => {
    const key = e.target.name;
    setForgotPasswordForm({ ...forgotPasswordForm, [key]: e.target.value });
  };

  const [successModal, setSuccessModal] = useState(false);

  /**
   *This method is used to  toggle success modal
   *
   */
  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
    setForgotPasswordForm({ username: "" });
  };

  /**
   * This method is used to submit password
   *
   * @param {*} value
   */
  const handleForgotPasswordSubmit = (value) => {
    if (value) {
      const username = forgotPasswordForm.username?.trim();
      const newForgotPasswordForm = { ...forgotPasswordForm, username: username?.toLowerCase() };
      getAnonymousToken().then(function (response) {
        UserData.setToken(response.data.token);
      }).then((res) => {
        initiateResetPassword(newForgotPasswordForm).then((response) => {
          if (response && response.success && response.data) {
            toggle();
            toggleSuccessModal();
          } else {
            setUserNameValidation(true);
          }
        });
      })

    }
  };

  if (isAuthenticated()) {
    const site = UserData.getSelectedSite();
    if (site && site.locationId && site.brandId) {
      if (from && from.pathname) {
        history.push(from.pathname);
      } else {
        history.push("/");
      }
    } else {
      history.push({ pathname: '/', state: { from } })
    }
  }

  /**
   * This method is used to handle submit form
   *
   * @param {Object} loginData
   */
  const handleSubmitForm = () => {
    setSubmitting(true);
    login(loginData).then((response) => {
      setSubmitting(false);
      if (response && response.success && response.data) {
        const {
          data: {
            token, refreshToken, username, name, authorities, role
          },
        } = response;
        UserData.setAll({
          token,
          authenticated: true,
          refreshToken,
          username,
          name,
          authorities,
          role,
        });
        history.push({ pathname: '/', state: { from } })
      } else {
        setError({ isValid: "Incorrect username or password" });
      }
    });
  };

  /**
   * This method is used to handle change text
   *
   * @param {String} key
   * @param {Object} e
   */
  const handleChange = (key, e) => {
    setError({ username: "", password: "", isValid: "" });
    setLoginData({ ...loginData, [key]: e.target.value && key === 'username' ? e.target.value.toLowerCase() : e.target.value });
  };

  /**
   * Tjis method is used to validate login form
   *
   * @returns
   */
  const validateForm = () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;
    let passwordError = "";
    let usernameError = "";
    if (loginData.password === "") {
      passwordError = "This field is mandatory";
      isValid = false;
    } else if (loginData.password.length < 5) {
      passwordError = "Password must have 5 letters";
      isValid = false;
    }
    if (loginData.username === "" && isValid) {
      usernameError = "This field is mandatory";
      isValid = false;
    } else if (!regexp.test(loginData.username)) {
      usernameError = "Invalid email format";
      isValid = false;
    }
    setError({ password: passwordError, username: usernameError });
    return isValid;
  };

  /**
   * This method is used to handle submit form
   *
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      handleSubmitForm();
    }
  };

  return (
    <>
      <Login
        handleSubmitForm={handleSubmitForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        loginData={loginData}
        error={error}
        submitting={submitting}
        modal={modal}
        toggle={toggle}
        handleForgotPasswordSubmit={handleForgotPasswordSubmit}
        forgotPasswordForm={forgotPasswordForm}
        handleChangeForgotPassword={handleChangeForgotPassword}
        successModal={successModal}
        toggleSuccessModal={toggleSuccessModal}
        toggleClose={toggleClose}
        userNameValidation={userNameValidation}
      />
    </>
  );
};

export default LoginContainer;
