/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ===== TAG COMPONENTS =========== */
import Dropdown from "../../core/Dropdown";
import DropdownToggle from "../../core/DropdownToggle";
import DropdownMenu from "../../core/DropdownMenu";
import Input from "../../core/Input";

/** ================== MODULE STYLES ================ */
import styles from "./Select.module.css";

const Select = React.memo((props) => {
  const {
    className, name, id, value,
    options, onChange, render, innerRef, invalid,
    typeahead, typeaheadPlaceholder, excluded, disabled,
  } = props;
  const hasTypeAhead = Boolean(typeahead);
  const localExcluded = Array.isArray(excluded) ? excluded : [];

  const [selectedValue, setSelectedValue] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [typeaheadText, setTypeaheadText] = useState("");

  /**
   * Used to change selected option
   * @param {String} selected
   */
  const toggleDropdown = (selected) => {
    onChange({ target: { value: selected, name } });
    if (hasTypeAhead) {
      setTypeaheadText("");
    }
    setDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    /** fist priory check goes to option value and value noting found check for default selected option else fist element  */
    const defaultValue = options.filter((option) => (option.value === value))[0] || options.filter((option) => (option.selected))[0] || options[0];
    if (!hasTypeAhead) {
      setSelectedValue(defaultValue || {});
    }
  }, [value, options, typeahead, hasTypeAhead]);


  useEffect(() => {
    if (hasTypeAhead) {
      if (Array.isArray(options)
        && options.length > 0) {
        const newSelectedValue = options.find((any) => any.value === value);
        if (newSelectedValue) {
          setSelectedValue(newSelectedValue);
        }
      }
    }
  }, [options, value, hasTypeAhead]);


  const stopPropagation = (event) => event.stopPropagation();

  /**
   * This method is used to change input text filed type ahead
   * @param {Event} event
   */
  const handleTypeAhead = (event) => {
    stopPropagation(event);
    const { value: localValue } = event.target;
    setTypeaheadText(localValue);
    if (typeahead) typeahead(localValue);
  };

  return (
    <Dropdown
      id={id}
      disabled={disabled}
      name={name}
      isOpen={dropdownOpen}
      className={clsx(styles.dropDown, invalid ? "is-invalid" : "", invalid ? styles.invalid : "", disabled ? styles.disabled : "")}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle disabled={disabled} className={clsx(className, "form-control")} caret>
        <span className="text-truncate" disabled={selectedValue.disabled}>
          {selectedValue.label}
        </span>
        <input name={name} type="hidden" defaultValue={value} ref={innerRef} />
      </DropdownToggle>
      <DropdownMenu className={styles.dropdownWrapper}>
        {
          hasTypeAhead && <Input placeholder={typeaheadPlaceholder} onFocus={stopPropagation} className={styles.typeahead} onChange={handleTypeAhead} value={typeaheadText} />
        }
        {Array.isArray(options)
          && options.filter((g) => !localExcluded.includes(g.value)).map((option) => (
            render ? render(option) : (
              <div
                key={option.value}
                disabled={option.disabled}
                active={option.value === selectedValue.value ? "selected" : null}
                onKeyPress={() => { }}
                role="button"
                tabIndex="0"
                className={styles.dropdownItem}
                onClick={(o) => !option.disabled && toggleDropdown(option.value, o)}
              >
                {option.label}
              </div>
            )))}
      </DropdownMenu>
    </Dropdown>
  );
});

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
  })).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  render: PropTypes.func,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
  invalid: PropTypes.bool,
  typeahead: PropTypes.func,
  typeaheadPlaceholder: PropTypes.string,
  excluded: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  className: "",
  render: null,
  innerRef: null,
  invalid: false,
  typeahead: null,
  typeaheadPlaceholder: null,
  excluded: [],
  disabled: false,
};

export default Select;

/**
 * Example usage
 *
 * <Select
 *   className="classNme"
 *   placeholder="Select form options"
 *   name="name"
 *   id="id"
 *   onChange={  onChange }
 *   options={[
 *       {
 *       selected: true, disabled: false, value: "", label: `Select Element`,
 *   },
 *   ...(
 *       Array.isArray( options ) &&
 *       options.map((option) => ({
 *           value: option.value,
 *           label: option.label
 *       }))
 *       )
 *   ]}
 *   value={ value }
 * />
 *
 *
 *
 *  options: PropTypes.arrayOf(PropTypes.shape({
 *   value: PropTypes.string,
 *   label: PropTypes.string,
 *   selected: PropTypes.bool,
 *   disabled: PropTypes.bool,
 * })).isRequired,
 *
 */
