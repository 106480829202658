/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Akshay raj
 */

import React, { useEffect, useState } from "react";
import { getSearchSettings, updateSearchSettings } from "../../../api/searchSettingServices";
import LocaleDropDownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";
import SearchSettingsManagement from "../components/SearchSettingsManagement";
import { getCatalogConfiguration } from "../../../api/productManagementServices";


const SearchSettingsManagementContainer = () => {
  const [siteSettings, setSiteSettings] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    getCatalogConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && Array.isArray(data.brands)) {
          const availableBrands = data.brands.map((brand) => ({ label: brand, value: brand }));
          const [defaultBrand] = data.brands;
          setBrand(defaultBrand);
          setBrands(availableBrands);
          fetchSearchSettings(defaultBrand);
        }
      }
    });
  }, []);

  const handleBrandChange = (selectedBrand) => {
    setBrand(selectedBrand);
    fetchSearchSettings(selectedBrand);
  };

  const fetchSearchSettings = (brandId) => {
    const searchConfigurationView = { code: "SiteSettings", brand: brandId };
    getSearchSettings(searchConfigurationView).then((response) => {
      const customization = [];
      if (response
        && response.data
        && response.data.defaultConfiguration
        && Array.isArray(response.data.defaultConfiguration)) {
        response.data.defaultConfiguration.forEach((siteSetting) => {
          const {
            fieldType, field, active, attributes,
          } = siteSetting;
          customization.push({
            fieldType, field, active, attributes,
          });
        });
      }
      setSiteSettings(customization);
    });
  };

  const updateSettings = ((active, field) => {
    let fieldType = "";
    let attributes = {};
    let indexFlag;
    const localSite = siteSettings;
    localSite.forEach((siteSetting, index) => {
      if (siteSetting.field === field) {
        fieldType = siteSetting.fieldType;
        attributes = siteSetting.attributes;
        indexFlag = index;
      }
    });
    localSite[indexFlag] = {
      field, fieldType, active, attributes,
    };
    const updateData = { code: "SiteSettings", brand: brand, defaultConfiguration: localSite };
    updateSearchSettings(updateData).then((response) => {
      const customization = [];
      if (response
        && response.data
        && response.data.defaultConfiguration
        && Array.isArray(response.data.defaultConfiguration)) {
        response.data.defaultConfiguration.forEach((siteSetting) => {
          customization.push({
            fieldType: siteSetting.fieldType,
            field: siteSetting.field,
            active: siteSetting.active,
            attributes: siteSetting.attributes,
          });
        });
      }
      setSiteSettings(customization);
    });
  });

  return (<SearchSettingsManagement
        updateSettings={updateSettings}
        siteSettings={siteSettings}
        LocaleDropDownContainer={LocaleDropDownContainer}
        brandId={brand}
        handleBrandChange={handleBrandChange}
        brands={brands}
    />);
};

export default SearchSettingsManagementContainer;
