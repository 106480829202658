/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/b2bCustomer.module.css";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import validateForm from "../../../common/utils/validateForm";
import validationConstants from "../../../common/utils/validationConstants";
import Alert from "../../../common/core/Alert";

const EditCustomer = ({
  customerDetails, onHandleCancel, handleFormChange, openConfirm, onHandleSave, submitting, message,
}) => {
  const { t } = useTranslation(["common", "b2bcustomer"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  /** validation methods */
  const { register, errors } = validator;
  const { IS_EMAIL, IS_NUMBER } = validationConstants;

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Form onSubmit={handleSubmit(onHandleSave)}>
        <fieldset disabled={submitting}>
          <Row className="common-page-card pageHeader">
            <Col xs="12">
              <span className="pageText">{t("b2bcustomer:label_customer_title")}</span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "common-page-card ")}>
            <Col xs="12">
              <Row className="m-0 ">
                <Col xs="6" className="p-0">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    {t("b2bcustomer:label_b2bCustomerEdit")}
                  </span>
                </Col>
                <Col className="mb-2" xs="6" sm>
                  <div className={styles.areaActionRight}>
                    <div>
                      <Button
                        color="secondary"
                        className={clsx(
                          styles.borderRadius2,
                          styles.btn,
                          styles.secondaryColor,
                          "text-uppercase",
                          "text-truncate",
                          "btn-tertiary",
                          "ml-1",
                        )}
                        onClick={onHandleCancel}
                      >
                        {t("common:button_cancel")}
                      </Button>
                      <Button
                        color="primary"
                        className={clsx(
                          styles.borderRadius2,
                          "text-uppercase",
                          "text-truncate",
                          "btn-tertiary",
                          "ml-1",
                        )}
                        type="submit"
                      >
                        {t("common:button_save")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_customer_Account")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="customerAccount"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_customer_Account"),
                            })
                          }
                          invalid={!!(errors.Customeraccount && errors.Customeraccount.message)}
                          value={customerDetails.customerAccount}
                        />
                        <FormFeedback>
                          {errors.Customeraccount && errors.Customeraccount.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_invoiceAccount")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="invoiceAccount"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_invoiceAccount"),
                            })
                          }
                          invalid={!!(errors.invoiceaccount && errors.invoiceaccount.message)}
                          value={customerDetails.invoiceAccount}
                        />
                        <FormFeedback>{errors.invoiceaccount && errors.invoiceaccount.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_name")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="name"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_name"),
                            })
                          }
                          invalid={!!(errors.name && errors.name.message)}
                          value={customerDetails.name}
                        />
                        <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_type")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="type"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_type"),
                            })
                          }
                          invalid={!!(errors.type && errors.type.message)}
                          value={customerDetails.type}
                        />
                        <FormFeedback>{errors.type && errors.type.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_siteid")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="siteid"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_siteid"),
                            })
                          }
                          invalid={!!(errors.siteid && errors.siteid.message)}
                        />
                        <FormFeedback>{errors.siteid && errors.siteid.message}</FormFeedback>
                      </FormGroup>
                    </Col> */}
                    {/* <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_status")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="Status"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_status"),
                            })
                          }
                          invalid={!!(errors.Status && errors.Status.message)}
                        />
                        <FormFeedback>{errors.Status && errors.Status.message}</FormFeedback>
                      </FormGroup> */}
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_currency")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="currency"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_currency"),
                            })
                          }
                          invalid={!!(errors.currency && errors.currency.message)}
                          value={customerDetails.currency}
                        />
                        <FormFeedback>{errors.currency && errors.currency.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_email")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="email"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:label_email_required"),
                              pattern: {
                                value: IS_EMAIL,
                                message: t("b2bcustomer:validation_label_email"),
                              },
                            })
                          }
                          invalid={!!(errors.email && errors.email.message)}
                          value={customerDetails.email}
                        />
                        <FormFeedback>{errors.email && errors.email.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:classification")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="classification"
                          onChange={(e) => handleFormChange(e)}
                          value={customerDetails.classification}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_classification"),
                            })
                          }
                          invalid={!!(errors.classification && errors.classification.message)}
                        />
                        <FormFeedback>{errors.classification && errors.classification.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:pricelist")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="priceList"
                          onChange={(e) => handleFormChange(e)}
                          value={customerDetails.priceList}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_pricelist"),
                            })
                          }
                          invalid={!!(errors.priceList && errors.priceList.message)}
                        />
                        <FormFeedback>{errors.priceList && errors.priceList.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:paymentmethod")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="paymentMethod"
                          onChange={(e) => handleFormChange(e)}
                          value={customerDetails.paymentMethod}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_paymentmethod"),
                            })
                          }
                          invalid={!!(errors.paymentMethod && errors.paymentMethod.message)}
                        />
                        <FormFeedback>{errors.paymentMethod && errors.paymentMethod.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col xs="6">
                      <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                        <Label title={t("b2bcustomer:label_enable")} className="formTextLabel text-truncate">{t("b2bcustomer:label_enable")}</Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-1",
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enableB2BCustomer-edit-customer"
                            name="enableB2BCustomer"
                            checked={customerDetails.active}
                            onChange={() => openConfirm(customerDetails, customerDetails.active)}
                          />
                          <Label className="custom-control-label" htmlFor="enableB2BCustomer-edit-customer" />
                        </div>
                      </FormGroup>
                    </Col> */}
                    <Row className={clsx(styles.tableWrapper, "common-page-card")}>
                      <Col>
                        <Label className="formTextLabel">{t("b2bcustomer:label_shippingaddress")}</Label>
                      </Col>
                      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
                        <Col xs="6">
                          <FormGroup>

                            <Label className="formTextLabel">{t("b2bcustomer:label_contact_person")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="contactPerson"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_fname")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="firstName"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_lname")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="lastName"
                              onChange={(e) => handleFormChange(e)}
                              value={customerDetails.lastName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_addressline")}</Label>
                            <span className="pl-1 text-danger">*</span>
                            <Input
                              className="formText"
                              placeholder=""
                              name="addressLine1"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:validation_label_addressline"),
                                })
                              }
                              invalid={!!(errors.address && errors.address.message)}
                              value={customerDetails.addressLine1}
                            />
                            <FormFeedback>{errors.address && errors.address.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_area")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="area"
                              onChange={(e) => handleFormChange(e)}
                              invalid={!!(errors.area && errors.area.message)}
                              value={customerDetails.area}
                            />
                            <FormFeedback>{errors.area && errors.area.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_city")}</Label>
                            <span className="pl-1 text-danger">*</span>
                            <Input
                              className="formText"
                              placeholder=""
                              name="city"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:validation_label_city"),
                                })
                              }
                              invalid={!!(errors.City && errors.City.message)}
                              value={customerDetails.city}
                            />
                            <FormFeedback>{errors.City && errors.City.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_country")}</Label>
                            <span className="pl-1 text-danger">*</span>
                            <Input
                              className="formText"
                              placeholder=""
                              name="country"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:validation_label_country"),
                                })
                              }
                              invalid={!!(errors.country && errors.country.message)}
                              value={customerDetails.country}
                            />
                            <FormFeedback>{errors.country && errors.country.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_zipCode")}</Label>
                            <span className="pl-1 text-danger">*</span>
                            <Input
                              className="formText"
                              placeholder=""
                              name="zipcode"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:validation_label_zipcode"),
                                })
                              }
                              invalid={!!(errors.zipcode && errors.zipcode.message)}
                              value={customerDetails.postCode}
                            />
                            <FormFeedback>{errors.zipcode && errors.zipcode.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_email")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="shippingEmail"
                              onChange={(e) => handleFormChange(e)}
                            />
                            <FormFeedback>{errors.shippingEmail && errors.shippingEmail.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_dialcode")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="dialCode"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_telephone")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="telephone"
                              innerRef={
                                register({
                                  pattern: {
                                    value: IS_NUMBER,
                                    message: t("b2bcustomer:validation_label_mobile"),
                                  },
                                })
                              }
                              invalid={!!(errors.Mobile && errors.Mobile.message)}
                              value={customerDetails.telephone}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_mobile")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="mobile"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  pattern: {
                                    value: IS_NUMBER,
                                    message: t("b2bcustomer:validation_label_mobile"),
                                  },
                                })
                              }
                              invalid={!!(errors.Mobile && errors.Mobile.message)}
                              value={customerDetails.mobile}
                            />
                            <FormFeedback>{errors.Mobile && errors.Mobile.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_note")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="note"
                              onChange={(e) => handleFormChange(e)}
                              invalid={!!(errors.notes && errors.notes.message)}
                              value={customerDetails.note}
                            />
                            <FormFeedback>{errors.notes && errors.notes.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Row>
                    {/* <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_created_date")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="cdate"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_created_date"),
                            })
                          }
                          invalid={!!(errors.cdate && errors.cdate.message)}
                        />
                        <FormFeedback>{errors.cdate && errors.cdate.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_updated_date")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="updateddate"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_updated_date"),
                            })
                          }
                          invalid={!!(errors.updateddate && errors.updateddate.message)}
                        />
                        <FormFeedback>{errors.updateddate && errors.updateddate.message}</FormFeedback>
                      </FormGroup>
                    </Col> */}
                    {/* <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("b2bcustomer:label_custome_attribute")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="customeattribute"
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("b2bcustomer:validation_label_custome_attribute"),
                            })
                          }
                          invalid={!!(errors.customeattribute && errors.customeattribute.message)}
                        />
                        <FormFeedback>{errors.customeattribute && errors.customeattribute.message}</FormFeedback>
                      </FormGroup>
                    </Col> */}
                    {/* <Row className={clsx(styles.tableWrapper, "common-page-card")}>
                      <Col>
                        <Label className="formTextLabel">{t("b2bcustomer:label_users")}</Label>
                      </Col>
                      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_username")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="username"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:validation_label_username"),
                                })
                              }
                              invalid={!!(errors.username && errors.username.message)}
                            />
                            <FormFeedback>{errors.username && errors.username.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_password")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="password"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:validation_label_password"),
                                })
                              }
                              invalid={!!(errors.password && errors.password.message)}
                            />
                            <FormFeedback>{errors.password && errors.password.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_roles")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="roles"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:validation_label_roles"),
                                })
                              }
                              invalid={!!(errors.roles && errors.roles.message)}
                            />
                            <FormFeedback>{errors.roles && errors.roles.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_email")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="address"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:label_email_required"),
                                  pattern: {
                                    value: IS_EMAIL,
                                    message: t("b2bcustomer:validation_label_email"),
                                  },
                                })
                              }
                              invalid={!!(errors.address && errors.address.message)}
                            />
                            <FormFeedback>{errors.address && errors.address.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">{t("b2bcustomer:label_contact_number")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="number"
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("b2bcustomer:mobile_required"),
                                  pattern: {
                                    value: IS_NUMBER,
                                    message: t("b2bcustomer:validation_label_mobile"),
                                  },
                                })
                              }
                              invalid={!!(errors.number && errors.number.message)}
                            />
                            <FormFeedback>{errors.number && errors.number.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Row> */}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>

    </>
  );
};

EditCustomer.propTypes = {
  onHandleCancel: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  customerDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  openConfirm: PropTypes.func.isRequired,
  onHandleSave: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default EditCustomer;
