/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Order Management Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import OrderManagement from "../components/OrderManagement";

/** ===== API SERVICE FUNCTIONS =========== */
import {
  getOrders,
  transformOrderData, getB2BOrderDetails,
  repostOrderByOrderId,
} from "../../../api/orderServices";
import constants from "../../../common/utils/constants";
import OrderDetailsContainer from "../../orderDetails/containers/OrderDetailsContainer";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import {
  getCatalogConfiguration,
} from "../../../api/productManagementServices";

const moment = require("moment");
const Datetime = require("react-datetime");

const {
  ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE,
  STATUS_INCOMPLETE, STATUS_MERGED, STATUS_ABANDONED,
} = constants;
const OrderManagementContainer = () => {
  /** state variables */
  const [orders, setOrders] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [isProccessing, setIsProccessing] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [salesCompany, setSalesCompany] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [salesCompanyList, setSalesCompanyList] = useState([]);
  const [catalogType, setCatalogType] = useState(null);
  const [isConfirmationMailSent, setIsConfirmationMailSent] = useState("");
  const [isOrderExportedToOms, setIsOrderExportedtoOms] = useState("");
  const [totalNumberOfOrders, setTotalNumberOfOrders] = useState(0);
  const [repostingOrder, setRepostingOrder] = useState(false);
  const [selectedOrderIdToRepost, setSelectedOrderIdToRepost] = useState({'orderNo': null, 'orderId': null});
  const [isRepostOrderModalOpen, setRepostOrderModalOpen] = useState(false);
  const orderType = [
    {
      label: "Pre order",
      value: "PreOrder",
    },
    {
      label: "Re order",
      value: "ReOrder",
    },
  ];
  const orderExportStatus = [
    {
      label: "Exported",
      value: true,
    },
    {
      label: "Submitted",
      value: false,
    },
  ];

  const orderEmailConfirmation = [
    {
      label: "Successful",
      value: true,
    },
    {
      label: "Waiting",
      value: false,
    },
  ];
  useEffect(() => {
    getCatalogConfiguration().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setSalesCompanyList(data.salesCompanies);
      }
    });
  }, []);
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
  };
  const sortDir = "desc";
  const onHandleSearch = (direction) => {
    let newPage = page + direction;
    const sortBy = ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE;
    const searchData = {
      startDate: fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      endDate: toDate
        ? moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : null,
      searchTerm: searchTerm ? searchTerm.trim() : "",
      excludedStatuses: [STATUS_INCOMPLETE, STATUS_MERGED, STATUS_ABANDONED],
    };
    const pageLimit = Math.max(newPage, 0);
    const size = paginationLimit;
    getOrders(searchData, pageLimit, size, sortBy, sortDir).then((response) => {
      if (response && response.success === true) {
        const {
          data: { content }, totalElements,
        } = response;
        if (Array.isArray(content)) {
          setTotalNumberOfOrders(totalElements);
          setOrders(content);
          setHasMoreData(true);
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 0));
      }
    });
  };
  /**
   * This method is used to list data
   * @param {String} localSearchTerm
   * @param {Number} newPage
   */
  const sortBy = ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE;
  const getB2BOrders = async (localSearchTerm, salesCompanyId, catalog, orderConfirmationEmailSent = isConfirmationMailSent, orderExportedToOms = isOrderExportedToOms, newPage = 0, paginationLimitin = itemsPerPage) => {
    setIsProccessing(true);
    const searchData = {
      startDate: fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      endDate: toDate
        ? moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : null,
      searchTerm: localSearchTerm ? localSearchTerm.trim() : "",
      salesCompany: salesCompanyId || "",
      catalogType: catalog || null,
      orderConfirmationEmailSent,
      orderExportedToOms,
    };
    await getB2BOrderDetails(searchData, newPage, paginationLimitin, sortBy, sortDir).then((response) => {
      setIsProccessing(false);
      if (response && response.data) {
        const {
          content, endOfResult, totalPages, totalElements,
        } = response.data;
        if (Array.isArray(content)) {
          setOrders(content);
          setHasMoreData(!endOfResult);
          setTotalPagesCount(totalPages);
          setTotalNumberOfOrders(totalElements);
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 0));
      }
    });
  };
  useEffect(() => {
    getB2BOrders(searchTerm, salesCompany, catalogType, isConfirmationMailSent, isOrderExportedToOms);
  }, [fromDate, toDate, paginationLimit, searchTerm]);
  const onPageChange = (data) => {
    const { selected } = data;
    getB2BOrders(searchTerm, salesCompany, catalogType, isConfirmationMailSent, isOrderExportedToOms, selected, itemsPerPage);
  };
  useEffect(() => {
    getB2BOrders();
  }, [itemsPerPage]);
  const getPageData = (direction) => {
    getB2BOrders(direction);
  };

  const initializeData = async () => {
    setIsConfirmationMailSent("");
    setIsOrderExportedtoOms("");
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    setPage(0);
    setPaginationLimit(10);
    setHasMoreData(true);
    setIsProccessing(false);
    setSalesCompany("");
    setCatalogType("");
    getB2BOrders();
  };

  /**
                 * This function returns the true or false to disable the previous and future dates in datepicker
                 */
  const isValidDate = (date, type) => {
    if (type === "ORDER_DATE_FROM") {
      if (toDate) {
        return date.isBefore(moment(toDate, "DD-MM-YYYY").add(1, "days"));
      }
      return date.isBefore(Datetime.moment());
    }
    if (type === "ORDER_DATE_TO") {
      if (fromDate) {
        return (
          date.isSameOrAfter(moment(fromDate, "DD-MM-YYYY"))
          && date.isSameOrBefore(Datetime.moment())
        );
      }
      return date.isSameOrBefore(Datetime.moment());
    }
  };

  const onHandleExport = async (type) => {
    const searchData = {
      startDate: fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      endDate: toDate
        ? moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : null,
      searchTerm,
      salesCompany: salesCompany || "",
      catalogType: catalogType || "",
      orderConfirmationEmailSent: isConfirmationMailSent || "",
      orderExportedToOms: isOrderExportedToOms || "",
    };
    setIsProccessing(true);
    const page = 0;
    const size = 0;
    getB2BOrderDetails(searchData, page, size, sortBy, sortDir)
      .then(async (response) => {
        if (response && response.success === true) {
          const {
            data: { content },
          } = response;
          if (Array.isArray(content)) {
            const data = await transformOrderData(content);
            let fileName = "Orders.xlsx";
            if (type === "CSV") {
              fileName = "Orders.csv";
            }
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Orders");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 30 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsProccessing(false);
      });
    setIsProccessing(false);
  };

  const onHandleBack = (id) => {
    setSelectedOrderId(id);
  };

  const onHandleClear = () => {
    initializeData();
  };

  const onClickRow = (row) => {
    const { id } = row;
    setSelectedOrderId(id);
  };
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getB2BOrders(value); } else getB2BOrders();
  };
  const handleChange = (key, value) => {
    if (key === "salesCompany") {
      setSalesCompany(value);
      getB2BOrders(null, value, catalogType);
    }
    if (key === "orderType") {
      setCatalogType(value);
      getB2BOrders(null, salesCompany, value);
    }
    if (key === "orderEmailConfirmation") {
      setIsConfirmationMailSent(value);
      getB2BOrders(null, null, null, value, isOrderExportedToOms);
    }
    if (key === "orderExportStatus") {
      setIsOrderExportedtoOms(value);
      getB2BOrders(null, null, null, isConfirmationMailSent, value);
    }
  };
  if (selectedOrderId) {
    return (
      <OrderDetailsContainer
        onHandleBack={onHandleBack}
        selectedOrderId={selectedOrderId}
      />
    );
  }
  const repostOrderToOms = async () => {
    setRepostingOrder(true);
    if (null !== selectedOrderIdToRepost.orderId) {
      await repostOrderByOrderId(selectedOrderIdToRepost.orderId).then((response) => {
        if (response) {
          setSelectedOrderIdToRepost({'orderNo': null, 'orderId': null});
          setRepostingOrder(false);
          closeRepostOrderModal();
          getB2BOrders();
        }
      }).catch((err) => {
        setRepostingOrder(false);
      });;
    }
  }

  const isOrderEligibleForRepost = (row) => {
    if (!row.orderExportedToOms || !row.orderConfirmationEmailSent) {
      return true;
    }
  }

  const openRepostOrderModal = (row) => {
    setSelectedOrderIdToRepost({'orderNo': row.orderNo, 'orderId': row.id});
    setRepostOrderModalOpen(true);
    
  }
  const closeRepostOrderModal = () => {
    setSelectedOrderIdToRepost({'orderNo': null, 'orderId': null});
    setRepostOrderModalOpen(false);
  }



  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_OPRTN_ORDR_MGMNT_ORDR_VIEW_LIST}
    >
      <OrderManagement
        setFromDate={setFromDate}
        setToDate={setToDate}
        fromDate={fromDate}
        toDate={toDate}
        setSearchTerm={setSearchTerm}
        onHandleSearch={onHandleSearch}
        orders={orders}
        page={page}
        onPageChange={onPageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        totalPagesCount={totalPagesCount}
        itemsPerPage={itemsPerPage}
        getPageData={getPageData}
        hasMoreData={hasMoreData}
        isValidDate={isValidDate}
        isProccessing={isProccessing}
        onHandleExport={onHandleExport}
        onClickRow={onClickRow}
        onHandleClear={onHandleClear}
        searchTerm={searchTerm}
        handleSearchTerm={handleSearchTerm}
        salesCompanyList={salesCompanyList}
        handleChange={handleChange}
        salesCompany={salesCompany}
        orderType={orderType}
        catalogType={catalogType}
        orderExportStatus={orderExportStatus}
        orderEmailConfirmation={orderEmailConfirmation}
        isOrderExportedToOms={isOrderExportedToOms}
        isConfirmationMailSent={isConfirmationMailSent}
        totalNumberOfOrders={totalNumberOfOrders}
        repostOrderToOms={repostOrderToOms}
        repostingOrder={repostingOrder}
        isOrderEligibleForRepost={isOrderEligibleForRepost}
        openRepostOrderModal={openRepostOrderModal}
        closeRepostOrderModal={closeRepostOrderModal}
        isRepostOrderModalOpen={isRepostOrderModalOpen}
        selectedOrderIdToRepost={selectedOrderIdToRepost}
      />
    </Permission>
  );
};

export default OrderManagementContainer;
