/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import styles from "../css/ViewSalesRep.module.css";
import CustomizationTable from "../../../common/components/Table";
import Label from "../../../common/core/Label";
import Spinner from "../../../common/core/Spinner";

const ViewSalesRepresentativeInfo = ({
  salesRepInfo, onHandleCancel, departments, handleAccessibleOrganizationStatus, disableChecked, enableChecked, onChangeCheckbox, selectAllCheckboxes, selectedB2BDepartments, isLoading,
}) => {
  const { t } = useTranslation(["common", "b2bcustomer"]);
  const [departmentOpen, setSalesRepresentativeOpen] = useState(false);
  const toggleSalesRepresentative = () => setSalesRepresentativeOpen(!departmentOpen);

  const departmentCustomRows = [
    {
      label: <input
        type="checkbox"
        id="accessibleOrganizationsCheckBoxAll"
        name="accessibleOrganizationsCheckBoxAll"
        onChange={selectAllCheckboxes}
        checked={departments.every((each) => selectedB2BDepartments.includes(each.id))}
      />,
      path: "render",
      render: (row) => (
        <input
          type="checkbox"
          id={`checkbox${row.id}`}
          name="accessibleOrganizationsCheckBox"
          onChange={() => onChangeCheckbox(row.id)}
          checked={selectedB2BDepartments.includes(row.id)}
        />
      ),
    },
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Email",
      value: "",
      path: "email",
      transform: "",
    },
    {
      label: "Enable",
      value: "",
      path: "render",
      render: (row) => (
        <>
          <div
            className={clsx(
              "custom-control",
              "custom-switch",
              "ml-1",
            )}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`department${row.id}`}
              name="accessibleOrganizations"
              checked={row.active}
              onChange={(o) => { handleAccessibleOrganizationStatus(row, !row.active); }}
            />
            <Label className="custom-control-label" htmlFor={`department${row.id}`} />
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <Form>
        <fieldset>
          <Row className="common-page-card pageHeader">
            <Col xs="12">
              <span className="pageText">{t("b2bcustomer:label_SalesReps_title")}</span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "common-page-card ")}>
            <Col xs="12">
              <Row className="m-0 ">
                <Col xs="6" className="p-0">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    {t("b2bcustomer:label_sales_rep_details")}

                  </span>
                </Col>
                <Col className="mb-2" xs="6" sm>
                  <div className={styles.areaActionRight}>
                    <div>
                      <Button
                        color="secondary"
                        className={clsx(
                          styles.borderRadius2,
                          styles.btn,
                          styles.secondaryColor,
                          "text-uppercase",
                          "text-truncate",
                          "btn-tertiary",
                          "ml-1",
                        )}
                        onClick={onHandleCancel}
                      >
                        {t("b2bcustomer:back")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              {isLoading || !salesRepInfo ? (
                <Row className="h-100">
                  <Col
                    xs="12"
                    className={styles.spinnerWrapper}
                  >
                    <div
                      className="h-100  d-flex align-items-center justify-content-center"
                    >
                      <Spinner />
                    </div>
                  </Col>
                </Row>
              )
                : (
                  <>
                    <div className={styles.displayData}>
                      <ul className={styles.dataTableList}>
                        <li>
                          <div className={styles.dataTableTitle}>Name:</div>
                          <div className={styles.dataTableText}>
                            {" "}
                            {salesRepInfo.fullName ? salesRepInfo.fullName : `${salesRepInfo.firstName || ""} ${salesRepInfo.lastName || ""}`}
                          </div>
                        </li>
                        <li>
                          <div className={styles.dataTableTitle}>Dial Number:</div>
                          <div className={styles.dataTableText}>
                            {" "}
                            {salesRepInfo.dialCode}
                          </div>
                        </li>
                        <li>
                          <div className={styles.dataTableTitle}>Phone number:</div>
                          <div className={styles.dataTableText}>
                            {" "}
                            {salesRepInfo.mobile}
                          </div>
                        </li>
                        <li>
                          <div className={styles.dataTableTitle}>
                            Email:
                          </div>
                          <div className={styles.dataTableText}>
                            {salesRepInfo.email}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
            </Col>
          </Row>
          {departments && departments.length > 0 ? (
            <Row className={clsx(styles.block3, "pageHeader common-page-card")}>
              <Col xs="12">
                <h5>Customers</h5>
                <span className="float-right">
                  <span onClick={toggleSalesRepresentative}>
                    {departmentOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      </svg>
                    )}
                  </span>
                </span>
                <div className="mt-3">
                  <span
                    onClick={(o) => o}
                    onKeyDown={() => { }}
                    tabIndex={0}
                    role="button"
                    className="mt-3"
                  />
                </div>
                {departmentOpen && (
                  <div className={(styles.dataTableTitle, "mt-3")}>
                    <div className={styles.areaActionRight}>
                      {selectedB2BDepartments.length > 0 ? (
                        <div>
                          <Button
                            onClick={enableChecked}
                            color="secondary"
                            className={clsx(
                              styles.borderRadius2,
                              styles.btn,
                              styles.secondaryColor,
                              "text-uppercase",
                              "text-truncate",
                              "btn-tertiary",
                              "ml-1",
                            )}
                          >
                            {t("b2bcustomer:label_enable_title")}

                          </Button>
                          <Button
                            onClick={disableChecked}
                            color="secondary"
                            className={clsx(
                              styles.borderRadius2,
                              styles.btn,
                              styles.secondaryColor,
                              "text-uppercase",
                              "text-truncate",
                              "btn-tertiary",
                              "ml-1",
                            )}
                          >
                            {t("b2bcustomer:label_disable_title")}

                          </Button>
                        </div>
                      ) : ""}
                    </div>
                    <div className="custom-table">
                      <CustomizationTable
                        customRows={departmentCustomRows}
                        customizationData={departments}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          ) : null}
        </fieldset>
      </Form>
    </>
  );
};

ViewSalesRepresentativeInfo.propTypes = {
  salesRepInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  departments: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  handleAccessibleOrganizationStatus: PropTypes.func.isRequired,
  selectAllCheckboxes: PropTypes.func.isRequired,
  disableChecked: PropTypes.func.isRequired,
  enableChecked: PropTypes.func.isRequired,
  selectedB2BDepartments: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default ViewSalesRepresentativeInfo;
