/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Midhun Murali
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import moment from "moment";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import styles from "../css/B2BAnalytics.module.css";
import AnalyticsFilter from "./AnalyticsFilter";
import GraphManager from "./GraphManager";
import PrefaceManager from "./PrefaceManager";

const B2BAnalytics = ({
  getIconAndBackgroundColor, prefaceData, selectedSalesCompany, isLoading, brandData, barData,
  isValidDate, setToDate, setFromDate, toDate, fromDate, resetFilterChange, catalogType,
  catalogTypeList, timeLineTypes, timeLineType, handleFilterChange,
  salesCompany, salesCompanyList, brand, brandList, lineData,
}) => {
  const { t } = useTranslation(["common", "b2bAnalytics"]);
  return (
    <>
      <Row className={clsx(styles.card, "position-fixed pb-4")}>
        <Col>
          <Row className="h5 pl-3 pt-4">
            <Col>
              <span className="pageText"><strong>{t("b2bAnalytics:analytics_title")}</strong></span>
            </Col>
          </Row>
        </Col>

        <Col xs="12 pt-4">
          <Row className="mx-0 bg-white pageHeader common-page-card ">
            <AnalyticsFilter
              resetFilterChange={resetFilterChange}
              catalogType={catalogType}
              catalogTypeList={catalogTypeList}
              timeLineTypes={timeLineTypes}
              handleFilterChange={handleFilterChange}
              salesCompany={salesCompany}
              salesCompanyList={salesCompanyList}
              brand={brand}
              brandList={brandList}
              timeLineType={timeLineType}
              isValidDate={isValidDate}
              setToDate={setToDate}
              setFromDate={setFromDate}
              toDate={toDate}
              fromDate={fromDate}
            />
          </Row>
        </Col>

      </Row>
      <Row className={clsx(styles.topcard, "pt-50")}>
        <Container className={clsx(styles.filterCard, "container-fluid  mw-100 ")}>
          <Row className="d-flex justify-content-between">
            <PrefaceManager
              getIconAndBackgroundColor={getIconAndBackgroundColor}
              prefaceData={prefaceData}
              selectedSalesCompany={selectedSalesCompany}
            />
          </Row>
          <Row className={clsx("d-flex justify-content-between pt-4")}>
            <GraphManager
              isLoading={isLoading}
              brandData={barData}
              barData={brandData}
              lineData={lineData}
            />
          </Row>
        </Container>
      </Row>

    </>
  );
};

B2BAnalytics.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  brandData: PropTypes.shape({}).isRequired,
  barData: PropTypes.shape({}).isRequired,
  lineData: PropTypes.arrayOf(PropTypes.number).isRequired,
  brand: PropTypes.string.isRequired,
  timeLineTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  timeLineType: PropTypes.string.isRequired,
  catalogTypeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  catalogType: PropTypes.string.isRequired,
  salesCompanyList: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandList: PropTypes.arrayOf(PropTypes.any).isRequired,
  prefaceData: PropTypes.arrayOf(PropTypes.any).isRequired,
  salesCompany: PropTypes.string.isRequired,
  selectedSalesCompany: PropTypes.string.isRequired,
  toDate: PropTypes.instanceOf(moment).isRequired,
  fromDate: PropTypes.instanceOf(moment).isRequired,
  // functions
  handleFilterChange: PropTypes.func.isRequired,
  resetFilterChange: PropTypes.func.isRequired,
  getIconAndBackgroundColor: PropTypes.func.isRequired,
  isValidDate: PropTypes.func.isRequired,
  setToDate: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
};

export default B2BAnalytics;
