import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import ViewCategory from "../components/ViewCategory";
import {
  getCategoryDetails, facetConfig,
} from "../../../api/categoryManagementServices";
import { getProductForAdminByCategoryId } from "../../../api/productManagementServices";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const ViewCategoryContainer = () => {
  const { params } = useRouteMatch();
  const [form, setForm] = useState({
    id: "",
    name: "",
    description: "",
    longDescription: "",
    navPosition: "",
    startDate: "",
    endDate: "",
    status: false,
    uniqueId: "",
    type: "",
    source: "",
    navigable: false,
    image: [],
    bannerImage: [],
  });
  const history = useHistory();
  const location = useLocation();
  const brand = (location && location.state && location.state.brandId) || null;
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState({});
  const [categoryFacet, setCategoryFacet] = useState([]);
  useEffect(() => {
    if (params.categoryId) {
      getCategoryDetails(params.categoryId, null, brand).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setForm(data);
        }
      });
    }
  }, [params.categoryId]);

  const onHandleCancel = () => {
    history.push({
        pathname : "/merchandising/category",
        state: { brand }
    });
  };

  const editCategory = (categoryId) => {
    history.push(`/merchandising/category/edit/${categoryId}`);
  };
  useEffect(() => {
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getProductForAdminByCategoryId(cancelToken, params.categoryId, page, itemPerPage, null, brand).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setProducts(data);
      }
    });
  }, [page, itemPerPage]);

  useEffect(() => {
    facetConfig(form.code).then((response) => {
      const customConfig = [];
      if (response && response.success) {
        const { data } = response;
        if (Array.isArray(data.defaultConfiguration)) {
          data.defaultConfiguration.forEach((config) => {
            if (config.level === "category") {
              form.config.active = Array.isArray(config.elements) ? config.elements.includes(form.categoryId) : [];
            } else if (config.level === "global") {
              form.config.active = Array.isArray(config.excludedElementList) ? !config.excludedElementList.includes(form.categoryId) : [];
            }
            customConfig.push(config);
          });
        }
        setCategoryFacet(customConfig);
      }
    });
  }, [form.categoryId, form.code]);
  const initializeData = async () => {
    setSearchTerm("");
    setPage(0);
  };

  const onHandleClear = () => {
    initializeData();
  };

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const onPageChange = (data) => {
    const { selected } = data;
    setPage(selected);
  };
  const handleItemsPerPageChange = (itemCount) => {
    setItemPerPage(itemCount);
  };
  return (
    <ViewCategory
      form={form}
      handleChange={handleChange}
      onHandleCancel={onHandleCancel}
      editCategory={editCategory}
      page={page}
      searchTerm={searchTerm}
      productData={products}
      onHandleClear={onHandleClear}
      facet={categoryFacet}
      onPageChange={onPageChange}
      handleItemsPerPageChange={handleItemsPerPageChange}
      itemPerPage={itemPerPage}
    />
  );
};

export default ViewCategoryContainer;
