import React, { useState } from "react";
import styles from "../css/B2BCustomerDetailsUI.module.css";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import Home from "../../../common/assets/images/svg/home.svg";
import LeftArrow from "../../../common/assets/images/svg/leftArrow.svg";
import Edit from "../../../common/assets/images/svg/edit-icon.svg";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Table from "../../../common/core/Table";
import CustomInput from "../../../common/core/CustomInput";
import Collapse from "../../../common/core/Collapse";

const B2BCustomerDetailsUI = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText"><strong>B2B Customer</strong></span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/#">
                <img src={Home} alt="Home" />
                Home
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem><a href="/#">Administration</a></BreadcrumbItem>
            <BreadcrumbItem><a href="/#">User Management</a></BreadcrumbItem>
            <BreadcrumbItem active>B2B Customer</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <Row className="justify-content-between align-items-center mb-4">
            <Col xs="auto">
              <h5 className="mb-0 d-flex align-items-center">
                <Button color="transparent" className="btn-square mr-3">
                  <img src={LeftArrow} alt="Home" />
                </Button>
                <strong>B2B Customer Details</strong>
              </h5>
            </Col>

            {/* TODO: Buttons should be visible only on edit view */}
            <Col xs="auto">
              <Button color="primary" outline className="mr-2 btn-width">Cancel</Button>
              <Button color="primary" className="btn-width">Save</Button>
            </Col>
          </Row>

          <Row>
            {/* TODO: Add "active" class onclick tabs "tab-item" */}
            <Col xs="12" className="tab-item active">
              <div className="tab-btn" onClick={toggle}>Basic Information</div>

              {/* TODO: default: <Collapse isOpen={isOpen}> :: isOpen={true} is only for first item */}
              <Collapse isOpen>
                <div className="tab-content">
                  <Row className={styles.ContentOuter}>
                    <Col>

                      <Row>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Customer ID</strong></h6>
                          <p>Customer ID1</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Invoice Account</strong></h6>
                          <p>AC19810608</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Customer Name</strong></h6>
                          <p>Mak Fashion</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Email</strong></h6>
                          <p><a href="mailto:b2buseremail@mail.com">b2buseremail@mail.com</a></p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Type</strong></h6>
                          <p>B2B Customer</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Status</strong></h6>
                          <p>Enabled</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Price Book ID</strong></h6>
                          <p>PB222</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Classification</strong></h6>
                          <p>K</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Currency</strong></h6>
                          <p>EUR</p>
                        </Col>
                      </Row>

                    </Col>
                    <Col xs="auto" className="tab-icon-col">
                      <Button color="transparent" className="btn-circle">
                        <img src={Edit} alt="Home" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </Col>

            <Col xs="12" className="tab-item">
              <div className="tab-btn" onClick={toggle}>Sales Representative Details</div>
              <Collapse isOpen={isOpen}>
                <Col xs="12">
                  <Table hover responsive className="table-vcenter mt-3">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Sales Representative 1</td>
                        <td>user@mail.com</td>
                        <td>+91 9751239087</td>
                        <td className="align-top">
                          <CustomInput type="switch" id="customSwitch" name="customSwitch" />
                        </td>
                      </tr>
                      <tr>
                        <td>Sales Representative 2</td>
                        <td>user@mail.com</td>
                        <td>+91 9751239087</td>
                        <td className="align-top">
                          <CustomInput type="switch" id="customSwitch" name="customSwitch" />
                        </td>
                      </tr>
                      <tr>
                        <td>Sales Representative 3</td>
                        <td>user@mail.com</td>
                        <td>+91 9751239087</td>
                        <td className="align-top">
                          <CustomInput type="switch" id="customSwitch" name="customSwitch" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Collapse>
            </Col>

            <Col xs="12" className="tab-item">
              <div className="tab-btn" onClick={toggle}>Shipping Information</div>
              <Collapse isOpen={isOpen}>
                <div className="tab-content">
                  <Row className={styles.ContentOuter}>
                    <Col>

                      <Row>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Contact Person</strong></h6>
                          {/* TODO: Sample Input disabled for edit view */}
                          <Input type="text" value="Mathew Varghese" disabled />
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>First Name</strong></h6>
                          <p>Mathew</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Last Name</strong></h6>
                          <p>Varghese</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Address Line1</strong></h6>
                          {/* TODO: Sample Input for edit view */}
                          <Input type="text" value="Box No: 4031" />
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Area</strong></h6>
                          <p>B2B Customer</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>City</strong></h6>
                          <p>Wiesenfeldstrasse</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Country</strong></h6>
                          <p>Germany</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Email</strong></h6>
                          <p><a href="mailto:b2buseremail@mail.com">b2buseremail@mail.com</a></p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Dial Code</strong></h6>
                          <p>+49</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Telephone</strong></h6>
                          <p>9605335533</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Mobile</strong></h6>
                          <p>9876543210</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>Note</strong></h6>
                          <p>Note goes here..</p>
                        </Col>
                      </Row>

                    </Col>
                    <Col xs="auto" className="tab-icon-col">
                      <Button color="transparent" className="btn-circle">
                        <img src={Edit} alt="Home" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </Col>

            <Col xs="12" className="tab-item">
              <div className="tab-btn" onClick={toggle}>User Details</div>
              <Collapse isOpen={isOpen}>
                <Col xs="12">
                  <Table hover responsive className="table-vcenter mt-3">
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Phone</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>User-B2b-User1</td>
                        <td>user@mail.com</td>
                        <td>Role1</td>
                        <td>+91 9751239087</td>
                        <td className="align-top">
                          <CustomInput type="switch" id="customSwitch" name="customSwitch" />
                        </td>
                      </tr>
                      <tr>
                        <td>User-B2b-User1</td>
                        <td>user@mail.com</td>
                        <td>Role1</td>
                        <td>+91 9751239087</td>
                        <td className="align-top">
                          <CustomInput type="switch" id="customSwitch1" name="customSwitch" />
                        </td>
                      </tr>
                      <tr>
                        <td>User-B2b-User1</td>
                        <td>user@mail.com</td>
                        <td>Role1</td>
                        <td>+91 9751239087</td>
                        <td className="align-top">
                          <CustomInput type="switch" id="customSwitch2" name="customSwitch" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Collapse>
            </Col>
          </Row>
        </Col>
      </Row>

    </>
  );
};

export default B2BCustomerDetailsUI;
