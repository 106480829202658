/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Kishor
 * */
import React, { useState, useEffect } from "react";
import CustomerAuditManagementComponent from "../components/CustomerAuditManagementComponent";
import { getCustomerManagementAudit } from "../../../api/CustomerAuditService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import CustomerStepAuditManagementContainer from "./CustomerStepAuditManagementContainer";


const CustomerAuditManagementContainer = () => {
  const [downloadLinks, setDownloadLinks] = useState();
  const [rowData, setRowData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [status, setStatus] = useState("");
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [listDropdownOpen, setListDropdownOpen] = useState(false);
  const [name, setName] = useState("");
  const [selectedJobId, setSelectedJobId] = useState();
  const jobStatus = [
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "STOPPED", value: "STOPPED" },
    { label: "FAILED", value: "FAILED" },
    { label: "STARTED", value: "STARTED" },
  ];
  const jobType = [
    { label: "Customer Feed Job", value: "b2bCustomerFeedJob" },
    { label: "Customer Delta Feed Job", value: "b2bCustomerDeltaFeedJob" },
  ];

  const getCustomerManagementAuditList = async (name, status, pageNo = 0, pageSize = 10) => {
    try {
      const requestData = { status, page: pageNo, size: pageSize, name };
      const response = await getCustomerManagementAudit(requestData);
      const { content, totalPages } = response?.data;
      setTotalPagesCount(totalPages);
      setAuditData(content);
    } catch (error) {
      console.error("Error fetching audit data:", error);
    }
  };
  function downloadSingleFile(fileUrls) {
    const test = fileUrls;
    if (test.length === 0) {
      // All files have been downloaded
      return;
    }

    const fileUrl = test[0];
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.setAttribute("href", fileUrl);
    tempLink.setAttribute("download", "");
    document.body.appendChild(tempLink);

    tempLink.click();

    // Clean up the anchor element after download
    // document.body.removeChild(tempLink);

    // Remove the downloaded file from the array
    test.shift();

    // Continue to download the next file (recursively)
    setTimeout(() => {
      downloadSingleFile(test);
    }, 200); // You can adjust the delay (in milliseconds) between downloads
  }

  const onClickDownload = (row) => {
    const { fileDownloadLinks } = row;
    if (fileDownloadLinks !== undefined && fileDownloadLinks.length !== 0) {
      downloadSingleFile(fileDownloadLinks);
    }
  };

  const toggleListDropdown = () => {
    setListDropdownOpen(!listDropdownOpen);
  };

  const handleItemsPerPageChange = (value) => {
    setSize(value);
    getCustomerManagementAuditList(name, status, page, value);
  };

  const onPageChange = (data) => {
    const { selected } = data;
    setPage(selected);
    getCustomerManagementAuditList(name, status, selected, size);
  };

  const onHandleStatusChange = (e) => {
    const { value } = e.target;
    setStatus(value);
    setPage(0);
    getCustomerManagementAuditList(name, value, 0, size);
  };

  const onHandleNameChange = (e) => {
    const { value } = e.target;
    setName(value);
    setPage(0);
    getCustomerManagementAuditList(value, status, 0, size);
  };

  const onClickRow = (row) => {
    const { id } = row;
    setSelectedJobId(id);
    setRowData(row);
  };

  const onHandleBack = (id) => {
    setSelectedJobId(id);
  };

  useEffect(() => {
    getCustomerManagementAuditList();
  }, []);

  if (selectedJobId) {
    return (
      <Permission
        allowed={Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_STEP_AUDIT_VIEW_LIST}
      >
        <CustomerStepAuditManagementContainer
          selectedJobId={selectedJobId}
          rowData={rowData}
          onHandleBack={onHandleBack}
        />
      </Permission>
    );
  }

  return (
    <CustomerAuditManagementComponent
      onClickRow={onClickRow}
      auditData={auditData}
      listDropdownOpen={listDropdownOpen}
      toggleListDropdown={toggleListDropdown}
      handleItemsPerPageChange={handleItemsPerPageChange}
      itemsPerPage={size}
      totalPagesCount={totalPagesCount}
      onPageChange={onPageChange}
      jobStatus={jobStatus}
      onHandleStatusChange={onHandleStatusChange}
      onClickDownload={onClickDownload}
      jobName={name}
      jobType={jobType}
      onHandleNameChange={onHandleNameChange}
    />
  );
};

export default CustomerAuditManagementContainer;
