/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Footer
 *
 * @author Naseef O
 *
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/Footer.module.css";


const Footer = ({ getCurrentYear }) => {
  const { t } = useTranslation(["common"]);

  return (
    <Row className={clsx(styles.footerWrapper, "m-0")}>
      <Col xs="12">
        <Row className="">
          <Col xs="6">
            <Row>
              <span className={styles.textUnderline}>
                Support/Contact
              </span>
            </Row>
            <Row>
              <span>
                +914712700666
              </span>
            </Row>
            <Row>
              <span>
                B2B_Support@valoriz.com
              </span>
            </Row>
            <Row>
              <span>
                <a href="https://www.valoriz.com/" target="_blank">www.valoriz.com</a>
              </span>
            </Row>
          </Col>
          <Col xs="6" className="pr-0">
            <span className="float-right">
              {t("common:copyright")}
              {getCurrentYear()}
              {t("common:by")}
              <a href="https://www.valoriz.com/" target="_blank">{t("common:company_name")}</a>
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
Footer.propTypes = { getCurrentYear: PropTypes.func.isRequired };
export default Footer;
