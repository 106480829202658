/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
/** ===== API SERVICE FUNCTIONS =========== */
import { getProductOptionById, getRelatedProducts, updateWarrantyProductOption } from "../../../api/productManagementServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
/** ======== CUSTOM COMPONENTS ========= */
import EditProductOption from "../components/EditProductOption";

/** Base container for edit product details */
const EditProductOptionContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  if (!params.productId) {
    history.push("/merchandising/product");
  }

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    productType: "",
    enableProduct: false, /** product buy default disabled */
    categories: [], /**  multiple category selection  */
    attributes: {}, /** multiple attribute key value pairs */
    skuName: "",
    listPrice: "",
    quantity: "",
    onSale: false, /** by default on sale is false */
    images: [], /** selected images list */
    description: "",
    longDescription: "",
    startDate: new Date(),
    endDate: new Date(),
    landingPageUrl: "",
    skus: [],
    selectedCategories: [],
    relatedProducts: [],
  });
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [retailerExtensions, setRetailerExtensions] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  useEffect(() => {
    let localForm = form;
    const loadRelatedProducts = (newFormData) => {
      getRelatedProducts(params.productId).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          if (Array.isArray(data)) {
            /** null rows for server */
            const filteredData = data.filter((each) => each);
            setRelatedProducts(filteredData);
            const relatedProductIds = filteredData.filter((each) => each).map(({ id }) => id);
            setForm({ ...newFormData, relatedProducts: relatedProductIds });
          }
        }
      });
    };

    getProductOptionById(params.productId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          localForm = {
            ...form,
            id: data.id,
            name: data.uniqueId || "",
            priceType: data.priceType || "",
            productValue: data.name || "",
            rate: data.rate || 0,
            productType: data.type || "",
            enableProduct: data.active,
            selectedCategories: data.parentCategories,
            categories: data.parentCategoryDetails,
            attributes: data.productSelector || {},
            skuName: data.name || "",
            description: data.description || "",
            longDescription: data.longDescription || "",
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            landingPageUrl: data.landingPageBaseUrl || "",
            listPrice: 25,
            quantity: 405,
            onSale: false,
            images: data.medias || [],
            skus: data.skus,
          };
          setForm(localForm);
          const { retailerExtensions: re, ...rest } = data;
          setRetailerExtensions({ ...re } || null);
          setProductDetails({ ...rest } || null);
        }
      }
      loadRelatedProducts(localForm);
    });
  }, [params.productId, form]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  /**
   * This method is used to update sku name by product name and attribute changes
   * @param {String} productName
   * @param {Object} productAttributes
   */
  const skuNameUpdate = (productName, productAttributes) => {
    if (productAttributes) {
      const hasNullAttributes = Object.entries(
        productAttributes,
      ).some((attribute) => !attribute[1]);
      /** fix for null value from server */
      if (hasNullAttributes || productName === "") { return; }
      let newSKUName = `${productName}${Object.keys(productAttributes).length > 0
        ? "-" : ""}${Object.entries(productAttributes).map((each) => `${each[1]}`).join("-")}`;
      newSKUName = newSKUName.replace(/ /g, "_");
      setForm({
        ...form,
        skuName: newSKUName,
      });
    }
  };

  useEffect(() => {
    skuNameUpdate(form.name, form.attributes);
  }, [form.name, form.attributes, skuNameUpdate]);

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (name, event) => {
    const { value } = event.target;
    handleChange(name, value);
  };

  /**
   * This method is used for  cancel and redirect to product listing page
   */
  const onCancel = () => {
    history.push("/merchandising/product-option");
  };

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const onSubmit = async (value) => {
    const requestBody = {
      id: form.id,
      name: form.productValue,
      type: form.productType,
      priceType: form.priceType,
      rate: form.rate,
    };
    if (value) {
      /** setting on submit true */
      setOnSubmitting(true);
      const response = await updateWarrantyProductOption(requestBody);
      if (response && response.success) {
        setOnSubmitting(false);
        history.push("/merchandising/product-option");
      }
    }
  };

  /**
   * This method is used to redirect page to add sku page
   *
   */
  const handleRedirectToModifySku = () => {
    history.push(`/merchandising/product/modify-sku/${params.productId}`);
  };

  /**
   * This method is used to redirect page to edit sku page
   *
   */
  const handleEditSku = (skuId) => {
    history.push(`/merchandising/product/modify-sku/${params.productId}/?skuId=${skuId}`);
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_UPD_PRDT_OPTN}
    >

      <EditProductOption
        /** form data */
        name={form.name}
        priceType={form.priceType}
        productValue={form.productValue}
        price={form.rate}
        enableProduct={form.enableProduct}
        skuName={form.skuName}
        listPrice={form.listPrice}
        quantity={form.quantity}
        onSale={form.onSale}
        images={form.images}
        skus={form.skus}
        selectedCategories={form.selectedCategories}
        categories={form.categories}
        productType={form.productType}
        attributes={form.attributes}
        description={form.description}
        longDescription={form.longDescription}
        startDate={form.startDate}
        endDate={form.endDate}
        landingPageUrl={form.landingPageUrl}
        relatedProducts={form.relatedProducts}
        relatedProductDetails={relatedProducts}
        retailerExtensions={retailerExtensions}
        productDetails={productDetails}
        /** sate change events */
        handleChange={handleChange}
        handleFormChange={handleFormChange}
        /** form action */
        onSubmitting={onSubmitting}
        onCancel={onCancel}
        onSubmit={onSubmit}
        handleEditSku={handleEditSku}
        handleRedirectToModifySku={handleRedirectToModifySku}
      />
    </Permission>
  );
};

EditProductOptionContainer.propTypes = {

};

export default EditProductOptionContainer;
