/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * Facet Edit Container
 * @author Harikrishnan A K
 */

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import FacetEditor from "../components/FacetEditor";
import { facetConfig, updateFacetConfig } from "../../../api/categoryManagementServices";
import CONSTANTS from "../../../common/utils/constants";
import commonUtils from "../../../common/utils/commonUtils";

const FacetEditorContainer = () => {
  const history = useHistory();
  const params = useParams();
  // utility function to redirect back to facet-config table page
  const redirect = () => 
    history.push({
        pathname : "/administration/facet-configurations",
        state: { brand : brandId }
    });
  const { isListNotEmpty } = commonUtils;
  if (!params.index) {
    redirect();
  }
  const { index, brandId } = params;


  const code = CONSTANTS.SEARCH_CONFIG.FACET;

  //  Entire serach config object for Facet
  const [facetObject, setFacetObject] = useState(null);

  const [facetCustomizationRows, setFacetCustomizationRows] = useState([]);
  const [singleFacetObject, setSingleFacetObject] = useState([null]);
  useEffect(() => {
    facetConfig(code, brandId).then((response) => {
      if (response && response.success && response.data
        && isListNotEmpty(response.data.defaultConfiguration)
        && response.data.defaultConfiguration.length > index) {
        setFacetObject(response.data);
        setFacetCustomizationRows(response.data.defaultConfiguration);
        setSingleFacetObject((response.data.defaultConfiguration[index]));
      }
    });
  }, [index, code, brandId]);
  const handleSave = () => {
    const newFacetCustomizationRows = facetCustomizationRows.map((item, i) => {
      if (i === parseInt(index, 10)) {
        return singleFacetObject;
      }
      return item;
    });

    const newFacetObject = { ...facetObject, defaultConfiguration: newFacetCustomizationRows, brand: brandId };
    updateFacetConfig(newFacetObject).then((response) => {
      if (response && response.success) {
        setFacetCustomizationRows(newFacetCustomizationRows);
        setFacetObject(newFacetObject);
        redirect();
      }
    });
  };


  const onHandleCancel = () => {
    redirect();
  };


  /**
       * This method is used to change fom state
       * @param {String} name
       * @param {String} value
       */
  const handleChange = (name, value) => {
    setSingleFacetObject({
      ...singleFacetObject,
      [name]: value,
    });
  };
  /**
     * This method is used to change form filed input
     * @param {Event} event
     */
  const handleFormChange = (event) => {
    if (event.target.type === "checkbox") {
      handleChange(event.target.name, !singleFacetObject[event.target.name]);
    } else {
      handleChange(event.target.name, event.target.value);
    }
  };

  return singleFacetObject ? (
    <FacetEditor
      singleFacetObject={singleFacetObject}
      onHandleCancel={onHandleCancel}
      handleSave={handleSave}
      index={index}
      handleFormChange={handleFormChange}
    />
  ) : "";
};
export default FacetEditorContainer;
