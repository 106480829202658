/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * User Management
 *
 * @author Naseef O
 *
 */
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import Select from "../../../common/components/Select";
import CustomizationTable from "../../../common/components/Table";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Label from "../../../common/core/Label";
import Row from "../../../common/core/Row";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import styles from "../css/UserManagement.module.css";

const UserManagement = (props) => {
  const {
    userList,
    hasMoreData,
    page,
    getPageData,
    searchTerm,
    handleSearchTerm,
    isLoading,
    submit,
    selectedDataId,
    roles,
    onHandleRolesChange,
    handleUserStatus,
    onHandleDeleteModalOpen,
    onHandleEditUser,
    onHandleNewUser,
    onHandleActiveChange,
    roleList,
    allRoles,
  } = props;
  const hidePagination = page === 0 && !(userList && Array.isArray(userList) && userList.length > 0);
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
          submit && selectedDataId === row.id ? "loading" : "",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) => handleUserStatus(!row.active, row.id, o)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };
  const getFullName = (row) => `${row.fullName ? row.fullName : `${row.firstName || ""} ${row.lastName || ""}`}`;
  const getRoles = (row) => `${row.roles.join(", ")} `;
  const customRows = [
    {
      label: "Name",
      path: "render",
      render: getFullName,
    },
    {
      label: "Email",
      value: "",
      path: "email",
      transform: "",
    },
    {
      label: "Role",
      value: "",
      path: "render",
      transform: "",
      render: getRoles,
    },
    {
      label: "Status",
      path: "render",
      render: getStatus,
    },
  ];

  return (
    <>
      <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
        <Col xs="8">
          <span className="pageText">Manage Users</span>
        </Col>
        <Col xs="4">
          <Permission
            allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_CRT_USRT}
          >
            <Button
              className={clsx(styles.buttonNewUser, "text-uppercase bg-color-tertiary-main")}
              onClick={onHandleNewUser}
            >
              New User
            </Button>
          </Permission>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="12" md="4">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">Role</Label>
                <Select
                  className="formTextLabel"
                  name="status"
                  value={roles}
                  onChange={(e) => onHandleRolesChange(e)}
                  options={allRoles}
                />
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">Status</Label>
                <Select
                  className="formTextLabel"
                  name="status"
                  onChange={(e) => onHandleActiveChange(e)}
                  options={[
                    { label: "Active", value: true },
                    { label: "Inactive", value: false },
                  ]}
                />
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="p-0">
              <FormGroup>
                <Label className="formTextLabel  pt-3" for="exampleEmail" />
                <div className={styles.areaActionRight}>
                  <div>
                    <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                      <Input
                        placeholder="Search User"
                        searchTerm={searchTerm}
                        onChange={handleSearchTerm}
                        value={searchTerm}
                      />
                      <InputGroupAddon
                        addonType="append"
                        className="commonPointer bg-color-tertiary-main"
                      >
                        <img
                          src={SearchIcon}
                          alt="Search"
                          className={styles.icon}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                    {" "}
                  </div>
                </div>
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <div className="custom-table">
            <CustomizationTable
              customRows={customRows}
              customizationData={userList}
              isStatusEnable={false}
              isDeleteEnable={false}
              isEditEnable
              onEditData={onHandleEditUser}
              onDeleteData={onHandleDeleteModalOpen}
              isLoading={isLoading}
            />
          </div>
          {!hidePagination
            ? (
              <div
                className={clsx(
                  styles.pagination,
                  "p-3",
                  "d-flex",
                  "justify-content-end",
                )}
              >
                <div
                  className={clsx(
                    styles.pagination,
                    "p-3",
                    "d-flex",
                    "justify-content-end",
                  )}
                >
                  <div className="d-flex">
                    <span
                      disabled={page <= 0}
                      className={clsx(styles.item, styles.arrowLeft)}
                      tabIndex={0}
                      role="button"
                      onKeyPress={() => { }}
                      onClick={() => !page <= 0 && getPageData(page - 1)}
                    >
                      <span />
                    </span>
                    <span className={styles.item} />
                    <span
                      disabled={!hasMoreData}
                      className={clsx(styles.item, styles.arrowRight)}
                      tabIndex={0}
                      role="button"
                      onKeyPress={() => { }}
                      onClick={() => hasMoreData && getPageData(page + 1)}
                    >
                      <span />
                    </span>
                  </div>
                </div>
              </div>
            )
            : ""}
        </Col>
      </Row>
    </>
  );
};
UserManagement.propTypes = {
  userList: PropTypes.objectOf(PropTypes.any).isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  getPageData: PropTypes.func.isRequired,
  onHandleRolesChange: PropTypes.func.isRequired,
  onHandleActiveChange: PropTypes.func.isRequired,
  roleList: PropTypes.arrayOf(PropTypes.any).isRequired,
  allRoles: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleUserStatus: PropTypes.func.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default UserManagement;
