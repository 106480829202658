
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import Alert from "../../../common/core/Alert";

/** ========= UTIlS ========= */
import validationConstants from "../../../common/utils/validationConstants";


/** ========= MODULE STYLES ========= */
import styles from "../css/PromotionForm.module.css";


const ClosenessQualifierForm = (props) => {
  const {
    isClosenessQualifierEnabled,
    handleChange,
    closenessQualifierMessage,
    closenessApproachMessage,
    closenessQualifierCriteriaSubTotal,
    handleFormChange,
    validator,
  } = props;

  const { IS_NUMBER } = validationConstants;
  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);

  /** validation methods */
  const { register, errors } = validator;
  return (
    <>
      <Row className={clsx("common-page-card", "pb-1")}>
        <Col xs="6" className="text-truncate pageHeader pb-0">
          <span className="pageText">{t("promotion:title_closeness_qualifier")}</span>
        </Col>
        <Col xs="6">
          <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex justify-content-center")}>
            <Label className="formTextLabel">{t("promotion:label_cq_checkbox")}</Label>
            <div
              className={clsx(
                "custom-control",
                "custom-switch",
                "ml-3",
              )}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="enable-closeness-qualifier"
                name="active"
                checked={isClosenessQualifierEnabled}
                onChange={() => handleChange("isClosenessQualifierEnabled", !isClosenessQualifierEnabled)}
              />
              <Label className="custom-control-label" htmlFor="enable-closeness-qualifier" />
            </div>
          </FormGroup>
        </Col>

        {isClosenessQualifierEnabled
          ? (
            <>
              <Col xs="12">
                <hr />
              </Col>
              <Col xs="12">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("promotion:label_cq_order_subtotal")}
                    <span className="pl-1 text-danger">*</span>
                  </Label>
                  <Input
                    className="formText"
                    placeholder={t("promotion:label_cq_order_subtotal")}
                    name="closenessQualifierCriteriaSubTotal"
                    defaultValue={closenessQualifierCriteriaSubTotal}
                    onChange={handleFormChange}
                    innerRef={
                      register({
                        required: isClosenessQualifierEnabled && t("promotion:validation_cq_criteria_subtotal_required"),
                        min: {
                          value: 1,
                          message: t("promotion:validation_cq_criteria_subtotal_invalid"),
                        },
                        pattern: {
                          value: IS_NUMBER,
                          message: t("common:validation_is_number"),
                        },
                      })
                    }
                    invalid={!!(errors.closenessQualifierCriteriaSubTotal
                      && errors.closenessQualifierCriteriaSubTotal.message)}
                  />
                  <FormFeedback>
                    {errors.closenessQualifierCriteriaSubTotal
                      && errors.closenessQualifierCriteriaSubTotal.message}

                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("promotion:label_cq_approach_message")}
                    <span className="pl-1 text-danger">*</span>
                  </Label>
                  <Input
                    className="formText"
                    placeholder={t("promotion:placeholder_cq_approaching_message")}
                    name="closenessApproachMessage"
                    innerRef={
                      register({
                        required: isClosenessQualifierEnabled && t("promotion:validation_cq_message_required"),
                        maxLength: {
                          value: 2499,
                          message: t("promotion:validation_too_large"),
                        },
                      })
                    }
                    defaultValue={closenessApproachMessage}
                    onChange={handleFormChange}
                    invalid={!!(errors.closenessApproachMessage
                      && errors.closenessApproachMessage.message)}
                  />
                  <FormFeedback>
                    {errors.closenessApproachMessage
                      && errors.closenessApproachMessage.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12">
                <Alert color="warning">
                  {t("promotion:alert_cq_order_cart_threshold")}
                </Alert>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("promotion:label_cq_qualifying_message")}
                    <span className="pl-1 text-danger">*</span>
                  </Label>
                  <Input
                    className="formText"
                    placeholder={t("promotion:label_cq_qualifying_message")}
                    name="closenessQualifierMessage"
                    innerRef={
                      register({
                        required: isClosenessQualifierEnabled && t("promotion:validation_cq_message_required"),
                        maxLength: {
                          value: 2499,
                          message: t("promotion:validation_too_large"),
                        },
                      })
                    }
                    defaultValue={closenessQualifierMessage}
                    onChange={handleFormChange}
                    invalid={!!(errors.closenessQualifierMessage
                      && errors.closenessQualifierMessage.message)}
                  />
                  <FormFeedback>
                    {errors.closenessQualifierMessage
                      && errors.closenessQualifierMessage.message}
                  </FormFeedback>
                </FormGroup>
              </Col>

            </>
          )
          : ""}

      </Row>

    </>
  );
};


ClosenessQualifierForm.propTypes = {
  onCampaign: PropTypes.bool.isRequired,
  isClosenessQualifierEnabled: PropTypes.bool.isRequired,
  closenessQualifierMessage: PropTypes.string.isRequired,
  closenessApproachMessage: PropTypes.string.isRequired,
  closenessQualifierCriteriaSubTotal: PropTypes.number.isRequired,
  // methods
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,

};


export default ClosenessQualifierForm;
