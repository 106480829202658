/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Akshay Raj
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Select from "../../../common/components/Select";
import FormGroup from "../../../common/core/FormGroup";

/** ======== COMPONENTS */

import CustomizationTable from "../../../common/components/Table";

import styles from "../css/SearchSettingsManagement.module.css";


const SearchSettingsManagement = (props) => {
  const {
    updateSettings,
    siteSettings,
    brandId,
    handleBrandChange,
    brands,
  } = props;
  /**
   * This method to change status in campaign list
   * @param {Object} row
   * @returns {Element}
   */
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
          // onSubmitting && selectedCampId === row.field ? "loading" : "",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.field}`}
          checked={row.active}
          onChange={(o) => updateSettings(!row.active, row.field, o)}
        />
        <Label
          className="custom-control-label"
          htmlFor={`expand-multiple${row.field}`}
        />
      </div>
    );

    return newStatus;
  };
  return (
    <>
      <Row>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12">
              <span className="pageText">Search Settings</span>
            </Col>
          </Row>

          <Row className="pageHeader common-page-card">
            <Col xs="12">
              <Row className="m-0">
                <Col className="mb-2" xs="12" sm="12" md="9">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    Manage Search Settings
                  </span>
                </Col>
                <Col className="mb-2" xs="12" sm>
                  <label htmlFor="exampleEmail" className="formTextLabel">Brand</label>
                  <div className={styles.areaActionRight}>
                    <FormGroup>
                      <Select
                        className="formTextLabel"
                        placeholder="select brand"
                        name="status"
                        value={brandId}
                        onChange={(e) => handleBrandChange(e.target.value)}
                        options={
                  Array.isArray(brands)
                    ? [{
                      label: "Select brand",
                      disabled: true,
                      value: "",
                    }, ...brands]
                    : [{
                      label: "Select brand",
                      disabled: true,
                      value: "",
                    }]
                }
                      >
                        <option value="" hidden>Select brand</option>
                      </Select>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>


          <Row className={clsx(styles.tableWrapper, " common-page-card")}>
            <Col xs="12">
              <div className="custom-table ">
                <CustomizationTable
                  customRows={[
                    {
                      label: "Settings name",
                      path: "fieldType",
                    },
                    {
                      label: "Field",
                      path: "field",
                    },
                    {
                      label: "Status",
                      path: "render",
                      render: getStatus,
                    },
                  ]}
                  customizationData={siteSettings}
                  // isStatusEnable
                  // isEditEnable
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
SearchSettingsManagement.propTypes = {
  rowsLoading: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleIndexing: PropTypes.func.isRequired,
};


export default SearchSettingsManagement;
