/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Table from "../../../common/core/Table";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Alert from "../../../common/core/Alert";

/** ========= SVG IMAGE ICON ============= */
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

/** ================== MODULE STYLES ================ */
import styles from "../css/BadgesManagement.module.css";

const BadgesManagement = (props) => {
  const {
    badges,
    getPageData,
    page,
    hasMoreData,
    searchTerm,
    handleSearchTerm,
    newBadge,
    editBadge,
    removeBadge,
    message,
    handleStatusFlag
  } = props;
  /** internationalization using i18n from common and badge  */
  const { t } = useTranslation(["common", "badge"]);

  return (
    <Row>
      {message && message.type && (
        <Alert color={message.type}>{message.message}</Alert>
      )}
      <Col xs="12">
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="7" className="text-truncate">
            <span className="pageText">{t("badge:title_badges")}</span>
          </Col>
        </Row>
      </Col>
      <Col xs="12">
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col className="mb-2" xs="12" sm="12" md="4">
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {t("badge:title_manage_badge_code")}
            </span>
          </Col>
          <Col className="mb-2" xs="12" sm>
            <div className={styles.areaActionRight}>
              <div>
                <InputGroup
                  className={clsx(styles.search, "color-tertiary-main")}
                >
                  <Input
                    placeholder="Search badge code"
                    value={searchTerm}
                    onChange={handleSearchTerm}
                  />
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer bg-color-tertiary-main"
                  >
                    <img
                      src={SearchIcon}
                      alt="search badge code"
                      className={styles.icon}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={newBadge}
                  className={clsx(
                    styles.borderRadius2,
                    "text-uppercase",
                    "text-truncate",
                    "btn-tertiary"
                  )}
                >
                  {t("badge:label_button_add_badge")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className={clsx(
            styles.block3,
            styles.tableWrapper,
            " common-page-card"
          )}
        >
          <Col>
            <div className={styles.badge}>
              {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
              <div
                className={clsx(
                  styles.couponBody,
                  "table-responsive",
                  "custom-table"
                )}
              >
                <Table borderless className={styles.couponTable}>
                  {/* ========= TABLE HEAD S[0003] ======================= */}
                  <thead>
                    <tr>
                      <th>{t("badge:label_badge_primary_text")}</th>
                      <th>{t("badge:label_badge_secondary_text")}</th>
                      <th>{t("badge:label_badge_font_color")}</th>
                      <th>{t("badge:label_badge_background_color")}</th>
                      <th>{t("common:table_title_status")}</th>
                      <th>{t("common:table_title_edit")}</th>
                      <th>{t("common:table_title_delete")}</th>
                    </tr>
                  </thead>
                  {/* ========= TABLE HEAD S[0003] ======================= */}
                  <tbody>
                    {/* ========= TABLE BODY S[0002] ======================= */}
                    {badges.length > 0 ? (
                      badges.map(
                        (
                          badge
                        ) => (
                          <tr key={badge.id}>
                            <td>{badge.primaryText}</td>
                            <td>{badge.secondaryText}</td>
                            <td>{badge.fontColor}</td>
                            <td>{badge.backgroundColor}</td>
                            <td>
                              <div
                                className={clsx(
                                  "custom-control",
                                  "custom-switch"
                                )}
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`expand-multiple${badge.id}`}
                                  checked={badge.active}
                                  onChange={(o) => handleStatusFlag(!badge.active, badge.id, o)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`expand-multiple${badge.id}`}
                                >
                                  <input type="hidden" />
                                </label>
                              </div>
                            </td>
                            <td>
                              <span
                                onClick={(o) => editBadge(badge.id, o)}
                                onKeyDown={() => { }}
                                tabIndex={0}
                                role="button"
                                className="commonPointer"
                              >
                                <img
                                  className={clsx("commonPointer", styles.icon)}
                                  src={EditIcon}
                                  alt="Edit"
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                onClick={(o) => removeBadge(badge.id, o)}
                                onKeyDown={() => { }}
                                tabIndex={0}
                                role="button"
                                className="commonPointer"
                              >
                                <img
                                  className={clsx("commonPointer", styles.icon)}
                                  src={DeleteIcon}
                                  alt="Delete"
                                />
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                        <tr>
                          <td colSpan={8} align="center" valign="center">
                            {" "}
                          Nothing to show.
                        </td>
                        </tr>
                      )}
                    {/* ========= TABLE BODY E[0002] ======================= */}
                  </tbody>
                </Table>
              </div>
              <div
                className={clsx(
                  styles.pagination,
                  "p-3",
                  "d-flex",
                  "justify-content-end"
                )}
              >
                <div className="d-flex">
                  <span
                    disabled={page < 1}
                    className={clsx(styles.item, styles.arrowLeft)}
                    tabIndex={0}
                    role="button"
                    onKeyPress={() => { }}
                    onClick={(o) => getPageData(-1, o)}
                  >
                    <span />
                  </span>
                  <span className={styles.item} />
                  <span
                    disabled={!hasMoreData}
                    className={clsx(styles.item, styles.arrowRight)}
                    tabIndex={0}
                    role="button"
                    onKeyPress={() => { }}
                    onClick={(o) => getPageData(1, o)}
                  >
                    <span />
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
BadgesManagement.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  removeBadge: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  newBadge: PropTypes.func.isRequired,
  editBadge: PropTypes.func.isRequired,
  message: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleStatusFlag: PropTypes.bool.isRequired,
};

export default BadgesManagement;
