/**
 * Copyright(c) 2019 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SuccessModal
 *
 * @author Harikrishnan A K
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Container from "../../../common/core/Container";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import Button from "../../../common/core/Button";

const SuccessModal = ({
  modal,
  toggle,
}) => {
  const { t } = useTranslation(["login"]);
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>
        {t("login:success")}
      </ModalHeader>
      <Button color="close-modal" onClick={() => toggle(false)}>
        <i className="icon-close" />
      </Button>
      <ModalBody>
        <Container>
          <Row className="justify-content-center my-4">
            <Col md="8">
              <p className="text-center">{t("login:reset_mail")}</p>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

SuccessModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default SuccessModal;
