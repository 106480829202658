/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import CacheClearManagementContainer from "./container/CacheClearManagementContainer";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";


/** components */


const CacheManagement = ({ match: { path, params } }) => {
    return (
        <Switch>
            <PrivateRouter exact path={`${path}`} component={CacheClearManagementContainer} />
            {/* <PrivateRouter exact path="" component={NotFound} /> */}
        </Switch>
    );
}
CacheManagement.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        params: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
};

export default CacheManagement;
