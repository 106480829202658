/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useEffect, useState } from "react";
import JobStepExAuditDetails from "../components/JobStepExAuditDetails";
import { getJobStepExAuditByJobId } from "../../../api/jobConfigurationService";
import Button from "../../../common/core/Button";

const JobStepExAuditDetailsContainer = ({
  onHandleBackStep,
  rowDataStep,
  formatDate,
}) => {
  const [jobStepExDetailsData, setJobStepExDetailsData] = useState([]);
  const [jobSteExDetailsRow, setJobSteExDetailsRow] = useState([]);
  const [startOfResult, setStartOfResult] = useState(false);
  const [endOfResult, setEndOfResult] = useState(false);
  const [pageSize, setPageSize] = useState(0);

  const setJobStepExTableHeadTableBody = (customRows, transformedData) => {
    const row = [
      {
        label: "Items",
        value: "",
        path: "items",
        transform: "",
      },
      {
        label: "FileName",
        value: "",
        path: "fileName",
        transform: "",
      },
      {
        label: "Download File",
        path: "render",
        render: () => (
          <div>
            <Button
              size="sm"
              color="primary"
              block
              className="text-white"
            >
              Download
            </Button>
          </div>
        ),
      },
    ];
    row.unshift(customRows);
    setJobSteExDetailsRow(row);
    setJobStepExDetailsData(transformedData);
  };

  const setJobSteExPriceTypeTable = (data) => {
    const transformedData = data.map((item) => ({
      priceType: (item && item.content && item.content.priceType) || "",
      items: (item && item.content && item.content.priceDetails && item.content.priceDetails.length) || "",
      fileName: item && item.fileName,
    }));
    const customRows = {
      label: "Price Type",
      value: "",
      path: "priceType",
      transform: "",
    };
    setJobStepExTableHeadTableBody(customRows, transformedData);
  };

  const setJobSteExInventoryTable = (data) => {
    const transformedData = data.map((item) => ({
      storeId: (item && item.content && item.content.storeId) || "",
      items: (item && item.content && item.content.inventoryDetails && item.content.inventoryDetails.length) || "",
      fileName: item && item.fileName,
    }));
    const customRows = {
      label: "Store Id",
      value: "",
      path: "storeId",
      transform: "",
    };
    setJobStepExTableHeadTableBody(customRows, transformedData);
  };

  const transformSetJobStepExDetailsData = (data) => {
    if (data && data.length > 0) {
      if (data[0].content && data[0].content.priceType) {
        setJobSteExPriceTypeTable(data);
      } else if (data[0].content && data[0].content.inventoryDetails) {
        setJobSteExInventoryTable(data);
      }
    }
  };

  const getJobStepExList = (jobId, stepId, page) => {
    getJobStepExAuditByJobId(jobId, stepId, page).then(
      async (response) => {
        if (response && response.data) {
          const { data } = response;
          if (data && data.content) {
            transformSetJobStepExDetailsData(data.content);
            setStartOfResult(data.startOfResult);
            setEndOfResult(data.endOfResult);
          }
        }
      },
    );
  };

  const paginationPrev = () => {
    if (!startOfResult) {
      getJobStepExList(rowDataStep.jobId, rowDataStep.id, pageSize - 1);
      setPageSize(pageSize - 1);
    }
  };

  const paginationNext = () => {
    if (!endOfResult) {
      getJobStepExList(rowDataStep.jobId, rowDataStep.id, pageSize + 1);
      setPageSize(pageSize + 1);
    }
  };

  useEffect(() => {
    getJobStepExList(rowDataStep.jobId, rowDataStep.id);
  }, []);

  return (
    <JobStepExAuditDetails
      jobStepExDetailsData={jobStepExDetailsData}
      onHandleBackStepEx={onHandleBackStep}
      rowDataStep={rowDataStep}
      formatDate={formatDate}
      jobSteExDetailsRow={jobSteExDetailsRow}
      paginationPrev={paginationPrev}
      paginationNext={paginationNext}
      startOfResult={startOfResult}
      endOfResult={endOfResult}
      canModify
    />
  );
};

export default JobStepExAuditDetailsContainer;
