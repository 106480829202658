/**
 * Copyright(c) 2019 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PasswordResetContainer
 *
 * @author Harikrishnan  A K
 *
 */

import React, { useState } from "react";
import {
  useHistory, useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../api/UserServices";
import PasswordReset from "../components/PasswordReset";

const PasswordResetContainer = () => {
  const history = useHistory();
  const token = new URLSearchParams(useLocation().search).get("token");
  const { t } = useTranslation(["login"]);

  const [resetForm, setResetForm] = useState({
    password: "",
  });
  const [modal, setModal] = useState({
    isOpen: false,
    success: false,
    title: "",
    message: "",
  });
  const closeModal = () => {
    history.push("/login");
    setModal({
      isOpen: false,
      success: false,
      title: "",
      message: "",
    });
  };


  /**
   * This method is used to handle change password
   *
   * @param {*} e
   */
  const handleChange = (e) => {
    const key = e.target.name;
    setResetForm({ ...resetForm, [key]: e.target.value });
  };

  /**
   * This method is used to handle submit new password
   *
   * @param {*} value
   */
  const handleSubmit = async (value) => {
    if (value && token) {
      const resetData = {
        ...resetForm,
        token,
      };
      await resetPassword(token, resetData.password).then((response) => {
        if (response && response.success && response.data) {
          setModal({
            title: t("login:title_reset_success"),
            message: t("login:message_reset_success"),
            isOpen: true,
            success: true,
          });
        } else {
          setModal({
            title: t("login:title_reset_failed"),
            message: t("login:message_reset_failed"),
            isOpen: true,
            success: false,
          });
        }
      });
    }
  };

  return (
    <>
      <PasswordReset
        handleChange={handleChange}
        resetForm={resetForm}
        handleSubmit={handleSubmit}
        modal={modal}
        closeModal={closeModal}
      />
    </>
  );
};


export default PasswordResetContainer;
