/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Batch Job Audit Management Container
 *
 * @author Joice Tomy
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BatchStepAuditManagement from "../components/BatchStepAuditManagement";

/** ===== API SERVICE FUNCTIONS =========== */
import { getJobStepAuditByJobId } from "../../../api/batchServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";


const BatchStepAuditManagementContainer = ({ selectedJobId, onHandleBack, rowData }) => {
  /** state variables */
  const [batchStepAudit, setBatchStepAudit] = useState([]);

  useEffect(() => {
    getJobStepAuditByJobId(selectedJobId).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        setBatchStepAudit(data);
      }
    });
  }, []);

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_STEP_AUDIT_VIEW_LIST}
    >
      <BatchStepAuditManagement
        batchStepAudit={batchStepAudit}
        jobAuditData={rowData}
        onHandleBack={onHandleBack}
      />
    </Permission>
  );
};
BatchStepAuditManagementContainer.propTypes = {
  selectedJobId: PropTypes.string.isRequired,
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleBack: PropTypes.func.isRequired,
};
export default BatchStepAuditManagementContainer;
