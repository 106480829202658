/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Joice Tomy
 */

import httpApi from "./httpApi";

/**
   * This method is used to return scheduler list
   * @returns {Array}
   */
const getBatchSchedulers = async () => {
  const response = await httpApi.get("v1/batch-scheduler/all");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
   * This method is used to return batch job audit list
   * @returns {Array}
   */
const getBatchJobAudit = async (requestData) => {
  const response = await httpApi.post("/v1/batchjob/logs", requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
  * This method used to retrieve Step Audit Data of a Job
  */
const getJobStepAuditByJobId = async (id) => {
  const response = await httpApi.get("/v1/batch/steps/logs", { params: { jobId: id } });
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  getBatchSchedulers,
  getBatchJobAudit,
  getJobStepAuditByJobId,
};
