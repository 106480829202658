/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Batch Management Container
 *
 * @author Joice Tomy
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BatchManagement from "../components/BatchManagement";

/** ===== API SERVICE FUNCTIONS =========== */
import { getBatchSchedulers } from "../../../api/batchServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import commonUtils from "../../../common/utils/commonUtils";


const BatchManagementContainer = () => {
  const history = useHistory();

  /** state variables */
  const [schedulers, setSchedulers] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const onClickRow = (row) => {
    const { jobConfigId } = row;
    history.push({ pathname: "/operations/batch/jobaudit", state: { jobConfigId } });
  };
  useEffect(() => {
    setIsProcessing(true);
    getBatchSchedulers().then((response) => {
      setIsProcessing(false);
      if (response && response.success) {
        const { data } = response;
        setSchedulers(commonUtils.isListNotEmpty(data) ? data : []);
      }
    });
  }, []);


  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_SCHEDLR_VIEW_LIST}
    >
      <BatchManagement
        schedulers={schedulers}
        isProcessing={isProcessing}
        onClickRow={onClickRow}
      />
    </Permission>
  );
};

export default BatchManagementContainer;
