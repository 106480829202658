/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * DragAndDrop Table Container
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import DragAndDropTable from "../components/DragAndDropTable";


const DragAndDropTableContainer = (props) => {
  const {
    facetCustomizationRows, handleActiveStatusChange, handleRedirectToFacetEditor,
    facetObject, onDragEnd,
  } = props;
  return (
    <DragAndDropTable
      handleRedirectToFacetEditor={handleRedirectToFacetEditor}
      facetCustomizationRows={facetCustomizationRows}
      facetObject={facetObject}
      onDragEnd={onDragEnd}
      handleActiveStatusChange={handleActiveStatusChange}
    />
  );
};
DragAndDropTableContainer.propTypes = {
  handleRedirectToFacetEditor: PropTypes.func.isRequired,
  handleActiveStatusChange: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  facetObject: PropTypes.func.isRequired,
  facetCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default DragAndDropTableContainer;
