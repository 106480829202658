/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
/** ========= API SERVICE FUNCTIONS ========= */
import { addStore, getStoreByStoreId, getCities } from "../../../api/storeManagementServices";

/** ========= SUB COMPONENT ========= */
import AddStore from "../components/AddStore";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const AddStoreContainer = () => {
  const history = useHistory();
  /** local states */
  const [form, setForm] = useState({
    name: "",
    events: "",
    services: "",
    showInStorefront: false,
    hdAvailable: false,
    cncAvailable: false,
    pageTitle: "",
    metaDescription: "",
    contactInfo: {
      dialCode: "+971", // TODO: Take the value of dialCode from country
    },
    workingHours: {},
    storeId: "",
    dncAvailable: false,

  });

  const [error, setError] = useState({
    storeId: "",
  });

  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [cityList, setCityList] = useState([]);


  /**
  * This method is used to change fom state
  * @param {String} name
  * @param {String} value
  */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
  * This method is used to change error state
  * @param {String} name
  * @param {String} value
  */
  const handleError = (name, value) => {
    setError({
      ...error,
      [name]: value,
    });
  };

  /**
 *  Validate storeId
 */
  const validateStoreId = async () => {
    const response = await getStoreByStoreId(form.storeId);
    if (response && response.data) {
      handleError("storeId", "Store Id already exits");
      return false;
    }
    handleError("storeId", "");
    return true;
  };


  /**
  * This method is used to change form filed input
  * @param {Event} event
  */
  const handleFormChange = (event) => {
    handleError(event.target.name, "");
    handleChange(event.target.name, event.target.value);
  };


  /**
  * This method is used to change form filed input
  * @param {Event} event
  */
  const handleFormObjectChange = (parentObject, label, event) => {
    handleChange(event.target.name, { ...parentObject, [label]: event.target.value });
  };


  /**
  * This method is used to handle location change
  * @param {Event} event
  */
  const onLocationChange = (location) => {
    const { lat, lng } = location;
    handleChange("contactInfo", { ...form.contactInfo, location: { lat, lng } });
  };

  const validateLocation = () => {
    if (form && form.contactInfo && form.contactInfo.location) {
      return { isLocationValid: true, message: "" };
    }
    const errorMessage = "Please select the store location from the map";
    return { isLocationValid: false, errorMessage };
  };

  /**
  * This method is used to submit the form for adding new Store
  * @param {Event} event
  */
  const formSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateStoreId();
    if (isValid) {
      const { isLocationValid, errorMessage } = validateLocation();
      if (isLocationValid) {
        const requestBody = {
          name: form.name,
          events: form.events,
          services: form.services,
          showInStorefront: form.showInStorefront,
          hdAvailable: form.hdAvailable,
          dncAvailable: form.dncAvailable,
          cncAvailable: form.cncAvailable,
          contactInfo: form.contactInfo,
          pageTitle: form.pageTitle,
          metaDescription: form.metaDescription,
          workingHours: form.workingHours,
          storeId: form.storeId,
        };
        setMessage({ type: null, message: "" });
        setOnSubmitting(true);
        const response = await addStore(requestBody);
        if (response && response.success) {
          setMessage({ type: "success", message: response.messages[0] });
          setTimeout(() => {
            setOnSubmitting(false);
            history.push("/administration/store-locations");
            setMessage({ type: null, message: "" });
          }, 3000);
        } else {
          setOnSubmitting(false);
          setMessage({ type: "warning", message: "Something went wrong." });
          setTimeout(() => {
            setMessage({ type: null, message: "" });
          }, 3000);
        }
      } else {
        setMessage({ type: "danger", message: errorMessage });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      }
    }
  };


  /**
  * This method is used for cancel the form
  */
  const cancelForm = () => {
    history.push("/administration/store-locations");
  };

  useEffect(() => {
    getCities().then(async (response) => {
      if (response && response.data && response.data.data) {
        setCityList(response.data.data);
      }
    });
  }, []);

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_STR_LCTN_CRT_STR_LCTN}
    >
      <AddStore
        name={form.name}
        events={form.events}
        services={form.services}
        showInStorefront={form.showInStorefront}
        hdAvailable={form.hdAvailable}
        dncAvailable={form.dncAvailable}
        cncAvailable={form.cncAvailable}
        contactInfo={form.contactInfo}
        workingHours={form.workingHours}
        pageTitle={form.pageTitle}
        metaDescription={form.metaDescription}
        message={message}
        onSubmitting={onSubmitting}
        cancelForm={cancelForm}
        formSubmit={formSubmit}
        handleFormChange={handleFormChange}
        handleChange={handleChange}
        handleFormObjectChange={handleFormObjectChange}
        onLocationChange={onLocationChange}
        storeId={form.storeId}
        validateStoreId={validateStoreId}
        error={error}
        cityList={cityList}
      />
    </Permission>
  );
};

export default AddStoreContainer;
