/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Label from "../../../common/core/Label";
import Alert from "../../../common/core/Alert";
import FormGroup from "../../../common/core/FormGroup";
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/Select";

/** ====== COMMON COMPONENTS ============= */
import CustomizationTable from "../../../common/components/Table";

/** ===== IMAGE ICONS =========== */
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";


/** ================== MODULE STYLES ================ */
import styles from "../css/CampaignListView.module.css";

const CampaignListView = (props) => {
  const {
    campaigns, searchTerm, onSubmitting, hasMoreData, page,
    newCampaign, handleSearchFormChange, handleCampaignStatus, editCampaign, removeCampaign, getPageData, selectedCampId,
    handlePushToLive, pushToLiveEnabled, message, previewSiteUrl, previewEnabled,
    getReplicationStatus,
    pushToLiveButtonEnabled, salesCompany, handleSalesCompanyChange, salesCompanyList,
  } = props;

  /** internationalization using i18n from common and campaign  */
  const { t } = useTranslation(["common", "campaign"]);

  /**
           * This method to change status in campaign list
           * @param {Object} row
           * @returns {Element}
           */
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
          onSubmitting && selectedCampId === row.id ? "loading" : "",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) => handleCampaignStatus(!row.active, row.id, o)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );

    return newStatus;
  };

  /**
           * This method is used to create action component for table
           * @param {Object} row
           * @returns {Element}
           */
  const getActions = (row) => (
    <div className={clsx("d-flex", "justify-content-start", styles.actions)}>
      <span
        // disabled={!row.active}
        className="commonPointer mr-2"
        onClick={(o) => editCampaign(row.id, o)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={EditIcon}
          alt="Edit"
        />
      </span>
      <span
        className="commonPointer"
        onClick={(o) => removeCampaign(row.id, o)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={DeleteIcon}
          alt="Delete"
        />
      </span>
    </div>
  );

  /**
 * This method to create end date in promotion list
 * @param {Object} row
 * @returns {Element}
 */

  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  const getEndDate = (row) => {
    const formattedData = moment(row.endDate, moment.ISO_8601, true).isValid()
      ? moment(row.endDate).format("YYYY-MM-DD HH:mm:ss")
      : null;
    return (
      <>
        {row.indefinite ? (
          <span className="pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62 2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12c1.02 1.01 2.37 1.57 3.82 1.57 2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37z" />
            </svg>
          </span>
        ) : formattedData}
      </>
    );
  };

  return (
    <Row className="">
      <Col xs="12">
        {
          message && message.type && (
            <Alert color={message.type}>
              {message.message}
            </Alert>
          )
        }
        <Row className=" common-page-card pageHeader">
          <Col xs="12" md="5" className="text-truncate">
            <span className="pageText">Campaign</span>
          </Col>
          <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
            <Row>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {previewEnabled && (
                  <Button
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                    onClick={() => redirectToPage()}
                  >
                    {t("common:button_preview")}
                  </Button>
                )}
              </Col>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {pushToLiveEnabled && (
                  <Button
                    onClick={handlePushToLive}
                    disabled={!pushToLiveButtonEnabled}
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                  >
                    {t("common:button_push_to_live")}
                  </Button>
                )}
              </Col>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {pushToLiveEnabled && (
                  <Button
                    onClick={getReplicationStatus}
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                  >
                    {t("common:button_refresh_status")}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          {/* <Col xs="12" md="5" className="d-flex justify-content-end">
          <Button type="button" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
            CANCEL
          </Button>
          <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1")}>
            SAVE CHANGES
          </Button>
        </Col> */}
        </Row>
      </Col>
      <Col xs="12">
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col className="mb-2" xs="12" sm="12" md="4">
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {t("campaign:title_manage")}
            </span>
          </Col>
          <Col className="mb-2" xs="12" sm>
            <div className={styles.areaActionRight}>
              <div className="px-2">
                <FormGroup className="mb-0">
                  <Select
                    className="formText"
                    placeholder="sales company"
                    name="salesCompany"
                    id="salesCompany"
                    value={salesCompany}
                    onChange={(e) => handleSalesCompanyChange(e.target.value)}
                    options={[
                      {
                        value: "", disabled: true, selected: true, label: "Select Sales Company",
                      },
                      ...(
                        Array.isArray(salesCompanyList) ? salesCompanyList.map((item) => ({ value: item.uniqueId, label: `${item.uniqueId}` })) : []
                      ),
                    ]}
                  />
                </FormGroup>
              </div>
              <div className="px-2">
                <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                  <Input placeholder="Search campaign" onChange={handleSearchFormChange} value={searchTerm} name="searchTerm" />
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer bg-color-tertiary-main"
                  >
                    <img
                      src={SearchIcon}
                      alt="search campaign"
                      className={styles.icon}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </div>
              {salesCompany ? (
                <>
                  <div>
                    <Button
                      className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary")}
                      onClick={newCampaign}
                      size="sm"
                      disabled={!salesCompany}
                    >
                      {t("campaign:label_add_new")}
                    </Button>
                  </div>
                </>
              ) : ""}
            </div>
          </Col>
        </Row>
        <Row className={clsx(styles.block3, styles.tableWrapper, " common-page-card")}>
          <Col>
            <div className={styles.campaign}>
              {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
              <Row>
                <Col xs="12">
                  <div className="custom-table pt-2">
                    <CustomizationTable
                      customRows={[
                        {
                          label: t("campaign:label_campaign_id"),
                          path: "id",
                        },
                        {
                          label: t("common:label_name"),
                          path: "name",
                        },
                        {
                          label: t("common:label_startDate"),
                          path: "startDate",
                          type: "date",
                        },
                        {
                          label: t("common:label_endDate"),
                          path: "render",
                          render: getEndDate,
                        },
                        {
                          label: t("common:label_status"),
                          path: "render",
                          render: getStatus,
                        },
                        {
                          label: t("common:label_actions"),
                          path: "render",
                          render: getActions,
                        },
                      ]}
                      customizationData={campaigns}
                    />
                  </div>
                </Col>
              </Row>
              {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
            </div>
            <div className={clsx(styles.pagination, "p-3", "d-flex", "justify-content-end")}>
              <div className="d-flex">
                <span disabled={page < 1} className={clsx(styles.item, styles.arrowLeft)} tabIndex={0} role="button" onKeyPress={() => { }} onClick={(o) => !(page < 1) && getPageData(page - 1, o)}>
                  <span />
                </span>
                <span className={styles.item} />
                <span disabled={!hasMoreData} className={clsx(styles.item, styles.arrowRight)} tabIndex={0} role="button" onKeyPress={() => { }} onClick={(o) => hasMoreData && getPageData(page + 1, o)}>
                  <span />
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CampaignListView.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  selectedCampId: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  // functions
  newCampaign: PropTypes.func.isRequired,
  handleSearchFormChange: PropTypes.func.isRequired,
  handleCampaignStatus: PropTypes.func.isRequired,
  editCampaign: PropTypes.func.isRequired,
  removeCampaign: PropTypes.func.isRequired,
  getPageData: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  handleSalesCompanyChange: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
  salesCompanyList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CampaignListView;
