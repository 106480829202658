/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Joice Tomy
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import BatchManagementContainer from "./containers/BatchManagementContainer";
import BatchJobAuditManagementContainer from "./containers/BatchJobAuditManagementContainer";
import PrivateRouter from "../../common/routes/PrivateRouter";

/** components */


const BatchManagement = ({ match: { path } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}/scheduler`} component={BatchManagementContainer} />
    <PrivateRouter exact path={`${path}/jobaudit`} component={BatchJobAuditManagementContainer} />
  </Switch>
);

BatchManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default BatchManagement;
