/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import MenuDetails from "../components/MenuDetails";
import OpenMenuItemModalContainer from "./OpenMenuItemModalContainer";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

/** ========= API SERVICE FUNCTIONS ========= */
import {
  getWebHeaderNavigationMenus, getMenu, updateMenu, getMenuTypes,
  getWebHeaderNavigationConfigurations,
} from "../../../api/siteNavigationServices";

const MenuDetailsContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const { NAVIGATION_MENU_POSITIONS } = constants;
  const localConstants = {
    delete: "DELETE",
  };

  /** redirecting to listing page if there is not any menu id in url */
  if (!params.menuId) {
    history.push("/administration/navigation");
  }

  /** local states */
  const [selectedMenu, setSelectedMenu] = useState({});
  const [form, setForm] = useState({
    id: "",
    name: "",
    position: "",
    menus: [],
    active: false,
  });

  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [menuTypes, setMenuTypes] = useState([]);
  const [isWebFooter, setIsWebFooter] = useState(false);
  const [isWebResponsiveHeader, setIsWebResponsiveHeader] = useState(false);
  const [lastUpdatedForm, setLastUpdatedForm] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [maxMenuItems, setmaxMenuItems] = useState(0);
  useEffect(() => {
    getMenuTypes().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) setMenuTypes(data);
      }
    });
  }, []);

  useEffect(() => {
    setIsWebFooter(NAVIGATION_MENU_POSITIONS.WEB_FOOTER === form.position);
    setIsWebResponsiveHeader(NAVIGATION_MENU_POSITIONS.WEB_RESPONSIVE_HEADER === form.position);
  }, [form.position]);
  useEffect(() => {
    getWebHeaderNavigationConfigurations().then((response) => {
      if (response) {
        const { success, data } = response;
        if (success && data) {
          setmaxMenuItems(data.maxColumnCount);
        }
      }
    });
  }, []);
  useEffect(() => {
    setLoading(true);

    /**
         * This method is used to generate unique menu id
         */
    const uniqueIdGenerator = () => [1, 2, 3]
      .map((o) => `${new Date().getTime()}-${Math.floor((Math.random() * 100 * o))}`)
      .join("-");

    /**
         * This method is used to parse response body to statue
         * @param {Object} data
         */
    const parseResponseBody = (data) => {
      const responseMenus = Array.isArray(data.menuItems)
        ? data.menuItems.map((menuItem) => {
          const subManus = [];
          if (Object.keys(menuItem.contents).length > 0) {
            Object.keys(menuItem.contents).sort().forEach((contentKye) => {
              const eachContent = menuItem.contents[contentKye];
              if (eachContent) {
                const columnWidth = eachContent.columnConfig
                  && eachContent.columnConfig.columnWidth
                  ? eachContent.columnConfig.columnWidth : 1;
                const displayType = eachContent.columnConfig
                  && eachContent.columnConfig.displayType
                  ? eachContent.columnConfig.displayType : null; /** required clarification */
                const columnConfig = {
                  columnWidth,
                  displayType,
                };
                const columnItems = Array.isArray(eachContent.columnItems)
                  ? eachContent.columnItems.map((columnItem) => {
                    const subMenuItems = Array.isArray(columnItem.subMenuItems)
                      ? columnItem.subMenuItems.map((subMenuItem) => ({
                        order: subMenuItem.order || "",
                        name: subMenuItem.name || "",
                        type: subMenuItem.type || "",
                        dataId: subMenuItem.catalogItemId || "",
                        id: `menu_L3_id_${uniqueIdGenerator()}`,
                        navigable: subMenuItem.navigable || false,
                        active: subMenuItem.active,
                        url: subMenuItem.link ? subMenuItem.link.url : "",
                        urlType: subMenuItem.link ? subMenuItem.link.type : "",
                        urlName: subMenuItem.link ? subMenuItem.link.name : "",
                        displayType: subMenuItem.displayType ? subMenuItem.displayType : null,
                        image: subMenuItem.imageUrl || "",
                        description: subMenuItem.description || "",
                        button: subMenuItem.button || "",
                      }))
                      : [];
                    return {
                      dataId: columnItem.id,
                      id: `menu_L2_id_${uniqueIdGenerator()}`,
                      order: columnItem.order || "",
                      name: columnItem.name || "",
                      type: columnItem.type || "",
                      catalogItemId: columnItem.catalogItemId || "",
                      navigable: columnItem.navigable || false,
                      active: columnItem.active,
                      url: columnItem.link ? columnItem.link.url : "",
                      urlType: columnItem.link ? columnItem.link.type : "",
                      urlName: columnItem.link ? columnItem.link.name : "",
                      image: columnItem.imageUrl || "",
                      description: columnItem.description || "",
                      menus: subMenuItems,
                    };
                  }) : [];
                subManus.push({
                  id: `menu_id_${uniqueIdGenerator()}`,
                  columnConfig,
                  menus: columnItems,
                });
              }
            });
          }

          return {
            dataId: menuItem.id,
            id: `menu_L1_id_${uniqueIdGenerator()}`,
            order: menuItem.order || "",
            name: menuItem.name || "",
            type: menuItem.type || "",
            catalogItemId: menuItem.catalogItemId || "",
            navigable: menuItem.navigable || false,
            url: menuItem.link ? menuItem.link.url : "",
            urlType: menuItem.link ? menuItem.link.type : "",
            urlName: menuItem.link ? menuItem.link.name : "",
            allowDropdown: menuItem.expandable || false,
            image: menuItem.imageUrl || null,
            columnCount: menuItem.columnCount || 0,
            active: Boolean(menuItem.active),
            menus: subManus,
          };
        }) : [];
      return {
        id: data.id,
        retailer: data.retailer,
        brand: data.brand,
        uniqueId: data.id,
        location: data.location,
        name: data.name,
        position: data.type,
        active: data.active,
        audience: data.audience || [],
        menus: responseMenus,
      };
    };

    const getMenus = (locale) => {
      getWebHeaderNavigationMenus(locale).then((response) => {
        if (response) {
          const { success, data } = response;
          if (success && Array.isArray(data)) {
            setMenus(data);
          }
        }
      });
    };

    getMenu(params.menuId).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        const parsedData = parseResponseBody(data);
        setForm(parsedData);
        getMenus(data.locale);
      }
      setLoading(false);
    });
  }, [params.menuId]);

  /** unique key for ui component id */
  const getLocalKey = () => [1, 2, 3]
    .map(() => `${Math.random().toString(36).substr(2, 9)}`)
    .join("-");

  /**
     * This method is used to handle selected menu
     * @param {Event} event
     */
  const handleRootMenuChange = (event) => {
    const { value } = event.target;
    setForm({ ...form, id: value });
    /** changing url param without reload the page */
    history.replace(`${history.location.pathname.split("/").slice(0, -1).join("/")}/${value}`);
  };

  /**
     * This method is used to update menu details
     * @param {Object} data
     * @param {Function} callback
     */
  const updateServer = async (data, callback = null) => {
    setLoading(true);
    setHasError(false);
    const response = await updateMenu(data);
    if (response && response.success) {
      if (callback) callback(response.data);
    } else {
      setHasError(true);
    }
    setLoading(false);
  };

  /**
     * This method is used to pase menu object for api request body
     * @param {Object} menuObject
     * @param {Function} callback
     */
  const parseMenuObject = (menuObject, callback = null, fromHistory = false) => {
    if (menuObject) {
      const menuItems = Array.isArray(menuObject.menus)
        ? menuObject.menus.map((menu) => {
          const contents = {};
          if (Array.isArray(menu.menus)) {
            menu.menus.forEach((menuItem, menuItemIndex) => {
              if (menuItem) {
                const columnWidth = menuItem.columnConfig
                  && menuItem.columnConfig.columnWidth ? menuItem.columnConfig.columnWidth : 1;
                const displayType = menuItem.columnConfig && menuItem.columnConfig.displayType ? menuItem.columnConfig.displayType : ""; /** required clarification */
                const columnConfig = {
                  columnWidth,
                  displayType,
                };
                const columnItems = Array.isArray(menuItem.menus)
                  ? menuItem.menus.map((subMenuIem) => {
                    const subMenuItems = Array.isArray(subMenuIem.menus)
                      ? subMenuIem.menus.map((megaMenuItem) => {
                        const link = megaMenuItem.link ? megaMenuItem.link : {
                          name: megaMenuItem.name,
                          type: megaMenuItem.type,
                          url: megaMenuItem.url,
                        };
                        return ({
                          name: megaMenuItem.name,
                          order: subMenuIem.order || null, /** required clarification */
                          type: megaMenuItem.type || subMenuIem.type || "",
                          catalogItemId: megaMenuItem.dataId || megaMenuItem.id,
                          navigable: megaMenuItem.navigable,
                          active: Boolean(menu.active),
                          displayType: megaMenuItem.displayType || null,
                          link,
                          description: megaMenuItem.description || "",
                          imageUrl: megaMenuItem.image || "",
                          button: megaMenuItem.button || "",
                        });
                      }) : [];
                    return {
                      name: subMenuIem.name,
                      order: subMenuIem.order || null, /** required clarification */
                      type: subMenuIem.type,
                      catalogItemId: subMenuIem.catalogItemId
                        || getLocalKey(), /** required clarification */
                      navigable: subMenuIem.navigable,
                      active: Boolean(menu.active),
                      link: {
                        name: subMenuIem.name,
                        type: subMenuIem.type,
                        url: subMenuIem.url,
                      },
                      description: subMenuIem.description || "",
                      imageUrl: subMenuIem.image || "", /** required clarification */
                      subMenuItems,
                    };
                  }) : [];
                const inMenuItem = {
                  columnConfig,
                  columnItems,
                };
                contents[`COL${(menuItemIndex + 1)}`] = inMenuItem;
              }
            });
          }
          return {
            name: menu.name,
            order: menu.order || null,
            type: menu.type || "", /** required clarification */
            catalogItemId: menu.catalogItemId || getLocalKey(), /** required clarification */
            navigable: menu.navigable,
            link: {
              type: menu.type || "",
              name: menu.name,
              url: menu.url,
            },
            expandable: menu.allowDropdown,
            imageUrl: menu.image || null,
            columnCount: Object.keys(contents).length,
            active: Boolean(menu.active),
            contents,
          };
        }) : [];
      const parsedMenuData = {
        id: menuObject.id,
        name: menuObject.name,
        type: menuObject.position,
        active: menuObject.active,
        audience: menuObject.audience || [], /** required clarification */
        menuItems,
      };
      updateServer(parsedMenuData, callback);
      if (!fromHistory) { setLastUpdatedForm(menuObject); }
    }
  };

  /**
     * This method is a callback for swap array elements
     * @param {Array} menuForSwap
     * @param {Object} metaData
     * @returns {Array}
     */
  const swapArray = (menuForSwap, metaData) => {
    let localArray = menuForSwap;
    /** can also use !isNaN(parseFloat(n)) && !isNaN(n - 0) # eslint error  */
    const isaNumber = (nou) => /^-?[\d.]+(?:e-?\d+)?$/.test(nou);

    /**
         * This method is used to swap elements in the array
         * @param {Array} list
         * @param {Number} startIndex
         * @param {Number} endIndex
         * @returns {Array}
         */
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };
    if (metaData && isaNumber(metaData.source) && isaNumber(metaData.destination)) {
      localArray = reorder(localArray, metaData.source, metaData.destination);
    }
    return localArray;
  };

  /**
     * This method is used to swap for swap array elements in the tree
     * @param {Array} mainMenuParam
     * @param {String} itemMenuId
     * @param {Object} metaData
     * @param {Function} swapCallback
     * @returns {Array}
     */
  const swapMenus = (mainMenuParam, itemMenuId, metaData, swapCallback) => {
    let mainMenu = mainMenuParam;
    if (Array.isArray(mainMenu)) {
      const isCurrentMenu = Boolean(mainMenu.filter((eachMenu) => (eachMenu.id === itemMenuId))[0]);
      if (isCurrentMenu) {
        mainMenu = swapCallback(mainMenu, metaData);
      } else {
        mainMenu.forEach((eachSubMenu, indexIn) => {
          if (Array.isArray(eachSubMenu.menus)) {
            /** in order tree traversal */
            mainMenu[indexIn].menus = swapMenus(
              mainMenu[indexIn].menus, itemMenuId, metaData, swapCallback,
            );
          }
        });
      }
    }
    return mainMenu;
  };

  /**
     * This method is use to reorder the menus based on user drag and rop
     * @param {Object} dragData
     */
  const onDragEnd = (dragData) => {
    setLoading(true);
    const { source, destination, draggableId } = dragData;
    /** destination will be null if there isn't a successfully drop */
    if (source && destination && draggableId) {
      /** restricting sort under the same parent menu only */
      if (source.droppableId === destination.droppableId) {
        const newFormData = {
          ...form,
          menus: swapMenus(form.menus, draggableId,
            { source: source.index, destination: destination.index }, swapArray),
        };
        const updateDataOnUi = () => {
          setForm(newFormData);
        };
        parseMenuObject(newFormData, updateDataOnUi);
      }
    }
    setLoading(false);
  };

  /**
     * This method is used to find the menu item in the menu tree and call on callback
     * @param {Array} mainMenuParam
     * @param {String} itemMenuId
     * @param {Function} callback
     * @returns {Array}
     */
  const findParentMenu = (mainMenuParam, itemMenuId, callback) => {
    let mainMenu = mainMenuParam;
    if (Array.isArray(mainMenu)) {
      const isCurrentMenu = Boolean(mainMenu.filter((eachMenu) => (eachMenu.id === itemMenuId))[0]);
      if (isCurrentMenu) {
        mainMenu = callback(mainMenu, itemMenuId);
      } else {
        mainMenu.forEach((eachSubMenu, indexIn) => {
          if (Array.isArray(eachSubMenu.menus)) {
            /** in order tree traversal */
            mainMenu[indexIn].menus = findParentMenu(mainMenu[indexIn].menus, itemMenuId, callback);
          }
        });
      }
    }
    return mainMenu;
  };

  /**
     * This method is used to remove selected menu item
     */
  const closeNewMenuModal = () => {
    setSelectedMenu(null);
  };

  /**
     * This method is used to add a menu item into the tree view
     * @param {Object} menuData
     * @param {Boolean} onEdit
     */
  const addNewMenuItem = (menuData, onEdit = false) => {
    let oldMainMenus = form.menus;
    if (onEdit) {
      oldMainMenus = oldMainMenus.map((each) => (each.id === menuData.id ? menuData : each));
    } else {
      oldMainMenus.push(menuData);
    }
    const newFormData = {
      ...form,
      menus: oldMainMenus,
    };
    const updateDataOnUi = () => {
      setForm(newFormData);
    };
    parseMenuObject(newFormData, updateDataOnUi);
  };

  /**
     * This method is used to add a mega menu item into the tree view
     * @param {Object} menuData
     * @param {Boolean} onEdit
     */
  const addNewColumnItem = (menuData, onEdit = false) => {
    if (menuData && menuData.parentId && menuData.parentMenu) {
      const localMenuItems = form.menus.map((each) => {
        if (each.id === menuData.parentMenu && Array.isArray(each.menus)) {
          const localInColumns = each.menus.map((eachCol) => {
            let localInMenus = eachCol.menus || [];
            if (eachCol.id === menuData.parentId) {
              if (Array.isArray(localInMenus)) {
                if (onEdit) {
                  localInMenus = localInMenus.map((
                    localMenuItem,
                  ) => (localMenuItem.id === menuData.id
                    ? menuData : localMenuItem));
                } else {
                  localInMenus.push(menuData);
                }
              }
            }
            return { ...eachCol, menus: localInMenus };
          });
          return { ...each, menus: localInColumns };
        }
        return each;
      });
      const newFormData = {
        ...form,
        menus: localMenuItems,
      };
      const updateDataOnUi = () => {
        setForm(newFormData);
      };
      parseMenuObject(newFormData, updateDataOnUi);
    }
  };

  /**
     * This method is used to cancel confirm form
     */
  const cancelConfirm = () => {
    setSelectedData(null);
  };

  /**
     * This method is used to delete a menu item from menu
     * @param {Array} mainMenu
     * @param {Object} itemMenuId
     * @returns {Array}
     */
  const deleteMenuItemCallback = (mainMenu, itemMenuId) => (Array.isArray(mainMenu)
    ? mainMenu.filter(
      (each) => each.id !== itemMenuId,
    ) : mainMenu);

  /**
     * This method for  confirm
     */
  const confirmModal = async () => {
    if (Boolean(selectedData) && selectedData.id) {
      if (selectedData.type === localConstants.delete) {
        const newFormData = {
          ...form,
          menus: findParentMenu(form.menus, selectedData.id, deleteMenuItemCallback),
        };
        const updateDataOnUi = () => {
          setForm(newFormData);
          setSelectedData(null);
        };
        parseMenuObject(newFormData, updateDataOnUi);
      }
    }
  };

  /**
     * This component use to update component
     * @param {Array} childColumns
     * @param {String} parentId
     * @param {Index} parentIndex
     */
  const updateColumns = (childColumns, parentId) => {
    if (Array.isArray(childColumns) && parentId) {
      const localMenuItems = form.menus.map((each) => {
        if (each.id === parentId) {
          return { ...each, menus: childColumns };
        }
        return each;
      });
      const newFormData = {
        ...form,
        menus: localMenuItems,
      };
      const updateDataOnUi = () => {
        setForm(newFormData);
      };
      parseMenuObject(newFormData, updateDataOnUi);
    }
  };

  /**
     * This method is used to save modal
     * @param {Object} newData
     * @param {Number} level
     */
  const onSaveModal = (newData, level) => {
    const onEdit = Boolean(selectedMenu.edit);
    if (newData && level === 0) {
      addNewMenuItem(newData, onEdit);
    }
    if (newData && level === 2) {
      addNewColumnItem(newData, onEdit);
    }
    /**  memory leak fix */
    setTimeout(() => {
      closeNewMenuModal();
    }, 0);
  };

  /**
     * This method is used to delete an element in the menu tree base on item id
     * @param {String} id
     */
  const deleteMenu = (id, event) => {
    if (event) event.preventDefault();
    setSelectedData({ id, type: localConstants.delete });
  };

  /**
     * This function is used to add add menu item
     * @param {Object} content
     */
  // const addSingleMenu = (content) => {
  //   const { id } = content;
  //   if (content) {
  //     const singleMenuItemContent = {
  //       active: true,
  //       allowDropdown: true,
  //       id: getLocalKey(),
  //       menu: id,
  //       menus: [
  //         {
  //           id: getLocalKey(),
  //           columnConfig: {
  //             displayType: "BasicItem",
  //             columnWidth: 6,
  //           },
  //           menus: [],
  //         },
  //       ],
  //       name: "Single Menu",
  //       navigable: false,
  //       parentMenu: "",
  //       type: "Category",
  //       url: "",
  //     };
  //     addNewMenuItem(singleMenuItemContent, false);
  //   }
  // };

  /**
     * This method is used to ope add menu item modal
     * @param {Object} data
     * @param {Integer} level
     */
  const addMenu = ({
    id, columnId, level, column,
  }) => {
    setSelectedMenu({
      id, columnId, level, column,
    });
  };

  /**
     * This method is use to find menu item by me u id
     * @param {String} menuId
     */
  const getMenuItemById = (menuId) => {
    const menuItemFinder = (mainMenuParam, itemMenuId) => {
      const mainMenu = mainMenuParam;
      let returnData;
      if (Array.isArray(mainMenu)) {
        const currentMenu = mainMenu.filter((eachMenu) => (eachMenu.id === itemMenuId))[0];
        if (currentMenu) {
          returnData = currentMenu;
          return returnData;
        } mainMenu.forEach((eachSubMenu) => {
          if (Array.isArray(eachSubMenu.menus)) {
            /** in order tree traversal */
            const inReturnData = menuItemFinder(eachSubMenu.menus, itemMenuId);
            if (inReturnData) {
              returnData = inReturnData;
            }
          }
        });
        return returnData;
      }
      return returnData;
    };
    return menuItemFinder(form.menus, menuId);
  };

  const editMenu = ({
    id, level, columnId, column,
  }) => {
    const edit = getMenuItemById(level ? columnId : id);
    setSelectedMenu({
      id, level, edit, columnId, column,
    });
  };

  /** This method is used to update status change of menu item */
  const handleMenuItemStatus = ({
    id,
  }) => {
    let oldMainMenus = form.menus;
    oldMainMenus = oldMainMenus.map((each) => (each.id === id
      ? { ...each, active: !each.active } : each));
    const newFormData = {
      ...form,
      menus: oldMainMenus,
    };
    const updateDataOnUi = () => {
      setForm(newFormData);
    };
    parseMenuObject(newFormData, updateDataOnUi);
  };

  /**
     * This function is used to update data from history
     * @param {Object} historyEntry
     */
  const updateFromHistory = (historyEntry) => {
    if ((historyEntry && historyEntry.id) === (form && form.id)) {
      setForm(historyEntry);
      parseMenuObject(historyEntry, () => console.log("success"), true);
    }
  };

  /** This function is used to clear error  */
  const removeError = () => {
    setHasError(false);
  };

  return (
    <>
      <MenuDetails
        id={form.id}
        name={form.name}
        position={form.position}
        menus={menus}
        subMenus={form.menus}
        loading={loading}
        mainMenu={lastUpdatedForm}
        hasError={hasError}
        // functions
        handleRootMenuChange={handleRootMenuChange}
        addMenu={addMenu}
        editMenu={editMenu}
        deleteMenu={deleteMenu}
        onDragEnd={onDragEnd}
        getLocalKey={getLocalKey}
        updateColumns={updateColumns}
        handleMenuItemStatus={handleMenuItemStatus}
        updateFromHistory={updateFromHistory}
        removeError={removeError}
        maxMenuItems={maxMenuItems}
      />
      <OpenMenuItemModalContainer
        showModal={Boolean(selectedMenu && Object.keys(selectedMenu).length > 0)}
        parentMenuId={selectedMenu && selectedMenu.id}
        parentId={selectedMenu && selectedMenu.columnId}
        level={selectedMenu && selectedMenu.level}
        column={selectedMenu && selectedMenu.column}
        edit={selectedMenu && selectedMenu.edit}
        isWebResponsiveHeader={isWebResponsiveHeader}
        isWebFooter={isWebFooter}
        menuTypes={menuTypes}
        rootMenus={menus}
        onCancel={closeNewMenuModal}
        onSave={onSaveModal}
        /** for add menu item level 0 */
        handleRootMenuChange={handleRootMenuChange}
        selectedMenuItem={form.id}
        /** for add menu item level 1 */
        menuItems={form.menus}
        getLocalKey={getLocalKey}
      />
      <ConfirmationModal
        isOpen={Boolean(selectedData)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmModal}
        content=" Are you sure you want to delete this menu item ?"
      />
    </>
  );
};

export default MenuDetailsContainer;
