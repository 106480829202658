/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * View SalesRepresentativeInfo Container
 *
 * @author Amritha
 *
 */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ViewSalesRepresentativeInfo from "../components/ViewSalesRepresentativeInfo";
import {
  getSalesRepresentativeInfoById, getB2BDepartmentsById, enableAccessibleOrganizations,
  disableAccessibleOrganizations,
} from "../../../api/customerConfigurationService";

const ViewSalesRepresentativeInfoContainer = () => {
  const params = useParams();
  const { id } = params;
  const [salesRep, setSalesRep] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedB2BDepartments, setSelectedB2BDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const getsalesRepInfo = () => {
    getSalesRepresentativeInfoById(id).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setSalesRep(data);
      }
      setIsLoading(false);
    });
  };
  const onHandleCancel = () => {
    history.push("/administration/sales-representative");
  };
  const getAllB2BDepartments = () => {
    getB2BDepartmentsById(id).then((response) => {
      if (response && response.success && response.data && Array.isArray(response.data)) {
        const { data } = response;
        setDepartments(data);
      }
      setIsLoading(false);
    });
  };
  const onChangeCheckbox = (rowId) => {
    const findIdx = selectedB2BDepartments.indexOf(rowId);
    let checkboxes = [...selectedB2BDepartments];
    if (findIdx > -1) {
      checkboxes = selectedB2BDepartments.filter((e) => e !== rowId);
    } else {
      checkboxes.push(rowId);
    }
    setSelectedB2BDepartments(checkboxes);
  };
  useEffect(() => {
    getAllB2BDepartments();
    getsalesRepInfo();
  }, [id]);
  const selectAllCheckboxes = () => {
    if (departments.every((each) => selectedB2BDepartments.includes(each.id))) {
      setSelectedB2BDepartments([]);
    } else {
      setSelectedB2BDepartments(departments.map((each) => each.id));
    }
  };
  const handleAccessibleOrganizationStatus = async (department, status) => {
    if (department) {
      const { inactiveAccessibleOrganizations, accessibleOrganizations } = salesRep;
      if (status) {
        if (inactiveAccessibleOrganizations) {
          const { b2bDepartments } = inactiveAccessibleOrganizations;
          if (b2bDepartments
            && Array.isArray(b2bDepartments)
            && b2bDepartments.includes(department.id)
          ) {
            const requestData = { userId: id, b2bDepartments: [department.id] };
            await enableAccessibleOrganizations(requestData);
            getAllB2BDepartments();
            getsalesRepInfo();
          }
        }
      } else if (accessibleOrganizations) {
        const { b2bDepartments } = accessibleOrganizations;
        if (b2bDepartments
          && Array.isArray(b2bDepartments)
          && b2bDepartments.includes(department.id)
        ) {
          const requestData = { userId: id, b2bDepartments: [department.id] };
          await disableAccessibleOrganizations(requestData);
          getAllB2BDepartments();
          getsalesRepInfo();
        }
      }
    }
  };
  const disableChecked = () => {
    const { accessibleOrganizations } = salesRep;
    if (accessibleOrganizations) {
      const { b2bDepartments } = accessibleOrganizations;
      if (b2bDepartments
        && Array.isArray(b2bDepartments)
        && b2bDepartments.some((v) => selectedB2BDepartments.includes(v))) {
        const requestData = { userId: id, b2bDepartments: selectedB2BDepartments };
        disableAccessibleOrganizations(requestData).then(() => {
          getAllB2BDepartments();
          getsalesRepInfo();
        });
      }
    }
  };
  const enableChecked = () => {
    const { inactiveAccessibleOrganizations } = salesRep;
    if (inactiveAccessibleOrganizations) {
      const { b2bDepartments } = inactiveAccessibleOrganizations;
      if (b2bDepartments
        && Array.isArray(b2bDepartments)
        && b2bDepartments.some((v) => selectedB2BDepartments.includes(v))) {
        const requestData = { userId: id, b2bDepartments: selectedB2BDepartments };
        enableAccessibleOrganizations(requestData).then(() => {
          getAllB2BDepartments();
          getsalesRepInfo();
        });
      }
    }
  };

  return (
    <ViewSalesRepresentativeInfo
      salesRepInfo={salesRep}
      onHandleCancel={onHandleCancel}
      departments={departments}
      handleAccessibleOrganizationStatus={handleAccessibleOrganizationStatus}
      disableChecked={disableChecked}
      enableChecked={enableChecked}
      onChangeCheckbox={onChangeCheckbox}
      selectAllCheckboxes={selectAllCheckboxes}
      selectedB2BDepartments={selectedB2BDepartments}
      isLoading={isLoading}
    />
  );
};

export default ViewSalesRepresentativeInfoContainer;
