/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Side navigation
 *
 * @author vikhil
 *
 */
import React from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import Row from "../../../common/core/Row";
import styles from "../css/SideNavigation.module.css";
import navClick from "../../../common/assets/images/svg/collapse-left.svg";
import navClickOpen from "../../../common/assets/images/svg/collapse-right.svg";
import { isAuthenticated } from "../../../common/security/permissionUtils";

const SideNavigation = (props) => {
  const {
    hideSideNav, sideNavOpen, navItems, isScrolled, handleNavigation,
  } = props;

  const location = useLocation();

  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    const matchLink = (inPath) => `${location.pathname}`.indexOf(inPath) > -1;

    if (location && location.pathname && Array.isArray(navItems)) {
      const matchLinks = navItems.filter((t) => t && t.path && matchLink(t.path));
      if (matchLinks.length > 0) {
        const activeTag = matchLinks[matchLinks.length - 1];
        setActiveLink(activeTag && activeTag.path);
      }
    }
  }, [location.pathname, navItems, location]);


  return (
    <>
      {sideNavOpen ? (
        <Row
          id="sideNavigationWrapper"
          className={clsx(
            (sideNavOpen && isScrolled && styles.sideNavScroll)
            || (sideNavOpen && !isScrolled && styles.sideNavigationWrapper),
            !sideNavOpen && "hideSideNav",
          )}
        >
          <span className={styles.navClick}>
            <img src={navClick} width="20px" role="presentation" alt="hide side bar icon" onClick={() => hideSideNav()} />
          </span>
          <ListGroup className={clsx(styles.sideNavigations, "custom-scrollbar")}>
            {navItems.map((navItem) => (navItem.path ? (
              <ListGroupItem
                key={navItem.displayText.replace(" ", "_")}
                className={clsx(styles.sideNavItem, activeLink === navItem.path ? styles.active : "")}
                onClick={() => handleNavigation(navItem.path)}
              >
                {navItem.displayText}
              </ListGroupItem>
            ) : (
              <>
                <br />
                <span key={navItem.displayText.replace(" ", "_")}>{navItem.displayText}</span>
              </>
            )))}
          </ListGroup>
        </Row>
      )
        : (
          <>
            { isAuthenticated()
              && (
                <span
                  role="presentation"
                  id="sideNavigationOpenWrapper"
                  className={isScrolled ? styles.navClickCloseScrolled : styles.navClickClose}
                  onClick={() => hideSideNav()}
                >
                  <img src={navClickOpen} width="20px" role="presentation" alt="hide side bar icon" />
                </span>
              )}
          </>
        )}
    </>
  );
};

SideNavigation.propTypes = {
  hideSideNav: PropTypes.func.isRequired,
  sideNavOpen: PropTypes.bool.isRequired,
  navItems: PropTypes.objectOf(PropTypes.any).isRequired,
  isScrolled: PropTypes.bool.isRequired,
  handleNavigation: PropTypes.func.isRequired,
};
export default SideNavigation;
