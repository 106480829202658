/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Vishakh
 */

import React from "react";
import {
  Chart as ChartJS, BarElement, CategoryScale, LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
);

const BarChart = ({ barXAxisData, barData }) => {
  const preOrderValues = barData && barData.xaxis ? barData?.yaxis?.preOrder : [];
  const reOrderValues = barData && barData.xaxis ? barData?.yaxis?.reOrder : [];
  const labels = barData?.xaxis;
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Pre-Order",
        data: preOrderValues,
        borderColor: "rgba(34, 158, 121, 1)",
        backgroundColor: "rgba(34, 158, 121, 1)",
        borderWidth: 1,
        borderRadius: 0,
        borderSkipped: false,
      },
      {
        label: "Re-Order",
        data: reOrderValues,
        borderColor: "rgba(73,188,170,0.4)",
        backgroundColor: "rgba(73,188,170,0.4)",
        borderWidth: 1,
        borderRadius: 0,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
        beginAtZero: true,
      },
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontSize: 25,
        padding: 200,
      },

    },
  };

  return (
    <div>
      <Bar
        data={data}
        height={380}
        options={options}

      />
    </div>
  );
};

BarChart.propTypes = {
  barData: PropTypes.shape({
    PreOrder: PropTypes.array.isRequired,
    ReOrder: PropTypes.array.isRequired,
  }).isRequired,
  barXAxisData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BarChart;
