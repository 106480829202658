/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import React, { useState } from "react";
import CacheClearManagement from "../component/CacheClearManagement";
import {
  clearAllCaches,
  clearPriceBookCache,
  clearPriceTableCache,
  clearPricingEngineCache,
  clearCategoriesCache,
  clearUserGroupsCache,
  clearPromotionsCache,
  clearCouponsCache,
  clearCampaignCache,
  clearCityCache,
  clearAreaCache,
  clearAppAccountsCache,
  clearCatalogCache,
  clearProductsCache,
  clearOrganizationCache,
  clearSystemSettingsCache
} from "../../../api/cacheManagementService";
import { useHistory } from "react-router-dom";

const CacheClearManagementContainer = () => {

  const history = useHistory();

  const [cacheManagement, setCacheManagement] = useState({
    removeAllCache: false,
    removePriceTableCache: false,
    removePriceBookCache: false,
    removeCategoryCache: false,
    removePricingEngineCache: false,
    removeUserGroupCache: false,
    removePromotionsCache: false,
    removeCategoriesCache: false,
    removeUserGroupsCache: false,
    removeCouponsCache: false,
    removeCampaignCache: false,
    removeCityCache: false,
    removeAreaCache: false,
    removeAppAccountsCache: false,
    removeCatalogCache: false,
    removeProductsCache: false,
    removeOrganizationCache: false,
    removeSystemSettingsCache: false,
  });

  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [isProccessing, setIsProccessing] = useState(false);

  /**
   * This method is used to change form state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    console.log("name,", name);
    setCacheManagement({
      ...cacheManagement,
      [name]: value,
    });
  };

  const handleApply = async () => {
    setIsProccessing(true);
    if (cacheManagement.removeAllCache) {
      const response = await clearAllCaches();
      handleAlert(response);
    }
    if (cacheManagement.removePriceTableCache) {
      const response = await clearPriceBookCache();
      handleAlert(response);
    }
    if (cacheManagement.removePriceBookCache) {
      const response = await clearPriceTableCache();
      handleAlert(response);
    }
    if (cacheManagement.removePricingEngineCache) {
      const response = await clearPricingEngineCache();
      handleAlert(response);
    }
    if (cacheManagement.removePromotionsCache) {
      const response = await clearPromotionsCache();
      handleAlert(response);
    }
    if (cacheManagement.removeUserGroupCache) {
      const response = await clearUserGroupsCache();
      handleAlert(response);
    }
    if (cacheManagement.removeCouponsCache) {
      const response = await clearCouponsCache();
      handleAlert(response);
    }
    if (cacheManagement.removeCampaignCache) {
      const response = await clearCampaignCache();
      handleAlert(response);
    }
    if (cacheManagement.removeAreaCache) {
      const response = await clearAreaCache();
      handleAlert(response);
    }
    if (cacheManagement.removeCityCache) {
      const response = await clearCityCache();
      handleAlert(response);
    }
    if (cacheManagement.removeAppAccountsCache) {
      const response = await clearAppAccountsCache();
      handleAlert(response);
    }
    if (cacheManagement.removeCategoriesCache) {
      const response = await clearCategoriesCache();
      handleAlert(response);
    }
    if (cacheManagement.removeCatalogCache) {
      const response = await clearCatalogCache();
      handleAlert(response);
    }
    if (cacheManagement.removeProductsCache) {
      const response = await clearProductsCache();
      handleAlert(response);
    }
    if (cacheManagement.removeOrganizationCache) {
      const response = await clearOrganizationCache();
      handleAlert(response);
    }
    if (cacheManagement.removeSystemSettingsCache) {
      const response = await clearSystemSettingsCache();
      handleAlert(response);
    }
    setIsProccessing(false);
  }

  const handleCancel = () => {
    history.push("/administration");
  }

  const handleAlert = (response) => {
    if (response.messages) {
      setMessage({ type: "warning", message: response.messages[0] });
    }
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 4000);
  }



  return (
    <CacheClearManagement
      cacheManagement={cacheManagement}
      handleChange={handleChange}
      handleApply={handleApply}
      handleCancel={handleCancel}
      message={message}
      isProccessing={isProccessing}
    />
  );
};

export default CacheClearManagementContainer;
