/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Header
 *
 * @author Naseef O
 *
 */
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../../common/assets/images/mozcom.png";
import AngleDown from "../../../common/assets/images/svg/angle-arrow-down.svg";
import notification from "../../../common/assets/images/svg/notifications.svg";
import ProfileDefault from "../../../common/assets/images/svg/profile-default.svg";
import LocationIcon from "../../../common/assets/images/svg/location-blue.svg";
import Col from "../../../common/core/Col";
import Dropdown from "../../../common/core/Dropdown";
import DropdownItem from "../../../common/core/DropdownItem";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import Nav from "../../../common/core/Nav";
import Navbar from "../../../common/core/Navbar";
import NavbarBrand from "../../../common/core/NavbarBrand";
import NavbarText from "../../../common/core/NavbarText";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";
import Row from "../../../common/core/Row";
import { isAuthenticated, hasMultipleSites } from "../../../common/security/permissionUtils";
import styles from "../css/Header.module.css";


const Header = (props) => {
  const {
    navigation, menuHandler, mainMenus,
  } = props;

  const [dropdownOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const { t } = useTranslation(["header"]);

  /**
 * This method is used to handle toggle dropdown
 *
 */
  const handleToggleDropdown = () => setIsOpen(!dropdownOpen);

  // const [selectedLanguage, setLanguage] = useState();
  // useEffect(() => {
  //   let ln = "English";
  //   if (i18n.language === "ar") {
  //     ln = "العربية";
  //   }
  //   setLanguage(ln);
  // }, [i18n.language]);

  /**
   * This method is used to change language
   *
   * @param {String} lng
   */
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  const handleChangepassword = () => {
    props.handleChangepassword();
    history.push("/change-password");
  };
  /**
   * This method is used to change language
   *
   * @param {String} lng
   */
  const handlelogout = () => {
    props.handleLogout();
    history.push("/login");
  };

  const handleChangeSite = () => {
    history.push("/select-site");
  };

  /**
 *This method is used to handle scrol
 *
 */
  // const handleScroll = () => {
  //   const element = document.getElementById("menuScrollWrap2");
  //   const element2 = document.getElementById("headerWrapper");
  //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 46) {
  //     element.classList.add("headerNavScroll");
  //     element2.classList.add("headerWrapperScroll");
  //   } else {
  //     element.classList.remove("headerNavScroll");
  //     element2.classList.remove("headerWrapperScroll");
  //   }
  // };

  // Check whether DOM manipulation is a best practice ?


  return (
    <>
      <div id="headerWrapper" className={styles.headerWrapper}>
        <Navbar>
          <Row className={clsx(styles.menuWrap, "m-0 ")}>
            <Col xs="12" className="p-0">
              <NavbarBrand href="/"><img src={logo} className={styles.logo} alt="logo of moz commerce" /></NavbarBrand>

              <div className="float-right">
                <Dropdown
                  isOpen={dropdownOpen}
                  className={styles.profileButton}
                  toggle={handleToggleDropdown}
                >
                  <DropdownToggle className={styles.btnProfile}>
                    <div className={styles.profileData}>
                      <figure>
                        <img src={ProfileDefault} alt="" />
                      </figure>
                      {/* <div className={styles.profileName}>John Doe</div> */}
                      <img src={AngleDown} className={styles.profileArrow} alt="" />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu right className={styles.dropdownMenu}>
                    <DropdownItem
                      className={styles.dropdownItem}
                      onClick={handleChangepassword}
                    >
                      {t("header:label_change_password")}
                    </DropdownItem>
                    <DropdownItem
                      className={styles.dropdownItem}
                      onClick={handlelogout}
                    >
                      {t("header:label_logout")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

              {/* {isAuthenticated() ? (
                <NavbarText className={styles.logout} onClick={handlelogout}>
                  {t("header:logout")}
                  <img src={logoutImage} alt="logout icon" />
                </NavbarText>
              )
                : null} */}

              {/* <Row className="float-right">
                <span className={styles.selectedLanguage}>{selectedLanguage}</span>
                <Dropdown
                  isOpen={dropdownOpen}
                  className={styles.languageSwitcher}
                  toggle={handleToggleDropdown}
                >
                  <DropdownToggle caret className={styles.dropdownButton}>
                    <img src={language} alt="language switcher" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => changeLanguage("en")}>English</DropdownItem>
                    <DropdownItem onClick={() => changeLanguage("ar")}>العربية</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Row> */}
            </Col>
          </Row>
          <Row id="menuScrollWrap2" className={clsx(styles.menuWrap2)}>
            {isAuthenticated()
              ? (
                <Col>
                  <Nav className="" navbar>
                    {/* adding active class based on navigation value
                    [dynamic select style - color change] */}
                    {
                      Array.isArray(mainMenus) && mainMenus.map((menuItem) => (
                        <NavItem>
                          <NavLink href="" className={menuItem.id === navigation ? "active" : ""} onClick={(o) => menuHandler(menuItem.id, o)}>{menuItem.label}</NavLink>
                        </NavItem>
                      ))
                    }
                  </Nav>
                </Col>
              )
              : null}
          </Row>
        </Navbar>
      </div>
    </>
  );
};

Header.propTypes = {
  mainMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  // navigation
  navigation: PropTypes.string.isRequired,
  menuHandler: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleChangepassword: PropTypes.func.isRequired,
};

export default Header;
