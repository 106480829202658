/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
/** ============ SVG IMAGE ICON ===================== */
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import DownloadIcon from "../../../common/assets/images/svg/get_app.svg";
import ImportIcon from "../../../common/assets/images/svg/publish.svg";
import UpdateIcon from "../../../common/assets/images/svg/update.svg";
/** ========= CUSTOM COMPONENTS ========= */
import CustomizationTable from "../../../common/components/Table";
import Alert from "../../../common/core/Alert";
import Button from "../../../common/core/Button";
/** ========= TAG COMPONENTS ========= */
import Collapse from "../../../common/core/Collapse";
/** ========= MODULE STYLES ========= */
import styles from "../css/MegaMenuItemCreator.module.css";
class IndexedDb {
  indexedDB = null;
  IDBTransaction = null;
  IDBKeyRange = null;
  dbName = null;
  db = null;
  error = null;
  collection = "history";

  constructor(dbName, collection) {
    this.dbName = `${dbName}_${collection}`;
    this.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    this.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
    this.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
  }

  hasSupport = () => {
    return Boolean(this.indexedDB)
  }

  loadDatabase = (callback) => {
    try {
      if (this.hasSupport()) {
        const request = this.indexedDB.open(this.dbName, 1);
        request.onerror = (event) => {
          this.error = true;
          this.db = null;
        };

        request.onsuccess = () => {
          this.db = request.result;
          if (typeof callback === "function") { callback(this.db); }
        };

        request.onupgradeneeded = (event) => {
          // const db = event.target.result;
          //   const objectStore = db.createObjectStore(this.collection, {keyPath: "id"});
        }
      }
    } catch (error) {

    }
  };

  add = (content, callback) => {
    try {
      if (this.db) {
        const request = this.db.transaction([this.collection], "readwrite")
          .objectStore(this.collection).add(content);

        request.onsuccess = (event) => {
          if (typeof callback === "function") { callback(true) };
        };

        request.onerror = (event) => {
          if (typeof callback === "function") { callback(false) };
        }
      }
    } catch (error) {

    }
  };

  readAll = (callback) => {
    try {
      if (this.db) {
        const objectStore = this.db.transaction(this.collection).objectStore(this.collection);
        const results = [];
        objectStore.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            results.push({
              key: cursor.key,
              value: cursor.value
            });
            cursor.continue();
          }
          else {
            callback(results);
          }
        };
      }
    } catch (error) {

    }
  }

  remove = (id, callback) => {
    try {
      if (this.db) {
        const request = this.db.transaction([this.collection], "readwrite")
          .objectStore(this.collection)
          .delete(id);

        request.onsuccess = (event) => {
          if (typeof callback === "function") { callback(true) };
        };

        request.onerror = (event) => {
          if (typeof callback === "function") { callback(false) };
        }
      }
    } catch (error) {

    }
  }

  read = (id, callback) => {
    try {
      if (this.db) {
        const transaction = this.db.transaction([this.collection]);
        const objectStore = transaction.objectStore(this.collection);
        const request = objectStore.get(id);

        request.onerror = function (event) {
          if (typeof callback === "function") { callback(false) };
        };

        request.onsuccess = function (event) {
          if (typeof callback === "function") { callback(request.result) };
        };
      }
    } catch (error) {

    }
  }
}

class JSONReader {
  constructor(completed = null) {
    this.onCompleted = completed;
    this.result = undefined;
    this.input = document.createElement('input');
    this.input.type = 'file';
    this.input.accept = 'text/json|application/json';
    this.input.addEventListener('change', this.onChange.bind(this), false);
    this.input.style.display = 'none';
    document.body.appendChild(this.input);
    this.input.click();
  }

  destroy() {
    this.input.removeEventListener('change', this.onChange.bind(this), false);
    document.body.removeChild(this.input);
  }

  onChange(event) {
    if (event.target.files.length > 0) {
      this.readJSON(event.target.files[0]);
    }
  }

  readJSON(file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target.readyState === 2) {
        this.result = JSON.parse(reader.result);
        if (typeof this.onCompleted === 'function') {
          this.onCompleted(this.result);
        }
        this.destroy();
      }
    };
    reader.readAsText(file);
  }

  static read(callback = null) {
    return new JSONReader(callback);
  }
}

const History = (props) => {

  const { data, onUpdate, collection } = props;
  const maxLimit = 10;

  //  local storage
  const [db, setDb] = useState(null);
  const [localData, setLocalData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  /** internationalization using i18n from common and site-navigation  */
  //   const { t } = useTranslation(["common", "site-navigation"]);

  useEffect(() => {
    if (collection) {
      const localDb = new IndexedDb("siteNavigationHistory", collection);
      if (localDb.hasSupport()) {
        localDb.loadDatabase(() => {
          setDb(localDb);
        });
      }
    }
  }, [collection]);


  useEffect(() => {
    if (db) {
      db.readAll(response => {
        if (Array.isArray(response)) {
          response.sort(function (a, b) {
            return (a && a.value && a.value.time) - (b && b.value && b.value.time);
          });
          setLocalData(response.map(each => each && each.value));
        }
      });
    }
  }, [db]);

  useEffect(() => {
    const updatedCallback = (status) => {
      if (status) {
        if (db) {
          db.readAll(response => {
            if (Array.isArray(response)) {
              response.sort(function (a, b) {
                return (a && a.value && a.value.time) - (b && b.value && b.value.time);
              });

              const currentDataLength = response.length;
              if (currentDataLength > maxLimit && response[0]) {
                db.remove(response[0].key, updatedCallback);
              } else {
                setLocalData(response.map(each => each && each.value));
              }
            }
          });
        }
      }
    }

    const id = Math.random().toString(33);
    if (db && db.add && data) {
      db.add({
        id,
        data,
        time: new Date()
      }, updatedCallback);
    }
  }, [db, data]);

  const updatedCallback = (status) => {
    if (status) {
      if (db) {
        db.readAll(response => {
          if (Array.isArray(response)) {
            setLocalData(response.map(each => each && each.value));
          }
        });
      }
    }
  }

  const actionHandler = (itemId) => {
    if (db && itemId) {
      db.remove(itemId, updatedCallback);
    }
  }

  /**
   * This function is used to update last site navigation
   * @param {Object} rowContent 
   */
  const onLocalUpdate = (rowContent) => {
    if (rowContent && rowContent.data && rowContent.data.id) {
      if (typeof onUpdate === "function") {
        onUpdate(rowContent.data);
      }
    }
  }

  /**
   * This function is used to export json
   * @param {Object} data 
   */
  const downloadJson = (data) => {
    if (data && data.data) {
      try {
        const name = `${data.time ? new Date(data.time).getTime() : "site-navigation"}.json`
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data.data));
        const dlAnchorElem = document.createElement('a');
        dlAnchorElem.setAttribute("href", dataStr);
        dlAnchorElem.setAttribute("download", name);
        dlAnchorElem.click();
      } catch (error) {
        console.error(error);
      }
    }
  }

  const editAction = (row) => {

    return (
      <>
        <span
          className="commonPointer mr-1"
          onClick={() => actionHandler(row.id)}
          role="button"
          tabIndex={0}
          onKeyPress={() => { }}
        >
          <img
            className={styles.icon}
            src={DeleteIcon}
            alt="Delete"
          />
        </span>
        <span
          className="commonPointer mr-1"
          onClick={() => downloadJson(row)}
          role="button"
          tabIndex={0}
          onKeyPress={() => { }}
        >
          <img
            className={styles.icon}
            src={DownloadIcon}
            alt="Download"
          />
        </span>
        <span
          className="commonPointer mr-1"
          onClick={() => onLocalUpdate(row)}
          role="button"
          tabIndex={0}
          onKeyPress={() => { }}
          title="Update"
        >
          <img
            className={styles.icon}
            src={UpdateIcon}
            alt="Update"
          />
        </span>
      </>
    );
  }

  /** This function is sued to import data */
  const importJson = () => {
    JSONReader.read((result) => {
      try {
        if (result && result.id && result.name && result.position === collection && Array.isArray(result.menus)) {
          onUpdate(result);
        }
      } catch (error) {

      }
    });
  }

  return (<div>
    <div className="mb-3 d-flex cursor-pointer" role="presentation" onClick={toggle}>
      <span>
        <span className="pageText h6 mb-1">Local History</span>
        {
          isOpen && (
            <p className="mb-0 text-muted"> Last 10 site navigation entries</p>
          )
        }
      </span>
      <span className="ml-auto" >
        {
          isOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /></svg>
          ) : (
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" /></svg>
            )
        }
      </span>
    </div>
    <Collapse isOpen={isOpen}>
      <>
        {
          db ? (Array.isArray(localData) && localData.length > 0 ? (<div className="custom-table">
            <CustomizationTable
              isSerialNoEnabled
              customRows={[
                {
                  label: "Time",
                  path: "render",
                  render: r => r && r.time && r.time.toLocaleString && r.time.toLocaleString()
                },
                {
                  label: "Actions",
                  path: "render",
                  render: editAction,
                }
              ]}
              customizationData={localData}
            />
          </div>) : null) : (
              <Alert color="warning">
                Your browser doesn't support a stable version of IndexedDB.
                Such and such feature will not be available.
              </Alert>
            )
        }
        <div className="w-100 d-flex">
          <div className="ml-auto mb-1">
            <Button
              onClick={importJson}
              color="info"
            >
              <img
                className={styles.icon}
                src={ImportIcon}
                alt="Import"
              />
            </Button>
          </div>
        </div>
      </>
    </Collapse>
  </div>);
};

History.defaultProps = {
  data: null,
  onUpdate: undefined,
  collection: "history"
}

History.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  collection: PropTypes.string,
  onUpdate: PropTypes.func
};

export default History;
