/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Authentication and authorization utilities
 * @module
 */
import user from "./UserData";

export const isAuthenticated = () => user.getAuthenticated() === "true";

export const hasMultipleSites = () => {
  const locations = (user.getAccessibleLocations() || "").split(",");
  if (locations && Array.isArray(locations) && locations.length > 1) {
    return true;
  }
  return false;
};

export const getSiteDetails = () => {
  const siteData = user.getSelectedSite();
  if (siteData) {
    return {
      locationId: siteData.locationId,
      brandId: siteData.brandId,
      siteName: siteData.siteName,
    };
  }
  return {
    locationId: null, brandId: null, siteName: null,
  };
};
/**
 * Checking logged in user has any one of accessrights
 * @param {Array} rights
 */
export const hasAccessRights = (rights) => {
  const allowedRightsString = user.getAuthorities();
  const allowedRights = (allowedRightsString || "").split(",");
  return Array.isArray(allowedRights)
    ? rights.every((right) => allowedRights.includes(right)) : false;
};


/**
 * Checking logged in user has any one of role
 * @param {Array} roles
 */
export const hasRole = (roles) => roles.some((role) => user.getType() === role);
