/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import DragIcon from "../../../common/assets/images/svg/dragIndicator.svg";
/** ========= SVG IMAGE ICON ========= */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import FullscreenIcon from "../../../common/assets/images/svg/fullscreen.svg";
import ExitFullscreenIcon from "../../../common/assets/images/svg/fullscreen_exit.svg";
import RightArrowIcon from "../../../common/assets/images/svg/right-arrow.svg";
/** ========= TAG COMPONENTS ========= */
import Collapse from "../../../common/core/Collapse";
import Label from "../../../common/core/Label";
import ListGroupItem from "../../../common/core/ListGroupItem";
import UncontrolledPopover from "../../../common/core/UncontrolledPopover";
/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";
/** ========= MODULE STYLES ========= */
import styles from "../css/MenuDetails.module.css";
/** ========= SUB COMPONENT ========= */
import MenuColumn from "./MenuColumn";

const MainMenuItem = (props) => {
  const {
    level, index, id, name, menus, allowDropdown, resizeAnimDiv, resizeAnimContainer, onResizing, active,
    hiddenHeader, collapseOpen, minimal, fullscreen, menuCount, position, image,
    editMenu, deleteMenu, addMenu, handleMenuItemStatus,
    getLocalKey,
    onMouseDown, onMouseMove, onMouseUp, openFullscreen, addNewColumn, removeColumn,
  } = props;
  const { NAVIGATION_MENU_POSITIONS } = constants;

  /** local states */
  const [open, setOpen] = useState(false);

  /**
   * This method is used to toggle the collapse
   */
  const toggleSubMenu = () => {
    setOpen((each) => !each);
  };

  const innerRefJoin = (current, funOne, funTwo) => {
    funOne.current = current;
    funTwo(current);
    return funOne;
  };

  const hasDropDown = allowDropdown && Array.isArray(menus) && menus.length > 0;
  //   const isWebHeader = NAVIGATION_MENU_POSITIONS.WEB_HEADER === position;
  const isWebResponsiveHeader = NAVIGATION_MENU_POSITIONS.WEB_RESPONSIVE_HEADER === position;

  let mainCollapseClasses = " rounded-bottom";
  mainCollapseClasses = fullscreen ? `${mainCollapseClasses} p-3` : `${mainCollapseClasses} pl-4`;

  return (
    <Draggable draggableId={id} width="100%" index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={fullscreen ? styles.fullscreen : ""}
        >
          <div className={styles.fullscreenBody}>
            {
              fullscreen
              && (
                <div className="d-flex px-3 pt-3 pb-2 border-bottom rounded-top">
                  <span className="text-truncate pl-2 font-weight-normal font-weight-bold text-body mr-auto">
                    {name}
                  </span>
                  <span disabled={false} className="ml-auto d-flex p-1 commonPointer " onClick={(o) => openFullscreen(id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                    <img
                      className={styles.icon}
                      src={ExitFullscreenIcon}
                      alt="Fullscreen edit"
                    />
                  </span>
                </div>
              )
            }
            <ListGroupItem
              tag="div"
              className={`list-group-item px-3 mb-1 border ${hiddenHeader ? "d-none" : ""}`}
            >
              <span className="d-flex align-items-center">
                {
                  menuCount > 1 && (
                    <span
                      className={clsx("px-1", styles.cursorMove)}
                      {...provided.dragHandleProps}
                    >
                      <img className={styles.icon} src={DragIcon} alt="" />
                    </span>
                  )
                }
                <span className={clsx("px-1", "mx-1", "commonPointer", styles.collapseIcon)} onClick={toggleSubMenu} role="button" tabIndex={0} onKeyPress={() => { }}>
                  {
                    hasDropDown
                    && <img src={RightArrowIcon} className={clsx(styles.icon, open ? styles.openCollapse : styles.closeCollapse)} alt="" />
                  }
                </span>
                <span className="text-truncate pl-2 font-weight-normal text-body mr-auto">
                  {name}
                </span>
                <span className="pr-1">
                  {
                    image && (
                      <>
                        <img id={`${id}-contentImageView`} height="20px" src={image} alt="" />
                        <UncontrolledPopover placement="left" trigger="hover" target={`${id}-contentImageView`}>
                          <img id="contentImageView" className="img-thumbnail" src={image} alt="" />
                        </UncontrolledPopover>
                      </>
                    )
                  }
                </span>
                <span className={clsx("d-flex", "justify-content-center", "pr-1", styles.actions)}>
                  <span>
                    <div
                      className={clsx(
                        "custom-control",
                        "custom-switch",
                        // submitting && selectedMenuId === row.id ? "loading" : "",
                      )}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`expand-multiple-main-menu-item-${id}`}
                        checked={active}
                        onChange={(o) => handleMenuItemStatus({ id, level, active }, o)}
                      />
                      <Label className="custom-control-label" htmlFor={`expand-multiple-main-menu-item-${id}`} />
                    </div>
                  </span>
                  {
                    hasDropDown
                    && (
                      <span disabled={false} className="commonPointer mr-2" onClick={(o) => openFullscreen(id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                        <img
                          className={styles.icon}
                          src={FullscreenIcon}
                          alt="Fullscreen"
                        />
                      </span>
                    )
                  }
                  <span disabled={false} className="commonPointer mr-2" onClick={(o) => editMenu({ id, level }, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                    <img
                      className={styles.icon}
                      src={EditIcon}
                      alt="Edit"
                    />
                  </span>
                  <span disabled={false} className="commonPointer" onClick={(o) => deleteMenu(id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                    <img
                      className={styles.icon}
                      src={DeleteIcon}
                      alt="Delete"
                    />
                  </span>
                </span>
              </span>
            </ListGroupItem>
            {
              hasDropDown
              && (
                <Collapse isOpen={open || fullscreen} className={mainCollapseClasses}>
                  <Droppable
                    key={id}
                    droppableId={`col_menu_${id}_${level}_${index}`}
                    type={`droppableSubMenu_${getLocalKey()}`}
                    direction="horizontal"
                  >

                    {(providedIn) => (
                      <div
                        {...providedIn.droppableProps}
                        className={clsx("row", "no-gutters", "position-relative", styles.menuColumnContainer, onResizing ? styles.cursorResizeChild : "")}
                        ref={(o) => innerRefJoin(o, resizeAnimContainer, providedIn.innerRef)}
                        onMouseMove={(o) => onMouseMove(index, o)}
                        onMouseUp={(o) => onMouseUp(index, o)}
                        role="presentation"
                      >
                        <div ref={resizeAnimDiv} className={styles.dummyResize} />
                        {
                          (isWebResponsiveHeader ? [menus[0]] : menus).map((menu, indexIn) => (menu && menu.columnConfig ? (
                            <MenuColumn
                              parentId={id}
                              collapseOpen={collapseOpen}
                              minimal={minimal}
                              level={level + 1}
                              minView={hasDropDown}
                              columnConfig={menu.columnConfig}
                              rootMenuLength={menus.length}
                              index={indexIn}
                              id={menu.id}
                              key={menu.id}
                              menus={menu.menus}
                              addNewColumn={addNewColumn}
                              removeColumn={removeColumn}
                              onMouseDown={onMouseDown}
                              onMouseUp={onMouseUp}
                              editMenu={editMenu}
                              addMenu={addMenu}
                              deleteMenu={deleteMenu}
                              getLocalKey={getLocalKey}
                              position={position}
                            />
                          ) : null))
                        }
                        {providedIn.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Collapse>
              )
            }
          </div>
        </div>
      )}
    </Draggable>
  );
};

MainMenuItem.defaultProps = {
  menus: null,
  allowDropdown: false,
  hiddenHeader: false,
  collapseOpen: false,
  minimal: false,
  fullscreen: false,
  iamge: null,
};

MainMenuItem.propTypes = {
  fullscreen: PropTypes.bool,
  level: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  position: PropTypes.string.isRequired,
  allowDropdown: PropTypes.bool,
  onResizing: PropTypes.bool.isRequired,
  hiddenHeader: PropTypes.bool,
  collapseOpen: PropTypes.bool,
  minimal: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  resizeAnimDiv: PropTypes.objectOf(PropTypes.any).isRequired,
  resizeAnimContainer: PropTypes.objectOf(PropTypes.any).isRequired,
  menus: PropTypes.arrayOf(PropTypes.any),
  menuCount: PropTypes.number.isRequired,
  editMenu: PropTypes.func.isRequired,
  deleteMenu: PropTypes.func.isRequired,
  addMenu: PropTypes.func.isRequired,
  getLocalKey: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  openFullscreen: PropTypes.func.isRequired,
  addNewColumn: PropTypes.func.isRequired,
  removeColumn: PropTypes.func.isRequired,
  handleMenuItemStatus: PropTypes.func.isRequired,
};

export default MainMenuItem;
