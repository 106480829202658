/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay
 *
 */

import httpApi from "./httpApi";

/* TODO :- Delete the dummy jobHistoryData object */
const jobHistoryData = [
  {
    id: 89768677,
    name: "MozCome Order Update",
    status: "SUCCESS",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    jobHistorySubTask: [
      {
        name: "FTP File Read",
        status: "SUCCESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
      },
      {
        name: "Update Artic",
        status: "SUCCESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
      },
    ],
  },
  {
    id: 89768677,
    name: "MozCome Order Update",
    status: "INPROGRESS",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    jobHistorySubTask: [
      {
        name: "Pricing Job TRU",
        status: "INPROGRESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
      },
      {
        name: "Pricing Job ACE",
        status: "INPROGRESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
      },
    ],
  },
  {
    id: 89768677,
    name: "MozCome Order Update",
    status: "WARNING",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    jobHistorySubTask: [
      {
        name: "Inventory Job TRU",
        status: "WARNING",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
      },
      {
        name: "Inventory Job ACE",
        status: "WARNING",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
      },
    ],
  },
  {
    id: 89768677,
    name: "MozCome Order Update",
    status: "FAILED",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    jobHistorySubTask: [
      {
        name: "Export Order Job TRU",
        status: "FAILED",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
      },
      {
        name: "Export Order Job ACE",
        status: "FAILED",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
      },
    ],
  },
];


const jobDetailsConfigData = {
  name: "Order Posting",
  description: "Order posting Jobs",
  scope: [
    {
      name: "KSA",
      id: "KSA",
    },
    {
      name: "IND",
      id: "IND",
    },
  ],
  locations: ["UAE"],
  jobScope: ["Mozcom"],
  recurring: "ONLY_ONCE",
  date: "",
  frequencyFromDate: "",
  frequencyEndDate: "",
  noEndDate: true,
  selectedWeekDays: ["Mon", "Tue", "Wed", "Thu", "Sun"],
  startDateTime: "",
  startType: "PARTICULAR_TIME",
  startTime: "",
  intervalTime: "",
  intervalTimeUnit: "HR",
  errorHandlingType: "RETRY_JOB",
  alertStatus: "In Progress",
  retryCount: 0,
  notificationChannel: "EMAIL",
  notificationStatuses: [],
  notificationEmailTo: "Hashim.muhammad@gmail.com",
  notificationEmailCC: "Shahul.hammed@gmail.com",
  notificationSMSTo: "",
};

const jobTaskConfigData = [
  {
    jobTaskName: "pricing job",
    jobTaskType: "FTP",
  },
  {
    jobTaskName: "inventory job",
    jobTaskType: "FTP",
  },
  {
    jobTaskName: "OrderListing job",
    jobTaskType: "API",
  },
];

const jobList = [
  {
    id: 89768677,
    name: "Order Posting",
    status: "SUCCESS",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    duration: "11:13",
    jobTask: [
      {
        id: 89768677222,
        name: "Order Posting TRU",
        status: "SUCCESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
        duration: "11:13",
      },
      {
        id: 89768688222,
        name: "Order Posting ACE",
        status: "SUCCESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
        duration: "11:13",
      },
    ],
  },
  {
    id: 89768672,
    name: "Pricing Job",
    status: "INPROGRESS",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    duration: "11:13",
    jobTask: [
      {
        id: 89768675552,
        name: "Pricing Job TRU",
        status: "INPROGRESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
        duration: "11:13",
      },
      {
        id: 89768622222,
        name: "Pricing Job ACE",
        status: "INPROGRESS",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
        duration: "11:13",
      },
    ],
  },
  {
    id: 89763222,
    name: "Inventory Job",
    status: "WARNING",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    duration: "11:13",
    jobTask: [
      {
        id: 89768227222,
        name: "Inventory Job TRU",
        status: "WARNING",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
        duration: "11:13",
      },
      {
        id: 89118677222,
        name: "Inventory Job ACE",
        status: "WARNING",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
        duration: "11:13",
      },
    ],
  },
  {
    id: 89768022,
    name: "Export Order Job",
    status: "FAILED",
    lastRun: "04-25-2019 11-11-54 AM",
    scope: "Site",
    duration: "11:13",
    jobTask: [
      {
        id: 89768622222,
        name: "Export Order Job TRU",
        status: "FAILED",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "TRU",
        duration: "11:13",
      },
      {
        id: 81168677222,
        name: "Export Order Job ACE",
        status: "FAILED",
        lastRun: "04-25-2019 11-11-54 AM",
        scope: "ACE",
        duration: "11:13",
      },
    ],
  },
];

const jobListStatus = [{ value: "All", label: "All" }, { value: "STARTED", label: "Started" }, { value: "COMPLETED", label: "Completed" }, { value: "FAILED", label: "Failed" }, { value: "STOPPED", label: "Stopped" }];
const jobListScope = [{ value: "All", label: "All Brands" }, { value: "MozCom", label: "MozCom" }, { value: "TRU", label: "TRU" }];
const jobHistoryRunTimes = [{ value: "Last Seven Days", label: "Last Seven Days" }, { value: "Last Month", label: "Last Month" },
{ value: "Date Range", label: "Date Range" }];

const locationList = {
  All: [
    {
      value: "All",
      label: "ALL",
    },
  ],
  MozCom: [
    {
      value: "KSA",
      label: "KSA",
    },
    {
      value: "IND",
      label: "IND",
    },
    {
      value: "UAE",
      label: "UAE",
    },
    {
      value: "USA",
      label: "USA",
    },
  ],
  TRU: [
    {
      value: "CHA",
      label: "CHA",
    },
    {
      value: "JPN",
      label: "JPN",
    },
  ],
};

const jobTypes = [
  {
    label: "Select job type",
    disabled: true,
    selected: true,
  },
  {
    value: "FTP",
    label: "FTP",
  },
  {
    value: "API",
    label: "API",
  }];

const jobTaskTemplateData = {
  FTP: [
    {
      inputType: "INPUT_FIELD",
      label: "Job task name",
      inputName: "jobTaskName",
      attributes: {
        inputFieldType: "text",
      },
    },
    {
      inputType: "INPUT_FIELD",
      label: "Host",
      inputName: "host",
      attributes: {
        inputFieldType: "text",
      },
    },
    {
      inputType: "SINGLE_SELECT",
      label: "Logon type",
      inputName: "loginType",
      attributes: {
        options: [
          {
            label: "Select login type",
            disabled: true,
            selected: true,
          },
          {
            value: "ASK_FOR_PASSWORD",
            label: "Ask for Password",
          },
        ],
      },
    },
    {
      inputType: "INPUT_FIELD",
      label: "Username",
      inputName: "username",
      attributes: {
        inputFieldType: "text",
      },
    },
    {
      inputType: "INPUT_FIELD",
      label: "Password",
      inputName: "password",
      attributes: {
        inputFieldType: "password",
      },
    },
    {
      inputType: "MULTI_SELECT_CHECKBOX",
      label: "Accepted file type(s)",
      inputName: "inputFileTypes",
      attributes: {
        options: [
          {
            label: "XML",
            value: "XML",
          },
          {
            label: "JSON",
            value: "JSON",
          },
        ],
      },
    },
    {
      inputType: "DATE_FIELD",
      label: "Start date",
      inputName: "startDate",
      attributes: {
        showTime: true,
        showDate: true,
        dateFormat: "DD-MM-YYYY",
        timeFormat: "HH:mm:ss",
        displayFormat: "DD-MM-YYYY HH:mm:ss",
        readOnly: true,
        closeOnSelect: true,
        placeholder: "Select Date",
        checkValidDate: true,
        allowFutureDate: false,
        allowPastDate: false,
      },
    },
    {
      inputType: "RADIO_BUTTON_GROUP",
      label: "Select job status",
      inputName: "activeStatus",
      attributes: {
        options: [
          {
            label: "Active",
            value: "on",
          },
          {
            label: "Deactive",
            value: "off",
          },
        ],
      },
    },
  ],
  API: [
    {
      inputType: "INPUT_FIELD",
      label: "Job task name",
      inputName: "jobTaskName",
      attributes: {
        inputFieldType: "text",
      },
    },
    {
      inputType: "INPUT_FIELD",
      label: "Api URL",
      inputName: "apiUrl",
      attributes: {
        inputFieldType: "text",
      },
    },
    {
      inputType: "SINGLE_SELECT",
      label: "Api method type",
      inputName: "method",
      attributes: {
        options: [
          {
            label: "Select method type",
            disabled: true,
            selected: true,
          },
          {
            label: "GET",
            value: "GET",
          },
          {
            label: "POST",
            value: "POST",
          },
          {
            label: "DELETE",
            value: "DELETE",
          },
        ],
        conditionalFields: [
          {
            inputType: "INPUT_FIELD",
            label: "Request body",
            inputName: "reqBody",
            attributes: {
              inputFieldType: "textarea",
              dependsOn: "POST",
            },
          },
        ],
      },
    },
    {
      inputType: "SINGLE_SELECT",
      label: "Authentication type",
      inputName: "authType",
      attributes: {
        options: [
          {
            label: "Select auth type",
            disabled: true,
            selected: true,
          },
          {
            label: "Basic auth",
            value: "BASIC_AUTH",
          },
        ],
        conditionalFields: [
          {
            inputType: "INPUT_FIELD",
            label: "Username",
            inputName: "username",
            attributes: {
              inputFieldType: "text",
              dependsOn: "BASIC_AUTH",
            },
          },
          {
            inputType: "INPUT_FIELD",
            label: "Password",
            inputName: "password",
            attributes: {
              inputFieldType: "password",
              dependsOn: "BASIC_AUTH",
            },
          },
        ],
      },
    },
  ],
};


/**
 * This method is used to get job list
 *
 * @param {*} id
 * @returns
 */
const getJobList = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobList };
  if (response && response.success && response.data) {
    return response;
  }
};

/**
 * This method is used to get Job History details by Id
 *
 * @param {*} id
 * @returns
 */
const getJobHistoryById = async (id) => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobHistoryData };
  if (response && response.success && response.data) {
    return response;
  }
};

/**
 * This method is used to get Job History details
 *
 * @returns
 */
const getJobHistory = async (data) => {
  const response = await httpApi.post("/v1/batchjob/logs", data);
  if (response && response.data) {
    return response.data;
  }
};

/**
 * This method is used to get job Listing page statuses in dropdown
 *
 * @param {*} id
 * @returns
 */
const getJobListStatus = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobListStatus };
  if (response && response.success && response.data) {
    return response;
  }
};

/**
 * TThis method is used to get job Listing page scopes in dropdown
 *
 * @param {*} id
 * @returns
 */
const getJobListScope = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobListScope };
  if (response && response.success && response.data) {
    return response;
  }
};

/**
 * This method is used to get job History page runTimes in dropdown
 *
 * @param {*} id
 * @returns
 */
const getJobHistoryRunTimes = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobHistoryRunTimes };
  if (response && response.success && response.data) {
    return response;
  }
};

/**
 * This method is used to get job History page statuses in dropdown
 *
 * @param {*} id
 * @returns
 */
const getJobHistoryStatuses = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobListStatus };
  if (response && response.success && response.data) {
    return response;
  }
};

/**
 * This method is used to get job task type in dropdown
 *
 * @param {*} id
 * @returns
 */
const getJobTaskTypes = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobTypes };
  if (response && response.success && response.data) {
    return response;
  }
  return null;
};

/**
 * This method is used to get job task template
 *
 * @param {*} id
 * @returns
 */
const getJobTaskTemplateDataByType = async (jobTaskType) => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobTaskTemplateData[jobTaskType] };
  if (response && response.success && response.data) {
    return response;
  }
  return null;
};

/**
 * This method is used to get job location in dropdown
 *
 * @param {*} id
 * @returns
 */
const getLocationListByScope = async (jobScope) => {
  let data = [];
  if (jobScope && jobScope.length > 0) {
    jobScope.forEach((scope) => {
      data = data.concat(locationList[scope]);
    });
  }
  const response = { success: true, data };
  if (response && response.success && response.data) {
    return response;
  }
  return null;
};

/**
 * This method is used to get job details in jobs page
 *
 * @param {*} id
 * @returns
 */
const getJobDetails = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobDetailsConfigData };
  if (response && response.success && response.data) {
    return response;
  }
};

/**
 * This method is used to get job task in jobs page
 *
 * @param {*} id
 * @returns
 */
const getJobTask = async () => {
  /* TODO :- Delete the dummy send array and return actual response from api */
  const response = { success: true, data: jobTaskConfigData };
  if (response && response.success && response.data) {
    return response;
  }
};


/**
 * This method used to retrieve location by brand
 */
const getLocationsByBrand = async () => {
  const response = await httpApi.get("/v1/organization/brands/locations");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method used to retrieve Step Audit Data of a Job
 */
const getJobStepAuditByJobId = async (id) => {
  const response = await httpApi.get("/v1/batch/steps/logs", { params: { jobId: id } });
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};
/**
 * This method used to retrieve Step Execution Audit Data of a Job
 */
const getJobStepExAuditByJobId = async (jobId, stepId, page = 0, size = 5, searchTerm = "") => {
  const response = await httpApi.get("/v1/batch/logs", {
    params: {
      jobId,
      stepId,
      searchTerm,
      page,
      size,
    },
  });
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get All Batch Job Configurations
 *
 * @returns
 */
const getAllBatchJobConfigurations = async (data) => {
  const response = await httpApi.get("/v1/batch-job-config", data);
  if (response && response.data) {
    return response.data;
  }
};


export {
  getJobList,
  getJobHistoryById,
  getJobListStatus,
  getJobListScope,
  getJobHistoryRunTimes,
  getJobHistoryStatuses,
  getJobTaskTypes,
  getJobTaskTemplateDataByType,
  getLocationListByScope,
  getJobDetails,
  getJobTask,
  getLocationsByBrand,
  getJobHistory,
  getJobStepAuditByJobId,
  getJobStepExAuditByJobId,
  getAllBatchJobConfigurations,
};
