import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import styles from "../css/ViewCategory.module.css";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import Home from "../../../common/assets/images/svg/home.svg";
import LeftArrow from "../../../common/assets/images/svg/leftArrow.svg";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownItem from "../../../common/core/DropdownItem";
import Close from "../../../common/assets/images/svg/close-alt.svg";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Table from "../../../common/core/Table";
import Collapse from "../../../common/core/Collapse";

function ViewCategory({
  form,
  onHandleCancel,
  editCategory,
  productData,
  onPageChange,
  facet,
  page,
  handleItemsPerPageChange,
  itemPerPage,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  /**
 *This method is used to toogle dropdown
 *
 */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation(["common", "category", "product"]);
  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };
  const getBanerImage = (bannerImage) => {
    let imageUrl = null;
    if (Array.isArray(bannerImage) && bannerImage[0]) {
      imageUrl = bannerImage[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };


  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText"><strong>{t("common:label_category")}</strong></span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/dashboard">
                <img src={Home} alt="Home" />
                {t("common:home")}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem><a href="/merchandising">{t("common:merchandising")}</a></BreadcrumbItem>
            <BreadcrumbItem><a href="/merchandising/category">{t("common:catalog_Management")}</a></BreadcrumbItem>
            <BreadcrumbItem active>{t("common:label_category")}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <Row className="justify-content-between align-items-center mb-4">
            <Col xs="auto">
              <h5 className="mb-0 d-flex align-items-center">
                <Button color="transparent" className="btn-square mr-3">
                  <img
                    src={LeftArrow}
                    alt="Home"
                    onClick={onHandleCancel}
                  />
                </Button>
                <strong>{form.id}</strong>
              </h5>
            </Col>


          </Row>

          <Row>
            <Col xs="12" className="tab-item active">
              <div className="tab-btn" onClick={toggle}>{t("category:title_category_Details")}</div>
              <Collapse isOpen>
                <div className="tab-content">
                  <Row className={styles.ContentOuter}>
                    <Col>

                      <Row>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_category_id")}</strong></h6>
                          <p>{form.id}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_category_name")}</strong></h6>
                          <p>{form.name}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_short_Description")}</strong></h6>
                          <p>{form.description}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_long_Description")}</strong></h6>
                          <p>{form.longDescription}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("common:label_startDate")}</strong></h6>
                          <p>{form.startDate}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("common:label_endDate")}</strong></h6>
                          <p>{form.endDate}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("common:label_type")}</strong></h6>
                          <p>{form.type}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_parent_Category")}</strong></h6>
                          <p>Root Category</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("common:label_status")}</strong></h6>
                          <p>Active</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_position")}</strong></h6>
                          <p>{form.navPosition}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_show_in_Site_Navigation")}</strong></h6>
                          <p>{form.navigable ? "True" : "False"}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_sorting_Rules")}</strong></h6>
                          <p>{form.selectedSort}</p>
                        </Col>
                        <Col xs="6" className="mb-2">
                          <h6><strong>{t("category:label_image_Library")}</strong></h6>
                        </Col>
                      </Row>

                    </Col>
                    {/* <Col xs="auto" className="tab-icon-col">
                      <Button
                        color="transparent"
                        className="btn-circle"
                        onClick={(o) => editCategory(form.id, o)}
                        onKeyDown={() => { }}
                      >
                        <img src={Edit} alt="Home" />
                      </Button>
                    </Col> */}
                  </Row>


                  {/* <Row className="mt-2 mb-4">
                    <Col className="text-center">
                      <Button className="show-more">Show More</Button>
                    </Col>
                  </Row> */}

                  {/* <Row className="mt-3">
                    <Col xs="12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th width="33.3333%">File Name</th>
                            <th width="33.3333%">Image Category</th>
                            <th width="33.3333%">Image</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Plus-one.png</td>
                            <td>Banner Image</td>
                            <td>
                              {/* <img src="//via.placeholder.com/800x450" width="200" alt="" /> */}
                  {/* <img
                                src={getBanerImage(form.bannerImage)}
                                alt={form.name}
                                width="200"
                                className={clsx(styles.productImage, "img-fluid")}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Plus-two.png</td>
                            <td>Banner Image</td>
                            <td>
                              <img src="//via.placeholder.com/800x450" width="200" alt="" />
                            </td>
                          </tr>
                          <tr>
                            <td>Plus-three.png</td>
                            <td>Banner Image</td>
                            <td>
                              <img src="//via.placeholder.com/800x450" width="200" alt="" />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row> */}

                  {/* <hr className="mt-1 mb-4" /> */}

                  {/* <Row>
                    <Col xs="12">
                      <h6><strong>Search Facet</strong></h6>
                      <ul className="tag-outer my-3">
                        <li className="tag-list">
                          <div className="tag-item">{facet.field}</div>
                        </li>
                        <li className="tag-list">
                          <div className="tag-item">Category (L1)</div>
                        </li>
                        <li className="tag-list">
                          <Input type="text" value="Category (L1)" size="myInput.value.length" className="tag-item" />
                          <Button color="transparent" className={clsx(styles.BtnClose, "btn-close")}>
                            <img src={Close} alt="Home" />
                          </Button>
                        </li>
                        <li className="tag-list">
                          <div className="tag-item">Category (L1)</div>
                        </li>
                        <li className="tag-list">
                          <div className="tag-item">Category (L1)</div>
                        </li>
                      </ul>
                    </Col>
                  </Row> */}
                </div>
              </Collapse>
            </Col>

            <Col xs="12" className="tab-item">
              <div className="tab-btn" onClick={toggle}>Products</div>
              <Collapse isOpen={isOpen}>
                <div className="tab-content">
                  <Row className="justify-content-between align-items-center mb-3">
                    <Col xs="auto"><h6 className="mb-0"><strong>Product List</strong></h6></Col>
                    {/* <Col xs="auto">
                      <Button color="transparent" className="btn-circle">
                        <img src={Edit} alt="Home" />
                      </Button>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col xs="12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th width="15%" />
                            <th>{t("product:label_id")}</th>
                            <th>{t("product:label_external_id")}</th>
                            <th>{t("common:label_name")}</th>
                            <th>{t("product:label_product_Rank")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(productData.content) && productData.content.length > 0 ? (
                            productData.content.map(({
                              medias, uniqueId, id, name, rank = "1",
                            }) => (
                              <tr key={id} className={styles.productTreeItem}>
                                <td className="p-1 align-middle text-center">
                                  <img className="img-fluid img-thumbnail" src={getPreviewImage(medias)} alt="" />
                                </td>
                                <td>{id}</td>
                                <td>{uniqueId}</td>
                                <td>
                                  <span
                                    title={name}
                                    className={clsx(
                                      styles.productName,
                                      "text-truncate",
                                      "d-block",
                                    )}
                                  >
                                    {name}
                                  </span>
                                </td>
                                <td>{rank}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} align="center" valign="center">
                                {" "}
                                Nothing to show.
                              </td>
                            </tr>
                          )}

                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  {Array.isArray(productData.content) && productData.content.length > 0 ? (
                    <Row className="justify-content-between">
                      <Col xs="auto" className="d-flex align-items-center">
                        Show
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggleDropdown}
                          className="dropdown-custom dropdown-number mx-2"
                        >
                          <DropdownToggle caret color="light" size="sm">
                            {itemPerPage}
                          </DropdownToggle>
                          <DropdownMenu down>
                            <DropdownItem
                              onClick={() => handleItemsPerPageChange(10)}
                              onKeyPress={() => { }}
                            >
                              10

                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleItemsPerPageChange(15)}
                              onKeyPress={() => { }}
                            >
                              15

                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleItemsPerPageChange(20)}
                              onKeyPress={() => { }}
                            >
                              20

                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        entries
                      </Col>
                      <Col xs="auto">
                        <ReactPaginate
                          previousLabel="previous"
                          nextLabel="next"
                          breakLabel="..."
                          breakClassName="break-me"
                          pageCount={productData.totalPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={onPageChange}
                          containerClassName="pagination"
                          pageClassName="page"
                          pageLinkClassName="pageLink"
                          activeClassName="activePage"
                          initialPage={0}
                        />
                      </Col>
                    </Row>
                  ) : ""}

                </div>
              </Collapse>
            </Col>

          </Row>
        </Col>
      </Row>

    </>
  );
}
ViewCategory.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  editCategory: PropTypes.func.isRequired,
  productData: PropTypes.objectOf(PropTypes.any).isRequired,
  page: PropTypes.objectOf(PropTypes.any).isRequired,
  onPageChange: PropTypes.func.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  itemPerPage: PropTypes.objectOf(PropTypes.any).isRequired,
  facet: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default ViewCategory;
