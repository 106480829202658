/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datetime";
import moment from "moment";
import PropTypes from "prop-types";

import Row from "../../../common/core/Row";
import leftArrowLong from "../../../common/assets/images/svg/left-arrow-long.svg";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import ButtonGroup from "../../../common/core/ButtonGroup";

import styles from "../css/JobConfiguration.module.css";

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const JobDetails = ({
  jobBasicConfigData,
  handleEdit,
  canModify,
  onHandleBack,
}) => {
  const { t } = useTranslation(["common", "job-configuration"]);
  //   const alertStatus = Array.isArray(jobBasicConfigData.alertStatus)
  //     ? jobBasicConfigData.alertStatus.join()
  //     : "";
  //   const jobScope = Array.isArray(jobBasicConfigData.jobScope)
  //     ? jobBasicConfigData.jobScope.join()
  //     : "";
  //   const jobLocation = Array.isArray(jobBasicConfigData.jobLocation)
  //     ? jobBasicConfigData.jobLocation.join()
  //     : "";
  return (
    <Row className={clsx(styles.block1, "common-page-card ")}>
      <Col xs="12">
        {canModify ? (
          <Row>
            <Col xs="12">
              <span className={clsx(styles.leftArrow, "mr-2")}>
                <img
                  src={leftArrowLong}
                  alt="click left"
                  width="30"
                  height="auto"
                  onClick={() => onHandleBack(null)}
                />
              </span>
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("job-configuration:span_job_details")}
              </span>
            </Col>
          </Row>
        ) : (
            ""
          )}
        <Row>
          <Col xs="6">
            <FormGroup>
              <Label className="formTextLabel">
                {t("job-configuration:label_name")}
              </Label>
              <Input
                className="formText"
                type="text"
                placeholder=""
                name="name"
                value={jobBasicConfigData.name}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="3">
            <FormGroup>
              <Label className="formTextLabel">
                {t("job-configuration:label_job_scope")}
              </Label>
              <Input
                className="formText"
                type="text"
                placeholder=""
                name="scope"
                value={jobBasicConfigData.jobScope}
                disabled
              />
            </FormGroup>
          </Col>
          <Col xs="3">
            <FormGroup>
              <Label className="formTextLabel">
                {t("job-configuration:label_job_locations")}
              </Label>
              <Input
                className="formText"
                type="text"
                placeholder=""
                name="scope"
                value={jobBasicConfigData.locations}
                disabled
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label className="formTextLabel">
                {t("common:label_description")}
              </Label>
              <Input
                className="formText"
                placeholder=""
                type="textarea"
                name="description"
                value={jobBasicConfigData.description}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="5" sm="6" md="7">
            <Label className={styles.sectionHead}>
              {t("job-configuration:label_job_frequency")}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col xs="5" sm="6" md="7">
            <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
              <Input
                className="formText"
                placeholder=""
                type="text"
                name="recurring"
                value={jobBasicConfigData.recurring}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        {jobBasicConfigData.recurring === "RECURRING" ? (
          <>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("job-configuration:label_from_date")}
                  </Label>
                  <DatePicker
                    id="fromDate"
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    closeOnSelect
                    input
                    inputProps={{
                      placeholder: t("common:placeholder_date_example"),
                      name: "startDate",
                      disabled: true,
                    }}
                    value={
                      jobBasicConfigData.frequencyFromDate
                        ? moment(jobBasicConfigData.frequencyFromDate).format(
                          "DD/MM/YYYY"
                        )
                        : null
                    }
                  />
                </FormGroup>
              </Col>
              {!jobBasicConfigData.noEndDate ? (
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("job-configuration:label_to_date")}
                    </Label>
                    <DatePicker
                      id="toDate"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                      input
                      inputProps={{
                        placeholder: t("common:placeholder_date_example"),
                        name: "toDate",
                        disabled: true,
                      }}
                      value={
                        jobBasicConfigData.frequencyEndDate
                          ? moment(jobBasicConfigData.frequencyEndDate).format(
                            "DD/MM/YYYY"
                          )
                          : null
                      }
                    />
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col xs="7">
                <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                  <Label
                    title={t("job-configuration:label_recurring_job")}
                    className="formTextLabel text-truncate"
                  >
                    {t("job-configuration:label_no_end_date")}
                  </Label>
                  <div
                    className={clsx("custom-control", "custom-switch", "ml-1")}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="noEndDate"
                      name="noEndDate"
                      checked={jobBasicConfigData.noEndDate}
                      disabled
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="noEndDate"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-4">
                <ButtonGroup>
                  {weekdays.map((day) => (
                    <Button
                      color={
                        jobBasicConfigData.selectedWeekDays.includes(day)
                          ? "primary"
                          : "secondary"
                      }
                      disabled
                    >
                      {day}
                    </Button>
                  ))}
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("job-configuration:label_start_type")}
                  </Label>
                  <Input
                    className="formText"
                    placeholder=""
                    type="text"
                    name="startType"
                    value={jobBasicConfigData.startType}
                    disabled
                  />
                </FormGroup>
              </Col>
              {jobBasicConfigData.startType === "PARTICULAR_TIME" ? (
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("job-configuration:label_start_time")}
                    </Label>
                    <DatePicker
                      id="startTime"
                      dateFormat={false}
                      closeOnSelect
                      input
                      inputProps={{
                        placeholder: t("common:placeholder_date_example"),
                        name: "startTime",
                        disabled: true,
                      }}
                      value={
                        jobBasicConfigData.startTime
                          ? moment(jobBasicConfigData.startTime).format(
                            "hh:mm:ss"
                          )
                          : null
                      }
                    />
                  </FormGroup>
                </Col>
              ) : (
                  <Col xs="6">
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("job-configuration:label_interval")}
                          </Label>
                          <Input
                            className="formText"
                            type="text"
                            placeholder=""
                            name="intervalTime"
                            value={jobBasicConfigData.intervalTime}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("job-configuration:label_unit")}
                          </Label>
                          <Input
                            className="formText"
                            placeholder=""
                            type="text"
                            name="intervalTimeUnit"
                            value={jobBasicConfigData.intervalTimeUnit}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                )}
            </Row>
          </>
        ) : (
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("job-configuration:label_date")}
                  </Label>
                  <DatePicker
                    id="date"
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    closeOnSelect
                    input
                    inputProps={{
                      placeholder: t("common:placeholder_date_example"),
                      name: "date",
                      disabled: true,
                    }}
                    value={
                      jobBasicConfigData.date
                        ? moment(jobBasicConfigData.date).format("DD/MM/YYYY")
                        : null
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        <hr />
        <Row>
          <Col xs="12">
            <Label className={styles.sectionHead}>
              {t("job-configuration:label_job_error_handling_title")}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                {t("job-configuration:label_handling_type")}
              </Label>
              <Input
                className="formText"
                placeholder=""
                type="text"
                name="errorHandlingType"
                value={jobBasicConfigData.errorHandlingType}
                disabled
              />
            </FormGroup>
          </Col>
          {jobBasicConfigData.errorHandlingType === "RETRY_JOB" ? (
            <Col xs="2">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_retry_count")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  type="number"
                  name="retryCount"
                  value={jobBasicConfigData.retryCount}
                  min="0"
                  disabled
                />
              </FormGroup>
            </Col>
          ) : null}
        </Row>
        <hr />
        <Row>
          <Col xs="12">
            <Label className={styles.sectionHead}>
              {t("job-configuration:label_job_notification_title")}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <FormGroup>
              <Label className="formTextLabel">
                {t("job-configuration:label_job_notification_type")}
              </Label>
              <Input
                className="formText"
                placeholder=""
                type="text"
                name="notificationChannel"
                value={t(
                  `job-configuration:option_${jobBasicConfigData.notificationChannel}`
                )}
                disabled
              />
            </FormGroup>
          </Col>
          <Col xs="3">
            <FormGroup>
              <Label className="formTextLabel">
                {t("job-configuration:label_alert_status")}
              </Label>
              <Input
                className="formText"
                placeholder=""
                type="text"
                name="notificationChannel"
                value={jobBasicConfigData.alertStatus}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        {jobBasicConfigData.notificationChannel === "EMAIL" ? (
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_email_to")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  type="text"
                  name="notificationEmailTo"
                  value={jobBasicConfigData.notificationEmailTo}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_email_cc")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  type="text"
                  name="notificationEmailCC"
                  value={jobBasicConfigData.notificationEmailCC}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {jobBasicConfigData.notificationChannel === "SMS" ? (
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_sms_to")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  type="text"
                  name="notificationSMSTo"
                  value={jobBasicConfigData.notificationSMSTo}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {canModify ? (
          <Row>
            <Col>
              <div className={styles.areaActionRight}>
                <div>
                  <Button
                    color="primary"
                    className={clsx(
                      styles.borderRadius2,
                      "text-uppercase",
                      "text-truncate",
                      "btn-tertiary",
                      "ml-1"
                    )}
                    onClick={handleEdit}
                  >
                    {t("job-configuration:button_edit_job")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
            ""
          )}
      </Col>
    </Row>
  );
};

JobDetails.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  jobBasicConfigData: PropTypes.objectOf(PropTypes.any).isRequired,
  canModify: PropTypes.bool.isRequired,
};

export default JobDetails;
