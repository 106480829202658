/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

/** ========= TAG COMPONENTS ========= */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Label from "../../../common/core/Label";
import Spinner from "../../../common/core/Spinner";
import ListGroup from "../../../common/core/ListGroup";
import FormGroup from "../../../common/core/FormGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";
import Alert from "../../../common/core/Alert";

/** ========= SUB COMPONENT ========= */
import MainMenuItem from "../containers/MainMenuItemContainer";
import History from "./History";

/** ========= SVG IMAGE ICON ========= */
import PlusIcon from "../../../common/assets/images/svg/add-24px.svg";

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/Select";

/** ========= MODULE STYLES ========= */
import styles from "../css/MenuDetails.module.css";

const MenuDetails = (props) => {
  const {
    id, name, menus, subMenus, loading, position, mainMenu, hasError,
    addMenu, editMenu, deleteMenu, handleRootMenuChange, onDragEnd, updateColumns, handleMenuItemStatus, updateFromHistory, removeError,
    getLocalKey, maxMenuItems,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  return (
    <>
      <Row className="pageHeader common-page-card ">
        <Col xs="12">
          <span className="pageText">{t("site-navigation:title_sub_edit")}</span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Alert color="danger" isOpen={hasError} toggle={removeError}>
        Something went wrong, try again!
      </Alert>
      <Row className={clsx(styles.block1, " common-page-card ")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="12" sm="10" md="6" lg="4" className="p-0 d-flex">
              <FormGroup className="w-100 d-flex flex-align-center">
                <div className="d-flex align-items-center pr-3">
                  <Label className="formTextLabel text-truncate">{t("site-navigation:label_name")}</Label>
                </div>
                <div className="flex-grow-1">
                  <Select
                    value={id}
                    name="mainMenu"
                    id="add-menu-main"
                    options={[
                      { disabled: true, value: "", label: "Select menu" },
                      ...(Array.isArray(menus) && menus.map(
                        ({ name: menuName, id: menuId }) => ({ label: menuName, value: menuId }),
                      )
                      ),
                    ]}
                    onChange={handleRootMenuChange}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.blockWrapper, " common-page-card ", "position-relative")}>
        <Col xs="12">
          {
            loading && (
              <div className={clsx(styles.loadingOverlay, "position-absolute", "h-100", "d-flex", "align-items-center", "justify-content-center")}>
                <div>
                  <Spinner />
                </div>
              </div>
            )
          }
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              {/* ============== MENU LISTING  S=================== */}
              <ListGroup className={styles.dragContainer}>
                {/* ============== TITLE FOR MENU  S=================== */}
                <ListGroupItem className="bg-light mb-1 border">
                  <span className="text-truncate font-weight-bold">
                    {t("site-navigation:label_menu_items")}
                  </span>
                </ListGroupItem>
                {/* ============== TITLE FOR MENU E=================== */}
                <Droppable droppableId="droppable" type="droppableItem">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {/* ============== EACH MAIN MENU ITEM S=================== */}
                      {
                        subMenus.map((menu, index) => (
                          <MainMenuItem
                            htmlId={`0${0}${index}`}
                            level={0}
                            index={index}
                            key={menu.id}
                            id={menu.id}
                            image={menu.image}
                            name={menu.name}
                            active={menu.active}
                            allowDropdown={menu.allowDropdown}
                            contents={menu.contents}
                            menus={menu.menus}
                            addMenu={addMenu}
                            editMenu={editMenu}
                            deleteMenu={deleteMenu}
                            getLocalKey={getLocalKey}
                            updateColumns={updateColumns}
                            handleMenuItemStatus={handleMenuItemStatus}
                            menuCount={subMenus.length}
                            position={position}
                          />
                        ))
                      }
                      {/* ============== EACH MAIN MENU ITEM E=================== ⚛️ */}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                {/* ============== ADD NEW MAIN MENU  S=================== */}
                {subMenus.length < maxMenuItems
                  ? (
                    <ListGroupItem tag="div" className="px-3 border-top commonPointer" onClick={(o) => addMenu({ id, level: 0 }, o)}>
                      <span className="d-flex align-items-center commonPointer">
                        <span className="px-1">
                          <img className={styles.icon} src={PlusIcon} alt="" />
                        </span>
                        <span className="pl-1 text-truncate font-weight-bold text-primary text-capitalize">
                          {t("site-navigation:label_add")}
                          <span className="px-1">{name}</span>
                          {
                            t("site-navigation:label_item")
                          }
                        </span>
                      </span>
                    </ListGroupItem>
                  ) : ""}
                {/* ============== ADD NEW MAIN MENU  E=================== */}
              </ListGroup>
              {/* ============== MENU LISTING  E=================== */}
            </DragDropContext>
          </div>
        </Col>
      </Row>

      <Row className={clsx(styles.block1, " common-page-card ")}>
        <Col xs="12">
          <History data={mainMenu} onUpdate={updateFromHistory} collection={position} />
        </Col>
      </Row>
    </>
  );
};

MenuDetails.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  menus: PropTypes.arrayOf(PropTypes.any).isRequired,
  subMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  mainMenu: PropTypes.objectOf(PropTypes.mainMenu).isRequired,
  hasError: PropTypes.bool.isRequired,
  addMenu: PropTypes.func.isRequired,
  editMenu: PropTypes.func.isRequired,
  deleteMenu: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  handleRootMenuChange: PropTypes.func.isRequired,
  getLocalKey: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateColumns: PropTypes.func.isRequired,
  handleMenuItemStatus: PropTypes.func.isRequired,
  updateFromHistory: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
};

export default MenuDetails;
