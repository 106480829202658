/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/b2bCustomer.module.css";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";
import Spinner from "../../../common/core/Spinner";

const ViewDepartment = ({
  onHandleCancel,
  department,
  users,
  salesReps,
  isLoading,
}) => {
  const { t } = useTranslation(["common", "b2bcustomer"]);
  const [isSalesRepresentativeOpen, SetisSalesRepresentativeOpen] = useState(false);
  const [isUsersOpen, SetisUsersOpen] = useState(false);
  const salesRepCustomRows = [
    {
      label: "Name",
      value: "",
      path: "fullName",
      transform: "",
    },
    {
      label: "Email",
      value: "",
      path: "email",
      transform: "",
    },
    {
      label: "Contact Number",
      value: "",
      path: "mobile",
      transform: "",
    },
  ];

  const userCustomRows = [
    {
      label: "Name",
      value: "",
      path: "users.name",
      transform: "",
    },
    {
      label: "Role",
      value: "",
      path: "users.role",
      transform: "",
    },
    {
      label: "Email",
      value: "",
      path: "users.email",
      transform: "",
    },
    {
      label: "Contact Number",
      value: "",
      path: "users.contactNumber",
      transform: "",
    },
  ];

  const toggleSalesrepresentative = () => SetisSalesRepresentativeOpen(!isSalesRepresentativeOpen);
  const toggleUsers = () => SetisUsersOpen(!isUsersOpen);

  return (
    <>
      <Form>
        <fieldset>
          <Row className="common-page-card pageHeader">
            <Col xs="12">
              <span className="pageText">{t("tax-configuration:B2B Account")}</span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "common-page-card ")}>
            <Col xs="12">
              <Row className="m-0 ">
                <Col xs="6" className="p-0">
                  <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>
                    B2B Account Details
                  </span>
                </Col>
                <Col className="mb-2" xs="6" sm>
                  <div className={styles.areaActionRight}>
                    <div>
                      <Button
                        color="secondary"
                        className={clsx(
                          styles.borderRadius2,
                          styles.btn,
                          styles.secondaryColor,
                          "text-uppercase",
                          "text-truncate",
                          "btn-tertiary",
                          "ml-1",
                        )}
                        onClick={onHandleCancel}
                      >
                        {t("b2bcustomer:back")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              {isLoading ? (
                <Row className="h-100">
                  <Col
                    xs="12"
                    className={styles.spinnerWrapper}
                  >
                    <div
                      className="h-100  d-flex align-items-center justify-content-center"
                    >
                      <Spinner />
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  <div className={styles.displayData}>
                    <ul className={styles.dataTableList}>
                      <li>
                        <div className={styles.dataTableTitle}>ID:</div>
                        <div className={styles.dataTableText}>{department.uniqueId}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Invoice Account:</div>
                        <div className={styles.dataTableText}>{department.invoiceAccount}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Name:</div>
                        <div className={styles.dataTableText}>{department.name}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Email:</div>
                        <div className={styles.dataTableText}>{department.email}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Discount%:</div>
                        <div className={styles.dataTableText}>{department.discount}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Price List:</div>
                        <div className={styles.dataTableText}>{department.priceList}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Classification:</div>
                        <div className={styles.dataTableText}>{department.classification}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Payment Method:</div>
                        <div className={styles.dataTableText}>{department.paymentMethods && department.paymentMethods.length > 0 ? department.paymentMethods[0] : ""}</div>
                      </li>
                      <li>
                        <div className={styles.dataTableTitle}>Address:</div>
                        {department.shippingAddress && department.shippingAddress.length > 0
                          && (
                            <div className={styles.dataTableText}>
                              {department.shippingAddress[0].addressLine1 || ""}
                              {" "}
                              <br />
                              {department.shippingAddress[0].city || ""}
                              {" "}
                              <br />
                              {department.shippingAddress[0].country || ""}
                              {" "}
                              <br />
                              {department.shippingAddress[0].postCode || ""}
                            </div>
                          )}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </Col>
          </Row>
          {salesReps && salesReps.length > 0 ? (
            <Row className={clsx(styles.block3, "pageHeader common-page-card")}>
              <Col xs="12">
                <h5>Sales Representatives</h5>
                <span className="float-right">
                  <span onClick={toggleSalesrepresentative}>
                    {isSalesRepresentativeOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      </svg>
                    )}
                  </span>
                </span>
                <div className="mt-3">
                  <span
                    onClick={(o) => o}
                    onKeyDown={() => { }}
                    tabIndex={0}
                    role="button"
                    className="mt-3"
                  />
                </div>
                {isSalesRepresentativeOpen && (
                  <div className={(styles.dataTableTitle, "mt-3")}>
                    <div className="custom-table">
                      <CustomizationTable
                        customRows={salesRepCustomRows}
                        customizationData={salesReps}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          ) : null}
          {users && users.length > 0 ? (
            <Row className={clsx(styles.block3, "pageHeader common-page-card")}>
              <Col xs="12">
                <h5>Users</h5>
                <span className="float-right">
                  <span onClick={toggleUsers}>
                    {isUsersOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      </svg>
                    )}
                  </span>
                </span>
                <div className="mt-3">
                  <span
                    onClick={(o) => o}
                    onKeyDown={() => { }}
                    tabIndex={0}
                    role="button"
                    className="mt-3"
                  />
                </div>
                {isUsersOpen && (
                  <div className={(styles.dataTableTitle, "mt-3")}>
                    <Table borderless>
                      <thead>
                        <tr>
                          {userCustomRows.map((row) => (
                            <th>{row.label}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {users && users.length > 0 ? (
                          users.map((item) => item && (
                            <tr>
                              <td>{item.fullName}</td>
                              <td>{item.roles}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} align="center" valign="center">
                              {" "}
                              {" "}
                              Nothing to show.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Col>
            </Row>
          ) : null}
        </fieldset>
      </Form>
    </>
  );
};
ViewDepartment.propTypes = {
  onHandleCancel: PropTypes.func.isRequired,
  department: PropTypes.objectOf(PropTypes.any).isRequired,
  users: PropTypes.objectOf(PropTypes.any).isRequired,
  salesReps: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ViewDepartment;
