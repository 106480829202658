/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Batch Job Audit Management Container
 *
 * @author Joice Tomy
 *
 */

import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import BatchJobAuditManagement from "../components/BatchJobAuditManagement";
import BatchStepAuditManagementContainer from "./BatchStepAuditManagementContainer";

/** ===== API SERVICE FUNCTIONS ========== */
import { getBatchJobAudit } from "../../../api/batchServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";


const BatchJobAuditManagementContainer = () => {
  /** state variables */
  const location = useLocation();
  const history = useHistory();
  const [jobAudits, setJobAudits] = useState([]);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [ListDropdownenopen, SetListDropdownenOpen] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState();
  const [rowData, setRowData] = useState([]);
  const jobType = [
    { label: "Price Job", value: "B2BGlobalPriceJob" },
    { label: "Inventory Job", value: "B2BGlobalInventoryJob" },
    { label: "Order Export Job", value: "b2bOrderExportBatchJob" },
    { label: "Order Email Job", value: "b2bOrderConfirmationEmailExportBatchJob" },
    { label: "Delta Build Job", value: "b2bDeltaBuildJob" },
    { label: "Product Index Job", value: "b2bProductIndexJob" },
  ];
  const jobStatus = [
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "STOPPED", value: "STOPPED" },
    { label: "FAILED", value: "FAILED" },
    { label: "STARTED", value: "STARTED" },
  ];

  const getBatchAuditList = (batchName, batchStatus, pageNo = 0, pageSize = 10, jobConfigId = "") => {
    setIsProcessing(true);
    const requestData = {
      name: batchName, status: batchStatus, page: pageNo, size: pageSize, jobConfigId,
    };
    getBatchJobAudit(requestData).then((response) => {
      setIsProcessing(false);
      if (response && response.success) {
        const { content, totalPages } = response.data;
        if (Array.isArray(content)) {
          setJobAudits(content);
          setTotalPagesCount(totalPages);
          if (location && location.state && location.state.jobConfigId) {
            setName(content[0].name);
            history.replace({ state: {} });
          }
        }
      }
    });
  };

  const onClickRow = (row) => {
    const { id } = row;
    setSelectedJobId(id);
    setRowData(row);
  };

  const onHandleBack = (id) => {
    setSelectedJobId(id);
  };

  const toggleListDropdown = () => {
    SetListDropdownenOpen(!ListDropdownenopen);
  };
  const onHandleStatusChange = (e) => {
    const { value } = e.target;
    setStatus(value);
    setPage(0);
    getBatchAuditList(name, value, 0, size);
  };

  const onHandleNameChange = (e) => {
    const { value } = e.target;
    setName(value);
    setPage(0);
    getBatchAuditList(value, status, 0, size);
  };

  const handleItemsPerPageChange = (value) => {
    setSize(value);
    getBatchAuditList(name, status, page, value);
  };

  const onPageChange = (data) => {
    const { selected } = data;
    setPage(selected);
    getBatchAuditList(name, status, selected, size);
  };


  useEffect(() => {
    if (location && location.state && location.state.jobConfigId) {
      const { jobConfigId } = location.state;
      getBatchAuditList("", "", 0, 10, jobConfigId);
    } else {
      getBatchAuditList();
    }
  }, []);

  if (selectedJobId) {
    return (
      <Permission
        allowed={Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_STEP_AUDIT_VIEW_LIST}
      >
        <BatchStepAuditManagementContainer
          selectedJobId={selectedJobId}
          rowData={rowData}
          onHandleBack={onHandleBack}
        />
      </Permission>
    );
  }
  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_AUDIT_VIEW_LIST}
    >
      <BatchJobAuditManagement
        jobAudits={jobAudits}
        onHandleStatusChange={onHandleStatusChange}
        onHandleNameChange={onHandleNameChange}
        ListDropdownenopen={ListDropdownenopen}
        toggleListDropdown={toggleListDropdown}
        itemsPerPage={size}
        handleItemsPerPageChange={handleItemsPerPageChange}
        totalPagesCount={totalPagesCount}
        onPageChange={onPageChange}
        isProcessing={isProcessing}
        onClickRow={onClickRow}
        jobName={name}
        jobType={jobType}
        jobStatus={jobStatus}
      />
    </Permission>
  );
};

export default BatchJobAuditManagementContainer;
