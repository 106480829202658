/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";

/** ====== COMMON COMPONENTS ============= */
import CustomizationTable from "../../../common/components/Table";

/** ===== IMAGE ICONS =========== */
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import LoaderIcon from "../../../common/assets/images/svg/loader.svg";
/** ================== MODULE STYLES ================ */
import styles from "../css/CustomerDataManagement.module.css";
import Button from "../../../common/core/Button";
import Spinner from "../../../common/core/Spinner";

const CustomerDataManagement = ({
  customers,
  page,
  hasMoreData,
  searchTerm,
  getPageData,
  handleSearchTerm,
  onHandleExport,
  isProccessing,
  handleResetPassword,
  loading,
}) => {
  const { t } = useTranslation(["customers"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  /**
   *This method is used to toogle dropdown
   *
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const customRows = [
    {
      label: "Unique Id",
      value: "",
      path: "uniqueId",
      transform: "",
    },
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Email",
      value: "",
      path: "email",
      transform: "",
    },
    {
      label: "Phone No",
      value: "",
      path: "render",
      render: (row) => {
        return `${row.dialCode || ""}${row.mobile || ""}`;
      },
    },
    {
      label: "Reset Password",
      path: "render",
      render: (row) => (
        <div>
          <Button
            size="sm"
            color="primary"
            onClick={() => handleResetPassword(row)}
            block
            className="text-white"
            disabled={Array.isArray(loading) && loading.includes(row.id)}
          >
            {Array.isArray(loading) && loading.includes(row.id) ? (
              <Spinner size="sm" />
            ) : (
                <span>{t("customers:resetPassword_button")}</span>
              )}
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7" className="text-truncate">
              <span className="pageText">Customer</span>
            </Col>
          </Row>
        </Col>
        <Col xs="12">
          <Row className={clsx(styles.block2, "common-page-card")}>
            <Col className="mb-2" xs="12" sm="12" md="4">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                Manage Customer
              </span>
            </Col>
            <Col className="mb-2" xs="12" sm>
              <div className={styles.areaActionRight}>
                <div>
                  <InputGroup
                    className={clsx(styles.search, "color-tertiary-main")}
                  >
                    <Input
                      placeholder="Search customer"
                      value={searchTerm}
                      onChange={handleSearchTerm}
                    />
                    <InputGroupAddon
                      addonType="append"
                      className="commonPointer bg-color-tertiary-main"
                    >
                      <img
                        src={SearchIcon}
                        alt="search customer"
                        className={styles.icon}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                {/* <div>
                  <Button
                    className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary")}
                  >
                    Add Customer
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <Row className={clsx(styles.block2, "pb-2")}>
                <Col xs="12">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className={clsx(
                      styles.dropdownBtn,
                      "commonDropDownWrapper",
                    )}
                  >
                    <DropdownToggle caret disabled={isProccessing || customers.length < 1}>
                      <span>{t("common:span_export_as")}</span>
                      {isProccessing ? <img
                        src={LoaderIcon}
                        width="22"
                        alt="Inventory file downloading"
                        className={styles.icon}
                      /> : false}
                    </DropdownToggle>
                    {!isProccessing ?
                      <DropdownMenu right className="dropdownMenuWrapper">
                        <div
                          onKeyPress={() => { }}
                          role="button"
                          tabIndex="0"
                          className="dropdownMenuItem"
                          onClick={() => onHandleExport("XLSX")}
                        >
                          {t("common:div_export_format_XLSX")}
                        </div>
                        <div
                          onKeyPress={() => { }}
                          role="button"
                          tabIndex="0"
                          className="dropdownMenuItem"
                          onClick={() => onHandleExport("CSV")}
                        >
                          {t("common:div_item_export_format_CSV")}
                        </div>
                      </DropdownMenu>
                      : null}
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="custom-table">
                    <CustomizationTable
                      customRows={customRows}
                      customizationData={customers}
                      isStatusEnable={false}
                      isDeleteEnable={false}
                      isEditEnable={false}
                    />
                  </div>
                  <div
                    className={clsx(
                      styles.pagination,
                      "p-3",
                      "d-flex",
                      "justify-content-end",
                    )}
                  >
                    <div className="d-flex">
                      <span
                        disabled={page <= 1}
                        className={clsx(styles.item, styles.arrowLeft)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => !(page <= 1) && getPageData(-1, o)}
                      >
                        <span />
                      </span>
                      <span className={styles.item} />
                      <span
                        disabled={!hasMoreData}
                        className={clsx(styles.item, styles.arrowRight)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => hasMoreData && getPageData(1, o)}
                      >
                        <span />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

CustomerDataManagement.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  isProccessing: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  onHandleExport: PropTypes.func.isRequired,
  handleResetPassword: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
};

export default CustomerDataManagement;
