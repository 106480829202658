/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Image Cropper componet
 *
 * @author Naseef O
 *
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import { useEffect } from "react";
import Modal from "../../core/Modal";
import ModalBody from "../../core/ModalBody";
import ModalFooter from "../../core/ModalFooter";
import imageUtils from "../../utils/imageUtils";
import Spinner from "../../core/Spinner";
import CustomInput from "../../core/CustomInput";

const ImageCropper = (props) => {
  const {
    modalOpen,
    setModalOpen,
    imageUrl,
    isUploading,
    setIsUploading,
    handleUpload,
    aspect,
    imageUploadApi,
    handleCloseModal,
  } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [newCroppedAreaPixels, setCroppedAreaPixels] = useState(1);
  const [editState, setEditState] = useState(null);
  const [aspectCheckStatus, setAspectCheckStatus] = useState(0); // 0 for checking -1 failed, 1 for success

  const getOptions = () => ({
    CROP: "CROP",
    ORIGINAL: "ORIGINAL",
  });

  useEffect(() => {
    const aspectFixValue = 2;
    if (imageUrl) {
      const i = new Image();

      i.onload = () => {
        const localAspectRatio = (Number(i.width) / Number(i.height));
        if (Number(aspect).toFixed(aspectFixValue) === localAspectRatio.toFixed(aspectFixValue)) {
          setAspectCheckStatus(1 * localAspectRatio);
          setEditState(getOptions().ORIGINAL);
        } else {
          setEditState(getOptions().CROP);
          setAspectCheckStatus(-1 * localAspectRatio);
        }
      };
      i.src = imageUrl;
    }
  }, [imageUrl, getOptions, aspect]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const isReady = () => aspectCheckStatus !== 0;

  /**
   * This method is used to handle crop image and upload to storage
   */
  const handleCropImage = async () => {
    setIsUploading(true);
    if (imageUrl) {
      let croppedImage = imageUrl;

      if (editState === getOptions().CROP && newCroppedAreaPixels) {
        croppedImage = await imageUtils.getCroppedImg(imageUrl, newCroppedAreaPixels);
      }

      const imageFile = imageUtils.dataURLtoFile(croppedImage, "Moz-com");
      await imageUploadApi(imageFile).then((response) => {
        if (response && response.success) {
          handleUpload(response.data);
          setModalOpen(false);
          setIsUploading(false);
        } else {
          setModalOpen(false);
          handleUpload(null);
          alert("Uploading failed!");
        }
      });
    }
  };


  const showCropper = () => {
    setEditState(getOptions().CROP);
  };
  const useOriginalImage = () => {
    setEditState(getOptions().ORIGINAL);
  };


  const getCropOptions = (selectedOption) => {
    let component = null;
    switch (selectedOption) {
      case getOptions().CROP:
        component = getCropView;
        break;
      case getOptions().ORIGINAL:
        component = getInputPreviewer;
        break;
      default:
        component = getInputPreviewer;
    }
    return component;
  };

  const getImageViewer = (src) => (
    <div>
      <img alt="" src={src} className="img-fluid" />
    </div>
  );

  const getInputPreviewer = () => (
    <div className="p-3">
      {
        aspectCheckStatus !== 0 && (
          <div className="d-block py-1 mb-1">
            { aspectCheckStatus < 0 && (
              <div className=" d-flex align-items-center text-danger ">
                <p className=" m-0">
                  <span className="text-success font-weight-bold">{`Preferred aspect ratio ${Number(aspect).toFixed(2)}`}</span>
                </p>
              </div>
            )}
            <div>
              <p className="mb-1">
                <span className="font-italic font-weight-bold">
                  Uploaded image aspect ratio
                  <span className="pl-1 text-danger">{Number(-1 * aspectCheckStatus).toFixed(2)}</span>
                </span>
              </p>
            </div>
          </div>
        )
      }
      {getImageViewer(imageUrl)}
    </div>
  );

  const getFooter = () => (
    <ModalFooter>
      <button type="button" disabled={isUploading || !isReady()} className="secondaryButton" onClick={() => handleCloseModal(false)}>Cancel</button>
      <button type="button" disabled={isUploading || !editState || !isReady()} className="primaryButton" onClick={() => editState && isReady() && handleCropImage()}>
        {" "}
        {isUploading ? <Spinner /> : "Confirm"}
      </button>
    </ModalFooter>
  );

  const getCropView = () => (
    <ModalBody>
      <div className="image-cropper-wrapper">
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </ModalBody>
  );

  const getHeader = () => (
    <div className="justify-content-start  modal-header">
      <span className="pr-1">
        <button
          type="button"
          disabled={isUploading}
          className="btn btn-info text-nowrap d-flex align-items-center"
          onClick={showCropper}
        >
          <CustomInput type="checkbox" id="dr1" checked={editState === getOptions().CROP} readOnly />
          Crop Image
        </button>
      </span>
      <span className="">
        <button
          type="button"
          disabled={isUploading}
          className="btn btn-info text-nowrap d-flex align-items-center"
          onClick={useOriginalImage}
        >
          <CustomInput type="checkbox" id="dr3" checked={editState === getOptions().ORIGINAL} readOnly />
          Original image
        </button>
      </span>
    </div>
  );

  return (
    <>
      <Modal isOpen={modalOpen}>
        {getHeader()}
        {getCropOptions(editState)()}
        {getFooter()}
      </Modal>
    </>
  );
};

ImageCropper.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired,
  imageUploadApi: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  setIsUploading: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  aspect: PropTypes.number.isRequired,
};

export default ImageCropper;
