/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import AngleDown from "../../../common/assets/images/svg/angle-arrow-down.svg";
import leftArrowLong from "../../../common/assets/images/svg/left-arrow-long.svg";
import Col from "../../../common/core/Col";
import Collapse from "../../../common/core/Collapse";
import FormGroup from "../../../common/core/FormGroup";
import Row from "../../../common/core/Row";
import Table from "../../../common/core/Table";
import commonUtils from "../../../common/utils/commonUtils";
import styles from "../css/OrderDetails.module.css";

const OrderDetails = ({
  order, onHandleBack, collapsesOpen, toggleCollapse,
}) => {
  const { t } = useTranslation(["common", "order-details"]);

  const { shippingAddress } = order && Array.isArray(order.shipments) && order.shipments.length > 0 ? order.shipments[0] : "";
  const { shipments } = order;
  const {
    contactPerson: shippingAddressContactPerson,
    addressLine1: shippingAddressAddressLine1,
    addressLine2: shippingAddressAddressLine2,
    city: shippingAddressCity,
    district: shippingAddressDistrict,
    state: shippingAddressState,
    email: shippingAddressEmail,
    telephone1: shippingAddressTelephone1,
    country: shippingAddressCountry,
    postCode: shippingAddressPostCode,
    dialCode: shippingAddressDialCode,
  } = shippingAddress || "";

  const getTotalQuantity = (lineItems) => {
    if (commonUtils.isListNotEmpty(lineItems)) {
      return lineItems.reduce((total, lineItem) => total + parseInt(lineItem?.quantity, 10), 0);
    }
    return 0;
  }

  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className={clsx(styles.leftArrow, "mr-2")}>
            <img
              src={leftArrowLong}
              alt="click left"
              width="30"
              height="auto"
              onClick={() => onHandleBack(null)}
            />
          </span>
          <span className="pageText">
            {t("order-details:order_details_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Row className="m-0">
            <Col xs="6" className="p-2">
              <Row>
                <Col md="12" className="p-2">
                  <span
                    className={clsx(
                      styles.pageSelectionText,
                      "fontsizeMediumBold",
                    )}
                  >
                    {t("order-details:span_order_summary")}
                  </span>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_order_number")}
                    </p>
                    <p>{order?.orderNo || "---"}</p>
                  </FormGroup>
                </Col>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_order_date")}
                    </p>
                    <p>{order?.orderDate || "---"}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_order_status")}
                    </p>
                    <p>{order?.orderExportedToOms ? "Exported" : order?.status}</p>
                  </FormGroup>
                </Col>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_order_amount")}
                    </p>
                    <p>
                      {order?.currency}
                      {" "}
                      {order?.priceInfo ? order?.priceInfo?.grossPrice : ""}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_sales_company")}
                    </p>
                    <p>{order?.salesCompany || "--"}</p>
                  </FormGroup>
                </Col>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_order_type")}
                    </p>
                    <p>
                      {order?.type || "--"}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_location")}
                    </p>
                    <p>{order?.location || "--"}</p>
                  </FormGroup>
                </Col>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_catalog_type")}
                    </p>
                    <p>
                      {order?.catalogType || "--"}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col xs="6" className="p-2">
              <Row>
                <Col md="12" className="p-2">
                  <span
                    className={clsx(
                      styles.pageSelectionText,
                      "fontsizeMediumBold",
                    )}
                  >
                    {t("order-details:span_account_information")}
                  </span>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_customer_name")}
                    </p>
                    <p>{order?.submittedName || "--"}</p>
                  </FormGroup>
                </Col>           
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_customer_account_no")}
                    </p>
                    <p>{order?.customerAccount || "--"}</p>
                  </FormGroup>
                </Col>
             
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_invoice_account_no")}
                    </p>
                    <p>{order?.invoiceAccount || "--"}</p>
                  </FormGroup>
                </Col>
             
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_email")}
                    </p>
                    <p>{order?.email || "---"}</p>
                  </FormGroup>
                </Col>
                <Col md="6" className="p-2">
                  <FormGroup>
                    <p className="font-weight-bold">
                      {t("order-details:p_user_id")}
                    </p>
                    <p>{order?.user || "--"}</p>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs="12" className="p-2">
              <Row>
                <Col md="12" className="p-2">
                  <span
                    className={clsx(
                      styles.pageSelectionText,
                      "fontsizeMediumBold",
                    )}
                  >
                    {t("order-details:span_address_information")}
                  </span>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="p-2">
                  <p className="font-weight-bold">
                    {t("order-details:p_shipping_address")}
                  </p>
                  <p>{shippingAddressContactPerson}</p>
                  <p>{shippingAddressAddressLine1}</p>
                  <p>{shippingAddressAddressLine2}</p>
                  <p>
                    {shippingAddressCity}
                    {" "}
                    {shippingAddressDistrict}
                    {" "}
                    {shippingAddressState}
                  </p>
                  <p>
                    {shippingAddressCountry}
                    ,
                    {shippingAddressPostCode}
                  </p>
                  <p>{shippingAddressEmail}</p>
                  <p>{`${shippingAddressDialCode || ""} ${shippingAddressTelephone1 || ""}`}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs="12" className="p-2">
              <Row>
                <Col md="12" className="p-2">
                  <span
                    className={clsx(
                      styles.pageSelectionText,
                      "fontsizeMediumBold",
                    )}
                  >
                    {t("order-details:p_shipment_total")}
                  </span>
                  <hr />
                </Col>
              </Row>
              <Row>
                {shipments && shipments.map((shipment) => (
                  <>
                    {getTotalQuantity(shipment?.lineItems) > 0 &&
                      < Col md="6" className="mb-3" >
                        <Table borderless className={styles.productTable}>
                          <>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_id")}
                              </td>
                              <td>
                                {`: ${shipment?.id}`}
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_order_no")}
                              </td>
                              <td>
                                {`: ${shipment?.orderNo}`}
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_shipment_order_status")}
                              </td>
                              <td>
                                <span className={`${shipment?.orderExportedToOms ? "text-success" : "text-danger"}`}>{shipment?.orderExportedToOms ? ": Exported" : ": Submitted"}</span>
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_shipment_email_status")}
                              </td>
                              <td>
                                <span className={`${shipment?.orderConfirmationEmailSent ? "text-success" : "text-danger"}`}> {shipment?.orderConfirmationEmailSent ? ": Successful" : ": Waiting"}</span>
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_shipment_brand")}
                              </td>
                              <td>
                                {`: ${shipment?.brand}`}
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_external_order_no")}
                              </td>
                              <td>
                                {`: ${shipment?.externalOrderNo ? shipment?.externalOrderNo : "NA"}`}
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_total_quantity")}
                              </td>
                              <td>
                                {`: ${getTotalQuantity(shipment?.lineItems)}`}
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_Kankenshare")}
                              </td>
                              <td>
                                {`: ${shipment?.kankenPercentage}`}
                                %
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_sub_total")}
                              </td>
                              <td>
                                {`: ${shipment?.priceInfo?.subTotal ? shipment?.priceInfo?.subTotal.grossPrice : ""}`}
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_discount_percentage")}
                              </td>
                              <td>
                                {shipment?.priceInfo?.adjustments && shipment?.priceInfo?.adjustments.length > 0 ? shipment?.priceInfo?.adjustments.map((discount) => (
                                  <>
                                    {`: ${discount?.discountPercentage || 0}`}
                                  </>

                                )) :
                                  <>
                                    {`: ${shipment?.discountPercentage || 0}`}
                                  </>
                                }
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_disdount")}
                              </td>
                              <td>
                                {`: ${shipment?.priceInfo?.discount ? shipment?.priceInfo?.discount?.grossPrice : "NA"}`}
                              </td>
                            </tbody>
                            <tbody>
                              <td className="font-weight-bold">
                                {t("order-details:p_grand_total")}
                              </td>
                              <td>
                                {`: ${shipment?.priceInfo ? shipment?.priceInfo?.grossPrice : ""}`}
                              </td>
                            </tbody>
                          </>
                        </Table>
                        <Col xs="12">
                          <div onClick={() => toggleCollapse(shipment?.id)}>
                            {" "}
                            <img src={AngleDown} alt="" />
                          </div>
                          <Collapse isOpen={collapsesOpen.includes(shipment?.id)}>
                            <Table borderless className={styles.productTable}>
                              <thead>
                                <tr>
                                  <th>{t("order-details:p_shipment_id")}</th>
                                  <th>{t("order-details:p_sizes")}</th>
                                  <th>{t("order-details:p_shipment_quantity")}</th>
                                </tr>
                              </thead>
                              {shipment?.lineItems && shipment?.lineItems.map((lineItem) => (
                                <tbody>
                                  <td>
                                    {" "}
                                    {lineItem?.masterProductUniqueId ? `${lineItem?.masterProductUniqueId}-${lineItem?.itemSelector?.color?.code}` : ""}
                                  </td>
                                  <td>
                                    {lineItem?.itemSelector?.size ? lineItem?.itemSelector?.size?.name : ""}
                                  </td>
                                  <td>
                                    {" "}
                                    {lineItem?.quantity ? lineItem?.quantity : ""}
                                  </td>
                                </tbody>
                              ))}
                            </Table>
                          </Collapse>
                        </Col>
                      </Col>
                    }
                  </>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs="12" className="p-2">
              <Row>
                <Col md="12" className="p-2">
                  <span
                    className={clsx(
                      styles.pageSelectionText,
                      "fontsizeMediumBold",
                    )}
                  >
                    {t("order-details:span_order_total")}
                  </span>
                  <hr />
                </Col>
              </Row>
              <Row>
                {/* <OrderNoteFormContainer
                  orderNotes={order?.orderNotes}
                  orderId={order?.id}
                /> */}
                <Col md="6" className="p-2">
                  <p className="font-weight-bold">
                    {t("order-details:p_order_total")}
                  </p>
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col xs="6">
                          <p>{t("order-details:p_sub_total")}</p>
                        </Col>
                        <Col xs="6" className="text-right">
                          <p>
                            {order?.currency}
                            {" "}
                            <p>
                              {" "}
                              {order?.priceInfo && order?.priceInfo?.subTotal ? order?.priceInfo?.subTotal.grossPrice : ""}
                            </p>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <p>{t("order-details:p_discounts")}</p>
                        </Col>
                        <Col xs="6" className="text-right">
                          <p>
                            {order?.currency}
                            {" "}
                            {order?.priceInfo && order?.priceInfo?.shipmentDiscounts ? order?.priceInfo?.shipmentDiscounts?.grossPrice : 0}
                          </p>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col xs="6" className="font-weight-bold">
                          <p>{t("order-details:p_grand_total")}</p>
                        </Col>
                        <Col xs="6" className="text-right">
                          <p>
                            {order?.currency}
                            {" "}
                            {order?.priceInfo ? order?.priceInfo?.grossPrice : 0}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row >
    </>
  );
};
OrderDetails.defaultProps = {
  order: {},
};
OrderDetails.propTypes = {
  order: PropTypes.objectOf(PropTypes.any),
  onHandleBack: PropTypes.func.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  collapsesOpen: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default OrderDetails;
