/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login
 *
 * @author Dipin
 *
 */

import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Card from "../../../common/core/Card";
import CardBody from "../../../common/core/CardBody";

const Login = (props) => {
  const { menuItems, openMenu } = props;

  return (
    <div className="fixed-body-height dashboard pb-3">
      <Row className="">
        {
          Array.isArray(menuItems) && menuItems.map((eachMenu, index) => (eachMenu.path ? (
            <Col xs="6" md="4" lg="3" className="text-center my-2">
              <a className="d-flex h-100 w-100">
                <Card className="cursor-pointer menu-item h-100 w-100" onClick={() => openMenu(eachMenu.path)}>
                  <CardBody>
                    <h6 className="text-truncate mb-0">{eachMenu.displayText}</h6>
                  </CardBody>
                </Card>
              </a>
            </Col>
          ) : (
              <Col xs="12" className={clsx("text-left", index !== 0 ? "mt-3" : "")}>
                <h5>{eachMenu.displayText}</h5>
              </Col>
            )))
        }
      </Row>
    </div>
  );
};

Login.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  openMenu: PropTypes.func.isRequired,
};
export default Login;
