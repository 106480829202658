import React from "react";
import B2BCustomerListUI from "../components/B2BCustomerListUI";

const B2BCustomerListUIContainer = () => {
  return (
    <>
      <B2BCustomerListUI />
    </>

  );
};
export default B2BCustomerListUIContainer;