/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Kishor
 * */
import httpApiExternal from "./httpApiExternal";

const getCustomerManagementAudit = async (data) => {
  const response = await httpApiExternal.post("/batch-history/job", data);
  if (response && response.data) {
    return response;
  }
  return null;
};

const getCustomerStepAuditByJobId = async (jobId) => {
  const response = await httpApiExternal.get("/batch-history/step", { params: { jobId } });
  if (response && response.data) {
    return response;
  }
  return null;
};


export {
  getCustomerManagementAudit,
  getCustomerStepAuditByJobId,
};