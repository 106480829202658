/**
 * Copyright(c) 2019 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SuccessModal
 *
 * @author Harikrishnan A K
 *
 */
import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Container from "../../../common/core/Container";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import invalidIcon from "../../../common/assets/images/svg/alert-error.svg";
import successIcon from "../../../common/assets/images/svg/check-solid-green.svg";

const MessageModal = ({
  modal,
  closeModal,
}) => (
  <Modal isOpen={modal.isOpen} centered>
    <ModalHeader toggle={closeModal}>
      {modal.title}
    </ModalHeader>
    <ModalBody>
      <Container className>
        <Row className="justify-content-center">
          <img src={modal.success ? successIcon : invalidIcon} alt="success" />
        </Row>
        <Row className="justify-content-center my-4">
          <Col md="8">
            <p className="text-center">{modal.message}</p>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
);

MessageModal.propTypes = {
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default MessageModal;
