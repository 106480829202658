/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Create Password Container
 *
 * @author Ajay J A
 *
 */

import React, { useState } from "react";
import {
  useHistory, useLocation,
} from "react-router-dom";
import queryString from "query-string";
import CreatePassword from "../components/CreatePassword";
import { resetPassword } from "../../../api/UserServices";
import { isAuthenticated } from "../../../common/security/permissionUtils";

const CreatePasswordContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { token } = parsed;

  const [createPasswordData, setCreatePasswordData] = useState({ newPassword: "", reTypePassword: "" });
  const [error, setError] = useState({ newPassword: "", reTypePassword: "", isValid: "" });
  const [submitting, setSubmitting] = useState(false);

  if (isAuthenticated()) {
    history.push("/");
  }
  const [showEachPassword, setShowPassword] = useState({
    showNewPassword: false,
    showConfirmPassword: false,
  });
  /**
   * This method is used to handle submit form
   *
   * @param {Object} createPasswordData
   */
  const handleSubmitForm = () => {
    setSubmitting(true);
    resetPassword(token, createPasswordData.newPassword).then((response) => { //
      setSubmitting(false);
      if (response && response.success && response.data) {
        history.push("/");
      } else {
        setError({ isValid: "Couldn't process now, please try again after some time" });
      }
    });
  };

  /**
   * This method is used to handle change text
   *
   * @param {String} key
   * @param {Object} e
   */
  const handleChange = (key, e) => {
    setError({ newPassword: "", reTypePassword: "", isValid: "" });
    setCreatePasswordData({ ...createPasswordData, [key]: e.target.value });
  };

  /**
   * Tjis method is used to validate login form
   *
   * @returns
   */
  const validateForm = () => {
    const regexp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
    let isValid = true;
    let newPasswordError = "";
    let reTypePasswordError = "";
    if (createPasswordData.newPassword.length < 6) {
      newPasswordError = "Password must have Minimum 6 characters with atleast 1 special character";
      isValid = false;
    }
    if (createPasswordData.reTypePassword.length < 6) {
      reTypePasswordError = "Password must have Minimum 6 characters with atleast 1 special character";
      isValid = false;
    }
    if (createPasswordData.newPassword !== createPasswordData.reTypePassword) {
      reTypePasswordError = "Password must be the same";
      isValid = false;
    } else if (!regexp.test(createPasswordData.newPassword)) {
      newPasswordError = "Password must have Minimum 6 characters with atleast 1 special character";
      isValid = false;
    }
    setError({ newPassword: newPasswordError, reTypePassword: reTypePasswordError });
    return isValid;
  };

  /**
   * This method is used to handle submit form
   *
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(createPasswordData)) {
      handleSubmitForm();
    }
  };
  const togglePassword = (key) => {
    setShowPassword({
      ...showEachPassword,
      [key]: !showEachPassword[key],
    });
  };

  return (
    <>
      <CreatePassword
        showNewPassword={showEachPassword.showNewPassword}
        showConfirmPassword={showEachPassword.showConfirmPassword}
        handleSubmitForm={handleSubmitForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        createPasswordData={createPasswordData}
        error={error}
        submitting={submitting}
        togglePassword={togglePassword}
      />
    </>
  );
};

export default CreatePasswordContainer;
