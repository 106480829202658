/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Header Container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import { getMainMenus } from "../../../api/navigationServices";
import { getSiteDetails } from "../../../common/security/permissionUtils";


const HeaderContainer = (props) => {
  const {
    setIsScrolled, navigation, handleLogout, handleChangepassword,
  } = props;

  const history = useHistory();
  /** local states */
  const [mainMenus, setMainMenus] = useState([]);

  useEffect(() => {
    const site = getSiteDetails();
    getMainMenus().then((response) => {
      if (response && response.success) {
        if (Array.isArray(response.data)) {
          setMainMenus(response.data);
        }
      }
    });
  }, []);

  /**
   * This method is used to handle menu change in header
   * @param {String} menuKey
   * @param {Event} event
   */
  const menuHandler = (menuKey, event) => {
    event.preventDefault();
    history.push(`/${menuKey}`);
  };

  return (
    <Header
      mainMenus={mainMenus}
      setIsScrolled={setIsScrolled}
      navigation={navigation}
      menuHandler={menuHandler}
      handleLogout={handleLogout}
      handleChangepassword={handleChangepassword}
    />
  );
};
HeaderContainer.defaultProps = {
  navigation: null,
};

HeaderContainer.propTypes = {
  setIsScrolled: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleChangepassword: PropTypes.func.isRequired,
  navigation: PropTypes.string,
};

export default HeaderContainer;
