/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../common/components/Select";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import Row from "../../../common/core/Row";
import Spinner from "../../../common/core/Spinner";
import validateForm from "../../../common/utils/validateForm";
import styles from "../css/PaymentClasses.module.css";

const EditPaymentMethod = ({
  onHandleCancel, handleFormChange, form, onHandleSave, submitting, loading,
}) => {
  const { t } = useTranslation(["payment-method", "common"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  /** validation methods */
  const { register, errors } = validator;
  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSave)}>
        <fieldset disabled={submitting}>
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7" className="text-truncate">
              <span className="pageText">
                {t("payment-method:edit_payment_method_title")}
              </span>
            </Col>
            <Col xs="12" md="5" className="d-flex justify-content-end">
              <Button
                type="button"
                color="secondary"
                className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}
                onClick={onHandleCancel}
              >
                {t("common:button_cancel")}
              </Button>
              <Button type="submit" color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")} onClick={onHandleSave}>
                {
                  loading ? (
                    <Spinner size="sm" />
                  ) : "SAVE"
                }
              </Button>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("common:label_name")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="name"
                          value={form.name}
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("common:validation_name"),
                              minLength: {
                                value: 3,
                                message: t("common:validation_min_three-letter"),
                              },
                              maxLength: {
                                value: 252,
                                message: t("common:validation_too_large"),
                              },
                            })
                          }
                          invalid={!!(errors.name && errors.name.message)}
                        />
                        <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("common:label_description")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="description"
                          value={form.description}
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("common:validation_description"),
                              minLength: {
                                value: 3,
                                message: t("common:validation_min_three-letter"),
                              },
                            })
                          }
                          invalid={!!(errors.description && errors.description.message)}
                        />
                        <FormFeedback>{errors.description && errors.description.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">{t("payment-method:label_display_message")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="displayMessage"
                          value={form.displayMessage}
                          onChange={(e) => handleFormChange(e)}
                        />
                        <FormFeedback>{errors.paymentType && errors.paymentType.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="3">
                      <Row>
                        <Col xs="9">
                          <FormGroup>
                            <Label className="formTextLabel">{t("payment-method:label_credit_limit")}</Label>
                            <Input
                              className="formText"
                              placeholder=""
                              name="paymentLimit"
                              type="number"
                              value={form.paymentLimit}
                              onChange={(e) => handleFormChange(e)}
                              innerRef={
                                register({
                                  required: t("common:validation_creditLimit"),
                                })
                              }
                              invalid={!!(errors.creditLimit && errors.creditLimit.message)}
                            />
                            <FormFeedback>{errors.creditLimit && errors.creditLimit.message}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="3">
                          <p className="text-left pt-4">
                            AED
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("payment-method:label_price_strategy")}</Label>
                    <Select
                      className="formText"
                      placeholder=""
                      name="priceStrategy"
                      options={[
                        { value: 1, label: "COD" },
                        { value: 0, label: "Debit" },
                      ]}
                      value={form.priceStrategy}
                      onChange={(e) => handleFormChange(e)}
                    />
                    <FormFeedback>{errors.code && errors.code.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="3">
                  <Row>
                    <Col xs="9">
                      <FormGroup>
                        <Label className="formTextLabel">{t("payment-method:label_payment_fee")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="paymentCharge"
                          type="number"
                          value={form.paymentCharge}
                          onChange={(e) => handleFormChange(e)}
                          innerRef={
                            register({
                              required: t("common:validation_paymentFee"),
                            })
                          }
                          invalid={!!(errors.paymentFee && errors.paymentFee.message)}
                        />
                        <FormFeedback>{errors.paymentFee && errors.paymentFee.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="3">
                      <p className="text-left pt-4">
                        AED
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  );
};

EditPaymentMethod.propTypes = {
  onHandleCancel: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleSave: PropTypes.func.isRequired,
};

export default EditPaymentMethod;
