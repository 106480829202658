/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

/** ========= CUSTOM COMPONENTS ========= */
import CustomerGroupCreatorView from "../components/CustomerGroupCreatorView";

/** ========= API SERVICE FUNCTIONS ========= */
import { addCustomerGroup } from "../../../api/customerGroupServices";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

const CustomerGroupCreator = (props) => {
  const {
    onSave, salesCompany,
  } = props;
  const positions = constants.NAVIGATION_MENU_POSITIONS;

  /** local states */
  const [form, setForm] = useState({
    name: "",
    description: "",
    type: "",
  });

  const [onSubmitting, setOnSubmitting] = useState(false);
  const [userIds, setUserIds] = useState([]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * Clear button trigger
   */
  const formCancel = () => {
    setUserIds(null);
    setForm({
      name: "",
      description: "",
      type: "",
    });
  };

  /**
    * This method is used to submit the form for add new customer Group
    * @param {Event} event
    */
  const formSubmit = async (event) => {
    const requestBody = {
      name: form.name,
      description: form.description,
      type: form.type,
      active: true,
      customers: userIds,
      salesCompany,
    };
    setOnSubmitting(true);
    const response = await addCustomerGroup(requestBody);
    if (response && response.success) {
      formCancel();
      if (onSave) {
        onSave(response.data);
      }
    }
    setOnSubmitting(false);
  };

  return (
    <CustomerGroupCreatorView
      // form field
      name={form.name}
      description={form.description}
      type={form.type}
      userIds={form.customers}
      /** form action */
      positions={positions}
      onSubmitting={onSubmitting}
      handleFormChange={handleFormChange}
      handleChange={handleChange}
      formSubmit={formSubmit}
      formCancel={formCancel}
      setUserIds={setUserIds}
      salesCompany={salesCompany}
    />
  );
};

CustomerGroupCreator.propTypes = {
  onSave: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default CustomerGroupCreator;
