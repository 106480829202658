/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ======== CUSTOM COMPONENTS ========= */
import CategorySelector from "../components/CategorySelector";


/** ===== API SERVICE FUNCTIONS =========== */

/** Base container for add new category  */
const CategorySelectorContainer = ({
  value, categoryDetails, onChange, id, disabled,
}) => {
  /** local states */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState(categoryDetails || []);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (Array.isArray(categoryDetails) && categoryDetails.length > 0) {
      const localCategories = [...categoryOptions, ...categoryDetails];
      const duplicateFiltered = localCategories.reduce((previous, current) => {
        const doesExist = previous.find((item) => item.id === current.id);
        return doesExist ? [...previous] : [...previous, current];
      }, []);
      setCategoryOptions(duplicateFiltered);
      setCategoryName(duplicateFiltered.map((category) => category.name).join(", "));
    }
  }, [categoryDetails, categoryOptions]);


  /**
   * This method is used return id only
   * but to display we need category name
   * @param {Object} categories
   */
  const localHandleCategorySelector = (categories) => {
    setCategoryName(categories.map((category) => category.name).join(", "));
    setCategoryOptions(categories);
    onChange(categories.map(({ id: catId }) => catId));
  };

  /**
   * This method is used for set selected category as root
   */
  const clearCategories = () => {
    setCategoryName(null);
    setCategoryOptions([]);
    onChange([]);
  };

  return (
    <CategorySelector
      // component
      categoryName={categoryName}
      toggle={toggle}
      dropdownOpen={dropdownOpen}
      clearCategories={clearCategories}
      options={categoryDetails}
      // selected category
      selectedCategories={value}
      handleCategorySelector={localHandleCategorySelector}
      id={id}
      disabled={disabled}
    />
  );
};

CategorySelectorContainer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  categoryDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CategorySelectorContainer;
