/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import httpApi from "./httpApi";

const moment = require("moment");

/**
 * This method is used to return order list
 * @returns {Array}
 */
const getOrders = async (requestData, page, size, sortBy, sortDirection) => {
  const response = await httpApi.post("v1/orders/search", requestData, {
    params: {
      page, size, sortBy, sortDirection,
    },
  });
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return order list
 * @returns {Array}
 */
const getOrderDetails = async (selectedOrderId) => {
  const response = await httpApi.get(`v1/b2b/orders/details/id/${selectedOrderId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update order note
 * @param String
 * @param String
 * @returns {Array}
 */
const updateOrderNote = async (id, note) => {
  const requestData = {
    note,
  };
  const response = await httpApi.patch(`v1/orders/${id}/note`, requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to transform order data
 * @param Array
 * @returns {Array}
 */
const transformOrderData = async (orders) => {
  // TODO - Configurable
  const filtered = orders.map(({
    orderNo,
    orderDate,
    submittedName,
    email,
    priceInfo,
    catalogType,
    salesCompany,
    orderExportedToOms,
  }) => ({
    "Order no": orderNo,
    "Order date": orderDate ? moment(orderDate).format("YYYY-MM-DD HH:mm:ss") : null,
    "Customer name": submittedName,
    Email: email,
    "Order status": orderExportedToOms ? "Exported" : "Submitted",
    "Order amount": priceInfo && priceInfo.grossPrice ? priceInfo.grossPrice : null,
    "Order type": catalogType,
    "Sales company": salesCompany,
  }));
  return filtered;
};
/**
 * This method is used to return b2b order list
 * @returns {Array}
 */
const getB2BOrderDetails = async (requestData, page = 0, size = 10, sortBy, sortDirection) => {
  const response = await httpApi.post("v1/b2b/orders/list", requestData, {
    params: {
      page,
      size,
      sortBy,
      sortDirection,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to repost order to OMS
 * @returns {Array}
 */
const repostOrderByOrderId = async (orderId) => {
  const response = await httpApi.put(`v1/b2b/orders/${orderId}/repost`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getOrders, getOrderDetails, updateOrderNote, transformOrderData, getB2BOrderDetails, repostOrderByOrderId,
};
