
/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amritha
 */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getPreOrders, updatePreOrders } from "../../../api/searchSettingServices";
import { getTranslationConfig } from "../../../api/translationServices";

import EditPreOrderManagement from "../components/EditPreOrderManagement";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import { getCustomerGroups } from "../../../api/customerGroupServices";

const EditPreOrderManagementConainer = (props) => {
  const [preOrderCatalogs, setPreOrderCatalogs] = useState([]);
  const [newData, setNewData] = useState(null);
  const [locale, setLocale] = useState([]);
  const history = useHistory();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [customers, setCustomers] = useState([]);
  const getPreOrder = (locale) => {
    const searchConfigurationView = { code: "PreOrderCatalogConfig", locale };
    getPreOrders(searchConfigurationView).then((response) => {
      if (response
        && response.data
        && response.data.defaultConfiguration
        && Array.isArray(response.data.defaultConfiguration)) {
        setNewData(response.data);
        setPreOrderCatalogs(response.data.defaultConfiguration);
        const [preOrderCatalog] = response.data.defaultConfiguration;
        if (preOrderCatalog
          && Array.isArray(preOrderCatalog.excludedCustomerList)
          && preOrderCatalog.excludedCustomerList.length > 0) {
          const newCustomers = preOrderCatalog.excludedCustomerList.map((each) => ({ name: each, id: each }));
          setCustomers(newCustomers);
        }
      }
    });
  };

  useEffect(() => {
    getTranslationConfig().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (data !== null) {
          setLocale(data.defaultLocale);
          getPreOrder(data.defaultLocale);
        }
      }
    });
  }, []);

  const handleCancel = () => {
    history.push("/merchandising/pre-order");
  };

  const redirect = () => history.push("/merchandising/pre-order");
  const handleFormChange = (event, index) => {
    const { name, value } = event.target;
    const newSiteSettings = [...preOrderCatalogs];
    const newValue = newSiteSettings[index];
    if (name === "elements") {
      const values = value.split(",");
      if (values && Array.isArray(values) && values.length > 0) {
        const valueArray = values.map((item) => item.trim());
        newValue[name] = valueArray;
      } else {
        newValue[name] = [];
      }
    } else {
      newValue[name] = value.trim();
    }
    newSiteSettings.splice(index, 1, newValue);
    setPreOrderCatalogs(newSiteSettings);
  };

  const updatePreOrder = (() => {
    const newPreOrderObject = { ...newData, locale, defaultConfiguration: preOrderCatalogs };
    updatePreOrders(newPreOrderObject).then((response) => {
      if (response && response.data && response.success) {
        redirect();
      }
    });
  });
  const getCustomerGroupList = (searchTerm, newPage = 0, sortBy = "createdDate", sortDirection = "DESC") => {
    getCustomerGroups(searchTerm, newPage, sortBy, sortDirection, false).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (Array.isArray(data)) {
          setCustomers(data);
        }
      }
    });
  };
  const handleSearch = (value) => {
    setCustomers([]);
    if (value && `${value}`.trim().length > 1) {
      getCustomerGroups(value).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          if (Array.isArray(data)) {
            setCustomers(data);
          }
        }
      });
    } else {
      getCustomerGroups();
    }
    setSearchKeyword(value);
  };
  const onCustomerGroupChange = (event, index) => {
    const { name, value } = event.target;
    if (Array.isArray(preOrderCatalogs) && preOrderCatalogs.length > 0) {
      const preOrderCatalog = preOrderCatalogs[index];
      if (preOrderCatalog) {
        const newPreOrderCatalog = { ...preOrderCatalog };
        if (name === "status") {
          newPreOrderCatalog.enableExcludeCustomer = !preOrderCatalog.enableExcludeCustomer;
        }
        if (name === "customerGroup") {
          newPreOrderCatalog.excludedCustomerList = value || [];
        }
        const newPreOrderCatalogs = [...preOrderCatalogs];
        newPreOrderCatalogs[index] = newPreOrderCatalog;
        setPreOrderCatalogs(newPreOrderCatalogs);
      }
    }
  };
  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_PRE_ORDR_CTLG_UPD_PRE_ORDR_CTLG}
    >
      <EditPreOrderManagement
        preOrderCatalogs={preOrderCatalogs}
        handleFormChange={handleFormChange}
        updatePreOrder={updatePreOrder}
        handleCancel={handleCancel}
        handleSearch={handleSearch}
        searchKeyword={searchKeyword}
        customers={customers}
        onCustomerGroupChange={onCustomerGroupChange}
      />
    </Permission>
  );
};

export default EditPreOrderManagementConainer;
