/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PageCustomization
 *
 * @author Naseef O
 *
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Dropdown from "../../../common/core/Dropdown";
import Label from "../../../common/core/Label";
import Spinner from "../../../common/core/Spinner";
import edit from "../../../common/assets/images/svg/edit.svg";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";
import { PageTypes } from "../../../common/utils/layoutUtils";
import warningIcon from "../../../common/assets/images/svg/warning.svg";
import styles from "../css/PageCustomization.module.css";
import CategorySelector from "../containers/CategorySelectorContainer";
import WidgetContentsWrapper from "../containers/WidgetContentWrapperContainer";
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import Input from "../../../common/core/Input";
import ModalHeader from "../../../common/core/ModalHeader";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

const PageCustomization = (props) => {
  const history = useHistory();
  const {
    pageConfiguration,
    isEdit,
    selectedWidget,
    setWidgetTitle,
    widgetTitle,
    handleEditWidget,
    handleSaveTitle,
    isLoading,
    handleCancelTitle,
    toggleDropdown,
    dropdownOpen,
    handleSelectPage,
    selectedPageData,
    categoryId,
    handleParentCategory,
    seoInformation,
    categoryLabel,
    brands,
    selectedBrand,
    handleSelectBrand,
    dropdownBrand,
    toggleDropdownBrand,
    previewSiteUrl,
    handlePushToLive,
    previewEnabled,
    pushToLiveEnabled,
    pages,
    customPageEnabled,
    toggleLandingPageStatus,
    toggleActiveWidget,
    widgetConfigurations,
    globalLayout,
    setCategoryLabel,
    layouts,
    selectedLayout,
    handleChangeLayout,
    dropdownLayout,
    toggleDropdownLayout,
    locale,
    localeHandler,
    message,
    getReplicationStatus,
    pushToLiveButtonEnabled,
    productsPerPage,
    setProductsPerPage,
    handleUpdateProductsPerPage,
    isEditProductsPerPage,
    setEditProductsPerPage,
  } = props;

  const { pageType: selectedPage, previewPath } = selectedPageData;
  const { t } = useTranslation(["common", "page-customization"]);

  const constructUrl = () => {
    let url = `${previewSiteUrl}${previewPath}`;
    if (categoryId && (selectedPage === PageTypes.CLP || selectedPage === PageTypes.PLP)) {
      url += `?cat=${categoryId}`;
    } else if (selectedBrand && selectedBrand.id && selectedPage === PageTypes.BLP) {
      url += `?id=${selectedBrand.id}`;
    } else if (selectedPage === PageTypes.PDP) {
      url += "?id=WATSPROD0000";
    }
    return url;
  };

  const redirectToPage = () => {
    const completeUrl = constructUrl();
    window.open(completeUrl, "_blank");
  };

  const disablePreview = () => {
    let disabled = true;
    if (selectedPage) {
      if (selectedPage === PageTypes.CLP || selectedPage === PageTypes.PLP) {
        disabled = !categoryId;
      } else if (selectedPage === PageTypes.BLP) {
        disabled = !selectedBrand;
      } else {
        disabled = false;
      }
    }
    return disabled;
  };

  /**
  *This method is used to handle redirect to WidgetContentEditor
  *
  */
  const handleRedirectToWidgetContentEditor = (widgetType, configId) => {
    history.push(`/merchandising/web-app-customization/content-editor?widgetType=${widgetType}&&configId=${configId}&&categoryId=${categoryId}&&pageType=${selectedPage}&&channel=web&&locale=${locale}`);
  };

  /**
  *This method is used to handle redirect to SEOInfoEditor
  *
  */
  const handleRedirectToSEOInfoEditor = () => {
    history.push(`/merchandising/web-app-customization/content-seo-editor?categoryId=${categoryId}&&pageType=${selectedPage}&&channel=web&&locale=${locale}`);
  };
  /**
  *This method is used to handle redirect to Featured products editor
  *handleRedirectToCombinedWidgetEditor
  */
  const handleRedirectToProductEditor = (widgetType) => {
    history.push(`/merchandising/web-app-customization/content-product-editor?categoryId=${categoryId}&&pageType=${selectedPage}&&widgetType=${widgetType}&&channel=web`);
  };
  /**
  *This method is used to handle redirect to combined widget editor
  *
  */
  const handleRedirectToCombinedWidgetEditor = (widgetType, configId) => {
    history.push(`/merchandising/web-app-customization/combined-widget-editor?widgetType=${widgetType}&&configId=${configId}&&categoryId=${categoryId}&&pageType=${selectedPage}&&channel=web&&locale=${locale}`);
  };

  const widgets = pageConfiguration && pageConfiguration.widgets;

  const [previewModal, setPreviewModal] = useState(false);
  const handleOpenModal = () => {
    setPreviewModal(!previewModal);
  };
  return (
    < >
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className=" common-page-card pageHeader">
        <Col xs="12" md="4">
          <span className="pageText"> Page Customization</span>
        </Col>
        <Col xs="12" md="8" className={styles.liveBtnWrapper}>
          <Row className="h-100 align-items-end">
            {previewEnabled && (
              <Col xs="12" md="4">
                <Button
                  className={clsx(styles.liveBtn, " bg-color-tertiary-main")}
                  onClick={() => redirectToPage()}
                  disabled={disablePreview()}
                >
                  {t("common:button_preview")}
                </Button>
              </Col>
            )}
            {pushToLiveEnabled && (
              <Col xs="12" md="4">
                <Button
                  className={clsx(styles.liveBtn, " bg-color-tertiary-main ")}
                  onClick={() => handlePushToLive()}
                  disabled={!pushToLiveButtonEnabled}
                >
                  {t("common:button_push_to_live")}
                </Button>
              </Col>
            )}
            {pushToLiveEnabled && (
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              </Col>
            )}
            <Col xs="12" md="4">
              <Button
                className={clsx(styles.liveBtnPush, " bg-color-tertiary-main ")}
                onClick={() => handlePushToLive()}
              >
                {t("common:button_push_to_live")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12" md="3">
          <Row className="m-0">
            <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}> Page Selection</span>
          </Row>
          <Row className="m-0">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              className={styles.pageSelection}
            >
              <DropdownToggle caret>
                <span>
                  {selectedPageData.name}
                </span>
              </DropdownToggle>
              <DropdownMenu className={styles.dropdownWrapper}>
                {pages.map((page) => (
                  <div
                    key={`PAGE-${page.pageType}`}
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className={styles.dropdownItem}
                    onClick={() => handleSelectPage(page)}
                  >
                    {page.name}
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Row>
        </Col>
        {Array.isArray(layouts) && layouts.length > 0 && (
          <Col xs="12" md="3">
            <Row className="m-0">
              <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}> Layout</span>
            </Row>
            <Row className="m-0">
              <Dropdown
                isOpen={dropdownLayout}
                toggle={toggleDropdownLayout}
                className={styles.pageSelection}
              >
                <DropdownToggle caret>
                  <span>
                    {(selectedLayout && selectedLayout.name) || "Select layout"}
                  </span>
                </DropdownToggle>
                <DropdownMenu className={styles.dropdownWrapper}>
                  {layouts.map((page) => (
                    <div
                      key={`layout-${page.pageType}`}
                      onKeyPress={() => { }}
                      role="button"
                      tabIndex="0"
                      className={clsx(styles.dropdownItem, " dropdownItem")}
                      onClick={() => { handleChangeLayout(page); toggleDropdownLayout(); }}
                    >
                      {page.name}
                    </div>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Row>
          </Col>
        )}
        {selectedPage === "BLP" && brands && brands.length > 0 && (
          <Col xs="12" md="3">

            <Row className="m-0">
              <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>Brand</span>
            </Row>
            <Row className="m-0">
              <Dropdown
                isOpen={dropdownBrand}
                toggle={toggleDropdownBrand}
                className={styles.pageSelection}
              >
                <DropdownToggle caret>
                  <span>
                    {selectedBrand.name || "Select a brand"}
                  </span>
                </DropdownToggle>
                <DropdownMenu className={styles.dropdownWrapper}>
                  {brands.map((brand) => (
                    <div
                      key={`PAGE-${brand.id}`}
                      onKeyPress={() => { }}
                      role="button"
                      tabIndex="0"
                      className={styles.dropdownItem}
                      onClick={() => handleSelectBrand(brand)}
                    >
                      {brand.name}
                    </div>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Row>
          </Col>
        )}
        {(selectedPage === "CLP" || selectedPage === "PLP")
          && (
            <Col xs="12" md="3">
              <Row className="m-0">
                <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}> Select a category</span>
              </Row>
              <Row className="m-0">
                <CategorySelector
                  parentCategory={categoryId}
                  handleParentCategory={handleParentCategory}
                  categoryLabel={categoryLabel}
                  setCategoryLabel={setCategoryLabel}
                />
              </Row>
            </Col>
          )}
        {(selectedPage === "PLP" || selectedPage === "WISHLIST_EDIT")
          && (
            <Col xs="12" md="6">
              <Row className="m-0 ml-2">
                <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}> Products Per Page</span>
                {!isEditProductsPerPage
                  ? (
                    <img
                      src={edit}
                      alt="edit"
                      width="20px"
                      role="presentation"
                      className="pointer ml-2 mb-3"
                      onClick={() => setEditProductsPerPage(true)}
                    />
                  ) : ""}
              </Row>
              <Row className="m-0">
                <Col xs="6">

                  <Input
                    className="formText"
                    plceholder="Enter Products per page"
                    value={productsPerPage}
                    onChange={(e) => setProductsPerPage(e.target.value)}
                    disabled={!isEditProductsPerPage}
                  />
                </Col>
                {isEditProductsPerPage
                  ? (
                    <>
                      <Col xs="6">
                        <Button
                          className={clsx(styles.buttonAdd, "mx-2")}
                          onClick={() => handleUpdateProductsPerPage(pageConfiguration)}
                        >
                          {t("page-customization:save")}
                        </Button>
                        <Button
                          className={clsx(styles.buttonCancel, "")}
                          onClick={() => {
                            setProductsPerPage(pageConfiguration.contentLimit || "");
                            setEditProductsPerPage(false);
                          }}
                        >
                          {t("page-customization:cancel")}
                        </Button>
                      </Col>
                    </>
                  )
                  : ""}
              </Row>
            </Col>
          )}
        {selectedLayout && selectedPage === selectedLayout.subType && (
          <Col xs="4" md="3" className={styles.pagePreview}>
            <div
              role="presentation"
              className={styles.pagePreviewWrapper}
              onClick={() => handleOpenModal()}
            >
              <img
                src={selectedLayout.smallImageUrl}
                alt="layout"
                className={clsx("cursor-pointer", styles.selectedPageImg)}
                width="73px"
              />
              <div className={clsx("cursor-pointer", styles.clickMe)}>Click me</div>
            </div>
            <Modal isOpen={previewModal} toggle={handleOpenModal}>
              <ModalHeader toggle={handleOpenModal}>Preview</ModalHeader>
              <ModalBody>
                <img
                  className="w-100"
                  src={selectedLayout.smallImageUrl}
                  alt="layout"
                />
              </ModalBody>
            </Modal>

          </Col>
        )}
        {((selectedPage === "CLP" && categoryId) || (selectedPage === "BLP" && selectedBrand))
          && (
            <Col xs="12" md="" className={styles.customLandingToggle}>
              <Row className="m-0">
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="expand-multiple-pg"
                    checked={customPageEnabled}
                    onChange={toggleLandingPageStatus}
                  />
                  <Label className="custom-control-label" htmlFor="expand-multiple-pg">Activate/Disable custom landing page</Label>
                </div>
              </Row>
            </Col>
          )}
      </Row>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7">
          <LocaleDropdownContainer
            isIndocument={false}
            entityName="pageContent"
            localeHandler={localeHandler}
          />
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          {!isLoading ? (
            <Row className="h-100">
              <Col
                xs="12"
                className={styles.spinnerWrapper}
              >
                <div
                  className="h-100  d-flex align-items-center justify-content-center"
                >
                  <Spinner />
                </div>
              </Col>
            </Row>
          )
            : (
              <>
                {pageConfiguration
                  && pageConfiguration.widgets
                  && (
                    <>
                      {(selectedPage === "CLP" || selectedPage === "PLP")
                        ? (
                          <>
                            {categoryId
                              ? Object.keys(pageConfiguration.widgets).map((key, index) => (
                                <WidgetContentsWrapper
                                  pageConfiguration={pageConfiguration}
                                  widgets={widgets}
                                  index={index}
                                  isEdit={isEdit}
                                  selectedWidget={selectedWidget}
                                  widgetTitle={widgetTitle}
                                  setWidgetTitle={setWidgetTitle}
                                  handleEditWidget={handleEditWidget}
                                  widgetType={key}
                                  handleSaveTitle={handleSaveTitle}
                                  handleCancelTitle={handleCancelTitle}
                                  categoryId={categoryId}
                                  configId={pageConfiguration.id}
                                  selectedPage={selectedPage}
                                  handleRedirectToWidgetContentEditor={
                                    handleRedirectToWidgetContentEditor
                                  }
                                  handleRedirectToProductEditor={handleRedirectToProductEditor}
                                  handleRedirectToCombinedWidgetEditor={
                                    handleRedirectToCombinedWidgetEditor
                                  }
                                  toggleActiveWidget={toggleActiveWidget}
                                  channel="web"
                                  widgetConfigurations={widgetConfigurations}
                                  globalLayout={globalLayout}
                                  locale={locale}
                                />
                              ))
                              : (
                                <Row className="h-100">
                                  <Col xs="12" className={styles.selectedCategory}>
                                    <span>
                                      Please select a category
                                    </span>
                                    <span className="pl-2">
                                      <img
                                        src={warningIcon}
                                        className="commonPointer"
                                        alt="warning"
                                        width="20px"
                                        role="presentation"
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              )}
                          </>
                        )
                        : (
                          <>
                            {Object.keys(pageConfiguration.widgets).map((key, index) => (
                              <WidgetContentsWrapper
                                pageConfiguration={pageConfiguration}
                                widgets={widgets}
                                index={index}
                                isEdit={isEdit}
                                selectedWidget={selectedWidget}
                                widgetTitle={widgetTitle}
                                setWidgetTitle={setWidgetTitle}
                                handleEditWidget={handleEditWidget}
                                widgetType={key}
                                handleSaveTitle={handleSaveTitle}
                                handleCancelTitle={handleCancelTitle}
                                categoryId={categoryId}
                                configId={pageConfiguration.id}
                                selectedPage={selectedPage}
                                handleRedirectToWidgetContentEditor={
                                  handleRedirectToWidgetContentEditor
                                }
                                handleRedirectToProductEditor={handleRedirectToProductEditor}
                                handleRedirectToCombinedWidgetEditor={
                                  handleRedirectToCombinedWidgetEditor
                                }
                                toggleActiveWidget={toggleActiveWidget}
                                channel="web"
                                widgetConfigurations={widgetConfigurations}
                                globalLayout={globalLayout}
                                locale={locale}
                              />
                            ))}
                          </>
                        )}
                      {isLoading
                        && seoInformation
                        && (
                          <Row className={clsx(styles.block3)}>
                            <Col xs="12" className="">
                              <Row>
                                <Col xs="8" className=" d-flex justify-content-start">
                                  <span className={clsx(styles.pageWidgetsText, "fontsizeMediumBold")}>
                                    SEO INFORMATION
                                  </span>
                                  <img
                                    src={edit}
                                    alt="edit"
                                    width="20px"
                                    role="presentation"
                                    className="pointer"
                                    onClick={() => handleRedirectToSEOInfoEditor()}
                                  />
                                </Col>
                              </Row>
                              <Row className="m-0 ">
                                <Col xs="12" className="pt-2">
                                  <Row>
                                    <Col xs="12" className={styles.seoText}>
                                      <span>Page Title:</span>
                                      <span
                                        className={styles.value}
                                      >
                                        {seoInformation.pageTitle ? seoInformation.pageTitle : ""}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12" className={styles.seoText}>
                                      <span>Meta Keywords:</span>
                                      <span className={styles.value}>
                                        {seoInformation.metaKeywords
                                          ? seoInformation.metaKeywords : ""}

                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12" className={styles.seoText}>
                                      <span>Meta Description:</span>
                                      <span
                                        className={styles.value}
                                      >
                                        {seoInformation.metaDescription ? seoInformation.metaDescription : ""}
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                    </>
                  )}
              </>
            )}
        </Col>
      </Row>
    </>
  );
};

PageCustomization.propTypes = {
  pageConfiguration: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  selectedWidget: PropTypes.number.isRequired,
  setWidgetTitle: PropTypes.func.isRequired,
  widgetTitle: PropTypes.string.isRequired,
  handleEditWidget: PropTypes.func.isRequired,
  handleSaveTitle: PropTypes.func.isRequired,
  handleCancelTitle: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  handleSelectPage: PropTypes.func.isRequired,
  selectedPageData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  seoInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  categoryLabel: PropTypes.string.isRequired,
  brands: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedBrand: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSelectBrand: PropTypes.func.isRequired,
  dropdownBrand: PropTypes.bool.isRequired,
  toggleDropdownBrand: PropTypes.func.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.any).isRequired,
  customPageEnabled: PropTypes.bool.isRequired,
  toggleLandingPageStatus: PropTypes.func.isRequired,
  toggleActiveWidget: PropTypes.func.isRequired,
  widgetConfigurations: PropTypes.arrayOf(PropTypes.any).isRequired,
  globalLayout: PropTypes.arrayOf(PropTypes.any).isRequired,
  setCategoryLabel: PropTypes.func.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLayout: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeLayout: PropTypes.func.isRequired,
  dropdownLayout: PropTypes.bool.isRequired,
  toggleDropdownLayout: PropTypes.func.isRequired,
  handleLanguageSelect: PropTypes.func.isRequired,
  dropdownLanguage: PropTypes.bool.isRequired,
  toggleDropdownLanguage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  isEditProductsPerPage: PropTypes.bool.isRequired,
  productsPerPage: PropTypes.number.isRequired,
  setProductsPerPage: PropTypes.func.isRequired,
  handleUpdateProductsPerPage: PropTypes.func.isRequired,
  setEditProductsPerPage: PropTypes.func.isRequired,
};

export default PageCustomization;
