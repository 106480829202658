/* eslint-disable react/jsx-props-no-spreading */

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Drag and droppable  table
 *
 * @author Hari A K
 *
 */


import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import editIcon from "../../../common/assets/images/svg/edit.svg";
import dragIndicatorIcon from "../../../common/assets/images/svg/dragIndicator.svg";
import Table from "../../../common/core/Table";
import Label from "../../../common/core/Label";

const RowItem = (props) => {
  const {
    rowItem,
    index,
    handleRedirectToFacetEditor,
    handleActiveStatusChange,
  } = props;

  return (
    <Draggable draggableId={`facet-${rowItem.code}`} width="100%" index={index}>
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <th scope="row" width="12%">
            <img src={dragIndicatorIcon} alt="drag indiactor icon" width="30px" height="24px" />
            <span className="p-2">{index + 1}</span>
          </th>
          <td width="22%">
            {" "}
            {rowItem.field}
            {" "}
          </td>
          <td width="22%">
            {" "}
            {rowItem.path}
            {" "}
          </td>

          <td width="22%">
            <img
              src={editIcon}
              className="commonPointer"
              onClick={() => handleRedirectToFacetEditor(index)}
              role="presentation"
              alt="drag indiactor icon"
              width="20px"
            />
          </td>
          <td width="22%">
            <div
              className={clsx(
                "custom-control",
                "custom-switch",

              )}
            >

              <input
                type="checkbox"
                className="custom-control-input"
                id={`toogle-${index + 1}`}
                checked={rowItem.active}
                onChange={() => handleActiveStatusChange(index)}
              />
              <Label className="custom-control-label" htmlFor={`toogle-${index + 1}`} />
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
};


RowItem.propTypes = {
  rowItem: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  handleRedirectToFacetEditor: PropTypes.func.isRequired,
  handleActiveStatusChange: PropTypes.func.isRequired,
};

const RowsList = React.memo((props) => {
  const {
    facetCustomizationRows,
    handleRedirectToFacetEditor,
    handleActiveStatusChange,
  } = props;
  return facetCustomizationRows.map((rowItem, index) => (
    <RowItem
      rowItem={rowItem}
      index={index}
      key={rowItem.code}
      handleRedirectToFacetEditor={handleRedirectToFacetEditor}
      handleActiveStatusChange={handleActiveStatusChange}
    />
  ));
});

RowsList.propTypes = {
  facetCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleRedirectToFacetEditor: PropTypes.func.isRequired,
  handleActiveStatusChange: PropTypes.func.isRequired,

};

const DragAndDropTable = (props) => {
  const {
    facetCustomizationRows,
    onDragEnd,
    handleRedirectToFacetEditor,
    handleActiveStatusChange,
  } = props;

  return (
    <Table borderless>
      <thead>
        <tr>
          <th>Order</th>
          <th>Facet Name</th>
          <th>Path</th>
          <th>Edit</th>
          <th>Status</th>
        </tr>
      </thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              <RowsList
                facetCustomizationRows={facetCustomizationRows}
                handleRedirectToFacetEditor={handleRedirectToFacetEditor}
                handleActiveStatusChange={handleActiveStatusChange}
              />
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};

DragAndDropTable.propTypes = {
  facetCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  handleRedirectToFacetEditor: PropTypes.func.isRequired,
  handleActiveStatusChange: PropTypes.func.isRequired,
};
export default DragAndDropTable;
