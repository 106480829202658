/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";

/** ===== IMAGE ICONS =========== */


/** ========= CUSTOM COMPONENTS ========= */
import VariantSelector from "../containers/VariantSelectorContainer";
import ImageSelection from "../containers/ImageSelectionContainer";

/** ============ SVG IMAGE ICON ===================== */
/** ======== MODULE STYLES ========== */
import styles from "../css/ProductForm.module.css";
import FormFeedback from "../../../common/core/FormFeedback";
import commonUtils from "../../../common/utils/commonUtils";
import constants from "../../../common/utils/validationConstants";

const { DATE_FORMAT, IS_NUMBER } = constants;

const ProductForm = (props) => {
  const {
    productType,
    attributes,
    skuName,
    images,
    handleFormChange,
    handleChange,
    description,
    longDescription,
    startDate,
    endDate,
    enableSku,
    quantity,
    onSale,
    register,
    errors,
  } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  return (
    <Row>
      <Col xs="12" md="10">
        <Row>
          <Col xs="12" sm="11" md="12">
            <FormGroup>
              <Label className="formTextLabel">{t("common:label_skuName")}</Label>
              <Input
                className="formText"
                placeholder={t("product:placeholder_skuName")}
                name="skuName"
                defaultValue={skuName}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: t("product:validation_sku_name"),
                    minLength: {
                      value: 3,
                      message: t("product:validation_min_three-letter"),
                    },
                  })
                }
                invalid={!!(errors.skuName && errors.skuName.message)}
              />
              <FormFeedback>{errors.skuName && errors.skuName.message}</FormFeedback>
            </FormGroup>
          </Col>
          <VariantSelector
            productType={productType}
            value={attributes}
            onChange={(o) => handleChange("attributes", o)}
            register={register}
            errors={errors}
          />
          <Col xs="12" sm="11" md="12">
            <FormGroup>
              <Label className="formTextLabel">{t("common:label_description")}</Label>
              <Input className="formText" placeholder={t("product:placeholder_description")} name="description" value={description} onChange={handleFormChange} />
            </FormGroup>
          </Col>
          <Col xs="12" sm="11" md="12">
            <FormGroup>
              <Label className="formTextLabel">{t("common:label_long_description")}</Label>
              <Input className="formText" placeholder={t("product:placeholder_long_description")} name="longDescription" value={longDescription} onChange={handleFormChange} />
            </FormGroup>
          </Col>

          <Col xs="12" sm="5" md="4">
            <FormGroup>
              <Label className="formTextLabel">
                Start date
              </Label>
              <DatePicker
                id="startDate"
                dateFormat={DATE_FORMAT}
                timeFormat={false}
                closeOnSelect
                inputProps={{
                  placeholder: `Ex: ${moment().format(DATE_FORMAT)}`,
                  name: "startDate",
                  readOnly: true,
                }}
                value={moment(startDate).format(DATE_FORMAT)}
                onChange={(o) => (moment.isMoment(o) && handleChange("startDate", o.toDate()))}
                required
                isValidDate={(date) => commonUtils.maxDate(date, endDate)}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="5" md="4">
            <FormGroup>
              <Label className="formTextLabel">
                End date
              </Label>
              <DatePicker
                id="endate"
                dateFormat={DATE_FORMAT}
                timeFormat={false}
                closeOnSelect
                isValidDate={(date) => commonUtils.minDate(date, startDate)}
                inputProps={{
                  placeholder: `Ex: ${moment().format(DATE_FORMAT)}`,
                  name: "endtDate",
                  readOnly: true,
                }}
                name="endDate"
                value={moment(endDate).format(DATE_FORMAT)}
                onChange={(o) => (moment.isMoment(o) && handleChange("endDate", o.toDate()))}
                required
              />
            </FormGroup>
          </Col>

          {/* Hiding Enable section
          <Col xs="12" sm="6" md="7">
            <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
              <Label title={t("product:label_enable")} className="formTextLabel text-truncate">{t("product:label_enable")}</Label>
              <div
                className={clsx(
                  "custom-control",
                  "custom-switch",
                  "ml-1",
                )}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="enableSku-new-product"
                  name="enableSku"
                  checked={enableSku}
                  onChange={() => handleChange("enableSku", !enableSku)}
                />
                <Label className="custom-control-label" htmlFor="enableSku-new-product" />
              </div>
            </FormGroup>
          </Col> */}
        </Row>
        <Row>
          {/* <Col xs="12" sm="6" md="4">
            <FormGroup>
              <Label className="formTextLabel">{t("common:label_listPrice")}</Label>
              <Input
                type="number"
                className="formText"
                placeholder={t("product:placeholder_listPrice")}
                name="listPrice"
                defaultValue={listPrice}
                innerRef={
                  register({
                    pattern: {
                      value: IS_NUMBER,
                      message: t("common:validation_is_number"),
                    },
                  })
                }
                onChange={handleFormChange}
                invalid={!!(errors.listPrice && errors.listPrice.message)}
              />
              <FormFeedback>{errors.listPrice && errors.listPrice.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" md="4">
            <FormGroup>
              <Label className="formTextLabel">{t("common:label_salePrice")}</Label>
              <Input
                type="number"
                className="formText"
                placeholder={t("product:placeholder_salePrice")}
                name="salePrice"
                defaultValue={salePrice}
                innerRef={
                  register({
                    pattern: {
                      value: IS_NUMBER,
                      message: t("common:validation_is_number"),
                    },
                  })
                }
                onChange={handleFormChange}
                invalid={!!(errors.salePrice && errors.salePrice.message)}
              />
              <FormFeedback>{errors.salePrice && errors.salePrice.message}</FormFeedback>
            </FormGroup>
          </Col> */}
          <Col xs="12" sm="6" md="4">
            <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex h-100 justify-content-center align-items-center")}>
              <Label title={t("product:placeholder_onSale")} className="formTextLabel text-truncate m-0">{t("common:label_onSale")}</Label>
              <div
                className={clsx(
                  "custom-control",
                  "custom-switch",
                  "ml-1",
                )}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="onsale-checkbox"
                  name="onSale"
                  checked={onSale}
                  onChange={() => handleChange("onSale", !onSale)}
                />
                <Label className="custom-control-label" htmlFor="onsale-checkbox" />
              </div>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" md="4">
            <FormGroup>
              <Label className="formTextLabel">{t("common:label_quantity")}</Label>
              <Input
                type="number"
                className="formText"
                placeholder={t("product:placeholder_quantity")}
                name="quantity"
                defaultValue={quantity}
                innerRef={
                  register({
                    pattern: {
                      value: IS_NUMBER,
                      message: t("common:validation_is_number"),
                    },
                  })
                }
                onChange={handleFormChange}
                invalid={!!(errors.quantity && errors.quantity.message)}
              />
              <FormFeedback>{errors.quantity && errors.quantity.message}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="11" md="12">
            <FormGroup>
              <Label className="formTextLabel">{t("product:banner_images")}</Label>
              <ImageSelection images={images} handleChange={handleChange} />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>

  );
};

ProductForm.propTypes = {
  /** form data */
  productType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  startDate: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  listPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  salePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSale: PropTypes.bool.isRequired,
  skuName: PropTypes.string.isRequired,
  enableSku: PropTypes.bool.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // validation
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductForm;
