/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Store Management Container
 *
 * @author Aardra S
 *
 */

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
/** ========= API SERVICE FUNCTIONS ========= */
import {
  getStores,
  deleteStoreById,
} from "../../../api/storeManagementServices";
import StoreManagement from "../components/StoreManagement";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  getSitePreviewConfigurations,
  replicateStoreLocations,
  getStoreLocationsReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const StoreManagementContainer = () => {
  const history = useHistory();

  const [stores, setStores] = useState([]);

  const [deleteStore, setDeleteStore] = useState(null);

  /**
    *This method is used to load add store page
    */
  const addNewStore = () => {
    history.push("/administration/store-locations/new");
  };

  /**
    *This method is used to load edit store page
    */
  const editStore = (storeId) => {
    history.push(`/administration/store-locations/edit/${storeId}`);
  };


  /**
    * This method is used to cancel confirm form
    */
  const cancelConfirm = () => {
    setDeleteStore(null);
  };

  const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

  /**
    * method for delete confirmation
    */
  const confirmDelete = async () => {
    if (Boolean(deleteStore) && deleteStore.id && deleteStore.element) {
      const { id, element } = deleteStore;
      const response = await deleteStoreById(id);
      if (response && response.success === true) {
        if (element && element.style) { element.style.display = "none"; }
      }
      if (isFunction(deleteStore.callback)) { deleteStore.callback(); }
      setDeleteStore(null);
    }
  };


  useEffect(() => {
    getStores().then((response) => {
      if (response) {
        const { success, data } = response;
        if (success && Array.isArray(data)) {
          setStores(data);
        }
      }
    });
  }, []);

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };
  const getReplicationStatus = async () => {
    getStoreLocationsReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last store configuration push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };
  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, [getReplicationStatus]);

  const handlePushToLive = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    replicateStoreLocations().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message:
            "Store locations pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };


  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_STR_LCTN_VIEW_LIST}
    >
      <>
        <StoreManagement
          stores={stores}
          addNewStore={addNewStore}
          setDeleteStore={setDeleteStore}
          editStore={editStore}
          pushToLiveEnabled={pushToLiveEnabled}
          previewSiteUrl={previewSiteUrl}
          previewEnabled={previewEnabled}
          message={message}
          handlePushToLive={handlePushToLive}
          pushToLiveButtonEnabled={pushToLiveButtonEnabled}
          getReplicationStatus={getReplicationStatus}
        />
        <ConfirmationModal
          isOpen={Boolean(deleteStore)}
          toggleOpen={cancelConfirm}
          togglClose={cancelConfirm}
          handleConfirm={confirmDelete}
          content=" Are you sure you want to delete this Store?"
        />
      </>
    </Permission>
  );
};

export default StoreManagementContainer;
