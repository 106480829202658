/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login
 *
 * @author Dipin
 *
 */

import React from "react";
import PropTypes from "prop-types";

import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Card from "../../../common/core/Card";
import CardBody from "../../../common/core/CardBody";
import DashboardIcon from "../../../common/components/DashboardIcon";

const Login = (props) => {
  const { mainMenus, openMenu } = props;

  /**
     * This function is used to return icon path
     * @param {String} iconKey
     */
  const getIcon = (iconKey) => DashboardIcon[iconKey] || null;

  return (
    <div className="fixed-body-height dashboard">
      <div className="mb-3 pt-4">
        <h4>Dashboard</h4>
      </div>
      <Row className="">
        {
          Array.isArray(mainMenus) && mainMenus.map((eachMenu) => (
            <Col key={eachMenu.id} xs="6" md="4" lg="3" className="text-center mb-3">
              <a className="d-flex h-100 w-100">
                <Card className="cursor-pointer menu-item h-100 w-100" onClick={() => openMenu(eachMenu.id)}>
                  <CardBody className="d-flex flex-column">
                    {
                      eachMenu.icon && getIcon(eachMenu.icon) && (
                        <div className="w-50 mx-auto d-flex align-items-center" style={{ minHeight: 100 }}>
                          <img src={getIcon(eachMenu.icon)} alt={eachMenu.label} className="img-fluid" />
                        </div>
                      )
                    }
                    <div className="mb-auto pt-2">
                      <h5 className="text-truncate mb-0" style={{ color: eachMenu.color || undefined }}>{eachMenu.label}</h5>
                      {
                        eachMenu.description && (
                          <p className="text-muted description font-weight-light">{eachMenu.description}</p>
                        )
                      }
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

Login.propTypes = {
  mainMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  openMenu: PropTypes.func.isRequired,
};
export default Login;
