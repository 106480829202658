/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Vishakh
 */

import React from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale, PointElement,
  Title, Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const LineChartTest = ({ barXAxisData, lineChartData }) => {
  console.log('lineChartData', lineChartData);
  const data = {
    labels: lineChartData?.xaxis ? lineChartData?.xaxis : [],
    datasets: [{
      label: "No of Orders",
      data: lineChartData?.yaxis ? lineChartData?.yaxis : [],
      backgroundColor: [
        "rgba(203, 127, 208, 1)",
      ],
      borderColor: [
        "rgba(203, 127, 208, 1)",
      ],
      borderWidth: 2,
    }],
  };

  const options = {
    tension: 0.4,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  };

  return (
    <div>
      <Line
        data={data}
        height={350}
        options={options}

      />
    </div>
  );
};

LineChartTest.propTypes = {
  barXAxisData: PropTypes.arrayOf(PropTypes.any).isRequired,
  lineChartData: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default LineChartTest;
