/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * WidgetContentEditor
 *
 * @author Naseef O
 *
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Button from "../../../common/core/Button";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import commonUtils from "../../../common/utils/commonUtils";
import styles from "../css/WidgetContentEditor.module.css";
import ImageCropper from "../../../common/components/imageCropper/Cropper";
import { uploadImage } from "../../../api/pageContentServices";
import ImageSelection from "./ImageSelection";
import Form from "../../../common/core/Form";
import errorIcon from "../../../common/assets/images/svg/error.svg";
import { getAspectRatio } from "../../../common/utils/layoutUtils";
import AnimationAssetsEditor from "./AnimationAssetsEditor";
import Spinner from "../../../common/core/Spinner";

const WidgetContentEditor = (props) => {
  const {
    location,
    handleChange,
    content,
    handleChangeFile,
    modalOpen,
    setModalOpen,
    linkType,
    setLinkType,
    submitForm,
    handleUploadImage,
    isUploading,
    setIsUploading,
    widgetType,
    handleRedirectToPageCustomization,
    handleCloseModal,
    media,
    selectedMediaType,
    handleRemoveImg,
    handleSubmit,
    register,
    errors,
    widgetConfig,
    selectedAnimationSet,
    handleChangeAnimationAsset,
    animationSets,
    selectedShape,
    handleChangeShape,
    mediaOption,
    handleMediaOption,
    loading,
    onSubmitting,
  } = props;


  const { isEdit } = commonUtils.getQueryParams(location);
  const { t } = useTranslation(["common", "page-customization"]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
     *This method is used to toggle dropdown
     *
     */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
     * This method is used to toggle select link
     *
     * @param {*} type
     */
  const toggleSelect = (type) => {
    setLinkType(type);
    setDropdownOpen(false);
  };


  const getBreakPoints = (type) => {
    const breakPoints = {
      largeMediaUrl: "Large",
      defaultMediaUrl: "Medium",
      smallMediaUrl: "Small",
      mediaThumbnailUrl: "Extra small",
    };
    return breakPoints[type];
  };

  return (
    < >
      <Row>
        <Col xs="12">
          <Form onSubmit={handleSubmit(submitForm)}>
            <fieldset disabled={onSubmitting}>
              <Row className={clsx("common-page-card pageHeader", styles.title)}>
                <Col xs="12" md="3" lg="3">
                  <span className="pageText">
                    {t(`page-customization:${widgetType}`)}
                  </span>
                </Col>
                <Col xs="12" md="4" lg="5" />
                <Col xs="12" md="5" lg="4" className="buttonGroup">
                  <Row>
                    <Col xs="6">
                      <Button
                        className="buttonCancel"
                        onClick={() => handleRedirectToPageCustomization()}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col xs="6">
                      <Button type="submit" className="buttonSave bg-color-tertiary-main">
                        <span>{isEdit ? "Update" : "Save"}</span>
                        {
                          loading ? (
                            <Spinner size="sm" />
                          ) : ""
                        }
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="common-page-card">
                <Col xs="12">
                  <Row className={styles.subTitle}>
                    <Col xs="12">
                      <span>Content </span>
                    </Col>
                  </Row>
                  <hr />
                  <Row className={styles.block2}>
                    {widgetConfig
                      && widgetConfig.propertyConfig
                      && Object.keys(widgetConfig.propertyConfig).map((key) => {
                        const { propertyConfig } = widgetConfig;
                        const input = propertyConfig[key];
                        return (
                          <>
                            {propertyConfig[key].type === "TEXT" && (
                              <Col xs="12" md="6" className="d-flex align-items-center">
                                {input.hasRule ? (
                                  <FormGroup className="w-100">
                                    <Label className="formTextLabel" for="exampleEmail">
                                      {commonUtils.toSentenceCase(propertyConfig[key].label || key)}
                                      <span className="pl-1 text-danger">*</span>
                                    </Label>
                                    <Input
                                      name={key}
                                      defaultValue={content[key]}
                                      className="formText"
                                      placeholder={commonUtils.toSentenceCase(propertyConfig[key].label || key)}
                                      onChange={(e) => handleChange(key, e)}
                                      innerRef={
                                        register({
                                          required: "This field is required",
                                          maxLength: {
                                            value: input.maxSize,
                                            message: `Should haven't more than ${input.maxSize} `,
                                          },
                                        })
                                      }
                                      invalid={!!(errors[key] && errors[key].message)}
                                    />
                                    <FormFeedback>{errors[key] && errors[key].message}</FormFeedback>
                                  </FormGroup>
                                )
                                  : (
                                    <FormGroup className="w-100">
                                      <Label className="formTextLabel" for={key}>
                                        {commonUtils.toSentenceCase(propertyConfig[key].label || key)}
                                      </Label>
                                      <Input
                                        name={key}
                                        rows={4}
                                        value={content[key]}
                                        className="formText"
                                        placeholder={commonUtils.toSentenceCase(propertyConfig[key].label || key)}
                                        onChange={(e) => handleChange(key, e)}
                                      />

                                    </FormGroup>
                                  )}
                              </Col>
                            )}
                            {propertyConfig[key].type === "TEXT_AREA" && (
                              <Col xs="12" md="6" className="d-flex align-items-center">
                                {input.hasRule ? (
                                  <FormGroup className="w-100">
                                    <Label className="formTextLabel" for="exampleEmail">
                                      {commonUtils.toSentenceCase(key)}
                                      <span className="pl-1 text-danger">*</span>
                                    </Label>
                                    <Input
                                      type="textarea"
                                      rows={4}
                                      name={key}
                                      defaultValue={content[key]}
                                      className="formText"
                                      placeholder={commonUtils.toSentenceCase(key)}
                                      onChange={(e) => handleChange(key, e)}
                                      innerRef={
                                        register({
                                          required: "This field is required",
                                          maxLength: {
                                            value: input.maxSize,
                                            message: `Should haven't more than ${input.maxSize} `,
                                          },
                                        })
                                      }
                                      invalid={!!(errors[key] && errors[key].message)}
                                    />
                                    <FormFeedback>{errors[key] && errors[key].message}</FormFeedback>
                                  </FormGroup>
                                )
                                  : (
                                    <FormGroup className="w-100">
                                      <Label className="formTextLabel" for={key}>
                                        {commonUtils.toSentenceCase(key)}
                                      </Label>
                                      <Input
                                        type="textarea"
                                        name={key}
                                        defaultValue={content[key]}
                                        className="formText"
                                        placeholder={commonUtils.toSentenceCase(key)}
                                        onChange={(e) => handleChange(key, e)}
                                        innerRef={
                                          register({
                                            maxLength: {
                                              value: input.maxSize,
                                              message: `Should haven't more than ${input.maxSize} `,
                                            },
                                          })
                                        }
                                        invalid={!!(errors[key] && errors[key].message)}
                                      />
                                      <FormFeedback>
                                        {
                                          errors[key] && errors[key].message
                                        }

                                      </FormFeedback>
                                    </FormGroup>
                                  )}
                              </Col>
                            )}
                            {propertyConfig[key].type === "DROPDOWN" && (
                              <Col xs="12" md="6" className="d-flex align-items-center">
                                <FormGroup className="w-100">
                                  <Label className="formTextLabel" for="exampleEmail">Call to action</Label>
                                  <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropdown}
                                    className={clsx(styles.widgetContentEditorDropdownBtn, "commonDropDownWrapper")}
                                  >
                                    <DropdownToggle caret>
                                      <span>
                                        {linkType || t("page-customization:internal")}
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className={clsx(styles.widgetContentEditorDropdownMenu, "dropdownMenuWrapper")}
                                    >
                                      <div
                                        onKeyPress={() => { }}
                                        role="button"
                                        tabIndex="0"
                                        className="dropdownMenuItem"
                                        onClick={() => toggleSelect("Internal")}
                                      >
                                        Internal
                                      </div>
                                      <div
                                        onKeyPress={() => { }}
                                        role="button"
                                        tabIndex="0"
                                        className="dropdownMenuItem"
                                        onClick={() => toggleSelect("External")}
                                      >
                                        External
                                      </div>
                                    </DropdownMenu>
                                  </Dropdown>
                                </FormGroup>
                              </Col>
                            )}
                          </>
                        );
                      })}
                  </Row>
                  {widgetConfig && widgetConfig.contentForADateRange && (
                    <Row>
                      <Col xs="12" md="6" className="d-flex align-items-center">
                        <FormGroup className="w-100">
                          <Label className="formTextLabel" for="exampleEmail">Start date</Label>
                          <DatePicker
                            id="endate"
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                            input
                            inputProps={{
                              placeholder: `Ex: ${moment().format("DD/MM/YYYY")}`,
                              name: "startDate",
                            }}
                            value={content.startDate}
                            onChange={(o) => (moment.isMoment(o) && handleChange("startDate", o.toDate()))}
                            required
                          />
                          <FormFeedback>You will not be able to see this</FormFeedback>
                        </FormGroup>

                      </Col>
                      <Col xs="12" md="6" className="d-flex align-items-center">
                        <FormGroup className="w-100">
                          <Label className="formTextLabel" for="exampleEmail">End date</Label>
                          <DatePicker
                            id="endDate"
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect
                            input
                            inputProps={{
                              placeholder: `Ex: ${moment().format("DD/MM/YYYY")}`,
                              name: "endDate",
                            }}
                            value={content.endDate}
                            onChange={(o) => (moment.isMoment(o) && handleChange("endDate", o.toDate()))}
                            required
                          />
                          <FormFeedback>You will not be able to see this</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              {widgetConfig && widgetConfig.propertyConfig
                && (widgetConfig.propertyConfig.imageUrl || widgetConfig.propertyConfig.animationAsset)
                && (
                  <Row className={clsx(styles.block4, "common-page-card")}>
                    <Col xs="12">
                      <Row className={styles.subTitle}>
                        <Col xs="6">
                          <span>Media </span>
                        </Col>
                        <Col xs="6" className={styles.mediaError}>
                          {errors.media && errors.media.message
                            && (
                              <span className="d-flex align-items-center">
                                <img src={errorIcon} alt="error" />
                                <span>{errors.media.message}</span>
                              </span>
                            )}
                        </Col>
                      </Row>
                      <hr />
                      {widgetConfig.propertyConfig.animationAsset ? (
                        <AnimationAssetsEditor
                          content={content}
                          handleChangeFile={handleChangeFile}
                          modalOpen={modalOpen}
                          setModalOpen={setModalOpen}
                          handleUploadImage={handleUploadImage}
                          isUploading={isUploading}
                          setIsUploading={setIsUploading}
                          handleCloseModal={handleCloseModal}
                          media={media}
                          selectedMediaType={selectedMediaType}
                          handleRemoveImg={handleRemoveImg}
                          widgetConfig={widgetConfig}
                          selectedAnimationSet={selectedAnimationSet}
                          handleChangeAnimationAsset={handleChangeAnimationAsset}
                          animationSets={animationSets}
                          selectedShape={selectedShape}
                          handleChangeShape={handleChangeShape}
                          mediaOption={mediaOption}
                          handleMediaOption={handleMediaOption}
                          getBreakPoints={getBreakPoints}
                        />
                      )
                        : (
                          <>
                            {widgetConfig.propertyConfig.imageUrl
                              && (
                                <Row className={styles.block3}>
                                  {widgetConfig.propertyConfig.imageUrl && (
                                    <>
                                      {Object.keys(
                                        widgetConfig.propertyConfig.imageUrl.aspectRatio,
                                      ).map((type) => (
                                        <Col className="d-flex col-auto justify-content-start align-items-center">
                                          <FormGroup>
                                            <Label className="formTextLabel pt-1">{getBreakPoints(type)}</Label>
                                            <ImageSelection
                                              handleChangeFile={handleChangeFile}
                                              imageUrl={media[type]}
                                              modalOpen={modalOpen}
                                              type={type}
                                              setModalOpen={setModalOpen}
                                              handleUpload={handleUploadImage}
                                              imageUploadApi={uploadImage}
                                              isUploading={isUploading}
                                              setIsUploading={setIsUploading}
                                              handleCloseModal={handleCloseModal}
                                              handleRemoveImage={handleRemoveImg}
                                            />
                                          </FormGroup>
                                        </Col>
                                      ))}
                                      <ImageCropper
                                        imageUrl={content.imageUrl}
                                        modalOpen={modalOpen}
                                        aspect={
                                          getAspectRatio(
                                            widgetConfig.propertyConfig.imageUrl.aspectRatio,
                                            selectedMediaType,
                                          )
                                        }
                                        setModalOpen={setModalOpen}
                                        handleUpload={handleUploadImage}
                                        imageUploadApi={uploadImage}
                                        isUploading={isUploading}
                                        setIsUploading={setIsUploading}
                                        handleCloseModal={handleCloseModal}
                                      />
                                    </>
                                  )}
                                </Row>
                              )}
                          </>
                        )}
                    </Col>
                  </Row>
                )}
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  );
};


WidgetContentEditor.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  setIsUploading: PropTypes.func.isRequired,
  handleChangeFile: PropTypes.func.isRequired,
  linkType: PropTypes.string.isRequired,
  setLinkType: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  widgetType: PropTypes.string.isRequired,
  handleRedirectToPageCustomization: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  media: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedMediaType: PropTypes.string.isRequired,
  handleRemoveImg: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  animationSets: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedAnimationSet: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeAnimationAsset: PropTypes.func.isRequired,
  selectedShape: PropTypes.string.isRequired,
  handleChangeShape: PropTypes.func.isRequired,
  mediaOption: PropTypes.string.isRequired,
  handleMediaOption: PropTypes.func.isRequired,
};

export default WidgetContentEditor;
