/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import FormGroup from "../../../common/core/FormGroup";

import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import CustomInput from "../../../common/core/CustomInput";

/** ========= SUB COMPONENT ========= */

/** ========= CUSTOM COMPONENTS ========= */

/** ========= MODULE STYLES ========= */

const CouponForm = (props) => {
  const {
    usesPerCoupon, usesPerCustomer, validator,
    handleFormChange, handleChange, unlimited, onCampaign, translatableFileds, selectedLanguage,
  } = props;

  const { register, errors, clearError } = validator;

  useEffect(() => {
    if (unlimited) {
      clearError(["usesPerCoupon"]);
    }
  }, [unlimited, clearError]);

  /** internationalization using i18n from common and coupon  */
  const { t } = useTranslation(["common", "coupon"]);

  return (
    <>
      <Row>
        <Col>
          <span className="pageTitleText, fontsizeMediumBold ">{t("coupon:title_advanced_settings")}</span>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm={onCampaign ? "12" : "6"}>
          <FormGroup>
            <Label className="formTextLabel">{t("coupon:label_uses_per_coupon")}</Label>
            <Input
              min="1"
              type="number"
              className="formText"
              name="usesPerCoupon"
              value={usesPerCoupon}
              onChange={handleFormChange}
              innerRef={
                register({
                  validate: (value) => {
                    const x = parseInt(value, 10);
                    return (!unlimited && (Number.isNaN(x) || x < 1))
                      ? t("validation_is_number")
                      : true;
                  },
                })
              }
              invalid={!!(errors.usesPerCoupon && errors.usesPerCoupon.message)}
              disabled={unlimited || (translatableFileds && Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("usesPerCoupon") : false)}
            />
            <FormFeedback>{errors.usesPerCoupon && errors.usesPerCoupon.message}</FormFeedback>

          </FormGroup>
          <FormGroup>
            <CustomInput
              value={unlimited}
              type="checkbox"
              name="unlimited"
              id="unlimited"
              onClick={() => handleChange("unlimited", !unlimited)}
              label={t("coupon:label_unlimited")}
              checked={unlimited}
              disabled={Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("unlimited") : false}
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm={onCampaign ? "12" : "6"}>
          <FormGroup>
            <Label className="formTextLabel">{t("coupon:label_uses_per_customer")}</Label>
            <Input
              min="1"
              t
              ype="number"
              className="formText"
              name="usesPerCustomer"
              value={usesPerCustomer}
              onChange={handleFormChange}
              innerRef={
                register({
                  validate: (value) => {
                    const x = parseInt(value, 10);
                    return ((isNaN(x) || x < 1))
                      ? t("validation_is_number")
                      : true;
                  },
                })
              }
              invalid={!!(errors.usesPerCustomer && errors.usesPerCustomer.message)}
              disabled={Array.isArray(translatableFileds) ? selectedLanguage !== "en" && !translatableFileds.includes("usesPerCustomer") : false}
            />
            <FormFeedback>{errors.usesPerCustomer && errors.usesPerCustomer.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
CouponForm.propTypes = {
  usesPerCoupon: PropTypes.string.isRequired,
  usesPerCustomer: PropTypes.string.isRequired,
  unlimited: PropTypes.bool.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  /** form methods */
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default CouponForm;
