/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Price Book Management
 *
 * @author Amjad Rehman A
 *
 */
import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datetime";

import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/PriceBookManagement.module.css";
import CustomizationTable from "../../../common/components/Table";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Select from "../../../common/components/Select";
import FormFeedback from "../../../common/core/FormFeedback";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";

import LoaderIcon from "../../../common/assets/images/svg/loader.svg";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import RefreshIcon from "../../../common/assets/images/svg/refresh-circle.svg";

const customRows = [
  {
    label: "Price Book Id",
    value: "",
    path: "priceBookId",
    transform: "",
  },
  {
    label: "Product Id",
    value: "",
    path: "productId",
    transform: "",
  },
  {
    label: "List Price",
    value: "",
    path: "listPrice",
    transform: "",
    default: 0.0,
  },
  {
    label: "Recommended Retail Price",
    value: "",
    path: "recommendedRetailPrice",
    transform: "",
    default: 0.0,
  },
  {
    label: "Sales company",
    value: "",
    path: "salesCompany",
    transform: "",
    default: 0.0,
  },
  {
    label: "Updated Date",
    value: "",
    path: "updatedDate",
    transform: "",
    type: "date",
  },
  {
    label: "Start Date",
    value: "",
    path: "startDate",
    transform: "",
    type: "date",
  },
  {
    label: "End Date",
    value: "",
    path: "endDate",
    transform: "",
    type: "date",
  },
];

const PriceBookManagement = (props) => {
  const {
    priceBookList,
    priceTableData,
    page,
    hasMoreData,
    searchTerm,
    onHandleClear,
    date,
    priceBookId,
    handleChange,
    onHandleExport,
    isProccessing,
    handlePushToLive,
    pushToLiveEnabled,
    previewSiteUrl,
    previewEnabled,
    message,
    getReplicationStatus,
    pushToLiveButtonEnabled,
    isLoading,
    salesCompany,
    salesCompanyId,
  } = props;

  const { t } = useTranslation(["common", "price-book-management"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  /**
           *This method is used to toogle dropdown
           *
           */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card ">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">
            {t("price-book-management:price_book_management_title")}
          </span>
        </Col>
        <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
          <Row>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                  onClick={() => redirectToPage()}
                >
                  {t("common:button_preview")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block1, " common-page-card ")}>
        <Col xs="12">
          <Row className={clsx(styles.areaActionRight, "m-0 pt-3")}>
            <Col className={clsx(styles.searchContainer)} xs="auto">
              <span
                onClick={() => onHandleClear()}
                className="commonPointer"
                title="Refresh"
                role="presentation"
              >
                <Button color="light">
                  <img
                    width="12"
                    src={RefreshIcon}
                    alt="search category"
                    className="mr-2"
                  />
                  <span>Reset</span>
                </Button>
              </span>
            </Col>
            <Col xs="3" className="py-0 pl-0 pr-2">
              <FormGroup>
                <Label className="formTextLabel">
                  Sales Company
                </Label>
                <Select
                  className="formTextLabel"
                  placeholder="Sales Company"
                  name="salesCompany"
                  value={salesCompanyId}
                  onChange={(e) => handleChange("salesCompany", e.target.value)}
                  options={salesCompany}
                />
              </FormGroup>
            </Col>
            <Col xs="2" className="py-0 pl-0 pr-2">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("price-book-management:label_price_book_id")}
                </Label>
                <Select
                  className="formTextLabel"
                  placeholder={t(
                    "price-book-management:placeholder_select_price_book_id",
                  )}
                  name="status"
                  value={priceBookId}
                  onChange={(e) => handleChange("priceBookId", e.target.value)}
                  options={[
                    { value: "", disabled: !!priceBookId, label: "Select Pricebook" },
                    ...(Array.isArray(priceBookList) ? priceBookList : []),
                  ]}
                >
                  <option value="" hidden>Select Pricebook</option>
                </Select>
              </FormGroup>
            </Col>
            <Col xs="2" className="py-0 pl-0 pr-2">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">
                  {t("price-book-management:label_date")}
                </Label>
                <DatePicker
                  id="date"
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  closeOnSelect
                  defaultValue={date || ""}
                  input
                  selected={date || ""}
                  name="date"
                  value={date}
                  onChange={(e) => handleChange("date", e)}
                  inputProps={{
                    placeholder: t("common:placeholder_date_example"),
                  }}
                />
                <FormFeedback>
                  {t("price-book-management:label_invalid_date_msg")}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="auto" className="py-0 pl-0 pr-2">
              <div className={styles.areaActionRight}>
                <div>
                  <Label className="formTextLabel" for="exampleEmail">
                    {t("price-book-management:label_search")}
                  </Label>
                  <InputGroup
                    className={clsx(styles.search, "color-tertiary-main")}
                  >
                    <Input
                      placeholder={t(
                        "price-book-management:placeholder_search",
                      )}
                      value={searchTerm}
                      onChange={(e) => handleChange("searchTerm", e.target.value)}
                    />
                    <InputGroupAddon
                      addonType="append"
                      className="commonPointer bg-color-tertiary-main"
                      onClick={() => handleChange("page", 1)}
                      title="Search"
                    >
                      <img
                        src={SearchIcon}
                        alt="search category"
                        className={styles.icon}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, " common-page-card ")}>
        <Col xs="12">
          <Row className={clsx(styles.block2, "pb-2")}>
            <Col xs="12">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className={clsx(styles.dropdownBtn, "commonDropDownWrapper")}
              >
                <DropdownToggle caret disabled={isProccessing || priceTableData.length < 1}>
                  <span>{t("common:span_export_as")}</span>
                  {isProccessing ? (
                    <img
                      src={LoaderIcon}
                      width="22"
                      alt="Inventory file downloading"
                      className={styles.icon}
                    />
                  ) : false}
                </DropdownToggle>
                {!isProccessing
                  ? (
                    <DropdownMenu right className="dropdownMenuWrapper">
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        className="dropdownMenuItem"
                        onClick={() => onHandleExport("XLSX")}
                      >
                        {t("common:div_export_format_XLSX")}
                      </div>
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        className="dropdownMenuItem"
                        onClick={() => onHandleExport("CSV")}
                      >
                        {t("common:div_item_export_format_CSV")}
                      </div>
                    </DropdownMenu>
                  )
                  : null}
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="table-responsive">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={priceTableData}
                  isStatusEnable={false}
                  isDeleteEnable={false}
                  isEditEnable={false}
                  isLoading={isLoading}
                />
              </div>
              <div
                className={clsx(
                  styles.pagination,
                  "p-3",
                  "d-flex",
                  "justify-content-end",
                )}
              >
                <div
                  className={clsx(
                    styles.pagination,
                    "p-3",
                    "d-flex",
                    "justify-content-end",
                  )}
                >
                  <div className="d-flex">
                    <span
                      disabled={page <= 1}
                      className={clsx(styles.item, styles.arrowLeft)}
                      tabIndex={0}
                      role="button"
                      onKeyPress={() => { }}
                      onClick={() => page > 1 && handleChange("page", page - 1)}
                    >
                      <span />
                    </span>
                    <span className={styles.item} />
                    <span
                      disabled={!hasMoreData}
                      className={clsx(styles.item, styles.arrowRight)}
                      tabIndex={0}
                      role="button"
                      onKeyPress={() => { }}
                      onClick={() => hasMoreData && handleChange("page", page + 1)}
                    >
                      <span />
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
PriceBookManagement.propTypes = {
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  priceBookList: PropTypes.func.isRequired,
  priceTableData: PropTypes.func.isRequired,
  searchTerm: PropTypes.func.isRequired,
  onHandleClear: PropTypes.func.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  priceBookId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onHandleExport: PropTypes.func.isRequired,
  isProccessing: PropTypes.bool.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  salesCompanyId: PropTypes.string.isRequired,
  salesCompany: PropTypes.arrayOf(PropTypes.any).isRequired,
};


export default PriceBookManagement;
