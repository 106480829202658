/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * ForgotPasswordHelper
 *
 * @author Harikrishnan A K
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
/** ===== TAG COMPONENTS =========== */
// import { withTranslation } from "@i18n/api";
import ValidationConstants from "../../../common/utils/validationConstants";
import validateForm from "../../../common/utils/validateForm";
import Input from "../../../common/core/Input";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import Button from "../../../common/core/Button";
import Label from "../../../common/core/Label";
// import FormFeedback from "../../../common/core/FormFeedback";
import Form from "../../../common/core/Form";
import Container from "../../../common/core/Container";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";


const ForgotPasswordHelper = ({
  modal,
  toggle,
  handleChangeForgotPassword,
  handleForgotPasswordSubmit,
  forgotPasswordForm,
  userNameValidation,
}) => {
  const { t } = useTranslation(["login"]);
  const methods = validateForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { handleSubmit, register, errors } = methods;
  const { ACE_EMAIL_VALIDATION } = ValidationConstants;

  return (
    <Modal isOpen={modal} toggle={toggle} centered scrollable>
      <Form onSubmit={handleSubmit(handleForgotPasswordSubmit)}>
        <ModalHeader>
          {t("login:forgot_your_password")}
        </ModalHeader>
        <Button color="close-modal" onClick={() => toggle(false)}>
          <i className="icon-close" />
        </Button>
        <ModalBody>
          <Container>
            <Row className="justify-content-center my-4">
              <Col md="8">
                <p className="text-center">{t("login:enter_mail_message")}</p>
                <Row className="mt-3">
                  <Col>
                    <Label>
                      {t("login:label_email")}
                      {" "}
                      <i>*</i>
                    </Label>
                    <Input
                      type="username"
                      id="username"
                      value={forgotPasswordForm.username}
                      onChange={handleChangeForgotPassword}
                      innerRef={
                        register({
                          required: t("login:enter_your_email"),
                          pattern: {
                            value: ACE_EMAIL_VALIDATION,
                            message: t("login:invalid_email_format"),
                          },
                        })
                      }
                      name="username"
                      invalid={errors.username && errors.username.message}
                      valid={forgotPasswordForm.username && !errors.username}
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          {userNameValidation ? (
            <Row className="pb-2 justify-content-center align-items-center text-danger">
              {t("login:invalid_email")}
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row className="justify-content-center">
              <Col md="auto">
                <Button type="submit" color="primary" size="lg" block>{t("login:reset_link")}</Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

ForgotPasswordHelper.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleChangeForgotPassword: PropTypes.number.isRequired,
  handleForgotPasswordSubmit: PropTypes.func.isRequired,
  forgotPasswordForm: PropTypes.objectOf(PropTypes.any).isRequired,
  userNameValidation: PropTypes.bool.isRequired,
};

export default ForgotPasswordHelper;
