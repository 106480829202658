/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * CustomerData Management Container
 *
 * @author Harikrishnan A K
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import XLSX from "xlsx";
import {
  getB2BCustomers, enableB2BCustomer, disableB2BCustomer, transformCustomerData, initiateResetPassword, getB2BCustomersWithSearchAndPagination
} from "../../../api/customerDataService";
import CustomerDataManagement from "../components/CustomerDataManagement";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const B2BCustomerDataManagementContainer = () => {
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isProccessing, setIsProccessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  /**
  * This method is used to list B2B customers
  * @param {String} searchTerm
  * @param {Number} newPage
  * @param {Number} itemsPerPage
  * @param {String} sortBy
  * @param {String} sortDirection
  */

  const getB2bCustomerList = (searchTerm = "", newPage = 0, paginationLimitIn = itemsPerPage) => {
    const searchKey = searchTerm.trim();
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getB2BCustomersWithSearchAndPagination(searchKey, newPage, paginationLimitIn, cancelToken).then((response) => {
      if (response && response.success) {
        const { content, endOfResult, totalPages } = response.data || {};
        if (Array.isArray(content)) {
          setCustomers(content);
          setHasMoreData(!endOfResult);
          setTotalPagesCount(totalPages);
        } else {
          newPage = page;
          setHasMoreData(false);
          setCustomers([]);
        }
        setPage(Math.max(newPage, 0));
      }
      setIsLoading(false);
    });
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    getB2bCustomerList(searchTerm, Math.max(page, 0), value);
  }

  /**
    * This method is used to trigger pagination
    * @param {Number} page
    */
  const getPageData = (direction) => {
    const newPage = direction ? page + direction : 0;
    getB2bCustomerList(searchTerm, Math.max(newPage, 0), itemsPerPage);
  };

  /**
    * This method is used to handle clicks on react-paginate component
    * @param {data object with selected key which is 1 less than selected page label}
    */
  const onPageChange = (data) => {
    const { selected } = data;
    getB2bCustomerList(searchTerm, selected, itemsPerPage);
  }



  /**
   * This method is used to redirect to edit customer page
   * @param {String} couponId
   */
  const editCustomer = (customerId) => {
    history.push(`/administration/b2bcustomer/edit/${customerId}`);
  };

  /**
  * This method is used to redirect to view customer page
  * @param {String} customerId
  */
  const viewCustomer = (customerId) => {
    history.push(`/administration/b2bcustomer/view/${customerId}`);
  };

  /** getting list of customers when page load */
  useEffect(() => {
    getB2bCustomerList();
  }, []);

  /**
      * This method is used to update text in search box
      * @param {Event} event
      */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getB2bCustomerList(value); } else getB2bCustomerList();
  };


  const onHandleExport = async (type) => {
    setIsProccessing(true);
    const page = 0;
    const size = -1;
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getB2BCustomers(searchTerm, page, size, cancelToken)
      .then(async (response) => {
        if (response && response.success === true) {
          const {
            data,
          } = response;
          if (Array.isArray(data)) {
            const customers = await transformCustomerData(data);
            let fileName = "Customers.xlsx";
            if (type === "CSV") {
              fileName = "Customers.csv";
            }
            const ws = XLSX.utils.json_to_sheet(customers);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Customers");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 15 },
              { wch: 30 },
              { wch: 15 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch((err) => {
        setIsProccessing(false);
      });
    setIsProccessing(false);
  };
  const [resetPasswordRequest, setResetPassword] = useState([]);
  const handleResetPassword = (row) => {
    setLoading([...loading, row.id]);
    setResetPassword([row.firstName, ...resetPasswordRequest]);
    initiateResetPassword().then((response) => {
      const filterLoading = loading.filter((each) => each.id !== row.id);
      setLoading(filterLoading);
      if (response && response.success) {
        if (Array.isArray(response.data)) {
          setResetPassword(response.data);
        }
      }
    });
  };

  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  /**
   * This method is used to check whether customer can be activated or not
   */
  const canActivate = (customer) => {
    if (customer
      && customer.classification
      && customer.priceList
      && ((customer.paymentMethods && customer.paymentMethods.length > 0) || customer.paymentMethod)
      && customer.shippingAddress && customer.shippingAddress[0] && customer.shippingAddress[0].city
      && customer?.address?.contactEmail
    ) {
      return { success: true };
    }
    return {
      success: false,
      message: "Email, pricelist, classification, payment method and address are mandatory for customer activation",
      type: "warning",
    };
  };

  /**
   * This method used to change B2B Customer status
   * @param {Object} customerDetails
   * @param {String} customerId
   * @param {Boolean} status
   */
  const handleB2BCustomerEnableStatus = async (customerDetails, customerId, status) => {
    const data = canActivate(customerDetails);
    if (!status || (data && data.success)) {
      if (submitting) return;
      setSubmitting(true);
      let response = null;
      if (status === true) {
        response = await enableB2BCustomer(customerId);
      } else {
        response = await disableB2BCustomer(customerId);
      }
      if (response && response.success) {
        const newCustomers = customers.map((customer) => (customer.id === customerId
          ? ({ ...customer, active: status }) : customer));
        setCustomers(newCustomers);
        /** should add a status change toast once the toast functionality implemented */
      }
      setSubmitting(false);
    } else {
      setAlert(data);
    }
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_VIEW_LIST}
    >
      <CustomerDataManagement
        customers={customers}
        editCustomer={editCustomer}
        viewCustomer={viewCustomer}
        handleB2BCustomerEnableStatus={handleB2BCustomerEnableStatus}
        page={page}
        hasMoreData={hasMoreData}
        searchTerm={searchTerm}
        getPageData={getPageData}
        onPageChange={onPageChange}
        handleSearchTerm={handleSearchTerm}
        onHandleExport={onHandleExport}
        isProccessing={isProccessing}
        handleResetPassword={handleResetPassword}
        resetPasswordRequest={resetPasswordRequest}
        loading={loading}
        message={message}
        handleItemsPerPageChange={handleItemsPerPageChange}
        totalPagesCount={totalPagesCount}
        itemsPerPage={itemsPerPage}
        isLoading={isLoading}
      />
    </Permission>
  );
};

export default B2BCustomerDataManagementContainer;
