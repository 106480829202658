/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 * */

import httpApi from "./httpApi";

/**
 * This method is used to transform Customers data
 * @param customerId String
 * @param status Boolean
 * @returns {Array}
 */
// functionn needs modification accoring to backend API
const setB2BCustomerStatus = async (customerId, status) => ({
  success: true,
});

/**
 * This method is used to transform Customers data
 * @param Array
 * @returns {Array}
 */
const transformCustomerData = async (customers) => {
  const filtered = customers.map(
    ({ firstName, lastName, email, dialCode, mobile }) => ({
      "First Name": firstName,
      "Last Name": lastName,
      Email: email,
      "Phone No": `${dialCode || ""}${mobile || ""}`,
    })
  );
  return filtered;
};
const initiateResetPassword = async (
  resetPasswordRequest,
  username,
  password,
  token,
  email,
  firstName,
  lastName,
  mobile
) => {
  const response = await httpApi.put("/v1/users/password-reset", {
    resetPasswordRequest,
    username,
    password,
    token,
    firstName,
    lastName,
    mobile,
    email,
  });
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get b2bCustomer details
 * @param {String}
 * @returns {Object}
 */
const getB2BCustomers = async (searchTerm, page, size, cancelToken) => {
  const response = await httpApi.get("/v1/b2b/customer/all", cancelToken);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return B2B Customer list with search and pagination
 * @returns {Array}
 */
const getB2BCustomersWithSearchAndPagination = async (
  searchTerm = "",
  page = 0,
  size = 10,
  cancelToken,
  searchable,
) => {
  const response = await httpApi.get("/v1/b2b/customer/search", {
    params: {
      searchTerm,
      page,
      size,
      searchable,
    },
    cancelToken,
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to enable b2bCustomer
 * @param {String}
 * @returns {Object}
 */
const enableB2BCustomer = async (customerId) => {
  const response = await httpApi.patch(
    `/v1/b2b/customer/id/${customerId}/activate`
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to disable b2bCustomer
 * @param {String}
 * @returns {Object}
 */
const disableB2BCustomer = async (customerId) => {
  const response = await httpApi.patch(
    `/v1/b2b/customer/id/${customerId}/deactivate`
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to enable b2bDepartment
 * @param {String}
 * @returns {Object}
 */
const enableB2BDepartment = async (b2bDepartment) => {
  const response = await httpApi.patch(
    `/v1/b2b/department/id/${b2bDepartment}/activate`
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to disable b2bDepartment
 * @param {String}
 * @returns {Object}
 */
const disableB2BDepartment = async (b2bDepartment) => {
  const response = await httpApi.patch(
    `/v1/b2b/department/id/${b2bDepartment}/deactivate`
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  transformCustomerData,
  initiateResetPassword,
  setB2BCustomerStatus,
  getB2BCustomers,
  enableB2BCustomer,
  disableB2BCustomer,
  getB2BCustomersWithSearchAndPagination,
  enableB2BDepartment,
  disableB2BDepartment,
};
