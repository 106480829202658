/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima Mohan
 */
import httpApi from "./httpApi";

/**
 * This method is used  list out Promotions
 * @param {Object} data
 */

const listPromotions = async (searchTerm = "", page = 0, size = 10, sort = "createdDate", sortDirection = "desc", cancelToken, salesCompany) => {
  const response = await httpApi.get("v1/promotions", {
    params: {
      searchTerm,
      page,
      size,
      sort,
      sortDirection,
      salesCompany,
    },
    cancelToken,
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method used to change status of a promotion
 * @param {String} promotionId
 */
const setStatus = async (promotionId) => {
  const response = await httpApi.patch(`v1/promotions/${promotionId}/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete a promotion
 * @param {String} promotionId
 */
const deletePromotion = async (promotionId) => {
  const response = await httpApi.delete(`v1/promotions/id/${promotionId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const updatePromotion = async (data) => {
  const response = await httpApi.patch("v1/promotions", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getPromotion = async (promotionId, locale) => {
  const params = { locale };
  const response = await httpApi.get(`v1/promotions/id/${promotionId}`, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get class types
 * @param {String} classType
 */
const getDiscountType = async (promotionClass) => {
  const response = await httpApi.get("v1/promotions/templates/class", { params: { promotionClass } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get discount types
 *
 */
const getClassType = async () => {
  const response = await httpApi.get("v1/promotions/classes");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to return discount Strategies
 */
const getDiscountStrategies = async (promotionTemplate, promotionClass) => {
  const response = await httpApi.get("v1/promotions/discount-strategies/template", { params: { promotionTemplate, promotionClass } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to return combine with values
 */
const getCombineWith = async () => {
  const response = await httpApi.get("v1/promotions/promotion-combineWith");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to add new promotion
 * @param {Object} data
 */
const addPromotion = async (data) => {
  const response = await httpApi.post("v1/promotions", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method used to retrieve channel group  by location
 */
const getChannelGroupsByLocation = async () => {
  const response = await httpApi.get("/v1/organization/locations/channel-groups");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  listPromotions,
  setStatus,
  deletePromotion,
  updatePromotion,
  getPromotion,
  getClassType,
  getDiscountType,
  getDiscountStrategies,
  addPromotion,
  getCombineWith,
  getChannelGroupsByLocation,
};
