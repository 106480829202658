/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Table from "../../../common/core/Table";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Spinner from "../../../common/core/Spinner";
/** ====== API SERVICE ============= */


/** ========= SVG IMAGE ICON ============= */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

/** ================== MODULE STYLES ================ */
import styles from "../css/CustomerGroupManagement.module.css";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import FormGroup from "../../../common/core/FormGroup";
import Select from "../../../common/components/Select";

const CustomerGroupManagement = (props) => {
  const {
    addNewCustomerGroup, editCustomerGroup, customerGroups, changeCustomerGroupStatus, submitting,
    getPageData, page, hasMoreData,
    searchTerm, handleSearchTerm, setDeleteCustomerGroup, isLoading, salesCompanyList,
    salesCompany, handleChange,
  } = props;
  const { t } = useTranslation(["customerGroup"]);

  const currentRow = useRef(null);

  const deleteCustomerGroup = (id) => {
    if (currentRow && currentRow.current) {
      setDeleteCustomerGroup({ id, element: currentRow.current });
    }
  };

  return (
    <Row>
      <Col xs="12">
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="7" className="text-truncate">
            <span className="pageText">{t("customerGroup:title_customer_groups")}</span>
          </Col>
        </Row>
      </Col>
      <Col xs="12">
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col className="mb-2" xs="12" sm="12" md="4">
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {t("customerGroup:title_manage_customer_groups")}
            </span>
          </Col>
          <Col xs="8" className="p-0">
            <div className={styles.areaActionRight}>
              <div className="px-2">
                <FormGroup className="mb-0">
                  <Select
                    className="formText"
                    placeholder="Salescompany"
                    name={salesCompany}
                    id={salesCompany}
                    value={salesCompany}
                    onChange={(e) => handleChange(e.target.value)}
                    options={[
                      {
                        value: "", disabled: true, selected: true, label: "Select Sales Company",
                      },
                      ...(
                        Array.isArray(salesCompanyList) ? salesCompanyList.map((item) => ({ value: item.uniqueId, label: `${item.uniqueId}` })) : []
                      ),
                    ]}
                  />
                </FormGroup>
              </div>
              <div>
                <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                  <Input placeholder={t("customerGroup:search_placeholder")} value={searchTerm} onChange={handleSearchTerm} />
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer bg-color-tertiary-main"
                  >
                    <img
                      src={SearchIcon}
                      alt={t("customerGroup:search_placeholder")}
                      className={styles.icon}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </div>
              {salesCompany
                ? (
                  <>
                    <div>
                      <Button
                        color="primary"
                        size="sm"
                        className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary")}
                        onClick={addNewCustomerGroup}
                      >
                        {t("customerGroup:title_add_group")}
                      </Button>
                    </div>
                  </>
                )
                : ""}
            </div>
          </Col>
        </Row>
        <Row className={clsx(styles.block3, styles.tableWrapper, "common-page-card")}>
          <Col>
            {isLoading ? (
              <Row className="h-100">
                <Col
                  xs="12"
                  className={styles.spinnerWrapper}
                >
                  <div
                    className="h-100  d-flex align-items-center justify-content-center"
                  >
                    <Spinner />
                  </div>
                </Col>
              </Row>
            )
              : (
                <>
                  <div className={styles.campaignTree}>
                    {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
                    <div
                      className={clsx(styles.promotionBody, "table-responsive")}
                    >
                      <Table borderless className={styles.CustomerGroupTable}>
                        {/* ========= TABLE HEAD S[0003] ======================= */}
                        <thead>
                          <tr>
                            <th>
                              {t("customerGroup:table_title_id")}
                            </th>
                            <th>
                              {t("customerGroup:table_title_group")}
                            </th>
                            <th>
                              {t("customerGroup:table_title_description")}
                            </th>
                            <th>
                              {t("customerGroup:table_title_type")}
                            </th>
                            <Permission
                              allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_UPD_CSTMR_GRP}
                            >
                              <th>
                                {t("customerGroup:table_title_status")}
                              </th>
                            </Permission>
                            <Permission
                              allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_UPD_CSTMR_GRP}
                            >
                              <th>
                                {t("customerGroup:table_title_edit")}
                              </th>
                            </Permission>
                            <Permission
                              allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_DLT_CSTMR_GRP}
                            >
                              <th>
                                {t("customerGroup:table_title_delete")}
                              </th>
                            </Permission>
                          </tr>
                        </thead>
                        {/* ========= TABLE HEAD S[0003] ======================= */}
                        <tbody>
                          {/* ========= TABLE BODY S[0002] ======================= */}
                          {
                            customerGroups.length > 0
                              ? customerGroups.map(({
                                id, name, description, type, active, enabled,
                              }) => (

                                <tr key={id} ref={currentRow}>
                                  <td>
                                    <span id={id} className={clsx(styles.CustomerGroupName, "text-truncate", "d-block")}>
                                      {id}
                                    </span>
                                  </td>
                                  <td>{name}</td>
                                  <td>{description}</td>
                                  <td>{type}</td>
                                  <Permission
                                    allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_UPD_CSTMR_GRP}
                                  >
                                    <td>
                                      <div
                                        className={clsx(
                                          "custom-control",
                                          "custom-switch",
                                          submitting ? "loading" : "",
                                        )}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`expand-multiple${id}`}
                                          checked={active}
                                          onChange={(o) => changeCustomerGroupStatus(!active, id, o)}
                                        />
                                        {/* added input tag for removing ESLint error > if added above input tag inside label it wont work */}
                                        <label className="custom-control-label" htmlFor={`expand-multiple${id}`}>
                                          <input type="hidden" />
                                        </label>
                                      </div>
                                    </td>
                                  </Permission>
                                  <Permission
                                    allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_UPD_CSTMR_GRP}
                                  >
                                    <td>
                                      <span
                                        onClick={(o) => editCustomerGroup(id, o)}
                                        onKeyDown={() => { }}
                                        tabIndex={0}
                                        role="button"
                                        className="commonPointer"
                                      >
                                        <img
                                          className={clsx("commonPointer", styles.icon)}
                                          src={EditIcon}
                                          alt="Edit"
                                        />
                                      </span>
                                    </td>
                                  </Permission>
                                  <Permission
                                    allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_DLT_CSTMR_GRP}
                                  >
                                    <td>
                                      <span className="commonPointer" onClick={(o) => deleteCustomerGroup(id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                                        <img
                                          className={styles.icon}
                                          src={DeleteIcon}
                                          alt="Delete"
                                        />
                                      </span>

                                    </td>
                                  </Permission>
                                  {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                                </tr>
                              )) : (
                                <tr>
                                  <td colSpan={8} align="center" valign="center"> Nothing to show.</td>
                                </tr>
                              )
                          }
                          {/* ========= TABLE BODY E[0002] ======================= */}
                        </tbody>
                      </Table>
                    </div>
                    <div className={clsx(styles.pagination, "p-3", "d-flex", "justify-content-end")}>
                      <div className="d-flex">
                        <span disabled={page <= 0} className={clsx(styles.item, styles.arrowLeft)} tabIndex={0} role="button" onKeyPress={() => { }} onClick={() => page > 0 && getPageData(page - 1)}>
                          <span />
                        </span>
                        <span className={styles.item} />
                        <span disabled={!hasMoreData} className={clsx(styles.item, styles.arrowRight)} tabIndex={0} role="button" onKeyPress={() => { }} onClick={(o) => hasMoreData && getPageData(page + 1)}>
                          <span />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
CustomerGroupManagement.propTypes = {
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  addNewCustomerGroup: PropTypes.func.isRequired,
  getPageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  changeCustomerGroupStatus: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  editCustomerGroup: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setDeleteCustomerGroup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  salesCompany: PropTypes.string.isRequired,
  salesCompanyList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CustomerGroupManagement;
