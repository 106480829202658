/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */

import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
/** ===== IMAGE ICONS =========== */
import Home from "../../../common/assets/images/svg/home.svg";
/** ====== COMMON COMPONENTS ============= */
import CustomizationTableNew from "../../../common/components/CustomTable";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Col from "../../../common/core/Col";
import Dropdown from "../../../common/core/Dropdown";
import DropdownItem from "../../../common/core/DropdownItem";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
/** ================== MODULE STYLES ================ */
import styles from "../css/SalesRepsManagement.module.css";
import EyeDark from "../../../common/assets/images/svg/eye-dark.svg";
import Button from "../../../common/core/Button";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import SearchDark from "../../../common/assets/images/svg/search-dark.svg";

const SalesRepresentativeView = ({
  salesRepInfo, handleChangeSalesRepStatus, viewSalesRepsInfo, totalPagesCount,
  itemsPerPage,
  onPageChange,
  handleItemsPerPageChange, searchTerm,
  handleSearchTerm, isLoading,
}) => {
  const { t } = useTranslation(["customers", "b2bcustomer", "common"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const getFullName = (row) => `${row.fullName ? row.fullName : `${row.firstName || ""} ${row.lastName || ""}`}`;
  const salesRepCustomRows = [
    {
      label: "Name",
      path: "render",
      render: getFullName,
    },
    {
      label: "Email",
      value: "",
      path: "email",
      transform: "",
    },
    {
      label: "Contact Number",
      value: "",
      path: "mobile",
      transform: "",
    },
    {
      label: "View",
      value: "",
      path: "render",
      render: (row) => (
        <div className="d-flex justify-content-start">
          <span
            onKeyDown={() => { }}
            onClick={(o) => viewSalesRepsInfo(row.id, o)}
            tabIndex={0}
            role="button"
            className="commonPointer"
          >
            <Button color="transparent" className="btn-square">
              <img src={EyeDark} alt="Home" />
            </Button>
          </span>
        </div>
      ),
    },
    {
      label: "Enable",
      value: "",
      path: "render",
      render: (row) => (
        <>
          <div
            className={clsx(
              "custom-control",
              "custom-switch",
              "ml-1",
            )}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`salesRep${row.id}`}
              name="enableSalesRep"
              checked={row.active}
              onChange={(o) => { handleChangeSalesRepStatus(!row.active, row.id, o); }}
            />
            <Label className="custom-control-label" htmlFor={`salesRep${row.id}`} />
          </div>
        </>
      ),
    },

  ];
  return (
    <>
      <Row>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7" className="text-truncate">
              <span className="pageText">
                <strong>
                  {t("b2bcustomer:label_sales_rep_details")}
                </strong>
              </span>
            </Col>
            <Col xs="12">
              <Breadcrumb className="mt-2">
                <BreadcrumbItem>
                  <a href="/dashboard">
                    <img src={Home} alt="Home" />
                    {t("common:home")}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="/administration">
                    {t("common:label_administration")}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem><a href="/administration">{t("common:label_userManagement")}</a></BreadcrumbItem>
                <BreadcrumbItem active>{t("b2bcustomer:label_SalesReps_title")}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </Col>
        <Col xs="12">
          <Row className={clsx(styles.block2, "common-page-card")}>

            <Col xs="12">
              <Row className="justify-content-between align-items-center mb-2">
                <Col xs="auto">
                  <h5 className="mb-0"><strong>{t("b2bcustomer:label_SalesReps_manage")}</strong></h5>
                </Col>
                <Col xs="auto">
                  <div className={styles.SearchCol}>
                    <Input
                      placeholder="Search sales rep"
                      value={searchTerm}
                      onChange={handleSearchTerm}
                    />
                    <Button><img src={SearchDark} alt="Home" /></Button>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs="12">
                  <CustomizationTableNew
                    customRows={salesRepCustomRows}
                    customizationData={salesRepInfo}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col xs="auto" className="d-flex align-items-center">
                  Show
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="dropdown-custom dropdown-number mx-2"
                  >
                    <DropdownToggle caret color="light" size="sm">
                      {itemsPerPage}
                    </DropdownToggle>
                    <DropdownMenu down>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(10)}
                        onKeyPress={() => { }}
                      >
                        10
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(15)}
                      >
                        15
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(20)}
                        onKeyPress={() => { }}
                      >
                        20
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  entries
                </Col>
                <Col xs="auto">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPagesCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={onPageChange}
                    containerClassName="pagination"
                    pageClassName=""
                    pageLinkClassName="pageLink"
                    activeClassName="activePage"
                    initialPage={0}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

SalesRepresentativeView.propTypes = {
  salesRepInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  viewSalesRepsInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeSalesRepStatus: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPagesCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default SalesRepresentativeView;
