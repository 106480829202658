/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Footer Container
 *
 * @author Naseef O
 *
 */

import React from "react";
import Footer from "../components/Footer";

const FooterContainer = () => {
  const getCurrentYear = () => new Date().getFullYear();
  return (
    <Footer
      getCurrentYear={getCurrentYear}
    />
  );
};
export default FooterContainer;
