/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAllBrands } from "../../../api/brandServices";
import { getCategoryByIds } from "../../../api/categoryManagementServices";
import { getPageContentByTypeAndConfigId, updatePageContent } from "../../../api/pageContentServices";
import { getProductByIds, getProducts } from "../../../api/productManagementServices";
import { getWidgetConfigurationByWidgetType } from "../../../api/widgetConfigurationsServices";
import commonUtils from "../../../common/utils/commonUtils";
import CombinedWidgetEditor from "../components/CombinedWidgetEditor";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const CombinedWidgetEditorContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    widgetType,
    configId,
    categoryId,
    pageType,
    channel,
    locale,
  } = commonUtils.getQueryParams(location);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [combinedContents, setCombinedContents] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContentType, setContentType] = useState(null);
  const [widgetContent, setPageContent] = useState(null);
  const [widgetConfig, setWidgetConfig] = useState(null);
  const [error, setError] = useState({ message: "" });
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [headerData, setHeaderData] = useState({
    header: "",
    landingPageBaseUrl: "",
  });
  const [loading, setLoading] = useState(false);
  const [onSubmitting, setOnSubmitting] = useState(false);
  /**
   *This method is used to toggle dropdown
   *
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
   * This method is used to get selected contents
   *
   * @param {*} data
   * @returns
   */
  const getSelectedContents = async (data) => {
    const selectedData = [];
    if (commonUtils.isListNotEmpty(data.contents)) {
      const [content] = data.contents;
      setHeaderData({
        header: content.header,
        landingPageBaseUrl: content.link.url,
      });
    }
    if (data && data.contentIds && data.contentIds.length > 0) {
      const { contentIds } = data;
      if (data.contentType === "Category" || data.contentType === "Brand") {
        await getCategoryByIds(contentIds).then((response) => {
          if (response && response.success && response.data) {
            const { data: categories } = response;
            if (Array.isArray(contentIds)) {
              categories.map((category) => {
                contentIds.map((item) => {
                  if (category.id === item) {
                    selectedData.push(category);
                  }
                  return null;
                });
                return null;
              });
            }
          }
        });
      }
      if (data.contentType === "Product") {
        await getProductByIds(contentIds).then((response) => {
          if (response && response.success && response.data) {
            response.data.map((product) => {
              contentIds.map((item) => {
                if (product.id === item) {
                  selectedData.push(product);
                }
                return null;
              });
              return null;
            });
          }
        });
      }
    }
    return selectedData;
  };


  useEffect(() => {
    getWidgetConfigurationByWidgetType(pageType, widgetType, channel).then((response) => {
      if (response && response.success && response.data) {
        setWidgetConfig(response.data);
      }
    });
  }, []);

  /**
   * This method is used to construct selected contents
   *
   * @param {*} dataArray
   * @returns
   */
  const constructSelectedContents = (dataArray) => {
    const constructedArray = [];
    if (dataArray && dataArray.length > 0) {
      dataArray.map((item) => {
        const newItem = {};
        newItem.name = item.name;
        newItem.id = item.id;
        if (item.medias && item.medias[0]) {
          newItem.imageUrl = item.medias[0].defaultMediaUrl;
        }
        constructedArray.push(newItem);
        return newItem;
      });
    }
    return constructedArray;
  };

  /**
   * This method is used to get page contents
   *
   */
  const getPageContents = () => {
    getPageContentByTypeAndConfigId(
      widgetType, configId, categoryId, channel, locale,
    ).then(async (response) => {
      if (response && response.success && response.data) {
        setPageContent(response.data);
        const newContents = await getSelectedContents(response.data);
        if (response.data.contentType) {
          setContentType({
            id: response.data.contentType,
            name: response.data.contentType,
          });
        }
        const constructedData = await constructSelectedContents(newContents);
        setCombinedContents(constructedData);
        setSelectedContents(constructedData);
      }
    });
  };

  useEffect(() => {
    getPageContents();
  }, []);

  /**
   * This method is used to handle select content typw
   *
   * @param {*} type
   */
  const handleSelectContentType = (type) => {
    setContentType(type);
    toggleDropdown();
    setCombinedContents([]);
    setSelectedContents([]);
    setSearchTerm("");
    setError({ message: "" });
  };

  /**
   * This method is used to handle search item
   *
   * @param {*} e
   */
  const handleSearch = (e) => {
    const term = e.target.value;
    setError({ message: "" });
    setSearchTerm(term);
  };

  /**
   * This method is used to handle set selected contents
   *
   * @param {*} data
   */
  const handleSetSelectedContents = (data) => {
    let prevContents = [...selectedContents];
    const isAlreadyExists = prevContents.find((item) => item.id === data.id);
    if (isAlreadyExists) {
      prevContents = prevContents.filter((item) => item.id !== data.id);
    } else {
      prevContents.push(data);
    }
    setError({ message: "" });
    setSelectedContents(prevContents);
  };

  /**
   * This method is sued to get product list from server
   * @param {String} searchTerm
   */
  const getProductListFromSever = (term, newPage = 0, paginationLimitin = itemsPerPage) => {
    const searchable = true;
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getProducts(cancelToken, term, newPage, paginationLimitin, searchable).then((response) => {
      if (response && response.success && response.data) {
        const { content: data } = response.data;
        const { content, endOfResult, totalPages } = response.data;
        if (Array.isArray(content)) {
          setHasMoreData(!endOfResult);
          setTotalPagesCount(totalPages);
          const constructedData = constructSelectedContents(data);
          setCombinedContents(constructedData);
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 0));
      }
    });
  };

  /**
   * This method is used to get all brands
   *
   * @param {*} term
   */
  const getBrands = (term) => {
    getAllBrands(term).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        const constructedData = constructSelectedContents(data);
        setCombinedContents(constructedData);
      }
    });
  };

  useEffect(() => {
    if (selectedContentType && selectedContentType.id === "Product") {
      getProductListFromSever(searchTerm);
    }
    if (selectedContentType && selectedContentType.id === "Brand") {
      getBrands(searchTerm);
    }
  }, [selectedContentType, searchTerm]);

  /**
   * This method is used to handle change category
   *
   * @param {*} categories
   */
  const handleChangeCategory = (categories) => {
    setError({ message: "" });
    const constructedData = constructSelectedContents(categories);
    setSelectedContents(constructedData);
  };

  /**
  * This method is used to redirect to page-customization
  *
  */
  const handleRedirectToPageCustomization = () => {
    const path = `/merchandising/${channel === "mobile" ? "mobile-app-customization" : "web-app-customization"}?pageType=${pageType}&&categoryId=${categoryId}`;
    history.push(path);
  };

  /**
   * This method is used to handle submit
   *
   */
  const submitForm = async (e) => {
    e.preventDefault();
    setError({ message: "" });
    if (selectedContents.length > 0) {
      let newWidgetContent = {};
      const headerContent = {
        header: headerData.header,
        link: {
          type: "Internal",
          url: headerData.landingPageBaseUrl,
        },
      };
      if (!widgetContent) {
        newWidgetContent.widgetType = widgetType;
        newWidgetContent.configId = configId;
        newWidgetContent.contents = [headerContent];
        newWidgetContent.categoryId = categoryId;
        newWidgetContent.contentType = selectedContentType.id;
        newWidgetContent.contentIds = selectedContents.map((item) => item.id);
      } else {
        newWidgetContent = {
          ...widgetContent,
          contents: [headerContent],
          contentType: selectedContentType.id,
          contentIds: selectedContents.map((item) => item.id),
        };
      }
      setLoading(true);
      setOnSubmitting(true);
      await updatePageContent(newWidgetContent, channel, locale).then((response) => {
        if (response && response.success) {
          handleRedirectToPageCustomization();
        }
        setLoading(false);
        setOnSubmitting(false);
      });
    } else {
      setError({ message: "Please select at least one item" });
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  /**
   * Delete a content
   *
   * @param {*} id
   * @param {*} index
   */
  const handleDelete = async (id, index) => {
    setIsLoading(true);
    setSelectedIndex(index);
    const newSelectedContents = selectedContents.filter(((item) => item.id !== id));
    setSelectedContents(newSelectedContents);
    setIsLoading(false);
  };

  const handleHeaderChange = (e) => {
    const { name, value } = e.target;
    setHeaderData({
      ...headerData,
      [name]: value,
    });
  };


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
 *This method is used ito on drag end
*
* @param {object} result
* @returns
*/
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rowData = reorder(
      selectedContents,
      result.source.index,
      result.destination.index,
    );
    setSelectedContents(rowData);
  };
  const onPageChange = (data) => {
    const { selected } = data;
    getProductListFromSever(searchTerm, selected, itemsPerPage);
  };

  return (
    <CombinedWidgetEditor
      submitForm={submitForm}
      widgetType={widgetType}
      handleRedirectToPageCustomization={handleRedirectToPageCustomization}
      dropdownOpen={dropdownOpen}
      toggleDropdown={toggleDropdown}
      handleSelectContentType={handleSelectContentType}
      selectedContentType={selectedContentType}
      handleChangeCategory={handleChangeCategory}
      combinedContents={combinedContents}
      selectedContents={selectedContents}
      handleSetSelectedContents={handleSetSelectedContents}
      handleDelete={handleDelete}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      searchTerm={searchTerm}
      handleSearch={handleSearch}
      error={error}
      headerData={headerData}
      handleHeaderChange={handleHeaderChange}
      widgetConfig={widgetConfig}
      onDragEnd={onDragEnd}
      totalPagesCount={totalPagesCount}
      hasMoreData={hasMoreData}
      onPageChange={onPageChange}
      loading={loading}
      onSubmitting={onSubmitting}
    />
  );
};

export default CombinedWidgetEditorContainer;
