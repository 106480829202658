/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Order report
 *
 * @author Naseef O
 *
 */
import React, { useState } from "react";
import DatePicker from "react-datetime";
import clsx from "clsx";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/OrderReport.module.css";
import CustomizationTable from "../../../common/components/Table";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";

const customRows = [
  {
    label: "Time interval",
    value: "",
    path: "timeInterval",
    transform: "",
  },
  {
    label: "Orders",
    value: "",
    path: "orders",
    transform: "",
  },
  {
    label: "Sales Item",
    value: "",
    path: "salesItem",
    transform: "",
  },
  {
    label: "Invoice",
    value: "",
    path: "invoice",
    transform: "",
  },
  {
    label: "Refunded",
    value: "",
    path: "refunded",
    transform: "",
  },
  {
    label: "Sales Tax",
    value: "",
    path: "salesTax",
    transform: "",
  },
  {
    label: "Sales Shipping",
    value: "",
    path: "salesShipping",
    transform: "",
  },
  {
    label: "Sales Discount",
    value: "",
    path: "salesDiscount",
    transform: "",
  },
  {
    label: "Cancelled",
    value: "",
    path: "cancelled",
    transform: "",
  },
];

const customizationData = [
  {
    timeInterval: "Mar 20, 2020",
    orders: "1",
    salesItem: "$130",
    invoice: "$130",
    refunded: "$0.00",
    salesTax: "$0.00",
    salesShipping: "$50.00",
    salesDiscount: "$0.00",
    cancelled: "$0.00",
  },
  {
    timeInterval: "Mar 20, 2020",
    orders: "1",
    salesItem: "$130",
    invoice: "$130",
    refunded: "$0.00",
    salesTax: "$0.00",
    salesShipping: "$50.00",
    salesDiscount: "$0.00",
    cancelled: "$0.00",
  },
  {
    timeInterval: "Mar 20, 2020",
    orders: "1",
    salesItem: "$130",
    invoice: "$130",
    refunded: "$0.00",
    salesTax: "$0.00",
    salesShipping: "$50.00",
    salesDiscount: "$0.00",
    cancelled: "$0.00",
  },
  {
    timeInterval: "Mar 20, 2020",
    orders: "1",
    salesItem: "$130",
    invoice: "$130",
    refunded: "$0.00",
    salesTax: "$0.00",
    salesShipping: "$50.00",
    salesDiscount: "$0.00",
    cancelled: "$0.00",
  },
  {
    timeInterval: "Mar 20, 2020",
    orders: "1",
    salesItem: "$130",
    invoice: "$130",
    refunded: "$0.00",
    salesTax: "$0.00",
    salesShipping: "$50.00",
    salesDiscount: "$0.00",
    cancelled: "$0.00",
  },
];

const OrderReport = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
   *This method is used to toogle dropdown
   *
   */
  const toggleDropdownRole = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [dropdownExportOpen, setDropdownOpenExport] = useState(false);

  /**
   *This method is used to toogle dropdown
   *
   */
  const toggleDropdownExport = () => {
    setDropdownOpenExport(!dropdownExportOpen);
  };

  return (
    <>

      <Row className={clsx(styles.block1, "common-page-card pageHeader")}>
        <Col xs="8">
          <span className="pageText">Order Reports</span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12" className="p-0">
          <Row className="m-0 ">
            <Col xs="12" md="4" className="p-3">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">
                  End date
                </Label>
                <DatePicker
                  id="endate"
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  closeOnSelect
                  input
                  name="endDate"
                  inputProps={{
                    placeholder: "Ex: dd/mm/yyyy",
                  }}
                />
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="p-3">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">
                  End date
                </Label>
                <DatePicker
                  id="endate"
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  closeOnSelect
                  input
                  name="endDate"
                  inputProps={{
                    placeholder: "dd/mm/yyyy",
                  }}
                />
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="p-3">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">
                  Date Used
                </Label>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdownRole}
                  className={clsx(styles.dropdownBtn, "commonDropDownWrapper")}
                >
                  <DropdownToggle caret>
                    <span>Order Created</span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdownMenuWrapper">
                    <div
                      onKeyPress={() => {}}
                      role="button"
                      tabIndex="0"
                      onClick={() => toggleDropdownRole()}
                      className="dropdownMenuItem"
                    >
                        Order Created
                    </div>
                    <div
                      onKeyPress={() => {}}
                      role="button"
                      tabIndex="0"
                      onClick={() => toggleDropdownRole()}
                      className="dropdownMenuItem"
                    >
                        Order Cancelled
                    </div>
                  </DropdownMenu>
                </Dropdown>
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <Row className={clsx(styles.block3, " pb-2  ")}>
            <Col xs="6">
              <Row className="m-0">
                <div className={styles.dateFilterRow}>
                  <span className={styles.verticalLineNone}>Day</span>
                  <span className={styles.verticalLine}>Month</span>
                  <span className={styles.verticalLine}>Year</span>
                </div>
              </Row>
            </Col>
            <Col xs="3" />
            <Col xs="3">
              <Dropdown
                isOpen={dropdownExportOpen}
                toggle={toggleDropdownExport}
                className={clsx(styles.dropdownBtn, "commonDropDownWrapper")}
              >
                <DropdownToggle caret>
                  <span>Export as</span>
                </DropdownToggle>
                <DropdownMenu right className="dropdownMenuWrapper">
                  <div
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                    onClick={() => toggleDropdownExport()}
                    className="dropdownMenuItem"
                  >
                    xlsx
                  </div>
                  <div
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                    onClick={() => toggleDropdownExport()}
                    className="dropdownMenuItem"
                  >
                    csv
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="custom-table ">

                <CustomizationTable
                  customRows={customRows}
                  customizationData={customizationData}
                  isStatusEnable={false}
                  isDeleteEnable={false}
                  isEditEnable={false}
                />

              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OrderReport;
