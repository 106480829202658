/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Coupons Management Container
 *
 * @author Naseef O
 *
 */


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CouponsManagement from "../components/CouponsManagement";
import { getCoupons, deleteCoupon, setStatus } from "../../../api/couponServices";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { getSitePreviewConfigurations, replicatePromotion, getPromotionReplicationStatus } from "../../../api/sitePreviewConfigurationsServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const CouponsManagementContainer = () => {
  const history = useHistory();

  const [coupons, setcoupons] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const paginationLimit = 5;
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  /**
 * This method is used to list coupons
 * @param {String} searchTerm
 * @param {Number} newPage
 * @param {Number} paginationLimit
 * @param {String} sortBy
 * @param {String} sortDirection
 */
  const getCouponList = () => (searchTerm = "", newPage = 0, paginationLimit = 10, sortBy = "createdDate", sortDirection = "DESC") => {
    getCoupons(searchTerm, newPage, paginationLimit, sortBy, sortDirection).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setcoupons(data);
          setHasMoreData(true);
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 1));
      }
    });
  };

  /**
   * This method is used to trigger pagination
   * @param {Number} page
   */
  const getPageData = (direction) => {
    const newPage = page + direction;
    getCouponList(searchTerm, Math.max(newPage, 1), paginationLimit);
  };

  /**
   * This method is used to update text in search box
   * @param {Event} event
   */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getCouponList(value); } else getCouponList();
  };

  /**
   * This method is used to redirect to add ndw coupons page
   */
  const newCoupon = () => {
    history.push("/marketing/coupons/new");
  };

  /**
   * This method is used to redirect to edit coupon page
   * @param {String} couponId
   */
  const editCoupon = (couponId) => {
    history.push(`/marketing/coupons/edit/${couponId}`);
  };

  const removeCoupon = (couponId) => {
    setSelectedCouponId(couponId);
    setOpenConfirmModal(true);
  };

  /**
   * This method is used to cancel confirm form
   */
  const cancelConfirm = () => {
    setSelectedCouponId("");
    setOpenConfirmModal(false);
  };

  /**
   *  This function form delete coupon
   */
  const confirmDelete = async () => {
    if (submitting) return;
    const response = await deleteCoupon(selectedCouponId);
    if (response && response.success) {
      const newCoupons = coupons.filter((coupon) => (coupon.id !== selectedCouponId));
      setcoupons(newCoupons);
    } else {
      if (response.messages) {
        setMessage({ type: "warning", message: response.messages[0] });
      }
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
    setSelectedCouponId("");
    cancelConfirm();
  };

  /** getting list of coupos when page load */
  useEffect(() => {
    getCouponList();
  }, [paginationLimit]);

  /**  This method used to change the coupon  status
  * @param {String} id
  * @param {Boolean} status
  */
  const changeCouponStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    const response = await setStatus(id);
    if (response && response.success) {
      const newCoupons = coupons.map((coupon) => (coupon.id === id
        ? ({ ...coupon, active: status }) : coupon));
      setcoupons(newCoupons);
    }
    setSubmitting(false);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };
  const getReplicationStatus = () => async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    getPromotionReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last promotion push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    replicatePromotion().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Coupon pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_CPNS_VIEW_LIST}
    >
      <>
        <CouponsManagement
          coupons={coupons}
          submitting={submitting}
          getPageData={getPageData}
          page={page}
          hasMoreData={hasMoreData}
          searchTerm={searchTerm}
          handleSearchTerm={handleSearchTerm}
          newCoupon={newCoupon}
          editCoupon={editCoupon}
          removeCoupon={removeCoupon}
          message={message}
          changeCouponStatus={changeCouponStatus}
          previewSiteUrl={previewSiteUrl}
          previewEnabled={previewEnabled}
          pushToLiveEnabled={pushToLiveEnabled}
          handlePushToLive={handlePushToLive}
          pushToLiveButtonEnabled={pushToLiveButtonEnabled}
          getReplicationStatus={getReplicationStatus}
        />
        <ConfirmationModal
          isOpen={openConfirmModal}
          toggleOpen={cancelConfirm}
          togglClose={cancelConfirm}
          handleConfirm={confirmDelete}
          content=" Are you sure you want to delete this coupon ?"
        />
      </>
    </Permission>
  );
};
export default CouponsManagementContainer;
