/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 *
 * @author Kishor
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getCustomerStepAuditByJobId } from "../../../api/CustomerAuditService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import CustomerStepAuditManagement from "../components/CustomerStepAuditManagement";

const CustomerStepAuditManagementContainer = ({ selectedJobId, onHandleBack, rowData }) => {

  const [batchStepAudit, setBatchStepAudit] = useState([]);

  useEffect(() => {
    getCustomerStepAuditByJobId(selectedJobId).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        setBatchStepAudit(data);
      }
    });
  }, []);

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_STEP_AUDIT_VIEW_LIST}
    >
      <CustomerStepAuditManagement
        batchStepAudit={batchStepAudit}
        jobAuditData={rowData}
        onHandleBack={onHandleBack}
      />
    </Permission>
  );
};
CustomerStepAuditManagementContainer.propTypes = {
  selectedJobId: PropTypes.string.isRequired,
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleBack: PropTypes.func.isRequired,
};
export default CustomerStepAuditManagementContainer;
