/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login
 *
 * @author Dipin
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Container from "../../../common/core/Container";
import Card from "../../../common/core/Card";
import Spinner from "../../../common/core/Spinner";
import styles from "../css/Login.module.css";

import ForgotPasswordHelper from "./ForgotPasswordHelper";
import SuccessModal from "./SuccessModal";

const Login = (props) => {
  const { t } = useTranslation(["login"]);
  const {
    handleSubmit,
    error,
    loginData,
    handleChange,
    submitting,
    toggle,
    modal,
    handleChangeForgotPassword,
    handleForgotPasswordSubmit,
    forgotPasswordForm,
    successModal,
    toggleSuccessModal,
    toggleClose,
    userNameValidation,
  } = props;

  return (
    <>
      <Container className={styles.loginWrapper1}>
        <Card className="loginWrapper">
          <h3>{t("login:signin")}</h3>
          <div className="loginContent">
            <Form onSubmit={handleSubmit}>
              <fieldset disabled={submitting}>
                <FormGroup className="fixed-form-height">
                  <Label for="exampleEmail">
                    {t("login:email")}
                    <i>*</i>
                  </Label>

                  <Input
                    value={loginData.username}
                    id="exampleEmail"
                    type="email"
                    onChange={(e) => handleChange("username", e)}
                    autoComplete="email"
                    invalid={error.username}
                  />
                  {" "}
                  <FormFeedback>{error.username}</FormFeedback>
                </FormGroup>
                <FormGroup className="fixed-form-height-pass">
                  <Row className="justify-content-between">
                    <Col xs="auto">
                      <Label>
                        {t("login:password")}
                        <i>*</i>
                      </Label>
                    </Col>
                    <Col xs="auto">
                      <span tabIndex={0} role="link" className="cursor-pointer link-info" onKeyPress={(e) => { if (e.key === "Enter") { toggle(); } }} onClick={() => toggle()}>
                        {t("login:forgot_your_password")}
                      </span>
                    </Col>
                  </Row>

                  <Input
                    id="examplePassword"
                    type="password"
                    autoComplete="current-password"
                    value={loginData.password}
                    onChange={(e) => handleChange("password", e)}
                    invalid={error.password}
                  />
                  {" "}
                  <FormFeedback>{error.password}</FormFeedback>
                  <p className="verifyLogin m-0 text-danger">
                    {error.isValid}
                  </p>
                </FormGroup>
                <Button className={error.isValid ? "rounded bluecolor border-0 w-100" : "rounded bluecolor border-0 w-100"}>
                  {
                    submitting ? (
                      <Spinner size="sm" animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                        <span>
                          {t("login:signin_button")}
                        </span>
                      )
                  }
                </Button>
              </fieldset>
            </Form>
          </div>
        </Card>
      </Container>
      <ForgotPasswordHelper
        modal={modal}
        toggle={toggle}
        handleChangeForgotPassword={handleChangeForgotPassword}
        handleForgotPasswordSubmit={handleForgotPasswordSubmit}
        forgotPasswordForm={forgotPasswordForm}
        toggleClose={toggleClose}
        userNameValidation={userNameValidation}
      />
      <SuccessModal
        modal={successModal}
        toggle={toggleSuccessModal}
      />
    </>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  loginData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  handleChangeForgotPassword: PropTypes.func.isRequired,
  handleForgotPasswordSubmit: PropTypes.func.isRequired,
  forgotPasswordForm: PropTypes.objectOf(PropTypes.any).isRequired,
  successModal: PropTypes.bool.isRequired,
  toggleSuccessModal: PropTypes.func.isRequired,
  toggleClose: PropTypes.func.isRequired,
  userNameValidation: PropTypes.bool.isRequired,

};
export default Login;
