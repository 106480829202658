/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import CategoryManagementContainer from "./containers/CategoryManagementContainer";
import AddCategoryContainer from "./containers/AddCategoryContainer";
import EditCategoryContainer from "./containers/EditCategoryContainer";
import SearchFacetsContainer from "./containers/SearchFacetContainer";
import SortingRulesContainer from "./containers/SortingRulesContainer";
import ViewCategoryContainer from "./containers/ViewCategoryContainer";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */


/** components */


const CategoryManagement = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={CategoryManagementContainer} />
    <PrivateRouter exact path={`${path}/new`} component={AddCategoryContainer} />
    <PrivateRouter exact path={`${path}/edit/:categoryId`} component={EditCategoryContainer} />
    <PrivateRouter exact path={`${path}/view/:categoryId`} component={ViewCategoryContainer} />
    <PrivateRouter exact path={`${path}/search-facets`} component={SearchFacetsContainer} />
    <PrivateRouter exact path={`${path}/sorting-rules`} component={SortingRulesContainer} />
    {/* <PrivateRouter exact path="" component={NotFound} /> */}
  </Switch>
);
CategoryManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default CategoryManagement;
