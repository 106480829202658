/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Card from "../../../common/core/Card";
import CardHeader from "../../../common/core/CardHeader";
import CardBody from "../../../common/core/CardBody";
import TabContent from "../../../common/core/TabContent";
import TabPane from "../../../common/core/TabPane";

/** ========= SUB COMPONENT ========= */
import CampaignStepOne from "../containers/CampaignStepOne";
import CampaignStepTwoContainer from "../containers/CampaignStepTwo";
import CampaignStepThree from "../containers/CampaignStepThree";

/** ========= CUSTOM COMPONENTS ========= */
/** ============ SVG IMAGE ICON ===================== */
import ArrowIcon from "../../../common/assets/images/svg/left-arrow-long.svg";

/** ========= UTILS =========== */
/** ========= MODULE STYLES ========= */
import styles from "../css/CampaignCreatorView.module.css";

const CampaignCreatorView = (props) => {
  const {
    activeStep, customerGroups, coupons, basicInfo,
    backButtonHandler, switchStep, nextStepHandler, handleChange, salesCompany,
  } = props;

  /** internationalization using i18n from common and campaign  */
  const { t } = useTranslation(["common", "campaign"]);

  return (
    <>
      <Row className="pageHeader common-page-card ">
        <Col xs="12">
          <span className="pageText">{t("campaign:title")}</span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, " common-page-card ")}>
        <Col xs="12">
          <div className="mb-1 d-flex align-items-center">
            <div className="  p-1">
              <span className="commonPointer mr-2" onClick={backButtonHandler} role="button" tabIndex={0} onKeyPress={() => { }}>
                <img
                  className={styles.icon}
                  src={ArrowIcon}
                  alt="Back"
                />
              </span>
            </div>
            <div className="p-0 flex-grow-1">
              <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>{t("campaign:label_add_new")}</span>
            </div>
          </div>
        </Col>
        <Col xs="12">
          <Card className={clsx(styles.step, "border-0", "rounded-0", "pt-2")}>
            <CardHeader className={clsx(styles.stepHeader, "p-4 ", "d-flex", "justify-content-center", "align-items-center")}>
              <ul className={clsx(styles.stepContainer, "d-flex", "justify-content-around", "w-100", "p-0", "m-0")}>
                <li
                  role="presentation"
                  // onClick={(o) => activeStep > 1 && switchStep(1, o)}
                  className={clsx(styles.stepItem, "d-md-flex", "d-block", "overflow-hidden")}
                  active={activeStep >= 1 ? "active" : null}
                  completed={activeStep > 1 ? "complete" : null}
                  disabled={activeStep >= 1 ? null : "disabled"}
                >
                  <span className={clsx(styles.index, "mx-auto", "mx-md-0", "rounded-circle", "p-2", "d-flex", "align-items-center", "justify-content-center")}>
                    <span>1</span>
                  </span>
                  <span className="d-block my-auto px-md-2 text-truncate font-weight-bold">
                    Basic Information
                  </span>
                </li>
                <li
                  role="presentation"
                  onClick={(o) => activeStep > 2 && switchStep(2, o)}
                  className={clsx(styles.stepItem, "d-md-flex", "d-block", "overflow-hidden")}
                  active={activeStep >= 2 ? "active" : null}
                  completed={activeStep > 2 ? "complete" : null}
                  disabled={activeStep >= 2 ? null : "disabled"}
                >
                  <span className={clsx(styles.index, "mx-auto", "mx-md-0", "rounded-circle", "p-2", "d-flex", "align-items-center", "justify-content-center")}>
                    <span>2</span>
                  </span>
                  <span className="d-block my-auto px-md-2 text-truncate font-weight-bold">
                    Qualifiers
                  </span>
                </li>
                <li
                  role="presentation"
                  onClick={(o) => activeStep > 3 && switchStep(3, o)}
                  className={clsx(styles.stepItem, "d-md-flex", "d-block", "overflow-hidden")}
                  active={activeStep >= 3 ? "active" : null}
                  completed={activeStep > 3 ? "complete" : null}
                  disabled={activeStep >= 3 ? null : "disabled"}
                >
                  <span className={clsx(styles.index, "mx-auto", "mx-md-0", "rounded-circle", "p-2", "d-flex", "align-items-center", "justify-content-center")}>
                    <span>3</span>
                  </span>
                  <span className="d-block my-auto px-md-2 text-truncate font-weight-bold">
                    Experience
                  </span>
                </li>
              </ul>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={`${activeStep}`}>
                <TabPane tabId="1" className="bg-white">
                  <CampaignStepOne
                    nextStep={(o) => nextStepHandler(2, o)}
                    handleChange={handleChange}
                    basicInfo={basicInfo}
                    salesCompany={salesCompany}
                  />
                </TabPane>
                <TabPane tabId="2" className="bg-white">
                  <CampaignStepTwoContainer
                    nextStep={nextStepHandler}
                    customerGroups={customerGroups}
                    coupons={coupons}
                    handleChange={handleChange}
                    basicInfo={basicInfo}
                    salesCompany={salesCompany}
                  />
                </TabPane>
                <TabPane tabId="3" className="bg-white">
                  <CampaignStepThree
                    nextStep={nextStepHandler}
                    customerGroups={customerGroups}
                    coupons={coupons}
                    basicInfo={basicInfo}
                    salesCompany={salesCompany}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

CampaignCreatorView.defaultProps = {
  basicInfo: null,
};

CampaignCreatorView.propTypes = {
  activeStep: PropTypes.number.isRequired,
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  coupons: PropTypes.arrayOf(PropTypes.any).isRequired,
  basicInfo: PropTypes.objectOf(PropTypes.any),
  /** functions */
  backButtonHandler: PropTypes.func.isRequired,
  switchStep: PropTypes.func.isRequired,
  nextStepHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default CampaignCreatorView;
