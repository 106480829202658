/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * page customization services
 *
 * @author Naseef O
 *
 */

import Rules from "../common/security/permissionRules";

const navigation = () => [
  ...(Rules.Control.MOZCOM_RA_MERCH_HEADER_MENU_VIEW_MENU() ? [{
    label: "Merchandising",
    id: "merchandising",
    icon: "merchandisingIcon",
    description: "Ensure your products and your brand are seen as you intend",
    color: "#1a9fe5",
    subMenus: [...(Rules.Control.MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU() ? [{
      displayText: "Catalog Management",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_MERCH_CTLG_AND_CTGRY_VIEW() ? [{
      displayText: "Category",
      path: "/merchandising/category",
      default: true,
    }] : []),
    // ...(Rules.Control.MOZCOM_RA_MERCH_CTLG_AND_BRND_VIEW() ? [{
    //   displayText: "Brand",
    //   path: "/merchandising/brand",
    //   default: true,
    // }] : []),
    ...(Rules.Control.MOZCOM_RA_MERCH_CTLG_AND_PRDT_VIEW() ? [{
      displayText: "Products",
      path: "/merchandising/product",
    }] : []),

    ...(Rules.Control.MOZCOM_RA_MERCH_CTLG_AND_PRCBK_VIEW() ? [{
      displayText: "Price",
      path: "/merchandising/pricebooks",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_MERCH_CTLG_AND_INVTRY_VIEW() ? [{
      displayText: "Inventory",
      path: "/merchandising/inventory",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_MERCH_PRE_ORDR_CTLG_VIEW_MENU() ? [{
      displayText: "Pre-order Catalog",
      path: "/merchandising/pre-order",
    }] : []),
    // {
    //   displayText: "Search Facets",
    //   path: "/merchandising/category/search-facets"
    // },
    ...(Rules.Control.MOZCOM_RA_MERCH_CTLG_AND_SRTNG_RLE_VIEW() ? [{
      displayText: "Sorting Rules",
      path: "/merchandising/category/sorting-rules",
    }] : []),
      // ...(Rules.Control.MOZCOM_RA_MERCH_CONTNT_MENU_VIEW_MENU() ? [{
      //   displayText: "Content Management",
      // }] : []),
      // {
      //   displayText: "Themes",
      //   path: "/merchandising/theme-settings",

      // },
      // ...(Rules.Control.MOZCOM_RA_MERCH_CONTNT_AND_LAYT_SETNG_VIEW() ? [{
      //   displayText: "Layout Settings",
      //   path: "/merchandising/layout-settings",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MERCH_CONTNT_AND_STIC_PG_VIEW() ? [{
      //   displayText: "Static Pages",
      //   path: "/merchandising/static-pages",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MERCH_CONTNT_AND_PG_CSTMZN_VIEW() ? [{
      //   displayText: "Web App Customizations",
      //   path: "/merchandising/web-app-customization",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MERCH_CONTNT_AND_MOB_APP_VIEW() ? [{
      //   displayText: "Mobile App Customizations",
      //   path: "/merchandising/mobile-app-customization",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MERCH_SITE_NVGTN_MENU_VIEW_MENU() ? [{
      //   displayText: "Site Navigations",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MERCH_SITE_NVGTN_AND_SITE_NVGTN_MGMNT_VIEW() ? [{
      //   displayText: "Navigation Management",
      //   path: "/merchandising/navigation",
      // }] : []),
    ],
  }] : []),
  ...(Rules.Control.MOZCOM_RA_OPRTN_HEADER_MENU_VIEW_MENU() ? [{
    label: "Operations",
    id: "operations",
    icon: "operationsIcon",
    description: "Creating a flawless shopping experience",
    color: "#33942f",
    subMenus: [
      ...(Rules.Control.MOZCOM_RA_OPRTN_ORDR_MGMNT_MENU_VIEW_MENU() ? [{
        displayText: "Order Management",
      }] : []),
      ...(Rules.Control.MOZCOM_RA_OPRTN_ORDR_MGMNT_AND_ORDR_VIEW() ? [{
        displayText: "Orders",
        path: "/operations/order",
        default: true,
      }] : []),

      // {
      //   displayText: "Order Settings",
      //   path: "/operations/order-settings",
      // },
      // {
      //   displayText: "Inventory",
      // },
      // {
      //   displayText: "Sources",
      //   path: "/operations/source",
      // },
      // {
      //   displayText: "Stock",
      //   path: "/operations/stock",
      // },
      // {
      //   displayText: "Order Settings",
      //   path: "/operations/order-settings",
      // },
      // {
      //   displayText: "Inventory",
      // },
      // {
      //   displayText: "Sources",
      //   path: "/operations/source",
      // },
      // {
      //   displayText: "Stock",
      //   path: "/operations/stock",
      // },
      ...(Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_MENU_VIEW_MENU() ? [{
        displayText: "Batch Management",
      }] : []),
      ...(Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_AND_SCHEDLR_VIEW() ? [{
        displayText: "Schedulers",
        path: "/operations/batch/scheduler",
      }] : []),
      ...(Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_AND_JOB_AUDIT_VIEW() ? [{
        displayText: "Job Audit",
        path: "/operations/batch/jobaudit",
      }] : []),
      // ...(Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_AND_JOB_AUDIT_VIEW() ? [{
      //   displayText: "Customer Management",
      // }] : []),
      ...(Rules.Control.MOZCOM_RA_OPRTN_BATCH_MGMNT_AND_JOB_AUDIT_VIEW() ? [{
        displayText: "Customer Audit",
        path: "/operations/customer-audit",
      }] : []),
    ],
  }] : []),
  ...(Rules.Control.MOZCOM_RA_MRKTNG_HEADER_MENU_VIEW_MENU() ? [{
    label: "Marketing",
    id: "marketing",
    icon: "marketingIcon",
    description: "Marketing tactics both on and off your website",
    color: "#f55d94",
    subMenus: [
      ...(Rules.Control.MOZCOM_RA_MRKTNG_MENU_VIEW_MENU() ? [{
        displayText: "Marketing",
      }] : []),
      ...(Rules.Control.MOZCOM_RA_MRKTNG_MRKTNG_AND_CMPGN_VIEW() ? [{
        displayText: "Campaign",
        path: "/marketing/campaign",
        default: true,
      }] : []),
      ...(Rules.Control.MOZCOM_RA_MRKTNG_MRKTNG_AND_PRMTN_VIEW() ? [{
        displayText: "Promotions",
        path: "/marketing/promotion",
      }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_MRKTNG_AND_CPNS_VIEW() ? [{
      //   displayText: "Coupons",
      //   path: "/marketing/coupons",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_MRKTNG_AND_BADGES_VIEW() ? [{
      //   displayText: "Badges",
      //   path: "/marketing/badges",
      // }] : []),
      // {
      //   displayText: "Content Slots",
      //   path: "/marketing/content-slots",
      // },
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU() ? [{
      //   displayText: "SEO Settings",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_SEO_AND_SITE_MP_VIEW() ? [{
      //   displayText: "Site Map",
      //   path: "/marketing/site-map",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_SEO_AND_RBTS_VIEW() ? [{
      //   displayText: "Robots Txt",
      //   path: "/marketing/robots-txt",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_SEO_AND_CUSTM_URL_VIEW() ? [{
      //   displayText: "Custom URL",
      //   path: "/marketing/custom-url",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_SEO_AND_URL_REDIRECT_VIEW() ? [{
      //   displayText: "URL Redirects",
      //   path: "/marketing/url-redirects",
      // }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_SEO_AND_PG_MTGS_VIEW() ? [{
      //   displayText: "Page Metatags",
      //   path: "/marketing/page-metatags",
      // }] : []),
      ...(Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_MENU_VIEW_MENU() ? [{
        displayText: "Customer Management",
      }] : []),
      // ...(Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_AND_CSTMR_VIEW() ? [{
      //   displayText: "Customer Data",
      //   path: "/marketing/customerdata",
      // }] : []),
      ...(Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_AND_GRP_VIEW() ? [{
        displayText: "Customer Groups",
        path: "/marketing/customergroups",
      }] : []),
    ],
  }] : []),

  {
    label: "Administration",
    id: "administration",
    icon: "administrationIcon",
    description: "Manage common e-commerce administrative tasks",
    color: "#ce6ed8",
    subMenus: [...(Rules.Control.MOZCOM_RA_ADMSTRTN_USR_MGMNT_MENU_VIEW_MENU() ? [{
      displayText: "User Management",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_VIEW_MENU() ? [{
      displayText: "Manage Users",
      path: "/administration/user",
      default: true,
    }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_VIEW_MENU() ? [{
    //   displayText: "Manage B2B Customers",
    //   path: "/administration/b2bcustomer",
    //   default: true,
    // }] : []),
    // {
    //   displayText: "Sales Representative",
    //   path: "/administration/sales-representative",
    // },
    // {
    //   displayText: "Search",
    //   path: "/administration/search",
    // },
    // {
    //   displayText: "Roles & Permissions",
    // },
    // {
    //   displayText: "Manage Roles",
    //   path: "/administration/manage-roles",
    // },
    // {
    //   displayText: "Manage Permissions",
    //   path: "/administration/permission",
    // },
    // {
    //   displayText: "Integrations",
    // },
    // {
    //   displayText: "API Settings",
    //   path: "/administration/api-setings",
    // },
    // {
    //   displayText: "Site Management",
    // },
    // {
    //   displayText: "Site Preference",
    //   path: "/administration/site-preference",
    // },
    // {
    //   displayText: "Site Protection",
    //   path: "/administration/site-protection",
    // },
    // {
    //   displayText: "Brand, Region Locale",
    //   path: "/administration/brand-region",
    // },
    // {
    //   displayText: "Currency",
    //   path: "/administration/currency",
    // },
    // {
    //   displayText: "URL Management",
    //   path: "/administration/url",
    // },
    // {
    //   displayText: "Currency Support",
    //   path: "/administration/currency-support",
    // },
    // {
    //   displayText: "Replication Support",
    //   path: "/administration/replication-support",
    // },
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_MENU_VIEW_MENU() ? [{
    //   displayText: "Tax configuration",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_CLS_VIEW_MENU() ? [{
    //   displayText: "Tax Class",
    //   path: "/administration/tax-class",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_LCTN_VIEW_MENU() ? [{
    //   displayText: "Tax Location",
    //   path: "/administration/tax-location",
    // }] : []),
    // {
    //   displayText: "Tax Rule",
    //   path: "/administration/tax-rule",
    // },
    // {
    //   displayText: "Payment Configuration",
    // },
    // {
    //   displayText: "Payment Method",
    //   path: "/administration/payment-method",
    // },
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_STR_MENU_VIEW_MENU() ? [{
    //   displayText: "Store Settings",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_STR_LCTN_VIEW_MENU() ? [{
    //   displayText: "Store Locations",
    //   path: "/administration/store-locations",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_MENU_VIEW_MENU() ? [{
    //   displayText: "Job Scheduling",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_NEW_JOB_VIEW_MENU() ? [{
    //   displayText: "New Job",
    //   path: "/administration/new-job",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_JOB_VIEW_MENU() ? [{
    //   displayText: "Jobs",
    //   path: "/administration/job-list",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_JOB_VIEW_MENU() ? [{
    //   displayText: "Job History",
    //   path: "/administration/job-details",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_GLBL_MENU_VIEW_MENU() ? [{
    //   displayText: "Global settings",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_GLBL_CART_VIEW_MENU() ? [{
    //   displayText: "Cart Settings",
    //   path: "/administration/cart-settings",
    // }] : []),
    // ...(Rules.Control.MOZCOM_RA_ADMSTRTN_CTLG_PRDT_UPD_CNFG() ? [{
    //   displayText: "Site Settings",
    //   path: "/administration/site-settings",
    // }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_SRCH_MENU_VIEW_MENU() ? [{
      displayText: "Search Configurations",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_SRCH_INDX_VIEW_MENU() ? [{
      displayText: "Search Indexes/ Attributes",
      path: "/administration/search-index",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_SRCH_SETTINGS_VIEW_MENU() ? [{
      displayText: "Search Settings",
      path: "/administration/search-settings",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_FACET_VIEW_MENU() ? [{
      displayText: "Facet Configurations",
      path: "/administration/facet-configurations",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_SRCH_SYNONYMS_VIEW_MENU() ? [{
      displayText: "Search Synonyms",
      path: "/administration/search-synonym",
    }] : []),
    // {
    //   displayText: "Search Suggestions",
    //   path: "/administration/search-suggestions",
    // },
    // {
    //   displayText: "Search Preferences",
    //   path: "/administration/search-preferences",
    // },
    // {
    //   displayText: "Search Relevancy Ranking",
    //   path: "/administration/search-ranking",
    // },
    // {
    //   displayText: "Sorting Options/ Rules",
    //   path: "/administration/sorting",
    // },
    // {
    //   displayText: "Shipping Configuration",
    // },
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_CACHE_MGMNT_MENU_VIEW_MENU() ? [{
      displayText: "Cache Management",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_CACHE_MGMNT_CLR_CACHE() ? [{
      displayText: "Clear Cache",
      path: "/administration/clear-cache",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_DISCOUNT_MGMNT_MENU_VIEW_MENU() ? [{
      displayText: "Price management",
    }] : []),
    ...(Rules.Control.MOZCOM_RA_ADMSTRTN_DISCOUNT_MGMNT_EDIT() ? [{
      displayText: "Discount configuration",
      path: "/administration/price-management",
    }] : []),
    ],
  },
];

const getMainMenus = async () => {
  const success = true;
  const data = navigation().map(({
    label, id, icon, description, color,
  }) => ({
    label, id, icon, description, color,
  }));
  return ({
    success,
    data,
  });
};

/**
 *This method is used to get page customization rows
 * @returns
 */
const getSideNavItems = async (navigationKey) => {
  const navg = navigation().filter((y) => navigationKey === y.id)[0];
  return {
    success: Boolean(navg),
    data: (navg && navg.subMenus) || [],
  };
};


export {
  getMainMenus,
  getSideNavItems,
};
