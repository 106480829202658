/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * User Management
 *
 * @author Mozanta
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Form, FormFeedback } from "reactstrap";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import UncontrolledDropdown from "../../../common/core/UncontrolledDropdown";
import styles from "../css/CreateNewUser.module.css";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import validateForm from "../../../common/utils/validateForm";
import Input from "../../../common/core/Input";
import Alert from "../../../common/core/Alert";
import Select from "../../../common/components/Select";
import MultiSelect from "../../../common/components/MultiSelect/MultiSelect";
import Spinner from "../../../common/core/Spinner";

const CreateNewUser = ({
  form,
  onHandleSave,
  customerRoles,
  onHandleCancel,
  handleFormChange,
  message, loading, onSubmitting,
}) => {
  const { t } = useTranslation(["common", "tax-configuration"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  const { register, errors } = validator;
  return (
    <>
      <div className={styles.createUserLayout}>
        {
          message && message.type && (
            <Alert color={message.type}>
              {message.message}
            </Alert>
          )
        }
        <Row className="common-page-card pageHeader">
          <Col xs="12">
            <span className="pageText">{t("common:label_create_users")}</span>
          </Col>
          <Col xs="12" md="4" lg="5" />
        </Row>
        <Form onSubmit={handleSubmit(onHandleSave)}>
          <fieldset disabled={onSubmitting}>
            <Col className="common-page-card px-4">
              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label>{t("common:label_first_name")}</Label>
                    <span className="pl-1 text-danger">*</span>
                    <Input
                      type="text"
                      name="firstName"
                      onChange={(e) => handleFormChange(e)}
                      innerRef={
                        register({
                          required: t("common:validation_first_name"),
                          minLength: {
                            value: 3,
                            message: t("common:validation_min_three-letter"),
                          },
                          maxLength: {
                            value: 252,
                            message: t("common:validation_too_large"),
                          },
                        })
                      }
                      invalid={!!(errors.firstName && errors.firstName.message)}
                    />
                    <FormFeedback>{errors.firstName && errors.firstName.message}</FormFeedback>
                  </Col>
                  <Col md="6">
                    <Label>{t("common:label_last_name")}</Label>
                    <span className="pl-1 text-danger">*</span>
                    <Input
                      type="text"
                      name="lastName"
                      onChange={(e) => handleFormChange(e)}
                      innerRef={
                        register({
                          required: t("common:validation_last_name"),
                        })
                      }
                      invalid={!!(errors.lastName && errors.lastName.message)}
                    />
                    <FormFeedback>{errors.lastName && errors.lastName.message}</FormFeedback>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label>{t("common:label_mobile")}</Label>
                    <span className="pl-1 text-danger">*</span>
                    <Input
                      type="text"
                      name="mobile"
                      onChange={(e) => handleFormChange(e)}
                      innerRef={
                        register({
                          required: t("common:validation_mobile"),
                          minLength: {
                            value: 10,
                            message: t("common:validation_min_ten-numbers"),
                          },
                        })
                      }
                      invalid={!!(errors.mobile && errors.mobile.message)}
                    />
                    <FormFeedback>{errors.mobile && errors.mobile.message}</FormFeedback>
                  </Col>
                  <Col md="6">
                    <Label>{t("common:label_email")}</Label>
                    <span className="pl-1 text-danger">*</span>
                    <Input
                      type="email"
                      name="email"
                      onChange={(e) => handleFormChange(e)}
                      innerRef={
                        register({
                          required: t("common:validation_email"),
                          minLength: {
                            value: 3,
                            message: t("common:validation_min_three-letter"),
                          },
                          maxLength: {
                            value: 252,
                            message: t("common:validation_too_large"),
                          },
                        })
                      }
                      invalid={!!(errors.email && errors.email.message)}
                    />
                    <FormFeedback>{errors.email && errors.email.message}</FormFeedback>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label>{t("common:label_role")}</Label>
                    <span className="pl-1 text-danger">*</span>
                    <UncontrolledDropdown className={styles.BtnSecondary}>
                      <Select
                        className="formTextLabel"
                        name="roles"
                        onChange={(e) => handleFormChange(e)}
                        options={
                          Array.isArray(customerRoles)
                          && customerRoles.map(({ code: value, displayText: label }) => ({ value, label }))
                        }
                      />

                    </UncontrolledDropdown>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col className="text-right">
                    <Button
                      color="secondary"
                      className="mr-2"
                      onClick={onHandleCancel}
                    >
                      {t("common:button_cancel")}

                    </Button>
                    <Button color="primary" className={styles.btnTertiary}>
                      {
                        loading ? (
                          <Spinner size="sm" />
                        ) : "Save"
                      }
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </fieldset>
        </Form>
      </div>
    </>
  );
};


CreateNewUser.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  onHandleSave: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  customerRoles: PropTypes.arrayOf(PropTypes.any).isRequired,
  form: PropTypes.arrayOf(PropTypes.any).isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default CreateNewUser;
