/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ========= SUB COMPONENT ========= */
import CustomerGroups from "../components/CustomerGroups";

/** ========= API SERVICE FUNCTIONS ========= */
import { getCustomerGroups } from "../../../api/customerGroupServices";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const CustomerGroupsContainer = (props) => {
  const {
    onChange, selectedCustomerGroupDetails, salesCompany,
  } = props;

  /** local states */
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [customerGroups, setCustomerGroups] = useState([]);
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState([]);
  const [customerGroupList, setCustomerGroupList] = useState([]);
  /**
   * This function is used to expand collapse
   * @param {Boolean} status
   */
  const toggleBody = (status) => {
    setOpen(status);
  };


  useEffect(() => {
    if (Array.isArray(selectedCustomerGroupDetails) && selectedCustomerGroupDetails.length > 0) {
      const listedCustomerGroupId = customerGroupList.map(({ id }) => id);
      const filteredList = selectedCustomerGroupDetails.filter((each) => each && !listedCustomerGroupId.includes(each.id));
      setCustomerGroupList([
        ...customerGroupList,
        ...filteredList,
      ]);
      setSelectedCustomerGroups(selectedCustomerGroupDetails.map(({ id }) => id));
    }
  }, [selectedCustomerGroupDetails]);


  /**
   * This function is used to change tab
   * @param {Number} tabIndex
   */
  const changeActiveTab = (tabIndex) => {
    setActiveTab(`${tabIndex}`);
  };

  /**
   * This method is sued to get customerGroup list from server
   * @param {String} searchTerm
   */
  const getCustomerGroupListFromSever = (searchTerm = "", newPage = 0, paginationLimit = 5, sortBy = "createdDate", sortDirection = "DESC") => {
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getCustomerGroups(searchTerm, salesCompany, newPage, paginationLimit, sortBy, sortDirection, true, cancelToken).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          const listedCustomerGroupId = customerGroupList.map(({ id }) => id);
          setCustomerGroups(data);
          setCustomerGroupList([
            ...(data.filter((each) => each && !listedCustomerGroupId.includes(each.id))),
            ...customerGroupList,
          ]);
        }
      }
    });
  };

  /**
   * This method is used to change search filed and trigger search functionality
   * @param {Event} event
   */
  const handleSearchFiled = (event) => {
    const { value } = event.target;
    if (value && `${value}`.trim().length > 1) {
      getCustomerGroupListFromSever(value);
    } else {
      setCustomerGroups([]);
    }
    setSearchKeyword(value);
  };

  /**
   * This method is used to update selected data in server
   * @param {Array} newSelectedList
   * @param {Array} sourceData
   */
  const updateInParent = (newSelectedList, sourceData) => {
    if (Array.isArray(sourceData)) {
      const fullObjectList = sourceData.filter((customerGroup) => newSelectedList.includes(customerGroup.id));
      onChange(fullObjectList || []);
    }
  };

  /**
   * This method is used to set select / deselect customerGroup
   * @param {String} customerGroupId
   */
  const setCustomerGroupSelected = (customerGroupId) => {
    const index = selectedCustomerGroups.indexOf(customerGroupId);
    let newSelectedList = [];
    if (index < 0) {
      /** if item not exist adding in the list */
      newSelectedList = ([...selectedCustomerGroups, customerGroupId]);
    } else {
      /** if item exits remove form the list */
      newSelectedList = (selectedCustomerGroups.filter((o) => o !== customerGroupId));
    }
    setSelectedCustomerGroups(newSelectedList);
    updateInParent(newSelectedList, customerGroupList);
  };

  /**
   * This function is used to clear search results
   */
  const clearSearchResults = () => {
    setCustomerGroups([]);
    setSearchKeyword("");
  };

  /**
   * This method is used to get new customer group data
   * @param {Object} newCustomerGroup
   */
  const handleNewCustomerGroup = (newCustomerGroup) => {
    if (newCustomerGroup && newCustomerGroup.id) {
      const sourceData = [...customerGroupList, newCustomerGroup];
      setCustomerGroupList(sourceData);
      const newSelectedList = ([...selectedCustomerGroups, newCustomerGroup.id]);
      setSelectedCustomerGroups(newSelectedList);
      updateInParent(newSelectedList, sourceData);
      setActiveTab("1");
    }
  };

  return (
    <CustomerGroups
      open={open}
      activeTab={activeTab}
      searchKeyword={searchKeyword}
      toggleBody={toggleBody}
      changeActiveTab={changeActiveTab}
      handleSearchFiled={handleSearchFiled}
      // customerGroups
      customerGroupList={customerGroupList}
      customerGroups={customerGroups}
      selectedCustomerGroups={selectedCustomerGroups}
      setCustomerGroupSelected={setCustomerGroupSelected}
      clearSearchResults={clearSearchResults}
      handleNewCustomerGroup={handleNewCustomerGroup}
      salesCompany={salesCompany}
    />
  );
};

CustomerGroupsContainer.defaultProps = {
  selectedCustomerGroupDetails: [],
  campaignId: null,
};

CustomerGroupsContainer.propTypes = {
  selectedCustomerGroupDetails: PropTypes.arrayOf(PropTypes.object),
  campaignId: PropTypes.string,
  // functions
  onChange: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default CustomerGroupsContainer;
