/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
/** ========= TAG COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */
import CampaignCreatorView from "../components/CampaignCreatorView";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */

const CampaignCreator = () => {
  const history = useHistory();

  /** local states */
  const [form, setForm] = useState({
    basicInfo: null,
    customerGroups: [],
    coupons: [],
  });
  const [activeStep, setActiveStep] = useState(1);
  const param = useParams();
  const { salesCompany } = param;
  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };


  /**
   * This function is used to change step view
   * @param {Number} stepIndex
   */
  const switchStep = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  /**
   * This method is used to redirect to campaign listing page
   */
  const backButtonHandler = () => {
    history.push("/marketing/campaign");
  };

  /**
   * This function is used to change steps
   * @param {Number} stepIndex
   */
  const nextStepHandler = (stepIndex) => {
    switchStep(stepIndex);
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_CMPGN_CRT_CMPGN}
    >
      <CampaignCreatorView
        activeStep={activeStep}
        customerGroups={form.customerGroups}
        coupons={form.coupons}
        basicInfo={form.basicInfo}
        // functions
        backButtonHandler={backButtonHandler}
        switchStep={switchStep}
        nextStepHandler={nextStepHandler}
        handleChange={handleChange}
        salesCompany={salesCompany}
      />
    </Permission>
  );
};

export default CampaignCreator;
