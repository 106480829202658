/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Card from "../../../common/core/Card";
import CardBody from "../../../common/core/CardBody";
import TabContent from "../../../common/core/TabContent";
import TabPane from "../../../common/core/TabPane";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Collapse from "../../../common/core/Collapse";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import ListGroup from "../../../common/core/ListGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Table from "../../../common/core/Table";
import Form from "../../../common/core/Form";

/** ========= SUB COMPONENT ========= */
import PromotionCreator from "../containers/PromotionCreator";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */

/** ============ SVG IMAGE ICON ===================== */
import RightArrowIcon from "../../../common/assets/images/svg/right-arrow.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

/** ========= MODULE STYLES ========= */
import styles from "../css/Promotions.module.css";

const Promotions = (props) => {
  const {
    open, activeTab, searchKeyword, promotions, selectedPromotions, promotionList,
    toggleBody, changeActiveTab, handleSearchFiled, setPromotionSelected, clearSearchResults, handleNewPromotion, salesCompany,
  } = props;

  /** internationalization using i18n from common and campaign  */
  const { t } = useTranslation(["common", "campaign", "promotion"]);

  const hasSearchResult = Array.isArray(promotions) && promotions.length > 0;
  const selectedCount = Array.isArray(selectedPromotions) ? selectedPromotions.length : 0;
  const selectedGroups = promotionList.filter((promotion) => selectedPromotions.includes(promotion.id));
  return (
    <Card>
      <CardBody className="p-0">
        <div className="px-3 py-4">
          <span
            className="commonPointer d-flex"
            onClick={(o) => toggleBody(!open, o)}
            onKeyPress={() => { }}
            role="presentation"
          >
            <span className="mr-auto">
              <h6 className="m-0">{`${t("campaign:label_promotion")} (${selectedCount})`}</h6>
            </span>
            <span className={styles.collapseIcon}>
              <img src={RightArrowIcon} className={open ? styles.openCollapse : styles.closeCollapse} alt="" />
            </span>
          </span>
        </div>
        <Collapse isOpen={open}>
          <div className={styles.tabContainer}>
            <div className="d-flex">
              <div className={clsx(activeTab === "1" ? styles.active : "", styles.tabHeader, "p-2", "w-50", "text-center", "commonPointer")} role="presentation" onClick={(o) => changeActiveTab("1", o)}>
                {t("campaign:label_assign_promotions")}
              </div>
              <div className={clsx(activeTab === "2" ? styles.active : "", styles.tabHeader, "p-2", "w-50", "text-center", "commonPointer")} role="presentation" onClick={(o) => changeActiveTab("2", o)}>
                {t("campaign:label_create_new_promotion")}
              </div>
            </div>
            <div className="p-3">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="bg-white p-0 text-body font-weight-normal">
                  <div className={styles.promotions}>
                    <Form onSubmit={(c) => { c.preventDefault(); c.stopPropagation(); }}>
                      <Dropdown isOpen={hasSearchResult} toggle={() => clearSearchResults()}>
                        <DropdownToggle
                          tag="span"
                          data-toggle="dropdown"
                          aria-expanded={hasSearchResult}
                          className={clsx(styles.search, "m-0", "input-group", "border")}
                        >
                          <Input role="presentation" placeholder={t("campaign:label_search_promotion")} value={searchKeyword} onChange={handleSearchFiled} />
                          <InputGroupAddon
                            addonType="append"
                            className="commonPointer d-flex align-items-center px-3"
                          >
                            <svg className="bi bi-search" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z" clipRule="evenodd" />
                              <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z" clipRule="evenodd" />
                            </svg>
                          </InputGroupAddon>
                        </DropdownToggle>
                        <DropdownMenu className={clsx("p-0 mt-1", styles.searchListContainer, "w-100", "custom-scrollbar")}>
                          <ListGroup flush>
                            {
                              promotions.map((promotion) => (
                                <ListGroupItem key={promotion.id} className="px-2 py-1 border-0 d-flex ">
                                  <div className="custom-control custom-checkbox text-truncate">
                                    {/*
                                  * maintaining separate state for selected promotions,
                                  * checked logic by checking includes
                                  * */}
                                    <input
                                      type="checkbox"
                                      id={`promotionRadio${promotion.id}`}
                                      checked={selectedPromotions.includes(promotion.id)}
                                      name={promotion.id}
                                      className="custom-control-input"
                                      readOnly
                                    />
                                    <Label
                                      onClick={(o) => setPromotionSelected(promotion.id, o)}
                                      className="custom-control-label text-black commonPointer mw-100 "
                                      htmlFor={`promotionRadio${promotion.id}`}
                                      title={promotion.description}
                                    >
                                      <span className="text-truncate d-block mw-100">{promotion.title}</span>
                                    </Label>
                                  </div>
                                </ListGroupItem>
                              ))
                            }
                          </ListGroup>
                        </DropdownMenu>
                      </Dropdown>
                    </Form>
                    <Table responsive className={clsx(styles.searchListTable, " mt-3", "table-borderless", "table-sm")}>
                      <thead>
                        <tr>
                          <th width="20%">
                            {t("campaign:label_promotion_id")}
                          </th>
                          <th width="60%" className="text-center">
                            {t("campaign:label_title")}
                          </th>
                          <th className="text-right">
                            {t("common:label_actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody className={clsx("py-1", "custom-scrollbar")}>
                        {
                          Array.isArray(selectedGroups) && selectedGroups.length > 0
                            ? selectedGroups.map((promotion) => (
                              <tr key={promotion.id}>
                                <td title={promotion.id} width="20%">
                                  <span className="text-truncate d-block mw-100">{promotion.id}</span>
                                </td>
                                <td title={promotion.title} width="60%" className="text-center">
                                  <span className="text-truncate d-block mw-100">{promotion.title}</span>
                                </td>
                                <td className="text-right">
                                  <span
                                    onClick={(o) => setPromotionSelected(promotion.id, o)}
                                    onKeyPress={() => { }}
                                    role="button"
                                    tabIndex={0}
                                    className="commonPointer"
                                  >
                                    <img
                                      className={clsx("commonPointer", styles.icon)}
                                      src={DeleteIcon}
                                      alt="Delete"
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))
                            : (
                              <tr>
                                <td colSpan="3">
                                  <div className="p-2 text-center w-100">
                                    <p className="m-0">{t("campaign:label_search_promotion")}</p>
                                  </div>
                                </td>
                              </tr>
                            )
                        }
                      </tbody>
                    </Table>
                  </div>
                </TabPane>
                <TabPane tabId="2" className="bg-white p-0 text-body font-weight-normal">
                  <PromotionCreator
                    onSave={handleNewPromotion}
                    onCancel={toggleBody}
                    salesCompany={salesCompany}
                  />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Collapse>
      </CardBody>
    </Card>
  );
};

Promotions.defaultProps = {

};

Promotions.propTypes = {
  open: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  searchKeyword: PropTypes.string.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPromotions: PropTypes.arrayOf(PropTypes.any).isRequired,
  promotionList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // function
  toggleBody: PropTypes.func.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  handleSearchFiled: PropTypes.func.isRequired,
  setPromotionSelected: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
  handleNewPromotion: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default Promotions;
