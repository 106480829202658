/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useState, useEffect } from "react";
import OrderDetails from "../components/OrderDetails";
import { getOrderDetails } from "../../../api/orderServices";

const OrderDetailsContainer = ({ onHandleBack, selectedOrderId }) => {
  const [order, setOrder] = useState({});
  const [collapsesOpen, setCollapsesOpen] = useState([]);
  const toggleCollapse = (shipmentId) => {
    const newCollapsesOpen = [...collapsesOpen];
    if (newCollapsesOpen.includes(shipmentId)) {
      setCollapsesOpen(newCollapsesOpen.filter((each) => each !== shipmentId));
    } else {
      newCollapsesOpen.push(shipmentId);
      setCollapsesOpen(newCollapsesOpen);
    }
  };
  useEffect(() => {
    getOrderDetails(selectedOrderId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setOrder(data);
      }
    });
  }, [selectedOrderId]);
  return (
    <>
      {order && Array.isArray(order.shipments) && order.shipments.length > 0 && (
        <OrderDetails
          order={order}
          onHandleBack={onHandleBack}
          collapsesOpen={collapsesOpen}
          toggleCollapse={toggleCollapse}
        />
      )}
    </>
  );
};

export default OrderDetailsContainer;
