/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */


import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Card from "../../../common/core/Card";
import CardBody from "../../../common/core/CardBody";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Form from "../../../common/core/Form";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";

/** ========= SUB COMPONENT ========= */
import PromotionsContainer from "../containers/PromotionsContainer";

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/MultiSelect";

/** ========= API SERVICE FUNCTIONS ========= */

/** ============ SVG IMAGE ICON ===================== */
import RightArrowIcon from "../../../common/assets/images/svg/right-arrow.svg";

/** ========= UTILS =========== */
import constants from "../../../common/utils/constants";

/** ========= MODULE STYLES ========= */
import styles from "../css/CampaignCreatorView.module.css";


const CampaignStepThreeView = (props) => {
  const { CAMPAIGN_EXPERIENCE } = constants;

  const {
    onSubmitting, openExperienceSelector, selectedExperiences, selectedPromotions, customerGroups, coupons, campaignId,
    formSubmitHandler, formBackHandler, handleExperienceSelector,
    handleSelectedExperience, handleSelectedPromotions, handleSelectionChange, handlePromotionChange, salesCompany,
  } = props;

  /** internationalization using i18n from common and campaign  */
  const { t } = useTranslation(["common", "campaign"]);

  return (
    <Row className="justify-content-center text-body">
      <Col sm="12" md="10" xl="8">
        <div className="text-center">
          <h4 className="text-body font-weight-bolder">{t("campaign:title_experience")}</h4>
        </div>
        <div className="text-center">
          <p className="font-weight-normal pt-2 pb-1">
            {t("campaign:sub_title_experience")}
          </p>
        </div>
        <div>
          <Form className={styles.form}>
            <fieldset disabled={onSubmitting}>
              <div>
                <Dropdown isOpen={openExperienceSelector} toggle={(o) => handleExperienceSelector(!openExperienceSelector, o)}>
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={openExperienceSelector}
                    className="card commonPointer"
                  >
                    <CardBody>
                      <span
                        className=" d-flex"
                      >
                        <span className="mr-auto">
                          <h6 className="m-0">
                            {
                              t("campaign:label_add_experience")
                            }
                          </h6>
                        </span>
                        <span className={styles.collapseIcon}>
                          <img src={RightArrowIcon} className={openExperienceSelector ? styles.openCollapse : styles.closeCollapse} alt="" />
                        </span>
                      </span>
                    </CardBody>
                  </DropdownToggle>
                  <DropdownMenu className=" w-100">
                    <div
                      role="presentation"
                      onClick={(o) => handleSelectedExperience(CAMPAIGN_EXPERIENCE.promotion, o)}
                      className="p-2  commonPointer font-weight-normal"
                    >
                      Add Promotion
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
              {
                selectedExperiences === CAMPAIGN_EXPERIENCE.promotion
                && (
                  <div className="pt-3">
                    <PromotionsContainer
                      campaignId={campaignId}
                      onChange={handleSelectedPromotions}
                      selectedPromotionDetails={selectedPromotions}
                      salesCompany={salesCompany}
                    />
                  </div>
                )
              }
              {
                Array.isArray(selectedPromotions) && selectedPromotions.map((promotion) => (
                  <div className="pt-3">
                    <Card>
                      <CardBody className="p-0">
                        <div className="p-3 border-bottom">
                          <h6 className="text-truncate font-weight-bold mb-1">
                            {promotion.id}
                          </h6>
                          <p className="m-0 font-weight-normal">
                            {promotion.title}
                          </p>
                        </div>
                        <div className="p-3 ">
                          <FormGroup>
                            <span className="d-flex w-100">
                              <span className="d-flex mr-auto text-truncate">
                                <Label className="formTextLabel">
                                  {t("campaign:label_customer_groups")}
                                </Label>
                              </span>
                              <span className="d-flex ml-auto text-truncate pl-1">
                                <div className={clsx(styles.rightCheckbox, "custom-control", "custom-checkbox", "text-truncate", "d-flex", "p-0", "commonPointer")}>
                                  <span
                                    role="presentation"
                                    onClick={() => handlePromotionChange(promotion.id, "applyToEveryone", !promotion.applyToEveryone)}
                                    className="text-truncate d-block mw-100"
                                  >
                                    {t("campaign:label_apply_to_everyone")}
                                  </span>
                                  <input
                                    type="checkbox"
                                    id={`campaign:label_customer_group${promotion.id}`}
                                    checked={!!promotion.applyToEveryone && promotion.applyToEveryone}
                                    name="applyToEveryone"
                                    className="custom-control-input"
                                    readOnly
                                  />
                                  <Label
                                    className="custom-control-label text-black commonPointer mw-100 "
                                    htmlFor={`campaign:label_customer_group${promotion.id}`}
                                    title=""
                                  />
                                </div>
                              </span>
                            </span>
                            <Select
                              className="formText"
                              placeholder={t("campaign:label_select_customer_groups")}
                              name="customerGroup"
                              id="campaign-customer-group"
                              value={Array.isArray(promotion.customerGroups) ? promotion.customerGroups : []}
                              onChange={(o) => handleSelectionChange(promotion.id, "customerGroups", o)}
                              options={
                                Array.isArray(customerGroups)
                                && customerGroups.map(({ id: value, name: label }) => ({ value, label }))
                              }
                              disabled={promotion.applyToEveryone}
                            />
                          </FormGroup>
                          {/* <FormGroup>
                            <Label className="formTextLabel">
                              {t("campaign:label_coupons")}
                            </Label>
                            <Select
                              className="formText"
                              placeholder={t("campaign:label_select_coupons")}
                              name="customerGroup"
                              id="campaign-coupon"
                              value={Array.isArray(promotion.coupons) ? promotion.coupons : []}
                              onChange={(o) => handleSelectionChange(promotion.id, "coupons", o)}
                              options={
                                Array.isArray(coupons)
                                && coupons.map(({ id: value, id: label }) => ({ value, label }))
                              }
                            />
                          </FormGroup> */}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))
              }
              <div className="pt-3">
                <Card>
                  <CardBody className="p-3">
                    <FormGroup className="mb-0">
                      <div className="d-flex flex-wrap justify-content-end">
                        <div className="mr-md-auto py-1">
                          <Button onClick={formBackHandler} type="button" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size")}>
                            {t("common:button_back")}
                          </Button>
                        </div>
                        <div className="py-1">
                          <Button onClick={formSubmitHandler} color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "button-font-size")}>
                            {t("campaign:button_finish")}
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </div>
            </fieldset>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

CampaignStepThreeView.defaultProps = {
  campaignId: null,
};

CampaignStepThreeView.propTypes = {
  onSubmitting: PropTypes.bool.isRequired,
  openExperienceSelector: PropTypes.bool.isRequired,
  selectedExperiences: PropTypes.string.isRequired,
  selectedPromotions: PropTypes.arrayOf(PropTypes.any).isRequired,
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  coupons: PropTypes.arrayOf(PropTypes.any).isRequired,
  campaignId: PropTypes.string,
  // functions
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
  formBackHandler: PropTypes.func.isRequired,
  formSaveDraftHandler: PropTypes.func.isRequired,
  handleExperienceSelector: PropTypes.func.isRequired,
  handleSelectedExperience: PropTypes.func.isRequired,
  handleSelectedPromotions: PropTypes.func.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  handlePromotionChange: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default CampaignStepThreeView;
