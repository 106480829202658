/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * User Management
 *
 * @author Mozanta
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import UncontrolledDropdown from "../../../common/core/UncontrolledDropdown";
import styles from "../css/CreateNewUser.module.css";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import validateForm from "../../../common/utils/validateForm";
import MultiSelect from "../../../common/components/MultiSelect/MultiSelect";
import Spinner from "../../../common/core/Spinner";

const EditUser = ({
  form,
  onHandleSave,
  onHandleCancel,
  handleFormChange,
  errors,
  loading, onSubmitting,
  roles,
}) => {
  const { t } = useTranslation(["common", "tax-configuration"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  return (
    <>
      <div className={styles.createUserLayout}>
        <Row className="common-page-card pageHeader">
          <Col xs="12">
            <span className="pageText">{t("common:label_edit_users")}</span>
          </Col>
          <Col xs="12" md="4" lg="5" />
        </Row>
        <Form onSubmit={handleSubmit(onHandleSave)}>
          <fieldset disabled={onSubmitting}>
            <Col className="common-page-card px-4">
              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label>{t("common:label_first_name")}</Label>
                    <Input
                      type="text"
                      name="firstName"
                      value={form.firstName || form.fullName}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Col>
                  <Col md="6">
                    <Label>{t("common:label_last_name")}</Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={form.lastName}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label>{t("common:label_mobile")}</Label>
                    <Input
                      type="text"
                      name="mobile"
                      value={form.mobile}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Col>
                  <Col md="6">
                    <Label>{t("common:label_email")}</Label>
                    <Input
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="6">
                    <Label>{t("common:label_role")}</Label>
                    <UncontrolledDropdown className={styles.BtnSecondary}>

                      <MultiSelect
                        className="formTextLabel"
                        id="campaign-customer-group"
                        name="roles"
                        value={form.roles}
                        onChange={(e) => handleFormChange(e)}
                        options={roles}
                        invalid={errors.roles && errors.roles.message}
                      />

                    </UncontrolledDropdown>
                    <span className={styles.errorText}>{errors.roles && errors.roles.message}</span>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col className="text-right">
                    <Button
                      color="secondary"
                      className="mr-2"
                      onClick={onHandleCancel}
                    >
                      {t("common:button_cancel")}

                    </Button>
                    <Button color="primary" className={styles.btnTertiary}>
                      {
                        loading ? (
                          <Spinner size="sm" />
                        ) : "Save"
                      }
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </fieldset>
        </Form>
      </div>
    </>
  );
};


EditUser.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  onHandleSave: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.any).isRequired,
  form: PropTypes.arrayOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default EditUser;
