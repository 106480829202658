/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Collapse from "../../../common/core/Collapse";
import Nav from "../../../common/core/Nav";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";
import TabContent from "../../../common/core/TabContent";
import TabPane from "../../../common/core/TabPane";
import Select from "../../../common/components/MultiSelect/Select";
/** ===== CUSTOM COMPONENTS =========== */
import CustomizationTable from "../../../common/components/Table";

/** ===== IMAGE ICONS =========== */
import RightArrowIcon from "../../../common/assets/images/svg/right-arrow.svg";

/** ======== MODULE STYLES ========== */
import styles from "../css/SiteMap.module.css";
import Form from "../../../common/core/Form";
/** ========== SUB COMPONENT ================ */
import AddUrl from "./AddUrl";
import PrioritySchedule from "./PrioritySchedule";
import SiteMap from "./SiteMap";


const customRows = [
  {
    label: "URL",
    value: "",
    path: "Title",
    transform: "",
  },

  {
    label: "Last Modified Date",
    value: "",
    path: "date",
    transform: "",
    type: "date",
  },
];

const customizationData = [
  {

    Title: "URL-1",
    date: "03/04/2020",
  },
  {
    date: "03/04/2020",
    Title: "URL-2",
  },
  {
    date: "03/04/2020",
    Title: "URL-3",
  },
  {
    date: "03/04/2020",
    Title: "URL-4",
  },
];

const SiteMapManagement = ({
  form, handleFormChange, onHandleSave, onHandleCancel, onSubmit, productPriority,
  productSchedule,
  categoryPriority,
  categorySchedule,
  handleChange,
  setForm,
  previewEnabled,
  indexingMode,
  IndexingModeList,
  onIndexingModeChange
}) => {
  /** local state */
  const [isOpen, setIsOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  //   const [isHome, setIsHometOpen] = useState(false);
  //   const [isCatPageOpen, setIsCatPageOpen] = useState(false);
  //   const [isProductPageOpen, setIsProductPageOpen] = useState(false);


  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleUrl = () => setIsOpen(!isOpen);
  const togglePage = () => setIsScheduleOpen(!isScheduleOpen);
  const toggleProduct = () => setIsProductOpen(!isProductOpen);
  const toggleCategory = () => setIsCategoryOpen(!isCategoryOpen);
  //   const togglehome = () => setIsHometOpen(!isHome);
  //   const toggleProductPage = () => setIsProductPageOpen(!isProductPageOpen);
  //   const toggleCategoryPage = () => setIsCatPageOpen(!isCatPageOpen);

  return (
    <>
      <Row>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7" className="text-truncate">
              <span className="pageText">SEO Settings</span>
            </Col>
            <Col xs="4">
              {previewEnabled ?
                <Select
                  className="formText"
                  name="indexingMode"
                  id="indexingMode"
                  value={indexingMode}
                  onChange={onIndexingModeChange}
                  options={IndexingModeList}
                />
                : ""}
            </Col>
            {
              false && (
                <Col xs="12" md="5" className="d-flex justify-content-end">
                  <Button type="button" onClick={onHandleCancel} className={clsx(styles.borderRadius2, styles.btn1, styles.secondaryColor, "text-truncate", "mr-2")}>
                    CANCEL
               </Button>
                  <Button type="button" className={clsx(styles.borderRadius2, styles.btn2, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                    SAVE
               </Button>
                </Col>
              )
            }
          </Row>
          <Row className={clsx(styles.block2, "common-page-card")}>
            <Col xs="12">
              <div className="d-flex align-items-center m-0 text-truncate flex-shrink-1 pr-3">
                <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Site Map</span>
              </div>
              {
                false && (
                  <>
                    <hr />
                    <div className="pt-3">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={clsx(styles.block2, { active: activeTab === "1" })}
                            onClick={() => { toggleTab("1"); }}
                          >
                            XML Site Map
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink

                            className={clsx({ active: activeTab === "2" })}
                            onClick={() => { toggleTab("2"); }}
                          >
                            Settings
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </>
                )
              }
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="bg-white">
                  <Form onSubmit={onSubmit}>
                    <Row className={clsx(styles.block2, "common-page-card")}>
                      <Col xs="12">
                        <div className="my-2">
                          <SiteMap indexingMode={indexingMode} previewEnabled={previewEnabled} />
                        </div>
                        {/*
                        <Row className="m-0 ">
                          <Col xs="12" md="10" lg="5" className="p-0">
                            <div className="d-flex align-items-center m-0 text-truncate flex-shrink-1 pr-3">
                              <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Your XML Site Map URL</span>
                            </div>
                          </Col>
                        </Row>
                         <Row className="m-2">
                          <Col xs="12" md="10" lg="5" className="p-0">
                            <div className="flex-grow-1 p-0">
                              <Input />
                              {" "}
                              <span className={styles.date}>Last modified date:06/04/2020</span>
                            </div>
                          </Col>
                          <Col xs="12" md="10" lg="7">
                            <div className={clsx(styles.areaActionRight)}>
                              <Button className={clsx(styles.buttonColor, "mr-1")}> Update</Button>
                              <Button className={clsx(styles.buttonColor, "ml-1")}>Download</Button>
                            </div>
                          </Col>
                        </Row> */}
                        {
                          false && (
                            <Row className={clsx(styles.tableWrapper, " common-page-card")}>
                              <Col xs="12">
                                <Row className="m-0 ">
                                  <Col xs="12" md="10" lg="5" className="p-0">
                                    <div className="d-flex align-items-center m-0 text-truncate flex-shrink-1 pr-3">
                                      <span className="fontsizeMediumBold align-items-center text-truncate m-2 pr-3 d-flex">URLS</span>
                                    </div>
                                  </Col>
                                  <Col xs="12" md="10" lg="7">
                                    <div className={clsx(styles.areaActionRight, "m-1")}>
                                      <AddUrl
                                        setForm={setForm}
                                        form={form}
                                        toggle
                                        handleFormChange={handleFormChange}
                                        onHandleSave={onHandleSave}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div className="custom-table mt-3 ">
                                  <CustomizationTable
                                    customRows={customRows}
                                    customizationData={customizationData}
                                    isDeleteEnable
                                    isEditEnable
                                  />
                                </div>
                                <div className={clsx(styles.pagination, "p-3", "d-flex", "justify-content-end")}>
                                  <div className="d-flex">
                                    <span className={clsx(styles.item, styles.arrowLeft)} disabled>
                                      <span />
                                    </span>
                                    <span className={styles.item} active="active"> 1 </span>
                                    <span className={styles.item}> 2 </span>
                                    <span className={styles.item}> 3 </span>
                                    <span className={clsx(styles.item, styles.arrowRight)}>
                                      <span />
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          )
                        }
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
                <TabPane tabId="2">
                  <Row className={clsx(styles.block2, "common-page-card")}>
                    <Col xs="12">
                      <Row className="m-0 ">
                        <Col xs="12" className="p-0">
                          <div className="d-flex align-items-center m-0 text-truncate flex-shrink-1 pr-3">
                            <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Site Map Settings</span>
                          </div>
                          <hr />
                          <div className="card">
                            <div className="card-header">
                              <label onClick={toggleUrl} className={styles.cardTitle}>
                                Sitemap Url
                              </label>
                              <span className={clsx(styles.collapseIcon)}>
                                <img src={RightArrowIcon} className={clsx(isOpen ? styles.openCollapse : styles.closeCollapse)} alt="" />
                              </span>
                            </div>
                          </div>
                          <Collapse isOpen={isOpen}>
                            <div className={clsx(styles.cardBody, "card-body m-2")}>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="default" value="defaultUrl" />
                                <label className="form-check-label" htmlFor="default">Use Default URL's</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input checked className="form-check-input" type="radio" name="inlineRadioOptions" id="custom" value="customUrl" />
                                <label className="form-check-label" htmlFor="custom">Use Custom URL's</label>
                              </div>
                            </div>
                          </Collapse>
                        </Col>
                      </Row>
                      <Row className="m-0 ">
                        <Col xs="12" className="p-0">
                          <div className="mt-3">
                            <div className="card">
                              <div className="card-header">
                                <label onClick={togglePage} className={styles.cardTitle}>
                                  Page Priority and  Update Schedule
                                </label>
                                <span className={clsx(styles.collapseIcon)}>
                                  <img src={RightArrowIcon} className={isScheduleOpen ? styles.openCollapse : styles.closeCollapse} alt="" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <Row className={clsx(styles.block3, "common-page-card m-3")}>
                            <Col xs="12" className="p-0">
                              <Collapse isOpen={isScheduleOpen}>
                                <div className="accordion">

                                  <div className="card-header">

                                    <label onClick={toggleProduct}>
                                      Product
                                    </label>
                                    <span className={clsx(styles.collapseIcon)}>
                                      <img src={RightArrowIcon} className={isProductOpen ? styles.openCollapse : styles.closeCollapse} alt="" />
                                    </span>
                                  </div>
                                  <Collapse isOpen={isProductOpen}>
                                    <PrioritySchedule
                                      productPriority={productPriority}
                                      productSchedule={productSchedule}
                                      handleChange={handleChange}
                                      name="Priority"
                                    />
                                  </Collapse>
                                  <div className="card-header">
                                    <label onClick={toggleCategory}>
                                      Category
                                    </label>
                                    <span className={clsx(styles.collapseIcon)}>
                                      <img src={RightArrowIcon} className={isCategoryOpen ? styles.openCollapse : styles.closeCollapse} alt="" />
                                    </span>
                                  </div>
                                  <Collapse isOpen={isCategoryOpen}>
                                    <PrioritySchedule
                                      categoryPriority={categoryPriority}
                                      categorySchedule={categorySchedule}
                                      handleChange={handleChange}
                                      name="Schedule"

                                    />

                                  </Collapse>
                                  {/* <div className="card-header">
                                    <label onClick={togglehome}>
                                      Home Page
                                    </label>
                                    <span className={clsx(styles.collapseIcon)}>
                                      <img src={RightArrowIcon} className={isHome ? styles.openCollapse : styles.closeCollapse} alt="" />
                                    </span>
                                  </div>
                                  <Collapse isOpen={isHome}>
                                    <PrioritySchedule />
                                  </Collapse>
                                  <div className="card-header">
                                    <label onClick={toggleCategoryPage}>
                                      Sub Category Pages
                                    </label>
                                    <span className={clsx(styles.collapseIcon)}>
                                      <img src={RightArrowIcon} className={isCatPageOpen ? styles.openCollapse : styles.closeCollapse} alt="" />
                                    </span>
                                  </div>
                                  <Collapse isOpen={isCatPageOpen}>
                                    <PrioritySchedule />
                                  </Collapse>
                                  <div className="card-header">
                                    <label onClick={toggleProductPage}>
                                      Detailed Product Pages
                                    </label>
                                    <span className={clsx(styles.collapseIcon)}>
                                      <img src={RightArrowIcon} className={isProductPageOpen ? styles.openCollapse : styles.closeCollapse} alt="" />
                                    </span>
                                  </div>
                                  <Collapse isOpen={isProductPageOpen}>
                                    <PrioritySchedule />
                                  </Collapse> */}
                                </div>
                              </Collapse>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

SiteMapManagement.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  onHandleSave: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  productPriority: PropTypes.string.isRequired,
  categoryPriority: PropTypes.string.isRequired,
  productSchedule: PropTypes.string.isRequired,
  categorySchedule: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  indexingMode: PropTypes.string.isRequired,
  IndexingModeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  onIndexingModeChange: PropTypes.func.isRequired,
};
export default SiteMapManagement;
