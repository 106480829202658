/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */
import AddCoupon from "../components/AddCoupon";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { addCoupon } from "../../../api/couponServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const AddCouponContainer = (props) => {
  const { onSave, onCancel } = props;
  const history = useHistory();
  const positions = constants.NAVIGATION_MENU_POSITIONS;

  /** local states */
  const [form, setForm] = useState({
    code: [],
    description: "",
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    active: false,
    usesPerCoupon: "1",
    usesPerCustomer: "1",
    indefinite: false,
    unlimited: false,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    const formData = {
      ...form,
      [name]: value,
    };
    if (name === "code") {
      formData.code = value.trim().toUpperCase();
    }
    if (name === "unlimited" && value) {
      formData.unlimited = value;
      formData.usesPerCoupon = 0;
    }
    setForm(formData);
  };
  /**
 * This method is used to change form filed input
 * @param {Event} event
 */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used to go back to listing page
     */
  const formCancel = () => {
    if (onCancel) {
      setForm({
        code: [],
        description: "",
        startDate: moment().startOf("day"),
        endDate: moment().endOf("day"),
        active: false,
        usesPerCoupon: "1",
        usesPerCustomer: "1",
        indefinite: false,
        unlimited: false,
      });
      onCancel(false);
    } else {
      history.push("/marketing/coupons");
    }
  };

  const formatCouponCode = (code) => {
    const couponCode = code && code.split(",");
    return (couponCode.map((each) => each.trim()));
  };
  /**
   * This method is used to submit the form for add new coupon
   * @param {Event} event
   */
  const formSubmit = async (data, event) => {
    if (data) {
      if (event) event.preventDefault();
      if (event) event.stopPropagation();
      const requestBody = {
        couponCodes: formatCouponCode(form.code),
        description: form.description,
        startDate: (form.startDate && moment.isMoment(form.startDate) && moment(form.startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")) || null,
        endDate: (form.endDate && moment.isMoment(form.endDate) && moment(form.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")) || null,
        indefinite: !form.endDate,
        active: form.active,
        usesPerCoupon: form.unlimited ? null : form.usesPerCoupon,
        usesPerCustomer: form.usesPerCustomer,
        unlimited: form.unlimited,
      };
      setMessage({ type: null, message: "" });
      setOnSubmitting(true);
      const response = await addCoupon(requestBody);
      if (response && response.success) {
        setOnSubmitting(false);
        if (onSave) {
          onSave(response.data);
        } else {
          setTimeout(() => {
            history.push("/marketing/coupons");
          }, 3000);
        }
      } else {
        setOnSubmitting(false);
        if (response.messages) {
          setMessage({ type: "warning", message: response.messages[0] });
        }
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      }
    }
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_CPNS_CRT_CPNS}
    >
      <AddCoupon
        code={form.code}
        description={form.description}
        startDate={form.startDate}
        endDate={form.endDate}
        active={form.active}
        usesPerCoupon={form.usesPerCoupon}
        usesPerCustomer={form.usesPerCustomer}
        indefinite={form.indefinite}
        unlimited={form.unlimited}
        /** form data */
        positions={positions}
        onSubmitting={onSubmitting}
        /** form methods */
        handleFormChange={handleFormChange}
        handleChange={handleChange}
        formSubmit={formSubmit}
        formCancel={formCancel}
        message={message}
        onCampaign={Boolean(onSave)}
      />
    </Permission>
  );
};
AddCouponContainer.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddCouponContainer;
