/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Facet Customization Container
 *
 * @author Harikrishnan A K
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CONSTANTS from "../../../common/utils/constants";
import FacetCustomization from "../components/FacetCustomization";
import { getCatalogConfiguration } from "../../../api/productManagementServices";
import { facetConfig, updateFacetConfig } from "../../../api/categoryManagementServices";

const FacetCustomizationContainer = () => {
  const [brandId, setBrandId] = useState("");
  const [brands, setBrands] = useState([{
    label: "Select brand",
    disabled: brandId,
    value: "",
  }]);
  const code = CONSTANTS.SEARCH_CONFIG.FACET;
  const history = useHistory();
  const location = useLocation();
  const [facetObject, setFacetObject] = useState(null);
  const [facetCustomizationRows, setFacetCustomizationRows] = useState([]);
  const getFacetConfigurations = (code, brandId) => {
    facetConfig(code, brandId).then((response) => {
      if (response && response.success && response.data) {
        setFacetObject(response.data);
        setFacetCustomizationRows(response.data.defaultConfiguration);
      }
    });
  };
  useEffect(() => {
    getCatalogConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && Array.isArray(data.brands)) {
          const availableBrands = data.brands.map((brand) => ({ label: brand, value: brand }));
          const [defaultBrand] = data.brands;
          setBrands(availableBrands);
          const previousBrand = (location && location.state && location.state.brand) || null;
          if (null != previousBrand) {
            setBrandId(previousBrand);
            getFacetConfigurations(code, previousBrand);
          } else {
            setBrandId(defaultBrand);
            getFacetConfigurations(code, defaultBrand);
          }
        }
      }
    });
  }, []);
  const saveFacetConfig = (newfacets) => {
    const newFacetObject = { ...facetObject, defaultConfiguration: newfacets, brand: brandId };
    const response = updateFacetConfig(newFacetObject);
    if (response) {
      setFacetObject(newFacetObject);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setBrandId(value);
    getFacetConfigurations(code, value);
  };
  const handleActiveStatusChange = (rowIndex) => {
    const modifiedFacetCustomizationRows = facetCustomizationRows.map((facet, index) => {
      if (index === rowIndex) {
        return { ...facet, active: !facet.active };
      }
      return facet;
    });
    saveFacetConfig(modifiedFacetCustomizationRows);
    setFacetCustomizationRows(modifiedFacetCustomizationRows);
  };
  /**
 * This is method is used to handle redirect  to edit Facet page
 *  @param {index} number
 */
  const handleRedirectToFacetEditor = (index) => {
    history.push(`/administration/facet-configurations/edit/${index}/brand/${brandId}`);
  };
  /**
 *This method is used to modify the list after drag
 *
 * @param {Array} list
 * @param {Number} startIndex
* @param {Number} endIndex
* @returns
*/
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
*This method is used ito on drag end
*
* @param {object} result
* @returns
*/
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rowData = reorder(
      facetCustomizationRows,
      result.source.index,
      result.destination.index,
    );
    const modifiedRowData = rowData.map((facet, index) => ({ ...facet, displayOrder: index + 1 }));
    saveFacetConfig(modifiedRowData);
    setFacetCustomizationRows(modifiedRowData);
  };
  return (
    <FacetCustomization
      code={code}
      brands={brands}
      brandId={brandId}
      handleChange={handleChange}
      facetCustomizationRows={facetCustomizationRows}
      facetObject={facetObject}
      handleActiveStatusChange={handleActiveStatusChange}
      handleRedirectToFacetEditor={handleRedirectToFacetEditor}
      onDragEnd={onDragEnd}
    />
  );
};
export default FacetCustomizationContainer;
