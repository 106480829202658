/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */
import React, { useState, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { getProductsByCategoryId, getProductByIds } from "../../../api/productManagementServices";
import FeaturedProductsEditor from "../components/FeaturedProductsEditor";
import commonUtils from "../../../common/utils/commonUtils";
import { getCategoryDetails, updateCategory } from "../../../api/categoryManagementServices";
import { getProductsByBrand } from "../../../api/brandServices";
import { getWidgetConfigurationByWidgetType } from "../../../api/widgetConfigurationsServices";


const FeaturedProductsEditorContainer = () => {
  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [category, setCategory] = useState(null);
  const [widgetConfig, setWidgetConfig] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const {
    categoryId, pageType, widgetType, channel,
  } = commonUtils.getQueryParams(location);

  useEffect(() => {
    getWidgetConfigurationByWidgetType(pageType, widgetType, channel).then((response) => {
      if (response && response.success && response.data) {
        setWidgetConfig(response.data);
      }
    });
  }, [channel, pageType, widgetType]);
  /**
   * This method is used to get products from response
   *
   * @param {*} data
   */
  const getProductsFromResponse = (data) => {
    if (Array.isArray(data)) {
      const listedProductId = productList.map(({ id }) => id);
      setProducts(data);
      setProductList([
        ...(data.filter((each) => each && !listedProductId.includes(each.id))),
        ...productList,
      ]);
    }
  };

  /**
   *This method is used to get get all products by brand
   *
   * @param {*} searchTerm
   */
  const getAllProductsByBrand = async (searchTerm) => {
    const result = await getProductsByBrand(categoryId, searchTerm);
    if (result && result.success) {
      const { data } = result;
      getProductsFromResponse(data);
    }
  };

  /**
   * This method is used to get all products
   *
   * @param {*} searchTerm
   */
  const getAllProductsByCategory = async (searchTerm) => {
    const response = await getProductsByCategoryId(categoryId, searchTerm);
    if (response && response.success) {
      const { data } = response;
      getProductsFromResponse(data);
    }
  };


  /**
     * This method is ued to get product list from server
     * @param {String} searchTerm
     */
  const getProductListFromSever = (searchTerm = "") => {
    if (category && category.type === "Brand") {
      getAllProductsByBrand(searchTerm);
    } else {
      getAllProductsByCategory(searchTerm);
    }
  };

  const [selectedProductDetails, setRelatedProducts] = useState([]);

  /**
       * This method is used to get products
       *
       * @param {*} ids
       */
  const getProducts = async (ids) => {
    const result = await getProductByIds(ids);
    if (result && result.success) {
      setRelatedProducts(result.data);
    }
  };

  useEffect(() => {
    getCategoryDetails(categoryId).then(async (response) => {
      if (response && response.success && response.data) {
        setCategory(response.data);
        const { featuredProducts } = response.data;
        if (Array.isArray(featuredProducts)) {
          setSelectedProducts(featuredProducts);
          getProducts(featuredProducts);
        }
      }
    });
  }, [categoryId]);

  useEffect(() => {
    if (Array.isArray(selectedProductDetails) && selectedProductDetails.length > 0) {
      const listedProductId = productList.map(({ id }) => id);
      const filteredList = selectedProductDetails.filter((each) => each
        && !listedProductId.includes(each.id));
      setProductList([
        ...productList,
        ...filteredList,
      ]);
      setSelectedProducts(selectedProductDetails.map(({ id }) => id));
    }
  }, [selectedProductDetails, productList]);


  /**
     * This method is used to change search filed and trigger search functionality
     * @param {Event} event
     */
  const handleSearchFiled = (event) => {
    const { value } = event.target;
    if (value && `${value}`.trim().length > 1) { getProductListFromSever(value); } else { getProductListFromSever(); }
    setSearchKeyword(value);
  };

  const [error, setError] = useState({ message: "" });
  /**
     * This method is used to set select / deselect product
     * @param {String} productId
     */
  const setProductSelected = (productId) => {
    setError({ message: "" });
    const index = selectedProducts.indexOf(productId);
    let newSelectedList = [];
    if (index < 0) {
      /** if item not exist adding in the list */
      newSelectedList = ([...selectedProducts, productId]);
    } else {
      /** if item exits remove form the list */
      newSelectedList = (selectedProducts.filter((o) => o !== productId));
    }
    getProducts(newSelectedList);
    setSelectedProducts(newSelectedList);
  };

  useEffect(() => {
    if (widgetConfig
      && Array.isArray(selectedProducts)
      && selectedProducts.length > widgetConfig.contentLimit) {
      setError({ message: "Limit reached. You can't add more products" });
    }
  }, [selectedProducts, widgetConfig]);

  useEffect(() => {
    if (category) {
      getProductListFromSever();
    }
  }, [category]);


  /**
    * This method is used to redirect to page-customization
    *
    */
  const handleRedirectToPageCustomization = () => {
    const path = `/merchandising/${channel === "mobile" ? "mobile-app-customization" : "web-app-customization"}?pageType=${pageType}&&categoryId=${categoryId}`;
    history.push(path);
  };


  const handleSave = () => {
    if (!error.message) {
      const newCategory = { ...category };
      newCategory.featuredProducts = selectedProducts;
      updateCategory(newCategory).then((response) => {
        if (response && response.success) {
          handleRedirectToPageCustomization();
        }
      });
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  /**
     * Delete a content
     *
     * @param {*} indexNumber
     */
  const handleDelete = async (id, indexNumber) => {
    setIsLoading(true);
    setSelectedIndex(indexNumber);
    setProductSelected(id);
    const newCategory = { ...category };
    newCategory.featuredProducts = selectedProducts.filter((item) => item !== id);
    updateCategory(newCategory).then((response) => {
      if (response && response.success) {
        setIsLoading(false);
      }
    });
  };

  return (
    <FeaturedProductsEditor
      error={error}
      productList={productList}
      products={products}
      selectedProducts={selectedProducts}
      setProductSelected={setProductSelected}
      searchKeyword={searchKeyword}
      handleSearchFiled={handleSearchFiled}
      handleSubmit={handleSave}
      selectedProductDetails={selectedProductDetails}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      handleDelete={handleDelete}
      handleRedirectToPageCustomization={handleRedirectToPageCustomization}
      channel={channel}
    />
  );
};


export default FeaturedProductsEditorContainer;
