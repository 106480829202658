/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import { FormFeedback } from "reactstrap";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import FormGroup from "../../../common/core/FormGroup";

/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/Select";

/** ========= API SERVICE FUNCTIONS ========= */

const VariantSelector = ({
  onChange, attributes, selectedAttributes, register, errors,
}) => {
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  /**
   * This method is used to translate labels
   * @param {String} key
   */
  const getTranslation = (key) => t(`product:attributes_${key}`);
  return (
    <>
      {
        attributes.map((attribute, index) => (
          <Col xs="12" sm="6" md="5" key={attribute.key}>
            <FormGroup>
              <Label className="formTextLabel">{getTranslation(`${attribute.key}`)}</Label>
              <Select
                className="formText custom-dropdown-validation "
                placeholder={`Select ${getTranslation(`${attribute.key}`)}`}
                name={`${attribute.key}`}
                id={`variant-${index}`}
                onChange={(o) => onChange(o, attribute.key)}
                options={[{ value: "", label: `Select ${getTranslation(`${attribute.key}`)}` }, ...(Array.isArray(attribute.values) && attribute.values.map((value) => ({ value, label: value })))]}
                value={selectedAttributes ? selectedAttributes[attribute.key] : ""}
                invalid={!!(errors[attribute.key] && errors[attribute.key].message)}
                innerRef={
                  register({ validate: (value) => (value !== `Select ${getTranslation(`${attribute.key}`)}` || t(`product:validation_${attribute.key}`)) })
                }
              />
              <FormFeedback>{errors[attribute.key] && errors[attribute.key].message}</FormFeedback>
            </FormGroup>
          </Col>
        ))
      }
    </>
  );
};

VariantSelector.propTypes = {
  /** values */
  attributes: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedAttributes: PropTypes.objectOf(PropTypes.any).isRequired,
  /** state change events */
  onChange: PropTypes.func.isRequired,
  /** validation */
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default VariantSelector;
