/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Table from "../../../common/core/Table";
import Input from "../../../common/core/Input";

/** ======== MODULE STYLES ========== */
import styles from "../css/ProductRankManagement.module.css";

const ProductRankManagement = (props) => {
  const {
    products,
    categoryId,
    page,
    getPageData,
    hasMoreData,
    handleRankChange,
    totalElements,
  } = props;
  const { t } = useTranslation(["common", "product"]);

  const getRank = (productRank) => {
    let rank = null;
    if (productRank !== null) {
      rank = productRank[categoryId];
    }
    return rank;
  };
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className={clsx(styles.pageTitleText, "fontsizeMediumBold")}>Product Rank Settings</span>
          <span style={{ float: "right" }}>
            {t("product:span_total_products")}
            {" "}
            {totalElements}
          </span>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, "common-page-card")}>
        <Col>
          <div className={styles.product}>
            {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
            <div className={clsx(styles.productBody, "table-responsive")}>
              <Table borderless className={styles.productTable}>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <thead>
                  <tr>
                    <th>{t("product:label_id")}</th>
                    <th>{t("product:label_external_id")}</th>
                    <th>{t("common:label_name")}</th>
                    <th>{t("product:label_rank")}</th>
                  </tr>
                </thead>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <tbody>
                  {/* ========= TABLE BODY S[0002] ======================= */}
                  {Array.isArray(products)
                    && products.length > 0 ? (
                      products.map(
                        (product) => (
                          <tr key={product.id} className={styles.productTreeItem}>
                            {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
                            <td>{product.id}</td>
                            <td>{product.uniqueId}</td>
                            <td>
                              <span
                                title={product.name}
                                className={clsx(
                                  styles.productName,
                                  "text-truncate",
                                  "d-block",
                                )}
                              >
                                {product.name}
                              </span>
                            </td>
                            <td>
                              <Input
                                className="formText"
                                placeholder="Product rank"
                                name="productRank"
                                type="number"
                                defaultValue={getRank(product.productRank)}
                                onChange={(e) => handleRankChange(product, e.target.value)}
                              />
                            </td>

                            {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                          </tr>
                        ),
                      )
                    ) : (
                      <tr>
                        <td colSpan={8} align="center" valign="center">
                          {" "}
                          Nothing to show.
                        </td>
                      </tr>
                    )}
                  {/* ========= TABLE BODY E[0002] ======================= */}
                </tbody>
              </Table>
            </div>
            <div
              className={clsx(
                styles.pagination,
                "p-3",
                "d-flex",
                "justify-content-end",
              )}
            >
              <div className="d-flex">
                <span
                  disabled={page < 1}
                  className={clsx(styles.item, styles.arrowLeft)}
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => { }}
                  onClick={(o) => getPageData(-1, o)}
                >
                  <span />
                </span>
                <span className={styles.item} />
                <span
                  disabled={!hasMoreData}
                  className={clsx(styles.item, styles.arrowRight)}
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => { }}
                  onClick={(o) => getPageData(1, o)}
                >
                  <span />
                </span>
              </div>
            </div>
            {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
          </div>
        </Col>
      </Row>
    </>
  );
};

ProductRankManagement.propTypes = {
  // products
  page: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  getPageData: PropTypes.func.isRequired,
  totalElements: PropTypes.number.isRequired,
  handleRankChange: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
};


export default ProductRankManagement;
