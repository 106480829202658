import classnames from "classnames";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Edit from "../../../common/assets/images/svg/edit-icon.svg";
import Home from "../../../common/assets/images/svg/home.svg";
import LeftArrow from "../../../common/assets/images/svg/leftArrow.svg";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import Collapse from "../../../common/core/Collapse";
import Nav from "../../../common/core/Nav";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";
import Row from "../../../common/core/Row";
import TabContent from "../../../common/core/TabContent";
import Table from "../../../common/core/Table";
import TabPane from "../../../common/core/TabPane";
import ProductSelectionContainer from "../../categoryManagement/containers/ProductSelectionContainer";
import commonUtils from "../../../common/utils/commonUtils";
import styles from "../css/ProductsUI.module.css";
import Image from "./Image";
import StaticDetailsView from "./StaticDetailsView";

const ViewProduct = (props) => {
  const {
    form,
    editProduct,
    onHandleCancel,
    productDetails,
    retailerExtensions,
    relatedProductDetails,
    handleChange,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation(["common", "product"]);
  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  // Tabs
  const [activeTab, setActiveTab] = useState("2");
  const toggle1 = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText">
            <strong>{t("common:title_Products")}</strong>
          </span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/dashboard">
                <img src={Home} alt="Home" />
                {t("common:home")}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/merchandising">{t("common:merchandising")}</a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/merchandising/product">{t("common:catalog_Management")}</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t("common:title_Products")}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <Row className="justify-content-between align-items-center mb-4">
            <Col xs="auto">
              <h5 className="mb-0 d-flex align-items-center">
                <Button color="transparent" className="btn-square mr-3">
                  <img src={LeftArrow} alt="Home" onClick={onHandleCancel} />
                </Button>
                <strong>{form.id}</strong>
              </h5>
            </Col>

            {/* TODO: Buttons should be visible only on edit view */}
            {/* <Col xs="auto">
              <Button color="primary" outline className="mr-2 btn-width" onClick={onHandleCancel}>Cancel</Button>
              <Button color="primary" className="btn-width">Save</Button>
            </Col> */}
          </Row>

          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <h4 className="mb-3">{t("product:title_basic_Information")}</h4>
              <div className={styles.displayData}>
                <Row className={clsx(styles.ContentOuter, "mb-3")}>
                  <Col>
                    <Row>
                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("product:placeholder_name")}</strong>
                        </h6>
                        <p>{form.name}</p>
                      </Col>
                      {/* <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("product:label_type")}</strong>
                        </h6>
                        <p>Apparel</p>
                      </Col> */}
                      {form.description ? <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_description")}</strong>
                        </h6>
                        <p>{form.description}</p>
                      </Col> : <></>}
                      {form.longDescription ? <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_long_description")}</strong>
                        </h6>
                        <p>{form.longDescription}</p>
                      </Col> : <></>}

                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_status")}</strong>
                        </h6>
                        <p>{form.active ? t("common:label_active") : t("common:label_inactive")}</p>
                      </Col>
                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_source_status")}</strong>
                        </h6>
                        <p>{form.activeInSource ? t("common:label_active") : t("common:label_inactive")}</p>
                      </Col>
                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_storefront_status")}</strong>
                        </h6>
                        <p>{form.showInStorefront ? t("common:label_visible") : t("common:label_hidden")}</p>
                      </Col>
                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_category")}</strong>
                        </h6>
                        <p>{form.categories}</p>
                      </Col>
                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_primary_category")}</strong>
                        </h6>
                        <p>{form.primaryParentCategory}</p>
                      </Col>
                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_external_Id")}</strong>
                        </h6>
                        <p>{form.uniqueId}</p>
                      </Col>
                      <Col xs="6" className="mb-2">
                        <h6>
                          <strong>{t("common:label_updatedDate")}</strong>
                        </h6>
                        <p>{form.updatedDate}</p>
                      </Col>
                      {form && form.retailerExtensions && commonUtils.isListNotEmpty(form.retailerExtensions.season)
                        ? (
                          < Col xs="6" className="mb-2">
                            <h6>
                              <strong>{t("common:label_seasons")}</strong>
                            </h6>
                            <p>{form.retailerExtensions.season.join()}</p>
                          </Col>) : ''}
                      {form && form.retailerExtensions && commonUtils.isListNotEmpty(form.retailerExtensions.customerProductClassification)
                        ? (
                          < Col xs="6" className="mb-2">
                            <h6>
                              <strong>{t("common:label_customer_classification")}</strong>
                            </h6>
                            <p>{form.retailerExtensions.customerProductClassification.join()}</p>
                          </Col>) : ''}
                      {form && form.retailerExtensions && commonUtils.isListNotEmpty(form.retailerExtensions.marketingProduct)
                        ? (
                          < Col xs="6" className="mb-2">
                            <h6>
                              <strong>{t("common:label_marketing_product")}</strong>
                            </h6>
                            <p>{form.retailerExtensions.marketingProduct.join()}</p>
                          </Col>) : ''}
                      {form && form.retailerExtensions && commonUtils.isListNotEmpty(form.retailerExtensions.newProductB2B)
                        ? (
                          < Col xs="6" className="mb-2">
                            <h6>
                              <strong>{t("common:label_new_product")}</strong>
                            </h6>
                            <p>{form.retailerExtensions.newProductB2B.join()}</p>
                          </Col>) : ''}
                      {form && form.retailerExtensions && commonUtils.isListNotEmpty(form.retailerExtensions.newItemB2B)
                        ? (
                          < Col xs="6" className="mb-2">
                            <h6>
                              <strong>{t("common:label_new_color")}</strong>
                            </h6>
                            <p>{form.retailerExtensions.newItemB2B.join()}</p>
                          </Col>) : ''}
                      {form && form.retailerExtensions && commonUtils.isListNotEmpty(form.retailerExtensions.keyProduct)
                        ? (
                          < Col xs="6" className="mb-2">
                            <h6>
                              <strong>{t("common:label_key_product")}</strong>
                            </h6>
                            <p>{form.retailerExtensions.keyProduct.join()}</p>
                          </Col>) : ''}
                    </Row>
                  </Col>
                  {/* <Col xs="auto" className="tab-icon-col">
                    <Button
                      color="transparent"
                      className="btn-circle"
                      onClick={(o) => editProduct(form.id, o)}
                      onKeyDown={() => { }}
                    >
                      <img src={Edit} alt="Home" />
                    </Button>
                  </Col> */}
                </Row>

                <hr />

                <Row>
                  <Col xs="12">
                    <h6 className="mb-3">
                      <strong>Products</strong>
                    </h6>
                    <ul className={styles.ProductListOuter}>
                      {form.medias ? (
                        form.medias.map((medias, i) => (
                          <li className={styles.ProductItem}>
                            <figure>
                              <img src={form.medias[i].defaultMediaUrl} alt={form?.name} />
                            </figure>
                            <p>{form?.name}</p>
                          </li>
                        ))) : ""}
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>

            <Row className={clsx(styles.tableWrapper, "common-page-card w-100")}>
              <Col xs="12">
                <h5>{t("product:title_variants")}</h5>
                <div className="w-100">
                  {Array.isArray(form.skus) && (
                    <div className="tab-content">
                      <Table responsive hover className="table-vcenter table-common mt-3">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              {t("product:label_skuId")}
                            </th>
                            <th>
                              {t("product:label_size")}
                            </th>
                            <th>{t("common:label_image")}</th>
                            <th>{t("product:label_external_id")}</th>
                            <th>{t("common:label_source_status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* ========= TABLE BODY S[0002] ======================= */}
                          {form.skus.length > 0 ? (
                            form.skus.map((sku) => (
                              <tr key={sku.id}>
                                {/* ======== EACH SKU ITEM S[0003] ============= */}
                                <td className={styles.mw75}>
                                  <span
                                    title={sku.id}
                                    className={clsx("text-truncate", "d-block")}
                                  >
                                    {sku.id}
                                  </span>
                                </td>
                                <td className={styles.mw75}>
                                  <span
                                    title={sku?.skuSelector?.size?.name}
                                    className={clsx("text-truncate", "d-block")}
                                  >
                                    {sku?.skuSelector?.size?.name}
                                  </span>
                                </td>
                                <td className="p-1 align-middle text-left">
                                  <Image
                                    src={getPreviewImage(sku.medias)}
                                    alt={form.name}
                                    className={clsx(
                                      styles.productImage,
                                      "img-fluid",
                                    )}
                                  />
                                </td>
                                <td className={styles.mw75}>{sku.uniqueId}</td>
                                <td>
                                  <div
                                    className={clsx(
                                      "custom-control",
                                      "custom-switch",
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`expand-multiple${0}`}
                                      checked={sku.active}
                                      disabled="true"
                                      onChange={() => { }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`expand-multiple${0}`}
                                    >
                                      <input type="hidden" />
                                    </label>
                                  </div>
                                </td>
                                {/* ======== EACH SKU ITEM E[0003] =============== */}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} align="center" valign="center">
                                {" "}
                                Nothing to show.
                              </td>
                            </tr>
                          )}
                          {/* ========= TABLE BODY E[0002] ======================= */}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Col xs="12" className="tab-item">
              <div className="tab-btn" onClick={toggle}>
                {t("product:title_product_Details")}
              </div>
              <Collapse isOpen={isOpen}>
                <div className="tab-content">
                  <Nav tabs className="tab-custom">
                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle1("1");
                        }}
                      >
                        {t("product:label_related_Products")}
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle1("2");
                        }}
                      >
                        {t("product:label_product_Details")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle1("3");
                        }}
                      >
                        {t("product:label_ external_Attributes")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="px-0">
                    {/* <TabPane tabId="1">
                      {form.id && (
                        <ProductSelectionContainer
                          onChange={(o) => handleChange("relatedProducts", o)}
                          properties={relatedProductDetails}
                          exclude={[form.id]}
                        />
                      )}
                    </TabPane> */}
                    <TabPane tabId="2">
                      <Row className={clsx(styles.block2, "common-page-card")}>
                        <div className="w-100">
                          <StaticDetailsView
                            properties={productDetails}
                            type="productDetails"
                          />
                        </div>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <Row className={clsx(styles.block2, "common-page-card")}>
                        <div className="w-100">
                          <StaticDetailsView
                            properties={retailerExtensions}
                            type="retailerExtensions"
                          />
                        </div>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Collapse>
            </Col>
          </Row>
        </Col >
      </Row >
    </>
  );
};
ViewProduct.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  editProduct: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
  productDetails: PropTypes.objectOf(PropTypes.any),
  retailerExtensions: PropTypes.objectOf(PropTypes.any),
  relatedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ViewProduct;
