/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import MenuEditor from "../components/MenuEditor";

/** ========= UTILS ========= */


/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { getMenu, updateMenu, getNavigationTypes } from "../../../api/siteNavigationServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import { getTranslationConfig } from "../../../api/translationServices";


const MenuEditorContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  /** redirecting to listing page if there is not any menu id in url */
  if (!params.menuId) {
    history.push("/merchandising/navigation");
  }

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    position: "",
    locale: "",
    active: false,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [navigationType, setNavigationType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locales, setLocales] = useState([]);
  const [defaultLocale, setDefaultLocale] = useState("");

  useEffect(() => {
    getNavigationTypes().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) setNavigationType(data);
      }
    });
    getTranslationConfig().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (data && Array.isArray(data.availableLocales)) {
          setLocales(data.availableLocales.map((each) => ({ label: each, value: each })));
          setDefaultLocale(data.defaultLocale);
        }
      }
    });
  }, []);

  useEffect(() => {
    getMenu(params.menuId).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setForm({
          ...data,
          id: data.id,
          name: data.name || "",
          position: data.type || "",
          locale: data.locale || defaultLocale,
          active: Boolean(data.active),
        });
      }
    });
  }, [params.menuId]);

  /**
     * This method is used to change fom state
     * @param {String} name
     * @param {String} value
     */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
     * This method is used to change form filed input
     * @param {Event} event
     */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
     * This method is used to go back to listing page
     */
  const formCancel = () => {
    history.push("/merchandising/navigation");
  };

  /**
     * This method is used to submit the form for add new menu
     * @param {Event} event
     */
  const formSubmit = async () => {
    setOnSubmitting(true);
    setLoading(true);
    const { localPosition, ...localForm } = form;
    const formData = { ...localForm, type: localPosition };
    const response = await updateMenu(formData);
    if (response && response.success) {
      setTimeout(() => {
        setOnSubmitting(false);
        setLoading(false);
        history.push("/merchandising/navigation");
      }, 3000);
    }
  };
  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_UPD_NVGTN}
    >
      <MenuEditor
        name={form.name}
        position={form.position}
        locale={form.locale}
        active={form.active}
        /** form data */
        positions={navigationType}
        locales={locales}
        onSubmitting={onSubmitting}
        /** form methods */
        handleFormChange={handleFormChange}
        handleChange={handleChange}
        formSubmit={formSubmit}
        formCancel={formCancel}
        loading={loading}
      />
    </Permission>
  );
};

export default MenuEditorContainer;
