/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ===== COMPONENTS  =========== */
import BrandListView from "../components/BrandListView";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

/** ===== API SERVICE FUNCTIONS =========== */
import { getAllBrands, setBrandStatus, deleteBrand } from "../../../api/brandServices";
import {
  getSitePreviewConfigurations, getRunningJobs, replicateCatalogue, getCatalogueReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const BrandList = (props) => {
  const history = useHistory();

  /** local states */
  const [brands, setBrands] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const getBrands = (search = undefined) => {
    setLoading(true);
    getAllBrands(search).then((response) => {
      setLoading(false);
      if (response) {
        const { success, data } = response;
        if (success && Array.isArray(data)) {
          setBrands(data);
        }
      }
    });
  };

  useEffect(() => {
    getBrands();
  }, []);

  /**
   * to redirect to add brand page
   */
  const addBrand = () => {
    history.push("/merchandising/brand/new");
  };

  /**
   * This method is sued to  open edit brand
   * @param {String} brandId
   */
  const editBrand = (brandId) => {
    history.push(`/merchandising/brand/edit/${brandId}`);
  };

  /**
   * This method is sued to  open edit brand
   * @param {String} brandId
   */
  const viewDetails = (brandId) => {
    history.push(`/merchandising/brand/details/${brandId}`);
  };

  /**
   * This method used to change product status
   * @param {String} id
   * @param {Boolean} status
   */
  const changeBrandStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    setSelectedBrandId(id);
    const response = await setBrandStatus(id, status);
    if (response && response.success) {
      const newBrands = brands.map((brand) => (brand.id === id
        ? ({ ...brand, active: status }) : brand));
      setBrands(newBrands);
      /** should add a status change toast once the toast functionality implemented */
    }
    setSelectedBrandId(null);
    setSubmitting(false);
  };

  /**
   * This method is used to remove a brand
   * @param {String} brandId
   */
  const removeBrand = (brandId) => {
    setSelectedBrand({ brandId });
  };

  /**
 * This method is used to cancel confirm form
 */
  const cancelConfirm = () => {
    setSelectedBrand(null);
  };

  /**
   * This function is used to change search term
   * @param {Event} event
   */
  const searchTermHandler = (event) => {
    if (event) {
      const { value } = event.target;
      setSearchTerm(value);
      if (`${value}`.length > 2) { getBrands(value); }
      if (!value) { getBrands(); }
    }
  };

  /**
   * This function is used to clear the search
   */
  const clearSearch = () => {
    setSearchTerm("");
    getBrands();
  };

  const keyPress = (event) => {
    if (event && event.key === "Enter") {
      getBrands(searchTerm);
    }
  };

  /**
   * method for confirming delete brand
   */
  const confirmDelete = async () => {
    if (Boolean(selectedBrand) && selectedBrand.brandId) {
      const { brandId } = selectedBrand;
      const response = await deleteBrand(brandId);
      if (response && response.success === true) {
        const newBrands = brands.filter((brand) => (brand.id !== brandId));
        setBrands(newBrands);
      }
      setSelectedBrand(null);
    }
  };


  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const getReplicationStatus = async () => {
    getCatalogueReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last category push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    const runningJobsResponse = await getRunningJobs();
    if (runningJobsResponse) {
      const { data: runningJobs } = runningJobsResponse;
      if (!runningJobs || runningJobs.length === 0) {
        setPushToLiveButtonEnabled(false);
        setShowPushToLiveAlert(true);
        replicateCatalogue().then((response) => {
          if (response && response.success) {
            const alertData = {
              type: "success",
              message: "Categories pushed to live successfully. Please refresh the search indexes through Administration > Search Index Management, there may be a small delay for reflecting the same in live.",
            };
            setAlert(alertData);
          } else {
            const alertData = {
              type: "danger",
              message: "Something went wrong. Push to live failed",
            };
            setAlert(alertData);
          }
        });
      } else {
        const alertData = {
          type: "warning",
          message: "An Automated batch job is running in background, Please wait and try again later to make your changes live.",
        };
        setAlert(alertData);
      }
    }
    return null;
  };


  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_BRND_VIEW_LIST}
    >
      <BrandListView
        brands={brands}
        submitting={submitting}
        selectedBrandId={selectedBrandId}
        searchTerm={searchTerm}
        loading={loading}
        // functional
        handleBrandStatus={changeBrandStatus}
        addBrand={addBrand}
        editBrand={editBrand}
        viewDetails={viewDetails}
        removeBrand={removeBrand}
        clearSearch={clearSearch}
        keyPress={keyPress}
        searchTermHandler={searchTermHandler}
        pushToLiveEnabled={pushToLiveEnabled}
        previewSiteUrl={previewSiteUrl}
        handlePushToLive={handlePushToLive}
        previewEnabled={previewEnabled}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
        message={message}
      />
      <ConfirmationModal
        isOpen={Boolean(selectedBrand)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this brand?"
      />
    </Permission>
  );
};

BrandList.propTypes = {

};

export default BrandList;
