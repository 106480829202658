/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import leftArrowLong from "../../../common/assets/images/svg/left-arrow-long.svg";
import CustomizationTable from "../../../common/components/Table";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Row from "../../../common/core/Row";
import styles from "../css/JobConfiguration.module.css";

const JobStepExAuditDetails = ({
  jobStepExDetailsData,
  canModify,
  onHandleBackStepEx,
  rowDataStep,
  formatDate,
  jobSteExDetailsRow,
  paginationPrev,
  paginationNext,
  startOfResult,
  endOfResult,

}) => {
  const { t } = useTranslation(["common", "job-configuration"]);

  return (
    <Row className={clsx(styles.block1, "common-page-card ")}>
      <Col xs="12">
        {canModify ? (
          <Row className="pb-4">
            <Col xs="12">
              <span className={clsx(styles.leftArrow, "mr-4")}>
                <img
                  src={leftArrowLong}
                  alt="click left"
                  width="30"
                  height="auto"
                  onClick={() => onHandleBackStepEx(null)}
                />
              </span>
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("job-configuration:span_job_step_exec_details")}
              </span>
            </Col>
          </Row>
        ) : (
            ""
          )}
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_name")}</b>
              </Label>
              <p>{rowDataStep.name}</p>
            </FormGroup>
          </Col>

          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_id")}</b>
              </Label>
              <p>{rowDataStep.jobId}</p>
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_step_id")}</b>
              </Label>
              <p>{rowDataStep.id}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_status")}</b>
              </Label>
              <p>{rowDataStep.status}</p>
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_start_date")}</b>
              </Label>
              <p>{formatDate(rowDataStep.startDate)}</p>
            </FormGroup>
          </Col>

          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_end_date")}</b>
              </Label>
              <p>{formatDate(rowDataStep.endDate)}</p>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className={clsx(
          styles.block3,
          styles.tableWrapper,
          " common-page-card",
        )}
        >
          <Col xs="12">
            <Label className={styles.sectionHead}>
              Step Execution Audit List
            </Label>
            <div className="custom-table">
              <CustomizationTable
                customRows={jobSteExDetailsRow}
                customizationData={jobStepExDetailsData}
                isStatusEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
              />
            </div>
            <div
              className={clsx(
                styles.pagination,
                "p-3",
                "d-flex",
                "justify-content-end",
              )}
            >
              <div className="d-flex">
                <span
                  className={clsx(styles.item, styles.arrowLeft)}
                  onClick={paginationPrev}
                  disabled={startOfResult}
                >
                  <span />
                </span>
                <span className="p-2"> </span>
                <span
                  className={clsx(styles.item, styles.arrowRight)}
                  onClick={paginationNext}
                  disabled={endOfResult}
                >
                  <span />
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

JobStepExAuditDetails.propTypes = {
  onHandleBack: PropTypes.func.isRequired,
  jobAuditDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  canModify: PropTypes.bool.isRequired,
  jobStepExDetailsConfigData: PropTypes.arrayOf.isRequired,
  paginationNext: PropTypes.func.isRequired,
  paginationPrev: PropTypes.func.isRequired,
  endOfResult: PropTypes.bool.isRequired,
  startOfResult: PropTypes.bool.isRequired,
};

export default JobStepExAuditDetails;
