/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/Select";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import validateForm from "../../../common/utils/validateForm";
/** ========= SUB COMPONENT ========= */
import CustomerSelectionContainer from "../../customerGroupManagement/containers/CustomerSelectionContainer";
/** ========= MODULE STYLES ========= */
import styles from "../css/CustomerGroupCreatorView.module.css";

const CustomerGroupCreatorView = (props) => {
  const {
    name, description, type, userIds,
    handleFormChange, formSubmit, formCancel,
    onSubmitting, setUserIds,
  } = props;
  const { t } = useTranslation(["common", "customerGroup"]);
  const validator = validateForm();
  const { handleSubmit, register, errors } = validator;
  return (
    <Form onSubmit={handleSubmit(formSubmit)}>
      <fieldset disabled={onSubmitting}>
        <FormGroup>
          <Label className="formTextLabel">
            {t("customerGroup:label_name")}
            <span className="pl-1 text-danger">*</span>
          </Label>
          <Input
            className="formText"
            placeholder={t("customerGroup:placeHolder_name")}
            name="name"
            value={name}
            onChange={handleFormChange}
            innerRef={
              register({
                required: "Please enter a customer group name",
                minLength: {
                  value: 3,
                  message: "Please enter a customer group name",
                },
              })
            }
            invalid={!!(errors.name && errors.name.message)}
          />
          <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="formTextLabel">
            {t("customerGroup:label_description")}
            <span className="pl-1 text-danger">*</span>
          </Label>
          <Input
            className="formText"
            placeholder={t("customerGroup:placeHolder_description")}
            name="description"
            value={description}
            onChange={handleFormChange}
            innerRef={
              register({
                required: "Please enter a valid description",
                minLength: {
                  value: 3,
                  message: "Please enter a valid description",
                },
              })
            }
            invalid={!!(errors.description && errors.description.message)}
          />
          <FormFeedback>{errors.description && errors.description.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="formTextLabel">
            {t("customerGroup:label_type")}
            <span className="pl-1 text-danger">*</span>
          </Label>
          <Select
            className="formText"
            placeholder={t("customerGroup:placeHolder_type")}
            name="type"
            onChange={handleFormChange}
            value={type}
            options={[
              {
                value: "", selected: true, label: "Select Customer Type ",
              },
              { value: "static", label: "Static" },
              // { value: "dynamic", label: "Dynamic" },
            ]}
            innerRef={
              register({
                required: "Please select a customer type",
              })
            }
            invalid={!!(errors.code && errors.code.message)}
          />
          <FormFeedback>{errors.code && errors.code.message}</FormFeedback>
        </FormGroup>
        {type === "static"
          ? (
            <>
              <div className={styles.titleTextContainer}>
                <span className="pageTitleText, fontsizeMediumBold ">
                  {t("customerGroup:label_customer")}
                </span>
              </div>
              <FormGroup>
                <Label className="formTextLabel">
                  {t("customerGroup:label_Select customers")}
                </Label>
                <CustomerSelectionContainer
                  userIds={userIds}
                  setUserIds={setUserIds}
                  isFormSubmit
                />
              </FormGroup>
            </>
          ) : "" }
        <div className="w-100 d-flex justify-content-end">
          <Button type="button" onClick={formCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
            {t("common:button_cancel")}
          </Button>
          <Button type="submit" color="tertiary" disabled={type !== "static"} className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size,btn-tertiary ")}>
            {t("common:button_save")}
          </Button>
        </div>
      </fieldset>
    </Form>
  );
};

CustomerGroupCreatorView.propTypes = {
  // form data
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  setUserIds: PropTypes.func.isRequired,
  // form actions
  onSubmitting: PropTypes.bool.isRequired,
  formSubmit: PropTypes.func.isRequired,
  formCancel: PropTypes.func.isRequired,
};

export default CustomerGroupCreatorView;
