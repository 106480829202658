/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amritha
 */
import clsx from "clsx";
import React from "react";

/** ===== TAG COMPONENTS =========== */
import { useTranslation } from "react-i18next";
import PropTypes, { element } from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Button from "../../../common/core/Button";
import styles from "../css/EditPreOrder.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import MultiSelect from "../../../common/components/MultiSelect/MultiSelect";

const EditPreOrderManagement = (props) => {
  const {
    preOrderCatalogs, handleFormChange, handleCancel, updatePreOrder,
    handleSearch, customers,
    onCustomerGroupChange,
  } = props;
  const { t } = useTranslation(["common", "pre-order"]);
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">
            {t("pre-order:edit_pre_order_title")}
          </span>
        </Col>
        <Col xs="12" md="5" className="d-flex justify-content-end">
          <Button
            type="button"
            color="secondary"
            className={clsx(
              styles.borderRadius2,
              styles.btn,
              styles.secondaryColor,
              "text-uppercase",
              "text-truncate",
              "mr-1",
            )}
            onClick={handleCancel}
          >
            {t("common:button_cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            className={clsx(
              styles.borderRadius2,
              styles.btn,
              styles.primaryColor,
              "text-uppercase",
              "text-truncate",
              "mr-1",
              "button-font-size",
            )}
            onClick={updatePreOrder}
          >
            {t("common:button_save")}
          </Button>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <FormGroup>
          {Array.isArray(preOrderCatalogs) && preOrderCatalogs.map((item, index) => (
            <>
              <Row className="m-0 ml-2">
                <Col xs="12">
                  <Label>
                    {" "}
                    {t("pre-order:title_field")}
                  </Label>
                  <Input
                    type="text"
                    name="field"
                    defaultValue={item.field}
                    onChange={(e) => handleFormChange(e, index)}
                  />
                </Col>
              </Row>
              <Row className="m-0  ml-2 mt-3 ">
                <Col md="12">
                  <Label>
                    {" "}
                    {t("pre-order:seasons_field")}
                  </Label>
                  <Input
                    type="text"
                    name="elements"
                    defaultValue={Array.isArray(item.elements) ? item.elements.join(",") : []}
                    onChange={(e) => handleFormChange(e, index)}
                  />
                </Col>
              </Row>
              <Row className="m-0 ml-2  mt-3 ">
                <Col md="12">
                  <Label>
                    {" "}
                    {t("pre-order:exclude_customer_title")}
                  </Label>
                  <MultiSelect
                    className="formText"
                    name="customerGroup"
                    placeholder="Select customer"
                    id="campaign-customer-group"
                    value={Array.isArray(item.excludedCustomerList) ? item.excludedCustomerList : []}
                    onChange={(e) => onCustomerGroupChange(e, index)}
                    typeahead={handleSearch}
                    options={
                      Array.isArray(customers)
                      && customers.map(({ id: value, name: label }) => ({ value, label }))
                    }
                  />
                </Col>
                <Row className="m-0 ml-1  mt-3 ">
                  <Col
                    xs="12"
                  >
                    <input
                      type="checkbox"
                      id={`status-${index}`}
                      name="status"
                      checked={item.enableExcludeCustomer}
                      onClick={(e) => onCustomerGroupChange(e, index)}
                    />
                    <label htmlFor={`status-${index}`}>
                      {" "}
                      {t("pre-order:enable_exclude_customer_title")}
                    </label>
                  </Col>
                </Row>
              </Row>
            </>
          ))}
        </FormGroup>
      </Row>
    </>
  );
};

EditPreOrderManagement.propTypes = {
  preOrderCatalogs: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  updatePreOrder: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  onCustomerGroupChange: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EditPreOrderManagement;
