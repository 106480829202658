/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amritha
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import CustomizationTable from "../../../common/components/Table";
import Select from "../../../common/components/MultiSelect/MultiSelect";


import styles from "../css/PreOrderManagement.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import Button from "../../../common/core/Button";
import ModalFooter from "../../../common/core/ModalFooter";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import Spinner from "../../../common/core/Spinner";


const PreOrderManagement = (props) => {
  const {
    catalogConfigs,
    isLoading,
    updateData,
    configEdited,
    catalog,
    toggleEditCatalogConfig,
    handleCatalogChange,
    isSubmitting,
    excludedCustomerGroups,
    handleExcludedCustomerGroupsChange,
    excludeCustomer,
    handleExcludeCustomerChange,
    searchCustomerGroup,
    customerGroupList,
  } = props;

  const { t } = useTranslation(["common", "pre-order"]);

  const renderStatus = (row) => (
    <div
      className={clsx(
        "custom-control",
        "custom-switch",
      )}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id={`pre-order-catalog-toggle-${row.id}`}
        checked={row.active}
        disabled={isSubmitting}
        onClick={() => updateData(row.id, "active", !row.active)}
      />
      <label
        className="custom-control-label"
        htmlFor={`pre-order-catalog-toggle-${row.id}`}
      >
        <input type="hidden" />
      </label>
    </div>
  );

  const renderCatalog = (row) => (
    <div>
      <>
        <span>{row.catalog}</span>
        <Permission
          allowed={Rules.Control.MOZCOM_RA_MERCH_PRE_ORDR_CTLG_UPD_PRE_ORDR_CTLG}
        >
          <img
            src={EditIcon}
            className="commonPointer ml-3"
            role="presentation"
            alt="drag indiactor icon"
            width="20px"
            onClick={() => toggleEditCatalogConfig(row)}
          />
        </Permission>
      </>
    </div>
  );

  const customRows = [
    {
      label: "Sales Company",
      path: "salesCompany",
      transform: "",
    },
    {
      label: "Brand",
      path: "brand",
    },
    {
      label: "Catalog",
      path: "render",
      render: renderCatalog,
    },
    {
      label: "Status",
      path: "render",
      render: renderStatus,
    },
  ];
  return (
    <>
      <Row style={{ minHeight: "70vh" }}>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12">
              <span className="pageText">
                {" "}
                {t("pre-order:pre_order_title")}
              </span>
            </Col>
          </Row>
          <Row className={clsx(styles.block2, "common-page-card")}>
            <Col className="mb-2" xs="12" sm="12" md="9">
              <span className="fontsizeMediumBold">
                {" "}
                {t("pre-order:pre_order_catalog")}
              </span>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <div className="custom-table">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={catalogConfigs}
                  isLoading={isLoading}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal isOpen={configEdited} centered>
        <ModalHeader>
          {t("pre-order:edit_catalog_modal_header")}
        </ModalHeader>
        <ModalBody>
          <Label>
            <b>{t("pre-order:table_header_sales_company")}</b>
            &nbsp;
            :
            &nbsp;
            {configEdited ? configEdited.salesCompany : ""}
            ,
            &nbsp;
            <b>{t("pre-order:table_header_brand")}</b>
            &nbsp;
            :
            &nbsp;
            {configEdited ? configEdited.brand : ""}
            &nbsp;
          </Label>
          <FormGroup>
            <Input
              className="formText"
              placeholder={t("pre-order:placeholder_catalog_input")}
              name="catalog"
              onChange={handleCatalogChange}
              value={catalog}
            />
          </FormGroup>
          <Label>
            <b>{t("pre-order:label_exclude_customer_group")}</b>
          </Label>
          <FormGroup>
            <Select
              options={customerGroupList}
              typeahead={(a) => searchCustomerGroup(a, configEdited)}
              typeaheadPlaceholder="Type to search customer groups"
              onChange={handleExcludedCustomerGroupsChange}
              value={excludedCustomerGroups}
              typeaheadClassName="d-flex"
            />
          </FormGroup>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="excludeCustomer"
              name="excludeCustomer"
              checked={excludeCustomer}
              onChange={() => handleExcludeCustomerChange(!excludeCustomer)}
            />
            <label className="custom-control-label" htmlFor="excludeCustomer">
              <b>{t("pre-order:label_enable_excluded_customer")}</b>
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!catalog || isSubmitting}
            onClick={() => catalog && updateData(configEdited.id, "catalog", catalog)}
          >
            {isSubmitting
              ? (
                <Spinner size="sm" animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : t("pre-order:button_save")}
          </Button>
          <Button
            onClick={() => toggleEditCatalogConfig(null)}
          >
            {t("pre-order:button_cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
PreOrderManagement.propTypes = {
  catalogConfigs: PropTypes.arrayOf(PropTypes.any).isRequired,
  customerGroupList: PropTypes.arrayOf(PropTypes.any).isRequired,
  configEdited: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  catalog: PropTypes.string.isRequired,
  updateData: PropTypes.func.isRequired,
  toggleEditCatalogConfig: PropTypes.func.isRequired,
  handleCatalogChange: PropTypes.func.isRequired,
  handleExcludedCustomerGroupsChange: PropTypes.func.isRequired,
  excludedCustomerGroups: PropTypes.objectOf(PropTypes.any).isRequired,
  excludeCustomer: PropTypes.bool.isRequired,
  handleExcludeCustomerChange: PropTypes.func.isRequired,
  searchCustomerGroup: PropTypes.func.isRequired,
};


export default PreOrderManagement;
