/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React from "react";
import clsx from "clsx";
import { Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import styles from "../css/TaxClasses.module.css";
import CustomizationTable from "../../../common/components/Table";
import Button from "../../../common/core/Button";
import NewTaxClassFormContainer from "../containers/NewTaxClassFormContainer";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
/** ========= CUSTOM COMPONENTS ========= */
import validateForm from "../../../common/utils/validateForm";

const TaxClasses = ({
  name,
  onHandleNewClass,
  isOpen,
  setName,
  setCode,
  setDescription,
  submitForm,
  submitting,
  data,
  onHandleEditClass,
  handletaxStatus,
  onHandleDeleteModalOpen,
  submit,
  selectedDataId,
}) => {
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
          submit && selectedDataId === row.id ? "loading" : "",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) => handletaxStatus(!row.active, row.id, o)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };
  const customRows = [
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Code",
      value: "",
      path: "code",
      transform: "",
    },
    {
      label: "Description",
      value: "",
      path: "description",
      transform: "",
    },
    {
      label: "Status",
      path: "render",
      render: getStatus,
    },
  ];
  const { t } = useTranslation(["tax-configuration", "common"]);
  const validator = validateForm();
  const { handleSubmit } = validator;

  return (
    <>
      <Form onSubmit={handleSubmit(submitForm)}>
        <fieldset disabled={submitting}>
          <Row className="common-page-card pageHeader">
            <Col xs="12">
              <span className="pageText">
                {t("tax-configuration:tax_configuration_title")}
              </span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "common-page-card ")}>
            <Col xs="12">
              <Row className="m-0 ">
                <Col xs="6" className="p-0">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    {t("tax-configuration:span_tax_classes")}
                  </span>
                </Col>
                <Col className="mb-2" xs="6" sm>
                  <Permission
                    allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_CLS_CRT_TAX_CLS}
                  >
                    <div className={styles.areaActionRight}>
                      <div>
                        {!isOpen ? (
                          <Button
                            color="primary"
                            className={clsx(
                              styles.borderRadius2,
                              "text-uppercase",
                              "text-truncate",
                              "btn-tertiary",
                              "ml-1",
                            )}
                            onClick={onHandleNewClass}
                          >
                            {t("tax-configuration:button_add_new_tax_class")}
                          </Button>
                        ) : (
                          <>
                            <Button
                              color="secondary"
                              className={clsx(
                                styles.borderRadius2,
                                styles.btn,
                                styles.secondaryColor,
                                "text-uppercase",
                                "text-truncate",
                                "btn-tertiary",
                                "ml-1",
                              )}
                              onClick={onHandleNewClass}
                            >
                              {t("common:button_cancel")}
                            </Button>
                            <Button
                              color="primary"
                              className={clsx(
                                styles.borderRadius2,
                                "text-uppercase",
                                "text-truncate",
                                "btn-tertiary",
                                "ml-1",
                              )}
                              type="submit"
                            >
                              {t("common:button_save")}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </Permission>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="common-page-card">
            <Col xs="12">
              <Row>
                <Col xs="12">
                  {!isOpen ? (
                    <div className={clsx(styles.tableWrapper, "custom-table")}>
                      <CustomizationTable
                        customRows={customRows}
                        customizationData={data}
                        isDeleteEnable
                        isEditEnable
                        onEditData={onHandleEditClass}
                        onDeleteData={onHandleDeleteModalOpen}
                      />
                    </div>
                  ) : (
                    <NewTaxClassFormContainer
                      setName={setName}
                      setCode={setCode}
                      setDescription={setDescription}
                      validator={validator}
                      name={name}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  );
};

TaxClasses.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHandleNewClass: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  onHandleEditClass: PropTypes.func.isRequired,
  onHandleDeleteModalOpen: PropTypes.func.isRequired,
  handletaxStatus: PropTypes.func.isRequired,
  submit: PropTypes.bool.isRequired,
  selectedDataId: PropTypes.string.isRequired,
  name: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TaxClasses;
