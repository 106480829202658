/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author kishor
 *
 */

import httpApi from "./httpApi";

/**
 * This method is used to retrieve the current business status from analytics.
 *
 */

const getAnalyticsPreface = async (requests) => {
  const response = await httpApi.post("v1/b2b/analytics/status", requests);
  if (response && response.data && response.data.data) {
    return response.data.data;
  }
  return null;
};

const getAnalyticData = async (request) => {
  const response = await httpApi.post("v1/b2b/analytics/graph-data", request);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getCatalogConfiguration = async () => {
  const response = await httpApi.get("v1/b2b/products/catalog-configuration");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getAnalyticsPreface,
  getAnalyticData,
  getCatalogConfiguration,
};
