/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * ProductsTable Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getCategoryDetails, updateCategory } from "../../../api/categoryManagementServices";
import { getProductByIds } from "../../../api/productManagementServices";
import ProductsTable from "../components/ProductsTable";

const ProductsTableContainer = (props) => {
  const {
    categoryId,
  } = props;

  const [products, setProducts] = useState([]);
  //   const [productIds, setProductIds] = useState([]);
  const [category, setCategory] = useState(null);


  /**
   * This method is used to get product by ids
   *
   * @param {*} ids
   */
  const getProducts = async (ids) => {
    const result = ids && await getProductByIds([...ids]);
    if (result && result.success && result.data) {
      setProducts(result.data);
    }
  };

  useEffect(() => {
    if (categoryId) {
      getCategoryDetails(categoryId).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setCategory(data);
          const { featuredProducts } = data;
          //   setProductIds(featuredProducts);
          await getProducts(featuredProducts);
        }
      });
    }
  }, [categoryId]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  /**
  * Delete a featured product
  *
  * @param {*} id
  * @param {*} indexNumber
  */
  const handleDelete = async (id, indexNumber) => {
    setIsLoading(true);
    setSelectedIndex(indexNumber);
    const newCategory = { ...category };
    const ids = category.featuredProducts.filter((item) => item !== id);
    newCategory.featuredProducts = ids;
    updateCategory(newCategory).then((response) => {
      if (response && response.success) {
        getCategoryDetails(categoryId).then(async (responseIn) => {
          if (responseIn && responseIn.success && responseIn.data) {
            const { data } = responseIn;
            setCategory(data);
            const { featuredProducts } = data;
            // setProductIds(featuredProducts);
            await getProducts(featuredProducts);
          }
        });
        setIsLoading(false);
      }
    });
  };

  return (
    <ProductsTable
      handleDelete={handleDelete}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      customizationData={products}
    />
  );
};
ProductsTableContainer.propTypes = {
  categoryId: PropTypes.string.isRequired,
};
export default ProductsTableContainer;
