/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */
import BrandEditorView from "../components/BrandEditorView";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import ConfirmationModal from "../../../common/components/ConfirmationModal";

/** ========= API SERVICE FUNCTIONS ========= */
import { getBrandDetails, updateBrand, deleteBrand } from "../../../api/brandServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const BrandEditor = () => {
  const history = useHistory();

  /** brand id from url params */
  const { brandId } = useParams();

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    description: "",
    longDescription: "",
    startDate: new Date(),
    endDate: new Date(),
    status: false,
    childProducts: [],
    childProductDetails: [],
    bannerImage: [],
    image: [],
    exclusiveBrand: false,
    uniqueId: "",
    logo: null,
  });
  const [submitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [selectedBrand, setSelectedBrand] = useState(false);

  useEffect(() => {
    /**
   * This function is used to construct media
   * @param {String} largeMediaUrl
   * @returns {Object}
   */
    const mediaObjectConstructor = (largeMediaUrl) => ({
      defaultMediaUrl: null,
      largeMediaUrl,
      mediaSwatchUrl: null,
      mediaThumbnailUrl: null,
      mimeType: null,
      smallMediaUrl: null,
    });

    getBrandDetails(brandId).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (data) {
          setForm({
            id: data.id,
            name: data.name,
            description: data.description || "",
            longDescription: data.longDescription || "",
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            status: data.active,
            uniqueId: data.uniqueId,
            childProducts: data.featuredProducts || [],
            childProductDetails: ((Array.isArray(data.featuredProductDetails) && data.featuredProductDetails)),
            bannerImage: data.bannerImages ? data.bannerImages.map((largeMediaUrl) => ({ largeMediaUrl })) : null,
            image: data.medias || [],
            exclusiveBrand: data.featured,
            logo: data.logoUrl && mediaObjectConstructor(data.logoUrl),
          });
        }
      }
    });
  }, [brandId]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
  * This method is used to set selected product
  * @param {Array} products
  */
  const selectedProductChange = (products) => {
    handleChange("childProducts", products);
  };

  /**
   * Clear button trigger
   */
  const formCancelHandler = () => {
    history.push("/merchandising/brand");
  };

  /**
   * This function is used to get media url
   * @param {String} ,mediaObject
   */
  const getMediaUrl = (mediaObject) => ((mediaObject && mediaObject.largeMediaUrl) || null);

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const formSubmitHandler = async (event) => {
    let tmpBannerImage = [];
    if (form.bannerImage) {
      tmpBannerImage = Array.isArray(form.bannerImage)
        ? form.bannerImage.map(({ largeMediaUrl }) => largeMediaUrl)
        : [form.bannerImage.largeMediaUrl];
    }

    const dataToServer = {
      id: form.id,
      name: form.name,
      description: form.description,
      longDescription: form.longDescription,
      active: form.status,
      featuredProducts: form.childProducts,
      uniqueId: "",
      startDate: moment(form.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(form.endDate).format("YYYY-MM-DD HH:mm:ss"),
      medias: [...(Array.isArray(form.image) ? form.image : [form.image])],
      bannerImages: tmpBannerImage,
      featured: form.exclusiveBrand,
      logoUrl: getMediaUrl(form.logo),
    };
    setMessage({ type: null, message: "" });
    setOnSubmitting(true);
    /** now passing object itself into service */
    const response = await updateBrand(dataToServer);
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages[0] });
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/merchandising/brand");
        setMessage({ type: null, message: "" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      console.error(response.error);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  /**
   * This method is used to cancel confirm form
   */
  const cancelConfirm = () => {
    setSelectedBrand(null);
  };

  /**
 * This method is used to delete the brand
 */
  const formDeleteHandler = () => {
    setSelectedBrand(true);
  };

  /**
   * method for delete confirm
   */
  const confirmDelete = async () => {
    if (selectedBrand && brandId) {
      const response = await deleteBrand(brandId);
      if (response && response.success === true) {
        history.push("/merchandising/brand");
      }
    }
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_BRND_UPD_BRND}
    >
      <BrandEditorView
        // form field
        id={form.id}
        name={form.name}
        description={form.description}
        longDescription={form.longDescription}
        startDate={form.startDate}
        endDate={form.endDate}
        status={form.status}
        childProducts={form.childProducts}
        childProductDetails={form.childProductDetails}
        bannerImage={form.bannerImage}
        exclusiveBrand={form.exclusiveBrand}
        image={form.image}
        logo={form.logo}
        // for showing success message
        message={message}
        // disable text filed while form submitting
        submitting={submitting}
        // input filed handle change
        handleFormChange={handleFormChange}
        handleChange={handleChange}
        // form actions
        formSubmitHandler={formSubmitHandler}
        formCancelHandler={formCancelHandler}
        formDeleteHandler={formDeleteHandler}
        // handle product
        selectedProductChange={selectedProductChange}
      />
      <ConfirmationModal
        isOpen={selectedBrand}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this brand ?"
      />
    </Permission>
  );
};

export default BrandEditor;
