/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * common utils
 *
 * @author Naseef O
 *
 */

import queryString from "query-string";

/**
 *This method is used to get query params from location
 *
 * @param {Object} location
 */
const getQueryParams = (location) => queryString.parse(location.search);

/**
 * This method is used to get mindate
 *
 * @param {*} currentDate
 * @param {*} date
 */
const minDate = (currentDate, date) => currentDate.isAfter(date);

/**
 * This method is used to get maxDate
 *
 * @param {*} currentDate
 * @param {*} date
 */
const maxDate = (currentDate, date) => currentDate.isBefore(date);

/**
 * This method is used to convert camelCase word to normal sentence case words
 *
 * @param {*} str
 */
const toSentenceCase = (str) => str.replace(/^[a-z]|[A-Z]/g, (v, i) => (i === 0 ? v.toUpperCase() : ` ${v.toLowerCase()}`));

/**
 *  To check list is not empty or not null
 *  Null check
 * @param {*} list
 */
const isListNotEmpty = (list) => {
  if (list && Array.isArray(list) && list.length > 0) {
    return true;
  }
  return false;
};

/**
  To sort sizes of products
 */
const sizeOrder = ["XXXS",
  "XXS",
  "XXS/S",
  "XXS/R",
  "XXS/L",
  "XS",
  "XS/S",
  "XS/R",
  "XS/L",
  "S",
  "M",
  "L",
  "XL",
  "XL/S",
  "XL/R",
  "XL/L",
  "XXL",
  "XXL/S",
  "XXL/R",
  "XXL/L",
  "XXXL",
  "XXXXL",
  "ONESIZE",
  "OneSize",
  "1 Size",
  "3",
  "3,5",
  "3,5-5",
  "4",
  "4,5",
  "5",
  "5,5",
  "5,5-7,5",
  "6",
  "6,5",
  "7",
  "7,5",
  "8",
  "8-9,5",
  "8,5",
  "9",
  "9,5",
  "10",
  "10,5",
  "10,5-12",
  "11",
  "11,5",
  "12",
  "12,5",
  "13",
  "13,5",
  "14",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "34/S",
  "34/R",
  "34/L",
  "35",
  "36",
  "36/S",
  "36/R",
  "36/L",
  "37",
  "38",
  "38/S",
  "38/R",
  "38/L",
  "39",
  "40",
  "40/S",
  "40/R",
  "40/L",
  "41",
  "42",
  "42/S",
  "42/R",
  "42/L",
  "43",
  "44",
  "44/S",
  "44/R",
  "44/L",
  "45",
  "46",
  "46/S",
  "46/R",
  "46/L",
  "47",
  "48",
  "48/S",
  "48/R",
  "48/L",
  "49",
  "50",
  "50/S",
  "50/R",
  "50/L",
  "51",
  "52",
  "52/S",
  "52/R",
  "52/L",
  "53",
  "54",
  "54/S",
  "54/R",
  "54/L",
  "55",
  "56",
  "56/S",
  "56/R",
  "56/L",
  "57",
  "58",
  "58/S",
  "58/R",
  "58/L",
  "59",
  "60",
  "60/S",
  "60/R",
  "60/L",
  "61",
  "62",
  "63",
  "64",
  "65",
  "98",
  "104",
  "110",
  "116",
  "122",
  "128",
  "134",
  "140",
  "146",
  "152",
  "158",
  "75cm",
  "85cm",
  "95cm",
  "110cm",
  "120cm",
  "42/30",
  "44/30",
  "46/30",
  "48/30",
  "50/30",
  "52/30",
  "54/30",
  "56/30",
  "58/30",
  "60/30",
  "40 R",
  "42 R",
  "44 R",
  "46 R",
  "48 R",
  "50 R",
  "52 R",
  "54 R",
  "56 R",
  "58 R",
  "60 R",
  "62 R",
  "64 R",
  "A: 32",
  "A: 34",
  "A: 36",
  "A: 38",
  "A: 40",
  "A: 42",
  "A: 44",
  "A: 46",
  "A: 48",
  "A: 50",
  "A: 52",
  "A: XXS",
  "A: XS",
  "A: S",
  "A: M",
  "A: L",
  "A: XL",
  "A: XXL",
  "XXS/S",
  "XS/S",
  "S/S",
  "M/S",
  "L/S",
  "XL/S",
  "XS/L",
  "S/L",
  "M/L",
  "L/L",
  "XL/L",
  "XXL/L",
  "XXS/R",
  "XS/R",
  "S/R",
  "M/R",
  "L/R",
  "XL/R",
  "XXL/R",
  "S/M",
  "L/XL",
];

const sortBySize = (sizes) => {
  if (!sizes || !Array.isArray(sizes) || sizes.length === 0) {
    return sizes;
  }
  return sizes.sort((a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b));
};


export default {
  getQueryParams,
  minDate,
  maxDate,
  toSentenceCase,
  isListNotEmpty,
  sortBySize,
};
