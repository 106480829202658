/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Midhun Murali
 */

import React, { useState, useEffect } from "react";
import Preface from "./Preface";
import constants from "../../../common/utils/constants";


const PrefaceManager = ({ prefaceData, getIconAndBackgroundColor, selectedSalesCompany }) => {
  const [prefaces, setPrefaces] = useState([]);

  useEffect(() => {
    let prefacesList = [];
    if (prefaceData && Array.isArray(prefaceData)) {
      prefaceData.map((pf) => {
        const { textValue, icon, color } = getIconAndBackgroundColor(pf?.description);
        let preface = {
          title: getPrefaceTitle(pf?.title, pf?.description),
          description: pf?.description,
          orginalText: pf?.title,
          attributes: pf?.attributes,
          textValue: textValue,
          icon: icon,
          color: color
        };
        prefacesList.push(preface);
      });
      setPrefaces(prefacesList);
    }
  }, [prefaceData]);

  const getPrefaceTitle = (title, description) => {
    const keys = Object.values(constants.PREFACE_KEY);
    if (keys.includes(description) && !selectedSalesCompany) {
      return "Please select sales company";
    }
    if (title) {
      if (isStringNumeric(title)) {
        return title;
      }
      else {
        if (description == "totalOrderPrice") {
          return parseInt(title).toFixed(2);
        }
        if (title.length > 19) {
          return <><span>{JSON.stringify(title).slice(0, 19).replace(/"/g, '')}</span><span id='tooltip' >... </span></>
        }
        return title;
      }
    } else {
      if (isStringNumeric(title)) {
        return 0.00;
      }
      else {
        return "NA";
      }

    }
  }
  function isStringNumeric(str) {
    return /^\d+$/.test(str);
  }
  return (
    prefaces.map((preface, index) => (
      <Preface
        index={index}
        attributes={preface.attributes}
        title={preface.title}
        orginalText={preface.orginalText}
        description={preface.description}
        textValue={preface.textValue}
        icon={preface.icon}
        color={preface.color}
        isStringNumeric={isStringNumeric}
      />
    ))

  );
};
export default PrefaceManager;
