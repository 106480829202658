/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Deepak D
 */

import clsx from "clsx";
import React from "react";

/** ===== TAG COMPONENTS =========== */
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Button from "../../../common/core/Button";
import styles from "../css/ShippingMethod.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Spinner from "../../../common/core/Spinner";

const EditShippingMethod = ({
  form,
  handleFormChange,
  shippingTypes,
  taxClasses,
  shippingMethods,
  shippingPriceStrategies,
  shippingTypeDropdownOpen,
  shippingMethodDropdownOpen,
  shippingPriceStrategyDropdownOpen,
  taxClassDropdownOpen,
  toggleTaxClassDropdown,
  toggleShippingTypeDropdown,
  toggleShippingMethodDropdown,
  toggleShippingPriceStrategyDropdown,
  handleShippingTypeDropdownSelection,
  handleShippingMethodDropdownSelection,
  handleShippingPriceStrategyDropdownSelection,
  handleTaxClassDropdownSelection,
  onHandleSave,
  onHandleCancel,
  loading,
}) => {
  const { t } = useTranslation(["common", "shipment"]);
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">
            {t("shipment:edit_shipment_method_title")}
          </span>
        </Col>
        <Col xs="12" md="5" className="d-flex justify-content-end">
          <Button
            type="button"
            color="secondary"
            className={clsx(
              styles.borderRadius2,
              styles.btn,
              styles.secondaryColor,
              "text-uppercase",
              "text-truncate",
              "mr-1",
            )}
            onClick={onHandleCancel}
          >
            {t("common:button_cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            className={clsx(
              styles.borderRadius2,
              styles.btn,
              styles.primaryColor,
              "text-uppercase",
              "text-truncate",
              "mr-1",
              "button-font-size",
            )}
            onClick={onHandleSave}
          >
            {
              loading ? (
                <Spinner size="sm" />
              ) : "SAVE"
            }
          </Button>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <Row>
            <Col xs="12">
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("common:label_name")}
                    </Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="name"
                      value={form.name}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("common:label_description")}
                    </Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="description"
                      value={form.description}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("shipment:label_display_message")}
                    </Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="displayMessage"
                      value={form.displayMessage}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("shipment:label_tax_class")}
                    </Label>
                    <Dropdown
                      isOpen={taxClassDropdownOpen}
                      toggle={() => toggleTaxClassDropdown()}
                      className={clsx("commonDropDownWrapper pr-0")}
                    >
                      <DropdownToggle caret className="w-100">
                        <span>{form.taxClass}</span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdownMenuWrapper w-100">
                        {taxClasses.map((taxClass) => (
                          <div
                            onKeyPress={() => { }}
                            role="button"
                            tabIndex="0"
                            className="dropdownMenuItem"
                            onClick={(event) => handleTaxClassDropdownSelection(event)}
                          >
                            {taxClass}
                          </div>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("shipment:label_shipping_type")}
                    </Label>
                    <Dropdown
                      isOpen={shippingTypeDropdownOpen}
                      toggle={() => toggleShippingTypeDropdown()}
                      className={clsx("commonDropDownWrapper pr-0")}
                    >
                      <DropdownToggle caret className="w-100">
                        <span>{form.shippingType}</span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdownMenuWrapper w-100">
                        {shippingTypes.map((shippingType, index) => (
                          <div
                            key={index.toString()}
                            onKeyPress={() => { }}
                            role="button"
                            tabIndex="0"
                            className="dropdownMenuItem"
                            onClick={(event) => handleShippingTypeDropdownSelection(event)}
                          >
                            {shippingType}
                          </div>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("shipment:label_shipment_method")}
                    </Label>
                    <Dropdown
                      isOpen={shippingMethodDropdownOpen}
                      toggle={toggleShippingMethodDropdown}
                      className={clsx("commonDropDownWrapper  pr-0")}
                    >
                      <DropdownToggle caret className="w-100">
                        <span>{form.shippingMethod}</span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdownMenuWrapper w-100">
                        {shippingMethods.map((shippingMethod, index) => (
                          <div
                            key={index.toString()}
                            onKeyPress={() => { }}
                            role="button"
                            tabIndex="0"
                            className="dropdownMenuItem"
                            onClick={(event) => handleShippingMethodDropdownSelection(event)}
                          >
                            {shippingMethod}
                          </div>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("shipment:label_price_strategy")}
                    </Label>
                    <Dropdown
                      isOpen={shippingPriceStrategyDropdownOpen}
                      toggle={toggleShippingPriceStrategyDropdown}
                      className={clsx("commonDropDownWrapper  pr-0")}
                    >
                      <DropdownToggle caret className="w-100">
                        <span>{form.priceStrategy}</span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdownMenuWrapper w-100">
                        {shippingPriceStrategies.map(
                          (shipmentPriceStrategy, index) => (
                            <div
                              key={index.toString()}
                              onKeyPress={() => { }}
                              role="button"
                              tabIndex="0"
                              className="dropdownMenuItem"
                              onClick={(event) => handleShippingPriceStrategyDropdownSelection(
                                event,
                              )}
                            >
                              {shipmentPriceStrategy}
                            </div>
                          ),
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("shipment:label_amount")}
                    </Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="amount"
                      value={form.amount}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

EditShippingMethod.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  taxClasses: PropTypes.arrayOf(PropTypes.any).isRequired,
  shippingTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  shippingMethods: PropTypes.arrayOf(PropTypes.any).isRequired,
  shippingPriceStrategies: PropTypes.arrayOf(PropTypes.any).isRequired,
  shippingTypeDropdownOpen: PropTypes.bool.isRequired,
  shippingMethodDropdownOpen: PropTypes.bool.isRequired,
  shippingPriceStrategyDropdownOpen: PropTypes.bool.isRequired,
  taxClassDropdownOpen: PropTypes.bool.isRequired,
  toggleShippingTypeDropdown: PropTypes.func.isRequired,
  toggleShippingMethodDropdown: PropTypes.func.isRequired,
  toggleShippingPriceStrategyDropdown: PropTypes.func.isRequired,
  toggleTaxClassDropdown: PropTypes.func.isRequired,
  handleTaxClassDropdownSelection: PropTypes.func.isRequired,
  handleShippingTypeDropdownSelection: PropTypes.func.isRequired,
  handleShippingMethodDropdownSelection: PropTypes.func.isRequired,
  handleShippingPriceStrategyDropdownSelection: PropTypes.func.isRequired,
  onHandleSave: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
};

export default EditShippingMethod;
