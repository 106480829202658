/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CreateNewUser from "../components/CreateNewUser";
import validateForm from "../../../common/utils/validateForm";
import commonUtils from "../../../common/utils/commonUtils";
import { createNewuser, getActiveUserRoles } from "../../../api/UserServices";
import { getBrandsByRetailerId, getLocationsForSelectedBrands } from "../../../api/brandServices";

const CreateNewUserContainer = () => {
  const validator = validateForm();
  const { clearError, setError, errors } = validator;
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    roles: [],
    accessibleOrganizations: {
      brands: [],
      locations: [],
    },
  });
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const history = useHistory();
  const [customerRoles, setCustomerRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onSubmitting, setOnSubmitting] = useState(false);


  /**
             * This method is used to get active roles for customer
             */
  const getAccessibleRoles = () => {
    getActiveUserRoles().then((response) => {
      if (response) {
        const { success, data } = response;
        if (success && Array.isArray(data) && data.length > 0) {
          const filteredData = data.filter((each) => each.code !== "RETAIL_ADMIN");
          setForm({
            ...form,
            roles: [filteredData[0].code],
          });
          setCustomerRoles(filteredData);
          return filteredData;
        }
      }
      return null;
    });
    return null;
  };

  /**
             * This method is used to change fom state
             * @param {String} name
             * @param {String} value
             */
  const handleChange = (name, value) => {
    if (name === "roles") {
      setForm({
        ...form,
        [name]: [value],
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };


  const onHandleCancel = () => {
    history.push("/administration/user");
  };

  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const onHandleSave = async () => {
    if (form.roles.length < 1) {
      setForm({
        ...form,
        roles: [customerRoles[0].code],
      });
    }
    setLoading(true);
    setOnSubmitting(true);
    createNewuser(form).then((response) => {
      setOnSubmitting(false);
      setLoading(false);
      if (response) {
        if (response.success) {
          setMessage({ type: "success", message: response.messages[0] || "" });
          setTimeout(() => {
            setMessage({ type: null, message: "" });
            history.push("/administration/user");
          }, 3000);
        } else {
          setMessage({ type: "warning", message: response.messages[0] || "" });
          setTimeout(() => {
            setMessage({ type: null, message: "" });
          }, 3000);
        }
      } else {
        setMessage({ type: "warning", message: "something went wrong" });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
          history.push("/administration/user");
        }, 3000);
      }
    });
  };

  useEffect(() => {
    getAccessibleRoles();
  }, []);

  return (
    <CreateNewUser
      handleFormChange={handleFormChange}
      form={form}
      message={message}
      accessibleOrganizationsErrors={errors}
      onHandleSave={onHandleSave}
      onHandleCancel={onHandleCancel}
      customerRoles={customerRoles}
      loading={loading}
      onSubmitting={onSubmitting}
    />
  );
};


export default CreateNewUserContainer;
