/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
/** ===== TAG COMPONENTS =========== */
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Alert from "../../../common/core/Alert";
import Button from "../../../common/core/Button";
import Label from "../../../common/core/Label";
/** ======== COMPONENTS ============= */
import CustomizationTable from "../../../common/components/Table";
import styles from "../css/SearchSynonymManagement.module.css";

const SearchSynonymManagement = (props) => {
  const {
    synonymSetting, onHandleEditSynonyms, onHandleDeleteSynonym, addNewSynonym, updateStatus, LocaleDropDownContainer, localeOnChange, updateInSearchEngine, message, isSyncingSynonyms,
  } = props;
  const { t } = useTranslation(["common", "synonym"]);

  /**
   * This method to change status in campaign list
   * @param {Object} row
   * @returns {Element}
   */
  const getStatus = (row) => {
    const newStatus = (
      <div className={clsx("custom-control", "custom-switch")}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) => updateStatus(!row.active, row.name, row.id, o)}
        />
        <Label
          className="custom-control-label"
          htmlFor={`expand-multiple${row.id}`}
        />
      </div>
    );

    return newStatus;
  };

  return (
    <>
      <Row>
        <Col xs="12">
          {
            message && message.type && (
              <Alert color={message.type}>
                {message.message}
              </Alert>
            )
          }
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7">
              <span className="pageText">
                {t("synonym:synonym_method_title")}
              </span>
            </Col>
            <Col xs="12" md="5" className="d-flex justify-content-end">
              <Button
                onClick={addNewSynonym}
                className={clsx(
                  styles.borderRadius2,
                  "text-uppercase",
                  "text-truncate",
                  "btn-tertiary",
                  "button-font-size",
                  "mr-1",
                )}
                title="Click here to add new synonym and update synonym in search engine"
              >
                Add New Synonym
              </Button>
              <Button
                onClick={updateInSearchEngine}
                className={clsx(
                  styles.borderRadius2,
                  "text-uppercase",
                  "text-truncate",
                  "btn-tertiary",
                  "button-font-size",
                  "mr-1",
                )}
                title="Click here to update synonyms in search engine"
                disabled={isSyncingSynonyms}
              >
                Sync Synonyms
              </Button>
            </Col>
          </Row>
          <Row className="pageHeader common-page-card">
            <Col xs="12">
              <Row className="m-0">
                <Col xs="8" className="p-0">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    {t("synonym:title_search_synonyms")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, " common-page-card")}>
            <Col xs="12">
              <div className="custom-table ">
                <CustomizationTable
                  customRows={[
                    {
                      label: "Settings name",
                      path: "name",
                    },
                    {
                      label: "Status",
                      path: "render",
                      render: getStatus,
                    },
                  ]}
                  customizationData={synonymSetting}
                  // isStatusEnable
                  isEditEnable
                  onEditData={onHandleEditSynonyms}
                  isDeleteEnable
                  onDeleteData={onHandleDeleteSynonym}
                />
              </div>
            </Col>
          </Row>

          {/* <Row className={clsx(styles.tableWrapper, " common-page-card")}>
            <Col xs="12">
              <Row>
                {synonymSetting &&
                  synonymSetting.map((element) => (
                    <>
                      <Col xs="4">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("synonym:synonym_field")}
                          </Label>
                          <Input
                            className="formText"
                            placeholder=""
                            name={element.field}
                            value={element.elements}
                            // onChange={(e) => handleFormChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="4 py-md-4">
                        <FormGroup>
                          <div
                            className={clsx("custom-control", "custom-switch")}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`expand-multiple${element.field}`}
                              checked={element.active}
                              onChange={(o) =>
                                updateSynonym(!element.active, element.field, o)
                              }
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor={`expand-multiple${element.field}`}
                            >
                              {t("synonym:label_active")}
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  ))}
              </Row>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
};

SearchSynonymManagement.propTypes = {
  updateInSearchEngine: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  isSyncingSynonyms: PropTypes.bool.isRequired,
};

export default SearchSynonymManagement;
