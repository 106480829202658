/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 *
 */

import httpApi from "./httpApi";

/**
 * This method is used to create customer location
 *
 */
const createb2bcustomer = async () => { };

/**
 * This method is used to update customer
 *
 * @param {*} data
 * @returns
 */
const updateb2bcustomer = async (form) => {
  const response = await httpApi.put("/v1/b2b/customer/update", form);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get b2bCustomer details by Id
 * @param {String}
 * @returns {Object}
 */
const getB2BCustomerDetailsById = async (customerId) => {
  const response = await httpApi.get(`/v1/b2b/customer/id/${customerId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get b2bDepartments details by customerId
 * @param {String}
 * @returns {Object}
 */
const getB2BDepartmentDetailsByCustomerId = async (customerId) => {
  const response = await httpApi.get(
    `/v1/b2b/department/customer/${customerId}`,
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getB2BUserDetailsByCustomer = async (customerId) => {
  const response = await httpApi.get(`/v1/b2b/users/customer/${customerId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getB2BUserDetailsByDepartment = async (departmentId) => {
  const response = await httpApi.get(`/v1/b2b/users/department/${departmentId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getSalesRepresentativesOfCustomer = async (customerId) => {
  const response = await httpApi.get(
    `/v1/b2b/users/sales-representative/customer/${customerId}`,
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getSalesRepresentativesOfDepartment = async (departmentId) => {
  console.log("departmentId", departmentId);
  const response = await httpApi.get(
    `/v1/b2b/users/sales-representative/department/${departmentId}`,
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const enableAccessibleOrganizations = async (requestData) => {
  const response = await httpApi.patch(
    "/v1/b2b/users/accessible-organization/enable",
    requestData,
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const disableAccessibleOrganizations = async (requestData) => {
  const response = await httpApi.patch(
    "/v1/b2b/users/accessible-organization/disable",
    requestData,
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get b2bDepartment details by ID
 * @param {String}
 * @returns {Object}
 */
const getB2BDepartmentDetailsById = async (id) => {
  const response = await httpApi.get(
    `/v1/b2b/department/id/${id}`,
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get sales Representative details by ID
 * @param {String}
 * @returns {Array}
 */
const getsalesRepresentativeInfo = async (searchTerm = "", page = 0,
  size = 10, searchable) => {
  const response = await httpApi.get("/v1/b2b/users/sales-representative", {
    params: {
      searchTerm,
      page,
      size,
      searchable,
    },
  });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};
const enableSalesRepresentative = async (id) => {
  const response = await httpApi.patch(`/v1/b2b/users/id/${id}/sales-representative/activate`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
const disableSalesRepresentative = async (id) => {
  const response = await httpApi.patch(`/v1/b2b/users/id/${id}/sales-representative/deactivate`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getSalesRepresentativeInfoById = async (id) => {
  const response = await httpApi.get(
    `/v1/b2b/users/sales-representative/${id}`,
  );
  if (response && response.data) {
    return response.data;
  }
  return null;
};
const getB2BDepartmentsById = async (id) => {
  const response = await httpApi.get(`/v1/b2b/users/sales-representative/${id}/departments`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
export {
  createb2bcustomer,
  updateb2bcustomer,
  getB2BCustomerDetailsById,
  getB2BUserDetailsByCustomer,
  getB2BUserDetailsByDepartment,
  getB2BDepartmentDetailsByCustomerId,
  getSalesRepresentativesOfCustomer,
  enableAccessibleOrganizations,
  disableAccessibleOrganizations,
  getB2BDepartmentDetailsById,
  getSalesRepresentativesOfDepartment,
  getsalesRepresentativeInfo,
  enableSalesRepresentative,
  disableSalesRepresentative,
  getSalesRepresentativeInfoById,
  getB2BDepartmentsById,
};
