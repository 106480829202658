/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi
 */

import React, { useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import ListGroup from "../../../common/core/ListGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import UncontrolledDropdown from "../../../common/core/UncontrolledDropdown";
import DropdownItem from "../../../common/core/DropdownItem";
/** ===== IMAGE ICONS =========== */
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import Spinner from "../../../common/core/Spinner";

/** ======== MODULE STYLES ========== */
import styles from "../css/CustomerSelection.module.css";

const CustomerSelector = (props) => {
  const {
    userList, users, selectedUsers, setUserSelected,
    isLoading, getInputValues, addCustomerToList, customers,
    deleteCustomerFromList, selectedCustomers,
  } = props;
  const currentRow = useRef(null);
  const { t } = useTranslation(["customerGroup"]);
  return (
    <>
      {isLoading ? (
        <Row className="h-100">
          <Col
            xs="12"
            className={styles.spinnerWrapper}
          >
            <div
              className="h-100  d-flex align-items-center justify-content-center"
            >
              <Spinner />
            </div>
          </Col>
        </Row>
      )
        : (
          <>
            <Row>
              <Col xs="12" sm="4" lg="4" md="4" className={clsx(styles.rightDivider, "position-relative")}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder={t("customerGroup:placeholder_customer_id")}
                    onChange={getInputValues}
                  />
                  <Button onClick={() => addCustomerToList()} disabled={!selectedCustomers}>ADD</Button>
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer"
                  />
                </InputGroup>
                <ListGroup className={clsx("py-1", "pl-1", styles.searchListContainer, "custom-scrollbar")} flush>
                  {
                    Array.isArray(users) && users.map((user) => (
                      <ListGroupItem key={user.id} className="px-2 py-1 border-0 d-flex ">
                        <div className="custom-control custom-checkbox text-truncate">
                          {/*
                    * maintaining separate state for selected users,
                    * */}
                          <input
                            type="checkbox"
                            id={`userRadio${user.id}`}
                            checked={selectedUsers.includes(user.id)}
                            name={user.id}
                            className="custom-control-input"
                            readOnly
                          />
                          <Label
                            onClick={(o) => setUserSelected(user.id, o)}
                            className="custom-control-label text-black commonPointer mw-100 "
                            htmlFor={`userRadio${user.id}`}
                            title={user.email}
                          >
                            <span className="text-truncate d-block mw-100">{`${user.uniqueId} - ${user.name}`}</span>
                          </Label>
                        </div>
                      </ListGroupItem>
                    ))
                  }
                </ListGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {/*  ====== LISTING SELECTED USERS S[0006]============ */}
                <Row>
                  <div
                    className={clsx(styles.customerBody, "table-responsive")}
                  >
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>{t("customerGroup:table_Customer_account_Id")}</th>
                          <th>{t("customerGroup:table_placeHolder_delete")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(customers && Array.isArray(customers)) ? (
                          customers.map((customer) => (
                            <>
                              <tr>
                                <td>
                                  {customer}
                                </td>
                                <td>
                                  <div className={styles.unselectUser}>
                                    <span onClick={(o) => deleteCustomerFromList(customer, o)} className="commonPointer" role="button" tabIndex={0} onKeyPress={() => { }} title={t("customerGroup:label_remove_customer")}>
                                      <img src={DeleteIcon} alt={t("customerGroup:label_remove_customer")} className={styles.icon} />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))
                        ) : ""}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};
CustomerSelector.defaultProps = {
  selectedCustomers: "",
  customers: [],
};
CustomerSelector.propTypes = {
  // users
  userList: PropTypes.arrayOf(PropTypes.any).isRequired,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCustomers: PropTypes.string,
  setUserSelected: PropTypes.func.isRequired,
  // search user
  searchKeyword: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  deleteCustomerFromList: PropTypes.func.isRequired,
  getInputValues: PropTypes.func.isRequired,
  addCustomerToList: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
};


export default CustomerSelector;
