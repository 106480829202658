/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";

/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */
import MenuForm from "./MenuForm";
import validateForm from "../../../common/utils/validateForm";

/** ========= MODULE STYLES ========= */
import styles from "../css/MenuEditor.module.css";
import Spinner from "../../../common/core/Spinner";

const MenuEditor = (props) => {
  const {
    name, position, active,
    positions, onSubmitting, locales,
    handleFormChange, handleChange, formSubmit, formCancel, locale, loading,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);
  const validator = validateForm();
  const { handleSubmit } = validator;

  return (
    <>
      <Row>
        <Col xs="12">
          {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
          <Form onSubmit={handleSubmit(formSubmit)}>
            <fieldset disabled={onSubmitting}>
              {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
              <Row className="pageHeader common-page-card">
                <Col xs="12" md="7" className="text-truncate">
                  <span className="pageText">
                    {t("site-navigation:title_edit")}
                  </span>
                </Col>
                <Col xs="12" md="5" className="d-flex justify-content-end">
                  <Button type="button" onClick={formCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                    {t("common:button_cancel")}
                  </Button>
                  <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                    {
                      loading ? (
                        <Spinner size="sm" />
                      ) : "Update"
                    }
                  </Button>
                </Col>
              </Row>
              {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}

              <Row className={clsx(styles.blockWrapper, " common-page-card ")}>
                <Col md="8" sm="12">
                  {/*  ====== MENU FORM S[0005]============ */}
                  <MenuForm
                    name={name}
                    position={position}
                    active={active}
                    positions={positions}
                    handleChange={handleChange}
                    handleFormChange={handleFormChange}
                    validator={validator}
                    locale={locale}
                    locales={locales}
                    isEdit
                  />
                  {/*  ====== MENU FORM E[0005]============ */}
                </Col>
              </Row>

            </fieldset>
          </Form>
          {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
        </Col>
      </Row>
    </>
  );
};

MenuEditor.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  locale: PropTypes.bool.isRequired,
  /** form data */
  positions: PropTypes.arrayOf(PropTypes.any).isRequired,
  locales: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  /** form methods */
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  formCancel: PropTypes.func.isRequired,
};


export default MenuEditor;
