/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Inventory Management Container
 *
 * @author Amjad Rehman A
 *
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import InventoryManagement from "../components/InventoryManagement";
import {
  getStoreList,
  getInventoryData,
  getInventoryDataBySearchCriteria,
  setPerpetualInInventory,
  transformInventoryData,
  getB2BInventoryConfiguration,
  getB2BstoreInventory,
} from "../../../api/inventoryManagementService";
import {
    getCatalogConfiguration,
} from "../../../api/productManagementServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const InventoryManagementContainer = () => {
  const [storeList, setStoreList] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [page, setPage] = useState(0);
  const [storeId, setStoreId] = useState();
  const [brand, setBrand] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isProccessing, setIsProccessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const addZero = (val) => (val < 10 ? `0${val}` : val);
  const [salesCompanyList, setSalesCompanyList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [salesCompany, setSalesCompany] = useState();
  const [inventoryConfiguration, setInventoryConfiguration] = useState([]);

  useEffect(() => {
    getCatalogConfiguration().then(async (response) => {
        if (response && response.success && response.data) {
            const { data } = response;
            setSalesCompanyList(data.salesCompanies);
        }
    });
  }, []);

  const getInventoryConfiguration = (selectedSalesCompany) => {
    getB2BInventoryConfiguration(selectedSalesCompany).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          if (data) {
                const brandSet = new Set([]);
                for (const item in data) {
                    brandSet.add(`${item}`);
                }
                const brandArray = Array.from(brandSet);
                setBrandList(brandArray);
                setBrand(brandArray[0]);
                handleBrandSelection(brandArray[0], data, selectedSalesCompany);
                setInventoryConfiguration(data);
          }
        }
      });
  };

  const getStoreInventory = (storeId, selectedSalesCompany, brandId) => {
    setIsLoading(true);
    getB2BstoreInventory(salesCompany || selectedSalesCompany, brand || brandId, "", "", storeId, 10, page).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          if (data) {
                const storeSet = new Set([]);
                if("" == storeId) {
                    data.map((item) => {
                        storeSet.add(item.storeId);
                    });
                    const storeArray = Array.from(storeSet);
                    setStoreList(storeArray);
                }
                setInventoryData(data);
          }
        }
        setIsLoading(false);
      });
  };

  const handleBrandSelection = (brandId, inventoryConfig, selectedSalesCompany) => {
    setBrand(brandId);
    setInventoryData("");
    setSearchTerm("");
    setStoreId("");
    setPage(0);
    for (const item in inventoryConfig) {
        if (item == brandId) {
            const config = inventoryConfig[item];
            setStoreList(config.storeIds);
            setStoreId(config.storeIds[0]);
            getStoreInventory(config.storeIds[0], selectedSalesCompany, brandId);
            
        }
    }
  };

  const handleChange = (key, value) => {
    if (key === "brand") {
        handleBrandSelection(value, inventoryConfiguration);
    }
    if (key === "salesCompany") {
        setSalesCompany(value);
        setSearchTerm("");
        setStoreId("");
        setPage(0);
        setInventoryData("");
        setBrand("");
        getInventoryConfiguration(value);
    }
    if (key === "storeId") {
        setStoreId(value);
        getStoreInventory(value);
    }
  };

  const getInventoryList = (storeId, pageNumber, limit) => {
    /** getting list of Inventory when page load */
    getInventoryData(storeId, pageNumber, limit).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setInventoryData(data);
      }
      setIsLoading(false);
    });
  };

  const updatePerpetualFlag = (perpetual, productId, skuId) => {
    setPerpetualInInventory(productId, skuId, perpetual).then(
      async (response) => {
        if (response && response.success) {
          const newInventoryData = inventoryData.map((inventory) => (inventory.productId === productId
            ? { ...inventory, perpetual }
            : inventory));
          setInventoryData(newInventoryData);
        }
      },
    );
  };

  const handlePerpetualStatus = (perpetual, productId, skuId, e) => {
    updatePerpetualFlag(perpetual, productId, skuId);
  };

  const getInventoryListBySearchTerm = (
    storeId,
    searchTerm,
    pageNumber,
    limit,
  ) => {
    let searchValue;
    if (searchTerm) {
      searchValue = searchTerm.trim();
    }
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    /** getting list of Inventory when page load */
    getInventoryDataBySearchCriteria(
      storeId,
      searchValue,
      pageNumber,
      limit,
      cancelToken,
    ).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setInventoryData(data);
      }
    });
  };

  const getInventoryListbyPage = (storeId, pageNumber, limit) => {
    /** getting list of Inventory when page load */
    getInventoryData(storeId, pageNumber, limit).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (Array.isArray(data)) {
          setInventoryData(data);
          const moreData = data.length > 0;
          setHasMoreData(moreData);
        } else {
          setHasMoreData(false);
        }
        setPage(pageNumber);
      }
    });
  };

  const getPageData = (direction) => {
    getInventoryListbyPage(storeId, direction);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleSearch = () => {
            setIsLoading(true);
            getB2BstoreInventory(salesCompany, brand, searchTerm, "", storeId, 10, page).then(async (response) => {
                if (response && response.success && response.data) {
                    const { data } = response;
                    if (data) {
                            setInventoryData(data);
                    }
                }
                setIsLoading(false);
            }); 
  };

  const initializeData = async () => {
    setSearchTerm("");
    setStoreId("");
    setPage(0);
    setHasMoreData(true);
    setInventoryData("");
    setBrand("");
    setSalesCompany("");
  };

  const onHandleClear = () => {
    initializeData();
  };

  const onHandleExport = async (type) => {
    setIsProccessing(true);
    const page = 0;
    const size = 0;
    const currentDateTime = new Date();
    let searchValue;
    if (searchTerm) {
      searchValue = searchTerm.trim();
    }
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getInventoryDataBySearchCriteria(storeId, searchValue, page, size, cancelToken)
      .then(async (response) => {
        if (response && response.success === true) {
          const { data } = response;
          if (Array.isArray(data)) {
            const inventories = await transformInventoryData(data);
            const year = currentDateTime.getFullYear();
            const month = addZero(currentDateTime.getMonth() + 1);
            const date = currentDateTime.getDate();
            const hours = currentDateTime.getHours();
            const mins = addZero(currentDateTime.getMinutes());
            const secs = currentDateTime.getSeconds();
            let fileName = `Inventory_${year}${month}${date}${hours}${mins}${secs}.xlsx`;
            if (type === "CSV") {
              fileName = `Inventory_${year}${month}${date}${hours}${mins}${secs}.csv`;
            }
            const ws = XLSX.utils.json_to_sheet(inventories);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Inventories");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 15 },
              { wch: 15 },
              { wch: 10 },
              { wch: 10 },
              { wch: 15 },
              { wch: 30 },
              { wch: 30 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch((err) => {
        setIsProccessing(false);
      });
  };
  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_INVTRY_VIEW_LIST}
    >
      <InventoryManagement
        salesCompanyList={salesCompanyList}
        brandList={brandList}
        storeList={storeList}
        inventoryData={inventoryData}
        getPageData={getPageData}
        page={page}
        hasMoreData={hasMoreData}
        searchTerm={searchTerm}
        handleSearchTerm={handleSearchTerm}
        search={handleSearch}
        handleKeyPress={handleKeyPress}
        onHandleClear={onHandleClear}
        storeId={storeId}
        onPerpetualStatusChange={handlePerpetualStatus}
        onHandleExport={onHandleExport}
        isProccessing={isProccessing}
        isLoading={isLoading}
        handleChange={handleChange}
        brand={brand}
        salesCompany={salesCompany}
        storeId={storeId}
      />
    </Permission>
  );
};

export default InventoryManagementContainer;
