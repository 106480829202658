/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import React, { useState, useRef, useEffect } from "react";


/** ========= TAG COMPONENTS ========= */
import Row from "../../core/Row";
import Col from "../../core/Col";
import Label from "../../core/Label";
import Input from "../../core/Input";
import FormGroup from "../../core/FormGroup";
import CustomInput from "../../core/CustomInput";

/** ========= CUSTOM COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */

/** ========= API SERVICE FUNCTIONS ========= */

/** ========= MODULE STYLES ========= */
import styles from "./styles.module.css";

const DatePickerComponent = (props) => {
  const {
    id, labelClass, startDateProps, endDateProps, dateFormat, timeFormat, datePickerProps, maxDate, minDate, indefinite,
    onChange,
  } = props;
  const closeOnSelect = datePickerProps && datePickerProps.closeOnSelect;

  const completeFormat = `${dateFormat}${timeFormat ? ` ${timeFormat}` : ""}`;
  const labelFrom = "From";
  const labelTo = "To";

  /** local states */
  const startDateWrapperRef = useRef(null);
  const endDateWrapperRef = useRef(null);
  const [noEndDate, setNoEndDate] = useState(indefinite);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [endDateLabel, setEndDateLabel] = useState(endDateProps.placeholder || labelTo);
  const [startDateLabel, setStartDateLabel] = useState(startDateProps.placeholder || labelFrom);

  const ifMomentConvertToDate = (date) => (moment.isMoment(date) ? date.toDate() : date);
  const convertToMoment = (date) => (!moment.isMoment(date) ? moment(date) : date);

  const noEndDateLabel = endDateProps.noEndDate === true ? "No End Date" : endDateProps.noEndDate;
  const startDate = startDateProps.value ? convertToMoment(startDateProps.value) : null;
  const endDate = endDateProps.value ? convertToMoment(endDateProps.value) : null;
  const startDateName = startDateProps.name || "startDate";
  const endDateName = endDateProps.name || "endDate";


  /**
   * Trigger blur if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (startDateWrapperRef.current && !startDateWrapperRef.current.contains(event.target)) {
      setOpenStartDate(false);
    }
    if (endDateWrapperRef.current && !endDateWrapperRef.current.contains(event.target)) {
      setOpenEndDate(false);
    }
  };

  useEffect(() => {
    /** Bind the event listener */
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      /** Unbind the event listener on clean up */
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (startDate) {
      setStartDateLabel(startDate.format(completeFormat));
    } else {
      setStartDateLabel(startDateProps.placeholder || labelFrom);
    }
  }, [startDate, completeFormat, startDateProps.placeholder]);

  useEffect(() => {
    if (endDate) {
      setEndDateLabel(endDate.format(completeFormat));
    } else {
      setEndDateLabel(endDateProps.placeholder || labelTo);
    }
  }, [endDate, completeFormat, endDateProps.placeholder]);

  /** This function is used to hide both date selector popovers */
  const hideDatePickers = () => {
    setOpenStartDate(false);
    setOpenEndDate(false);
  };

  /** This function is used to trigger prop change */
  const triggerOnChange = (name, value) => {
    onChange({ target: { name, value } });
  };

  /** This function is used to manage no end date checkbox change */
  const handleNoEndDate = (status) => {
    setNoEndDate(status);
    if (status) {
      setEndDateLabel(endDateProps.placeholder || labelTo);
      triggerOnChange(endDateName, null);
    }
    hideDatePickers();
  };

  /**
   * This method is used to validate each date
   * @param {Moment} current
   */
  const isStartDateValidDate = (current) => {
    let isValid = true;
    /** validating min max date from root component  */
    if (maxDate && current.isAfter(ifMomentConvertToDate(maxDate))) isValid = false;
    if (minDate && current.isBefore(ifMomentConvertToDate(minDate))) isValid = false;
    if (endDate && current.isAfter(ifMomentConvertToDate(endDate))) isValid = false;
    return isValid;
  };

  /**
   * This method is used to validate each date
   * @param {Moment} current
   */
  const isEndDateValidDate = (current) => {
    let isValid = true;
    /** validating min max date from root component  */
    if (maxDate && current.isAfter(ifMomentConvertToDate(maxDate))) isValid = false;
    if (minDate && current.isBefore(ifMomentConvertToDate(minDate))) isValid = false;
    if (startDate && current.isBefore(ifMomentConvertToDate(startDate))) isValid = false;
    return isValid;
  };


  /**
   * This method is used to change date
   * @param {Object} date
   */
  const handleStartDateChange = (date) => {
    if (moment.isMoment(date)) {
      triggerOnChange(startDateName, date);
      if (!timeFormat) { setOpenStartDate(false); }
    }
  };

  /**
   * This method is used to change date
   * @param {Object} date
   */
  const handleEndDateChange = (date) => {
    if (moment.isMoment(date)) {
      triggerOnChange(endDateName, date);
      if (!timeFormat) { setOpenEndDate(false); }
    }
  };

  /** This function is used to open start date picker popup */
  const openStartDatePicker = () => {
    setOpenStartDate(true);
  };

  /** This function is used to open end date picker popup */
  const openEndDatePicker = () => {
    if (!noEndDate) {
      setOpenEndDate(true);
    }
  };

  useEffect(() => {
    setNoEndDate(indefinite);
  }, [indefinite]);

  return (
    <Row className={clsx(styles.datePickerComponent, "p-0")}>
      <Col>
        <FormGroup className="position-relative">
          <Label className={labelClass} htmlFor="date-rage-picker-start-date">
            {startDateProps.label}
            <span className="pl-1 text-danger">*</span>
          </Label>
          <Input value={startDateLabel} disabled={startDateProps.disabled} onClick={openStartDatePicker} type="text" className={clsx("form-control", startDateProps.disabled ? "bg-grey" : "bg-white")} readOnly id={`date-rage-picker-start-date-${id}`} />
          <div ref={startDateWrapperRef} className={clsx("position-absolute", openStartDate ? styles.datePicker : "")}>
            <DatePicker
              id={`date-rage-picker-start-date-${id}`}
              timeFormat={timeFormat}
              dateFormat={dateFormat}
              closeOnSelect={closeOnSelect}
              name={startDateName}
              input={false}
              open={openStartDate}
              onChange={handleStartDateChange}
              isValidDate={isStartDateValidDate}
              value={startDate}
            />
          </div>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="position-relative">
          <Label className={labelClass} htmlFor="date-rage-picker-end-date">
            {endDateProps.label}
            {
              !noEndDateLabel
              && <span className="pl-1 text-danger">*</span>
            }
          </Label>
          <Input value={endDateLabel} disabled={endDateProps.disabled ? endDateProps.disabled : false} onClick={openEndDatePicker} type="text" className={clsx("form-control", noEndDateLabel && noEndDate ? "bg-light" : endDateProps.disabled ? "bg-grey" : "bg-white")} readOnly id={`date-rage-picker-end-date-${id}`} />
          <div ref={endDateWrapperRef} className={clsx("position-absolute", openEndDate ? styles.datePicker : "")}>
            <DatePicker
              id={`date-rage-picker-no-ned-date-id-${id}`}
              timeFormat={timeFormat}
              dateFormat={dateFormat}
              closeOnSelect={closeOnSelect}
              name={endDateName}
              input={false}
              open={openEndDate}
              onChange={handleEndDateChange}
              isValidDate={isEndDateValidDate}
              value={endDate}
            />
          </div>
          {
            noEndDateLabel
            && (
              <div>
                <CustomInput
                  type="checkbox"
                  checked={noEndDate}
                  onChange={() => { }}
                  onClick={() => handleNoEndDate(!noEndDate)}
                  id={`date-rage-picker-no-end-date-label-${id}`}
                  className="font-weight-light small d-flex"
                  label={`${noEndDateLabel}`}
                />
              </div>
            )
          }
        </FormGroup>
      </Col>
    </Row>
  );
};

DatePickerComponent.defaultProps = {
  id: Math.random().toString(36).substring(7),
  labelClass: "",
  dateFormat: "DD-MM-YYYY",
  indefinite: false,
  timeFormat: false,
  datePickerProps: {
    closeOnSelect: true,
  },
  maxDate: null,
  minDate: null,
  startDateProps: {
    label: "Start date",
    placeholder: "From",
    value: null,
    name: "startDate",
  },
  endDateProps: {
    label: "End date",
    noEndDate: false,
    placeholder: "To",
    value: null,
    name: "endDate",
  },
};

DatePickerComponent.propTypes = {
  id: PropTypes.string,
  labelClass: PropTypes.string,
  dateFormat: PropTypes.string,
  indefinite: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  timeFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  datePickerProps: PropTypes.objectOf({
    closeOnSelect: PropTypes.bool,
  }),
  maxDate: PropTypes.objectOf(PropTypes.any),
  minDate: PropTypes.objectOf(PropTypes.any),
  startDateProps: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.objectOf(PropTypes.any),
    placeholder: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  endDateProps: PropTypes.shape({
    label: PropTypes.string,
    noEndDate: PropTypes.bool,
    value: PropTypes.objectOf(PropTypes.any),
    placeholder: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
};

export default DatePickerComponent;

/**   sample usage

   <DateRangePicker
    startDateProps={{
      label: "Start date",
      value: startDate,
      name: "startDate",
    }}
    endDateProps={{
      label: "End date",
      noEndDate: true,
      value: endDate,
      name: "endDate",
    }}
    onChange={handleFormChange}
  />

    onChange(event)
    event = { target: { name, value }}

 */
