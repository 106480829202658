/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Promotions Management Container
 *
 * @author Naseef O
 *
 */

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ========= SUB COMPONENT ========= */
import PromotionListView from "../components/PromotionListView";

/** ========= CUSTOM COMPONENTS ========= */
import ConfirmationModal from "../../../common/components/ConfirmationModal";

/** ========= API SERVICE FUNCTIONS ========= */
import {
  listPromotions, setStatus, deletePromotion,
} from "../../../api/promotionServices";
import {
  getAllSalesCompany,
} from "../../../api/customerGroupServices";
import { getSitePreviewConfigurations, replicatePromotion, getPromotionReplicationStatus } from "../../../api/sitePreviewConfigurationsServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import api from "../../../api/httpApi";

let cancelPreviousCall;

/*  Main container of the PromotionList */
const PromotionList = () => {
  const paginationLimit = 5;

  /** state variables */
  const [promotions, setPromotions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPromotionId, setSelectedPromotionId] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [salesCompany, setSalesCompany] = useState();
  const [salesCompanyList, setSalesCompanyList] = useState([]);


  const history = useHistory();
  /**
 * This method is used to list promotions
 * @param {String} localSearchTerm
 * @param {Number} newPage
 * @param {Number} paginationLimit
 * @param {String} sort
 * @param {String} sortDirection

   * This method used to change the promotion status
   * @param {String} id
   * @param {Boolean} status
   */
  const changePromotionStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    setSelectedPromotionId(id);
    const response = await setStatus(id);
    if (response && response.success) {
      const newPromotions = promotions.map((promotion) => (promotion.id === id
        ? ({ ...promotion, active: status }) : promotion));
      setPromotions(newPromotions);
    }
    setSelectedPromotionId("");
    setSubmitting(false);
  };


  /**
   * This method is used to list promotions
   * @param {String} localSearchTerm
   * @param {Number} newPage
   * @param {Number} paginationLimit
   * @param {String} sort
   * @param {String} sortDirection
   */
  const getPromotionListFromServer = (localSearchTerm = "", selectedSalesCompany, newPage = 0, inPaginationLimit = 10, sort = "createdDate", sortDirection = "DESC") => {
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    /** getting list of promotions when page load */
    listPromotions(localSearchTerm, newPage, inPaginationLimit, sort, sortDirection, cancelToken, selectedSalesCompany || salesCompany).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setPromotions(data);
        } else {
          newPage = page;
        }
        setHasMoreData(Array.isArray(data) && data.length >= inPaginationLimit);
        setPage(Math.max(newPage, 0));
      }
    });
  };

  const handleSalesCompanyChange = (value) => {
    setSalesCompany(value);
    getPromotionListFromServer(searchTerm, value);
  };

  /**
 * This method is used to open new promotion
 * This method will trigger when pagination button click
 * @param {Number} page
 */
  const getPageData = (direction) => {
    const newPage = page + direction;
    getPromotionListFromServer(searchTerm, salesCompany, Math.max(newPage, 0), paginationLimit);
  };

  /**
 * This method is used to update text in search box
 * @param {Event} event
 */
  const handleSearchTerm = (event) => {
    setPromotions([]);
    const { value } = event.target;
    setSearchTerm(value);
    if (salesCompany) {
      if (value && `${value}`.trim().length > 1) { getPromotionListFromServer(value); } else getPromotionListFromServer();
    }
  };


  useEffect(() => {
    getAllSalesCompany().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        const salesCompanyId = data[0].uniqueId;
        setSalesCompany(salesCompanyId);
        setSalesCompanyList(data);
        getPromotionListFromServer(null, salesCompanyId);
      }
    });
  }, []);

  const AddNewPromotion = () => {
    history.push({ pathname: "/marketing/promotion/new", state: { salesCompany } });
  };

  const editPromotion = (promotionId) => {
    history.push({ pathname: `/marketing/promotion/edit/${promotionId}`, state: { salesCompany } });
  };

  const removePromotion = (promotionId) => {
    setSelectedPromotionId(promotionId);
    setOpenConfirmModal(true);
  };

  /**
   * This method is used to cancel confirm form
   */
  const cancelConfirm = () => {
    setSelectedPromotionId("");
    setOpenConfirmModal(false);
  };

  /**
   * This function form delete promotion
   */
  const confirmDelete = async () => {
    if (submitting) return;
    const response = await deletePromotion(selectedPromotionId);
    if (response && response.success) {
      const newPromotions = promotions.filter((promotion) => (promotion.id !== selectedPromotionId));
      setPromotions(newPromotions);
    }
    setSelectedPromotionId("");
    cancelConfirm();
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };
  const getReplicationStatus = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    getPromotionReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last promotion push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };
  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, [getReplicationStatus]);

  const handlePushToLive = async () => {
    replicatePromotion().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Promotion pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };


  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_PRMTN_VIEW_LIST}
    >
      <>
        <PromotionListView
          promotions={promotions}
          submitting={submitting}
          page={page}
          hasMoreData={hasMoreData}
          searchTerm={searchTerm}
          selectedPromotionId={selectedPromotionId}
          pushToLiveEnabled={pushToLiveEnabled}
          previewSiteUrl={previewSiteUrl}
          previewEnabled={previewEnabled}
          message={message}
          // functions
          getPageData={getPageData}
          handleSearchTerm={handleSearchTerm}
          AddNewPromotion={AddNewPromotion}
          changePromotionStatus={changePromotionStatus}
          editPromotion={editPromotion}
          removePromotion={removePromotion}
          handlePushToLive={handlePushToLive}
          pushToLiveButtonEnabled={pushToLiveButtonEnabled}
          getReplicationStatus={getReplicationStatus}
          salesCompany={salesCompany}
          salesCompanyList={salesCompanyList}
          handleSalesCompanyChange={handleSalesCompanyChange}
        />
        <ConfirmationModal
          isOpen={openConfirmModal}
          toggleOpen={cancelConfirm}
          togglClose={cancelConfirm}
          handleConfirm={confirmDelete}
          content=" Are you sure you want to delete this promotion ?"
        />
      </>
    </Permission>
  );
};


export default PromotionList;
