/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
/** ========= CUSTOM COMPONENTS ========= */
import clsx from "clsx";
import CustomizationTable from "../../../common/components/Table";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Select from "../../../common/components/Select";
import styles from "../css/PromotionListView.module.css";
/** ===== TAG COMPONENTS =========== */


const PromotionManagement = (props) => {
  const {
    salesCompany,
    salesCompanyList,
    handleSalesCompanyChange,
    multiBrandConfiguration,
    updateData,
  } = props;

  const { t } = useTranslation(["common", "promotion"]);
  const renderFetchOrderPromotion = (row) => (
    <div
      className={clsx(
        "custom-control",
        "custom-switch",
      )}
    >
      <input
        type="radio"
        id={`price-config-toggle-${row.brand}`}
        checked={row.fetchOrderPromotion}
        disabled={!row.active}
        onClick={() => updateData(row.brand, "fetchOrderPromotion", !row.fetchOrderPromotion)}
      />
      <label
        htmlFor={`price-config-toggle-${row.brand}`}
      >
        <input type="hidden" />
      </label>
    </div>
  );
  const renderconsiderAccountDiscount = (row) => (
    <div
      className={clsx(
        "custom-control",
        "custom-switch",
      )}
    >
      <input
        type="radio"
        id={`price-toggle-${row.brand}`}
        checked={row.considerAccountDiscount}
        disabled={!row.active}
        onClick={() => updateData(row.brand, "considerAccountDiscount", !row.considerAccountDiscount)}
      />
      <label
        htmlFor={`price-toggle-${row.brand}`}
      >
        <input type="hidden" />
      </label>
    </div>
  );
  const renderActive = (row) => (
    <div
      className={clsx(
        "custom-control",
        "custom-switch",
      )}
    >
      <input
        type="checkbox"
        id={`price-discount-toggle-${row.brand}`}
        checked={row.active}
        onClick={() => updateData(row.brand, "active", !row.active)}
      />
      <label
        htmlFor={`price-discount-toggle-${row.brand}`}
      >
        <input type="hidden" />
      </label>
    </div>
  );
  const customRows = [
    {
      label: "Brand",
      path: "brand",
    },
    {
      label: "MOZCOM Discount",
      path: "render",
      render: renderFetchOrderPromotion,
    },
    {
      label: "SMP Discount",
      path: "render",
      render: renderconsiderAccountDiscount,
    },
    {
      label: "Activate Discount Option",
      path: "render",
      render: renderActive,
    },
  ];
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="5" className="text-truncate">
          <span className="pageText">
            {" "}
            {t("promotion:label_promotion_management")}
          </span>
        </Col>
      </Row>
      <Row className="common-page-card justify-content-end">
        <Col className="mb-2" xs="12" sm="12" md="9">
          <span
            className="fontsizeMediumBold"
          >
            {t("promotion:label_manage_promotion_management")}
          </span>
        </Col>
        <Col className="mb-2" xs="12" sm>
          <div className={styles.areaActionRight}>
            <div>
              <FormGroup>
                <Select
                  className="formText"
                  align="right"
                  placeholder="sales company"
                  name="salesCompany"
                  value={salesCompany}
                  onChange={(e) => handleSalesCompanyChange(e.target.value)}
                  options={[
                    {
                      value: "", disabled: true, selected: true, label: "Select Sales Company",
                    },
                    ...(
                      Array.isArray(salesCompanyList) ? salesCompanyList.map((item) => ({ value: item.uniqueId, label: `${item.uniqueId}` })) : []
                    ),
                  ]}
                />
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <div className="custom-table">
            <CustomizationTable
              customRows={customRows}
              customizationData={multiBrandConfiguration}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

PromotionManagement.propTypes = {
  salesCompany: PropTypes.string.isRequired,
  salesCompanyList: PropTypes.arrayOf(PropTypes.any).isRequired,
  multiBrandConfiguration: PropTypes.arrayOf(PropTypes.any).isRequired,
  updateData: PropTypes.func.isRequired,
  handleSalesCompanyChange: PropTypes.func.isRequired,
};
export default PromotionManagement;
