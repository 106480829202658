/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Batch Management
 *
 * @author Joice Tomy
 *
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/BatchManagement.module.css";
import CustomizationTable from "../../../common/components/Table";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Home from "../../../common/assets/images/svg/home.svg";
import commonUtils from "../../../common/utils/commonUtils";


const getJobName = (row) => {
  let jobName;
  if (row.name === "Fenix Global Price Job Schedule") {
    jobName = "Price Job";
  } else if (row.name === "Fenix Global Inventory Job Schedule") {
    jobName = "Inventory Job";
  } else if (row.name === "Fenix Global Order Export Job Schedule") {
    jobName = "Order Export Job";
  } else if (row.name === "Fenix Global Order Confirrmation Email Job Schedule") {
    jobName = "Order Email Job";
  } else if (row.name === "Fenix Delta Build Job Scheduler") {
    jobName = "Delta Build Job";
  } else if (row.name === "Fenix Product Index Job Scheduler") {
    jobName = "Product Index Job";
  } else if (row.name === "Fenix Analytics Report Generate Batch Job Schedule") {
    jobName = "Analytics Report Job";
  } else {
    jobName = row.jobSchedule;
  }
  return jobName;
};
const customRows = [
  {
    label: "Job Name",
    path: "render",
    transform: "",
    render: getJobName,
  },
  {
    label: "Job Schedule",
    value: "",
    path: "jobSchedule",
    transform: "",
  },
  {
    label: "Next Job Execution Time",
    value: "",
    path: "nextJobExecutionTime",
    transform: "",
  },
  {
    label: "Previous Job Execution Time",
    value: "",
    path: "prvsJobCompletionTime",
    transform: "",
  },
  {
    label: "Status",
    value: "",
    path: "render",
    transform: "",
    render: (row) => {
      if (row.status === "READY_TO_RUN") {
        return (
          <p>Ready to run</p>
        );
      }
      if (row.status === "STARTED") {
        return (
          <p>
            Started
          </p>
        );
      }
      return row.status;
    },
  },
  {
    label: "Active",
    value: "",
    path: "render",
    transform: "",
    render: (row) => (
      <>
        {row.enabled ? <p className="text-success">true</p> : <p className="text-danger">false</p>}
      </>
    ),
  },
];

const BatchManagement = ({
  schedulers,
  isProcessing,
  onClickRow,
}) => {
  const { t } = useTranslation(["common", "batch-management"]);
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText"><strong>{t("batch-management:batch_management_title")}</strong></span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/dashboard">
                <img src={Home} alt="Home" />
                {t("common:home")}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem><a href="/operations">{t("common:operations")}</a></BreadcrumbItem>
            <BreadcrumbItem><a href="/operations">{t("common:batch_management")}</a></BreadcrumbItem>
            <BreadcrumbItem active>{t("common:schedulers")}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className={clsx(styles.block1, "common-page-card mb-0 pb-0")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="4" className="p-0 d-flex align-items-center">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("batch-management:schedulers_title")}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="table-responsive mt-3">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={commonUtils.isListNotEmpty(schedulers) ? schedulers : []}
                  isLoading={isProcessing}
                  isSerialNoEnabled
                  onClickRow={onClickRow}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

BatchManagement.propTypes = {
  schedulers: PropTypes.arrayOf(PropTypes.any).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
};

export default BatchManagement;
