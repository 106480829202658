/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/TaxLocation.module.css";
import CustomizationTable from "../../../common/components/Table";
import Button from "../../../common/core/Button";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const customRows = [
  {
    label: "Name",
    value: "",
    path: "name",
    transform: "",
  },
  {
    label: "Country",
    value: "",
    path: "country",
    transform: "",
  },
  {
    label: "Region",
    value: "",
    path: "region",
    transform: "",
  },
  {
    label: "Description",
    value: "",
    path: "description",
    transform: "",
  },
];

const TaxLocation = ({
  onHandleNewLocation,
  onHandleSelectedLocation,
  taxLocationData,
  onHandleEditTaxLocation,
  onHandleDeleteTaxLocation,
}) => {
  const { t } = useTranslation(["common", "tax-configuration"]);
  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">
            {t("tax-configuration:span_tax_configuration_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="6" className="p-0">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("tax-configuration:span_tax_location")}
              </span>
            </Col>
            <Col className="mb-2" xs="6" sm>
              <Permission
                allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_LCTN_CRT_TAX_LCTN}
              >
                <div className={styles.areaActionRight}>
                  <div>
                    <Button
                      color="primary"
                      className={clsx(
                        styles.borderRadius2,
                        "text-uppercase",
                        "text-truncate",
                        "btn-tertiary",
                        "ml-1",
                      )}
                      onClick={onHandleNewLocation}
                    >
                      {t("tax-configuration:button_add_new_location")}
                    </Button>
                  </div>
                </div>
              </Permission>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <Row>
            <Col xs="12">
              <div className={clsx(styles.tableWrapper, "custom-table")}>
                <CustomizationTable
                  customRows={customRows}
                  customizationData={taxLocationData}
                  isStatusEnable
                  isDeleteEnable
                  isEditEnable
                  onClickRow={onHandleSelectedLocation}
                  onEditData={onHandleEditTaxLocation}
                  onDeleteData={onHandleDeleteTaxLocation}
                  isDropdownEnabled
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

TaxLocation.propTypes = {
  onHandleNewLocation: PropTypes.func.isRequired,
  onHandleSelectedLocation: PropTypes.func.isRequired,
};

export default TaxLocation;
