/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import CustomInput from "../../../common/core/CustomInput";
import Alert from "../../../common/core/Alert";
import Spinner from "../../../common/core/Spinner";

/** ========= UTIlS ========= */
import constants from "../../../common/utils/constants";
/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import CategorySelector from "../containers/CategorySelectorContainer";
import ProductSelector from "../../productManagement/containers/SimpleProductSelectorContainer";
import BrandSelector from "../../categoryManagement/containers/SimpleBrandSelectorContainer";
import CombineWithSelector from "../containers/CombineWithSelector";
import OrganizationSelector from "../containers/OrganizationSelectorContainer";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */
import downloadIconfrom from "../../../common/assets/images/svg/file-upload.svg";
import publishIcon from "../../../common/assets/images/svg/publish.svg";

import ItemGroupSelector from "./ItemGroupSelector";

const AdvancedSettingsForm = (props) => {
  const {
    combine,
    categories,
    products,
    validator,
    categoryDetails,
    productDetails,
    onCampaign,
    brands,
    brandDetails,
    excludedProducts,
    excludedProductDetails,
    excludeProductsOnOffer,
    discountedItemsSameAsApplicableItems,
    handleChange,
    disableDiscountedItemsSameAsApplicableItems,
    hideDiscountedItemsSameAsApplicableItems,
    refQualifiedElements,
    restrictedChannelGroups,
    restrictedChannelGroupDetails,
    discountType,
    handleBulkImport,
    downloadBulkImportTemplate,
    translatableFileds,
    isDefault,
    loadingFlags,
    itemGroups,
    enableItemGroupSelector,
  } = props;
  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);
  const { PROMOTION } = constants;
  /** validation methods */
  const { errors } = validator;
  return (
    <>
      <div className="common-page-card">
        <Row className={clsx("px-3", "pb-1")}>
          <Col xs="12">
            <Row>
              <Col xs="12" sm={onCampaign ? "12" : "6"}>
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("promotion:label_combine_with")}
                  </Label>
                  <CombineWithSelector
                    name="combine"
                    value={combine}
                    onChange={(o) => handleChange("combine", o)}
                    // validation
                    validator={validator}
                    message={{
                      validate: t("promotion:validation_select_combine"),
                    }}
                    disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("combine") : false}
                  />
                </FormGroup>
              </Col>
              {/* <Col xs="12" sm={onCampaign ? "12" : "6"}>
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("promotion:label_restricted_channel_groups")}

                  </Label>
                  <OrganizationSelector
                    name="restrictedChannelGroups"
                    value={Array.isArray(restrictedChannelGroups) ? restrictedChannelGroups : []}
                    options={Array.isArray(restrictedChannelGroupDetails)
                      ? restrictedChannelGroupDetails
                      : []}
                    onChange={(o) => handleChange("restrictedChannelGroups", o)}
                    type={constants.ORGANIZATIONS.CHANNELGROUP}
                    disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("restrictedChannelGroups") : false}
                  />
                </FormGroup>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
      {/* {discountType !== PROMOTION.BUNDLE_PROMOTION
        ? (
          <div className="common-page-card">
            <Row className={clsx("px-3", "pb-1")}>
              <Col>
                <Row>
                  <Col xs="12" className="text-truncate pageHeader pb-0">
                    <span className="pageText">
                      {t("promotion:title_qualifying_products")}
                    </span>
                    <hr />
                    <div ref={refQualifiedElements}>
                      {errors.qualifyingElements && (
                        <Alert color="danger">
                          {errors.qualifyingElements.message}
                        </Alert>
                      )}
                    </div>
                  </Col> */}
      {/* <Col xs="12">
                    <div className="p-3 my-1 border rounded">
                      <FormGroup>
                        <Label className="formTextLabel">
                          Category
                        </Label>
                        <span className="float-right" title="Upload Categories">
                          <label htmlFor="categoriesFile" className="btn btn-primary ">
                            {
                              loadingFlags && loadingFlags.context === "categories" && loadingFlags.value
                                ? <Spinner size="sm" />
                                : (
                                  <img
                                    src={publishIcon}
                                    alt="upload"
                                  />
                                )
                            }
                            <input
                              name="categories"
                              id="categoriesFile"
                              type="file"
                              className="d-none"
                              accept={PROMOTION.FILE_TYPES_PROMOTION}
                              onChange={
                                (e) => handleBulkImport(e, PROMOTION.SHEET_CONTEXT_CATEGORY)
                              }
                              disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("categories") : false}
                            />
                          </label>
                        </span>
                        <span
                          className="float-right mr-3 btn btn-light border text-primary"
                          role="presentation"
                          onClick={() => downloadBulkImportTemplate("categories", PROMOTION.SHEET_CONTEXT_CATEGORY)}
                          title="Download Template"
                        >
                          <img
                            src={downloadIconfrom}
                            alt="d"
                          />
                        </span>
                        <CategorySelector
                          name="categories"
                          value={Array.isArray(categories) ? categories : []}
                          categoryDetails={
                            Array.isArray(categoryDetails) ? categoryDetails : []
                          }
                          onChange={(o) => handleChange("categories", o)}
                          id="asfcs"
                          disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("categories") : false}
                        />
                      </FormGroup>
                    </div>
                  </Col> */}

      {/* <Col xs="12">
                    <div className="p-3 my-1 border rounded">
                      <FormGroup>
                        <Label className="formTextLabel">Brand</Label>
                        <span className="float-right" title="Upload Brands">
                          <label htmlFor="brandsFile" className="btn btn-primary ">
                            {
                              loadingFlags && loadingFlags.context === "brands" && loadingFlags.value
                                ? <Spinner size="sm" />
                                : (
                                  <img
                                    src={publishIcon}
                                    alt="upload"
                                  />
                                )
                            }
                            <input
                              id="brandsFile"
                              name="brands"
                              type="file"
                              className="d-none"
                              accept={PROMOTION.FILE_TYPES_PROMOTION}
                              onChange={(e) => handleBulkImport(e, PROMOTION.SHEET_CONTEXT_BRAND)}
                              disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("brands") : false}
                            />
                          </label>
                        </span>
                        <span
                          className="float-right mr-3 btn btn-light border text-primary"
                          role="presentation"
                          onClick={() => downloadBulkImportTemplate("brands", PROMOTION.SHEET_CONTEXT_BRAND)}
                          title="Download Template"
                        >
                          <img
                            src={downloadIconfrom}
                            alt="download"
                          />
                        </span>
                        <BrandSelector
                          name="brands"
                          value={Array.isArray(brands) ? brands : []}
                          options={Array.isArray(brandDetails) ? brandDetails : []}
                          onChange={(o) => handleChange("brands", o)}
                          disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("brands") : false}
                        />
                      </FormGroup>
                    </div>
                  </Col> */}
      {/* <Col xs="12">
                    <div className="p-3 my-1 border rounded">
                      <Row>
                        <Col xs="12" sm={onCampaign ? "12" : "6"}>
                          <FormGroup>
                            <Label className="formTextLabel">
                              Included products
                            </Label>
                            <span className="float-right" title="Upload Products">
                              <label htmlFor="productsFile" className="btn btn-primary">
                                {
                                  loadingFlags && loadingFlags.context === "products" && loadingFlags.value
                                    ? <Spinner size="sm" />
                                    : (
                                      <img
                                        src={publishIcon}
                                        alt="upload"
                                      />
                                    )
                                }
                                <input
                                  name="products"
                                  id="productsFile"
                                  type="file"
                                  className="d-none"
                                  accept={PROMOTION.FILE_TYPES_PROMOTION}
                                  onChange={
                                    (e) => handleBulkImport(e, PROMOTION.SHEET_CONTEXT_PRODUCT)
                                  }
                                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("products") : false}
                                />
                              </label>
                            </span>
                            <span
                              className="float-right mr-3 btn btn-light border text-primary"
                              role="presentation"
                              onClick={() => downloadBulkImportTemplate("products", PROMOTION.SHEET_CONTEXT_PRODUCT)}
                              title="Download Template"
                            >
                              <img
                                src={downloadIconfrom}
                                alt="download"
                              />
                            </span>
                            <ProductSelector
                              name="products"
                              value={Array.isArray(products) ? products : []}
                              options={
                                Array.isArray(productDetails) ? productDetails : []
                              }
                              onChange={(o) => handleChange("products", o)}
                              excluded={
                                Array.isArray(excludedProducts)
                                  ? excludedProducts
                                  : []
                              }
                              disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("products") : false}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="12" sm={onCampaign ? "12" : "6"}>
                          <FormGroup>
                            <Label className="formTextLabel">
                              Excluded products
                            </Label>
                            <span
                              title="Upload Excluded Products"
                              className="float-right"
                            >
                              <label htmlFor="excludedProductsFile" className="btn btn-primary ">
                                {
                                  loadingFlags && loadingFlags.context === "excludedProducts" && loadingFlags.value
                                    ? <Spinner size="sm" />
                                    : (
                                      <img
                                        src={publishIcon}
                                        alt="upload"
                                      />
                                    )
                                }
                                <input
                                  name="excludedProducts"
                                  id="excludedProductsFile"
                                  type="file"
                                  className="d-none"
                                  accept={PROMOTION.FILE_TYPES_PROMOTION}
                                  onChange={
                                    (e) => handleBulkImport(e, PROMOTION.SHEET_CONTEXT_PRODUCT)
                                  }
                                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("excludedProducts") : false}
                                />
                              </label>
                            </span>
                            <span
                              className="float-right mr-3 btn btn-light border text-primary"
                              role="presentation"
                              onClick={() => downloadBulkImportTemplate("excludedProducts", PROMOTION.SHEET_CONTEXT_PRODUCT)}
                              title="Download Template"
                            >
                              <img
                                src={downloadIconfrom}
                                alt="download"
                              />
                            </span>
                            <ProductSelector
                              name="excludedProducts"
                              value={
                                Array.isArray(excludedProducts)
                                  ? excludedProducts
                                  : []
                              }
                              options={
                                Array.isArray(excludedProductDetails)
                                  ? excludedProductDetails
                                  : []
                              }
                              onChange={(o) => handleChange("excludedProducts", o)}
                              excluded={Array.isArray(products) ? products : []}
                              disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("excludedProducts") : false}
                            />
                          </FormGroup>
                          <CustomInput
                            type="checkbox"
                            checked={excludeProductsOnOffer}
                            name="excludeProductsOnOffer"
                            onChange={() => { }}
                            onClick={() => handleChange(
                              "excludeProductsOnOffer",
                              !excludeProductsOnOffer,
                            )}
                            id="exclude-products-label-994"
                            className="font-weight-light small d-flex"
                            label={t("promotion:message_exclude_products")}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {!hideDiscountedItemsSameAsApplicableItems ? (
                    <Col xs="12">
                      <CustomInput
                        type="checkbox"
                        checked={discountedItemsSameAsApplicableItems}
                        name="discountedItemsSameAsApplicableItems"
                        onChange={() => { }}
                        onClick={() => handleChange(
                          "discountedItemsSameAsApplicableItems",
                          !discountedItemsSameAsApplicableItems,
                        )}
                        id="qualifying-products-label-994"
                        className="font-weight-light small d-flex"
                        label={t("promotion:message_qualifying_products")}
                        disabled={disableDiscountedItemsSameAsApplicableItems}
                      />
                    </Col>
                  )
                    : (
                      ""
                    )}
                </Row>
              </Col>
            </Row>
          </div>
        )
        : ""} */}
      <div className="common-page-card">
        <Row className={clsx("px-3", "pb-1")}>
          <Col>
            <Row>
              <Col xs="12" className="text-truncate pageHeader pb-0">
                <span className="pageText">
                  Qualifying itemGroups
                </span>
                <hr />
                <div ref={refQualifiedElements}>
                  {errors.qualifyingElements && (
                  <Alert color="danger">
                    {errors.qualifyingElements.message}
                  </Alert>
                  )}
                </div>
              </Col>
              {enableItemGroupSelector && (
              <Col xs="12">
                <div className="p-3 my-1 border rounded">
                  <Row>
                    <Col xs="12" sm={onCampaign ? "12" : "6"}>
                      <FormGroup>
                        <Label className="formTextLabel">
                          Included item-groups
                        </Label>
                      </FormGroup>
                      <ItemGroupSelector
                        itemGroups={itemGroups}
                        setItemGroups={handleChange}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

AdvancedSettingsForm.propTypes = {
  combine: PropTypes.string.isRequired,
  discountType: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  categoryDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  productDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  onCampaign: PropTypes.bool.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludeProductsOnOffer: PropTypes.bool.isRequired,
  discountedItemsSameAsApplicableItems: PropTypes.bool.isRequired,
  disableDiscountedItemsSameAsApplicableItems: PropTypes.bool.isRequired,
  hideDiscountedItemsSameAsApplicableItems: PropTypes.bool.isRequired,
  restrictedChannelGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  restrictedChannelGroupDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingFlags: PropTypes.objectOf(PropTypes.any).isRequired,
  // functions
  handleChange: PropTypes.func.isRequired,
  handleBulkImport: PropTypes.func.isRequired,
  downloadBulkImportTemplate: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,

  // scroll ref
  refQualifiedElements: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  itemGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  enableItemGroupSelector: PropTypes.bool.isRequired,
};

export default AdvancedSettingsForm;
