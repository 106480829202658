/* eslint-disable import/prefer-default-export */
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anagha
 */


import httpApi from "./httpApi";

/**
 *This method is used Change Password
 *
 * */

const changePasswordforAdmin = async (
  changePasswordRequest,
) => {
  const response = await httpApi.patch("/v1/users/admin/password", changePasswordRequest);
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};


export {
  changePasswordforAdmin,
};
