/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Error boundary for react applications
 * @module
 */

import React, { Component } from "react";
import pageNotFound from "../assets/images/svg/page_not_found_.svg";
import Row from "../core/Row";
import Col from "../core/Col";
import Navbar from "../core/Navbar";
import clsx from "clsx";
import styles from "../css/error.boundary.styles.css";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // log error messages to an error reporting service here
  }

  render() {
    const { errorInfo } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;

    if (errorInfo) {
      // Error path
      return (<>
        <div id="headerWrapper" className={styles.headerWrapper}>
          {/* Header */}
          <Navbar>
            <Row className={clsx(styles.menuWrap, "m-0 ")}>
              <Col xs="12" className="p-0">
                <a href="/" >Back to home</a>
              </Col>
            </Row>
          </Navbar>
        </div>
        {/* Body */}
        <Row className="under-construction-row">
          <Col xs="12" className="text-center">
            <Row>
              <Col xs="12" className="text-center under-construction-img">
                <img src={pageNotFound} width="432px" alt="under construction" />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="text-center under-construction ">
                Something went wrong
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Footer */}
        <Row className={clsx(styles.footerWrapper, "m-0")}>
          <Col xs="12">
            <Row className="">
              <Col xs="6">
                <Row>
                  <span className={styles.textUnderline}>
                    Support/Contact
              </span>
                </Row>
                <Row>
                  <span>
                    +97150000012
              </span>
                </Row>
                <Row>
                  <span>
                    support@mozanta.com
              </span>
                </Row>
                <Row>
                  <span>
                    www.mozanta.com
              </span>
                </Row>
              </Col>
              <Col xs="6" className="pr-0">
                <span className="float-right">
                  Copyright © 2020 by mozanta
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
      );
    }
    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
