/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Midhun Murali
 */
import React from "react";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Spinner from "../../../common/core/Spinner";
import DoughnutChart from "./graphs/DoughnutChart";
import BarChart from "./graphs/BarChart";
import LineChart from "./graphs/LineChart";

const GraphManager = ({
  isLoading, brandData, barData, lineData,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <>
      <Col>
        <Row md="12">
          <Col md="3">
            {isLoading ? (
              <div style={{
                backgroundColor: "#FFFFFF", padding: "40px", borderRadius: "10px", height: "500px", display: "flex", justifyContent: "center", alignItems: "center",
              }}
              >
                <Spinner size="lg" animation="border" role="status" className="my-5" />
              </div>
            ) : (
              <>
                {brandData && !Object.values(brandData).every((obj) => obj === 0)
                  ? (
                    <div style={{
                      backgroundColor: "#FFFFFF", padding: "40px", borderRadius: "10px", height: "500px", display: "flex", justifyContent: "center", alignItems: "center",
                    }}
                    >
                      {" "}
                      <DoughnutChart brandData={brandData} />
                      {" "}
                    </div>
                  ) : (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "#FFFFFF", padding: "40px", borderRadius: "10px", height: "500px",
                      }}
                    >
                      <Card className="border-0">
                        <CardBody>{t("common:td_nothing_to_show")}</CardBody>
                      </Card>
                    </div>
                  )}

              </>

            )}
          </Col>

          <Col md="9">
            {isLoading
              ? (
                <div style={{
                  backgroundColor: "#FFFFFF", padding: "40px", borderRadius: "10px", height: "500px", paddingTop: "80px", display: "flex", justifyContent: "center", alignItems: "center",
                }}
                >
                  <Spinner size="lg" animation="border" role="status" className="my-5" />
                </div>
              )
              : (
                <>
                  {barData && barData.xaxis && Array.isArray(barData.xaxis) && barData.xaxis.length != 0
                    ? (
                      <div
                        className=""
                        style={{
                          backgroundColor: "#FFFFFF", padding: "40px", borderRadius: "10px", height: "500px", paddingTop: "80px",
                        }}
                      >
                        {" "}
                        <BarChart barData={barData} />
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#FFFFFF", padding: "40px", borderRadius: "10px", height: "500px", paddingTop: "80px",
                        }}
                      >
                        <Card className="border-0">
                          <CardBody>{t("common:td_nothing_to_show")}</CardBody>
                        </Card>
                      </div>
                    )}
                </>
              )}
          </Col>
        </Row>
        <Row className="pt-4">
          <Col>
            {isLoading ? (
              <div style={{
                backgroundColor: "#FFFFFF", padding: "30px", borderRadius: "10px", height: "400px", width: "100%", marginBottom: "20px", paddingTop: "30px", display: "flex", justifyContent: "center", alignItems: "center",
              }}
              >
                <Spinner size="lg" animation="border" role="status" className="my-5" />
              </div>
            ) : (
              <>
                {lineData && lineData.yaxis && Array.isArray(lineData.yaxis) && lineData.yaxis.length !== 0 ? (
                  <div style={{
                    backgroundColor: "#FFFFFF", padding: "30px", borderRadius: "10px", height: "400px", width: "100%", marginBottom: "20px", paddingTop: "30px",
                  }}
                  >
                    <LineChart lineChartData={lineData} />
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: "#FFFFFF", padding: "30px", borderRadius: "10px", height: "400px", width: "100%", marginBottom: "20px", paddingTop: "30px",
                    }}
                  >
                    <Card className="border-0">
                      <CardBody>{t("common:td_nothing_to_show")}</CardBody>
                    </Card>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>

    </>
  );
};

GraphManager.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  brandData: PropTypes.shape({}).isRequired,
  barData: PropTypes.shape({}).isRequired,
  lineData: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default GraphManager;
