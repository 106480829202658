/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Combined Widget Editor
 *
 * @author Naseef O
 *
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Button from "../../../common/core/Button";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import styles from "../css/CombinedWidgets.module.css";
import errorIcon from "../../../common/assets/images/svg/error.svg";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import ListGroup from "../../../common/core/ListGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";
import MultiCategorySelector from "../containers/MultiCategorySelector";
import ItemTable from "./ItemTable";
import Spinner from "../../../common/core/Spinner";

const CombinedWidgetEditor = (props) => {
  const {
    submitForm,
    widgetType,
    handleRedirectToPageCustomization,
    dropdownOpen,
    toggleDropdown,
    handleSelectContentType,
    selectedContentType,
    handleChangeCategory,
    combinedContents,
    selectedContents,
    handleSetSelectedContents,
    handleDelete,
    isLoading,
    selectedIndex,
    searchTerm,
    handleSearch,
    error,
    headerData,
    handleHeaderChange,
    widgetConfig,
    onDragEnd,
    totalPagesCount,
    onPageChange,
    loading, onSubmitting,
  } = props;

  const { t } = useTranslation(["common", "page-customization"]);

  const contentTypes = [
    { id: "Product", name: "Product" },
    { id: "Category", name: "Category" },
    { id: "Brand", name: "Brand" },
  ];

  const disableContents = widgetConfig
    && widgetConfig.contentLimit > 0
    && widgetConfig.contentLimit <= selectedContents.length;

  return (
    < >
      <Row>
        <Col xs="12">
          <Row className={clsx("common-page-card pageHeader", styles.title)}>
            <Col xs="12" md="3" lg="3">
              <span className="pageText">
                {t(`page-customization:widget-type-${widgetType}`)}
              </span>
            </Col>
            <Col xs="12" md="4" lg="5" />
            <Col xs="12" md="5" lg="4" className="buttonGroup">
              <Row>
                <Col xs="6">
                  <Button
                    className="buttonCancel"
                    onClick={() => handleRedirectToPageCustomization()}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs="6">
                  <Button
                    type="submit"
                    className="buttonSave bg-color-tertiary-main"
                    onClick={(e) => submitForm(e)}
                    disabled={onSubmitting}
                  >
                    {
                      loading ? (
                        <Spinner size="sm" />
                      ) : "Save"
                    }
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx("common-page-card ", styles.block2)}>
            <Col xs="12">
              <Row className="">
                <Col xs="4">
                  <Label>Select a type</Label>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="commonDropDownWrapper"
                  >
                    <DropdownToggle caret>
                      <span>
                        {selectedContentType ? selectedContentType.name : "Select a type"}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdownMenuWrapper">
                      {contentTypes.map((type) => (
                        <div
                          key={`contentType-${type.id}`}
                          onKeyPress={() => { }}
                          role="button"
                          tabIndex="0"
                          className="dropdownMenuItem"
                          onClick={() => handleSelectContentType(type)}
                        >
                          {type.name}
                        </div>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <Label>Redirection link text</Label>
                    <Input
                      className="formText"
                      placeholder="Header"
                      name="header"
                      value={headerData.header}
                      onChange={handleHeaderChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup>
                    <Label>Landing page URL</Label>
                    <Input
                      className="formText"
                      placeholder="Landing page URL"
                      name="landingPageBaseUrl"
                      value={headerData.landingPageBaseUrl}
                      onChange={handleHeaderChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {error && error.message && (
                <span className="d-flex mt-3 align-items-center color-primary-dark ">
                  <span className="pr-1">{error.message}</span>
                  {" "}
                  <img src={errorIcon} alt="error" />
                </span>
              )}
              {selectedContentType && (
                <>
                  {selectedContentType.id === "Category"
                    ? (
                      <>
                        <Row className={clsx("mt-3 mx-0", styles.categorySelector)}>
                          <FormGroup>
                            <MultiCategorySelector
                              value={Array.isArray(selectedContents) ? selectedContents : []}
                              onChange={(e) => handleChangeCategory(e)}
                            />
                          </FormGroup>
                        </Row>
                      </>
                    )
                    : (
                      <>
                        <Row className="mt-3">
                          <Col>
                            <FormGroup>
                              <InputGroup className={styles.search}>
                                <Input
                                  placeholder={`Search ${selectedContentType.id === "Brand" ? "brands" : "products"}...`}
                                  value={searchTerm}
                                  onChange={handleSearch}
                                />
                                <InputGroupAddon
                                  addonType="append"
                                  className="commonPointer"
                                >
                                  <img
                                    src={SearchIcon}
                                    alt="search product"
                                    className={styles.icon}
                                  />
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <ListGroup className="py-1 custom-scrollbar" flush>
                          {
                            combinedContents
                              && combinedContents.length > 0 ? combinedContents.map((content) => (
                                <ListGroupItem key={content.id} className="px-2 py-1 border-0 d-flex ">
                                  <div className="custom-control custom-checkbox text-truncate">

                                    <input
                                      type="checkbox"
                                      id={`productRadio${content.id}`}
                                      checked={
                                        !!selectedContents.find((item) => item.id === content.id)
                                      }
                                      name={content.id}
                                      className="custom-control-input"
                                      readOnly
                                      disabled={!selectedContents.find((item) => item.id === content.id) && disableContents}
                                    />
                                    <Label
                                      onClick={() => !(!selectedContents.find((item) => item.id === content.id) && disableContents) && handleSetSelectedContents(content)}
                                      className="custom-control-label text-black commonPointer mw-100 "
                                      htmlFor={`productRadio${content.id}`}
                                      title={content.name}
                                    >
                                      {content.imageUrl && (
                                        <span className="text-truncate d-block mw-100">
                                          <img className={styles.labelImg} src={content.imageUrl} alt="product" />
                                        </span>
                                      )}
                                      <span className="text-truncate d-block mw-100">{content.name}</span>
                                    </Label>
                                  </div>
                                </ListGroupItem>
                              ))
                              : (
                                <Row>
                                  <Col xs="12" className="d-flex justify-content-center">
                                    <span className="font-weight-bold"> No data found!</span>
                                  </Col>
                                </Row>
                              )
                          }
                        </ListGroup>
                        <Row>
                          <Col />
                          <Col xs="auto">
                            <ReactPaginate
                              previousLabel="previous"
                              nextLabel="next"
                              pageCount={totalPagesCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={onPageChange}
                              containerClassName="pagination"
                              pageClassName="page"
                              pageLinkClassName="pageLink"
                              activeClassName="activePage"
                              initialPage={0}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                </>
              )}
            </Col>
          </Row>
          <Row className={clsx(styles.block3, "common-page-card")}>
            <Col xs="12">
              <Row className="custom-table m-0 ">
                <ItemTable
                  handleDelete={handleDelete}
                  isLoading={isLoading}
                  selectedIndex={selectedIndex}
                  customizationData={selectedContents}
                  onDragEnd={onDragEnd}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
CombinedWidgetEditor.propTypes = {
  submitForm: PropTypes.func.isRequired,
  widgetType: PropTypes.string.isRequired,
  handleRedirectToPageCustomization: PropTypes.bool.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  handleSelectContentType: PropTypes.func.isRequired,
  selectedContentType: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeCategory: PropTypes.func.isRequired,
  combinedContents: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedContents: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSetSelectedContents: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  headerData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleHeaderChange: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};
export default CombinedWidgetEditor;
