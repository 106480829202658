/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */


import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Label from "../../../common/core/Label";
import Button from "../../../common/core/Button";
import CardBody from "../../../common/core/CardBody";
import styles from "../css/CacheClearManagement.module.css";
import Alert from "../../../common/core/Alert";
import toggleOnIcon from "../../../common/assets/images/svg/toggle-on.svg";
import toggleOffIcon from "../../../common/assets/images/svg/toggle-off.svg";
import Spinner from "../../../common/core/Spinner";


const CacheClearManagement = ({
  cacheManagement, handleChange, handleApply, handleCancel, message, isProccessing,
}) => {
  const { removeAllCache } = cacheManagement;
  const customData = [
    {
      text: "Remove All Cache",
      name: "removeAllCache",
      value: removeAllCache,
    },
    {
      text: "Remove Price Table Cache",
      name: "removePriceTableCache",
      value: cacheManagement.removePriceTableCache,
    },
    {
      text: "Remove Price Book Cache",
      name: "removePriceBookCache",
      value: cacheManagement.removePriceBookCache,
    },
    {
      text: "Remove Pricing Engine Cache",
      name: "removePricingEngineCache",
      value: cacheManagement.removePricingEngineCache,
    },
    {
      text: "Remove Promotion Cache",
      name: "removePromotionsCache",
      value: cacheManagement.removePromotionsCache,
    },
    {
      text: "Remove Coupons Cache",
      name: "removeCouponsCache",
      value: cacheManagement.removeCouponsCache,
    },
    {
      text: "Remove Campaigns Cache",
      name: "removeCampaignCache",
      value: cacheManagement.removeCampaignCache,
    },
    {
      text: "Remove App accounts Cache",
      name: "removeAppAccountsCache",
      value: cacheManagement.removeAppAccountsCache,
    },
    {
      text: "Remove Categories Cache",
      name: "removeCategoriesCache",
      value: cacheManagement.removeCategoriesCache,
    },
    {
      text: "Remove Products Cache",
      name: "removeProductsCache",
      value: cacheManagement.removeProductsCache,
    },
    // {
    //   text: "Remove Organization Cache",
    //   name: "removeOrganizationCache",
    //   value: cacheManagement.removeOrganizationCache
    // },
    {
      text: "Remove System settings Cache",
      name: "removeSystemSettingsCache",
      value: cacheManagement.removeSystemSettingsCache,
    },
  ];

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">Cache Management</span>
        </Col>
        <Col xs="12" md="5" className="d-flex justify-content-end">
          <Button type="button" onClick={handleCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
            CANCEL
          </Button>
          <Button type="submit" onClick={handleApply} color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
            {isProccessing ? <Spinner size="sm" animation="border" role="status" />
              : "APPLY"}
          </Button>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <CardBody>
            {customData && customData.map((singleData) => (
              <>
                <Row>
                  <Col xs="6">
                    <Label className="formTextLabel">
                      {singleData.text}
                    </Label>
                  </Col>
                  <Col xs="6">
                    <img
                      src={singleData.value ? toggleOnIcon : toggleOffIcon}
                      className={clsx(styles.removeCacheSwitch)}
                      width="23px"
                      alt="drag indiactor icon"
                      onClick={() => handleChange(singleData.name, !singleData.value)}
                    />
                  </Col>
                </Row>
              </>
            ))}
          </CardBody>
        </Col>
      </Row>
    </>
  );
};
CacheClearManagement.propTypes = {
  siteSettings: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  isProccessing: PropTypes.bool.isRequired,
};

export default CacheClearManagement;
