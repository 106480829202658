/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amritha
 */

import React, { useEffect, useState } from "react";
import { getAllPreOrderCatalogConfigs, updatePreOrderCatalogConfig } from "../../../api/preOrderCatalogServices";
import { bulkIndexProducts } from "../../../api/productManagementServices";
import PreOrderManagement from "../components/PreOrderManagement";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import commonutils from "../../../common/utils/commonUtils";
import { getCustomerGroups } from "../../../api/customerGroupServices";
import api from "../../../api/httpApi";

const PreOrderManagementContainer = () => {
  const [catalogConfigs, setCatalogConfigs] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const [configEdited, setEditedConfig] = useState(null);
  const [catalog, setCatalog] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [excludedCustomerGroups, setExcludedCustomerGroups] = useState([]);
  const [excludeCustomer, setExcludeCustomer] = useState(false);
  const [customerGroupList, setCustomerGroupList] = useState([]);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  let cancelPreviousCustomerAPICall;
  const toggleEditCatalogConfig = (catalogConfig) => {
    setEditedConfig(catalogConfig);
    setCatalog(catalogConfig ? catalogConfig.catalog : "");
    setExcludeCustomer(catalogConfig ? catalogConfig.excludeCustomer : false);
    if (catalogConfig && commonutils.isListNotEmpty(catalogConfig.excludedCustomerGroups)) {
      setExcludedCustomerGroups(catalogConfig.excludedCustomerGroups);
      setCustomerGroupList(catalogConfig.excludedCustomerGroups.map(
        (each) => ({ value: each, label: each }),
      ));
    } else {
      setExcludedCustomerGroups([]);
      setCustomerGroupList([]);
    }
  };

  const handleCatalogChange = (e) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    setCatalog(trimmedValue);
  };

  useEffect(() => {
    setLoading(true);
    getAllPreOrderCatalogConfigs().then((response) => {
      setLoading(false);
      if (response && response.success && commonutils.isListNotEmpty(response.data)) {
        setCatalogConfigs(response.data);
      } else {
        setCatalogConfigs([]);
      }
    });
  }, []);

  const handleExcludedCustomerGroupsChange = (e) => {
    const { value } = e.target;
    setExcludedCustomerGroups(value);
  };

  const handleExcludeCustomerChange = (value) => {
    setExcludeCustomer(value);
  };

  const handleAlert = (alertMessage) => {
    if (alertMessage) {
      setMessage({ type: "warning", alertMessage });
    }
    setTimeout(() => {
      setMessage({ type: null, alertMessage: "" });
    }, 4000);
  };

  const updateData = (id, name, value) => {
    if (id) {
      const editedCatalogConfigIndex = catalogConfigs.findIndex((each) => each.id === id);
      if (editedCatalogConfigIndex > -1) {
        const editedCatalogConfig = catalogConfigs[editedCatalogConfigIndex];
        const excludedCustomerGroupsArray = commonutils.isListNotEmpty(excludedCustomerGroups)
          ? excludedCustomerGroups
          : [];
        const trimmedExcludedCustomerGroupsArray = excludedCustomerGroupsArray.map(
          (string) => string.trim(),
        );

        let newData = { ...editedCatalogConfig, [name]: value };
        newData = {
          ...editedCatalogConfig,
          [name]: value,
          excludedCustomerGroups: trimmedExcludedCustomerGroupsArray,
          excludeCustomer,
        };
        setSubmitting(true);
        updatePreOrderCatalogConfig(newData).then((response) => {
          setSubmitting(false);
          if (response && response.success && response.data) {
            const newCatalogConfigs = [...catalogConfigs];
            newCatalogConfigs.splice(editedCatalogConfigIndex, 1, newData);
            setCatalogConfigs(newCatalogConfigs);
            setCatalog("");
            setEditedConfig(null);
            bulkIndexProducts().then(() => {
              // TODO - add an alert
            });
          }
        });
      }
    }
  };

  const getCustomerGroupList = (searchTerm, salesCompanyId, newPage = 0, sortBy = "createdDate", sortDirection = "DESC") => {
    if (cancelPreviousCustomerAPICall) {
      cancelPreviousCustomerAPICall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCustomerAPICall = cancelMethod;
    getCustomerGroups(
      searchTerm, salesCompanyId, newPage, 5, sortBy, sortDirection, true, cancelToken,
    ).then(
      (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          if (Array.isArray(data)) {
            setCustomerGroupList(data.map((each) => ({ value: each.id, label: each.id })));
          } else {
            setCustomerGroupList([]);
          }
        }
      },
    );
  };

  const searchCustomerGroup = (searchTerm, config) => {
    getCustomerGroupList(searchTerm, config.salesCompany);
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_PRE_ORDR_CTLG_VIEW_LIST}
    >
      <PreOrderManagement
        catalogConfigs={catalogConfigs}
        isLoading={isLoading}
        updateData={updateData}
        configEdited={configEdited}
        catalog={catalog}
        toggleEditCatalogConfig={toggleEditCatalogConfig}
        handleCatalogChange={handleCatalogChange}
        isSubmitting={isSubmitting}
        handleExcludedCustomerGroupsChange={handleExcludedCustomerGroupsChange}
        excludedCustomerGroups={excludedCustomerGroups}
        excludeCustomer={excludeCustomer}
        handleExcludeCustomerChange={handleExcludeCustomerChange}
        searchCustomerGroup={searchCustomerGroup}
        customerGroupList={customerGroupList}
      />
    </Permission>
  );
};

export default PreOrderManagementContainer;
