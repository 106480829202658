/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Dipin
 */
import httpApi from "./httpApi";
import anonymusHttpApi from "./anonymusHttpApi";
import config from "../config";
import authApi from "./authServerApi";

const {
  api: { logoutURL },
} = config;

/**
 *This method is used to login
 *
 * @param {*} loginData
 */
const login = async (loginData) => {
  const tokenResponse = await authApi.login(loginData, httpApi.apiBaseUrl);
  return tokenResponse;
};

/**
 *This method is used to get anonymous token
 *
 * @return token {String}
 */
const getAnonymousToken = async () => {
  const tokenResponse = await authApi.getAnonymusToken(httpApi.apiBaseUrl);
  return tokenResponse;
};

/**
 *This method is used to logout
 */
const logout = async () => {
  const response = await httpApi.get(logoutURL);
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to get user List
 *
 * @returns
 */

/**
 * This method is used to initiate reset password for admin
 *
 * @param {*} resetData
 * @returns
 */
const initiateResetPassword = async (resetData) => {
  const response = await httpApi.put("v1/users/admin/password-reset", resetData);
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

const getUserList = async (roles = "", active = "", page = 0, size = 10) => {
  const response = await httpApi.get("v1/users/list", {
    params: {
      roles,
      active,
      page,
      size,
    },
  });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to create new User
 *
 * @returns
 */
const createNewuser = async (data) => {
  const response = await httpApi.post("/v1/users/admin", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get List of roles
 *
 * @returns
 */
const getActiveUserRoles = async () => {
  const response = await httpApi.get("/v1/users/roles/active");
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to reset password
 *
 * @returns
 */
const resetPassword = async (token, password) => {
  const reqBody = { token, password };
  const response = await anonymusHttpApi.patch("/v1/users/admin/reset-password", reqBody);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};
const updateUser = async (data) => {
  const response = await httpApi.patch("/v1/users/admin/update", data);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};
const getUserById = async (id) => {
  const response = await httpApi.get(`/v1/users/id/${id}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};
const deleteUser = async (id) => {
  const response = await httpApi.delete(`/v1/users/id/${id}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};
const enableUser = async (id) => {
  const response = await httpApi.patch(`/v1/users/id/${id}/activate`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
const disableUser = async (id) => {
  const response = await httpApi.patch(`/v1/users/id/${id}/deactivate`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
const getUsersWithSearchAndPagination = async (roles, active, searchTerm = "", page = 0, size = 10, searchable) => {
  const response = await httpApi.post("/v1/users/search", { roles, active, searchTerm },
    {
      params: {
        page, size, searchable,
      },
    });
  if (response && response.data) {
    return response.data;
  }
  return null;
};


const updateTokenLocation = async (location, token) => {
  const response = await authApi.updateTokenLocation(location, token, httpApi.apiBaseUrl);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  login,
  logout,
  getUserList,
  initiateResetPassword,
  resetPassword,
  getAnonymousToken,
  createNewuser,
  getActiveUserRoles,
  updateUser,
  getUserById,
  deleteUser,
  enableUser,
  disableUser,
  getUsersWithSearchAndPagination,
  updateTokenLocation,
};
