/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Vishakh
 */

import React, { useEffect, useState } from "react";
import { getAnalyticData, getAnalyticsPreface, getCatalogConfiguration } from "../../../api/analyticsService";
import Customer from "../../../common/assets/images/analytics/high-value.png";
import Price from "../../../common/assets/images/analytics/price.png";
import Representative from "../../../common/assets/images/analytics/top-sales.png";
import Product from "../../../common/assets/images/analytics/top-selling.png";
import Orders from "../../../common/assets/images/analytics/total-order.png";
import B2BAnalytics from "../components/B2BAnalytics";

const moment = require("moment");
const Datetime = require("react-datetime");

const B2BAnalyticsContainer = () => {
  const [salesCompany, setSalesCompany] = useState();
  const [brandData, setBrandData] = useState();
  const [barData, setBarData] = useState();
  const [lineData, setLineData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [prefaceData, setPrefaceData] = useState([]);
  const catalogTypeList = ["PreOrder", "ReOrder"];
  const [catalogType, setCatalogType] = useState();
  const [brand, setBrand] = useState();
  const [brandList, setBrandList] = useState();
  const [salesCompanyList, setSalesCompanyList] = useState();
  const [toDate, setToDate] = useState(moment());
  const [fromDate, setFromDate] = useState(moment("2019-12-31"));
  const [timeLineType, setTimeLineType] = useState(null);
  const timeLineTypes = [
    {
      label: "Daily",
      value: "DAILY",
    },
    {
      label: "Weekly",
      value: "WEEKLY",
    },
    {
      label: "Monthly",
      value: "MONTHLY",

    },
    {
      label: "Yearly",
      value: "YEARLY",
    },
  ];
  /**
   * This methode is used to find out the date is valid or not
   * @param {moment} date
   * @param {String} type
   * @returns boolean
   */
  const isValidDate = (date, type) => {
    if (type === "ORDER_DATE_FROM") {
      if (toDate) {
        return date.isBefore(moment(toDate, "DD-MM-YYYY").add(1, "days"));
      }
      return date.isBefore(Datetime.moment());
    }
    if (type === "ORDER_DATE_TO") {
      if (fromDate) {
        return (
          date.isSameOrAfter(moment(fromDate, "DD-MM-YYYY"))
          && date.isSameOrBefore(Datetime.moment())
        );
      }
      return date.isSameOrBefore(Datetime.moment());
    }
  };

  useEffect(() => {
    getCatalogConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && Array.isArray(data.brands)) {
          setBrandList(data.brands);
        }
        if (data && Array.isArray(data.salesCompanies)) {
          setSalesCompanyList(data.salesCompanies);
        }
      }
    });
  }, []);

  const getIconAndBackgroundColor = (description) => {
    const iconAndBackgroundColors = {
      totalOrder: { textValue: "Total Orders", icon: Orders, color: "#2C54E7" },
      totalOrderPrice: { textValue: "Total Order Price", icon: Price, color: "#71B841" },
      topSellingProduct: { textValue: "Top Selling Product", icon: Product, color: "#E04666" },
      highValueCustomer: { textValue: "High-value Customer", icon: Customer, color: "#F0AA46" },
      topSalesRep: { textValue: "Top Sales Representative", icon: Representative, color: "#509FB5" },
    };
    return iconAndBackgroundColors[description] ? iconAndBackgroundColors[description] : { textValue: "", icon: "", color: "" };
  };

  /**
   *This method is used to handle the filter changes.
   * @param {String} name
   * @param {String} value
   */
  const handleFilterChange = (name, value) => {
    setLoading(true);
    switch (name) {
      case "brand":
        setBrand(value);
        break;
      case "salesCompany":
        setSalesCompany(value);
        break;
      case "catalogType":
        setCatalogType(value);
        break;
      case "timeLineType":
        setTimeLineType(value);
        break;
      case "toDate":
        setToDate(value);
        break;
      case "fromDate":
        setFromDate(value);
        break;
      default:
        break;
    }
  };

  /**
   * This method is used scrool window to the  top
   */
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };


  /**
  * This method is used to retrieve the current business status from analytics.
  *
  */
  const getAnalyticsPrefaceData = async () => {
    const request = {
      type: "ORDER",
      startDate: fromDate,
      endDate: toDate,
      catalog: catalogType,
      brand,
      timeLineType,
      salesCompany,
    };
    const response = await getAnalyticsPreface(request);
    if (Array.isArray(response) && response.length) {
      setPrefaceData(response);
    } else {
      setPrefaceData([]);
    }
  };


  /**
  * This method is used to retrieve the current business data for charts.
  *
  */
  const getAnalyticChartData = async (request) => {
    const response = await getAnalyticData(request);
    if (response && response.success) {
      setLoading(false);
      if (response.data && response.data.graphData) {
        return response.data.graphData;
      }
    }
    return null;
  };


  /**
   * This method is used to populating the graph request
   * @param {String} graphType
   * @param {String} timelineType
   * @returns graphRequest
   */
  const constructGraphRequest = (graphType, timelineType) => {
    const request = {
      type: "ORDER",
      startDate: fromDate,
      endDate: toDate,
      graphType,
      catalog: catalogType,
      brand,
      timeLineType: timelineType,
      salesCompany,
    };
    return request;
  };


  /**
 * This method is used  to populate X-axis data.
 * @param {Array} values
 * @returns
 */
  const getXaxisValues = (values) => values.map((item, index) => {
    const regex = /(.*)\(([^)]+)\)/; // Regular expression to capture both parts
    const matches = item.match(regex);
    if (matches) {
      let value1 = matches[1];
      if (matches[1] === "week") {
        value1 = `${matches[1]}- ${index + 1}`;
      }
      return [value1, `(${matches[2]})`];
    }
    return [item];
  });

  /**
   *This method is used to fetch  line chart data.
   * @param {String} timelineType
   */
  const getLineChartData = async (timelineType) => {
    const lineChartRequest = constructGraphRequest("LINE", timelineType);
    if (lineChartRequest != null) {
      const lineGraphResponse = await getAnalyticChartData(lineChartRequest);
      if (lineGraphResponse != null) {
        const result = getXaxisValues(lineGraphResponse.xaxis);
        lineGraphResponse.xaxis = result;
        setLineData(lineGraphResponse);
      }
    }
  };

  /**
   * This method is used to fetch BarChat data.
   * @param {String} timelineType
   */
  const getBarChartData = async (timelineType) => {
    const barGraphRequest = constructGraphRequest("BAR", timelineType);
    if (barGraphRequest != null) {
      const barGraphRespone = await getAnalyticChartData(barGraphRequest);
      if (barGraphRespone != null) {
        const result = getXaxisValues(barGraphRespone.xaxis);
        barGraphRespone.xaxis = result;
        setBarData(barGraphRespone);
      }
    }
  };

  /**
   *This method is used to fetch douhnutChart data.
   * @param {String} timelineType
   */
  const getDouhnutChartData = async (timelineType) => {
    const douhnutGraphRequest = constructGraphRequest("DOUGHNUT", timelineType);
    if (douhnutGraphRequest != null) {
      const douhnutGraphRespone = await getAnalyticChartData(douhnutGraphRequest);
      if (douhnutGraphRespone != null) {
        setBrandData(douhnutGraphRespone);
      }
    }
  };

  /**
  * Thsi method is used to  fetch all Graph data
  *
  */
  const getAllGraphData = () => {
    getLineChartData(timeLineType);
    getBarChartData(timeLineType);
    getDouhnutChartData(timeLineType);
  };

  /**
* This method is used to  reset all filter values.
*/
  const resetFilterChange = () => {
    setBrand("");
    setSalesCompany("");
    setCatalogType("");
    setFromDate(moment("2019-12-31"));
    setToDate(moment());
    setTimeLineType(null);
    setBarData(null);
    setBrandData(null);
    setLineData(null);
  };

  /**
   * This useEffect is used to first time initalization of data.
   */
  useEffect(() => {
    getAllGraphData();
    getAnalyticsPrefaceData();
  }, [catalogType, brand, salesCompany, fromDate, toDate, timeLineType]);

  return (
    <B2BAnalytics
      getIconAndBackgroundColor={getIconAndBackgroundColor}
      prefaceData={prefaceData}
      selectedSalesCompany={salesCompany}
      isLoading={isLoading}
      brandData={barData}
      barData={brandData}
      resetFilterChange={resetFilterChange}
      catalogType={catalogType}
      catalogTypeList={catalogTypeList}
      timeLineTypes={timeLineTypes}
      timeLineType={timeLineType}
      handleFilterChange={handleFilterChange}
      salesCompany={salesCompany}
      salesCompanyList={salesCompanyList}
      brand={brand}
      brandList={brandList}
      lineData={lineData}
      isValidDate={isValidDate}
      setToDate={setToDate}
      setFromDate={setFromDate}
      toDate={toDate}
      fromDate={fromDate}
    />
  );
};

export default B2BAnalyticsContainer;
