/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
/** ========= CUSTOM COMPONENTS ============== */
import XLSX from "xlsx";
import ProductManagement from "../components/ProductManagement";
/** ===== API SERVICE FUNCTIONS =========== */
import { getProducts, setStatus, getCatalogConfiguration } from "../../../api/productManagementServices";
import {
  getSitePreviewConfigurations, getRunningJobs, replicateCatalogue, getCatalogueReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";

import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import api from "../../../api/httpApi";
import constants from "../../../common/utils/constants";

let cancelPreviousCall;

/*  Main container of the ProductManagementContainer */
const ProductManagementContainer = () => {
  const history = useHistory();
  const location = useLocation();
  /** state variables */
  const [products, setProducts] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  // Pagination related
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [isProccessing, setIsProccessing] = useState(false);
  const [active, setActive] = useState("");
  const [isDownloadInProgress, setDownloadInProgress] = useState(false);
  const [brandId, setBrandId] = useState("");
  const [brands, setBrands] = useState([]);
  const {
    PRODUCT_LISTING_SORT_BY_UPDATED_DATE,
  } = constants;
  const sortDir = "desc";
  const sortBy = PRODUCT_LISTING_SORT_BY_UPDATED_DATE;
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [confirmDisableProductModalData, setConfirmDisableProductModalData] = useState({
    isModalOpen: false,
    productId: "",
    forceIndex: false,
  });
  const getReplicationStatus = async () => {
    getCatalogueReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last product push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };
  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);


  // This method is used to list data from server
  const getProductListFromServer = (localSearchTerm, brand, status, newPage = 0, paginationLimitIn = itemsPerPage) => {
    let searchValue;
    if (localSearchTerm) {
      searchValue = localSearchTerm.trim();
    }
    /** getting list of products when page load */
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    setIsProccessing(true);
    getProducts(cancelToken, searchValue, brand, status, newPage, paginationLimitIn, sortBy, sortDir).then((response) => {
      if (response && response.success && response.data) {
        const { content, totalPages, totalElements } = response.data;
        if (Array.isArray(content)) {
          setProducts(content);
          setTotalPagesCount(totalPages);
          setNumberOfElements(totalElements);
        } else {
          setProducts([]);
        }
        setIsProccessing(false);
      }
    });
  };

  // This method used to change product status
  const changeProductStatus = async (data) => {
    const { productId: id, forceIndex } = data;
    if (submitting) return;
    setSubmitting(true);
    await setStatus(id, forceIndex);
    getProductListFromServer(searchTerm, brandId, active);
    setSubmitting(false);
    setConfirmDisableProductModalData({
      productId: "",
      isModalOpen: "",
      forceIndex: false,
      uniqueId: "",
      status: false,
    });
  };

  // This method is used to handle clicks on react-paginate component
  const onPageChange = (data) => {
    const { selected } = data;
    if (brandId) {
      getProductListFromServer(searchTerm, brandId, active, selected, itemsPerPage);
    }
  };

  // This method is used to update text in search box
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getProductListFromServer(value, brandId, active); } else getProductListFromServer(null, brandId, active);
  };

  // This method is used to load edit page
  const addNewProduct = () => {
    history.push("/merchandising/product/new");
  };

  // This method is used to open edit page
  const editProduct = (productId) => {
    history.push(`/merchandising/product/edit/${productId}`);
  };
  const viewProduct = (productId) => {
    history.push({
      pathname: `/merchandising/product/view/${productId}`,
      state: { brandId },
    });
  };


  useEffect(() => {
    getCatalogConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && Array.isArray(data.brands)) {
          const availableBrands = data.brands.map((brand) => ({ label: brand, value: brand }));
          setBrands(availableBrands);
          const [defaultBrand] = data.brands;
          setBrandId(defaultBrand);
          const previousBrand = (location && location.state && location.state.brand) || null;
          if (previousBrand != null) {
            setBrandId(previousBrand);
            getProductListFromServer(null, previousBrand, active);
          }
        }
      }
    });
  }, []);

  const onHandleClear = () => {
    setBrandId("");
    setSearchTerm("");
    setProducts([]);
    setTotalPagesCount();
    setNumberOfElements();
  };

  const handlePushToLive = async () => {
    const runningJobsResponse = await getRunningJobs();
    if (runningJobsResponse) {
      const { data: runningJobs } = runningJobsResponse;
      if (!runningJobs || runningJobs.length === 0) {
        setPushToLiveButtonEnabled(false);
        setShowPushToLiveAlert(true);
        replicateCatalogue().then((response) => {
          if (response && response.success) {
            const alertData = {
              type: "success",
              message: "Products pushed to live successfully. Please refresh the search indexes through Administration > Search Index Management, there may be a small delay for reflecting the same in live.",
            };
            setAlert(alertData);
          } else {
            const alertData = {
              type: "danger",
              message: "Something went wrong. Push to live failed",
            };
            setAlert(alertData);
          }
        });
      } else {
        const alertData = {
          type: "warning",
          message: "An Automated batch job is running in background, Please wait and try again later to make your changes live.",
        };
        setAlert(alertData);
      }
    }
    return null;
  };


  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    getProductListFromServer(null, brandId, active, 0, value);
  };
  const handleBrandChange = (event) => {
    const { value } = event.target;
    setBrandId(value);
    if (value) {
      getProductListFromServer(null, value, active);
    }
  };

  const handleChangeProductStatus = (id, uniqueId, isOpen, status) => {
    setConfirmDisableProductModalData({
      productId: id,
      isModalOpen: isOpen,
      forceIndex: false,
      uniqueId,
      status,
    });
  };

  const toggleForceIndex = () => {
    const newData = {
      ...confirmDisableProductModalData,
      forceIndex: !confirmDisableProductModalData.forceIndex,
    };
    setConfirmDisableProductModalData(newData);
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setActive(value);
    getProductListFromServer(null, brandId, value);
  };

  const addZero = (val) => (val < 10 ? `0${val}` : val);

  const onHandleDownload = async (type) => {
    if (type) {
      setDownloadInProgress(true);
      const page = 0;
      const size = numberOfElements;
      const currentDateTime = new Date();
      await getProducts(null, searchTerm, brandId, active, page, size, sortBy, sortDir, null, true).then((response) => {
        if (response && response.success && response.data
         && Array.isArray(response.data.content) && response.data.content.length > 0) {
          const filtered = response.data.content.map(({
            uniqueId,
            name,
            brand,
          }) => ({
            "External Id": uniqueId,
            Name: name,
            Brand: brand,
          }));
          const year = currentDateTime.getFullYear();
          const month = addZero(currentDateTime.getMonth() + 1);
          const date = currentDateTime.getDate();
          const hours = currentDateTime.getHours();
          const mins = addZero(currentDateTime.getMinutes());
          const secs = currentDateTime.getSeconds();
          let fileName = `Product_${year}${month}${date}${hours}${mins}${secs}.xlsx`;
          if (type === "CSV") {
            fileName = `Product_${year}${month}${date}${hours}${mins}${secs}.csv`;
          }
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(filtered);
          XLSX.utils.book_append_sheet(wb, ws, "products");
          ws["!cols"] = [
            { wch: 15 },
            { wch: 30 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
          ];
          XLSX.writeFile(wb, fileName);
          setDownloadInProgress(false);
        }
      }).catch((err) => {
        setDownloadInProgress(false);
      });
    }
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_VIEW_LIST}
    >
      <ProductManagement
        submitting={submitting}
        changeProductStatus={changeProductStatus}
        addNewProduct={addNewProduct}
        editProduct={editProduct}
        searchTerm={searchTerm}
        handleSearchTerm={handleSearchTerm}
        onHandleClear={onHandleClear}
        productData={products}
        message={message}
        pushToLiveEnabled={pushToLiveEnabled}
        previewSiteUrl={previewSiteUrl}
        handlePushToLive={handlePushToLive}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
        previewEnabled={previewEnabled}
        viewProduct={viewProduct}
        handleItemsPerPageChange={handleItemsPerPageChange}
        totalPagesCount={totalPagesCount}
        itemsPerPage={itemsPerPage}
        onPageChange={onPageChange}
        numberOfElements={numberOfElements}
        isProccessing={isProccessing}
        handleBrandChange={handleBrandChange}
        brands={brands}
        brandId={brandId}
        confirmDisableProductModalData={confirmDisableProductModalData}
        handleChangeProductStatus={handleChangeProductStatus}
        toggleForceIndex={toggleForceIndex}
        handleStatusChange={handleStatusChange}
        onHandleDownload={onHandleDownload}
        isDownloadInProgress={isDownloadInProgress}
      />
    </Permission>
  );
};

export default ProductManagementContainer;
