/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import XLSX from "xlsx";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AddCustomerGroup from "../components/AddCustomerGroup";
/** ========= API SERVICE FUNCTIONS ========= */
import { addCustomerGroup, getCustomersByIds } from "../../../api/customerGroupServices";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";
import UserUtils from "../../../common/utils/UserUtils";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const AddCustomerGroupContainer = () => {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const positions = constants.NAVIGATION_MENU_POSITIONS;
  const { CUSTOMER_GROUP } = constants;
  /** local states */
  const [form, setForm] = useState({
    name: "",
    description: "",
    type: "",
    criteria: "",
    matchType: "",
    pattern: "",
    orderAmount: "",
    noOfDays: "",
  });
  const param = useParams();
  const { salesCompany } = param;
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    const localForm = { ...form };
    localForm[name] = value;
    if (name === "type") {
      localForm.criteria = "";
      localForm.matchType = "";
      localForm.pattern = "";
      localForm.orderAmount = "";
      localForm.noOfDays = "";
      setUserIds([]);
    }
    if (name === "criteria") {
      localForm.matchType = "";
      localForm.pattern = "";
      localForm.orderAmount = "";
      localForm.noOfDays = "";
      setUserIds([]);
    }
    if (name === "matchType") {
      localForm.pattern = "";
      setUserIds([]);
    }
    setForm(localForm);
  };

  /**
         * This method is used to change form filed input
         * @param {Event} event
         */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
         * Clear button trigger
         */
  const formCancel = () => {
    history.push("/marketing/customergroups");
  };

  /**
          * This method is used to submit the form for add new customer Group
          * @param {Event} event
          */
  const formSubmit = async (event) => {
    const requestBody = {
      name: form.name,
      description: form.description,
      type: form.type,
      active: true,
      customers,
      salesCompany,
    };
    if (form.type === "dynamic") {
      const criteria = {
        type: form.criteria,
        includeCriteria: {},
        excludeCriteria: {},
      };
      if (form.criteria === CUSTOMER_GROUP.BIG_SPENDER) {
        criteria.noOfDays = form.noOfDays;
        criteria.includeCriteria.orderAmount = form.orderAmount;
        criteria.includeCriteria.guestUser = false;
      }
      if (form.criteria === CUSTOMER_GROUP.EMPLOYEE) {
        criteria.noOfDays = form.noOfDays;
        criteria.includeCriteria.pattern = form.pattern;
        criteria.includeCriteria.guestUser = false;
        criteria.includeCriteria.exactMatch = form.matchType === CUSTOMER_GROUP.EXACT_MATCH;
        criteria.excludeCriteria.orderAmount = form.orderAmount;
      }
      requestBody.criteria = criteria;
    } else {
      requestBody.criteria = null;
    }
    setMessage({ type: null, message: "" });

    setOnSubmitting(true);
    const response = await addCustomerGroup(requestBody);
    if (response && response.success) {
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/marketing/customergroups");
        setMessage({ type: "Success", message: "Customer Group Details Updated Successfully" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  const uploadCustomers = (e) => {
    if (e && e.target && e.target.files) {
      const [file] = e.target.files;
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".csv")) {
        window.scrollTo(0, 0);
        setMessage({ type: "warning", message: "Invalid file type. Please upload a XLSX/CSV file" });
      } else {
        const reader = new FileReader();
        reader.onload = async (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNameList = workbook.SheetNames;
          const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
          const parsedData = UserUtils.parseDataFromSheet(rawData);
          if (parsedData.isValid) {
            const filterDuplicate = parsedData.value.filter((each) => !userIds.includes(each));
            const newUsers = [...filterDuplicate];
            setUserIds(newUsers);
          } else {
            window.scrollTo(0, 0);
            setMessage({ type: "warning", message: parsedData.message || "Something went wrong.Please try again" });
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const downloadCustomers = (e) => {
    e.preventDefault();
    const data = userIds.map((each) => ({ email: each }));
    UserUtils.downloadXlsxDocument("customers.xlsx", data);
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_CRT_CSTMR_GRP}
    >
      <AddCustomerGroup
        // form field
        name={form.name}
        description={form.description}
        type={form.type}
        userIds={userIds}
        userList={userList}
        criteria={form.criteria}
        matchType={form.matchType}
        pattern={form.pattern}
        noOfDays={form.noOfDays}
        orderAmount={form.orderAmount}
        message={message}
        /** form action */
        positions={positions}
        onSubmitting={onSubmitting}
        handleFormChange={handleFormChange}
        handleChange={handleChange}
        formSubmit={formSubmit}
        formCancel={formCancel}
        setUserIds={setUserIds}
        uploadCustomers={uploadCustomers}
        downloadCustomers={downloadCustomers}
        setCustomers={setCustomers}
        customers={customers}
      />
    </Permission>
  );
};

export default AddCustomerGroupContainer;
