/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */


import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Label from "../../../common/core/Label";
import FormGroup from "../../../common/core/FormGroup";
import Button from "../../../common/core/Button";
import CardBody from "../../../common/core/CardBody";
import styles from "../css/SiteSettingsManagement.module.css";
import toggleOnIcon from "../../../common/assets/images/svg/toggle-on.svg";
import toggleOffIcon from "../../../common/assets/images/svg/toggle-off.svg";


const SiteSettingsManagement = ({ siteSettings, handleChange, handleCancel, saveSiteSettings }) => {
  const { showOutOfStockProducts, showZeroPriceProduct } = siteSettings;
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">Site Settings</span>
        </Col>
        <Col xs="12" md="5" className="d-flex justify-content-end">
          <Button type="button" onClick={handleCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
            CANCEL
          </Button>
          <Button type="submit" onClick={saveSiteSettings} color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
            SAVE
          </Button>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <CardBody>
            <Col xs="12" sm="6">
              <FormGroup className="d-flex">
                <Label className="formTextLabel">
                  Display out of stock products
</Label>
                <img
                  src={showOutOfStockProducts ? toggleOnIcon : toggleOffIcon}
                  className={clsx(styles.outOfStockSwitch)}
                  width="23px"
                  alt="drag indiactor icon"
                  onClick={() => handleChange("showOutOfStockProducts", !showOutOfStockProducts)}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="6" pl="2">
              <FormGroup className="d-flex">
                <Label className="formTextLabel">
                  Display zero priced products
</Label>
                <img
                  src={showZeroPriceProduct ? toggleOnIcon : toggleOffIcon}
                  className={clsx(styles.zeroPriceProductStatusSwitch)}
                  width="23px"
                  alt="drag indiactor icon"
                  onClick={() => handleChange("showZeroPriceProduct", !showZeroPriceProduct)}
                />
              </FormGroup>
            </Col>
          </CardBody>
        </Col>
      </Row>
    </>
  );
};
SiteSettingsManagement.propTypes = {
  siteSettings: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SiteSettingsManagement;
