/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Harikrishnan A K
 */

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  disableAccessibleOrganizations,
  enableAccessibleOrganizations,
  getB2BCustomerDetailsById,
  getB2BDepartmentDetailsByCustomerId,
  getB2BUserDetailsByCustomer,
  getSalesRepresentativesOfCustomer,
} from "../../../api/customerConfigurationService";
import {
  disableB2BDepartment, enableB2BDepartment,
} from "../../../api/customerDataService";
import ViewCustomer from "../components/ViewCustomer";

const ViewCustomerContainer = () => {
  const history = useHistory();
  const params = useParams();
  if (!params.customerId) {
    history.push("/administration/b2bcustomer");
  }
  const { customerId } = params;
  const [customer, setCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);
  const [salesReps, setSalesReps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const getAllSalesRepInfo = () => {
    getSalesRepresentativesOfCustomer(customerId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setSalesReps(data);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getB2BCustomerDetailsById(customerId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setCustomer(data);
      }
      setIsLoading(false);
    });
    getAllSalesRepInfo();
  }, [customerId]);

  const getB2BDepartmentDetails = () => {
    getB2BDepartmentDetailsByCustomerId(customerId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && data.length > 0) {
          setSubAccounts(data);
        }
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getB2BDepartmentDetails();
  }, []);

  const onHandleCancel = () => {
    history.push("/administration/b2bcustomer");
  };

  useEffect(() => {
    getB2BUserDetailsByCustomer(customerId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setUsers(data);
      }
    });
  }, [customerId]);

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 5000);
  };
  /**
     * This method is used to check whether customer can be activated or not
     */
  const canActivate = (department) => {
    if (department
      && department.email
      && department.classification
      && department.priceList
      && (department.shippingAddress[0] && department.shippingAddress.length > 0
        && (department.shippingAddress[0].addressLine1 || department.shippingAddress[0].city
          || department.shippingAddress[0].country || department.shippingAddress[0].postCode))
    ) {
      return true;
    }
    const modalData = {
      success: false,
      message: `${department.email ? "" : "Email ,"}
                    ${department.priceList ? "" : "Pricelist ,"}
                    ${department.classification ? "" : "Classification ,"}
                    ${department.shippingAddress[0] && department.shippingAddress.length > 0
          && (department.shippingAddress[0].addressLine1 || department.shippingAddress[0].city
            || department.shippingAddress[0].country || department.shippingAddress[0].postCode)
          ? "" : "Address "} are mandatory for Account activation`,
      type: "warning",
    };
    setAlert(modalData);
    return false;
  };

  const handleB2BDepartmentEnableStatus = async (departmentDetails, departmentId, status) => {
    const activate = canActivate(departmentDetails);
    let response = null;
    if (activate) {
      if (status === true) {
        response = await enableB2BDepartment(departmentId);
      } else {
        response = await disableB2BDepartment(departmentId);
      }
      if (response && response.success) {
        getB2BDepartmentDetails();
      }
    }
  };

  const handleAccessibleOrganizationStatus = async (user, status) => {
    if (user) {
      const { inactiveAccessibleOrganizations, accessibleOrganizations } = user;
      const allSubAccountIds = subAccounts.map((subAccount) => subAccount.id);
      if (status) {
        if (inactiveAccessibleOrganizations) {
          const { b2bDepartments } = inactiveAccessibleOrganizations;
          if (b2bDepartments
            && Array.isArray(b2bDepartments)
            && allSubAccountIds.some((subAccount) => b2bDepartments.indexOf(subAccount) >= 0)
          ) {
            const requestData = { userId: user.id, b2bDepartments: allSubAccountIds };
            await enableAccessibleOrganizations(requestData);
            getAllSalesRepInfo();
          }
        }
      } else if (accessibleOrganizations) {
        const { b2bDepartments } = accessibleOrganizations;
        if (b2bDepartments
          && Array.isArray(b2bDepartments)
          && allSubAccountIds.some((subAccount) => b2bDepartments.indexOf(subAccount) >= 0)
        ) {
          const requestData = { userId: user.id, b2bDepartments: allSubAccountIds };
          await disableAccessibleOrganizations(requestData);
          getAllSalesRepInfo();
        }
      }
    }
  };

  const checkInactiveAccessibleOrganizationStatus = (user) => {
    if (user) {
      const { inactiveAccessibleOrganizations, accessibleOrganizations } = user;
      if (inactiveAccessibleOrganizations) {
        const { b2bDepartments } = inactiveAccessibleOrganizations;
        if (b2bDepartments
          && Array.isArray(b2bDepartments)
          && subAccounts.some((subAccount) => b2bDepartments.indexOf(subAccount) >= 0)
        ) {
          return false;
        }
      }
      if (accessibleOrganizations) {
        const { b2bDepartments } = accessibleOrganizations;
        if (!b2bDepartments || !Array.isArray(b2bDepartments) || b2bDepartments.length === 0) {
          return false;
        }
      }
    }
    return true;
  };

  /**
        * This method is used to redirect to view customer page
        * @param {String} departmenrId
        */
  const viewDepartment = (departmenrId) => {
    history.push(`/administration/b2bcustomer/view/${customerId}/department/${departmenrId}`);
  };

  return (
    <ViewCustomer
      customer={customer}
      users={users}
      subAccounts={subAccounts}
      onHandleCancel={onHandleCancel}
      handleB2BDepartmentEnableStatus={handleB2BDepartmentEnableStatus}
      handleAccessibleOrganizationStatus={handleAccessibleOrganizationStatus}
      checkInactiveAccessibleOrganizationStatus={checkInactiveAccessibleOrganizationStatus}
      salesReps={salesReps}
      viewDepartment={viewDepartment}
      message={message}
      isLoading={isLoading}
    />
  );
};

export default ViewCustomerContainer;
