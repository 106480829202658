/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amritha
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */

import EditCustomerContainer from "./container/EditCustomerContainer";
import ViewCustomerContainer from "./container/ViewCustomerContainer";
import B2BCustomerDataManagementContainer from "./container/B2BCustomerDataManagementContainer";
import B2BCustomerListUIContainer from "./container/B2BCustomerListUIContainer";
import B2BCustomerDetailsUIContainer from "./container/B2BCustomerDetailsUIContainer";
import ViewDepartmentContainer from "./container/ViewDepartmentContainer";
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */


/** components */


const B2BCustomerManagement = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={B2BCustomerDataManagementContainer} />
    <PrivateRouter exact path={`${path}/edit/:customerId`} component={EditCustomerContainer} />
    <PrivateRouter exact path={`${path}/view/:customerId`} component={ViewCustomerContainer} />
    <PrivateRouter exact path={`${path}/list`} component={B2BCustomerListUIContainer} />
    <PrivateRouter exact path={`${path}/details`} component={B2BCustomerDetailsUIContainer} />
    <PrivateRouter exact path={`${path}/view/:customerId/department/:departmentId`} component={ViewDepartmentContainer} />
    {/* <PrivateRouter exact path="" component={NotFound} /> */}
  </Switch>
);

B2BCustomerManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default B2BCustomerManagement;
