import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import ViewProduct from "../components/ViewProduct";
import { getProduct } from "../../../api/productManagementServices";

function ViewProductContainer() {
  const { params } = useRouteMatch();
  const [form, setForm] = useState({
    id: "",
    name: "",
    productType: "",
    description: "",
    longDescription: "",
    startDate: "",
    endDate: "",
    primaryParentCategory: "",
    landingPageUrl: "",
    images: [],
    skus: [],
    relatedProducts: [],
  });
  const [categories, setCategories] = useState([]);
  const [retailerExtensions, setRetailerExtensions] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const relatedProducts = [];
  useEffect(() => {
    if (params.productId) {
      getProduct(params.productId).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          //   const { parentCategoryDetails } = response.data;
          //   if (parentCategoryDetails != null) {
          //     parentCategoryDetails.map((parentCategory) => {
          //       const primaryParentCategory = parentCategory.id;
          //     });
          //   }
          setForm(data);
          setCategories(data.parentCategoryDetails);
          const { retailerExtensions: re, ...rest } = data;
          setRetailerExtensions({ ...re } || null);
          setProductDetails({ ...rest } || null);
        }
      });
    }
    // setId(params.productId);
  }, [params.productId]);

  const history = useHistory();
  const location = useLocation();
  const brand = (location && location.state && location.state.brandId) || null;
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const editProduct = (productId) => {
    history.push(`/merchandising/product/edit/${productId}`);
  };

  const onHandleCancel = () => {
    history.push({
        pathname : "/merchandising/product",
        state: { brand }
    });
  };

  return (
    <ViewProduct
      form={form}
      handleChange={handleChange}
      editProduct={editProduct}
      onHandleCancel={onHandleCancel}
      relatedProductDetails={relatedProducts}
      retailerExtensions={retailerExtensions}
      productDetails={productDetails}
      categories={categories}
    />
  );
}
export default ViewProductContainer;
