/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddonText from "../../../common/core/InputGroupText";
import styles from "../css/ItemGroupSelector.module.css";
import ClearIcon from "../../../common/assets/images/svg/clear.svg";
import FormGroup from "../../../common/core/FormGroup";

const ItemGroupSelector = (props) => {
  const {
    itemGroups,
    setItemGroups,
  } = props;

  const [newItemGroup, setNewItemGroup] = useState("");
  const handleTextChange = (e) => {
    setNewItemGroup(e.target.value);
  };

  const handleAddItemGroup = () => {
    if (newItemGroup && !itemGroups.includes(newItemGroup)) {
      setItemGroups("itemGroups", [...itemGroups, newItemGroup]);
      setNewItemGroup("");
    }
  };

  const removeItemGroup = (i) => {
    setItemGroups("itemGroups", itemGroups.filter((each) => each !== i));
  };

  return (
    <>
      <FormGroup>
        <InputGroup>
          <Input
            type="text"
            value={newItemGroup}
            className="ml-1"
            onChange={handleTextChange}
          />
          <InputGroupAddonText
            addonType="append"
            className="commonPointer bg-color-tertiary-main"
            onClick={handleAddItemGroup}
          >
            Add
          </InputGroupAddonText>
        </InputGroup>
      </FormGroup>
      {
          itemGroups.map((option) => (
            <span key={option} className={styles.chip}>
              <span className="commonPointer text-truncate pr-1">
                {option}
              </span>
              <span
                className={clsx(styles.remove, "commonPointer")}
                onKeyDown={() => { }}
                tabIndex={0}
                role="button"
                onClick={() => removeItemGroup(option)}
              >
                <img src={ClearIcon} alt="remove" />
              </span>
            </span>
          ))
        }
    </>
  );
};

ItemGroupSelector.propTypes = {
  setItemGroups: PropTypes.func,
  itemGroups: PropTypes.arrayOf(PropTypes.any),
};

ItemGroupSelector.defaultProps = {
  itemGroups: [],
  setItemGroups: () => {},
};

export default ItemGroupSelector;
