/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/MultiSelect";

const ProductSelector = ({
  name, handleSearchFiled, products, setProductSelected, selectedProducts,
}) => {
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["product"]);

  return (
    <Select
      className="formText"
      placeholder={t("product:placeholder_select_Products")}
      name={name}
      id="product"
      value={selectedProducts}
      onChange={setProductSelected}
      typeahead={handleSearchFiled}
      typeaheadText="Product name"
      typeaheadPlaceholder="Search ID, name"
      options={
        Array.isArray(products)
        && products.map(({ id: value, name: label }) => ({ value, label }))
      }
    />
  );
};

ProductSelector.propTypes = {
  // products
  name: PropTypes.string.isRequired,
  productList: PropTypes.arrayOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  setProductSelected: PropTypes.func.isRequired,
  // search product
  searchKeyword: PropTypes.string.isRequired,
  handleSearchFiled: PropTypes.func.isRequired,

};

export default ProductSelector;
