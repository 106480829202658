/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";

/** ===== IMAGE ICONS =========== */
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

/** ========= CUSTOM COMPONENTS ========= */
import TreeCategorySelector from "../containers/TreeCategorySelectorContainer";


/** ======== MODULE STYLES ========== */
import styles from "../css/ParentCategory.module.css";

const ParentCategory = (props) => {
  const {
    parentCategory, handleParentCategory, dropdownOpen, toggle, categoryName, clearCategories, exclude, disbaledivClass,
  } = props;

  return (
    <Col xs="12" sm="7" md="6" className={clsx(styles.rightDivider, "position-relative")}>
      <FormGroup>
        <Label className="formTextLabel">Parent category</Label>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className={`${styles.dropdown} ${disbaledivClass}`}>
          <DropdownToggle
            className="formText form-control"
            caret
          >
            <span className="text-truncate">
              {categoryName || "Root category"}
            </span>
            <span className="ml-auto">
              <span />
              {
                categoryName && (
                  <span role="button" onKeyDown={() => { }} tabIndex={0} className={styles.clear} onClick={clearCategories}>
                    <img src={ClearIcon} alt="" />
                  </span>
                )
              }
            </span>
          </DropdownToggle>
          <DropdownMenu className={clsx(styles.dropdownMenu, "custom-scrollbar")}>
            <TreeCategorySelector
              categories={parentCategory}
              onChange={handleParentCategory}
              fullObject
              exclude={exclude}
            />
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
    </Col>
  );
};

ParentCategory.defaultProps = {
  categoryName: null,
  parentCategory: null,
};

ParentCategory.propTypes = {
  // tag
  categoryName: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  clearCategories: PropTypes.func.isRequired,
  exclude: PropTypes.arrayOf(PropTypes.any).isRequired,
  // parent category
  parentCategory: PropTypes.string,
  handleParentCategory: PropTypes.func.isRequired,
  disbaledivClass: PropTypes.string.isRequired,
};

export default ParentCategory;
