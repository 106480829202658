/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";

/** ===== IMAGE ICONS =========== */

/** ========= CUSTOM COMPONENTS ========= */
import ImageSelection from "../containers/ImageSelectionContainer";
import ProductTypeSelector from "../containers/ProductTypeSelectorContainer";
import CategorySelector from "../../categoryManagement/containers/SimpleCategorySelectorContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/ProductForm.module.css";
import FormFeedback from "../../../common/core/FormFeedback";
import commonUtils from "../../../common/utils/commonUtils";
import constants from "../../../common/utils/constants";

const { DATE_FORMAT } = constants;

const ProductForm = (props) => {
  const {
    name,
    productType,
    selectedCategories,
    categories,
    showInStorefront,
    activeInSource,
    images,
    handleFormChange,
    handleChange,
    description,
    longDescription,
    startDate,
    endDate,
    landingPageUrl,
    register,
    errors,
    primaryParentCategory,
    productOptions,
    translatableFileds,
    isDefault,
  } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);
  return (
    <>
      <Row className={styles.block3}>
        <Col lg="10">
          <Row>
            <Col xs="12" sm="11" md="12">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_name")}</Label>
                <Input
                  className="formText"
                  placeholder={t("product:placeholder_name")}
                  name="name"
                  defaultValue={name}
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("name") : false}
                  onChange={handleFormChange}
                  innerRef={
                    register({
                      required: t("common:validation_name"),
                      minLength: {
                        value: 3,
                        message: t("common:validation_min_three-letter"),
                      },
                    })
                  }
                  invalid={!!(errors.name && errors.name.message)}
                />
                <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("product:label_type")}</Label>
                <ProductTypeSelector
                  name="productType"
                  value={productType}
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("productType") : false}
                  onChange={(o) => handleChange("productType", o)}
                />
              </FormGroup>
            </Col>
            <Col xs="5" sm="6" md="7">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Label title={t("product:label_enable")} className="formTextLabel text-truncate">{t("common:label_storefront_status")}</Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-1",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="enableProduct-new-product"
                    name="enableProduct"
                    checked={showInStorefront}
                    disabled={!activeInSource}
                    onChange={() => handleChange("showInStorefront", !showInStorefront)}
                  />
                  <Label className="custom-control-label" htmlFor="enableProduct-new-product" />
                </div>
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="12">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_description")}</Label>
                <Input className="formText" placeholder={t("product:placeholder_description")} name="description" value={description} onChange={handleFormChange} disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("description") : false} />
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="12">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_long_description")}</Label>
                <Input className="formText" placeholder={t("product:placeholder_long_description")} name="longDescription" value={longDescription} onChange={handleFormChange} disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("longDescription") : false} />
              </FormGroup>
            </Col>
            <Col xs="12" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  Start date
                </Label>
                <DatePicker
                  id="startDate"
                  dateFormat={DATE_FORMAT}
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{
                    placeholder: `Ex: ${moment().format(DATE_FORMAT)}`,
                    name: "startDate",
                    readOnly: true,
                  }}
                  value={moment(startDate).format(DATE_FORMAT)}
                  onChange={(o) => (moment.isMoment(o) && handleChange("startDate", o.toDate()))}
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("startDate") : false}
                  required
                  isValidDate={(date) => commonUtils.maxDate(date, endDate)}

                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  End date
                </Label>
                <DatePicker
                  id="endate"
                  dateFormat={DATE_FORMAT}
                  timeFormat={false}
                  closeOnSelect
                  isValidDate={(date) => commonUtils.minDate(date, startDate)}
                  inputProps={{
                    placeholder: `Ex: ${moment().format(DATE_FORMAT)}`,
                    name: "endtDate",
                    readOnly: true,
                  }}
                  name="endDate"
                  value={moment(endDate).format(DATE_FORMAT)}
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("endtDate") : false}
                  onChange={(o) => (moment.isMoment(o) && handleChange("endDate", o.toDate()))}
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="5" md="4">
              <FormGroup className="text-truncate ">
                <Label className="formTextLabel mb-1 text-truncate">{t("product:label_landing_page_url")}</Label>
                <Input className="formText" placeholder={t("product:placeholder_landing_page_url")} name="landingPageUrl" value={landingPageUrl} onChange={handleFormChange} disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("landingPageUrl") : false} />
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="12">
              <FormGroup>
                <Label className="formTextLabel">{t("product:banner_images")}</Label>
                <ImageSelection images={images} handleChange={handleChange} disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("images") : false} />
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="12">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_category")}</Label>
                <CategorySelector
                  name="categories"
                  value={selectedCategories}
                  options={categories}
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("categories") : false}
                  onChange={(o) => handleChange("selectedCategories", o)}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_primary_category")}</Label>
                {Array.isArray(categories) && categories.length > 0 ? (
                  <Input
                    className="formText"
                    placeholder="Primary Category "
                    type="select"
                    name="categories"
                    value={primaryParentCategory}
                    disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("categories") : false}
                    options={categories}
                    onChange={(e) => handleChange("primaryParentCategory", e.target.value)}
                  >
                    {categories.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </Input>
                )
                  : (
                    <Input
                      className="formText"
                      placeholder="None "
                      type="text"
                      name="categories"
                      disabled
                    />
                  )}
              </FormGroup>
              <FormGroup>
                {productOptions && Array.isArray(productOptions) && productOptions.length > 0 ? (
                  <span>
                    <Label className="formTextLabel">{t("common:label_product_options")}</Label>
                    <br />
                    {Array.isArray(productOptions) ? productOptions.join(", ") : null}
                  </span>
                )
                  : ""}
                <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ProductForm.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  showInStorefront: PropTypes.bool.isRequired,
  activeInSource: PropTypes.bool.isRequired,
  productType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  landingPageUrl: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  startDate: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  isDefault: PropTypes.string.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // validation
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductForm;
