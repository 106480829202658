/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * role management services
 *
 * @author Amritha
 *
 */
const retailAdminRoles = [
  {
    label: "Super User",
    value: "SUPER_USER",
    type: "RetailAdmin",
    disabled: false,
  },
  {
    label: "Customer Service Admin",
    value: "CUSTOMER_SERVICE_ADMIN",
    type: "RetailAdmin",
    disabled: false,
  },
  {
    label: "Super Admin",
    value: "SUPER_ADMIN",
    type: "RetailAdmin",
    disabled: false,
  },
];
export {
  retailAdminRoles as default,
  retailAdminRoles,
};
