/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */


import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";
/** ========= MODULE STYLES ========= */
import styles from "../css/PromotionCreatorView.module.css";
/** ========= UTIlS ========= */
import constants from "../../../common/utils/constants";
/** ========= SUB COMPONENT ========= */
import PromotionForm from "./PromotionForm";
import DiscountForm from "./DiscountForm";
import AdvancedSettingsForm from "./AdvancedSettingsForm";
import DiscountedProductsForm from "./DiscountedProductsForm";
import StorefrontSettingsForm from "./StorefrontSettingsForm";
import ClosenessQualifierForm from "./ClosenessQualifierForm";

const PromotionCreatorView = (props) => {
  const {
    validator,
    name, description, priority, externalId, startDate, endDate, combine, categories, products, submitting, indefinite, excludeProductsOnOffer, discountedItemsSameAsApplicableItems, payFor,
    handleFormChange, handleChange, classType, discountType, cartValue, promotionType, onCampaign, brands, excludedProducts, showInBrowsingJourney, badge, colorOptions,
    discountStrategyCategories, discountStrategyBrands, discountStrategyProducts, discountStrategyExcludedProducts, discountedExcludeProductsOnOffer,
    discountRate,
    defaultDiscountRate,
    discountAmount,
    miniProduct,
    buy, amount, status,
    submitForm, cancelForm, calloutMessage,
    isClosenessQualifierEnabled, closenessQualifierMessage, closenessApproachMessage, closenessQualifierCriteriaSubTotal,
    isapplicationsPerOrderUnlimited, maxApplicationsPerOrder,
    refDiscountedElements, refQualifiedElements, refProductOptions, message,
    fulfilmentTypes, restrictedChannelGroups, productOptions, bonusProduct, promotionBundles,
    handleBulkImport, categoryDetails, productDetails, brandDetails, excludedProductDetails, downloadBulkImportTemplate,
    loadingFlags,
    itemGroups,
    enableItemGroupSelector,
  } = props;

  const {
    BUY_X_GET_Y_PROMOTION,
    BUY_X_GET_Y_FREE,
    PERCENTAGE_OFF,
    CLASS_SHIPPING,
  } = constants.PROMOTION;
  const isDiscountedItemsSameAsApplicableItemsDisabled = () => {
    let disable = false;
    if (discountType === BUY_X_GET_Y_PROMOTION) {
      if (promotionType === PERCENTAGE_OFF) disable = true;
    }
    return disable;
  };

  const isDisabledDiscountedItemsSameAsApplicableItemsHidden = () => {
    let hidden = true;
    if (discountType === BUY_X_GET_Y_PROMOTION) {
      hidden = false;
    }

    return hidden;
  };

  const disableDiscountedItemsSameAsApplicableItems = isDiscountedItemsSameAsApplicableItemsDisabled();
  const hideDiscountedItemsSameAsApplicableItems = isDisabledDiscountedItemsSameAsApplicableItemsHidden();
  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);
  const { handleSubmit } = validator;
  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <fieldset disabled={submitting}>
        {
          !onCampaign && (
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">{t("promotion:title_create")}</span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")} onClick={cancelForm}>
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {t("common:button_save")}
                </Button>
              </Col>
            </Row>
          )
        }
        {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
        <PromotionForm
          name={name}
          handleChange={handleChange}
          handleFormChange={handleFormChange}
          startDate={startDate}
          endDate={endDate}
          indefinite={indefinite}
          discountType={discountType}
          cartValue={cartValue}
          promotionType={promotionType}
          discountRate={discountRate}
          discountAmount={discountAmount}
          miniProduct={miniProduct}
          buy={buy}
          amount={amount}
          status={status}
          validator={validator}
          onCampaign={onCampaign}
          priority={priority}
        />
        <DiscountForm
          name={name}
          classType={classType}
          discountType={discountType}
          cartValue={cartValue}
          promotionType={promotionType}
          discountRate={discountRate}
          defaultDiscountRate={defaultDiscountRate}
          discountAmount={discountAmount}
          miniProduct={miniProduct}
          buy={buy}
          payFor={payFor}
          amount={amount}
          handleChange={handleChange}
          handleFormChange={handleFormChange}
          validator={validator}
          onCampaign={onCampaign}
          isapplicationsPerOrderUnlimited={isapplicationsPerOrderUnlimited}
          maxApplicationsPerOrder={maxApplicationsPerOrder}
          externalId={externalId}
          fulfilmentTypes={fulfilmentTypes}
          refProductOptions={refProductOptions}
          productOptions={productOptions}
          bonusProduct={bonusProduct}
          promotionBundles={promotionBundles}
          promotionBundleDetails={promotionBundles}
          handleBulkImport={handleBulkImport}
          downloadBulkImportTemplate={downloadBulkImportTemplate}
          loadingFlags={loadingFlags}
        />
        <AdvancedSettingsForm
          combine={combine}
          categories={categories}
          products={products}
          excludedProducts={excludedProducts}
          discountType={discountType}
          handleChange={handleChange}
          handleFormChange={handleFormChange}
          validator={validator}
          onCampaign={onCampaign}
          brands={brands}
          excludeProductsOnOffer={excludeProductsOnOffer}
          discountedItemsSameAsApplicableItems={discountedItemsSameAsApplicableItems}
          disableDiscountedItemsSameAsApplicableItems={disableDiscountedItemsSameAsApplicableItems}
          hideDiscountedItemsSameAsApplicableItems={hideDiscountedItemsSameAsApplicableItems}
          refQualifiedElements={refQualifiedElements}
          restrictedChannelGroups={restrictedChannelGroups}
          handleBulkImport={handleBulkImport}
          categoryDetails={categoryDetails}
          brandDetails={brandDetails}
          productDetails={productDetails}
          excludedProductDetails={excludedProductDetails}
          downloadBulkImportTemplate={downloadBulkImportTemplate}
          loadingFlags={loadingFlags}
          itemGroups={itemGroups}
          enableItemGroupSelector={enableItemGroupSelector}
        />
        {
          (
            promotionType === BUY_X_GET_Y_FREE && !discountedItemsSameAsApplicableItems
          ) && (
            <DiscountedProductsForm
              categories={discountStrategyCategories}
              products={discountStrategyProducts}
              excludedProducts={discountStrategyExcludedProducts}
              handleChange={handleChange}
              handleFormChange={handleFormChange}
              validator={validator}
              onCampaign={onCampaign}
              brands={discountStrategyBrands}
              excludeProductsOnOffer={discountedExcludeProductsOnOffer}
              refDiscountedElements={refDiscountedElements}
            />
          )
        }
        {/* <StorefrontSettingsForm
          handleChange={handleChange}
          handleFormChange={handleFormChange}
          validator={validator}
          colorOptions={colorOptions}
          badge={badge}
          calloutMessage={calloutMessage}
          onCampaign={onCampaign}
          showInBrowsingJourney={showInBrowsingJourney}
          description={description}
        /> */}
        {classType === CLASS_SHIPPING
          ? (
            <ClosenessQualifierForm
              onCampaign={onCampaign}
              isClosenessQualifierEnabled={isClosenessQualifierEnabled}
              handleChange={handleChange}
              closenessQualifierMessage={closenessQualifierMessage}
              closenessApproachMessage={closenessApproachMessage}
              closenessQualifierCriteriaSubTotal={closenessQualifierCriteriaSubTotal}
              handleFormChange={handleFormChange}
              validator={validator}
            />
          )
          : ""}
        {
          onCampaign && (
            <div className="w-100 d-flex justify-content-end">
              <Button type="button" onClick={cancelForm} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                {t("common:button_cancel")}
              </Button>
              <Button type="submit" color="tertiary" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size,btn-tertiary ")}>
                {t("common:button_save")}
              </Button>
            </div>
          )
        }
      </fieldset>
    </Form>
  );
};

PromotionCreatorView.propTypes = {
  // form field
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  priority: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  onCampaign: PropTypes.bool.isRequired,

  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  indefinite: PropTypes.bool.isRequired,

  promotionType: PropTypes.func.isRequired,
  classType: PropTypes.string.isRequired,
  discountType: PropTypes.string.isRequired,

  combine: PropTypes.string.isRequired,
  categories: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  fulfilmentTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  restrictedChannelGroups: PropTypes.arrayOf(PropTypes.any).isRequired,

  excludeProductsOnOffer: PropTypes.bool.isRequired,
  discountedItemsSameAsApplicableItems: PropTypes.bool.isRequired,
  showInBrowsingJourney: PropTypes.bool.isRequired,
  badge: PropTypes.string.isRequired,
  colorOptions: PropTypes.arrayOf(PropTypes.any).isRequired,

  discountStrategyCategories: PropTypes.objectOf(PropTypes.any).isRequired,
  discountStrategyBrands: PropTypes.objectOf(PropTypes.any).isRequired,
  discountStrategyProducts: PropTypes.objectOf(PropTypes.any).isRequired,
  discountStrategyExcludedProducts: PropTypes.objectOf(PropTypes.any).isRequired,
  discountedExcludeProductsOnOffer: PropTypes.bool.isRequired,

  cartValue: PropTypes.string.isRequired,
  discountRate: PropTypes.string.isRequired,
  discountAmount: PropTypes.string.isRequired,
  miniProduct: PropTypes.string.isRequired,
  buy: PropTypes.string.isRequired,
  payFor: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  isapplicationsPerOrderUnlimited: PropTypes.bool.isRequired,
  maxApplicationsPerOrder: PropTypes.number.isRequired,
  productOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  bonusProduct: PropTypes.string.isRequired,
  promotionBundles: PropTypes.arrayOf(PropTypes.any).isRequired,

  message: PropTypes.string.isRequired,
  isClosenessQualifierEnabled: PropTypes.bool.isRequired,
  closenessQualifierMessage: PropTypes.string.isRequired,
  closenessApproachMessage: PropTypes.string.isRequired,
  closenessQualifierCriteriaSubTotal: PropTypes.number.isRequired,
  categoryDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  productDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  calloutMessage: PropTypes.string.isRequired,
  // functions
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  cancelForm: PropTypes.func.isRequired,
  handleBulkImport: PropTypes.func.isRequired,
  downloadBulkImportTemplate: PropTypes.func.isRequired,
  loadingFlags: PropTypes.objectOf(PropTypes.any).isRequired,

  // validation
  validator: PropTypes.shape({
    handleSubmit: PropTypes.func,
  }).isRequired,

  // scroll ref
  refDiscountedElements: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refQualifiedElements: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refProductOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,

  //item group
  itemGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  enableItemGroupSelector: PropTypes.bool.isRequired,
};


export default PromotionCreatorView;
