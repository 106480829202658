/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 *
 */

import moment from "moment";
import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

/** ========= SUB COMPONENT ========= */
import EditCoupon from "../components/EditCoupon";

/** ========= API SERVICE FUNCTIONS ========= */
import { getCoupon, updateCoupon } from "../../../api/couponServices";
import { getTranslatedInputsByPage } from "../../../api/translationServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const EditCouponContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  if (!params.couponId) {
    history.push("/marketing/coupons");
  }

  const [form, setForm] = useState({
    id: "",
    code: "",
    description: "",
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    indefinite: false,
    active: false,
    usesPerCoupon: "1",
    usesPerCustomer: "1",
    unlimited: false,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [selectedLanguage, setLanguage] = useState("en");
  const [translatableFileds, setSelectedtranslatableFiledsect] = useState([]);

  useEffect(() => {
    let localForm = form;

    getCoupon(params.couponId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        localForm = {
          ...form,
          id: data.id,
          code: Array.isArray(data.couponCodes) ? data.couponCodes.join(",") : "",
          description: data.description || "",
          startDate: (data.startDate && moment(data.startDate)) || null,
          endDate: (data.endDate && moment(data.endDate)) || null,
          indefinite: data.indefinite,
          active: data.active,
          usesPerCoupon: data.usesPerCoupon === 0 ? "" : data.usesPerCoupon,
          usesPerCustomer: data.usesPerCustomer,
          unlimited: data.unlimited,
        };
        setForm(localForm);
      }
    });
  }, [params.couponId]);

  useEffect(() => {
    getTranslatedInputsByPage("coupons").then((response) => {
      if (response && response.data) {
        const { data } = response;
        if (data) {
          setSelectedtranslatableFiledsect(data);
        }
      }
    });
  }, []);

  /**
   * This method is used to change form state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    const formData = {
      ...form,
      [name]: value,
    };
    if (name === "code") {
      formData.code = value.trim().toUpperCase();
    }
    if (name === "unlimited" && value) {
      formData.unlimited = value;
      formData.usesPerCoupon = 0;
    }
    setForm(formData);
  };
  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const formatCouponCode = (code) => {
    const couponCode = code && code.split(",");
    return (couponCode.map((each) => each.trim()));
  };
  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const onSubmit = async (data, event) => {
    event.preventDefault();
    const requestBody = {
      id: form.id,
      couponCodes: formatCouponCode(form.code),
      description: form.description,
      startDate: (form.startDate && moment.isMoment(form.startDate) && moment(form.startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")) || null,
      endDate: (form.endDate && moment.isMoment(form.endDate) && moment(form.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")) || null,
      indefinite: !form.endDate,
      active: form.active,
      usesPerCoupon: form.unlimited ? null : form.usesPerCoupon,
      usesPerCustomer: form.usesPerCustomer,
      unlimited: form.unlimited,
    };

    /** clear the messages */
    setMessage({ type: null, message: "" });
    /** setting on submit true */
    setOnSubmitting(true);

    const response = await updateCoupon(requestBody);
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages[0] });
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/marketing/coupons");
        setMessage({ type: null, message: "" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  /**
 * This method is used to go back to coupons listing page
 */
  const formCancel = () => {
    history.push("/marketing/coupons");
  };

  /**
   * Method to select Language
   */
  const selectedLanguageChange = (value) => {
    setLanguage(value);
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_CPNS_UPD_CPNS}
    >
      <EditCoupon
        code={form.code}
        description={form.description}
        startDate={form.startDate}
        endDate={form.endDate}
        indefinite={form.indefinite}
        active={form.active}
        usesPerCoupon={form.usesPerCoupon}
        usesPerCustomer={form.usesPerCustomer}
        handleChange={handleChange}
        handleFormChange={handleFormChange}
        formCancel={formCancel}
        message={message}
        onSubmitting={onSubmitting}
        onSubmit={onSubmit}
        unlimited={form.unlimited}
        onCampaign={false}
        selectedLanguageChange={selectedLanguageChange}
        translatableFileds={translatableFileds}
        selectedLanguage={selectedLanguage}
      />
    </Permission>
  );
};

export default EditCouponContainer;
