/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * All authorization rules, that controlling UI is defined here
 * @module
 */
import { hasAccessRights } from "./permissionUtils";

/**
 * Rules.Navigation: Rules that decides user navigation across app
 * Rules.Control: Rules that decides user actions available on a particular page
 */
const Rules = {
  Navigation: {},
  Control: {
    // product configuration related accessRights
    MOZCOM_RA_MERCH_CTLG_PRDT_DLT_PRDT: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_DLT_PRDT"]),
    MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT"]),
    MOZCOM_RA_MERCH_CTLG_PRDT_CRT_PRDT: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_CRT_PRDT"]),
    MOZCOM_RA_MERCH_CTLG_PRDT_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CTLG_PRDT_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_VIEW_MENU"]),
    // Merchandising section View right
    MOZCOM_RA_MERCH_HEADER_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MERCH_HEADER_MENU_VIEW_MENU"]),
    // merch/catelog managemnt subsection view  and view of each sub section under catelog
    MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CTLG_AND_PRDT_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CTLG_PRDT_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CTLG_AND_INVTRY_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CTLG_INVTRY_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CTLG_AND_PRCBK_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CTLG_PRCBK_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CTLG_AND_PRDT_OPTN_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CTLG_AND_CTGRY_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CTLG_CTGRY_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CTLG_AND_BRND_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CTLG_BRND_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CTLG_AND_SRTNG_RLE_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CTLG_SRTNG_RLE_VIEW_MENU"]),
    MOZCOM_RA_MERCH_PRE_ORDR_CTLG_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_MENU_VIEW_MENU"]),
    // category related access rights
    MOZCOM_RA_MERCH_CTLG_CTGRY_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_CTGRY_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CTLG_CTGRY_DLT_CTGRY: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_CTGRY_DLT_CTGRY"]),
    MOZCOM_RA_MERCH_CTLG_CTGRY_UPD_CTGRY: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_CTGRY_UPD_CTGRY"]),
    MOZCOM_RA_MERCH_CTLG_CTGRY_CRT_CTGRY: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_CTGRY_CRT_CTGRY"]),
    // pre-order related access rights
    MOZCOM_RA_MERCH_PRE_ORDR_CTLG_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_PRE_ORDR_CTLG_VIEW_LIST"]),
    MOZCOM_RA_MERCH_PRE_ORDR_CTLG_UPD_PRE_ORDR_CTLG: () => hasAccessRights(["MOZCOM_RA_MERCH_PRE_ORDR_CTLG_UPD_PRE_ORDR_CTLG"]),
    // product options related access rights
    MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_DLT_PRDT_OPTN: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_DLT_PRDT_OPTN"]),
    MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_UPD_PRDT_OPTN: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_UPD_PRDT_OPTN"]),
    MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_CRT_PRDT_OPTN: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRDT_OPTN_CRT_PRDT_OPTN"]),
    // pricebook management related access rights
    MOZCOM_RA_MERCH_CTLG_PRCBK_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_PRCBK_VIEW_LIST"]),
    // brand configuration related access rights
    MOZCOM_RA_MERCH_CTLG_BRND_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_BRND_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CTLG_BRND_DLT_BRND: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_BRND_DLT_BRND"]),
    MOZCOM_RA_MERCH_CTLG_BRND_UPD_BRND: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_BRND_UPD_BRND"]),
    // inventory config access rights
    MOZCOM_RA_MERCH_CTLG_INVTRY_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_INVTRY_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CTLG_INVTRY_UPD_INVTRY: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_INVTRY_UPD_INVTRY"]),

    // sortingRule  config access Rights
    MOZCOM_RA_MERCH_CTLG_SRTNG_RLE_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_SRTNG_RLE_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CTLG_SRTNG_RLE_CRT_SRTNG_RLE: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_SRTNG_RLE_CRT_SRTNG_RLE"]),
    MOZCOM_RA_MERCH_CTLG_SRTNG_RLE_UPD_SRTNG_RLE: () => hasAccessRights(["MOZCOM_RA_MERCH_CTLG_SRTNG_RLE_UPD_SRTNG_RLE"]),

    // Report  section View right
    MOZCOM_RA_RPRT_HEADER_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_RPRT_HEADER_MENU_VIEW_MENU"]),
    // Report/OrderReport related access rights
    MOZCOM_RA_RPRT_ORDR_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_RPRT_ORDR_VIEW_LIST"]),
    MOZCOM_RA_RPRT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_RPRT_MENU_VIEW_MENU"]),
    MOZCOM_RA_RPRT_ORDR_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_RPRT_ORDR_VIEW_MENU"]),

    // Report/SiteTrafficReport access rights
    MOZCOM_RA_RPRT_SITE_TRFC_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_RPRT_SITE_TRFC_VIEW_MENU"]),
    MOZCOM_RA_RPRT_SITE_TRFC_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_RPRT_SITE_TRFC_VIEW_LIST"]),

    // merch/content managemnt subsection view  and view of each sub section under content
    MOZCOM_RA_MERCH_CONTNT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_MENU_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CONTNT_AND_LAYT_SETNG_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CONTNT_AND_PG_CSTMZN_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CONTNT_AND_STIC_PG_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CONTNT_STIC_PG_VIEW_MENU"]),
    MOZCOM_RA_MERCH_CONTNT_AND_MOB_APP_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_CONTNT_MOB_APP_CSTMZN_VIEW_MENU"]),
    // layoutSettings accessRights
    MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_UPD_LAYT_SETNG: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_UPD_LAYT_SETNG"]),
    MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_DLT_LAYT_SETNG: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_DLT_LAYT_SETNG"]),
    // web app customization accessRights
    MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_VIEW_LIST"]),
    MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_CRT_PG_CSTMZN: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_CRT_PG_CSTMZN"]),
    MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_UPD_PG_CSTMZN: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_UPD_PG_CSTMZN"]),
    MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_DLT_PG_CSTMZN: () => hasAccessRights(["MOZCOM_RA_MERCH_CONTNT_PG_CSTMZN_DLT_PG_CSTMZN"]),
    // merch/Site Navigations subMenu view  and view of each sub section under site navigation
    MOZCOM_RA_MERCH_SITE_NVGTN_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MERCH_SITE_NVGTN_MENU_VIEW_MENU"]),
    MOZCOM_RA_MERCH_SITE_NVGTN_AND_SITE_NVGTN_MGMNT_VIEW: () => hasAccessRights(["MOZCOM_RA_MERCH_SITE_NVGTN_MENU_VIEW_MENU", "MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_VIEW_MENU"]),
    // site navigation management accessRights
    MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_VIEW_LIST"]),
    MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_CRT_NVGTN: () => hasAccessRights(["MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_CRT_NVGTN"]),
    MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_UPD_NVGTN: () => hasAccessRights(["MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_UPD_NVGTN"]),
    MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_DLT_NVGTN: () => hasAccessRights(["MOZCOM_RA_MERCH_SITE_NVGTN_MGMNT_DLT_NVGTN"]),
    // Administration section View right
    MOZCOM_RA_ADMSTRTN_HEADER_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_HEADER_MENU_VIEW_MENU"]),
    // ADMSTRTN/User Management related access rights
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_VIEW_LIST"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_VIEW_LIST"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_CRT_USR: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_CRT_USR"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_UPD_USR: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_UPD_USR"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_UPD_CUSTMR: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_UPD_CUSTMR"]),
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_DLT_USR: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_DLT_USR"]),
    // ADMSTRTN/B2B Customer Management related access rights
    MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_EXPRT_LIST: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_EXPRT_LIST"]),
    // ADMSTRTN/Tax configuration related access rights
    MOZCOM_RA_ADMSTRTN_TAX_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_TAX_CLS_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_CLS_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_TAX_LCTN_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_LCTN_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_TAX_CLS_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_CLS_VIEW_LIST"]),
    MOZCOM_RA_ADMSTRTN_TAX_LCTN_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_LCTN_VIEW_LIST"]),
    MOZCOM_RA_ADMSTRTN_TAX_CLS_CRT_TAX_CLS: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_CLS_CRT_TAX_CLS"]),
    MOZCOM_RA_ADMSTRTN_TAX_CLS_UPD_TAX_CLS: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_CLS_UPD_TAX_CLS"]),
    MOZCOM_RA_ADMSTRTN_TAX_CLS_DLT_TAX_CLS: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_CLS_DLT_TAX_CLS"]),
    MOZCOM_RA_ADMSTRTN_TAX_LCTN_CRT_TAX_LCTN: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_LCTN_CRT_TAX_LCTN"]),
    MOZCOM_RA_ADMSTRTN_TAX_LCTN_UPD_TAX_LCTN: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_LCTN_UPD_TAX_LCTN"]),
    MOZCOM_RA_ADMSTRTN_TAX_LCTN_DLT_TAX_LCTN: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_TAX_LCTN_DLT_TAX_LCTN"]),
    // ADMSTRTN/Store Settings related access rights
    MOZCOM_RA_ADMSTRTN_STR_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_STR_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_STR_LCTN_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_STR_LCTN_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_STR_LCTN_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_STR_LCTN_VIEW_LIST"]),
    MOZCOM_RA_ADMSTRTN_STR_LCTN_CRT_STR_LCTN: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_STR_LCTN_CRT_STR_LCTN"]),
    MOZCOM_RA_ADMSTRTN_STR_LCTN_UPD_STR_LCTN: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_STR_LCTN_UPD_STR_LCTN"]),
    MOZCOM_RA_ADMSTRTN_STR_LCTN_DLT_STR_LCTN: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_STR_LCTN_DLT_STR_LCTN"]),
    // ADMSTRTN/Job Scheduling  related access rights
    MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_NEW_JOB_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_NEW_JOB_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_JOB_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_JOB_SCHDLNG_JOB_VIEW_MENU"]),
    // ADMSTRTN/Global settings related access rights
    MOZCOM_RA_ADMSTRTN_GLBL_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_GLBL_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_GLBL_CART_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_GLBL_CART_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_CTLG_PRDT_UPD_CNFG: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_CTLG_PRDT_UPD_CNFG"]),
    // ADMSTRTN/Search Configurations related access rights
    MOZCOM_RA_ADMSTRTN_SRCH_INDX_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_INDX_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_SRCH_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_SRCH_INDX_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_INDX_VIEW_LIST"]),
    MOZCOM_RA_ADMSTRTN_SRCH_SETTINGS_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_SETTINGS_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_SRCH_SYNONYMS_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_SYNONYMS_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_FACET_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_FACET_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_SRCH_FACET_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_FACET_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_SRCH_INDX_CRT_SRCH_INDX: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_INDX_CRT_SRCH_INDX"]),
    MOZCOM_RA_ADMSTRTN_SRCH_INDX_UPD_SRCH_INDX: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_INDX_UPD_SRCH_INDX"]),
    MOZCOM_RA_ADMSTRTN_SRCH_INDX_DLT_SRCH_INDX: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_SRCH_INDX_DLT_SRCH_INDX"]),
    MOZCOM_RA_ADMSTRTN_CACHE_MGMNT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_CACHE_MGMNT_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_CACHE_MGMNT_CLR_CACHE: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_CACHE_MGMNT_CLR_CACHE"]),
    MOZCOM_RA_ADMSTRTN_DISCOUNT_MGMNT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_DISCOUNT_MGMNT_MENU_VIEW_MENU"]),
    MOZCOM_RA_ADMSTRTN_DISCOUNT_MGMNT_EDIT: () => hasAccessRights(["MOZCOM_RA_ADMSTRTN_DISCOUNT_MGMNT_EDIT"]),
    // Operations section View accessRights
    MOZCOM_RA_OPRTN_HEADER_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_OPRTN_HEADER_MENU_VIEW_MENU"]),
    // operations/Order Management subsection view  and view of each sub section under catelog
    MOZCOM_RA_OPRTN_ORDR_MGMNT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_OPRTN_ORDR_MGMNT_MENU_VIEW_MENU"]),
    MOZCOM_RA_OPRTN_ORDR_MGMNT_AND_ORDR_VIEW: () => hasAccessRights(["MOZCOM_RA_OPRTN_ORDR_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_OPRTN_ORDR_MGMNT_ORDR_VIEW_MENU"]),
    MOZCOM_RA_OPRTN_ORDR_MGMNT_AND_SHPNG_MTHD_VIEW: () => hasAccessRights(["MOZCOM_RA_OPRTN_ORDR_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_OPERTNS_SHPNG_MTHD_VIEW_MENU"]),
    MOZCOM_RA_OPRTN_ORDR_MGMNT_AND_FULFILMNT_MTHD_VIEW: () => hasAccessRights(["MOZCOM_RA_OPRTN_ORDR_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_OPERTNS_FULFILMNT_MTHD_VIEW_MENU"]),
    MOZCOM_RA_OPRTN_ORDR_MGMNT_AND_PAYMNT_MTHD_VIEW: () => hasAccessRights(["MOZCOM_RA_OPRTN_ORDR_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_OPERTNS_PAYMNT_MTHD_VIEW_MENU"]),
    // order Management accessRights
    MOZCOM_RA_OPRTN_ORDR_MGMNT_ORDR_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_OPRTN_ORDR_MGMNT_ORDR_VIEW_LIST"]),
    // Batch management accessRights
    MOZCOM_RA_OPRTN_BATCH_MGMNT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_OPRTN_BATCH_MGMNT_MENU_VIEW_MENU"]),
    MOZCOM_RA_OPRTN_BATCH_MGMNT_AND_SCHEDLR_VIEW: () => hasAccessRights(["MOZCOM_RA_OPRTN_BATCH_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_OPRTN_BATCH_MGMNT_SCHEDLR_VIEW_MENU"]),
    MOZCOM_RA_OPRTN_BATCH_MGMNT_AND_JOB_AUDIT_VIEW: () => hasAccessRights(["MOZCOM_RA_OPRTN_BATCH_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_OPRTN_BATCH_MGMNT_AND_JOB_AUDIT_VIEW"]),
    MOZCOM_RA_OPRTN_BATCH_MGMNT_SCHEDLR_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_OPRTN_BATCH_MGMNT_SCHEDLR_VIEW_LIST"]),
    MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_AUDIT_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_AUDIT_VIEW_LIST"]),
    MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_STEP_AUDIT_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_OPRTN_BATCH_MGMNT_JOB_STEP_AUDIT_VIEW_LIST"]),
    // shipping methods access rights
    MOZCOM_RA_OPERTNS_SHPNG_MTHD_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_OPERTNS_SHPNG_MTHD_VIEW_LIST"]),
    MOZCOM_RA_OPERTNS_SHPNG_MTHD_CRT_SHPNG_MTHD: () => hasAccessRights(["MOZCOM_RA_OPERTNS_SHPNG_MTHD_CRT_SHPNG_MTHD"]),
    MOZCOM_RA_OPERTNS_SHPNG_MTHD_UPD_SHPNG_MTHD: () => hasAccessRights(["MOZCOM_RA_OPERTNS_SHPNG_MTHD_UPD_SHPNG_MTHD"]),
    MOZCOM_RA_OPERTNS_SHPNG_MTHD_DLT_SHPNG_MTHD: () => hasAccessRights(["MOZCOM_RA_OPERTNS_SHPNG_MTHD_DLT_SHPNG_MTHD"]),
    // fullfillment methods access rights
    MOZCOM_RA_OPERTNS_FULFILMNT_MTHD_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_OPERTNS_FULFILMNT_MTHD_VIEW_LIST"]),
    MOZCOM_RA_OPERTNS_FULFILMNT_MTHD_UPD_FULFILMENT_MTHD: () => hasAccessRights(["MOZCOM_RA_OPERTNS_FULFILMNT_MTHD_UPD_FULFILMENT_MTHD"]),
    // payment methods access rights
    MOZCOM_RA_OPERTNS_PAYMNT_MTHD_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_OPERTNS_PAYMNT_MTHD_VIEW_LIST"]),
    MOZCOM_RA_OPERTNS_PAYMNT_MTHD_UPD_PAYMNT_MTHD: () => hasAccessRights(["MOZCOM_RA_OPERTNS_PAYMNT_MTHD_UPD_PAYMNT_MTHD"]),
    // Marketing section View accessRights
    MOZCOM_RA_MRKTNG_HEADER_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MRKTNG_HEADER_MENU_VIEW_MENU"]),
    // Marketing/Marketing Management subsection view  and view of each sub section under Marketing
    MOZCOM_RA_MRKTNG_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MRKTNG_MENU_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_MRKTNG_AND_CMPGN_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_CMPGN_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_MRKTNG_AND_PRMTN_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_PRMTN_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_MRKTNG_AND_CPNS_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_CPNS_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_MRKTNG_AND_BADGES_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_BADGES_VIEW_MENU"]),
    // Marketing/SEO Settings subsection view  and view of each sub section under SEO Settings
    MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_SEO_AND_SITE_MP_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_SEO_SITE_MP_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_SEO_AND_PG_MTGS_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_SEO_PG_MTGS_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_SEO_AND_RBTS_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_SEO_RBTS_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_SEO_AND_CUSTM_URL_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_SEO_CUSTM_URL_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_SEO_AND_URL_REDIRECT_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_VIEW_MENU"]),
    // Marketing/Customer Management subsection view  and  sub sections under Customer Management
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_MENU_VIEW_MENU: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_MENU_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_AND_CSTMR_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_VIEW_MENU"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_AND_GRP_VIEW: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_MENU_VIEW_MENU", "MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_VIEW_MENU"]),
    // Campaign management accessRights
    MOZCOM_RA_MRKTNG_CMPGN_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CMPGN_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_CMPGN_CRT_CMPGN: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CMPGN_CRT_CMPGN"]),
    MOZCOM_RA_MRKTNG_CMPGN_UPD_CMPGN: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CMPGN_UPD_CMPGN"]),
    MOZCOM_RA_MRKTNG_CMPGN_DLT_CMPGN: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CMPGN_DLT_CMPGN"]),
    // Promotions management accessRights
    MOZCOM_RA_MRKTNG_PRMTN_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_PRMTN_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_PRMTN_CRT_PRMTN: () => hasAccessRights(["MOZCOM_RA_MRKTNG_PRMTN_CRT_PRMTN"]),
    MOZCOM_RA_MRKTNG_PRMTN_UPD_PRMTN: () => hasAccessRights(["MOZCOM_RA_MRKTNG_PRMTN_UPD_PRMTN"]),
    MOZCOM_RA_MRKTNG_PRMTN_DLT_PRMTN: () => hasAccessRights(["MOZCOM_RA_MRKTNG_PRMTN_DLT_PRMTN"]),
    // Coupon management accessRights
    MOZCOM_RA_MRKTNG_CPNS_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CPNS_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_CPNS_CRT_CPNS: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CPNS_CRT_CPNS"]),
    MOZCOM_RA_MRKTNG_CPNS_UPD_CPNS: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CPNS_UPD_CPNS"]),
    MOZCOM_RA_MRKTNG_CPNS_DLT_CPNS: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CPNS_DLT_CPNS"]),
    // Badges management accessRights
    MOZCOM_RA_MRKTNG_BADGES_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_BADGES_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_BADGES_CRT_BADGES: () => hasAccessRights(["MOZCOM_RA_MRKTNG_BADGES_CRT_BADGES"]),
    MOZCOM_RA_MRKTNG_BADGES_UPD_BADGES: () => hasAccessRights(["MOZCOM_RA_MRKTNG_BADGES_UPD_BADGES"]),
    MOZCOM_RA_MRKTNG_BADGES_DLT_BADGES: () => hasAccessRights(["MOZCOM_RA_MRKTNG_BADGES_DLT_BADGES"]),
    // Sitemap management accessRights
    MOZCOM_RA_MRKTNG_SEO_SITE_MP_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_SITE_MP_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_SEO_SITE_MP_CRT_SITE_MP: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_SITE_MP_CRT_SITE_MP"]),
    MOZCOM_RA_MRKTNG_SEO_SITE_MP_UPD_SITE_MP: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_SITE_MP_UPD_SITE_MP"]),
    MOZCOM_RA_MRKTNG_SEO_SITE_MP_DLT_SITE_MP: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_SITE_MP_DLT_SITE_MP"]),
    // Robots Text management accessRights
    MOZCOM_RA_MRKTNG_SEO_RBTS_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_RBTS_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_SEO_RBTS_FILE_UPLD: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_RBTS_FILE_UPLD"]),
    MOZCOM_RA_MRKTNG_SEO_RBTS_FILE_DWNLD: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_RBTS_FILE_DWNLD"]),
    // URL Redirects management accessRights
    MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_CRT_REDIRECT: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_CRT_REDIRECT"]),
    MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_UPD_REDIRECT: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_UPD_REDIRECT"]),
    MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_DLT_REDIRECT: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_URL_REDIRECT_DLT_REDIRECT"]),
    // Page MetaTags management accessRights
    MOZCOM_RA_MRKTNG_SEO_PG_MTGS_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_PG_MTGS_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_SEO_PG_MTGS_CRT_PG_MTGS: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_PG_MTGS_CRT_PG_MTGS"]),
    MOZCOM_RA_MRKTNG_SEO_PG_MTGS_UPD_PG_MTGS: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_PG_MTGS_UPD_PG_MTGS"]),
    MOZCOM_RA_MRKTNG_SEO_PG_MTGS_DLT_PG_MTGS: () => hasAccessRights(["MOZCOM_RA_MRKTNG_SEO_PG_MTGS_DLT_PG_MTGS"]),
    // Customer Data management accessRights
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_CRT_CSTMR: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_CRT_CSTMR"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_UPD_CSTMR: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_UPD_CSTMR"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_DLT_CSTMR: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_CSTMR_DLT_CSTMR"]),
    // Customer Group management accessRights
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_VIEW_LIST: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_VIEW_LIST"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_CRT_CSTMR_GRP: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_CRT_CSTMR_GRP"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_UPD_CSTMR_GRP: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_UPD_CSTMR_GRP"]),
    MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_DLT_CSTMR_GRP: () => hasAccessRights(["MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_DLT_CSTMR_GRP"]),
  },
};

export default Rules;
