/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import CampaignStepThreeView from "../components/CampaignStepThreeView";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { updateExperience } from "../../../api/campaignServices";

/** ============ SVG IMAGE ICON ===================== */
/** ========= UTILS =========== */
/** ========= MODULE STYLES ========= */

const CampaignStepThree = (props) => {
  const history = useHistory();
  const {
    nextStep, customerGroups, coupons, basicInfo, promotions: propPromotions, salesCompany,
  } = props;
  const campaignId = (basicInfo && basicInfo.id) || null;

  /** local states */
  const [form, setForm] = useState({
    customerGroups: [],
    coupons: [],
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [openExperienceSelector, setOpenExperienceSelector] = useState(false);
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [selectedPromotions, setSelectedPromotions] = useState([]);

  useEffect(() => {
    if (!(basicInfo && basicInfo.id)) {
      nextStep(1);
    }
  }, [basicInfo]);

  useEffect(() => {
    let customerGroupsIds = [];
    if (Array.isArray(customerGroups)) {
      customerGroupsIds = customerGroups.map((customerGroup) => customerGroup.id);
    }

    let couponsIds = [];
    if (Array.isArray(customerGroups)) {
      couponsIds = coupons.map((coupon) => coupon.id);
    }

    setForm((oldState) => ({
      ...oldState,
      customerGroups: customerGroupsIds,
      coupons: couponsIds,
    }));

    setSelectedPromotions((oldState) => (Array.isArray(oldState)
      ? oldState.map((promotion) => ({
        id: promotion.id,
        title: promotion.title,
        applyToEveryone: promotion.applyToEveryone,
        description: promotion.description,
        customerGroups: customerGroupsIds,
        coupons: couponsIds,
      }))
      : []));
  }, [customerGroups, coupons]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This function is used to submit the form
   * @param {Event} event
   */
  const formSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOnSubmitting(true);
    const mappedPromotions = selectedPromotions.map((promotion) => {
      const promotionId = promotion.id;
      const applyToEveryone = promotion.applyToEveryone || false;
      const associatedCoupons = promotion.coupons;
      const applicableCustomerGroups = promotion.customerGroups;
      return {
        promotionId,
        applyToEveryone,
        associatedCoupons,
        applicableCustomerGroups,
      };
    });
    const formData = {
      id: basicInfo.id,
      promotions: mappedPromotions,
    };

    updateExperience(formData).then((response) => {
      setOnSubmitting(false);

      if (response && response.success) {
        history.push("/marketing/campaign");
      }
    });
  };

  /** This function is used to go to previous step */
  const formBackHandler = () => {
    nextStep(2);
  };

  /** This method is used to open experience select */
  const handleExperienceSelector = (status) => {
    setOpenExperienceSelector(status);
  };

  /** This method is used to select experience */
  const handleSelectedExperience = (experience) => {
    setSelectedExperiences(experience);
    handleExperienceSelector(false);
  };

  /**
   * This method is used to handle selected promotions
   * @param {Array} promotions
   */
  const handleSelectedPromotions = (promotions) => {
    if (Array.isArray(promotions)) {
      const promotionsWithUserCoupons = promotions.map((promotion) => ({
        id: promotion.id,
        title: promotion.title,
        description: promotion.description,
        customerGroups: form.customerGroups,
        coupons: form.coupons,
        applyToEveryone: promotion.applyToEveryone,
      }));
      setSelectedPromotions(promotionsWithUserCoupons);
    }
  };

  /**
   * This function is used to change promotion value
   * @param {String} promotionId
   * @param {String} key
   * @param {Any} value
   */
  const handlePromotionChange = (promotionId, key, value) => {
    const newSelectedPromotions = selectedPromotions.map((promotion) => (promotion.id === promotionId ? ({ ...promotion, [key]: value }) : (promotion)));
    setSelectedPromotions(newSelectedPromotions);
  };
  /**
   * This function is used to handle change in multi select dropdown
   * @param {String} promotionId
   * @param {String} filterItem
   * @param {Event} event
   */
  const handleSelectionChange = (promotionId, filterItem, event) => {
    if (Array.isArray(selectedPromotions) && event && event.target) {
      if (Array.isArray(event.target.value)) {
        const { value } = event.target;
        handlePromotionChange(promotionId, filterItem, value);
      }
    }
  };


  useEffect(() => {
    if (propPromotions) {
      setSelectedPromotions(propPromotions);
    }
  }, [propPromotions]);

  return (
    <CampaignStepThreeView
      onSubmitting={onSubmitting}
      openExperienceSelector={openExperienceSelector}
      selectedExperiences={selectedExperiences}
      selectedPromotions={selectedPromotions}
      customerGroups={customerGroups}
      coupons={coupons}
      campaignId={campaignId}
      // functions
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      formSubmitHandler={formSubmitHandler}
      formBackHandler={formBackHandler}
      handleExperienceSelector={handleExperienceSelector}
      handleSelectedExperience={handleSelectedExperience}
      handleSelectedPromotions={handleSelectedPromotions}
      handleSelectionChange={handleSelectionChange}
      handlePromotionChange={handlePromotionChange}
      salesCompany={salesCompany}
    />
  );
};

CampaignStepThree.defaultProps = {
  basicInfo: null,
};

CampaignStepThree.propTypes = {
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  coupons: PropTypes.arrayOf(PropTypes.any).isRequired,
  basicInfo: PropTypes.objectOf(PropTypes.any),
  // functions
  nextStep: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default CampaignStepThree;
