/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Batch Job Audit Management
 *
 * @author Joice Tomy
 *
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/BatchManagement.module.css";
import CustomizationTable from "../../../common/components/Table";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Home from "../../../common/assets/images/svg/home.svg";
import commonUtils from "../../../common/utils/commonUtils";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownItem from "../../../common/core/DropdownItem";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Select from "../../../common/components/Select";

const getJobName = (row) => {
  let jobName;
  if (row.name === "b2bProductIndexJob") {
    jobName = "Product Index Job";
  } else if (row.name === "B2BGlobalInventoryJob") {
    jobName = "Inventory Job";
  } else if (row.name === "b2bOrderExportBatchJob") {
    jobName = "Order Export Job";
  } else if (row.name === "b2bOrderConfirmationEmailExportBatchJob") {
    jobName = "Order Email Job";
  } else if (row.name === "b2bDeltaBuildJob") {
    jobName = "Delta Build Job";
  } else if (row.name === "B2BGlobalPriceJob") {
    jobName = "Price Job";
  } else if (row.name === "b2bAnalyticsReportGenerateBatchJob") {
    jobName = "Analytics Report Job";
  } else {
    jobName = row.jobSchedule;
  }
  return jobName;
};
const customRows = [
  {
    label: "Id",
    path: "id",
    transform: "",
  },
  {
    label: "Job Name",
    value: "",
    path: "render",
    transform: "",
    render: getJobName,
  },
  {
    label: "Start Date",
    value: "",
    path: "startDate",
    transform: "",
  },
  {
    label: "End Date",
    value: "",
    path: "endDate",
    transform: "",
  },
  {
    label: "Status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Errors",
    value: "",
    path: "render",
    transform: "",
    render: (row) => {
      if (Array.isArray(row.errors) && row.errors.length > 0) {
        return (
          <p className="text-danger">true</p>
        );
      }
      return (
        <p className="text-success">false</p>
      );
    },
  },
];
const BatchJobAuditManagement = ({
  jobAudits,
  onHandleStatusChange,
  onHandleNameChange,
  ListDropdownenopen,
  toggleListDropdown,
  itemsPerPage,
  handleItemsPerPageChange,
  totalPagesCount,
  onPageChange,
  isProcessing,
  onClickRow,
  jobName,
  jobType,
  jobStatus,
}) => {
  const { t } = useTranslation(["common", "batch-management"]);
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText"><strong>{t("batch-management:batch_management_title")}</strong></span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/dashboard">
                <img src={Home} alt="Home" />
                {t("common:home")}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem><a href="/operations">{t("common:operations")}</a></BreadcrumbItem>
            <BreadcrumbItem><a href="/operations">{t("common:batch_management")}</a></BreadcrumbItem>
            <BreadcrumbItem active>{t("common:batch_job_audit")}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className={clsx(styles.block1, "common-page-card mb-0 pb-0")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="4" className="p-0 d-flex align-items-center">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("batch-management:job_audit_title")}
              </span>
            </Col>
            {/* </Row>
          <Row className={clsx(styles.block2, "common-page-card")}> */}
            <Col xs="8">
              <Row className="m-0 justify-content-end">
                <Col xs="12" md="3">
                  <FormGroup>
                    <Label className="formTextLabel" for="schedulerName">
                      {t("batch-management:scheduler_name_selection")}
                    </Label>
                    <Select
                      className="formTextLabel"
                      name="name"
                      onChange={(e) => onHandleNameChange(e)}
                      value={jobName}
                      options={[
                        {
                          value: "", selected: true, label: "ALL",
                        },
                        ...(
                          Array.isArray(jobType) ? jobType.map((item) => ({ value: item.value, label: item.label })) : []
                        ),
                      ]}
                    />
                    <FormFeedback>You will not be able to see this</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12" md="3">
                  <FormGroup>
                    <Label className="formTextLabel" for="status">
                      {" "}
                      {t("batch-management:scheduler_status_selection")}
                    </Label>
                    <Select
                      className="formTextLabel"
                      name="status"
                      onChange={(e) => onHandleStatusChange(e)}
                      options={[
                        {
                          value: "", selected: true, label: "ALL",
                        },
                        ...(
                          Array.isArray(jobStatus) ? jobStatus.map((item) => ({ value: item.value, label: item.label })) : []
                        ),
                      ]}
                    />
                    <FormFeedback>You will not be able to see this</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="table-responsive mt-3">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={commonUtils.isListNotEmpty(jobAudits) ? jobAudits : []}
                  isSerialNoEnabled
                  isLoading={isProcessing}
                  onClickRow={onClickRow}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col xs="auto" className="d-flex align-items-center">
              Show
              <Dropdown
                isOpen={ListDropdownenopen}
                toggle={toggleListDropdown}
                className="dropdown-custom dropdown-number mx-2"
              >
                <DropdownToggle caret color="light" size="sm">
                  {itemsPerPage}
                </DropdownToggle>
                <DropdownMenu down>
                  <DropdownItem
                    onClick={() => handleItemsPerPageChange(10)}
                    onKeyPress={() => { }}
                  >
                    10
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleItemsPerPageChange(15)}
                  >
                    15
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleItemsPerPageChange(20)}
                    onKeyPress={() => { }}
                  >
                    20
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              entries
            </Col>
            <Col xs="auto">
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                pageCount={totalPagesCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={onPageChange}
                containerClassName="pagination"
                pageClassName="page"
                pageLinkClassName="pageLink"
                activeClassName="activePage"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

BatchJobAuditManagement.propTypes = {
  jobAudits: PropTypes.arrayOf(PropTypes.any).isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPagesCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onHandleNameChange: PropTypes.func.isRequired,
  onHandleStatusChange: PropTypes.func.isRequired,
  ListDropdownenopen: PropTypes.func.isRequired,
  toggleListDropdown: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  jobName: PropTypes.string.isRequired,
  jobType: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BatchJobAuditManagement;
