/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * User Management Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserManagement from "../components/UserManagement";
import {
  getUserList, getActiveUserRoles, deleteUser, enableUser, disableUser, getUsersWithSearchAndPagination,
} from "../../../api/UserServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  retailAdminRoles,
} from "../../../api/rolesManagement";

const UserManagementContainer = () => {
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [roles, setRoles] = useState("");
  const [active, setactive] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const limit = 10;
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [selectedDataId, setSelectedDataId] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allRoles, setAllRoles] = useState();
  useEffect(() => {
    const allLabel = [{
      label: "All",
      value: "",
    }];
    const role = [...allLabel, ...retailAdminRoles];
    setAllRoles(role);
  }, []);
  /**
        * This method is used to get List of user data
        */
  const geUsersListBySearchTerm = (localRole, localActive, pageNumber, localSearchTerm) => {
    const searchValue = localSearchTerm ? localSearchTerm.trim() : "";
    setIsLoading(true);
    getUsersWithSearchAndPagination(localRole, localActive, searchValue, pageNumber, limit).then(
      (response) => {
        setIsLoading(false);
        if (response && response.success && response.data) {
          if (Array.isArray(response.data)) {
            setUserList(response.data);
            const moreData = response.data.length > 0 && response.data.length >= limit;
            setHasMoreData(moreData);
          } else {
            setHasMoreData(false);
          }
          setPage(pageNumber);
        }
      },
    );
  };

  const getUserData = async (localRole, localActive, localPage) => {
    setIsLoading(true);
    await getUserList(localRole, localActive, localPage, limit).then((response) => {
      setIsLoading(false);
      if (response && response.data) {
        if (Array.isArray(response.data)) {
          setUserList(response.data);
          const moreData = response.data.length > 0 && response.data.length >= limit;
          setHasMoreData(moreData);
        } else {
          setHasMoreData(false);
        }
        setPage(localPage);
      }
    });
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    geUsersListBySearchTerm(roles, active, 0, value);
  };

  const onHandleRolesChange = (e) => {
    const { value } = e.target;
    setRoles(value);
    getUserData(value, active, 0);
  };
  const onHandleActiveChange = (e) => {
    const { value } = e.target;
    setactive(value);
    getUserData(roles, value, 0);
  };
  const getPageData = (direction) => {
    getUserData(roles, active, direction);
  };

  const onHandleNewUser = () => {
    history.push("/administration/user/new");
  };
  const onHandleEditUser = (id) => {
    history.push(`/administration/user/edit/${id}`);
  };

  /**
    * This method is used to get active roles for customer
    */
  const getAccessibleRoles = () => {
    getActiveUserRoles().then((response) => {
      if (response) {
        const { success, data: responseData } = response;
        if (success && Array.isArray(responseData) && responseData.length > 0) {
          const filteredData = responseData.filter((each) => each.code !== "RETAIL_ADMIN");
          setRoleList(filteredData);
          getUserData(allRoles, true, 0);
        }
      }
    });
  };

  const onHandleDeleteuser = async () => {
    await deleteUser(userIdToDelete);
    getUserData(roles, active, 0);
    setUserIdToDelete(null);
  };

  const onHandleDeleteModalOpen = (id) => {
    setUserIdToDelete(id);
  };

  const cancelForm = () => {
    history.push("/administration/user");
    setUserIdToDelete(null);
  };

  useEffect(() => { getAccessibleRoles(); }, []);

  const changeUserStatus = async (status, id) => {
    let response = null;
    setSelectedDataId(id);
    setSubmit(true);
    if (status === true) {
      response = await enableUser(id);
    } else {
      response = await disableUser(id);
    }
    if (response && response.success) {
      const newData = data.map((user) => (user.id === id
        ? ({ ...user, active: status }) : user));
      setData(newData);
    }
    getUserData(roles, active, 0);
    setSelectedDataId(null);
    setSubmit(false);
  };
  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_USR_MGMNT_USR_VIEW_LIST}
    >
      <UserManagement
        userList={userList}
        page={page}
        onHandleNewUser={onHandleNewUser}
        hasMoreData={hasMoreData}
        getPageData={getPageData}
        onHandleRolesChange={onHandleRolesChange}
        onHandleActiveChange={onHandleActiveChange}
        roleList={roleList}
        onHandleEditUser={onHandleEditUser}
        onHandleDeleteModalOpen={onHandleDeleteModalOpen}
        handleUserStatus={changeUserStatus}
        selectedDataId={selectedDataId}
        submit={submit}
        handleSearchTerm={handleSearchTerm}
        isLoading={isLoading}
        searchTerm={searchTerm}
        allRoles={allRoles}
      />
      <ConfirmationModal
        isOpen={Boolean(userIdToDelete)}
        toggleOpen={cancelForm}
        togglClose={cancelForm}
        handleConfirm={onHandleDeleteuser}
        content=" Are you sure you want to delete this user?"
      />
    </Permission>
  );
};
export default UserManagementContainer;
