/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amritha
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import PrivateRouter from "../../common/routes/PrivateRouter";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */

import SalesRepresentativeContainer from "./container/SalesRepresentativeContainer";
import ViewSalesRepresentativeInfoContainer from "./container/ViewSalesRepresentativeInfoContainer";

const SalesRepManagement = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={SalesRepresentativeContainer} />
    <PrivateRouter exact path={`${path}/view/:id`} component={ViewSalesRepresentativeInfoContainer} />
  </Switch>
);

SalesRepManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default SalesRepManagement;
