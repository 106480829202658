
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima Mohan
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */
import PageMetaTags from "../components/PageMetaTags";
import { getMetaTagConfiguration, updateMetaTagConfiguration } from "../../../api/SeoSettingsService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const PageMetaTagsContainer = () => {
  const history = useHistory();

  /** local states */
  const [activeTab, setActiveTab] = useState("product");
  const [isOpen, setIsOpen] = useState(true);
  const [form, setForm] = useState({
    productmetaTitle: "",
    productmetaKeyword: "",
    productmetaDescription: "",
    categorymetaTitle: "",
    categorymetaKeyword: "",
    categorymetaDescription: "",
  });

  const toggle = (active) => {
    setActiveTab(active);
    if (activeTab === active) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(isOpen);
    }
  };

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };
  /**
     * This method is used for  cancel
     */
  const onCancel = () => {
    history.push("/marketing/page-metatags");
  };

  const [onSubmitting, setOnSubmitting] = useState(false);
  const onSubmit = async (value) => {
    const requestBody = {
      productmetaTitle: form.productmetaTitle,
      productmetaKeyword: form.productmetaKeyword,
      productmetaDescription: form.productmetaDescription,
      categorymetaTitle: form.categorymetaTitle,
      categorymetaKeyword: form.categorymetaKeyword,
      categorymetaDescription: form.categorymetaDescription,
    };

    if (value) {
      /** setting on submit true */
      setOnSubmitting(true);
      const response = await updateMetaTagConfiguration(requestBody);
      if (response && response.success) {
        setOnSubmitting(false);
        history.push("/marketing/page-metatags");
      }
    }
  };

  useEffect(() => {
    let localForm = form;
    getMetaTagConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        localForm = {
          ...form,
          productmetaTitle: (data.product && data.product.pageTitlePattern) || "",
          productmetaKeyword: (data.product && data.product.keywordPattern) || "",
          productmetaDescription: (data.product && data.product.descriptionPattern) || "",
          categorymetaTitle: (data.category && data.category.pageTitlePattern) || "",
          categorymetaKeyword: (data.category && data.category.keywordPattern) || "",
          categorymetaDescription: (data.category && data.category.descriptionPattern) || "",
        };
        setForm(localForm);
      }
    });
  }, []);

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_SEO_PG_MTGS_VIEW_LIST}
    >
      <PageMetaTags activeTab={activeTab} toggle={toggle} isOpen={isOpen} handleChange={handleChange} productmetaTitle={form.productmetaTitle} productmetaKeyword={form.productmetaKeyword} productmetaDescription={form.productmetaDescription} categorymetaTitle={form.categorymetaTitle} categorymetaKeyword={form.categorymetaKeyword} categorymetaDescription={form.categorymetaDescription} onSubmit={onSubmit} onSubmitting={onSubmitting} onCancel={onCancel} handleFormChange={handleFormChange} />
    </Permission>
  );
};

export default PageMetaTagsContainer;
