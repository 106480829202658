/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React from "react";
import NewTaxClassForm from "../components/NewTaxClassForm";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const NewTaxClassFormContainer = (props) => (
  <Permission
    allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_CLS_CRT_TAX_CLS}
  >
    {" "}
    <NewTaxClassForm {...props} />
  </Permission>
);

export default NewTaxClassFormContainer;
