/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";

import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Alert from "../../../common/core/Alert";
import Select from "../../../common/components/MultiSelect/Select";


/** ===== IMAGE ICONS =========== */
import SearchIcon from "../../../common/assets/images/svg/search.svg";

/** ======== COMPONENTS */

import CustomizationTable from "../../../common/components/Table";

import styles from "../css/SearchIndexManagement.module.css";


const SearchIndexManagement = ({
  handleIndexing,
  rowsLoading,
  indexingMode,
  IndexingModeList,
  onIndexingModeChange,
  previewEnabled,
  message,
}) => (
  <>
    <Row>
      <Col xs="12">
        {
            message && message.type && (
              <Alert color={message.type}>
                {message.message}
              </Alert>
            )
          }
        <Row className="pageHeader common-page-card">
          <Col xs="6">
            <span className="pageText">Search Index Management</span>
          </Col>
          <Col xs="4">
            {previewEnabled
              ? (
                <Select
                  className="formText"
                  name="indexingMode"
                  id="indexingMode"
                  value={indexingMode}
                  onChange={onIndexingModeChange}
                  options={IndexingModeList}
                />
              )
              : ""}
          </Col>
        </Row>
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col className="mb-2" xs="12" sm="12" md="4">
            <span
              className="fontsizeMediumBold"
            >
              Manage Search Index
            </span>
          </Col>
          <Col className="mb-2" xs="12" sm>
            <div className={styles.areaActionRight}>
              <div>
                <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                  <Input placeholder="Search Index" />
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer bg-color-tertiary-main"
                  >
                    <img
                      src={SearchIcon}
                      alt="search index"
                      className={styles.icon}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={clsx(styles.tableWrapper, " common-page-card")}>
          <Col xs="12">
            <div className="custom-table ">
              <CustomizationTable
                customRows={[
                  {
                    label: "Indexer",
                    path: "name",
                  },
                  {
                    label: "Description",
                    path: "descirption",

                  },
                  {
                    label: "Reindex",
                    path: "render",
                    render: (row) => (
                      <div>
                        {row && row.indexStaus
                          ? <Button className={styles.buttonGreen}>Indexed</Button>
                          : (
                            <Button
                              className={styles.buttonRed}
                              onClick={() => handleIndexing(row)}
                              disabled={rowsLoading && rowsLoading.includes(row.name)}
                            >
                              {rowsLoading && rowsLoading.includes(row.name)
                                ? <span className={clsx(styles.spinnerStyle, "spinner-grow")} />
                                : "Reindex"}
                            </Button>
                          )}
                      </div>
                    ),
                  },
                ]}
                customizationData={[
                  {
                    name: "Products",
                    descirption: "Index Category/Product Association",
                    date: "12/12/2019",
                    id: "1",
                    indexStaus: false,
                  },
                  {
                    name: "Category",
                    descirption: "Index Category/Product Association",
                    date: "12/12/2019",
                    id: "095809",
                    indexStaus: false,

                  },
                ]}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
SearchIndexManagement.propTypes = {
  rowsLoading: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleIndexing: PropTypes.func.isRequired,
  indexingMode: PropTypes.string.isRequired,
  IndexingModeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  onIndexingModeChange: PropTypes.func.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
};


export default SearchIndexManagement;
