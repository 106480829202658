/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * CustomerGroup Management Container
 *
 * @author Indrajith C
 *
 */


import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CustomerGroupManagement from "../components/CustomerGroupManagement";
import {
  getCustomerGroups, setStatus, deleteUserGroup, getAllSalesCompany,
} from "../../../api/customerGroupServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
import api from "../../../api/httpApi";

let cancelPreviousCall;

const CustomerGroupManagementContainer = () => {
  const history = useHistory();


  const [customerGroups, setCustomerGroups] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const paginationLimit = 10;
  const [salesCompanyList, setSalesCompanyList] = useState([]);
  const [deleteCustomerGroup, setDeleteCustomerGroup] = useState(null);
  const [salesCompany, setSalesCompany] = useState("");
  /**
     * This method is used to cancel confirm form
    */
  const cancelConfirm = () => {
    setDeleteCustomerGroup(null);
  };

  /**
     * This method is used to redirect to edit customer group page
     * @param {String} couponId
     */
  const editCustomerGroup = (id) => {
    history.push(`/marketing/customergroups/edit/${id}/salesCompany/${salesCompany}`);
  };
  const addNewCustomerGroup = () => {
    history.push(`/marketing/customergroups/new/salesCompany/${salesCompany}`);
  };

  const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

  /**
   * This method is used to list customer Groups
   * @param {String} searchTerm
   * @param {Number} newPage
   * @param {Number} paginationLimit
   * @param {String} sortBy
   * @param {String} sortDirection
   */
  const getCustomerGroupList = (searchTerm, salesCompanyId, newPage = 0, sortBy = "createdDate", sortDirection = "DESC") => {
    setIsLoading(true);
    if (cancelPreviousCall) {
      cancelPreviousCall();
    }
    const { cancelToken, cancelMethod } = api.getCancelToken();
    cancelPreviousCall = cancelMethod;
    getCustomerGroups(searchTerm, salesCompanyId, newPage, paginationLimit, sortBy, sortDirection, false, cancelToken).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (Array.isArray(data)) {
          setCustomerGroups(data);
          setPage(Math.max(newPage, 0));
        }
        setIsLoading(false);
        setHasMoreData(Array.isArray(data) && data.length >= paginationLimit);
      }
    });
  };

  /**
     * method for delete confirm
     */
  const confirmDelete = async () => {
    if (Boolean(deleteCustomerGroup) && deleteCustomerGroup.id && deleteCustomerGroup.element) {
      const { id, element } = deleteCustomerGroup;
      const response = await deleteUserGroup(id);
      if (response && response.success) {
        getCustomerGroupList("", salesCompany, 0);
        if (element && element.style) { element.style.display = "none"; }
      }
      if (isFunction(deleteCustomerGroup.callback)) { deleteCustomerGroup.callback(); }
      setDeleteCustomerGroup(null);
    }
  };


  /**  This method used to change the customer group status
    * @param {String} id
    * @param {Boolean} status
    */
  const changeCustomerGroupStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    const response = await setStatus(id);
    if (response && response.success) {
      const newCustomerGroups = customerGroups.map((customerGroup) => (customerGroup.id === id
        ? ({ ...customerGroup, active: status }) : customerGroup));
      setCustomerGroups(newCustomerGroups);
    }
    setSubmitting(false);
  };

  /**
     * This method is used to trigger pagination
     * @param {Number} page
     */
  const getPageData = (newPage) => {
    getCustomerGroupList(searchTerm, salesCompany, Math.max(newPage, 0));
  };

  /**
     * This method is used to update text in search box
     * @param {Event} event
     */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getCustomerGroupList(value, salesCompany); } else getCustomerGroupList();
  };


  /** getting list of customer Groups when page load */
  useEffect(() => {
    getAllSalesCompany().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        const salesCompanyId = data[0].uniqueId;
        setSalesCompany(salesCompanyId);
        setSalesCompanyList(data);
        getCustomerGroupList("", salesCompanyId, 0);
      }
    });
  }, [paginationLimit]);
  const handleChange = (value) => {
    if (value) {
      setSalesCompany(value);
      getCustomerGroupList("", value, 0);
    }
  };
  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MRKTNG_CSTMR_MGMNT_GRP_VIEW_LIST}
    >
      <>
        <CustomerGroupManagement
          addNewCustomerGroup={addNewCustomerGroup}
          customerGroups={customerGroups}
          submitting={submitting}
          getPageData={getPageData}
          page={page}
          hasMoreData={hasMoreData}
          searchTerm={searchTerm}
          handleSearchTerm={handleSearchTerm}
          changeCustomerGroupStatus={changeCustomerGroupStatus}
          editCustomerGroup={editCustomerGroup}
          setDeleteCustomerGroup={setDeleteCustomerGroup}
          isLoading={isLoading}
          handleChange={handleChange}
          salesCompanyList={salesCompanyList}
          salesCompany={salesCompany}
        />
        <ConfirmationModal
          isOpen={Boolean(deleteCustomerGroup)}
          toggleOpen={cancelConfirm}
          togglClose={cancelConfirm}
          handleConfirm={confirmDelete}
          content=" Are you sure you want to delete this customer group ?"
        />
      </>
    </Permission>
  );
};
export default CustomerGroupManagementContainer;
