/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Role Management Container
 *
 * @author amritha
 *
 */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SalesRepresentativeView from "../components/SalesRepresentativeView";
import {
  getsalesRepresentativeInfo,
  enableSalesRepresentative,
  disableSalesRepresentative,
} from "../../../api/customerConfigurationService";

const SalesRepresentativeContainer = () => {
  const [salesRepInfo, setSalesRepInfo] = useState([]);
  const history = useHistory();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
  };
  /**
   * This method is used to list sales rep
   * @param {Number} newPage
   * @param {Number} itemsPerPage
   */
  const getAllSalesRepInfo = (searchTerm = "", newPage = 0, paginationLimitIn = itemsPerPage) => {
    if (searchTerm) {
      setSearchTerm(searchTerm.trim());
    }
    getsalesRepresentativeInfo(searchTerm, newPage, paginationLimitIn).then((response) => {
      if (response && response.success && response.data) {
        const { content, endOfResult, totalPages } = response.data || {};
        if (Array.isArray(content)) {
          setSalesRepInfo(content);
          setHasMoreData(!endOfResult);
          setTotalPagesCount(totalPages);
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 0));
      }
      setIsLoading(false);
    });
  };
  const onPageChange = (data) => {
    const { selected } = data;
    getAllSalesRepInfo(searchTerm, selected, itemsPerPage);
  };

  useEffect(() => {
    getAllSalesRepInfo();
  }, [itemsPerPage]);
  const viewSalesRepsInfo = (id) => {
    history.push(`/administration/sales-representative/view/${id}`);
  };
  const handleChangeSalesRepStatus = async (status, id) => {
    let response = null;
    if (status === true) {
      response = await enableSalesRepresentative(id);
    } else {
      response = await disableSalesRepresentative(id);
    }
    if (response && response.success) {
      getAllSalesRepInfo();
    }
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    getAllSalesRepInfo(value, 0);
  };
  return (
    <SalesRepresentativeView
      salesRepInfo={salesRepInfo}
      handleChangeSalesRepStatus={handleChangeSalesRepStatus}
      viewSalesRepsInfo={viewSalesRepsInfo}
      page={page}
      hasMoreData={hasMoreData}
      totalPagesCount={totalPagesCount}
      itemsPerPage={itemsPerPage}
      handleItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={onPageChange}
      handleSearchTerm={handleSearchTerm}
      isLoading={isLoading}
    />
  );
};
export default SalesRepresentativeContainer;
