/* eslint-disable import/prefer-default-export */
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */


import httpApi from "./httpApi";

/**
 *This method is used to clear all caches
 *
 * */
const clearAllCaches = async () => {
  const response = await httpApi.delete("/v1/cache/all");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear price book cache
 *
 * */
const clearPriceBookCache = async () => {
  const response = await httpApi.delete("/v1/cache/pricing/price-book");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear price book cache
 *
 * */
const clearPriceTableCache = async () => {
  const response = await httpApi.delete("/v1/cache/pricing/price-table");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear pricing engine cache
 *
 * */
const clearPricingEngineCache = async () => {
  const response = await httpApi.delete("/v1/cache/pricing-engine");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear categories cache
 *
 * */
const clearUserGroupsCache = async () => {
  const response = await httpApi.delete("/v1/cache/user-groups");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear promotions cache
 *
 * */
const clearPromotionsCache = async () => {
  const response = await httpApi.delete("/v1/cache/promotions");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear coupons cache
 *
 * */
const clearCouponsCache = async () => {
  const response = await httpApi.delete("/v1/cache/coupons");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear campaigns cache
 *
 * */
const clearCampaignCache = async () => {
  const response = await httpApi.delete("/v1/cache/campaigns");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear city cache
 *
 * */
const clearCityCache = async () => {
  const response = await httpApi.delete("/v1/cache/city");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear area cache
 *
 * */
const clearAreaCache = async () => {
  const response = await httpApi.delete("/v1/cache/area");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear app-accounts cache
 *
 * */
const clearAppAccountsCache = async () => {
  const response = await httpApi.delete("/v1/cache/app-accounts");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear catalog cache
 *
 * */
const clearCatalogCache = async () => {
  const response = await httpApi.delete("/v1/cache/catalog");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear categories cache
 *
 * */
const clearCategoriesCache = async () => {
  const response = await httpApi.delete("/v1/cache/categories");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear products cache
 *
 * */
const clearProductsCache = async () => {
  const response = await httpApi.delete("/v1/cache/products");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear organization cache
 *
 * */
const clearOrganizationCache = async () => {
  const response = await httpApi.delete("/v1/cache/organization");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to clear system settings" cache
 *
 * */
const clearSystemSettingsCache = async () => {
  const response = await httpApi.delete("/v1/cache/system-settings");
  if (response && response.data) {
    return response.data;
  }
  return null;
};


export {
  clearAllCaches,
  clearPriceBookCache,
  clearPriceTableCache,
  clearPricingEngineCache,
  clearCategoriesCache,
  clearUserGroupsCache,
  clearPromotionsCache,
  clearCouponsCache,
  clearCampaignCache,
  clearCityCache,
  clearAreaCache,
  clearAppAccountsCache,
  clearCatalogCache,
  clearProductsCache,
  clearOrganizationCache,
  clearSystemSettingsCache
};
