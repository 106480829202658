/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Kishor
 * */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, FormGroup, Label,
} from "reactstrap";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import CustomizationTable from "../../../common/components/Table";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Home from "../../../common/assets/images/svg/home.svg";
import commonUtils from "../../../common/utils/commonUtils";
import Select from "../../../common/components/Select";
import styles from "../css/CustomerAuditManagement.module.css";

const getJobName = (row) => {
  let jobName;
  if (row.name === "b2bCustomerFeedJob") {
    jobName = "Customer Feed Job";
  } else if (row.name === "b2bCustomerDeltaFeedJob") {
    jobName = "Customer Delta Feed Job";
  }
  return jobName;
};
const customRows = [
  {
    label: "Id",
    path: "id",
    transform: "",
  },
  {
    label: "Job Name",
    value: "",
    path: "render",
    transform: "",
    render: getJobName,
  },
  {
    label: "Start Date",
    value: "",
    path: "startDate",
    transform: "",
  },
  {
    label: "End Date",
    value: "",
    path: "endDate",
    transform: "",
  },
  {
    label: "Status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Errors",
    value: "",
    path: "render",
    transform: "",
    render: (row) => {
      if (Array.isArray(row.errors) && row.errors.length > 0) {
        return (
          <p className="text-danger">true</p>
        );
      }
      return (
        <p className="text-success">false</p>
      );
    },
  },
];

const CustomerAuditManagementComponent = ({
  onClickDownload,
  auditData,
  listDropdownOpen,
  itemsPerPage,
  toggleListDropdown,
  handleItemsPerPageChange,
  totalPagesCount,
  onPageChange,
  onHandleStatusChange,
  jobStatus,
  jobName,
  jobType,
  onHandleNameChange,
  onClickRow
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText">
            <strong>{t("common:customer_management")}</strong>
          </span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/dashboard">
                <img src={Home} alt="Home" />
                {t("common:home")}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/operations">Operations</a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/operations">Customer Management</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Customer Audit</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className={clsx("common-page-card mb-0 pb-0")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="4" className="p-0 d-flex align-items-center">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                Customer Audit
              </span>
            </Col>
            {/* </Row>
          <Row className={clsx(styles.block2, "common-page-card")}> */}
            <Col xs="8">
              <Row className="m-0 justify-content-end">
                <Col xs="12" md="3">
                  <FormGroup>
                    <Label className="formTextLabel" for="schedulerName">
                      {t("common:label_name")}
                    </Label>
                    <Select
                      className="formTextLabel"
                      name="name"
                      onChange={(e) => onHandleNameChange(e)}
                      value={jobName}
                      options={[
                        {
                          value: "", selected: true, label: "ALL",
                        },
                        ...(
                          Array.isArray(jobType) ? jobType.map((item) => ({ value: item.value, label: item.label })) : []
                        ),
                      ]}
                    />
                    <FormFeedback>You will not be able to see this</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12" md="3">
                  <FormGroup>
                    <Label className="formTextLabel" for="status">
                      {" "}
                      {t("common:table_title_status")}
                    </Label>
                    <Select
                      className="formTextLabel"
                      name="status"
                      onChange={(e) => onHandleStatusChange(e)}
                      options={[
                        {
                          value: "", selected: true, label: "ALL",
                        },
                        ...(
                          Array.isArray(jobStatus) ? jobStatus.map((item) => ({ value: item.value, label: item.label })) : []
                        ),
                      ]}
                    />
                    <FormFeedback>You will not be able to see this</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="table-responsive mt-3">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={commonUtils.isListNotEmpty(auditData) ? auditData : []}
                  isSerialNoEnabled
                  onClickRow={onClickRow}
                  // onClickDownload={onClickDownload}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col xs="auto" className="d-flex align-items-center">
              Show
              <Dropdown
                isOpen={listDropdownOpen}
                toggle={toggleListDropdown}
                className="dropdown-custom dropdown-number mx-2"
              >
                <DropdownToggle caret color="light" size="sm">
                  {itemsPerPage}
                </DropdownToggle>
                <DropdownMenu down>
                  {[10, 15, 20].map((perPage) => (
                    <DropdownItem
                      key={perPage}
                      onClick={() => handleItemsPerPageChange(perPage)}
                    >
                      {perPage}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              entries
            </Col>
            <Col xs="auto">
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                pageCount={totalPagesCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={onPageChange}
                containerClassName="pagination"
                pageClassName="page"
                pageLinkClassName="pageLink"
                activeClassName="activePage"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

CustomerAuditManagementComponent.propTypes = {
  auditData: PropTypes.arrayOf(PropTypes.any).isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPagesCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  onHandleNameChange: PropTypes.func.isRequired,
  onHandleStatusChange: PropTypes.func.isRequired,
  toggleListDropdown: PropTypes.func.isRequired,
  jobName: PropTypes.string.isRequired,
  jobType: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickDownload: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
};

export default CustomerAuditManagementComponent;
