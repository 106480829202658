/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Create Password
 *
 * @author Ajay J A
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Container from "../../../common/core/Container";
import Card from "../../../common/core/Card";
import Spinner from "../../../common/core/Spinner";
import styles from "../css/CreatePassword.module.css";

const CreatePassword = (props) => {
  const { t } = useTranslation(["create-password"]);
  const {
    handleSubmit,
    error,
    createPasswordData,
    handleChange,
    submitting,
    showNewPassword,
    showConfirmPassword,
    togglePassword,
  } = props;
  const TYPE_TEXT = "text";
  const TYPE_PASSWORD = "password";
  return (
    <Container className={styles.createPasswordWrapper1}>
      <Card className="loginWrapper">
        <h3>{t("create-password:create_new_password")}</h3>
        <div className="loginContent">
          <Form onSubmit={handleSubmit}>
            <fieldset disabled={submitting}>
              <FormGroup className="fixed-form-height">
                <Label for="newPassword">
                  {t("create-password:create_new_password")}
                </Label>
                <div className="input-password">
                  <Input
                    autoComplete="password"
                    value={createPasswordData.newPassword}
                    id="newPassword"
                    onChange={(e) => handleChange("newPassword", e)}
                    type={showNewPassword ? TYPE_TEXT : TYPE_PASSWORD}
                    invalid={error.newPassword}
                  />
                  {" "}
                  <FormFeedback>{error.newPassword}</FormFeedback>
                  <button className={`password-eye ${showNewPassword ? "active" : ""}`} onClick={() => togglePassword("showNewPassword")} type="button">
                    <span className="sr-only" />
                  </button>
                </div>
              </FormGroup>
              <FormGroup className="fixed-form-height-pass">
                <Label for="reTypePassword">
                  {t("create-password:retype_new_password")}
                </Label>
                <div className="input-password">
                  <Input
                    id="reTypePassword"
                    value={createPasswordData.reTypePassword}
                    onChange={(e) => handleChange("reTypePassword", e)}
                    type={showConfirmPassword ? TYPE_TEXT : TYPE_PASSWORD}
                    invalid={error.reTypePassword}
                  />
                  {" "}
                  <FormFeedback>{error.reTypePassword}</FormFeedback>
                  <button className={`password-eye ${showConfirmPassword ? "active" : ""}`} onClick={() => togglePassword("showConfirmPassword")} type="button">
                    <span className="sr-only" />
                  </button>
                  <p className="verifyLogin m-0 text-danger">{error.isValid}</p>
                </div>
              </FormGroup>
              <Button
                className={
                  error.isValid
                    ? "rounded bluecolor border-0 w-100"
                    : "rounded bluecolor border-0 w-100"
                }
              >
                {submitting ? (
                  <Spinner size="sm" animation="border" role="status">
                    <span className="sr-only">
                      {t("create-password:loading")}
                    </span>
                  </Spinner>
                ) : (
                    <span>{t("create-password:create_password")}</span>
                  )}
              </Button>
            </fieldset>
          </Form>
        </div>
      </Card>
    </Container>
  );
};

CreatePassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  createPasswordData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  showNewPassword: PropTypes.bool.isRequired,
  showConfirmPassword: PropTypes.bool.isRequired,
  togglePassword: PropTypes.func.isRequired,
};
export default CreatePassword;
