/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Order Management
 *
 * @author Naseef O
 *
 */
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DatePicker from "react-datetime";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import Home from "../../../common/assets/images/svg/home.svg";
import RefreshIcon from "../../../common/assets/images/svg/refresh-circle.svg";
import SearchIcon from "../../../common/assets/images/svg/search-black.svg";
import Select from "../../../common/components/Select";
import CustomizationTable from "../../../common/components/Table";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import Dropdown from "../../../common/core/Dropdown";
import DropdownItem from "../../../common/core/DropdownItem";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Label from "../../../common/core/Label";
import Row from "../../../common/core/Row";
import styles from "../css/orderManagement.module.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Spinner from "../../../common/core/Spinner";
import UserData from "../../../common/security/UserData";


const OrderManagement = ({
  setToDate,
  setFromDate,
  setSearchTerm,
  onHandleSearch,
  orders,
  page,
  getPageData,
  hasMoreData,
  isValidDate,
  isProccessing,
  onHandleExport,
  totalPagesCount,
  itemsPerPage,
  onPageChange,
  handleItemsPerPageChange,
  onClickRow,
  onHandleClear,
  searchTerm,
  fromDate,
  toDate,
  handleSearchTerm,
  salesCompanyList,
  handleChange,
  salesCompany,
  orderType,
  catalogType,
  orderExportStatus,
  orderEmailConfirmation,
  isOrderExportedToOms,
  isConfirmationMailSent,
  totalNumberOfOrders,
  openRepostOrderModal,
  repostOrderToOms,
  repostingOrder,
  isOrderEligibleForRepost,
  isRepostOrderModalOpen,
  closeRepostOrderModal,
  selectedOrderIdToRepost,

}) => {
  const customRows = [
    {
      label: "No",
      value: "",
      path: "orderNo",
      transform: "",
    },
    {
      label: "Date",
      value: "",
      path: "orderDate",
      transform: "",
      type: "date",
    },
    {
      label: "Placed by",
      value: "",
      path: "submittedName",
      render: (row) => {
        if (row && row.billingAddress) {
          if (row.billingAddress.contactPerson) {
            return (
              <p>{row.billingAddress.contactPerson}</p>
            );
          }
          if (row.billingAddress.firstName) {
            return (
              <p>
                {`${row.billingAddress.firstName}  ${row.billingAddress.lastName}`}
              </p>
            );
          }
        }
      },
      transform: "",
    },
    {
      label: "Email",
      value: "",
      path: "email",
      transform: "",
    },
    {
      label: "Order",
      value: "",
      path: "render",
      render: (row) => (
        <p className={`${row.orderExportedToOms ? "text-success" : "text-danger"}`}>
          {row.orderExportedToOms ? "Exported" : row.status}
        </p>
      ),
      transform: "",
    },
    {
      label: "Email",
      value: "",
      path: "render",
      render: (row) => (
        <p className={`${row.orderConfirmationEmailSent ? "text-success" : "text-danger"}`}>
          {row.orderConfirmationEmailSent ? "Successful" : "Waiting"}
        </p>
      ),
      transform: "",
    },
    {
      label: "Amount",
      value: "",
      path: "priceInfo.grossPrice",
      transform: "",
    },
    {
      label: "SC",
      value: "",
      path: "salesCompany",
      transform: "",
    },
    {
      label: "Type",
      value: "",
      path: "catalogType",
      transform: "",
    },
    {
      label: "Discount",
      value: "",
      path: "render",
      transform: "",
      render: (row) => {
        if (row && row.discountPercentages) {
          return (
            <>
              <Row>
                {row.discountPercentages.FJ
                  ? (
                    <>
                      FJ :
                      {row.discountPercentages.FJ}
                    </>
                  ) : ""}
                {row.discountPercentages.FJ && row.discountPercentages.HW
                  ? (
                    <>
                      ,
                    </>
                  ) : ""}
                {row.discountPercentages.HW
                  ? (
                    <>
                      HW :
                      {row.discountPercentages.HW}
                    </>
                  ) : ""}
              </Row>
              <Row>
                {row.discountPercentages.PR
                  ? (
                    <>
                      PR :
                      {row.discountPercentages.PR}
                    </>
                  ) : ""}
                {row.discountPercentages.PR && row.discountPercentages.RR
                  ? (
                    <>
                      ,
                    </>
                  ) : ""}
                {row.discountPercentages.RR
                  ? (
                    <>
                      RR :
                      {row.discountPercentages.RR}
                    </>
                  ) : ""}
              </Row>
            </>
          );
        }
      },
    },
  ];
  const { t } = useTranslation(["common", "order-management"]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [ListDropdownenopen, SetListDropdownenOpen] = useState(false);
  const userRole = UserData.getRole();

  /**
      *This method is used to toogle dropdown
      *
      */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleListDropdown = () => {
    SetListDropdownenOpen(!ListDropdownenopen);
  };

  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <span className="pageText"><strong>{t("order-management:order_management_title")}</strong></span>
        </Col>
        <Col xs="12">
          <Breadcrumb className="mt-2">
            <BreadcrumbItem>
              <a href="/dashboard">
                <img src={Home} alt="Home" />
                {t("common:home")}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem><a href="/operations">{t("common:operations")}</a></BreadcrumbItem>
            <BreadcrumbItem><a href="/operations">{t("common:order management")}</a></BreadcrumbItem>
            <BreadcrumbItem active>{t("common:Orders")}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className={clsx(styles.block1, "common-page-card mb-0 pb-0")}>
        <Col md="12">
          <Row className="m-0 ">
            <Col md="12" lg="2" className="p-0 d-flex align-items-center">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("order-management:span_manage_orders")}
              </span>
            </Col>
            <Col md="12" lg="10" className="p-0">
              <Row>
                <Col md="12" lg="1">
                  <div className={styles.areaActionRight} />
                  <div className={` ${clsx(styles.searchContainer)}`}>
                    <span
                      onClick={() => onHandleClear()}
                      className="commonPointer"
                      title="Refresh"
                    >
                      <Button color="light">
                        <img
                          width="12"
                          src={RefreshIcon}
                          alt="search category"
                          className="mr-2"
                        />
                        <span>Reset</span>
                      </Button>
                    </span>
                  </div>
                </Col>
                <Col md="12" lg="3">
                  <div className="mx-2">
                    <FormGroup>
                      <Select
                        className="formText"
                        placeholder="sales company"
                        name={salesCompany}
                        id={salesCompany}
                        value={salesCompany}
                        onChange={(e) => handleChange("salesCompany", e.target.value)}
                        options={[
                          {
                            value: "", disabled: true, selected: true, label: "Select Sales Company",
                          },
                          ...(
                            Array.isArray(salesCompanyList) ? salesCompanyList.map((item) => ({ value: item, label: item })) : []
                          ),
                        ]}
                      />
                    </FormGroup>
                  </div>
                  <div className="mx-2">
                    <FormGroup>
                      <Select
                        className="formText"
                        placeholder="Order type"
                        name={catalogType}
                        id={catalogType}
                        value={catalogType}
                        onChange={(e) => handleChange("orderType", e.target.value)}
                        options={[
                          {
                            value: "", disabled: true, selected: true, label: "Select order type",
                          },
                          ...(
                            Array.isArray(orderType) ? orderType.map((item) => ({ value: item.value, label: item.label })) : []
                          ),
                        ]}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md="12" lg="3">
                  <div className="mx-2">
                    <FormGroup>
                      <Select
                        className="formText"
                        placeholder="Order status"
                        name="orderStatus"
                        id="orderStatus"
                        value={isOrderExportedToOms}
                        onChange={(e) => handleChange("orderExportStatus", e.target.value)}
                        options={[
                          {
                            value: "", disabled: true, selected: true, label: "Select order status",
                          },
                          ...(
                            Array.isArray(orderExportStatus) ? orderExportStatus.map((item) => ({ value: item.value, label: item.label })) : []
                          ),
                        ]}
                      />
                    </FormGroup>
                  </div>
                  <div className="mx-2">
                    <FormGroup>
                      <Select
                        className="formText"
                        placeholder="Email status"
                        name="emailStatus"
                        id="emailStatus"
                        value={isConfirmationMailSent}
                        onChange={(e) => handleChange("orderEmailConfirmation", e.target.value)}
                        options={[
                          {
                            value: "", disabled: true, selected: true, label: "Select email status",
                          },
                          ...(
                            Array.isArray(orderEmailConfirmation) ? orderEmailConfirmation.map((item) => ({ value: item.value, label: item.label })) : []
                          ),
                        ]}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md="12" lg="3">
                  <div className="mx-1">
                    <FormGroup>
                      <div className="custom-datepicker">
                        <Label className="formTextLabel" for="exampleEmail">
                          {/* {t("order-management:label_order_from_date")} */}
                          From:
                        </Label>
                        <DatePicker
                          id="fromDate"
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          closeOnSelect
                          input
                          name="fromDate"
                          onChange={(e) => setFromDate(e)}
                          isValidDate={(e) => isValidDate(e, "ORDER_DATE_FROM")}
                          inputProps={{
                            placeholder: t("common:placeholder_date_example"),
                          }}
                          value={fromDate}
                        />
                      </div>
                      <FormFeedback>
                        {t("order-management:label_invalid_order_date_msg")}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="mx-1">
                    <FormGroup>
                      <div className="custom-datepicker">
                        <Label className="formTextLabel" for="exampleEmail">
                          {/* {t("order-management:label_order_to_date")} */}
                          To:
                        </Label>
                        <DatePicker
                          id="toDate"
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          closeOnSelect
                          input
                          name="toDate"
                          onChange={(e) => setToDate(e)}
                          isValidDate={(e) => isValidDate(e, "ORDER_DATE_TO")}
                          inputProps={{
                            placeholder: t("common:placeholder_date_example"),
                          }}
                          value={toDate}
                        />
                      </div>
                      <FormFeedback>
                        {t("order-management:label_invalid_order_date_msg")}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </Col>
                <Col md="12" lg="2">
                  <div className="mx-2">
                    <InputGroup
                      className={clsx(styles.search)}
                    >
                      <Input
                        placeholder={t(
                          "order-management:placeholder_search_orders",
                        )}
                        onChange={handleSearchTerm}
                        value={searchTerm}
                      />
                      <InputGroupAddon
                        addonType="append"
                        className="commonPointer"
                        title="Search"
                      >
                        <img
                          src={SearchIcon}
                          alt="search category"
                          className={styles.icon}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                    {" "}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card pt-2")}>
        <Col md="12">
          <span>
            {`  Total Orders - ${totalNumberOfOrders}`}
          </span>
        </Col>
        <Col xs="12">
          <Row className={clsx(styles.block2, " pb-2  ")}>
            <Col xs="12">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className={clsx(styles.dropdownBtn, "commonDropDownWrapper")}
              >
                {isProccessing ? <span>{t("common:span_loading")}</span> : null}
                <DropdownToggle caret>
                  <span>{t("common:span_export_as")}</span>
                </DropdownToggle>
                <DropdownMenu right className="dropdownMenuWrapper">
                  <div
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className="dropdownMenuItem"
                    onClick={() => onHandleExport("XLSX")}
                  >
                    {t("common:div_export_format_XLSX")}
                  </div>
                  <div
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className="dropdownMenuItem"
                    onClick={() => onHandleExport("CSV")}
                  >
                    {t("common:div_item_export_format_CSV")}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="table-responsive mt-3">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={orders}
                  isStatusEnable={false}
                  isDeleteEnable={false}
                  isEditEnable={false}
                  isLoading={isProccessing}
                  onClickRow={onClickRow}
                  isActionEnable={userRole === "SUPER_ADMIN"}
                  onActionButtonClick={openRepostOrderModal}
                  isActionSubmitting={repostingOrder}
                  isActionEligibleForRow={isOrderEligibleForRepost}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col xs="auto" className="d-flex align-items-center">
              Show
              <Dropdown
                isOpen={ListDropdownenopen}
                toggle={toggleListDropdown}
                className="dropdown-custom dropdown-number mx-2"
              >
                <DropdownToggle caret color="light" size="sm">
                  {itemsPerPage}
                </DropdownToggle>
                <DropdownMenu down>
                  <DropdownItem
                    onClick={() => handleItemsPerPageChange(10)}
                    onKeyPress={() => { }}
                  >
                    10
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleItemsPerPageChange(15)}
                  >
                    15
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleItemsPerPageChange(20)}
                    onKeyPress={() => { }}
                  >
                    20
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              entries
            </Col>
            <Col xs="auto">
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                pageCount={totalPagesCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={onPageChange}
                containerClassName="pagination"
                pageClassName="page"
                pageLinkClassName="pageLink"
                activeClassName="activePage"
                initialPage={0}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal isOpen={isRepostOrderModalOpen} centered>
        <ModalHeader>
          Confirm repost order
        </ModalHeader>
        <ModalBody>
          <h6>
            {`${"Are you sure that you want to repost order No"} ${selectedOrderIdToRepost.orderNo}?`}
          </h6>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={repostingOrder}
            onClick={() => !repostingOrder && repostOrderToOms()}
          >
            {repostingOrder
              ? (
                <Spinner size="sm" animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : <>Confirm</>}
          </Button>
          <Button onClick={() => closeRepostOrderModal()}>
            <>Cancel</>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

OrderManagement.propTypes = {
  setToDate: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.any).isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPagesCount: PropTypes.number.isRequired,
  totalNumberOfOrders: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  onHandleExport: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  getPageData: PropTypes.func.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  isValidDate: PropTypes.bool.isRequired,
  isProccessing: PropTypes.bool.isRequired,
  isOrderExportedToOms: PropTypes.bool.isRequired,
  isConfirmationMailSent: PropTypes.bool.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  salesCompanyList: PropTypes.arrayOf(PropTypes.any).isRequired,
  salesCompany: PropTypes.string.isRequired,
  catalogType: PropTypes.string.isRequired,
  orderType: PropTypes.arrayOf(PropTypes.any).isRequired,
  orderEmailConfirmation: PropTypes.arrayOf(PropTypes.any).isRequired,
  orderExportStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default OrderManagement;
