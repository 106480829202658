/* eslint-disable max-len */
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Edit FulFillmentMethod
 *
 * @author Anagha
 *
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/EditfulfillmentMethod.module.css";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import validateForm from "../../../common/utils/validateForm";
import Form from "../../../common/core/Form";
import Spinner from "../../../common/core/Spinner";

const EditFulfillmentMethod = ({
  form,
  handleFormChange,
  onHandleSave,
  onHandleCancel,
  submitting,
  shippingMethodConfigurations,
  paymentMethodConfigurations,
  onPaymentStatusChange,
  onShipmentStatusChange,
  loading,
}) => {
  const { t } = useTranslation(["common", "fulfillment-method"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  const { register, errors } = validator;


  const getPaymentStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={form.applicablePaymentMethods.includes(row.paymentMethod)}
          onChange={(e) => onPaymentStatusChange(row.paymentMethod)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );

    return newStatus;
  };

  const getShipmentStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={form.defaultShippingMethod === row.shippingMethod}
          onChange={(e) => onShipmentStatusChange(row.id, form.defaultShippingMethod !== row.shippingMethod)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );

    return newStatus;
  };
  const customRowsShipment = [
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Shipment Method",
      value: "",
      path: "shippingMethod",
      transform: "",
    },
    {
      label: "Description",
      value: "",
      path: "description",
      transform: "",
    },
    {
      label: "Amount",
      value: "",
      path: "amount",
      transform: "",
    },
    {
      label: "Default",
      path: "render",
      render: getShipmentStatus,
    },
  ];
  const customRowspayment = [
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Description",
      value: "",
      path: "description",
      transform: "",
    },
    {
      label: "Payment Type",
      value: "",
      path: "paymentMethod",
      transform: "",
    },
    {
      label: "Credit Limit",
      value: "",
      path: "paymentLimit",
      transform: "",
      default: 0,
    },
    {
      label: "Status",
      path: "render",
      render: getPaymentStatus,
    },
  ];

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSave)}>
        <fieldset disabled={submitting}>
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7">
              <span className="pageText">{t("fulfillment-method:edit_fulfillment_method_title")}</span>
            </Col>
            {" "}
            <Col xs="12" md="5" className="d-flex justify-content-end">
              <Button
                type="button"
                color="secondary"
                className={clsx(
                  styles.borderRadius2,
                  styles.btn,
                  styles.secondaryColor,
                  "text-uppercase",
                  "text-truncate",
                  "mr-1",
                )}
                onClick={onHandleCancel}
              >
                {t("common:button_cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                className={clsx(
                  styles.borderRadius2,
                  styles.btn,
                  styles.primaryColor,
                  "text-uppercase",
                  "text-truncate",
                  "mr-1",
                  "button-font-size",
                )}
                onClick={onHandleSave}
              >
                {
                  loading ? (
                    <Spinner size="sm" />
                  ) : "SAVE"
                }
              </Button>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("common:label_name")}</Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="name"
                      value={form.name}
                      onChange={(e) => handleFormChange(e)}
                      innerRef={
                        register({
                          required: t("common:validation_name"),
                          minLength: {
                            value: 3,
                            message: t("common:validation_min_three-letter"),
                          },
                          maxLength: {
                            value: 252,
                            message: t("common:validation_too_large"),
                          },
                        })
                      }
                      invalid={!!(errors.name && errors.name.message)}
                    />
                    <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("common:label_description")}</Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="description"
                      value={form.description}
                      onChange={(e) => handleFormChange(e)}
                      innerRef={
                        register({
                          required: t("common:validation_description"),
                          minLength: {
                            value: 3,
                            message: t("common:validation_min_three-letter"),
                          },
                          maxLength: {
                            value: 252,
                            message: t("common:validation_too_large"),
                          },
                        })
                      }
                      invalid={!!(errors.description && errors.description.message)}
                    />
                    <FormFeedback>{errors.description && errors.description.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("fulfillment-method:label_display_message")}</Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="displayMessage"
                      value={form.displayMessage}
                      onChange={(e) => handleFormChange(e)}
                      innerRef={
                        register({
                          required: t("common:validation_displaymessage"),
                          minLength: {
                            value: 3,
                            message: t("common:validation_min_three-letter"),
                          },
                          maxLength: {
                            value: 252,
                            message: t("common:validation_too_large"),
                          },
                        })
                      }
                      invalid={!!(errors.displaymessage && errors.displaymessage.message)}
                    />
                    <FormFeedback>{errors.displaymessage && errors.displaymessage.message}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
            <Col xs="12">
              <Col xs="8" md="7" className="text-truncate">
                <span className="pageText">Shipment Methods Associated</span>
              </Col>
              <Col xs="12" />
              <Row>
                <Col xs="12" />
                <Col xs="12">
                  <div className="custom-table">
                    <CustomizationTable
                      customRows={customRowsShipment}
                      customizationData={shippingMethodConfigurations}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
            <Col xs="12">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">Payment Methods Associated</span>
              </Col>
              <Row>
                <Col xs="12">
                  <div className="custom-table">
                    <CustomizationTable
                      customRows={customRowspayment}
                      customizationData={paymentMethodConfigurations}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  );
};
EditFulfillmentMethod.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onHandleSave: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
};

export default EditFulfillmentMethod;
