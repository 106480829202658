/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
/** ========= API SERVICE FUNCTIONS ========= */
import { generateSiteMap, getSiteMap } from "../../../api/SeoSettingsService";
import { generateSitemapLive } from "../../../api/sitePreviewConfigurationsServices";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Spinner from "../../../common/core/Spinner";

const SiteMap = (props) => {
  const { indexingMode, previewEnabled } = props;

  // local states
  const [siteMaps, setSiteMaps] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSiteMap().then((response) => {
      setLoading(false);
      if (response && response.success) {
        if (Array.isArray(response.data)) {
          setSiteMaps(response.data);
        }
      }
    });
  }, []);

  /**
   * This function is used to generate / update site map
   */
  const updateSiteMap = async () => {
    if (loading) return;
    setLoading(true);
    const response = await generateSiteMap();
    setLoading(false);
    if (response && response.success) {
      if (Array.isArray(response.data)) {
        setSiteMaps(response.data);
      }
    }
  };
  const updateSiteMapLive = async () => {
    if (loading) return;
    setLoading(true);
    const response = await generateSitemapLive();
    setLoading(false);
    if (response && response.success) {
      if (Array.isArray(response.data)) {
        setSiteMaps(response.data);
      }
    }
  };


  const updateSiteMapMethod = previewEnabled && indexingMode === "LIVE" ? updateSiteMapLive : updateSiteMap;
  return (
    <Row>
      <Col xs="12" className="mb-3 d-flex algin-items-center">
        <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Your XML Site Map URL</span>
        <span className="ml-auto col-xs-4 col-md-3 col-lg-2 p-0">
          {
            siteMaps.length > 0 ? (
              <Button size="sm" color="warning" onClick={updateSiteMapMethod} block className="text-white">
                {
                  loading ? (
                    <Spinner size="sm" />
                  ) : "Update"
                }
              </Button>
            ) : (
                <Button size="sm" color="primary" onClick={updateSiteMapMethod} block className="text-white">
                  {
                    loading ? (
                      <Spinner size="sm" />
                    ) : "Generate"
                  }
                </Button>
              )
          }
        </span>
      </Col>
      {
        siteMaps.map((siteMap, index) => (
          <Col xs="12" className="d-flex align-items-center mb-1">
            <div>
              <span className="p-1 mr-1">
                {(index + 1)}
              </span>
            </div>
            <div className="overflow-hidden" title={siteMap}>
              <p className="m-0 text-truncate pr-1 ">
                {siteMap}
              </p>
            </div>
            <div className="ml-auto">
              <a href={siteMap} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-info text-white">
                Download
              </a>
            </div>
          </Col>
        ))
      }
    </Row>
  );
};

SiteMap.propTypes = {
  indexingMode: PropTypes.string.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
};

export default SiteMap;
