/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Harikrishnan A K
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/b2bCustomer.module.css";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Label from "../../../common/core/Label";
import CustomizationTable from "../../../common/components/Table";
import Alert from "../../../common/core/Alert";
import Spinner from "../../../common/core/Spinner";
/** ===== IMAGE ICONS =========== */
import EyeDark from "../../../common/assets/images/svg/eye-dark.svg";

const ViewCustomer = ({
  onHandleCancel,
  customer,
  subAccounts,
  handleB2BDepartmentEnableStatus,
  viewDepartment,
  message,
  isLoading,
}) => {
  const { t } = useTranslation(["common", "b2bcustomer"]);
  const [isSubAccountOpen, SetisSubAccountOpen] = useState(true);

  const subAccountCustomRows = [
    {
      label: "ID",
      value: "",
      path: "uniqueId",
      transform: "",
    },
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Email",
      value: "",
      path: "email",
      transform: "",
    },
    {
      label: "Enable",
      value: "",
      path: "render",
      render: (row) => (
        <>
          <div
            className={clsx(
              "custom-control",
              "custom-switch",
              "ml-1",
            )}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`B2BSubAccountStatus${row.id}`}
              name="enableB2BDepartment"
              checked={row.active}
              onChange={(o) => { handleB2BDepartmentEnableStatus(row, row.id, !row.active, o); }}
            />
            <Label className="custom-control-label" htmlFor={`B2BSubAccountStatus${row.id}`} />
          </div>
        </>
      ),
    },
    {
      label: "Actions",
      value: "",
      path: "render",
      render: (row) => (
        <div className="d-flex justify-content-start">
          <span
            onClick={(o) => viewDepartment(row.id, o)}
            onKeyDown={() => { }}
            tabIndex={0}
            role="button"
            className="commonPointer"
          >
            <Button color="transparent" className="btn-square">
              <img src={EyeDark} alt="Home" />
            </Button>
          </span>
        </div>
      ),
    },
  ];
  const togglesubaccount = () => SetisSubAccountOpen(!isSubAccountOpen);

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Form>
        <fieldset>
          <Row className="common-page-card pageHeader">
            <Col xs="12">
              <span className="pageText">{t("b2bcustomer:label_customer_title")}</span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "common-page-card ")}>
            <Col xs="12">
              <Row className="m-0 ">
                <Col xs="6" className="p-0">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    {t("b2bcustomer:label_customer_details")}

                  </span>
                </Col>
                <Col className="mb-2" xs="6" sm>
                  <div className={styles.areaActionRight}>
                    <div>
                      <Button
                        color="secondary"
                        className={clsx(
                          styles.borderRadius2,
                          styles.btn,
                          styles.secondaryColor,
                          "text-uppercase",
                          "text-truncate",
                          "btn-tertiary",
                          "ml-1",
                        )}
                        onClick={onHandleCancel}
                      >
                        {t("b2bcustomer:back")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              {isLoading || !customer ? (
                <Row className="h-100">
                  <Col
                    xs="12"
                    className={styles.spinnerWrapper}
                  >
                    <div
                      className="h-100  d-flex align-items-center justify-content-center"
                    >
                      <Spinner />
                    </div>
                  </Col>
                </Row>
              )
                : (
                  <>
                    <div className={styles.displayData}>
                      <ul className={styles.dataTableList}>
                        <li>
                          <div className={styles.dataTableTitle}>ID:</div>
                          <div className={styles.dataTableText}>
                            {" "}
                            {customer.uniqueId}
                          </div>
                        </li>
                        <li>
                          <div className={styles.dataTableTitle}>Invoice Account:</div>
                          <div className={styles.dataTableText}>
                            {" "}
                            {customer.invoiceAccount}
                          </div>
                        </li>
                        <li>
                          <div className={styles.dataTableTitle}>
                            Name:
                          </div>
                          <div className={styles.dataTableText}>
                            {customer.name}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
            </Col>
          </Row>
          <Row className={clsx(styles.block3, "pageHeader common-page-card")}>
            <Col xs="12">
              <h5>{`Accounts${subAccounts && subAccounts.length > 0 ? ` (${subAccounts.length})` : ""}`}</h5>
              <span className="float-right">
                <span onClick={togglesubaccount} role="presentation">
                  {isSubAccountOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                    </svg>
                  )
                    : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      </svg>
                    )}
                </span>
              </span>
              {isSubAccountOpen && customer && (
                <div className={(styles.dataTableTitle, "mt-3")}>
                  <div className="custom-table">
                    <CustomizationTable
                      customRows={subAccountCustomRows}
                      customizationData={subAccounts}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  );
};
ViewCustomer.propTypes = {
  onHandleCancel: PropTypes.func.isRequired,
  handleB2BDepartmentEnableStatus: PropTypes.func.isRequired,
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  subAccounts: PropTypes.objectOf(PropTypes.any).isRequired,
  viewDepartment: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ViewCustomer;
