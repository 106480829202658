/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * Validation constants
 *
 * @author Naseef O
 *
 */

const constants = {
  DATE_FORMAT: "DD/MM/YYYY",
  IS_NUMBER: /^[1-9][0-9]*([.][0-9]{2}|)$/,
  IS_EMAIL: /\S+@\S+\.\S+/,
  IS_INTEGER: /^[1-9][0-9]*$/,
  IS_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,100}$/,
  IS_ADMIN_RESET_PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W).{8,}$/,
  IS_NUMBERS: /^[0-9][0-9]*([.][0-9]{2}|)$/,
};

export default constants;
