/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Inventory Management Container
 *
 * @author Amjad Rehman A
 *
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
/** ===== API SERVICE FUNCTIONS =========== */
import PriceBookManagement from "../components/PriceBookManagement";
import { getCatalogConfiguration } from "../../../api/productManagementServices";
import {
  getPriceBookList,
  getPriceTableData,
  transformPriceBookData,
} from "../../../api/priceBookManagementService";
import {
  getSitePreviewConfigurations,
  getRunningJobs,
  replicatePrice,
  getPriceReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const moment = require("moment");

const PriceBookManagementContainer = () => {
  const [priceBookList, setPriceBookList] = useState([]);
  const [priceTableData, setPriceTableData] = useState([]);
  const [date, setDate] = useState("");
  const [priceBookId, setPriceBookId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isProccessing, setIsProccessing] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [salesCompanyId, setSalesCompanyId] = useState("");
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [salesCompanies, setSalesCompanies] = useState([{
    label: "Select Sales Company",
    disabled: salesCompanyId,
    value: "",
  }]);

  const paginationLimit = 10;

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const getReplicationStatus = async () => {
    getPriceReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last price push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch(() => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };
  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  // Format Date to required format
  const formatDate = (unFormattedDate) => (unFormattedDate
    ? moment(unFormattedDate).format("YYYY-MM-DD HH:mm:ss")
    : null);
  const addZero = (val) => (val < 10 ? `0${val}` : val);

  // API call to fetch pricetable entries based on the filter
  const getPricingListFromServer = (
    searchWord, newPage = 1, id, searchDate, selectedSalesCompany,
  ) => {
    setIsLoading(true);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    let localSearchWord = searchTerm ? searchTerm.trim() : "";
    if (searchWord !== null) {
      localSearchWord = searchWord.trim();
    }
    const searchData = {
      priceBookId: id || priceBookId,
      newDate: formatDate(searchDate) || formatDate(date) || formatDate(new Date()),
      timeZone,
      salesCompany: selectedSalesCompany,
      searchTerm: localSearchWord,
    };
    if (searchData.priceBookId && searchData.newDate) {
      getPriceTableData(searchData, newPage, paginationLimit).then(
        (response) => {
          if (response && response.success === true) {
            const { data } = response;
            if (Array.isArray(data) && data.length > 0) {
              setPriceTableData(data);
              setHasMoreData(true);
            } else {
              setPriceTableData([]);
              setHasMoreData(false);
            }
            setPage(newPage);
          } else {
            setPriceTableData([]);
            setHasMoreData(false);
          }
          setIsLoading(false);
        },
      );
    }
  };

  useEffect(() => {
    getCatalogConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && Array.isArray(data.salesCompanies)) {
          const availableSalesCompanies = data.salesCompanies.map(
            (salesCompany) => ({ label: salesCompany, value: salesCompany }),
          );
          setSalesCompanies([...salesCompanies, ...availableSalesCompanies]);
        }
      }
    });
  }, []);

  const listPriceBooks = (selectedSalesCompanyId) => {
    getPriceBookList(selectedSalesCompanyId).then(async (response) => {
      if (response && Array.isArray(response)) {
        setPriceBookList(response);
        setPriceBookId(response[0].value);
        getPricingListFromServer("", 1, response[0].value, null, selectedSalesCompanyId);
      }
    });
  };

  // Handles the changes of each filter field.
  const handleChange = (key, value) => {
    if (key === "searchTerm") {
      setSearchTerm(value);
      getPricingListFromServer(value, 1, null, null, salesCompanyId);
    }
    if (key === "date") {
      setDate(value);
      getPricingListFromServer(null, 1, null, value, salesCompanyId);
    }
    if (key === "priceBookId") {
      setPriceBookId(value);
      getPricingListFromServer(null, 1, value, null, salesCompanyId);
    }
    if (key === "salesCompany") {
      setSearchTerm("");
      setSalesCompanyId(value);
      listPriceBooks(value);
    }
    if (key === "page") {
      getPricingListFromServer(null, value, null, null, salesCompanyId);
    }
  };

  const initializeData = () => {
    setSearchTerm("");
    setDate(null);
    setPage(1);
    setSalesCompanyId("");
    setPriceBookId("");
    setHasMoreData(true);
  };

  /**
         * Clears the data and filters
         */
  const onHandleClear = () => {
    initializeData();
    setPriceTableData([]);
  };

  const onHandleExport = async (type) => {
    setIsProccessing(true);
    const currentDateTime = new Date();
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const searchData = {
      priceBookId,
      newDate: formatDate(date) || formatDate(new Date()),
      timeZone,
      salesCompanyId,
      searchTerm: searchTerm ? searchTerm.trim() : "",
      salesCompany: salesCompanyId,
    };
    getPriceTableData(searchData, page, paginationLimit)
      .then(async (response) => {
        if (response && response.success === true) {
          const { data } = response;
          if (Array.isArray(data)) {
            const priceBooks = await transformPriceBookData(data);
            const year = currentDateTime.getFullYear();
            const month = addZero(currentDateTime.getMonth() + 1);
            const currentDate = currentDateTime.getDate();
            const hours = currentDateTime.getHours();
            const mins = addZero(currentDateTime.getMinutes());
            const secs = currentDateTime.getSeconds();
            let fileName = `PriceBooks_${year}${month}${currentDate}${hours}${mins}${secs}.xlsx`;
            if (type === "CSV") {
              fileName = `PriceBooks_${year}${month}${currentDate}${hours}${mins}${secs}.csv`;
            }
            const ws = XLSX.utils.json_to_sheet(priceBooks);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "PriceBooks");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 15 },
              { wch: 15 },
              { wch: 15 },
              { wch: 10 },
              { wch: 10 },
              { wch: 30 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch(() => {
        setIsProccessing(false);
      });
  };

  const handlePushToLive = async () => {
    const runningJobsResponse = await getRunningJobs();
    if (runningJobsResponse) {
      const { data: runningJobs } = runningJobsResponse;
      if (!runningJobs || runningJobs.length === 0) {
        setPushToLiveButtonEnabled(false);
        setShowPushToLiveAlert(true);
        replicatePrice().then((response) => {
          if (response && response.success) {
            const alertData = {
              type: "success",
              message:
                "Prices pushed to live successfully. Please refresh the search indexes through Administration > Search Index Management, there may be a small delay for reflecting the same in live.",
            };
            setAlert(alertData);
          } else {
            const alertData = {
              type: "danger",
              message: "Something went wrong. Push to live failed",
            };
            setAlert(alertData);
          }
        });
      } else {
        const alertData = {
          type: "warning",
          message:
            "An Automated batch job is running in background, Please wait and try again later to make your changes live.",
        };
        setAlert(alertData);
      }
    }
    return null;
  };


  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRCBK_VIEW_LIST}
    >
      <PriceBookManagement
        priceBookList={priceBookList}
        priceTableData={priceTableData}
        date={date}
        page={page}
        hasMoreData={hasMoreData}
        searchTerm={searchTerm}
        onHandleClear={onHandleClear}
        priceBookId={priceBookId}
        handleChange={handleChange}
        onHandleExport={onHandleExport}
        isProccessing={isProccessing}
        pushToLiveEnabled={pushToLiveEnabled}
        previewSiteUrl={previewSiteUrl}
        handlePushToLive={handlePushToLive}
        previewEnabled={previewEnabled}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
        message={message}
        isLoading={isLoading}
        salesCompany={salesCompanies}
        salesCompanyId={salesCompanyId}
      />
    </Permission>
  );
};

export default PriceBookManagementContainer;
