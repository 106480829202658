/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Layout Settings Container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import LayoutSettings from "../components/LayoutSettings";
import { getPageLayouts } from "../../../api/LayoutServices";
import {
  getSitePreviewConfigurations, replicateContent, getContentReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const LayoutSettingsContainer = () => {
  const [layouts, setLayouts] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [pageType, setPageType] = useState("HOME");
  const domainName = "";
  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);

  const handleSelectPage = (type) => {
    setPageType(type);
    toggleDropdown();
  };


  useEffect(() => {
    getPageLayouts(pageType).then((response) => {
      if (response && response.success) {
        setLayouts(response.data);
      }
    });
  }, [pageType]);

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    replicateContent().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Page contents pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    }).catch((error) => {
      const alertData = {
        type: "danger",
        message: "Something went wrong. Push to live failed",
      };
      setAlert(alertData);
    });
  };

  const getReplicationStatus = async () => {
    getContentReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last content push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CONTNT_LAYT_SETNG_VIEW_LIST}
    >
      <LayoutSettings
        layouts={layouts}
        toggleDropdown={toggleDropdown}
        dropdownOpen={dropdownOpen}
        handleSelectPage={handleSelectPage}
        pageType={pageType}
        domainName={domainName}
        previewSiteUrl={previewSiteUrl}
        handlePushToLive={handlePushToLive}
        previewEnabled={previewEnabled}
        pushToLiveEnabled={pushToLiveEnabled}
        message={message}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
      />
    </Permission>
  );
};

export default LayoutSettingsContainer;
