/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";

/** ========== SUB COMPONENT ================ */
import ProductForm from "./ProductForm";

/** ===== IMAGE ICONS =========== */
/** ======== MODULE STYLES ========== */
import styles from "../css/AddProduct.module.css";
import validateForm from "../../../common/utils/validateForm";
import Spinner from "../../../common/core/Spinner";

const AddProduct = (props) => {
  const {
    categories,
    productType,
    name,
    enableProduct,
    skuName,
    listPrice,
    salePrice,
    quantity,
    onSale,
    images,
    attributes,
    handleChange,
    handleFormChange,
    onSubmit,
    onSubmitting,
    handleRedirect,
    loading,
  } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);
  const methods = validateForm();

  const {
    handleSubmit, register, errors,
  } = methods;

  return (
    <Row className="">
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
          <fieldset disabled={onSubmitting}>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  {t("product:title_add")}
                </span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={handleRedirect} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {
                    loading ? (
                      <Spinner size="sm" />
                    ) : "Save"
                  }


                </Button>
              </Col>
            </Row>
            {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}

            <div className="common-page-card">
              {/*  ====== PRODUCT FORM E[0005]============ */}
              <ProductForm
                /** form data */
                name={name}
                enableProduct={enableProduct}
                skuName={skuName}
                listPrice={listPrice}
                salePrice={salePrice}
                quantity={quantity}
                onSale={onSale}
                images={images}
                productType={productType}
                categories={categories}
                attributes={attributes}
                register={register}
                errors={errors}
                /** events */
                handleFormChange={handleFormChange}
                handleChange={handleChange}
              />
            </div>
          </fieldset>
          {/*  ====== SET PATENT PRODUCTS E[0004]============ */}
        </Form>
        {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
      </Col>
    </Row>
  );
};

AddProduct.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  enableProduct: PropTypes.bool.isRequired,
  skuName: PropTypes.string.isRequired,
  listPrice: PropTypes.string.isRequired,
  salePrice: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  onSale: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  productType: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  /** events */
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  /** form event */
  onSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
};

export default AddProduct;
