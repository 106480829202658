/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import Coupons from "../components/Coupons";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { getCoupons } from "../../../api/campaignServices";

/** ============ SVG IMAGE ICON ===================== */
/** ========= MODULE STYLES ========= */

const CouponsContainer = (props) => {
  const {
    campaignId,
    onChange, selectedCouponDetails,
  } = props;

  /** local states */
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [couponList, setCouponList] = useState([]);
  /**
   * This function is used to expand collapse
   * @param {Boolean} status
   */
  const toggleBody = (status) => {
    setOpen(status);
  };


  useEffect(() => {
    if (Array.isArray(selectedCouponDetails) && selectedCouponDetails.length > 0) {
      const listedCouponId = couponList.map(({ id }) => id);
      const filteredList = selectedCouponDetails.filter((each) => each && !listedCouponId.includes(each.id));
      setCouponList([
        ...couponList,
        ...filteredList,
      ]);
      setSelectedCoupons(selectedCouponDetails.map(({ id }) => id));
    }
  }, [selectedCouponDetails]);


  /**
   * This function is used to change tab
   * @param {Number} tabIndex
   */
  const changeActiveTab = (tabIndex) => {
    setActiveTab(`${tabIndex}`);
  };

  /**
   * This method is sued to get coupon list from server
   * @param {String} searchTerm
   */
  const getCouponListFromSever = (searchTerm = "") => {
    getCoupons(searchTerm, campaignId).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          const listedCouponId = couponList.map(({ id }) => id);
          setCoupons(data);
          setCouponList([
            ...(data.filter((each) => each && !listedCouponId.includes(each.id))),
            ...couponList,
          ]);
        }
      }
    });
  };

  /**
   * This method is used to change search filed and trigger search functionality
   * @param {Event} event
   */
  const handleSearchFiled = (event) => {
    const { value } = event.target;
    if (value && `${value}`.trim().length > 1) {
      getCouponListFromSever(value);
    } else {
      setCoupons([]);
    }
    setSearchKeyword(value);
  };

  /**
   * This method is used to update selected data in server
   * @param {Array} newSelectedList
   * @param {Array} sourceData
   */
  const updateInParent = (newSelectedList, sourceData) => {
    if (onChange && Array.isArray(sourceData)) {
      const fullObjectList = sourceData.filter((coupon) => newSelectedList.includes(coupon.id));
      onChange(fullObjectList || []);
    }
  };

  /**
   * This method is used to set select / deselect coupon
   * @param {String} couponId
   */
  const setCouponSelected = (couponId) => {
    const index = selectedCoupons.indexOf(couponId);
    let newSelectedList = [];
    if (index < 0) {
      /** if item not exist adding in the list */
      newSelectedList = ([...selectedCoupons, couponId]);
    } else {
      /** if item exits remove form the list */
      newSelectedList = (selectedCoupons.filter((o) => o !== couponId));
    }
    setSelectedCoupons(newSelectedList);
    updateInParent(newSelectedList, couponList);
  };

  /**
   * This function is used to clear search results
   */
  const clearSearchResults = () => {
    setCoupons([]);
    setSearchKeyword("");
  };

  /**
   * This method is used to get new customer group data
   * @param {Object} newCoupon
   */
  const handleNewCoupon = (newCoupon) => {
    if (newCoupon && newCoupon.id) {
      const sourceData = [...couponList, newCoupon];
      setCouponList(sourceData);
      const newSelectedList = ([...selectedCoupons, newCoupon.id]);
      setSelectedCoupons(newSelectedList);
      updateInParent(newSelectedList, sourceData);
      setActiveTab("1");
    }
  };

  return (
    <Coupons
      open={open}
      activeTab={activeTab}
      searchKeyword={searchKeyword}
      toggleBody={toggleBody}
      changeActiveTab={changeActiveTab}
      handleSearchFiled={handleSearchFiled}
      // coupons
      couponList={couponList}
      coupons={coupons}
      selectedCoupons={selectedCoupons}
      setCouponSelected={setCouponSelected}
      clearSearchResults={clearSearchResults}
      handleNewCoupon={handleNewCoupon}
    />
  );
};

CouponsContainer.defaultProps = {
  selectedCouponDetails: [],
};

CouponsContainer.propTypes = {
  selectedCouponDetails: PropTypes.arrayOf(PropTypes.object),
  // functions
  onChange: PropTypes.func.isRequired,
};

export default CouponsContainer;
