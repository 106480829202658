/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import React, {
  useState, useRef, useEffect,
} from "react";
/** =========== TAG COMPONENTS =========== */
import Label from "../../../common/core/Label";
import Button from "../../../common/core/Button";
/** =========== IMPORT API FUNCTIONS =========== */
import { getCategoriesByParenCategoryId, setStatus } from "../../../api/categoryManagementServices";

/** ============ SVG IMAGE ICON ===================== */
import PlusIcon from "../../../common/assets/images/svg/add-24px.svg";
import MinusIcon from "../../../common/assets/images/svg/minus-24px.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import EyeDark from "../../../common/assets/images/svg/eye-dark.svg";
/** ================ MODULE STYLES ================== */
import styles from "../css/Category.module.css";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";


/** =========== RECURSIVE FUNCTIONAL COMPONENT =========== */
const Category = ({
  index,
  id,
  getLocalKey /** id sued for html id attribute */,
  /** category details */
  name,
  type,
  active,
  startDate,
  endDate,
  childCategories,
  childProducts,
  brandId,
  locations,
  setDeleteCategory,
  checkParentHasChildCategory,
}) => {
  const history = useHistory();
  const currentRow = useRef(null);

  /** local states */
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [categoryList, setCategoryList] = useState(null);

  /** setting details into local state for local changes  */
  const [details, setDetails] = useState({
    id, name, type, active, startDate, endDate, locations,
  });

  const localKey = getLocalKey();

  /** defines has sub categories  */
  const [hasCategory, setHasCategory] = useState(
    Boolean(Array.isArray(childCategories) && childCategories.length > 0 && active),
  );

  /**
   * This method is used to change local state active status
   * @param {Boolean} active
   */
  const changeStatus = (activeStatus) => {
    setDetails({ ...details, active: activeStatus });
  };

  /**
   * This method is used to set category status
   * @param {Boolean} status
   * @param {Event} event
   */
  const changeCategoryStatus = async (status, categoryId) => {
    if (submitting) return;
    setSubmitting(true); /** on updating  */
    const response = await setStatus(categoryId, status);
    if (response && response.success === true) {
      changeStatus(status);
    }
    setSubmitting(false);
  };

  /**
   * This method is used to get category list by parent Category id
   * @param {String} id
   */
  const getCategoryByParenCategory = async (categoryId) => {
    const response = await getCategoriesByParenCategoryId(categoryId);
    if (response && response.success === true) {
      const { data } = response;
      try {
        if (Array.isArray(data) && data.length > 0) {
          setHasCategory(details.active);
          setCategoryList(data);
        } else {
          setHasCategory(false);
        }
      } catch (error) { }
    }
  };

  useEffect(() => {
    if (details.active) {
      getCategoryByParenCategory(id);
    }
  }, [details.active, id]);

  /**
 * This method is sued to toggle the catalogue collapse
 * call sub category list when its open
 * @param {String} categoryId
 */
  const toggle = (categoryId) => {
    if (hasCategory) {
      setIsOpen(!isOpen);
      if (!isOpen) { getCategoryByParenCategory(categoryId); }
    }
  };

  /**
   * Used to check parent has child category or not
   */
  const checkHasChildCategory = () => {
    if (id) {
      getCategoryByParenCategory(id);
    }
  };

  /**
   * This method used to position sub category - left
   * @param {Number} limit
   */
  const getDivForPositioning = (limit) => {
    const positionComponents = [];
    for (let o = 0; o < limit; o += 1) { positionComponents.push(<span />); }
    return positionComponents;
  };

  /**
   * This method is used to load edit page
   * @param {String} categoryId
   */
  const editCategory = (categoryId) => {
    history.push(`/merchandising/category/edit/${categoryId}`);
  };

  const viewCategory = (categoryId) => {
    history.push({ pathname: `/merchandising/category/view/${categoryId}`, state: { brandId } });
  };


  /**
   * This method is a callback , trigger when a category is deleted;
   */
  const deleteCategoryCallback = () => {
    if (checkParentHasChildCategory) { checkParentHasChildCategory(); }
  };

  /**
   * This method is used to delete a category
   * @param {String} categoryId
   */
  const deleteCategory = (categoryId) => {
    if (currentRow && currentRow.current) { setDeleteCategory({ categoryId, element: currentRow.current, callback: deleteCategoryCallback }); }
  };

  return (
    <>
      <tr key={id} className={styles.categoryTreeItem} ref={currentRow}>
        {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
        <td className="text-truncate">
          {id}
        </td>
        <td className={clsx(styles.tableTd, "py-0", "align-items-stretch")}>
          <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
            {getDivForPositioning(index)}
            <div className={clsx(styles.itemTitle, "bg-white", "d-flex", (!hasCategory || !details.active) && styles.noDescendants, isOpen ? styles.isOpen : styles.isClosed)}>
              {hasCategory && details.active
                && (
                  <span className="commonPointer" onClick={(o) => toggle(id, o)} onKeyDown={() => { }} tabIndex={0} role="button">
                    <img
                      className={styles.icon}
                      src={isOpen ? MinusIcon : PlusIcon}
                      alt=""
                    />
                  </span>
                )}
              <span className={clsx(styles.fontWeightBold, "px-1", "text-truncate")}>{details.name}</span>
            </div>
          </div>
        </td>
        <td className="text-truncate">{details.type}</td>
        <td className="text-truncate">{brandId}</td>
        <td className="text-truncate">{details.locations}</td>
        <td className="text-truncate">
          {moment(details.startDate).format("DD-MMM-YY")}
        </td>
        <td className="text-truncate">
          {moment(details.endDate).format("DD-MMM-YY")}
        </td>
        <td>{details.active ? "True" : "False"}</td>
        {/* <Permission
          allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_CTGRY_UPD_CTGRY}
        >
          <td align="center">
            <div
              className={clsx(
                "custom-control",
                "custom-switch",
                submitting ? "loading" : "",
              )}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={`expand-multiple${localKey}`}
                checked={details.active}
                onChange={(o) => changeCategoryStatus(!details.active, id, o)}
                disabled={isOpen}
              />
              <Label className="custom-control-label" htmlFor={`expand-multiple${localKey}`} />
            </div>
          </td>
        </Permission>
        <Permission
          allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_CTGRY_UPD_CTGRY
            || Rules.Control.MOZCOM_RA_MERCH_CTLG_CTGRY_DLT_CTGRY}
        >
          <td align="center">
            <div className={clsx("d-flex", "justify-content-center", styles.actions)}>
              <Permission
                allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_CTGRY_UPD_CTGRY}
              >
                <span disabled={!details.active} className="commonPointer mr-2" onClick={(o) => editCategory(details.id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                  <img
                    className={styles.icon}
                    src={EditIcon}
                    alt="Edit"
                  />
                </span>
              </Permission>
              <Permission
                allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_CTGRY_DLT_CTGRY}
              >
                <span disabled={hasCategory || (childProducts && childProducts.length > 0)} className="commonPointer" onClick={(o) => deleteCategory(details.id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                  <img
                    className={styles.icon}
                    src={DeleteIcon}
                    alt="Delete"
                  />
                </span>
              </Permission>
            </div>
          </td>
        </Permission> */}
        <td>
          <Button
            color="transparent"
            className="btn-square"
            onClick={(o) => viewCategory(id, o)}
            onKeyDown={() => { }}
          >
            <img src={EyeDark} alt="View" />
          </Button>
        </td>

        {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
      </tr>
      {/* ========= SUB CATEGORIES S[0004] ======================= */}
      {hasCategory && isOpen && (
        Array.isArray(categoryList) ? (
          <>
            {
              categoryList.length > 0 ? (
                <>
                  {categoryList.map((category) => (
                    <Category
                      index={index + 1}
                      key={category.id}
                      id={category.id}
                      getLocalKey={getLocalKey}
                      name={category.name}
                      type={category.type}
                      active={category.active}
                      startDate={category.startDate}
                      endDate={category.endDate}
                      childCategories={category.childCategories}
                      childProducts={category.childProducts}
                      setDeleteCategory={setDeleteCategory}
                      checkParentHasChildCategory={checkHasChildCategory}
                      brandId={brandId}
                    />
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="7" className="p-0">
                    <div className="text-center pt-1 pb-3">
                      <span>No Data</span>
                    </div>
                  </td>
                </tr>
              )
            }
          </>
        ) : (
          <tr className={styles.categoryTreeItem}>
            <td className={clsx(styles.tableTd, "py-0", "align-items-stretch")}>
              <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
                {getDivForPositioning(index)}
              </div>
            </td>
            <td colSpan="6" className="p-0">
              <div className="text-center pt-1 pb-3">
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </tr>

        )
      )}
      {/* ========= SUB CATEGORIES E[0004] ======================= */}
    </>
  );
};

Category.propTypes = {
  checkParentHasChildCategory: null,
};

Category.propTypes = {
  index: PropTypes.number.isRequired,
  getLocalKey: PropTypes.func.isRequired,
  /* actual category details */
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  setDeleteCategory: PropTypes.func.isRequired,
  /** sub category details */
  childCategories: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any)]).isRequired,
  childProducts: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any)]).isRequired,
};

/** ===========  MAIN  FUNCTIONAL COMPONENT =========== */
const CategoryComponent = ({
  getLocalKey,
  /* actual category details */
  id,
  name,
  type,
  active,
  startDate,
  endDate,
  /** corresponding category list */
  childCategories,
  childProducts,
  brandId,
  locations,
  setDeleteCategory,
}) => (
  /** =========== USING RECURSIVE FUNCTIONAL COMPONENT =========== */
  <Category
    index={0}
    id={id}
    getLocalKey={getLocalKey}
    name={name}
    type={type}
    active={active}
    startDate={startDate}
    endDate={endDate}
    childCategories={childCategories}
    childProducts={childProducts}
    brandId={brandId}
    locations={locations}
    setDeleteCategory={setDeleteCategory}
  />
);

CategoryComponent.propTypes = {
  getLocalKey: PropTypes.func.isRequired,
  /* actual category details */
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  setDeleteCategory: PropTypes.func.isRequired,
  /** sub category details */
  childCategories: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any)]).isRequired,
  childProducts: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any)]).isRequired,
};

export default CategoryComponent;
