/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * FacetCustomization
 *
 * @author Harikrishann A K
 *
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import DragAndDropTableContainer from "../containers/DragAndDropTableContainer";
import styles from "../css/FacetCustomization.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Select from "../../../common/components/Select";

const FacetCustomization = (props) => {
  const {
    code,
    brands,
    brandId,
    handleChange, facetCustomizationRows,
    facetObject, handleActiveStatusChange, handleRedirectToFacetEditor, onDragEnd,
  } = props;


  const { t } = useTranslation(["common", "facet-customization"]);

  return (
    < >
      <Row className=" common-page-card pageHeader">
        <Col xs="12" md="4">
          <span className="pageText">
            {" "}
            {t("facet-customization:title_facet_customization")}
          </span>
        </Col>
      </Row>
      <Row className="pageHeader common-page-card">
        <Col xs="12">
          <Row className="m-0">
            <Col className="mb-2" xs="12" sm="12" md="9">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("facet-customization:title_manage_facet_customization")}
              </span>
            </Col>
            <Col className="mb-2" xs="12" sm>
              <label htmlFor="exampleEmail" className="formTextLabel">Brand</label>
              <div className={styles.areaActionRight}>
                <FormGroup>
                  <Select
                    className="formTextLabel"
                    placeholder="Sales Company"
                    name="salesCompany"
                    onChange={(e) => handleChange(e)}
                    value={brandId}
                    options={brands}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card justify-content-end")}>
        <Col xs="12">
          <DragAndDropTableContainer
            code={code}
            brands={brands}
            brandId={brandId}
            handleChange={handleChange}
            facetCustomizationRows={facetCustomizationRows}
            facetObject={facetObject}
            handleActiveStatusChange={handleActiveStatusChange}
            handleRedirectToFacetEditor={handleRedirectToFacetEditor}
            onDragEnd={onDragEnd}
          />
        </Col>
      </Row>
    </>
  );
};

FacetCustomization.propTypes = {
  code: PropTypes.string.isRequired,
  handleRedirectToFacetEditor: PropTypes.func.isRequired,
  handleActiveStatusChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  facetObject: PropTypes.func.isRequired,
  facetCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandId: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FacetCustomization;
