/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Vishakh
 */

import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import PrivateRouter from "../../common/routes/PrivateRouter";
import B2BAnalyticsContainer from "./container/B2BAnalyticsContainer";

const B2BAnalyticsManager = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={B2BAnalyticsContainer} />
  </Switch>
);

B2BAnalyticsManager.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default B2BAnalyticsManager;
