/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Harikrishnan A K
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
/** ===== IMAGE ICONS =========== */
import EyeDark from "../../../common/assets/images/svg/eye-dark.svg";
import Home from "../../../common/assets/images/svg/home.svg";
import SearchDark from "../../../common/assets/images/svg/search-dark.svg";
/** ====== COMMON COMPONENTS ============= */
import CustomizationTableNew from "../../../common/components/CustomTable";
import Alert from "../../../common/core/Alert";
import Breadcrumb from "../../../common/core/Breadcrumb";
import BreadcrumbItem from "../../../common/core/BreadcrumbItem";
import Button from "../../../common/core/Button";
import Col from "../../../common/core/Col";
import Dropdown from "../../../common/core/Dropdown";
import DropdownItem from "../../../common/core/DropdownItem";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import Input from "../../../common/core/Input";
/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import UncontrolledDropdown from "../../../common/core/UncontrolledDropdown";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";
/** ================== MODULE STYLES ================ */
import styles from "../css/CustomerDataManagement.module.css";


const CustomerDataManagement = ({
  customers,
  viewCustomer,
  totalPagesCount,
  itemsPerPage,
  searchTerm,
  onPageChange,
  handleSearchTerm,
  handleItemsPerPageChange,


  isLoading,
  message,
}) => {
  const { t } = useTranslation(["customers", "b2bcustomer", "common"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  /**
     *This method is used to toogle dropdown
     *
     */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const customRows = [
    {
      label: "Customer ID",
      value: "",
      path: "invoiceAccount",
      transform: "",
    },
    {
      label: "Customer Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "No of Accounts",
      value: "",
      path: "childOrganizations.length",
      transform: "",
    },
    {
      label: "Actions",
      value: "",
      path: "render",
      render: (row) => (
        <div className="d-flex justify-content-start">
          <span
            onClick={(o) => viewCustomer(row.id, o)}
            onKeyDown={() => { }}
            tabIndex={0}
            role="button"
            className="commonPointer"
          >
            <Button color="transparent" className="btn-square">
              <img src={EyeDark} alt="Home" />
            </Button>
          </span>
        </div>
      ),
    },

  ];
  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7" className="text-truncate">
              <span className="pageText">
                <strong>
                  {t("b2bcustomer:label_customer_title")}
                </strong>
              </span>
            </Col>
            <Col xs="12">
              <Breadcrumb className="mt-2">
                <BreadcrumbItem>
                  <a href="/dashboard">
                    <img src={Home} alt="Home" />
                    {t("common:home")}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="/administration">
                    {t("common:label_administration")}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem><a href="/administration">{t("common:label_userManagement")}</a></BreadcrumbItem>
                <BreadcrumbItem active>{t("b2bcustomer:label_customer_title")}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </Col>
        <Col xs="12">
          <Row className={clsx(styles.block2, "common-page-card")}>

            <Col xs="12">
              <Row className="justify-content-between align-items-center mb-2">
                <Col xs="auto">
                  <h5 className="mb-0"><strong>{t("b2bcustomer:label_customer_manage")}</strong></h5>
                </Col>
                <Col xs="auto">
                  <div className={styles.SearchCol}>
                    <Input
                      placeholder="Search B2B Customer"
                      value={searchTerm}
                      onChange={handleSearchTerm}
                    />
                    <Button><img src={SearchDark} alt="Home" /></Button>
                  </div>
                </Col>
              </Row>
              <Permission
                allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_USR_MGMNT_B2B_CUSTMR_EXPRT_LIST}
              >
                <Row className="mb-2">
                  <Col xs="auto" className="ml-auto">
                    <UncontrolledDropdown className="dropdown-custom">
                      <DropdownToggle caret color="light" size="sm">
                        {t("common:span_export_as")}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>{t("common:div_export_format_XLSX")}</DropdownItem>
                        <DropdownItem>{t("common:div_item_export_format_CSV")}</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
              </Permission>
              <Row className="mb-4">
                <Col xs="12">
                  <CustomizationTableNew
                    customRows={customRows}
                    customizationData={customers}
                    isStatusEnable={false}
                    isDeleteEnable={false}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col xs="auto" className="d-flex align-items-center">
                  Show
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="dropdown-custom dropdown-number mx-2"
                  >
                    <DropdownToggle caret color="light" size="sm">
                      {itemsPerPage}
                    </DropdownToggle>
                    <DropdownMenu down>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(10)}
                        onKeyPress={() => { }}
                      >
                        10

                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(15)}
                        onKeyPress={() => { }}
                      >
                        15

                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleItemsPerPageChange(20)}
                        onKeyPress={() => { }}
                      >
                        20

                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  entries
                </Col>
                <Col xs="auto">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPagesCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={onPageChange}
                    containerClassName="pagination"
                    pageClassName=""
                    pageLinkClassName="pageLink"
                    activeClassName="activePage"
                    initialPage={0}
                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

CustomerDataManagement.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
  viewCustomer: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPagesCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CustomerDataManagement;
