/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useEffect, useState } from "react";
import JobAuditDetails from "../components/JobAuditDetails";
import JobStepExAuditDetailsContainer from "./JobStepExAuditDetailsContainer";
import { getJobStepAuditByJobId } from "../../../api/jobConfigurationService";

const JobAuditDetailsContainer = ({
  onHandleBack,
  selectedJobId,
  rowData,
  formatDate,
}) => {
  const [selectedJobIdStep, setSelectedJobIdStep] = useState(null);
  const [rowDataStep, setRowDataStep] = useState([]);
  const [jobDetailsConfigData, setJobDetailsConfigData] = useState([]);
  useEffect(() => {
    getJobStepAuditByJobId(selectedJobId).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        setJobDetailsConfigData(data);
      }
    });
  }, []);

  const onClickRowStep = (row) => {
    const { id } = row;
    setRowDataStep(row);
    setSelectedJobIdStep(id);
  };
  const onHandleBackStep = (id) => {
    setSelectedJobIdStep(id);
  };

  if (selectedJobIdStep && rowDataStep) {
    return (
      <JobStepExAuditDetailsContainer
        onHandleBackStep={onHandleBackStep}
        selectedJobIdStep={selectedJobIdStep}
        rowDataStep={rowDataStep}
        formatDate={formatDate}
      />
    );
  }

  return (
    <JobAuditDetails
      jobAuditDetails={jobDetailsConfigData}
      onHandleBack={onHandleBack}
      selectedJobId={selectedJobId}
      jobHistoryData={rowData}
      onClickRowStep={onClickRowStep}
      formatDate={formatDate}
      canModify
    />
  );
};

export default JobAuditDetailsContainer;
