/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import Promotions from "../components/Promotions";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { getPromotions } from "../../../api/campaignServices";

/** ============ SVG IMAGE ICON ===================== */
/** ========= MODULE STYLES ========= */

const PromotionsContainer = (props) => {
  const {
    onChange, selectedPromotionDetails, campaignId, salesCompany,
  } = props;
  /** local states */
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [promotions, setPromotions] = useState([]);
  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const [promotionList, setPromotionList] = useState([]);
  /**
   * This function is used to expand collapse
   * @param {Boolean} status
   */
  const toggleBody = (status) => {
    setOpen(status);
  };


  useEffect(() => {
    if (Array.isArray(selectedPromotionDetails) && selectedPromotionDetails.length > 0) {
      setPromotionList([...selectedPromotionDetails]);
      setSelectedPromotions(selectedPromotionDetails.map(({ id }) => id));
    }
  }, [selectedPromotionDetails]);


  /**
   * This function is used to change tab
   * @param {Number} tabIndex
   */
  const changeActiveTab = (tabIndex) => {
    setActiveTab(`${tabIndex}`);
  };

  /**
   * This method is sued to get promotion list from server
   * @param {String} searchTerm
   */
  const getPromotionListFromSever = (searchTerm = "") => {
    getPromotions(searchTerm, salesCompany, campaignId).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          const listedPromotionId = promotionList.map(({ id }) => id);
          setPromotions(data);
          setPromotionList([
            ...(data.filter((each) => each && !listedPromotionId.includes(each.id))),
            ...promotionList,
          ]);
        }
      }
    });
  };

  /**
   * This method is used to change search filed and trigger search functionality
   * @param {Event} event
   */
  const handleSearchFiled = (event) => {
    const { value } = event.target;
    if (value && `${value}`.trim().length > 1) {
      getPromotionListFromSever(value.trim(), salesCompany);
    } else {
      setPromotions([]);
    }
    setSearchKeyword(value);
  };

  /**
   * This method is used to update selected data in server
   * @param {Array} newSelectedList
   * @param {Array} sourceData
   */
  const updateInParent = (newSelectedList, sourceData) => {
    if (onChange && Array.isArray(sourceData)) {
      const fullObjectList = sourceData.filter((promotion) => newSelectedList.includes(promotion.id));
      onChange(fullObjectList || []);
    }
  };

  /**
   * This method is used to set select / deselect promotion
   * @param {String} promotionId
   */
  const setPromotionSelected = (promotionId) => {
    const index = selectedPromotions.indexOf(promotionId);
    let newSelectedList = [];
    if (index < 0) {
      /** if item not exist adding in the list */
      newSelectedList = ([...selectedPromotions, promotionId]);
    } else {
      /** if item exits remove form the list */
      newSelectedList = (selectedPromotions.filter((o) => o !== promotionId));
    }
    setSelectedPromotions(newSelectedList);
    updateInParent(newSelectedList, promotionList);
  };

  /**
   * This function is used to clear search results
   */
  const clearSearchResults = () => {
    setPromotions([]);
    setSearchKeyword("");
  };

  /**
   * This method is used to get new customer group data
   * @param {Object} newPromotion
   */
  const handleNewPromotion = (newPromotion) => {
    if (newPromotion && newPromotion.id) {
      const sourceData = [...promotionList, { ...newPromotion, applyToEveryone: false }];
      setPromotionList(sourceData);
      const newSelectedList = ([...selectedPromotions, newPromotion.id]);
      setSelectedPromotions(newSelectedList);
      updateInParent(newSelectedList, sourceData);
      setActiveTab("1");
    }
  };

  return (
    <Promotions
      open={open}
      activeTab={activeTab}
      searchKeyword={searchKeyword}
      toggleBody={toggleBody}
      changeActiveTab={changeActiveTab}
      handleSearchFiled={handleSearchFiled}
      // promotions
      promotionList={promotionList}
      promotions={promotions}
      selectedPromotions={selectedPromotions}
      setPromotionSelected={setPromotionSelected}
      clearSearchResults={clearSearchResults}
      handleNewPromotion={handleNewPromotion}
      salesCompany={salesCompany}
    />
  );
};

PromotionsContainer.defaultProps = {
  selectedPromotionDetails: [],
  campaignId: null,
};

PromotionsContainer.propTypes = {
  selectedPromotionDetails: PropTypes.arrayOf(PropTypes.object),
  campaignId: PropTypes.string.isRequired,
  // functions
  onChange: PropTypes.func.isRequired,
  salesCompany: PropTypes.string.isRequired,
};

export default PromotionsContainer;
