/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha, Harikrishnan A K
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import EditCustomer from "../components/EditCustomer";
import { getB2BCustomerDetailsById, updateb2bcustomer } from "../../../api/customerConfigurationService";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

const EditCustomerContainer = () => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [form, setForm] = useState({
    customerAccount: "",
    invoiceAccount: "",
    name: "",
    active: false,
    shippingAddress: [],
    currency: "",
    email: "",
    paymentMethods: []
  });
  const submitting = false;

  useEffect(() => {
    let localForm = form;
    if (params.customerId != null) {
      getB2BCustomerDetailsById(params.customerId).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          localForm = {
            ...form,
            customerAccount: data.uniqueId,
            invoiceAccount: data.invoiceAccount,
            id: data.id,
            name: data.name,
            type: data.type,
            active: data.active,
            currency: data.currency,
            classification: data.classification,
            priceList: data.priceList,
            paymentMethod: data.paymentMethods && data.paymentMethods.length > 0 ? data.paymentMethods[0] : null,
            email: data.address?.contactEmail,
            contactPerson: data.shippingAddress[0]?.contactPerson,
            firstName: data.shippingAddress[0]?.firstName,
            lastName: data.shippingAddress[0]?.lastName,
            addressLine1: data.shippingAddress[0]?.addressLine1,
            addressLine2: data.shippingAddress[0]?.addressLine2,
            area: data.shippingAddress[0]?.area,
            city: data.shippingAddress[0]?.city,
            country: data.shippingAddress[0]?.country,
            postCode: data.shippingAddress[0]?.postCode,
            shippingEmail: data.shippingAddress[0]?.email,
            dialCode: data.shippingAddress[0]?.dialCode,
            telephone: data.shippingAddress[0]?.telephone1,
            mobile: data.shippingAddress[0]?.mobile,
            note: data.shippingAddress[0]?.note,
          };
          setForm(localForm);
        }
      });
    }
  }, [params.badgeId, form, params.customerId]);


  /**
       * This method is used to change fom state
       * @param {String} name
       * @param {String} value
       */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  /**
   * This method is used to open confirm Model
   */
  const openConfirm = (customerDetails, enableB2BCustomer) => {
    const response = canActivate(customerDetails);
    if (enableB2BCustomer || (response && response.success)) {
      setOpenConfirmModal(true);
    } else {
      setAlert(response)
    }
  };

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  /**
   * This method is used to check whether customer can be activated or not
   */
  const canActivate = (customer) => {
    if (customer
      && customer.email
      && customer.classification
      && customer.priceList
      && ((customer.paymentMethods && customer.paymentMethods.length > 0) || customer.paymentMethod)
      && customer.city
    ) {
      return { success: true };
    } else {
      return {
        success: false,
        message: "Email, pricelist, classification, payment method and address are mandatory for customer activation",
        type: "warning"
      };
    }
  };

  /**
   * This method is used to cancel confirm Model
   */
  const cancelConfirm = () => {
    setOpenConfirmModal(false);
  };
  /**
   * This method is to Confirm the Status(Enable/Disable) Change fo B2B customer
   * and update the form state accoringly
   */
  const confirmB2BCustomerStatusChange = () => {
    handleChange("active", !form.active);
    cancelConfirm();
  };


  /**
     * This method is used to change form filed input
     * @param {Event} event
     */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };
  const onHandleCancel = () => {
    history.push("/administration/b2bcustomer");
  };
  const onHandleSave = async () => {
    const shippingAddress = {
      contactPerson: form.contactPerson,
      firstName: form.firstName,
      lastName: form.lastName,
      addressLine1: form.addressLine1,
      addressLine2: form.addressLine2,
      area: form.area,
      city: form.city,
      country: form.country,
      postCode: form.postCode,
      email: form.shippingEmail,
      dialCode: form.dialCode,
      telephone1: form.telephone,
      mobile: form.mobile,
      note: form.note,
    };
    form.paymentMethods = form.paymentMethod ? [form.paymentMethod] : [];
    const address = { contactEmail: form.email };
    form.shippingAddress[0] = shippingAddress;
    form.address = address;
    if (params.customerId) {
      await updateb2bcustomer(form);
    }
    history.push("/administration/b2bcustomer");
  };


  return (
    <>
      <EditCustomer
        handleFormChange={handleFormChange}
        enableB2BCustomer={form.active}
        onHandleSave={onHandleSave}
        onHandleCancel={onHandleCancel}
        /** sate change events */
        handleChange={handleChange}
        openConfirm={openConfirm}
        submitting={submitting}
        customerDetails={form}
        message={message}
      />
      <ConfirmationModal
        isOpen={openConfirmModal}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmB2BCustomerStatusChange}
        content={`Are you sure you want to ${form.active ? "Disable" : "Enable"} this B2B Customer? `}
      />
    </>

  );
};
export default EditCustomerContainer;
