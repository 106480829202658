/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */


/** ===== API SERVICE FUNCTIONS =========== */
import { createSKU, getSKUById, updateSKU } from "../../../api/skuServices";
import ModifySku from "../components/ModifySku";
import commonUtils from "../../../common/utils/commonUtils";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

/** Base container for edit product details */
const ModifySkuContainer = (props) => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();

  const { skuId } = commonUtils.getQueryParams(location);

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    productType: "Apparel",
    enableSku: false, /** product buy default disabled */
    attributes: {}, /** multiple attribute key value pairs */
    skuName: "",
    listPrice: "",
    salePrice: "",
    quantity: "",
    onSale: false, /** by default on sale is false */
    images: [], /** selected images list */
    description: "",
    longDescription: "",
    startDate: new Date(),
    endDate: new Date(),
  });
  const [onSubmitting, setOnSubmitting] = useState(false);

  useEffect(() => {
    if (skuId) {
      getSKUById(skuId).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setForm({
            id: data.id,
            name: data.name || "",
            productType: data.type || "",
            enableSku: data.active,
            attributes: data.skuSelector || {},
            skuName: data.name || "",
            description: data.description || "",
            longDescription: data.longDescription || "",
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            listPrice: 25,
            salePrice: 20,
            quantity: 405,
            onSale: false,
            images: data.medias || [],
            skus: data.skus,
          });
        }
      });
    }
  }, [params.skuId, skuId]);

  /**
     * This method is used to change fom state
     * @param {String} name
     * @param {String} value
     */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };


  /**
     * This method is used to change form filed input
     * @param {Event} event
     */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };


  const handleRedirect = () => {
    history.push(`/merchandising/product/edit/${params.productId}`);
  };

  /**
     * This method is used to submit the form
     * @param {Event} event
     */
  const onSubmit = async (value) => {
    const requestBody = {
      id: form.id,
      name: form.skuName,
      type: form.productType,
      active: form.enableSku,
      parentCategories: form.selectedCategories,
      skuSelector: form.attributes,
      skuName: form.skuName,
      salePrice: form.salePrice,
      listPrice: form.listPrice,
      quantity: form.quantity,
      onSale: form.onSale,
      description: form.description,
      longDescription: form.longDescription,
      startDate: form.startDate,
      endDate: form.endDate,
      parentProduct: params.productId,
      landingPageBaseUrl: form.landingPageUrl,
      medias: form.images,
    };
    if (value) {
      /** setting on submit true */
      setOnSubmitting(true);
      let response = null;
      if (skuId) {
        response = await updateSKU(requestBody);
      } else {
        response = await createSKU(requestBody);
      }
      if (response && response.success) {
        setOnSubmitting(false);
        handleRedirect();
      }
    }
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_MERCH_CTLG_PRDT_UPD_PRDT}
    >
      <ModifySku
        /** form data */
        name={form.name}
        enableSku={form.enableSku}
        skuName={form.skuName}
        listPrice={form.listPrice}
        salePrice={form.salePrice}
        quantity={form.quantity}
        onSale={form.onSale}
        images={form.images}
        skus={form.skus}
        selectedCategories={form.selectedCategories}
        categories={form.categories}
        productType={form.productType}
        attributes={form.attributes}
        description={form.description}
        longDescription={form.longDescription}
        startDate={form.startDate}
        endDate={form.endDate}
        landingPageUrl={form.landingPageUrl}
        /** sate change events */
        handleChange={handleChange}
        handleFormChange={handleFormChange}
        /** form action */
        onSubmitting={onSubmitting}
        handleRedirect={handleRedirect}
        onSubmit={onSubmit}
        skuId={skuId}
      />
    </Permission>
  );
};

ModifySkuContainer.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ModifySkuContainer;
