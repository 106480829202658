/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Deepak D
 */

import { useHistory, useRouteMatch } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ===== COMPONENTS  =========== */
import EditShippingMethod from "../components/EditShippingMethod";

/** ===== API SERVICE FUNCTIONS =========== */
import {
  getShippingMethodConfigurationById,
  getShippingTypes,
  getShippingMethods,
  getShippingPriceStrategies,
  updateShippingMethodConfiguration,
} from "../../../api/shipmentManagementServices";

import { getTaxClassCodesByRetailerandBrand as getTaxClassCodesByRetailerAndBrand } from "../../../api/taxServices";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const EditShippingMethodContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [form, setForm] = useState({
    name: "",
    description: "",
    displayMessage: "",
    taxClass: "",
    shippingType: "",
    shippingMethod: "",
    priceStrategy: "",
    amount: "",
    retailer: "5e411b264d50307e272ea9ed",
    brand: "5e411c234d50307e272eaa05",
  });
  const [shippingTypes, setShippingTypes] = useState([]);
  const [taxClasses, setTaxClasses] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [shippingPriceStrategies, setShipmentPriceStrategies] = useState([]);

  const [taxClassDropdownOpen, setTaxClassDropdownOpen] = useState(false);
  const [shippingTypeDropdownOpen, setShippingTypeDropdownOpen] = useState(
    false,
  );
  const [shippingMethodDropdownOpen, setShippingMethodDropdownOpen] = useState(
    false,
  );
  const [
    shippingPriceStrategyDropdownOpen,
    setShippingPriceStrategyDropdownOpen,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (params.id) {
      getShippingMethodConfigurationById(params.id).then((response) => {
        if (response && response.data) {
          const { data } = response;
          setForm(data);
        }
      });
    }

    getShippingTypes().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setShippingTypes(data);
      }
    });

    getShippingMethods().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setShippingMethods(data);
      }
    });

    getShippingPriceStrategies().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setShipmentPriceStrategies(data);
      }
    });

    getTaxClassCodesByRetailerAndBrand().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setTaxClasses(data);
      }
    });
  }, [params.id]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used to toggle the shipping type dropdown
   */
  const toggleTaxClassDropdown = () => {
    setTaxClassDropdownOpen(!taxClassDropdownOpen);
  };

  /**
   * This method is used to toggle the shipping type dropdown
   */
  const toggleShippingTypeDropdown = () => {
    setShippingTypeDropdownOpen(!shippingTypeDropdownOpen);
  };

  /**
   * This method is used to toggle the shipping method dropdown
   */
  const toggleShippingMethodDropdown = () => {
    setShippingMethodDropdownOpen(!shippingMethodDropdownOpen);
  };

  /**
   * This method is used to toggle the price strategy dropdown
   */
  const toggleShippingPriceStrategyDropdown = () => {
    setShippingPriceStrategyDropdownOpen(!shippingPriceStrategyDropdownOpen);
  };

  /**
   * This method is used to update the form with the selected Shipping Type.
   */
  const handleTaxClassDropdownSelection = (event) => {
    handleChange("taxClass", event.target.innerText);
    toggleTaxClassDropdown();
  };

  /**
   * This method is used to update the form with the selected Shipping Type.
   */
  const handleShippingTypeDropdownSelection = (event) => {
    handleChange("shippingType", event.target.innerText);
    toggleShippingTypeDropdown();
  };

  /**
   * This method is used to update the form with the selected Shipping Method
   */
  const handleShippingMethodDropdownSelection = (event) => {
    handleChange("shippingMethod", event.target.innerText);
    toggleShippingMethodDropdown();
  };

  /**
   * This method is used to update the form with the selected Price Strategy
   */
  const handleShippingPriceStrategyDropdownSelection = (event) => {
    handleChange("priceStrategy", event.target.innerText);
    toggleShippingPriceStrategyDropdown();
  };

  /**
   * This method is used to cancel the edit operation and return to the listing page
   */
  const onHandleCancel = () => {
    history.push("/operations/shipping-method");
  };

  /**
   * This method is used to update the form
   */
  const onHandleSave = async () => {
    setLoading(true);
    await updateShippingMethodConfiguration(form);
    history.push("/operations/shipping-method");
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_OPERTNS_SHPNG_MTHD_UPD_SHPNG_MTHD}
    >
      <EditShippingMethod
        form={form}
        toggleShippingTypeDropdown={toggleShippingTypeDropdown}
        toggleShippingMethodDropdown={toggleShippingMethodDropdown}
        toggleShippingPriceStrategyDropdown={toggleShippingPriceStrategyDropdown}
        toggleTaxClassDropdown={toggleTaxClassDropdown}
        taxClassDropdownOpen={taxClassDropdownOpen}
        shippingTypeDropdownOpen={shippingTypeDropdownOpen}
        shippingMethodDropdownOpen={shippingMethodDropdownOpen}
        shippingPriceStrategyDropdownOpen={shippingPriceStrategyDropdownOpen}
        handleShippingTypeDropdownSelection={handleShippingTypeDropdownSelection}
        handleShippingMethodDropdownSelection={
          handleShippingMethodDropdownSelection
        }
        handleShippingPriceStrategyDropdownSelection={
          handleShippingPriceStrategyDropdownSelection
        }
        handleTaxClassDropdownSelection={handleTaxClassDropdownSelection}
        taxClasses={taxClasses}
        shippingTypes={shippingTypes}
        shippingMethods={shippingMethods}
        shippingPriceStrategies={shippingPriceStrategies}
        handleFormChange={handleFormChange}
        onHandleSave={onHandleSave}
        onHandleCancel={onHandleCancel}
        loading={loading}
      />
    </Permission>
  );
};
export default EditShippingMethodContainer;
