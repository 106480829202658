/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect } from "react";
import PromotionManagement from "../components/PromotionManagement";
import {
  getAllSalesCompany,
} from "../../../api/customerGroupServices";
import { getPriceConfigurations, updatePriceConfigurations } from "../../../api/priceBookManagementService";
import { clearPricingEngineCache } from "../../../api/cacheManagementService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const PromotionManagementContainer = () => {
  const [salesCompany, setSalesCompany] = useState();
  const [salesCompanyList, setSalesCompanyList] = useState([]);
  const [multiBrandConfiguration, setMultiBrandConfiguration] = useState([]);
  const [multiBrandConfigurationMap, setMultiBrandConfigurationMap] = useState([]);
  const [configData, setConfigData] = useState([]);

  const getpriceConfiguration = (salesCompanyId) => {
    getPriceConfigurations(salesCompanyId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setConfigData(data);
        if (data && data.multiBrandConfigurations) {
          setMultiBrandConfiguration(data.multiBrandConfigurations);
        } else {
          setMultiBrandConfiguration([]);
        }
      }
    });
  };
  useEffect(() => {
    getAllSalesCompany().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        setSalesCompanyList(data);
      }
    });
  }, []);
  useEffect(() => {
    const brandConfigurationMap = Object.entries(
      multiBrandConfiguration,
    ).map(([key, value]) => ({
      brand: key,
      fetchOrderPromotion: value.fetchOrderPromotion,
      considerAccountDiscount: value.considerAccountDiscount,
      active: value.active,
    }));
    setMultiBrandConfigurationMap(brandConfigurationMap);
  }, [multiBrandConfiguration]);

  const handleSalesCompanyChange = (value) => {
    setSalesCompany(value);
    getpriceConfiguration(value);
  };
  const updateData = async (brand, name, status) => {
    if (configData && (status || name === "active")) {
      const brandData = configData.multiBrandConfigurations[brand];
      const newBrandData = { ...brandData, [name]: status };
      if (name === "active") {
        newBrandData.active = status;
      } else if (name === "fetchOrderPromotion") {
        newBrandData.considerAccountDiscount = !status;
      } else {
        newBrandData.fetchOrderPromotion = !status;
      }
      const multiBrandConfigurations = {
        ...configData.multiBrandConfigurations,
        [brand]: newBrandData,
      };
      const newConfigData = { ...configData, multiBrandConfigurations };
      await updatePriceConfigurations(newConfigData, salesCompany).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setConfigData(data);
          if (data && data.multiBrandConfigurations) {
            setMultiBrandConfiguration(data.multiBrandConfigurations);
          } else {
            setMultiBrandConfiguration([]);
          }
          clearPricingEngineCache();
        }
      });
    }
  };
  return (
    <Permission allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_DISCOUNT_MGMNT_EDIT}>
      <PromotionManagement
        salesCompany={salesCompany}
        salesCompanyList={salesCompanyList}
        handleSalesCompanyChange={handleSalesCompanyChange}
        multiBrandConfiguration={multiBrandConfigurationMap}
        updateData={updateData}
      />
    </Permission>
  );
};

export default PromotionManagementContainer;
