/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TaxLocation from "../components/TaxLocation";
import { getTaxLocationsByRetailerAndBrand, deleteTaxLocation } from "../../../api/taxConfigurationService";
import Permission from "../../../common/security/Permission";
import Rules from "../../../common/security/permissionRules";

const TaxLocationContainer = () => {
  const history = useHistory();

  const onHandleNewLocation = () => {
    history.push("/administration/tax-location/new");
  };
  const [editable, setEditable] = useState(false);
  const onHandleSelectedLocation = (location) => {
    if (!editable) {
      history.push(`/administration/tax-location/tax-configuration/${location.id}`);
    }
  };

  const [taxLocationData, setTaxLocation] = useState([]);
  useEffect(() => {
    getTaxLocationsByRetailerAndBrand("5e411b264d50307e272ea9ed", "5e411c234d50307e272eaa05").then(async (response) => {
      if (response && response.success && response.data) {
        setTaxLocation(response.data);
      }
    });
  }, []);

  const onHandleEditTaxLocation = (id) => {
    setEditable(true);
    history.push(`/administration/tax-location/edit/${id}`);
  };

  const onHandleDeleteTaxLocation = async (id) => {
    setEditable(true);
    await deleteTaxLocation(id);
    getTaxLocationsByRetailerAndBrand("5e411b264d50307e272ea9ed", "5e411c234d50307e272eaa05").then(async (response) => {
      if (response && response.success && response.data) {
        setTaxLocation(response.data);
      }
    });
  };

  return (
    <Permission
      allowed={Rules.Control.MOZCOM_RA_ADMSTRTN_TAX_LCTN_VIEW_LIST}
    >
      <TaxLocation
        onHandleNewLocation={onHandleNewLocation}
        onHandleEditTaxLocation={onHandleEditTaxLocation}
        onHandleSelectedLocation={onHandleSelectedLocation}
        taxLocationData={taxLocationData}
        onHandleDeleteTaxLocation={onHandleDeleteTaxLocation}
      />
    </Permission>
  );
};

export default TaxLocationContainer;
