/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * App configurations
 * @module
 */

const getSubdomain = (hostname, siteNameSeparator) => {
  const containsWww = hostname.includes("www.");
  const splitIndex = siteNameSeparator === "." && containsWww ? 1 : 0;
  let subDomain = hostname.split(siteNameSeparator)[splitIndex];
  if (subDomain && siteNameSeparator !== "." && containsWww) {
    subDomain = subDomain.split(".")[1];
  }
  return subDomain;
};

const constructAppApiBaseURL = () => {
  const apiHost = process.env.REACT_APP_API_HOST || "b2b-api-fenix-local.mozcomtest.com:8071";
  const siteNameSeparator = process.env.REACT_APP_SITE_NAME_SEPARATOR || ".";
  const disableConstructApiUrl = process.env.REACT_APP_DISABLE_CONSTRUCT_API_URL || false;
  if (String(disableConstructApiUrl).toLowerCase() === "true") {
    return apiHost;
  }
  const { location: { protocol, hostname } } = window;
  const hostSubdomain = hostname ? getSubdomain(hostname, siteNameSeparator) : null;
  return `${protocol}//${apiHost}`;
};

const constructExternalApiBaseURL = () => {
  const apiHost = process.env.REACT_APP_EXTERNAL_API_HOST || "";
  const siteNameSeparator = process.env.REACT_APP_SITE_NAME_SEPARATOR || ".";
  const disableConstructApiUrl = process.env.REACT_APP_DISABLE_CONSTRUCT_API_URL || false;
  if (String(disableConstructApiUrl).toLowerCase() === "true") {
    return apiHost;
  }
  const { location: { protocol, hostname } } = window;
  const hostSubdomain = hostname ? getSubdomain(hostname, siteNameSeparator) : null;
  return `${protocol}//${apiHost}`;
};

const apiHostName = constructAppApiBaseURL();
const externalHostName = constructExternalApiBaseURL();
const config = {
  environment: process.env.ENVIRONMENT || "LOCAL",
  api: {
    webhookurl: process.env.REACT_APP_WEBHOOK_URL,
    baseURL: `${apiHostName}/api/`,
    loginURL: `${apiHostName}/login`,
    logoutURL: `${apiHostName}/v1/auth/logout`,
    externalServer: externalHostName,
  },
  storeFrontURL: process.env.REACT_APP_STOREFRONT_URL || "b2b-web-fenix-local.mozcomtest.com:3000",
  key: {
    googleMap: "AIzaSyDp0qkfNuXBDwggC5l78-qXqYs2-fpbfVY",
  },
  authServerHost: process.env.REACT_APP_AUTH_SERVER_HOST || "http://localhost:6443",
  disableErrorReporting: process.env.REACT_APP_DISABLE_ERROR_REPORTING || false,
  customer360UserName: process.env.CUSTOMER_360_LOGIN_USER_NAME || "user1-login@gmail.com",
  customer360Password: process.env.CUSTOMER_360_LOGIN_USER_PASSWORD || "password",
};

export default config;
