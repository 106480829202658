/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Customization table
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import deleteIcon from "../../../common/assets/images/svg/delete.svg";
import dragIndicatorIcon from "../../../common/assets/images/svg/dragIndicator.svg";
import Spinner from "../../../common/core/Spinner";
import Table from "../../../common/core/Table";
import styles from "../css/Table.module.css";
import transformers from "../../../common/utils/transformers";
import Row from "../../../common/core/Row";


const RowItem = (props) => {
  const {
    rowItem,
    index,
    pageCustomizationRows,
    handleDelete,
    isLoading,
    selectedIndex,
  } = props;

  /**
   *This method is used to get a value from an item
   *
   * @param {object} row
   * @param {object} rowItemData
   * @returns
   */
  const getItem = (row, rowItemData) => {
    let data = get(row, rowItemData.path);
    if (rowItemData.transform && rowItemData.transform !== "") {
      data = transformers[rowItemData.transform](row, rowItemData.path);
    }
    return data;
  };

  return (
    <Draggable draggableId={getItem(rowItem, { path: "id" })} width="100%" index={index} key={getItem(rowItem, { path: "id" })}>
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <th scope="row" width="10%">
            <img src={dragIndicatorIcon} alt="drag indiactor icon" width="30px" height="24px" />
            <span className="p-2">{index + 1}</span>
          </th>
          <td width="10%">
            {rowItem.imageUrl ? <img src={getItem(rowItem, { path: "imageUrl" })} alt="preview" width="60px" /> : "No image"}
          </td>
          {pageCustomizationRows.map((row) => <td width="10%">{getItem(rowItem, row)}</td>)}
          <td width="10%">
            {isLoading && (selectedIndex === index) ? (
              <Spinner className={styles.spinnerWrapper} />
            )
              : (
                <img
                  src={deleteIcon}
                  className="commonPointer"
                  alt="drag indicator icon"
                  width="20px"
                  role="presentation"
                  onClick={() => handleDelete(rowItem.id, index)}
                />
              )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};

RowItem.propTypes = {
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowItem: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
};


const ItemTable = (props) => {
  const {
    handleDelete,
    isLoading,
    selectedIndex,
    onDragEnd,
    customizationData,
  } = props;

  const pageCustomizationRows = [
    {
      label: "Id",
      value: "",
      path: "id",
    },
    {
      label: "name",
      value: "",
      path: "name",
    },
  ];

  return (
    <Table borderless>
      <thead>
        <tr>
          <th>Order</th>
          <th>Preview</th>
          {pageCustomizationRows.map((row) => <th>{row.label}</th>)}
          <th>Delete</th>
        </tr>
      </thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="combined" type="combined">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {customizationData && Array.isArray(customizationData)
                && customizationData.length > 0 ? customizationData.map((rowItem, index) => (
                  <RowItem
                    rowItem={rowItem}
                    index={index}
                    isLoading={isLoading}
                    selectedIndex={selectedIndex}
                    handleDelete={handleDelete}
                    pageCustomizationRows={pageCustomizationRows}
                  />
                ))
                : <Row className="justify-content-center">No data</Row>}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};

ItemTable.propTypes = {
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDelete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default ItemTable;
