/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author sreelekshmi
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import BrandSelector from "../components/BrandSelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { getAllBrands } from "../../../api/brandServices";


const BrandSelectorContainer = ({ onChange, name, value: selectedBrandDetails }) => {
  /** local states */
  /** used to for showing and sorting brands */
  const [brands, setBrands] = useState(Array.isArray(selectedBrandDetails) ? selectedBrandDetails : []);
  /** used for store brands for rollback sort/filter to default */
  const [brandList, setBrandList] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  /**
   * This method is sued to get brand list from server
   */
  const getBrandListFromSever = (searchTerm = "") => {
    getAllBrands(searchTerm).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          const listedBrandsId = brandList.map(({ id }) => id);
          setBrands(data);
          setBrandList([
            ...(data.filter((each) => each && !listedBrandsId.includes(each.id))),
            ...brandList,
          ]);
        }
      }
    });
  };

  useEffect(() => {
    if (Array.isArray(selectedBrandDetails) && selectedBrandDetails.length > 0) {
      const listedBrandId = brandList.map(({ id }) => id);
      const filteredList = selectedBrandDetails.filter((each) => each && !listedBrandId.includes(each.id));
      setBrandList([
        ...brandList,
        ...filteredList,
      ]);
      setSelectedBrands(selectedBrandDetails.map(({ id }) => id));
    }
  }, [selectedBrandDetails, brandList]);

  /**
   * This method is used to change search filed and trigger search functionality
   * @param {Event} event
   */
  const handleSearchField = (value) => {
    if (value && `${value}`.trim().length > 1) { getBrandListFromSever(value); } else { getBrandListFromSever(); }
    setSearchKeyword(value);
  };


  /**
   * This function is used to manipulate object
   * @param {Object} brand
   */
  const updateUrl = (brand) => {
    const catalogItemId = brand.dataId || brand.id;
    const url = brand.landingPageBaseUrl || `/brand-details?id=${catalogItemId}`;
    return ({ ...brand, url });
  };

  /**
   * This method is used to set select / deselect brand
   * @param {Event} event
   */
  const setBrandSelected = (event) => {
    const { value: brandIds } = event.target;
    setSelectedBrands(brandIds);

    const filteredBrands = brandList.filter((o) => brandIds.includes(o.id));
    onChange(filteredBrands.map(updateUrl));
  };

  useEffect(() => {
    getBrandListFromSever();
  }, [getBrandListFromSever]);

  return (
    <BrandSelector
      name={name}
      BrandList={brandList}
      brands={brands}
      selectedBrands={selectedBrands}
      setBrandSelected={setBrandSelected}
      searchKeyword={searchKeyword}
      handleSearchField={handleSearchField}
    />
  );
};


BrandSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
};

BrandSelectorContainer.defaultProps = {
  name: "brand",
};

export default BrandSelectorContainer;
