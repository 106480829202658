/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * page content services
 *
 * @author Naseef O
 *
 */

import httpApi from "./httpApi";

/**
 *This method is used to get page customization rows
 * @returns
 */
const getPageCustomizationRows = async () => {
  const rows = [
    {
      label: "Header",
      value: "",
      path: "header",
    },
  ];
  return rows;
};

/**
 *This method is used to get page customization data
 * @returns
 */
const getPageCustomizationData = async () => {
  const rowData = [
    {
      preview: "https://assets.myntassets.com/f_webp,w_980,c_limit,fl_progressive,dpr_2.0/assets/images/2020/2/11/a21f2653-00a4-4e84-bc3a-74a18a0349001581417737841-GJ_Desk_Banner.jpg",
      startDate: "10-12-2019",
      endDate: "10-03-2020",
      id: "232356",
    },
    {
      preview: "https://assets.myntassets.com/f_webp,w_980,c_limit,fl_progressive,dpr_2.0/assets/images/2020/2/11/a21f2653-00a4-4e84-bc3a-74a18a0349001581417737841-GJ_Desk_Banner.jpg",
      startDate: "01-01-2020",
      endDate: "31-01-2020",
      id: "234356",
    },
    {
      preview: "https://assets.myntassets.com/f_webp,w_980,c_limit,fl_progressive,dpr_2.0/assets/images/2020/2/11/a21f2653-00a4-4e84-bc3a-74a18a0349001581417737841-GJ_Desk_Banner.jpg",
      startDate: "04-12-2019",
      endDate: "1-03-2020",
      id: "224356",
    },
    {
      preview: "https://assets.myntassets.com/f_webp,w_980,c_limit,fl_progressive,dpr_2.0/assets/images/2020/2/11/a21f2653-00a4-4e84-bc3a-74a18a0349001581417737841-GJ_Desk_Banner.jpg",
      startDate: "01-12-2019",
      endDate: "10-03-2020",
      id: "324356",
    },
  ];
  return rowData;
};

/**
 *This method is used to get page contents by page type
 *
 * @param {*} pageType
 * @returns
 */
const getPageContentByPageType = async (pageType) => {
  const response = await httpApi.get(`/v1/page-content/page-type/${pageType}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update page contents
 *
 * @param {Object} requestData
 * @param {String} channelType
 * @returns
 */
const updatePageContent = async (requestData, channelType, locale) => {
  const params = { channelType, locale };
  const response = await httpApi.patch("/v1/page-content", requestData, { params });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get page contents
 *
 * @param {String} widgetType
 * @param {String} configId
 * @param {String} categoryId
 * @param {String} channelType
 * @returns
 */
const getPageContentByTypeAndConfigId = async (widgetType, configId, categoryId, channelType, locale) => {
  const params = {
    categoryId,
    channelType,
    locale,
  };
  const response = await httpApi.get(`/v1/page-content/admin/type/${widgetType}/config-id/${configId}`, { params });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to upload image
 *
 * @param {*} image
 * @returns
 */
const uploadImage = async (image) => {
  const formData = new FormData();
  formData.append("image", image);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data; charset=utf-8;",
    },
  };
  const response = await httpApi.post("/v1/page-content/image", formData, config);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  getPageCustomizationRows,
  getPageCustomizationData,
  getPageContentByPageType,
  updatePageContent,
  getPageContentByTypeAndConfigId,
  uploadImage,
};
