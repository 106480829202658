/**
* Copyright(c) 2020 Mozanta Technologies Private Ltd.
*
* All rights reserved.
*
* This software is the confidential and proprietary information of Mozanta
* ("Confidential Information"). You shall not disclose such Confidential
* Information and shall use it only in accordance with the terms of the
* contract agreement you entered into with Mozanta.
*
*/

import React, { useEffect, useState } from "react";
import { getAllCustomPages, updateCustomPage } from "../../../api/pageConfigurationsServices";
import { getContentReplicationStatus, getSitePreviewConfigurations, replicateContent } from "../../../api/sitePreviewConfigurationsServices";
import StaticPages from "../components/StaticPages";

const StaticPagesContainer = () => {
  const [customPages, setCustomPages] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  //   const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  //   const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };
  const getReplicationStatus = async () => {
    getContentReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last content push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          //   setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          //   const { previewSiteUrl: localPreviewSiteUrl } = data;
          //   setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, [getReplicationStatus]);


  /**
 * This method is used to get all custom pages
 *
 * @param {*} pageNumber
 */
  const getCustomPages = (pageNumber) => {
    const searchTerm = "";
    const limit = 5;
    getAllCustomPages(searchTerm, pageNumber, limit).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setPage(pageNumber);
        setCustomPages(data);
        if (Array.isArray(data) && data.length > 0) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      }
    });
  };

  useEffect(() => {
    getCustomPages(page);
  }, [page]);

  /**
 * This method is used to get  handle change status of a custom a page
 *
 * @param {*} value
 */
  const handleChangeStatus = async (value) => {
    const requestData = {
      id: value.id,
      url: value.url,
      content: value.content,
      active: !value.active,
    };
    await updateCustomPage(requestData).then(async (response) => {
      if (response && response.success && response.data) {
        getCustomPages(page);
      }
    });
  };

  /**
 * This method is used to get handdle search custom pages
 *
 * @param {*} direction
 */
  const onHandleSearch = (direction) => {
    const newPage = page + direction;
    getCustomPages(newPage);
  };


  const handlePushToLive = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    replicateContent().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Page pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };


  return (
    <StaticPages
      customPages={customPages}
      handleChangeStatus={handleChangeStatus}
      page={page}
      onHandleSearch={onHandleSearch}
      hasMoreData={hasMoreData}
      handlePushToLive={handlePushToLive}
      message={message}
      pushToLiveEnabled={pushToLiveEnabled}
      pushToLiveButtonEnabled={pushToLiveButtonEnabled}
      getReplicationStatus={getReplicationStatus}
    />
  );
};

export default StaticPagesContainer;
